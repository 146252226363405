import React, { useState } from "react";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Formik } from "formik";
import {Zoom, toast } from "react-toastify";
import axios from "axios";

const ForgotPassword = (props) => {
  const [validation_error, setValidation_error] = useState([]);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  return (
    <>
      
      <div className='email_verify'>
        <h4 className='text-center py-3 fw-bold border-bottom w-75 mx-auto'>
         Forgot Password
        </h4>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: '',
          }}
          validate={(values) => {
            const errors = {};

            // Email validate condition
            if (!values.email) {
              errors.email = "Please Input Your Email Address";
            } else if (values.email.length > 100) {
              errors.email = "Email maximum length of 50 characters";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid Email Address";
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
          setDisableSubmitBtn(true);
            const data = values;
            axios
              .post("/forgot-password", data)
              .then((res) => {
                if (res.data.status === 200) {
                  document.getElementById("resetForm").reset();
                  resetForm();
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });                
                 setDisableSubmitBtn(false);
                  props.handleVerifyPin(true);
                  localStorage.setItem("user_forgot_email", res.data.email);
                } else if  (res.data.status === 404){
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }); 
                  setDisableSubmitBtn(false);
                }
                 else if  (res.data.status === 422){
                  setDisableSubmitBtn(false);
                  setValidation_error(res.data.validation_errors);
                }
              })
              .catch((err) => {
                setDisableSubmitBtn(false);
              });
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} id='resetForm'>
              {/* email Input */}
              <div>
                <label className='fw-bold'>Email</label>
                <div className='input-group'>
                  <input
                    type='text'
                    name='email'
                    id='email'
                    // disabled
                    placeholder='Email'
                    className='form-control'
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    touched={formik.touched.email}
                    onBlur={formik.handleBlur}
                  />
                  <label className='input-group-text' htmlFor='email'>
                    <i className='fas fa-envelope'></i>
                  </label>
                </div>
                {/* errors will return when field validation fails  */}
                {validation_error.email ? (
                  <span className='text-danger'>{validation_error.email}</span>
                ) : (
                  formik.touched.email &&
                  formik.errors.email && (
                    <span className='text-danger'>{formik.errors.email}</span>
                  )
                )}
              </div>
              

              <div>
                <MDBBtn
                  type='submit'
                  rounded
                  className='login-submit-btn btn btn-danger btn-rounded btn-block my-3 mx-auto'
                  color='danger'
                  disabled={disableSubmitBtn}
                  >
                  Send verification Code  
                  {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
                </MDBBtn>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotPassword;
