import React, { useState, useEffect, useContext } from "react";
import "./SidebarMenu.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import authContext from './../../auth/Context/AuthUser/authContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
declare var $: any;  
const SidebarMenu = () => {
  const {Login} =
  useContext(authContext);


   // get user
   const [user, setUser] = useState([]);



  useEffect(() => {
    
    let isMounted = true;
    const fetchData = async () => {
      if (Login) {
      try {
        const res = await axios.get("/me");
        if(isMounted){
          if (res.data.status === 200) {
            setUser(res.data.user);
          }
        }
      } catch (e) {
         console.log(e);
      }
    }
   }
  
       fetchData();

          return () => {
      isMounted = false;
    }
  }, []);








  const path = window.location.pathname;

  useEffect(() => {
    $("body").Layout();
    const trees = $('[data-widget="treeview"]');
    trees.Treeview("init");
    // window.$('[data-widget="treeview"]').Treeview('init');
    // window.$('[data-toggle="push-menu"]').pushMenu();
    // window.$('[data-widget="tree"]').tree();
    // window.$('[data-toggle="control-sidebar"]').controlSidebar();
//     $("ul.sidebar-menu li a[href='"+document.location.pathname+"']").parents('li').addClass('active');
    
// Will only work if string in href matches with location
// $('.treeview-menu li a[href="' + url + '"]').parent().addClass('active');
// // Will also work for relative and absolute hrefs
// $('.treeview-menu li a').filter(function() {
// return this.href == url;
// }).parent().parent().parent().addClass('active');


 var url = window.location;

// for sidebar menu entirely but not cover treeview
  // $('ul.nav-sidebar a').filter(function() {
  //   return this.href == url;
  // }).addClass('active');

// for treeview
  $('ul.nav-treeview a').filter(function() {
    return this.href == url;
  }).parentsUntil(".nav-sidebar > .nav-treeview").addClass('menu-open').prev('a').addClass('active');

  }, []);
  // ----------path === r/our-dashboardoute path menu open condition----------

  const categoryMenuOpen =
    path === "/our-dashboard/all/categories" ||
    path === "/our-dashboard/add/categories"
      ? "nav-item menu-open"
      : "nav-item";
  const SubcategoryMenuOpen =
    path === "/our-dashboard/all/sub-categories" ||
    path === "/our-dashboard/add/sub-categories"
      ? "nav-item menu-open"
      : "nav-item";
  const SOScategoryMenuOpen =
    path === "/our-dashboard/all/sos-categories" ||
    path === "/our-dashboard/add/sos-categories"
      ? "nav-item menu-open"
      : "nav-item";
  const StoreMenuOpen =
    path === "/our-dashboard/all/store" || path === "/our-dashboard/add/store"
      ? "nav-item menu-open"
      : "nav-item";
  const productMenuOpen =
    path === "/our-dashboard/all/product" ||
    path === "/our-dashboard/add/product" ||
    path === "/our-dashboard/product/draft"
      ? "nav-item menu-open"
      : "nav-item";
  const orderMenuOpen =
    path === "/our-dashboard/all/orders" || path === "/our-dashboard/new/orders"
      ? "nav-item menu-open"
      : "nav-item";
  const CustomserviceMenuOpen =
    path === "/our-dashboard/admin/new-service-request" ||
    path === "/our-dashboard/admin/approved-service" ||
    path === "/our-dashboard/admin/delivered-services" ||
    path === "/our-dashboard/admin/cancled-service"
      ? "nav-item menu-open"
      : "nav-item";
  const newsletterMenuOpen =
    path === "/our-dashboard/newsletter-subscription"
      ? "nav-item menu-open"
      : "nav-item";
  const adminMenuOpen =
    path === "/our-dashboard/add-admin" ||
    path === "/our-dashboard/all-admin" ||
    path === "/our-dashboard/deactive-admin"
      ? "nav-item menu-open"
      : "nav-item";
  const userMenuOpen =
    path === "/our-dashboard/all-user" ||
    path === "/our-dashboard/deactive-user"
      ? "nav-item menu-open"
      : "nav-item";

      const accountMenuOpen =
      path === "/our-dashboard/accounts"
        ? "nav-item menu-open"
        : "nav-item";

  return (
    <>
      <aside className='main-sidebar custom_bg sidebar-dark-primary elevation-4'>
        {/* Brand Logo */}
        <NavLink to='/our-dashboard' className='brand-link'>
          <LazyLoadImage
          effect="blur" 
            src='/assest/image/logo/ourrajshahi-icon.png'
            alt='ourraj-admin'
            className='img-circle elevation-3 custom_site_icon'
            style={{ opacity: ".8" }}
          />

          <span className='brand-text font-weight-light pl-2'>Dashboard</span>
        </NavLink>
        {/* Sidebar */}
        <div className='sidebar'>
          {/* Sidebar user panel (optional) */}
          <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
            <div className='image'>
              {
                <LazyLoadImage
                effect="blur" 
                src= {`${process.env.REACT_APP_API_URL}${user?.profile_image}`}
                className='img-circle elevation-2 user_img'
                alt='User'
              />
              }
              
            </div>
            <div className='info'>
              <NavLink to='/our-dashboard/profile' className='d-block'>
              {user?.name}
              </NavLink>
            </div>
          </div>
          {/* SidebarSearch Form */}
          <div className='form-inline'>
            <div className='input-group' data-widget='sidebar-search'>
              <input
                className='form-control form-control-sidebar'
                type='search'
                placeholder='Search'
                aria-label='Search'
              />
              <div className='input-group-append'>
                <button className='btn btn-sidebar'>
                  <i className='fas fa-search fa-fw' />
                </button>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className='mt-2 mb-5'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'>
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              {/* --site visit-- */}
              <li className='nav-item'>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'
                  className='nav-link'>
                  <i class='fas fa-globe-americas  nav-icon'></i>
                  <p>Visit Site</p>
                </a>
              </li>

              {/* --- all nav item---- */}
              <li className={categoryMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/categories" ||
                      path === "/our-dashboard/add/categories")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-folder nav-icon'></i>
                  <p>
                    Categories
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Categories</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Categories</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={SubcategoryMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/sub-categories" ||
                      path === "/our-dashboard/add/sub-categories")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-folder-plus nav-icon'></i>
                  <p>
                    Sub Categories
                    <i className='right fas fa-angle-left' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/sub-categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Sub Categories</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/sub-categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Sub Categories</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={SOScategoryMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/sos-categories" ||
                      path === "/our-dashboard/add/sos-categories")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-folder-open nav-icon'></i>
                  <p>
                    SOS Categories
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/sos-categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All SOS Categories</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/sos-categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add SOS Categories</p>
                    </NavLink>
                  </li>
                </ul>
              </li>


              <li className="nav-item">
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/admin/blog/categories" ||
                      path === "/our-dashboard/admin/blog/add/categories")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-folder-open nav-icon'></i>
                  <p>
                  Blog
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/admin/blog/categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Categories</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/admin/blog/subcategories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Subcategories</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/admin/blog/post'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Post</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/admin/blog/comment'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Comment</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/admin/blog/right-site-others'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Right-Site-Others</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={StoreMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/store" ||
                      path === "/our-dashboard/add/store")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-store nav-icon'></i>
                  <p>
                    Store
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/store'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Store</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/store'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Store</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className={productMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/product" ||
                      path === "/our-dashboard/add/product" ||
                      path === "/our-dashboard/product/draft")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-box-open nav-icon'></i>
                  <p>
                    Products
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/product'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Products</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/product'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Products</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/product/draft'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Draft Products</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/product/review'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Review Products</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/color" ||
                      path === "/our-dashboard/add/color")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class="fas fa-paint-brush nav-icon"></i>
                  <p>
                    Colors
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/color'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Color</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/color'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Color</p>
                    </NavLink>
                  </li>
                </ul>

              </li>
              <li className="nav-item">
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/brand" ||
                      path === "/our-dashboard/add/brand")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class="fas fa-building nav-icon"></i>
                  <p>
                    Brands
                    <i className='fas fa-angle-left right' />

                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/brand'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Brand</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add/brand'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Brand</p>
                    </NavLink>
                  </li>
                </ul>
                
              </li>

              <li className={orderMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all/orders" ||
                      path === "/our-dashboard/new/orders")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-shopping-bag  nav-icon'></i>
                  <p>
                    Orders
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all/orders'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Orders</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/orders/new'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>New Orders</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={accountMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    path === "/our-dashboard/accounts"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class="fas fa-dollar-sign nav-icon"></i>
                  <p>
                    Accounts
                    <i className='fas fa-angle-left right' />
                    
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/accounts'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Order Amounts</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

                
              <li className={CustomserviceMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "our-dashboard/add-service-plan" ||
                      path === "our-dashboard/all-service-plan" ||
                      path === "our-dashboard/deactive-service-plan" 
                     )
                      ? "nav-link active"
                      : "nav-link"
                    }>
                   <i class="fas fa-lightbulb nav-icon"></i>
                  <p>
                     Service Plans
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add-service-plan'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Service Plan</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-service-plan'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Service Plan</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/deactive-service-plan'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Deactive Service Plan</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
              
              <li className={CustomserviceMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "our-dashboard/new-service-request" ||
                      path === "our-dashboard/approved-service" ||
                      path === "our-dashboard/delivered-services" ||
                      path === "our-dashboard/cancled-service")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-handshake  nav-icon'></i>
                  <p>
                    Custom Service
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/custom-service/all'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Service</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/custom-service/new'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>New Service</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/custom-service/approved'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Approved Service</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/custom-service/delivered'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Delivered Service</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/custom-service/cancled'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Cancled Service</p>
                    </NavLink>
                  </li>
                </ul>
              </li>


              

              <li className={newsletterMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    path === "/our-dashboard/newsletter-subscription"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  {/* <i class="fas fa-envelope-open-text nav-icon"></i> */}
                  {/* <i class="fas fa-newspaper nav-icon"></i> */}
                  <i className='nav-icon far fa-envelope' />
                  <p>
                    Newsletter
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/newsletter-subscription'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Subscription</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive && path === "/our-dashboard/top-search"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i className='nav-icon fas fa-search' />
                  <p>
                    Searches
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/top-search'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Top Searches</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/top-search/month'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Last 30 Days Searches</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/top-search/year'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Last 1 Year Searches</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/today-search'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Todays Top Searches</p>
                    </NavLink>
                  </li>
                  <li className='nav-item pt-1 border-top'>
                    <NavLink
                      to='/our-dashboard/top-search-user'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Top Searches By User</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/top-search-user/month'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Last 30 Days Searches By User</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/top-search-user/year'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Last 1 Year Searches By User</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/today-search-user'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Todays Top Searches By User</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-search'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Searches</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={adminMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/add-admin" ||
                      path === "/our-dashboard/all-admin" ||
                      path === "/our-dashboard/deactive-admin")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-user-graduate nav-icon'></i>
                  <p>
                    Admin
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add-admin'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Admin</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-admin'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Admin</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/deactive-admin'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Deactived Admin</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/add-slider" ||
                      path === "/our-dashboard/all-slider")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-shield-alt nav-icon'></i>
                  <p>
                    Role & permission
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-role'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Role</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-permission'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>All Permission</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add-permission'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Permission</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive && path === "/our-dashboard/stats/visitor-summary"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-users nav-icon'></i>
                  <p>
                    Visitor
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/stats/visitor-summary'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Visitor Summary</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={userMenuOpen}>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    (path === "/our-dashboard/all-user" ||
                      path === "/our-dashboard/deactive-user")
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-user-circle nav-icon'></i>
                  <p>
                    Users
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-user'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />

                      <p>All Users</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/deactive-user'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Deactive Users</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    isActive &&
                    path === "/our-dashboard/add-gallery"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i className='nav-icon far fa-image' />
                  <p>
                    Gallery
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add-gallery'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />

                      <p>Add  gallery</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/all-gallery'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Gallery Images</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item'>
                <NavLink
                  to='#'
                  className={({ isActive }) =>
                    (isActive && path === "/our-dashboard/active-coupon") ||
                    path === "/our-dashboard/add-coupon" ||
                    path === "/our-dashboard/deactive-coupon" ||
                    path === "/our-dashboard/expired-coupon"
                      ? "nav-link active"
                      : "nav-link"
                  }>
                  <i class='fas fa-gift nav-icon'></i>
                  <p>
                    Coupon
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/active-coupon'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Activeted Coupon</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/add-coupon'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Add Coupon</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/deactive-coupon'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Deactive Coupon</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/expired-coupon'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Expired Coupon</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              

              <li className='nav-item'>
                <NavLink to='#!' className='nav-link'>
                  <i class='fas fa-cog nav-icon'></i>
                  <p>
                    Home Settings
                    <i className='fas fa-angle-left right' />
                  </p>
                </NavLink>
                <ul className='nav nav-treeview'>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/header-top'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Header Top</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/header'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Header</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-slider'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Slider</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-mini-slider'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Mini Slider</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-mini-banner'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Mini Banner</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-special-service'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Special Service</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-featured-categories'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Featured Categoires</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-traditional'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Traditional</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-gift-item'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Gift Item</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/add-brand'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Brand</p>
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <NavLink
                      to='/our-dashboard/setting/footer'
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }>
                      <i className='far fa-circle nav-icon' />
                      <p>Footer</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default SidebarMenu;
