import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditHeaderSearchoptionSec = () => {
  // update header search option
  const [updateSearchOption, setupdateSearchOption] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(null);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  // const navigate = useNavigate();

  const { id } = useParams();
 
  // get data
  useEffect(() => {
    axios.get(`/setting/header-search-option/edit/${id}`).then((res) => {
      if (res.data.status === 200) {
        setupdateSearchOption(res.data.data);
      }
      setloading(false);
    });
  }, [rerendering]);

  // handleChangeImage header search option
  const SearchOptionHandleChange = (e) => {
    setupdateSearchOption({
      ...updateSearchOption,
      [e.target.name]: e.target.value,
    });
  };

  // SearchOptionHandleSubmit header search option
  const SearchOptionHandleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const data = updateSearchOption;
    axios
      .post(`/setting/header-search-option/update/${id}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setRerendering(res.data.message);
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  if (loading) {
    <h2>loading...</h2>;
  }
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='col-md-8 mx-auto'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h3 className='card-title'>Edit Header Search Option</h3>
            <div className='card-tools'>
              <Link
                to='/our-dashboard/setting/all-header-search-option'
                className='btn btn-success btn'>
                All Search Option
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <form
              className='form-horizontal'
              encType='multipart/form-data'
              onSubmit={SearchOptionHandleSubmit}>
              <div className='form-group col-md-12'>
                <label htmlFor='input-search-option'>
                  Add Header Search Option
                </label>
                <input
                  type='text'
                  name='search_option'
                  value={
                    updateSearchOption.search_option == null
                      ? ""
                      : updateSearchOption.search_option
                  }
                  onChange={SearchOptionHandleChange}
                  className='form-control'
                  id='input-search-option'
                  placeholder='Enter Search Option'
                />
                {validation_error && (
                  <span className='text-danger'>
                    {validation_error.search_option}
                  </span>
                )}
              </div>
              <div className='form-group row'>
                <div className='col-12'>
                  <button
                    type='submit'
                    class='btn btn-info btn-block w-75 mx-auto'
                    disabled={disableSubmitBtn}
                    >
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHeaderSearchoptionSec;
