import { useEffect, useState} from "react";
import axios from "axios";
import moment from 'moment'
import { MDBTable, MDBTableHead, MDBTableBody,
  MDBTooltip,
  MDBAccordion,
  MDBAccordionItem,MDBBtn,MDBBadge } from 'mdb-react-ui-kit';
import {Helmet} from "react-helmet";
import { MultiStepForm, Step } from 'react-multi-form'
import Pending from './orderStep/Pending';
import Comfirmed from './orderStep/Comfirmed';
import Processing from './orderStep/Processing';
import Picked from './orderStep/Picked';
import Shipped from './orderStep/Shipped';
import Delivered from './orderStep/Delivered';
import Cancled from './orderStep/Cancled';

const UserOrder = () => {

  const [order, setOrder] = useState([]);
  const [active, setActive] =useState(0)

  const [orderDetails, setOrderDetails] = useState([]);


  // get data
  useEffect(() => {
    let isMounted = true;
   
    const fetchData = async () => {
      try {
          const res = await axios.get("/profile/order");
          if(isMounted){
            if (res.data.status === 200) {
              setOrder(res.data.orders);
              if(res.data.orders?.length){
                setOrderDetails([res?.data?.orders[0]]);
                setActive(res?.data?.orders[0]?.status  + 1);
              }
            }
          }
      } catch (e) {
         console.log(e);
      }
    }

    fetchData();
     
    return () => {
      isMounted = false;
    }
    
  }, []);




const filterDetails = (id) => {
  var updatedList = order?.filter((item)=> item.id === id);
  setOrderDetails(updatedList);
  setActive(updatedList[0].status + 1);
  console.log(updatedList[0].status, updatedList[0].status + 1)
}






  return <>
          {/* seo title */}
            <Helmet>
            <title>Order || User</title>
            </Helmet>
              
         <div>
        <div className='all_orders p-2'>
        <h4 className='text-dark text-start fw-bold'>All Orders</h4>
        <MDBTable hover responsive>
      <MDBTableHead className='custom_bg text-white'>
        <tr>
          <th scope='col'>Order No</th>
          <th scope='col'>Order Date</th>
          <th scope='col'>Total</th>
          <th scope='col'>Status</th>
          <th scope='col'>View</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        
       {
        order?.map((val)=>{
          var totalPrice = 0;
          return (
            <>
              <tr >
                <MDBTooltip tag='th' title='Click to view order details'>
                  {val?.invoice_id}
                </MDBTooltip>
                <MDBTooltip tag='td' title='Click to view order details'>
                  {moment(val?.created_at).format("DD/MM/YYYY")}
                </MDBTooltip>
                <MDBTooltip tag='td' title='Click to view order details'>
                  {val?.order_items.map((item) => {
                    totalPrice += Number(item?.price) * item?.qty;
                  })}
                  ৳ {totalPrice}
                </MDBTooltip>
                <MDBTooltip tag='td' title='Click to view order details'>
                <MDBBadge color='success' pill>
                    {val.status === 0
                    ? "Pending"
                    : val.status === 1
                    ? "Comfirmed"
                    : val.status === 2
                    ? "Processing"
                    : val.status === 3
                    ? "Picked"
                    : val.status === 4
                    ? "Shipped"
                    : val.status === 5 
                    ? "Delivered"
                    : val.status === 6 ?
                     val.payment_mode === 'cash-on-delivery' && "Cancled"
                     : null
                    }
                 </MDBBadge>
                </MDBTooltip>
                <MDBTooltip tag='td' title='Click to view order details' >
                <MDBBtn color='link' rounded size='sm' onClick={() => { filterDetails(val.id) }}>
                <i class="text-success fas fa-eye"></i>
               </MDBBtn>
                </MDBTooltip>
              </tr>
            </>
          );
        })
       }
        
      
        
       
      </MDBTableBody>
    </MDBTable>
      </div>



      {/* view details */}
       <div className='order_multi_step my-2'>
       <MDBAccordion initialActive={1} alwaysOpen >
       <MDBAccordionItem
         collapseId={1}
        headerTitle=" Order Details:"
        className='h-auto'
        >
              {
                orderDetails.length > 0 && 
                <>
                 <div className='text-dark d-flex justify-content-between'>
        <p>ORDER: {orderDetails[0]?.invoice_id}</p>
        <p>{moment(orderDetails[0]?.created_at).format("DD/MM/YYYY")}</p>
      </div>
      <div className='mx-2'>
      {
        orderDetails[0]?.status === 6 ?
        orderDetails[0]?.payment_mode === 'cash-on-delivery' &&
      <MultiStepForm activeStep={1}>
         <Step label='Cancled'>
          <Cancled data={orderDetails[0]?.status === 6 && orderDetails} />
        </Step>
      </MultiStepForm>
      
        :
      <MultiStepForm activeStep={active}>
        <Step label='Pending'>
          <Pending data={orderDetails[0]?.status === 0 && orderDetails} />
        </Step>
        <Step label='Comfirmed'>
          <Comfirmed data={orderDetails[0]?.status === 1 && orderDetails} />
        </Step>
        <Step label='Processing'>
          <Processing data={orderDetails[0]?.status === 2 && orderDetails} />
        </Step>
        <Step label='Picked'>
          <Picked data={orderDetails[0]?.status === 3 && orderDetails} />
        </Step>
        <Step label='Shipped'>
          <Shipped data={orderDetails[0]?.status === 4 && orderDetails} />
        </Step>
        <Step label='Delivered'>
          <Delivered data={orderDetails[0]?.status === 5  && orderDetails} />
        </Step>
         
      </MultiStepForm>
      }
      </div>
                </>

              }

        </MDBAccordionItem>
      </MDBAccordion>
      </div>
      </div>
      
     
  </>;
};

export default UserOrder;
