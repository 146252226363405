import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import {Helmet} from "react-helmet";
import { AllRoleData } from "./Role_&_PermissionData";
const AllRoleSec = () => {
  useEffect(() => {
    // initialize datatable
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  });
  return (
    <>
              {/* seo title */}
        <Helmet>
        <title>All-Role || Role-Permission</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Role & Permission" pageTitle="All-Role" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr>
                  <th className="text-center">Role Name</th>
                  <th className="text-center">Permission</th>
                  <th className="text-center">Give Permission</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {AllRoleData.length === 0 ? (
                  <td
                    colSpan="12"
                    className="text-center bg-warning font-weight-bold h4"
                  >
                    No data available in table
                  </td>
                ) : (
                  <>
                    {AllRoleData.map((Value, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{Value.roleName}</td>
                          <td className="text-center">
                            <span className="bg-warning p-1 rounded">
                              {Value.Permissions}
                            </span>
                          </td>
                          <td className="text-center">
                            <Link
                              to={`/our-dashboard/admin-give-permission/${Value.id}`}
                              className="btn btn-info btn-sm"
                            >
                              Give Permission
                            </Link>
                          </td>
                          <td className="text-center">
                            <Link
                              to={`/our-dashboard/admin-edit-role/${Value.id}`}
                              className="btn btn-info btn-sm"
                            >
                              <i className="fas fa-edit pr-2" />
                              Edit
                            </Link>
                          </td>
                          <td className="text-center">
                            <a
                              className="btn btn-danger btn-sm"
                              data-toggle="modal"
                              data-target=".bd-example-modal-sm"
                              href="##"
                            >
                              <i className="fas fa-trash pr-2"></i>
                              Delete
                              <div
                                class="modal fade bd-example-modal-sm"
                                tabindex="-1"
                                role="dialog"
                                aria-labelledby="mySmallModalLabel"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-sm modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-body text-center">
                                      <i class="fas fa-times text-danger fa-3x"></i>
                                      <p className="mb-2 text-dark">
                                        <span className="font-weight-bold h4">
                                          Are you sure?
                                        </span>
                                        <br />
                                        Once deleted, you will not be able to
                                        recover this data!
                                      </p>
                                    </div>
                                    <div class="modal-footer border">
                                      <button
                                        type="button"
                                        class="btn btn-danger"
                                        data-dismiss="modal"
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        class="btn btn-outline-danger"
                                        data-dismiss="modal"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllRoleSec;
