import React from 'react'
import AddAdminSec from '../../../../Components/admin/Admin/AddAdminSec'

const AddAdmin = () => {
  return (
    <>
    <AddAdminSec/>
    </>
  )
}

export default AddAdmin