import React, {useContext} from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import SocialMedia from "../SocialMedia/SocialMedia";
import FooterTop from "./FooterTop/FooterTop";
import Footer_2ndSection_Data from "./Footer_2ndSection_Data";
import Footer_contact_usData from "./Footer_contact_usData";
import CopyRight from "./Footer_CopyRight/CopyRight";
import authContext from './../../auth/Context/AuthUser/authContext';


const Footer = () => {
  // auth login context
  const { Login, toggleShow} =useContext(authContext);

  return (
    <>
      <div className='footer'>
        <FooterTop />
        <div className='footer-body'>
          <div className='container'>
            <div className='row footer-section'>
              <div className='col-lg-3 col-md-3 col-10 mx-auto'>
                <h5 className='footer-title'>OurRajshahi.com</h5>
                <ul>
                   <li>
                    <Link className='hover_effect' to='/shop/arts-&-crafts'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Arts & Crafts
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/shop/electronics'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Electronics
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/shop/fashion'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Fashion
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/shop/food'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Food
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/shop/grocery'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Grocery
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/shop/gift-items'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Gift Items
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 col-md-3 col-10 mx-auto'>
                <h5 className='footer-title'>Popular Products </h5>
                <ul>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Rajshahi
                      Silk
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Mango
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; 3D
                      Wallpaper
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Grocery
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Honey
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Date Juice{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 col-md-3 col-10 mx-auto'>
                <h5 className='footer-title'> Important Links</h5>
                <ul>
                
                  {
                    Login ? 
                    <li>
                    <Link className='hover_effect' to='/user/profile'>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; Become a Seller
                    </Link>
                  </li>
                  : 
                  <li>
                    <a className='hover_effect' href='##'  onClick={toggleShow}>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; Become a Seller
                    </a>
                  </li>
                  }

                  {
                    Login ? 
                    <li>
                    <Link className='hover_effect' to='/user/profile'>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; My Account
                    </Link>
                  </li>
                  : 
                  <li>
                    <a className='hover_effect' href='##'  onClick={toggleShow}>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; My Account
                    </a>
                  </li>
                  }
                  <li>
                    <Link className='hover_effect' to=''>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; Popular Categories
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to=''>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp; Gift Package
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/branding-product'>
                      <i className='fas fa-chevron-right' target='_blank'></i>{" "}
                      &nbsp;Branding Products
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='hover_effect'
                      to='/payment-method'
                      target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Payment
                      Method
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-lg-3 col-md-3 col-10 mx-auto'>
                <h5 className='footer-title'>Company</h5>
                <ul>
                  <li>
                    <Link
                      className='hover_effect'
                      to='about-us'
                      target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='hover_effect'
                      to='/contact-us'
                      target='_blank'>
                      <i className='fas fa-chevron-right'></i>&nbsp; Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/career' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Career
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/blog' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Blog
                    </Link>
                  </li>
                  <li>
                    <Link className='hover_effect' to='/vlog' target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Vlog
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='hover_effect'
                      to='/gallery'
                      target='_blank'>
                      <i className='fas fa-chevron-right'></i> &nbsp; Gallery
                    </Link>
                  </li>
                </ul>
              </div>

              {Footer_2ndSection_Data.map((Value, index) => {
                return (
                  <div
                    className='col-lg-3 col-md-3 col-10 mx-auto mt-2'
                    key={index}>
                    <h5 className='footer-title'> {Value.footer_title} </h5>
                    <ul>
                      <li>
                        <Link className='hover_effect' to={Value.link_1}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_1}
                        </Link>
                      </li>
                      <li>
                        <Link className='hover_effect' to={Value.link_2}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_2}
                        </Link>
                      </li>
                      <li>
                        <Link className='hover_effect' to={Value.link_3}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_3}
                        </Link>
                      </li>
                      <li>
                        <Link className='hover_effect' to={Value.link_4}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_4}
                        </Link>
                      </li>
                      <li>
                        <Link className='hover_effect' to={Value.link_5}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_5}
                        </Link>
                      </li>
                      <li>
                        <Link className='hover_effect' to={Value.link_6}>
                          <i className='fas fa-chevron-right'></i> &nbsp;{" "}
                          {Value.footer_text_6}
                        </Link>
                      </li>
                    </ul>
                  </div>
                );
              })}
              {/* contact us */}
              <div className='col-lg-3 col-md-3 col-10 mx-auto mt-2'>
                <h5 className='footer-title contact-us-title'>
                  {Footer_contact_usData[0].title}
                </h5>
                {Footer_contact_usData.map((Value, index) => {
                  return (
                    <p key={index}>
                      <i className={Value.icon}></i> &nbsp; {Value.name}{" "}
                    </p>
                  );
                })}
                <span className='stay_connect'>
                  {Footer_contact_usData[0].stay_connected}
                </span>
                <SocialMedia />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </>
  );
};

export default Footer;
