export const topsearchData =[
    {
     pageTitle:"Top-Search",
    keyword:"i-phone",
    count:"1"
    },
    {
        keyword:"i-phone",
        count:"1"
    }
]

export const allsearchData =[
    {
    keyword:"i-phone",
    count:"1",
    user:"rashelislamsojib"
    },
    {
        keyword:"i-phone",
        count:"1",
        user:"rashelislamsojib"
    }
]