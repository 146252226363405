import React, {useState,useEffect} from "react";
import axios from 'axios'
import NewArrivalsSlider from "react-slick";
import "./NewArrivals.css";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const NewArrivals = (props) => {

//  get product data
const [newArrival, setNewArrival] = useState([]);
const [loading, setloading] = useState(true);


// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
    const res = await  axios.get("/new-arrival/all");
    if(isMounted){
      if (res.data.status === 200) {
        setNewArrival(res.data.new_arrivals);
       }
       setloading(false);
    }  

} catch (e) {
      console.log(e);
   }
    
 }
  
 fetchData();

  return () => {
    isMounted = false;
  }
}, []);






  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2500,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
        {/* seo meta tag  start */}
        {
          newArrival.map(val=>{
        <Helmet>
         <meta name='keywords' content={val?.meta_keyword} />
        <meta name='description' content={val?.meta_description} />
        <meta name='subject' content={val?.meta_description} />
        <meta name='topic' content={val?.meta_description} />
       

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={val?.product_name}  />
        <meta
          itemprop="description"
          content={val?.meta_description}
        />
        <meta
          itemProp='image'
          ontent={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={val?.meta_description}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={val?.meta_description} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
        />
        <meta
          property='og:image:alt'
          content={val?.product_name}
        />
        <meta
          name='og:description'
          content={val?.meta_description}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='val?.summary_large_image' />
        <meta
          name='twitter:title'
          content={val?.product_name} 
        />
        <meta
          name='twitter:description'
          content={val?.meta_description}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
        />
        <meta
          name='twitter:image:alt'
          content={val?.product_name}
        />
      </Helmet> 
          })
        }
        
      {/* seo meta tag  end */}




      <section className='new_arrivals py-2 overflow-hidden'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold py-2'>
            New Arrivals
          </h5>
          <div className='bg-white rounded'>
            <NewArrivalsSlider {...settings}>
            {loading ?
                  Array(5)
                  .fill()
                  .map((val, idx) => {
                    return (
                      <div key={idx}>
                          <ContentLoader
                            speed={3}
                            width='100%'
                            height='100%'
                            viewBox='0 0 200 85'
                            backgroundColor='#f5f5f5'
                            foregroundColor='#dbdbdb'
                            {...props}>
                            <rect
                              x='53'
                              y='16'
                              rx='3'
                              ry='3'
                              width='108'
                              height='48'
                            />
                          </ContentLoader>
                        </div>
                    );
                  }): 
                newArrival?.map((val, idx) => {
                return (
                    <div className="mx-auto mt-2" data-index={idx}
                      key={idx} style={{ width: 160}}>
                      <Link to={`/product/${val?.product_slug}`}>
                        <LazyLoadImage
                          effect="blur"
                          className='mx-auto img_fit'
                          placeholderSrc='./assest/image/loader/placeholder.jpg'
                          src={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                          alt='arrival'
                        />
                      </Link>
                    </div>
                );
              })
            }
            </NewArrivalsSlider>
          </div>
        </div>
      </section>

    </>
  );
};

export default NewArrivals;
