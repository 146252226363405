import React from 'react'
import "./AboutUs.css"
import PageBanner from "../../../Components/frontend/PageBanner/PageBanner";
import { MDBCard, MDBCardBody} from 'mdb-react-ui-kit';

const AboutUs = () => {
    return (
        <>
        <>
            <div className="about_page">
                <PageBanner
                 page_title="About US"
                />
            <h4 className='title text-center py-2'>Our Rajshahi</h4>
                <div className="container">

                    <div className="col-10 mx-auto text-center">
                       <MDBCard className='shadow-2-strong custom-box-shadow'>
                        <MDBCardBody>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam temporibus ratione id quas, minima provident, numquam laboriosam quia quae nesciunt ex sunt quos illo eos. Itaque iure vitae rerum est quos accusantium quas odio eligendi distinctio voluptatem quod delectus cupiditate nisi perferendis explicabo debitis, unde in deleniti nulla sequi recusandae maiores nihil fuga voluptatibus! Dolore et aperiam magni id, exercitationem cupiditate quaerat. Saepe doloremque, magnam corporis a molestias illo pariatur ab! Iste, sequi sint perspiciatis libero expedita atque commodi aliquam, impedit unde quae nesciunt distinctio tenetur necessitatibus corrupti temporibus vero ullam! Suscipit animi iusto aperiam aliquam reiciendis reprehenderit numquam delectus.
                        </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
            </div>
        </>
        </>
    )
}

export default AboutUs
