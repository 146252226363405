import React, { useState, useContext, useEffect } from "react";
import "./MainHeader.css";
import "./customSelect.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import ContentLoader from "react-content-loader"
import MobileMenu from "./MobileMenu/MobileMenu";
import { AiOutlineBars } from "react-icons/ai";
import { BsCart3 } from "react-icons/bs";
import {
  MDBInputGroup,
  MDBBtnGroup,
  MDBCard,
  MDBDropdown,
  MDBDropdownToggle,
  MDBBadge,
  MDBBtn 
} from "mdb-react-ui-kit";
import AllcateGories from "../ShopByDepartmentSection/AllcateGories";
import wishNoteContext from "../Context/Wishlist/wishNoteContext";
import cartNoteContext from "../Context/Cart/cartNoteContext";
import authContext from './../../auth/Context/AuthUser/authContext';
import axios from "axios";
import { toast } from "react-toastify";



const MainHeader = (props) => {
  const navigate = useNavigate();
  // auth login context
  const { Login, currentUser, userLogOut, isLoading, toggleShow } =useContext(authContext);
  //  wish Note Context 
  const { itemWish } = useContext(wishNoteContext);
 //  cart Note Context 
  const { itemCart} = useContext(cartNoteContext);


    
  //  get header logo data
  const [headerlogo, setHeaderlogo] = useState([]);
 //  get header search option  data
 const [headerSearchOption, setheaderSearchOption] = useState([]);
 const [showSuggested, setshowSuggested] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [search, setSearch] = useState({
    optionSearch: "all",
    inputSearch: "",
  });
    // category icon
    const [visible, setVisible] = useState(false);

  // get  search 
  const [productResult, setProductResult] = useState([]);
  const [serviceResult, setServiceResult] = useState([]);
  const [allResult, setAllResult] = useState([]);
  
  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const { optionSearch, inputSearch } = search;

  //  get header logo data
  useEffect(() => {
    try {
      fetchLogo();
      fetchSearchOption();
    } catch (e) {
       console.log(e);
    }
  }, []);


  const fetchLogo = async () => {
    const res = await axios.get("/setting/header-logo");
    if (res.data.status === 200) {
     setHeaderlogo(res.data.header_logo);
   }
 }

 const fetchSearchOption = async () => {
 const res = await axios.get("/setting/header-search-option");
  if (res.data.status === 200) {
   setheaderSearchOption(res.data.data);
 }
}

  useEffect(() => {
    window.$('#optionSearch').on('select2:select', function (e) {
      var data = e.params.data.text;
      setSearch({...search, optionSearch:data});
  });
  }, [optionSearch]);

  

 // search 
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try{
      if(inputSearch !== ''){
        await Promise.all([
          optionSearch.toLowerCase() === "product" ?
  
            axios.get(`/search/product/${inputSearch}`)
  
           : optionSearch.toLowerCase() === "service" ?
  
            axios.get(`/search/service/${inputSearch}`)
           :
            axios.get(`/search/all/${inputSearch}`),
            axios.get(`/search/product/${inputSearch}`),
            axios.get(`/search/service/${inputSearch}`),
      ]).then(axios.spread((all,product, service) => {
             if(isMounted){
              setAllResult(all.data.all)
              setProductResult(product.data.products)
              setServiceResult(service.data.service)
             }
         }))
      }
    }catch(e){
      console.log(e);
    }
    }

  fetchData();

    return () => {
      isMounted = false;
    }
    
  }, [inputSearch]);


  // window scroll header category icon show 
  useEffect(()=>{
    let isMounted = true;
    if(isMounted){
    window.onscroll = function(){
        let y = window.scrollY
      if (y > 850) {
        setVisible(true);
    } else {
      setVisible(false);
    }
    }
  }
    return () => {
      isMounted = false;
    }        
},[])







// logout user
const logout = () => {
  if(Login){
    axios
    .post("/logout")
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.removeItem("user_token");
        userLogOut();
        navigate('/');
        window.location.reload();
      }
    })
    .catch((err) => {});
  }
 
};
  
  // search handle submit 
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate({
      pathname:
      productResult?.some((val) =>
      val?.product_name.toLowerCase() || val?.product_code.toLowerCase().includes(inputSearch.toLowerCase())
        ) && optionSearch.toLowerCase() === "all"
          ? "/shop"
          : serviceResult?.some((val) =>
              val?.title
                .toLowerCase()
                .includes(inputSearch.toLowerCase())
            ) && optionSearch.toLowerCase() === "all"
          ? "/view-special-services"
          : optionSearch.toLowerCase() === "product"
          ? "/shop"
          : optionSearch.toLowerCase() === "service"
          ? "/view-special-services"
          : "/shop",
      search: createSearchParams({
        type: optionSearch,
        search: inputSearch.toLowerCase().replace(/ +/g, "-"),
      }).toString(),
    });

    setSearch({...search, inputSearch: ''});
    setshowSuggested(false);
  };



  return (
    <>
   

      <div className='header pt-2'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-2 col-md-2 col-3  mx-auto'>
              {/* logo */}
              <Link className='navbar-brand' to='/'>
              <LazyLoadImage 
            effect="blur" 
             src={`${process.env.REACT_APP_API_URL}${headerlogo?.logo}`}
             height='50'
             alt='OurRajshahi'
             className='desktop-logo ps-lg-3'
              />
              </Link>
              <Link className='navbar-brand' to='/'>
                <LazyLoadImage
                effect="blur" 
                  className='mobile-icon'
                  src='/assest/image/logo/ourrajshahi-icon.png'
                  height='60'
                  alt='MDB Logo'
                  loading='lazy'
                />
              </Link>
            </div>
            <div className='col-lg-1 col-md-1 mx-auto d-none d-xxl-block d-lg-block d-md-block pt-3 '>
              {
                visible &&
                <a className='header_category_wrapper text-info' href='##'>
                {/* window scroll header category icon show */}
                <div className='py-2 header-category-menu-btn ms-md-4 ms-lg-3'>
                  <MDBBtnGroup className='shadow-1 border' aria-label='Basic example'>
                    <AiOutlineBars style={{ fontSize: "25px" }} />
                    <i className='fas fa-sort-down'></i>
                  </MDBBtnGroup>
                </div>
                <div className='header_wrap'>
                  <AllcateGories />
                </div>
              </a>
              }
              
            </div>
            <div className='col-lg-6 col-md-6 col-9 mx-auto pt-2'>
              {/* search bar  header */}
              <div>
                <form  onSubmit={handleSubmit}>
                  <MDBInputGroup>
                    <div>
                    {headerSearchOption ?
                      <select
                       defaultValue='all'
                        required
                        id='optionSearch'
                        className='js-example-basic-single'
                        name='optionSearch'
                        style={{ width: "100%" }}>
                          <option value="all" selected="selected">
                              All
                            </option>
                        {headerSearchOption && headerSearchOption.map((val) => {
                          return (
                            <option value={val.search_option.toLowerCase()} key={val.id}>
                              {val.search_option}
                            </option>
                          );
                        })}
                      </select>: null
}
                    </div>
                    <input
                      className='form-control custom_search_input'
                      required
                      autoComplete='off'
                      type='text'
                      id='type'
                      placeholder=''
                      name='inputSearch'
                      onChange={handleChange}
                      value={inputSearch}
                      onFocus={() => setshowSuggested(true)}
                      onBlur={() => {
                        if (!isHovered) {
                          setshowSuggested(false);
                        }
                      }}
                    />

                      <MDBBtn 
                        type='submit'
                        className='search-camera d-none d-xxl-block d-lg-block d-md-block'
                        color='tertiary'
                        >
                        <LazyLoadImage
                          effect="blur" 
                          className='img-fluid'
                          style={{ width: "15px", height: "15px" }}
                          src={`${process.env.REACT_APP_API_URL}${headerlogo?.search_camera}`}
                          alt='camera'
                        />
                      </MDBBtn>
                      <MDBBtn  type='submit' className='search-submit'
                        color='danger'
                        >
                        <i
                          style={{ fontSize: "13px" }}
                          className='fas fa-search'></i>
                        &nbsp;Search
                      </MDBBtn>

                  </MDBInputGroup>
                </form>
              </div>
              {/* search auto suggested list  */}

              <div
                className='search_suggested_list rounded-bottom'
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}>
                <ul className='p-0'>
                  {/* search auto suggested list conditon base */}
                  {optionSearch.toLowerCase() === "all"
                    ? allResult?.filter((data) =>
                        data?.product_name || data?.title
                          .toLowerCase()
                          .includes(inputSearch.toLowerCase()) 
                      )?.map((val) => {
                        return inputSearch.length > 1 && showSuggested ? (
                          <>
                         {
                          val.product_name &&
                          <Link onClick={()=>  setshowSuggested(false)} className='text-dark' to={`/shop?type=${optionSearch}&search=${val.product_slug}`}>
                          <li
                            className='p-2 border-bottom'
                           >
                       {
                        val?.product_img && 
                        <img 
                        src={`${process.env.REACT_APP_API_URL}${val?.product_img}`} 
                        alt='products-img'
                        className='suggest_img me-2' />
                      }  
                      {val.product_name}
                          </li>
                          </Link>
                         }
                            {
                              val.title &&
                              <Link onClick={()=>  setshowSuggested(false)} className='text-dark' to={`/view-special-services?type=${optionSearch}&search=${val.slug}`}>
                            <li
                            className='p-2 border-bottom'
                           >

                              {
                                val?.image && 
                                <img 
                                src={`${process.env.REACT_APP_API_URL}${val?.image}`} 
                                alt='products-img'
                                className='suggest_img me-2' />
                              }
                            {val?.title}
                          </li>
                          </Link>
                            }
                          
                          </>
                          
                        ) : null;
                      })
                    : optionSearch.toLowerCase() === "product"
                    ? productResult
                        ?.filter((val) =>
                        val.product_name 
                            .toLowerCase()
                            .includes(inputSearch.toLowerCase())
                        )
                        ?.map((val) => {
                          return inputSearch.length > 1 && showSuggested ? (
                            <Link onClick={()=>  setshowSuggested(false)} className='text-dark' to={`/shop?type=${optionSearch}&search=${val.product_slug}`}>
                            <li
                            className='p-2 border-bottom'
                           >
                              {
                                val.product_img && 
                                <img 
                                src={`${process.env.REACT_APP_API_URL}${val.product_img}`} 
                                alt='products-img'
                                className='suggest_img me-2' />
                              }
                            {val.product_name}
                          </li>
                          </Link>                           
                          ) : null;
                        })
                    : optionSearch.toLowerCase() === "service"
                    ? serviceResult?.filter((data) =>
                        data.title
                          .toLowerCase()
                          .includes(inputSearch.toLowerCase())
                      )?.map((val) => {
                        return inputSearch.length > 1 && showSuggested ? (
                          <Link onClick={()=>  setshowSuggested(false)} className='text-dark' to={`/view-special-services?type=${optionSearch}&search=${val.slug}`}>
                          <li
                            className='p-2 border-bottom'
                           >
                              {
                                val.image && 
                                <img 
                                src={`${process.env.REACT_APP_API_URL}${val.image}`} 
                                alt='products-img'
                                className='suggest_img me-2' />
                              }
                            {val.title}
                          </li>
                          </Link>
                        ) : null;
                      })
                    : null}
                </ul>
              </div>
            </div>

            <div className='col-lg-3 col-md-3 mx-auto sm-hide'>
              <div className='cart wishlist login'>
                <ul className='d-flex justify-content-around axtra-menu pt-2'>
                  <>
                  {/* wish item   */}
                    <Link className='text-info' to='/wishlist'>
                      <MDBDropdown className='mt-2'>
                        <MDBDropdownToggle tag='div'>
                          <i
                            style={{ fontSize: "26px"}}
                            className='far fa-heart'></i>
                          <MDBBadge
                            className='mt-3'
                            color='danger'
                            notification
                            pill>
                            {itemWish.length}
                          </MDBBadge>
                        </MDBDropdownToggle>
                      </MDBDropdown>
                    </Link>
                  </>

                  <>
                  {/* cart item   */}
                    <Link className='text-info'  to='/cart'>
                      <MDBDropdown className='mt-2'>
                        <MDBDropdownToggle tag='div'>
                            <BsCart3 style={{ fontSize: "25px"}}  />
                            
                          <MDBBadge
                            className='mt-3'
                            color='danger'
                            notification
                            pill>
                            {itemCart.length}
                          </MDBBadge>
                        </MDBDropdownToggle>
                      </MDBDropdown>
                    </Link>
                  </>

                  {Login ? (
                    /* user login dropdown menu */
                    isLoading ? (
                      <ContentLoader 
                        speed={2}
                        width={130}
                        height={80}
                        viewBox="0 0 200 100"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                      >
                        <circle cx="39" cy="29" r="30" /> 
                        <rect x="74" y="5" rx="3" ry="3" width="108" height="9" /> 
                        <rect x="80" y="27" rx="3" ry="3" width="90" height="9" />
                      </ContentLoader>
                    ) : (
                      <>
                        <li className='axtra-menu-item axtra_menu_loging_account'>
                          <img
                            className='img-fluid  user_img me-3'
                            src={`${process.env.REACT_APP_API_URL}${currentUser?.profile_image}`}
                            alt='user'
                          />
                          &nbsp;
                          <p className='login-text mb-0'>
                            {currentUser?.name.slice(0, 12)}
                            &nbsp;
                            <i className='fas fa-sort-down fs-6 d-inline'></i>
                          </p>
                          <div className='login_hover_menu'>
                            <MDBCard className='border py-2'>
                              <div className='ps-1'>
                                {
                                currentUser?.usertype === "admin" ? (
                                  <div className='admin custom_hover pt-1 ps-2 border'>
                                    <Link to='/our-dashboard' target='_blank'>
                                      <p className='mb-0'>
                                        <i className='fas fa-user-edit pe-2'></i>
                                        &nbsp;Dashboard
                                      </p>
                                    </Link>
                                  </div>
                                ) : (
                                  <>
                                    <div className='user_profile custom_hover pt-1 ps-2 border'>
                                      <Link to='/user/profile'>
                                        <p className='mb-0'>
                                          <i className='fas fa-user-edit pe-2'></i>
                                          &nbsp;Profile
                                        </p>
                                      </Link>
                                    </div>
                                    <div className='user_cart custom_hover pt-1 ps-2 border'>
                                      <Link to='/user/order'>
                                        <p className='mb-0'>
                                        <i className='fas fa-box-open pe-2'></i>&nbsp;Orders
                                        </p>
                                      </Link>
                                    </div>
                                    <div className='user_store custom_hover pt-1 ps-2 border'>
                                      <Link to='/user/add-store'>
                                        <p className='mb-0'>
                                          <i class='fas fa-store pe-2'></i>
                                          &nbsp;Store
                                        </p>
                                      </Link>
                                    </div>

                                    <div className='user_change_password custom_hover pt-1 ps-2 border'>
                                      <Link  to='/user/change-password'>
                                        <p className='mb-0'>
                                          <i className='fas fa-unlock pe-2'></i>
                                          &nbsp;Change Password
                                        </p>
                                      </Link>
                                    </div>
                                    <div className='user_logout custom_hover pt-1 ps-2 border'>
                                      <a  href='##' onClick={logout}>
                                        <p className='mb-0'> 
                                          <i className='fas fa-power-off pe-2'></i>
                                          &nbsp;Logout
                                        </p>
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </MDBCard>
                          </div>
                        </li>
                      </>
                    )
                    )
                    :(
                      <>
                      <li className='axtra-menu-item axtra-menu-account'>
                      <a href="##" className='mt-2 text-dark  hover_effect' onClick={toggleShow}>
                       <i className='extra-icon far fa-user text-info'> </i> 
                       <p className='login-text pb-0'>Login</p>
                      </a>
                      </li>
                      </>
                    
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu usertype={currentUser?.usertype} profile_image={currentUser?.profile_image} />
    </>
  );
};

export default MainHeader;
