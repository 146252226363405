import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from './../../frontend/Loader/Loader';
const EditeGallerySec = () => {
  const { id } = useParams();

  const [update, setUpdate] = useState([]);

// image preview
  const [previewImage, setPreviewImage] = useState(null);
 
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  
   //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  
  // get data
  useEffect(() => {
    let isMounted = true;
 
      axios.get(`/gallery/edit/${id}`).then((res) => {
        if (isMounted) {
        if (res.data.status === 200) {
          setUpdate(res.data.gallery);
          setRerendering(!rerendering);
        }
        setloading(false);
      }
      });
    
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.files[0],
    });
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("title", update.title);
    formData.append("image", update.image);
    formData.append("img_alt", update.img_alt);
    axios
      .post(`/gallery/update/${update.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValidation_error({});
          setRerendering(!rerendering);
          setPreviewImage(null);
          setDisableSubmitBtn(false);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <Loader />;
  }


  return (
    <>
     {/* seo title */}
     <Helmet>
        <title> Edit || Gallery</title>
        </Helmet>
        
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Gallery" pageTitle="Edit-Gallery" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit-Gallery</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/all-gallery"
                    className="btn btn-success btn"
                  >
                    All Gallery
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={update.title}
                      className="form-control"
                      placeholder="Enter Title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }

                  
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Image</label>

                    <input
                    // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="customFile"
                    />
                  </div>

                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    previewImage &&
                    <img
                    alt='item-img'
                    width='60'
                    src={previewImage}
                  />
                   }
                   
                    {
                      !previewImage && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update?.image == null
                        ? null
                        :`${process.env.REACT_APP_BASEURL}${update?.image}`
                    }
                  />
                  }

                  <div className="form-group">
                    <label htmlFor="inputalt">Image Alt</label>
                    <input
                    required
                      type="text"
                      id="inputalt"
                      name="img_alt"
                      onChange={handleChange}
                      value={update.img_alt}
                      className="form-control"
                      placeholder="Enter Image Alt"
                    />
                  </div>
                  {
                    validation_error.img_alt &&
                    <span className='text-danger'>
                          {validation_error.img_alt}
                      <br />
                      </span>
                  }
                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditeGallerySec;
