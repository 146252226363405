import React, { useState, useContext } from "react";
import "./CheckOutSection.css";
import { Formik } from "formik";
import {useNavigate } from "react-router-dom";
import {
  MDBCard,
  MDBInputGroup,
  MDBBtn,
  MDBInput,
  MDBRadio,
  MDBCheckbox,
  MDBSpinner,
  MDBCollapse
} from "mdb-react-ui-kit";
import PaymentMethodSelectData from "./PaymentMethodSelectData";
import option from "./CityoptionData";
import OrderPlace from "./OrderPlace";
import {toast,Zoom } from "react-toastify";
import axios from "axios";
import cartNoteContext from "../Context/Cart/cartNoteContext";
import authContext from './../../auth/Context/AuthUser/authContext';
import {Helmet} from "react-helmet";
const CheckOutSection = () => {
  const { currentUser, userDetails, } =useContext(authContext);
  const {  itemCart, reRenderCartClear } = useContext(cartNoteContext);
  const [validation_error, setValidation_error] = useState([]);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const history = useNavigate();
  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);


  return (
    <>
     {/* seo title */}
     <Helmet>
       <title>Checkout</title>
       </Helmet>

      <div className="checkout_section bg-light py-4">
        <div className="container-lg container-fluid">
          <h5 className="section-title text-center fw-bold">Checkout</h5>
          
          <Formik
           enableReinitialize={true}
            initialValues={{
              billing_full_name: currentUser?.name ? currentUser?.name : '',
              billing_email: currentUser?.email ? currentUser?.email : '',
              billing_phone: currentUser?.phone ? currentUser?.phone : '',
              billing_full_address:  userDetails?.user_detail?.full_address ? userDetails?.user_detail?.full_address : '',
              billing_city: userDetails?.user_detail?.city ? userDetails?.user_detail?.city : '',
              billing_zip_code: userDetails?.user_detail?.zip_code ? userDetails?.user_detail?.zip_code : '',
              
              shipping_full_name: '',
              shipping_email: '',
              shipping_phone: '',
              shipping_full_address: '',
              shipping_city: '',
              shipping_zip_code: '',
              delivery: '',
              payment_mode: '',


            }}
            validate={(values) => {
              const errors = {};

              // ------  billing validation code    ------
              // fullName validate condition
              if (!values.billing_full_name) {
                errors.billing_full_name = "The billing full name field is required.";
              } else if (values.billing_full_name.length > 50) {
                errors.billing_full_name =
                  "The billing full name must not be greater than 50 characters.";
              } else if (!/^[a-zA-Z ]*$/i.test(values.billing_full_name)) {
                errors.billing_full_name = "The billing full name format is invalid.";
              }

              // Email validate condition
              if (!values.billing_email) {
                errors.billing_email = "The billing email field is required.";
              } else if (values.billing_email.length > 100) {
                errors.billing_email = "The billing email must not be greater than 50 characters.";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.billing_email
                )
              ) {
                errors.billing_email = "The billing email format is invalid.";
              }

              // phone validate condition
              if (!values.billing_phone) {
                errors.billing_phone = "The billing phone field is required.";
              } else if (values.billing_phone.length < 11) {
                errors.billing_phone =
                  "The billing phone must be at least 11 characters.";
              } else if (values.billing_phone.length > 15) {
                errors.billing_phone =
                  "The billing phone must not be greater than 50 characters.";
              } else if (!/^[0-9\b]+$/i.test(values.billing_phone)) {
                errors.billing_phone = "The billing phone format is invalid.";
              }
              // fulladdress validate condition
              if (!values.billing_full_address) {
                errors.billing_full_address = "The billing full address field is required.";
              } else if (values.billing_full_address.length > 50) {
                errors.billing_full_address =
                  "The billing full address must not be greater than 50 characters.";
              }

              // city validate condition
              if (!values.billing_city) {
                errors.billing_city = "The billing city field is required.";
              }

              // zipCode validate condition
              if (!values.billing_zip_code) {
                errors.billing_zip_code = "The billing zip code field is required.";
              } else if (values.billing_zip_code.length < 4) {
                errors.billing_zip_code =
                  "The billing zip code must be at least 4 characters.";
              } else if (!/^[0-9\b]+$/i.test(values.billing_zip_code)) {
                errors.billing_zip_code = "The billing zip format is invalid.";
              }

              // Delivery validate condition
              // if (!values.delivery) {
              //   errors.delivery = "Please Select Delivery";
              // }


              // payment_mode validate condition
              if (!values.payment_mode) {
                errors.payment_mode = "Please Select Payment Mode";
              }

              

              // ------  shipping validation code    ------
               // fullName validate condition
              if (!values.shipping_full_name) {
                errors.shipping_full_name = "The shipping full name field is required.";
              } else if (values.shipping_full_name.length > 50) {
                errors.shipping_full_name =
                  "The shipping full name must not be greater than 50 characters.";
              } else if (!/^[a-zA-Z ]*$/i.test(values.shipping_full_name)) {
                errors.shipping_full_name = "The shipping full name format is invalid.";
              }

              // Email validate condition
              if (!values.shipping_email) {
                errors.shipping_email = "The shipping email field is required.";
              } else if (values.shipping_email.length > 100) {
                errors.shipping_email = "The shipping email must not be greater than 50 characters.";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.shipping_email
                )
              ) {
                errors.shipping_email = "The shipping email format is invalid.";
              }

              // phone validate condition
              if (!values.shipping_phone) {
                errors.shipping_phone = "The shipping phone field is required.";
              } else if (values.shipping_phone.length < 11) {
                errors.shipping_phone =
                  "The shipping phone must be at least 11 characters.";
              } else if (values.shipping_phone.length > 15) {
                errors.shipping_phone =
                  "The shipping phone must not be greater than 50 characters.";
              } else if (!/^[0-9\b]+$/i.test(values.shipping_phone)) {
                errors.shipping_phone = "The shipping phone format is invalid.";
              }
              // fulladdress validate condition
              if (!values.shipping_full_address) {
                errors.shipping_full_address = "The shipping full address field is required.";
              } else if (values.shipping_full_address.length > 50) {
                errors.shipping_full_address =
                  "The shipping full address must not be greater than 50 characters.";
              }

              // city validate condition
              if (!values.shipping_city) {
                errors.shipping_city = "The shipping city field is required.";
              }

              // zipCode validate condition
              if (!values.shipping_zip_code) {
                errors.shipping_zip_code = "The shipping zip code field is required.";
              } else if (values.shipping_zip_code.length < 4) {
                errors.shipping_zip_code =
                  "The shipping zip code must be at least 4 characters.";
              } else if (!/^[0-9\b]+$/i.test(values.shipping_zip_code)) {
                errors.shipping_zip_code = "The shipping zip format is invalid.";
              }


              
              
              return errors;
            }}
            onSubmit={(values,  {resetForm}) => {
              setDisableSubmitBtn(true);
               const data = values;
               const {payment_mode}= data;
               if(payment_mode === 'bkash'){
                axios
              .post("/bkash/createpayment", data)
              .then((res) => {
                if (res.data.status === 200) {
                  window.location.replace(res.data.url);
                  setDisableSubmitBtn(false);
                }else if(res.data.status === 422)  {
                  setValidation_error(res.data.validation_errors);
                  setDisableSubmitBtn(false);
                }else if (res.data.status === 404) {
                  toast.error(res.data.message, {
                      position: "top-center",
                      theme: 'colored',
                      transition: Zoom,
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
              else if (res.data.status === 400) {
                toast.error(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setDisableSubmitBtn(false);
              })
              .catch((err) => {});
               }else if(payment_mode === 'nagad'){

                axios
                .post("/nagad/payment", data)
                .then((res) => {
                  if (res.data.status === 200) {
                    window.location.replace(res.data.url);
                    setDisableSubmitBtn(false);
                  }else if(res.data.status === 422)  {
                    setValidation_error(res.data.validation_errors);
                    setDisableSubmitBtn(false);
                  }else if (res.data.status === 404) {
                    toast.error(res.data.message, {
                        position: "top-center",
                        theme: 'colored',
                        transition: Zoom,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                else if (res.data.status === 400) {
                  toast.error(res.data.message, {
                      position: "top-center",
                      theme: 'colored',
                      transition: Zoom,
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
              setDisableSubmitBtn(false);
                })
                .catch((err) => {});
               
               }else if(payment_mode === 'cash-on-delivery'){
                axios
              .post("/order/cash-on-delivery", data)
              .then((res) => {
                if (res.data.status === 200) {
                  document.getElementById("resetForm").reset();
                  resetForm();
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 300,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  reRenderCartClear();
                  history("/user/order");
                  setDisableSubmitBtn(false);
                }else if(res.data.status === 422)  {
                  setValidation_error(res.data.validation_errors);
                  
                }else if (res.data.status === 404) {
                  toast.error(res.data.message, {
                      position: "top-center",
                      theme: 'colored',
                      transition: Zoom,
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
              else if (res.data.status === 400) {
                toast.error(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setDisableSubmitBtn(false);
               }).catch((err) => {});
              
            }
              
            }}
          >
            {(formik) => (
              <form id='resetForm' onSubmit={formik.handleSubmit} className='w-100'>
                <div className="row">
                  <div className="col-lg-7 col-md-7 col-10 mx-auto mt-2">
                    <MDBCard className="rounded bg-white pb-2" style={{ maxWidth: "40rem" }}>
                      <span className="fs-5 ps-4 fw-normal ">
                      Billing & Shipping details :
                      </span>
                      <div className="shipping_billing_form pt-3">
                        <div className="ps-4 billing_sec">
                          <div className="mt-1">
                            <label htmlFor="fullName">
                              Full Name <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup
                              className="w-100"
                              textBefore={<i class="fas fa-user"></i>}
                            >
                              <MDBInput
                                label="Full Name"
                                name="billing_full_name"
                                placeholder="Full Name *"
                                id="fullName"
                                type="text"
                                onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                value={formik.values.billing_full_name}
                                className={`form-control ${
                                  formik.errors.billing_full_name && formik.touched.billing_full_name 
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </MDBInputGroup>
                            {
                              formik.errors.billing_full_name && formik.touched.billing_full_name &&  (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_full_name}
                                </span>
                              )}
                          </div>
                          <div className="mt-2">
                            <label htmlFor="email">
                              Email <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup>
                              <span
                                className="input-group-text"
                                style={{ paddingLeft: "10px" }}
                              >
                                <i className="fas fa-envelope"></i>
                              </span>

                              <MDBInput
                                label="Email"
                                name="billing_email"
                                placeholder="Email *"
                                id="email"
                                type="email"
                                onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                value={formik.values.billing_email}
                                className={`form-control ${
                                  formik.errors.billing_email && formik.touched.billing_email 
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </MDBInputGroup>
                            {
                              formik.errors.billing_email && formik.touched.billing_email &&  (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_email}
                                </span>
                              )}
                          </div>
                          <div className="mt-2">
                            <label htmlFor="phone">
                              Phone <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup>
                              <span
                                className="input-group-text"
                                style={{ paddingLeft: "15px" }}
                              >
                                <i class="fas fa-mobile-alt"></i>
                              </span>

                              <MDBInput
                                label="Phone"
                                name="billing_phone"
                                placeholder="Phone *"
                                id="phone"
                                type="tel"
                                onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                value={formik.values.billing_phone}
                                className={`form-control ${
                                  formik.errors.billing_phone && formik.touched.billing_phone 
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </MDBInputGroup>
                            {
                              formik.errors.billing_phone && formik.touched.billing_phone &&  (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_phone}
                                </span>
                              )}
                          </div>
                          <div className="mt-2">
                            <label htmlFor="full-address">
                              Full Address{" "}
                              <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup
                              textBefore={<i class="fas fa-map-marker-alt"></i>}
                            >
                              <MDBInput
                                label="full-address"
                                name="billing_full_address"
                                placeholder="Full Address *"
                                id="full-address"
                                type="text"
                                onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                value={formik.values.billing_full_address}
                                className={`form-control ${
                                  formik.errors.billing_full_address && formik.touched.billing_full_address 
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </MDBInputGroup>
                            {
                              validation_error.billing_full_address ?
                              <span className="text-danger mt-1">
                              {validation_error.billing_full_address}
                            </span>
                              :
                              formik.errors.billing_full_address && formik.touched.billing_full_address &&  (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_full_address}
                                </span>
                              )}
                          </div>

                          

                        
                          <div className="mt-2">
                            <label htmlFor="city">
                              City <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup className='w-75'
                              textBefore={<i class="fas fa-building"></i>}
                            >
                            <select
                            className={`form-control ${
                                  formik.errors.billing_city && formik.touched.billing_city 
                                    ? "is-invalid"
                                    : ""
                                }`}
                            onChange={formik.handleChange}
                            name='billing_city'
                            value={formik.values.billing_city}
                            placeholder='Select Your City'
                           
                          >
                           
                      <option value='' disabled="disabled">--Select Your City--</option>
                      {option?.map((val)=>{
                        return (
                        <option value={val?.value}>{val.label}</option>
                        );
                      })}
                          </select>
                              
                            </MDBInputGroup>
                            {validation_error.billing_city ?
                            <span className="text-danger mt-1">
                            {  validation_error.billing_city}
                              </span>
                              :
                              formik.errors.billing_city && formik.touched.billing_city && (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_city}
                                </span>
                              )}
                          </div>
                          <div className="mt-2">
                            <label htmlFor="zip-code">
                              Zip Code <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup
                              textBefore={<i class="fas fa-file-archive"></i>}
                            >
                              <MDBInput
                                label="Zip Code"
                                name="billing_zip_code"
                                placeholder="Zip Code *"
                                id="zip-code"
                                type="text"
                                onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                value={formik.values.billing_zip_code}
                                className={`form-control ${
                                  formik.errors.billing_zip_code && formik.touched.billing_zip_code 
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </MDBInputGroup>
                            {validation_error.billing_zip_code ?
                            <span className="text-danger mt-1">
                            {  validation_error.billing_zip_code}
                              </span>
                              :
                              formik.errors.billing_zip_code && formik.touched.billing_zip_code &&  (
                                <span className="text-danger mt-1">
                                  {formik.errors.billing_zip_code}
                                </span>
                              )}
                          </div>

                          {/* ---- similar shipping address check ----- */}
                          <div className="my-4">
                            <MDBCheckbox
                              name="similarChecked"
                              id="flexCheckDefault"
                              label="My billing and shipping address are the same"
                              onChange={(e) => {
                                const { checked } = e.target;

                                if (checked) {
                                  // value set input &&  field touched error false 
                                 if(formik.values.billing_full_name !== ''){
                                  formik.setFieldValue("shipping_full_name", formik.values.billing_full_name);
                                  formik.setFieldTouched("shipping_full_name", false);
                                 }

                                 if(formik.values.billing_email !== ''){
                                  formik.setFieldValue("shipping_email", formik.values.billing_email);
                                  formik.setFieldTouched("shipping_email", false);
                                 }

                                 if(formik.values.billing_phone !== ''){
                                  formik.setFieldValue("shipping_phone", formik.values.billing_phone);
                                  formik.setFieldTouched("shipping_phone", false);
                                 }

                                 if(formik.values.billing_full_address !== ''){
                                  formik.setFieldValue("shipping_full_address", formik.values.billing_full_address);
                                  formik.setFieldTouched("shipping_full_address", false);
                                 }
                                          
                                 if(formik.values.billing_city !== ''){
                                  formik.setFieldValue("shipping_city", formik.values.billing_city);
                                  formik.setFieldTouched("shipping_city", false);
                                 }     
                                            
                                 if(formik.values.billing_zip_code !== ''){
                                  formik.setFieldValue("shipping_zip_code", formik.values.billing_zip_code);
                                  formik.setFieldTouched("shipping_zip_code", false);
                                 }     
                                }else{

                                  // field input empty
                                  formik.setFieldValue("shipping_full_name", '');
                                  formik.setFieldValue("shipping_email", '');
                                  formik.setFieldValue("shipping_phone", '');
                                  formik.setFieldValue("shipping_full_address", '');
                                  formik.setFieldValue("shipping_city",'');
                                  formik.setFieldValue("shipping_zip_code", '');

                                    //  field touched error true
                                           formik.setFieldTouched('shipping_full_name', true);
                                           formik.setFieldTouched('shipping_email', true);
                                            formik.setFieldTouched("shipping_email", true);
                                            formik.setFieldTouched("shipping_phone", true);
                                            formik.setFieldTouched("shipping_full_address", true);
                                            formik.setFieldTouched("shipping_city", true);
                                            formik.setFieldTouched("shipping_zip_code", true);


                                            // formik.touched.shipping_full_name:true
                                }
                                
                              }}
                            
                            />
                          </div>
                        </div>


                        {/* shipping addresss section */}
                        <div className="shipping_address mx-3">
                          
                             <button className={`accordion-button ${showShow && 'collapsed'}`} color='info' onClick={toggleShow}>Shipping Address </button>
                             <MDBCollapse show={showShow}>
                              {/* Shipping form start */}
                              <div className="mt-1">
                                <label htmlFor="fullName">
                                  Full Name{" "}
                                  <span className="text-danger mt-1">*</span>
                                </label>
                                <MDBInputGroup
                                  textBefore={<i class="fas fa-user"></i>}
                                >
                                  <MDBInput
                                    label="Full Name"
                                    name="shipping_full_name"
                                    placeholder="Full Name *"
                                    id="fullName"
                                    type="text"
                                    onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                    value={formik.values.shipping_full_name}
                                    className={`form-control ${
                                      formik.errors.shipping_full_name && formik.touched.shipping_full_name 
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                </MDBInputGroup>
                                {
                                validation_error.shipping_full_name ?
                            <span className="text-danger mt-1">
                            {  validation_error.shipping_full_name}
                              </span>
                                :
                                  formik.errors.shipping_full_name && formik.touched.shipping_full_name &&  (
                                    <span className="text-danger mt-1">
                                      {formik.errors.shipping_full_name}
                                    </span>
                                  )}
                              </div>
                              <div className="mt-2">
                                <label htmlFor="email">
                                  Email <span className="text-danger mt-1">*</span>
                                </label>
                                <MDBInputGroup>
                                  <span
                                    className="input-group-text"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <i className="fas fa-envelope"></i>
                                  </span>

                                  <MDBInput
                                    label="Email"
                                    name="shipping_email"
                                    placeholder="Email *"
                                    id="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                    value={formik.values.shipping_email}
                                    className={`form-control ${
                                      formik.errors.shipping_email && formik.touched.shipping_email 
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                </MDBInputGroup>
                                {
                                validation_error.shipping_email ?
                              <span className="text-danger mt-1">
                            {validation_error.shipping_email}
                              </span>
                                :
                                  formik.errors.shipping_email && formik.touched.shipping_email &&  (
                                    <span className="text-danger mt-1">
                                      {formik.errors.shipping_email}
                                    </span>
                                  )}
                              </div>
                              <div className="mt-2">
                                <label htmlFor="phone">
                                  Phone <span className="text-danger mt-1">*</span>
                                </label>
                                <MDBInputGroup>
                                  <span
                                    className="input-group-text"
                                    style={{ paddingLeft: "15px" }}
                                  >
                                    <i class="fas fa-mobile-alt"></i>
                                  </span>

                                  <MDBInput
                                    label="Phone"
                                    name="shipping_phone"
                                    placeholder="Phone *"
                                    id="phone"
                                    type="tel"
                                    onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                    value={formik.values.shipping_phone}
                                    className={`form-control ${
                                      formik.errors.shipping_phone && formik.touched.shipping_phone 
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                </MDBInputGroup>
                                { 
                                validation_error.shipping_phone ?
                              <span className="text-danger mt-1">
                            {validation_error.shipping_phone}
                              </span>
                                :
                                  formik.errors.shipping_phone && formik.touched.shipping_phone &&  (
                                    <span className="text-danger mt-1">
                                      {formik.errors.shipping_phone}
                                    </span>
                                  )}
                              </div>
                              <div className="mt-2">
                                <label htmlFor="full-address">
                                  Full Address{" "}
                                  <span className="text-danger mt-1">*</span>
                                </label>
                                <MDBInputGroup
                                  textBefore={
                                    <i class="fas fa-map-marker-alt"></i>
                                  }
                                >
                                  <MDBInput
                                    label="full-address"
                                    name="shipping_full_address"
                                    placeholder="Full Address *"
                                    id="full-address"
                                    type="text"
                                    onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                    value={formik.values.shipping_full_address}
                                    className={`form-control ${
                                      formik.errors.shipping_full_address && formik.touched.shipping_full_address 
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                </MDBInputGroup>
                                {
                                  validation_error.shipping_full_address ?
                                  <span className="text-danger mt-1">
                                {validation_error.shipping_full_address}
                                  </span>
                                    :
                                  formik.errors.shipping_full_address && formik.touched.shipping_full_address &&  (
                                    <span className="text-danger mt-1">
                                      {formik.errors.shipping_full_address}
                                    </span>
                                  )}
                              </div>
                              
                          <div className="mt-2">
                            <label htmlFor="city">
                              City <span className="text-danger mt-1">*</span>
                            </label>
                            <MDBInputGroup className='w-75'
                              textBefore={<i class="fas fa-building"></i>}
                            >
                            <select
                            className={`form-control ${
                                  formik.errors.shipping_city && formik.touched.shipping_city 
                                    ? "is-invalid"
                                    : ""
                                }`}
                            onChange={formik.handleChange}
                            name='shipping_city'
                            value={formik.values.shipping_city}
                            placeholder='Select Your City'
                           
                          >
                           
                      <option value='' disabled="disabled">--Select Your City--</option>
                      {option?.map((val)=>{
                        return (
                        <option value={val?.value}>{val.label}</option>
                        );
                      })}
                          </select>
                              
                            </MDBInputGroup>
                            {validation_error.shipping_city ?
                            <span className="text-danger mt-1">
                            {  validation_error.shipping_city}
                              </span>
                              :
                              formik.errors.shipping_city && formik.touched.shipping_city && (
                                <span className="text-danger mt-1">
                                  {formik.errors.shipping_city}
                                </span>
                              )}
                          </div>
                              <div className="mt-2">
                                <label htmlFor="zip-code">
                                  Zip Code{" "}
                                  <span className="text-danger mt-1">*</span>
                                </label>
                                <MDBInputGroup
                                  textBefore={
                                    <i class="fas fa-file-archive"></i>
                                  }
                                >
                                  <MDBInput
                                    label="Zip Code"
                                    name="shipping_zip_code"
                                    placeholder="Zip Code *"
                                    id="zip-code"
                                    type="text"
                                    onChange={formik.handleChange}
                                     onBlur={formik.handleBlur}
                                    value={ formik.values.shipping_zip_code}
                                    className={`form-control ${
                                      formik.errors.shipping_zip_code && formik.touched.shipping_zip_code 
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                </MDBInputGroup>
                                {
                                    
                                    validation_error.shipping_zip_code ?
                                    <span className="text-danger mt-1">
                                  {validation_error.shipping_zip_code}
                                    </span>
                                      :
                                  formik.errors.shipping_zip_code && formik.touched.shipping_zip_code &&  (
                                    <span className="text-danger mt-1">
                                      {formik.errors.shipping_zip_code}
                                    </span>
                                  )}
                              </div>
                            
                          </MDBCollapse>
                        </div>
                      </div>
                    </MDBCard>
                  </div>
                  <div className="col-lg-5 col-md-5 col-10 mx-auto mt-2">
                    <MDBCard className="bg-white rounded px-3 pb-4 pt-2">
                      {/* order place  */}
                      <OrderPlace />

                      <div className="my-3">
                        <label htmlFor="zip-code">
                          {" "}
                          Select a label for effective delivery:
                        </label>
                        <MDBInputGroup>
                          <MDBRadio
                            inline
                            name="delivery"
                            id="Home"
                            label="Home"
                             onBlur={formik.handleBlur}
                            value="Home"
                            onChange={formik.handleChange}
                            checked={formik.values.delivery === "Home"}
                          />
                          <MDBRadio
                            name="delivery"
                            id="Office"
                            label="Office"
                             onBlur={formik.handleBlur}
                            value="Office"
                            onChange={formik.handleChange}
                            checked={formik.values.delivery === "Office"}
                          />
                          <MDBRadio
                            name="delivery"
                            id="Relative"
                            label="Relative"
                             onBlur={formik.handleBlur}
                            value="Relative"
                            onChange={formik.handleChange}
                            checked={formik.values.delivery === "Relative"}
                          />
                          <MDBRadio
                            name="delivery"
                            id="Friend"
                            label="Friend"
                             onBlur={formik.handleBlur}
                            value="Friend"
                            onChange={formik.handleChange}
                            checked={formik.values.delivery === "Friend"}
                          />
                          <MDBRadio
                            name="delivery"
                            id="Others"
                            label="Others"
                             onBlur={formik.handleBlur}
                            value="Others"
                            onChange={formik.handleChange}
                            checked={formik.values.delivery === "Others"}
                          />
                        </MDBInputGroup>
                      </div>

                      <div className="checkout_payment-wrap">
                        <span className="total fs-6 ">
                          Select Payment Methods :
                        </span>

                        <div className="py-2  ps-1">
                          {PaymentMethodSelectData.map((val, i) => {
                            return (
                              <div
                                className="form-check pe-3 d-inline"
                                key={i}
                              >
                                <label htmlFor={val.text}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="payment_mode"
                                     onBlur={formik.handleBlur}
                                    value={val.text}
                                    id={val.text}
                                    onChange={formik.handleChange}
                                    checked={formik.values.payment_mode === val.text}
                                  />
                                  <img
                                    className="payment_img"
                                    id={val.text}
                                    src={val.payment_img}
                                    alt={val.text}
                                  />
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {
                        itemCart.length ?

                        <MDBBtn
                        disabled={disableSubmitBtn}
                        type="submit"
                        className="m-1 text-center w-100"
                        size="lg"
                        color='info'
                        onClick={ () =>
                          formik.errors.shipping_full_name || formik.errors.shipping_email  || formik.errors.shipping_email || formik.errors.shipping_phone || formik.errors.shipping_full_address || formik.errors.shipping_city || formik.errors.shipping_zip_code ?
                          setShowShow(true)
                          : setShowShow(false)
                        }
                        
                      >
                        Place Order
                        {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
                      </MDBBtn>

                       : 
                      <span className="text-danger mt-1 fw-bold">
                      Cart Empty Please Add to Cart
                      </span> 

                     } 
                     
                    </MDBCard>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      

  
    </>
  );
};

export default CheckOutSection;

