import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import "./ShopByDepartmentSection.css";
import axios from "axios";

const AllcateGories = () => {
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
    try {
      const res = await axios.get("/shop-by-department");
      if(isMounted){
        if (res.data.status === 200) {
          setAllCategories(res.data.allcategories);
        }
      }
       
    } catch (e) {
       console.log(e);
    }
  }
  fetchData();
  return () => {
    isMounted = false;
  }
  }, []);


 
  




  return (
    <>
      <ul className='shop_by_department_menu bg-white shadow-2'>
        {allCategories && allCategories?.map((cate) => {
          return (
            <li className='menu-item mobile-menu-item' key={cate.id}>
              <Link className='d-inline' to={`/shop/${cate.category_slug}`}>
                <img
                  style={{ height: "18px", width: "18px" }}
                  className='item_img'
                  src={`${process.env.REACT_APP_API_URL}${cate.item_img}`}
                  alt='item_img'
                />{" "}
                &nbsp;{cate.category_name}
              </Link>
              <div className='sub-catagory sub-catagory-md sub-category-mobile'>
                <div className='container-fluid'>
                  <div className='row'>
                    {cate.subcategory?.map((sub)=>{
                      return(
                        <div className='col-lg-4 col-md-6 col-10 mx-auto' key={sub.id}>
                        <ul className='sub-menu mobile-sub-menu'>
                          <Link to={`/shop/${cate.category_slug}/${sub.sub_category_slug}`} className='sub-title mobile-sub-title pt-1' >
                            {sub.sub_category_name}
                          </Link>
                          {sub.sos_category?.map((sos) => {
                            return (
                              <li
                                className='sub-menu-item mobile-sub-menu-item'
                                key={sos.id}>
                                <Link
                                  to={`/shop/${cate.category_slug}/${sub.sub_category_slug}/${sos.sub_of_soscategory_slug}`}>
                                  {sos.sub_of_soscategory_name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      )
                    })}
                  
                    
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AllcateGories;
