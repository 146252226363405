import React from 'react'
import HeaderSec from '../../../../Components/admin/Settings/Header/HeaderSec'

const HeaderSetting = () => {
  return (
    <>
    <HeaderSec/>
    </>
  )
}

export default HeaderSetting