//Footer_2ndSection_Data
const Footer_2ndSection_Data = [
  {
    // 1st col
    footer_title: "Support",
    footer_text_1: "FAQ",
    link_1: "/faq",
    footer_text_2: "How to Buy",
    link_2: "/how-to-buy",
    footer_text_3: "Help",
    link_3: "/help",
    footer_text_4: "Forum",
    link_4: "/forum",
    footer_text_5: "Documentary",
    link_5: "/documentary",
    footer_text_6: "E-Magazine",
    link_6: "/e-magazine",
  },
  {
    // 2nd col
    footer_title: "Legal",
    footer_text_1: "Privacy Policy",
    link_1: "/privacy-policy",
    footer_text_2: "Return Policy",
    link_2: "/return-policy",
    footer_text_3: "Terms & Conditions",
    link_3: "/terms-and-conditions",
    footer_text_4: "Terms of Service",
    link_4: "/terms-of-service",
    footer_text_5: "Terms of Use",
    link_5: "/terms-of-use",
    footer_text_6: "Copyright",
    link_6: "/copy-right",
  },
  {
    //3rd col
    footer_title: "Services",
    footer_text_1: "Home Delivery",
    link_1: "/view-special-services/home-delivery",
    footer_text_2: "Daily Bazar",
    link_2: "/view-special-services/Meal Bazar",
    footer_text_3: "Community Tourism",
    link_3: "/community-tourism",
    footer_text_4: "Electrician",
    link_4: "/view-special-services/electrician",
    footer_text_5: "Daily Worker Manage",
    link_5: "/view-special-services/daily-worker-manage",
    footer_text_6: "Laundry",
    link_6: "/view-special-services/laundry",
  },
];
export default Footer_2ndSection_Data;
