import React from "react";

const Loader = () => {
  return <>
       <div className='text-center mx-auto'>
        <img src="/assest/image/loader/spinner.gif"  alt='loading'></img>

       </div>
  </>;
};

export default Loader;
