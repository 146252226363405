import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {toast,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MDBBtn,MDBSpinner } from "mdb-react-ui-kit";
import authContext from './../../auth/Context/AuthUser/authContext';
import axios from "axios";
import SocialLogin from "./SocialLogin";
const Login = (props) => {
  const { userLogin } = useContext(authContext);
  const [validation_error, setValidation_error] = useState([]);
  //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  // form validition
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // password show hide
  const [passwordShown, setpasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setpasswordShown(passwordShown ? false : true);
  };
 
  const email = localStorage.getItem("email_phone_or_username");
  const password = localStorage.getItem("password");

  
useEffect(() => {
  let defaultValues = {};
  if (email !== null || password !== null) {
  defaultValues.email_phone_or_username = email;
  defaultValues.password = password;
  reset({ ...defaultValues });
  }
}, []);


  

  // form submit data
  const onSubmit_login = (data,e) => {
       e.preventDefault();
    console.log(data)
    setDisableSubmitBtn(true);
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("/login", data)
        .then((res) => {
          if (res.data.status === 200) {
            if(data.check){
              localStorage.setItem("email_phone_or_username", data.email_phone_or_username);
              localStorage.setItem("password", data.password);
            }else {
              localStorage.setItem("email_phone_or_username", '');
              localStorage.setItem("password", '');
            }
            reset();
            toast.info(res.data.message, {
              position: "top-right",
              theme: 'colored',
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            userLogin();
            localStorage.setItem("user_token", res.data.access_token);
            setValidation_error();
          }else if(res.data.status === 400){
              toast.success(res.data.message, {
                position: "top-center",
                theme: 'colored',
                transition: Zoom,
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              localStorage.setItem("email_verify", res.data.email);
              props.handleEmailVerify(true, res.data.email);
              setDisableSubmitBtn(false);
          }else if(res.data.status === 401){
            toast.success(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            window.location.reload();
        } else {
            setValidation_error(res.data.validation_errors);
            setDisableSubmitBtn(false);
          }
        })
        .catch((err) => {
     
        });
    });
  };

  const handleforgotPassword = ()=>{
    props.handleforgotPassword(true);
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit_login)}>
        {/* email, phone, userName Input */}
        <div>
          <div className='input-group'>
            <input
              type='text'
              name='email_phone_or_username'
              id='email_phone_or_username'
              placeholder='Email, phone or username'
              className='form-control'
              {...register("email_phone_or_username", {
                required: true,
              })}
            />
            <label
              className='input-group-text'
              htmlFor='email_phone_or_username'>
              <i className='fas fa-user'></i>
            </label>
          </div>
          {/* errors will return when field validation fails  */}
          {validation_error.email_phone_or_username ? (
            <span className='text-danger'>
              {validation_error.email_phone_or_username}
            </span>
          ) : (
            errors.email_phone_or_username && (
              <span className='text-danger'>
                {errors.email_phone_or_username.type === "required" &&
                  "Input Your Email, Phone or Username"}
              </span>
            )
          )}
        </div>
        {/* <!-- Password input --> */}
        <div>
          <div className='input-group mt-3'>
            <input
              type={passwordShown ? "text" : "password"}
              id='loginPassword'
              name='password'
              placeholder='Password'
              className='form-control'
              {...register("password", {
                required: true,
                minLength: 8,
                maxLength: 256,
              })}
            />
            <label className='input-group-text' htmlFor='loginPassword'>
              {passwordShown ? (
                <i
                  className='fas fa-lock-open'
                  onClick={togglePasswordVisiblity}></i>
              ) : (
                <i
                  className='fas fa-lock'
                  onClick={togglePasswordVisiblity}></i>
              )}
            </label>
          </div>
          {/* errors will return when field validation fails  */}
          {validation_error && 
            <span className='text-danger'>
             {validation_error} 
            </span>
            }
          {validation_error.password ? (
            <span className='text-danger'>
             {validation_error.password} 
            </span>
          ) : (
            errors.password && (
              <span className='text-danger'>
                {errors.password.type === "minLength" &&
                  "Password minimum length of 8 characters "}
                {errors.password.type === "maxLength" &&
                  "Password maximum length of 256 characters"}
              </span>
            )
          )}
        </div>
        <div className='row pt-3'>
          <div className='col-md-6 d-flex justify-content-center'>
            {/* <!-- Checked checkbox --> */}
            <div class='form-check'>
              <input
                class='form-check-input'
                type='checkbox'
                value=''
                name='check'
                id='remember'
                {...register("check", {
                  required: true,
                })}
              />
              <label class='form-check-label' htmlFor='remember'>
                Remember Me{" "}
              </label>
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-center'>
            {/* <!-- Simple link --> */}
            <Link className='p' to='##' onClick={handleforgotPassword}  >
              Forgot Password?
            </Link>
          </div>
        </div>

        <div>
          <MDBBtn
            type='submit'
            rounded
            className='login-submit-btn btn btn-danger btn-rounded btn-block mb-2 mt-2 mx-auto'
            color='danger'
            disabled={disableSubmitBtn}
            >
            Login 
            {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
          </MDBBtn>
        </div>
        <div className='text-center'>
          <p>
            Not a member? <a href='#!'>Register</a>
          </p>
        </div>
        {/* social sign in */}
        <div className='text-center social'>
          <p style={{ marginBottom: "2px" }}>Login with Social Account</p>
          <SocialLogin />
        </div>
      </form>
    </>
  );
};

export default Login;
