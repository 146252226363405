import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import {toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from "../../frontend/Loader/Loader";

const AllProductSec = () => {
  //  get product data
 const [product, setProduct] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;

   axios.get("/product/all").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setProduct(res.data.products);
     }
     setloading(false);
    }
   }).catch((e) => {});
  
  return () => {
    isMounted = false
  }
 }, []);
 
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


// status active or deactive 
const updateStatus = (id) => {
const formData = new FormData();
formData.append("status", 1);
axios
.post(`/product/update_status/${id}`, formData)
.then((res) => {
  if (res.data.status === 200) {
    toast.success(res.data.message, {
      position: "top-center",
      theme: 'colored',
      transition: Zoom,
      autoClose: 400,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

        setProduct(
          product.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

const handleDeleteItems = (id) => {
  axios
    .delete(`/product/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setProduct(
          product.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>
}
  return (
    <>
          {/* seo title */}
          <Helmet>
        <title> All || Product</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Product" pageTitle="All / Product" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
                  <Link
                    to='/our-dashboard/product/draft'
                    className='btn btn-danger btn float-right'>
                    Draft Product
                  </Link>
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr >
                <th  >
                  SL
                  </th>
                  <th >
                   Image
                  </th>
                  <th >
                   Name
                  </th>
                  <th >
                  Category 
                  </th>
                  <th >
                  price
                  </th>
                  <th >
                  Status
                  </th>
                  <th >
                  Action
                  </th>
                  <th >
                    View
                  </th>
                  <th >
                    Edit
                  </th>
                  <th >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {product && product.map((val) => {
                 
                    return (
                      <tr key={val.id}>
                        <td class="dtr-control">{val.id}</td>
                       
                        <td>
                          <ul className='list-inline'>
                            <li className='list-inline-item'>
                              <img
                                alt='product_img'
                                width='50'
                                src={
                                  val.product_img == null
                                    ? null
                                    : `${process.env.REACT_APP_API_URL}${val.product_img}`
                                }
                              />
                            </li>
                          </ul>
                        </td>
                        <td>{val.product_name}</td>
                        <td>{val.category?.category_name}</td>
                        <td>{val.selling_price}</td>
                        <td><span className="p-1 rounded bg-success">
                         {val.status == 0 ? "Actived" : "Deactived"} 
                        </span></td>
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(val.id);
                                      }}
                                      >
                          Deatived Now
                        </span>
                      </td>
                        <td>
                          <Link
                            to={`/our-dashboard/view/product/${val.id}`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/our-dashboard/edit/product/${val.id}`}
                            className="btn btn-info btn-sm"
                          >
                            <i className="fas fa-edit pr-2" />
                            Edit
                          </Link>
                        </td>
                        <td>
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllProductSec;
