import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { Link } from "react-router-dom";
import Loader from './../../frontend/Loader/Loader';
import axios from "axios";
import {Helmet} from "react-helmet";

const MonthlySell = () => {
 //  get data
 const [data, setData] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;

   axios.get("/accounts/monthly-sell").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
       setData(res.data.data);
     }
     setloading(false);
    }
   }).catch((e) => {});
  
  return () => {
    isMounted = false
  }
 }, []);
 
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


const {orders, total_amount } = data;

if (loading) {
  return <Loader />;
}
  return (
    <>
           {/* seo title */}
       <Helmet>
       <title> All || Monthly-Sell</title>
       </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Accounts" pageTitle="All / Monthly-Sell" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr >
                <th  >
                  SI
                  </th>
                  <th >
                   Invoice ID
                  </th>
                  <th >
                   Name
                  </th>
                  <th >
                  Email
                  </th>
                  <th>
                   Payment Mode
                  </th>
                  <th >
                  Order Status 
                  </th>
                  <th >
                   Amount
                  </th>
                  <th >
                   Charge
                 </th>
                  <th >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders && orders?.map((val) => {
                  var  totalPrice = 0
                 const {user, order_items}=val;
                    return (
                      <tr key={val.id}>
                        <td class="dtr-control">{val.id}</td>
                        <td>{val.invoice_id}</td>
                        <td>
                           {user?.name}
                        </td>
                        <td>
                           {user?.email}
                        </td>
                        <td>
                         <span className='text-success'>
                         {
                         val?.payment_mode
                       }
                       </span>
                       </td>
                         <td><span className={val.status === 5 && 'p-1 rounded bg-success'}>
                         {
                         val.status === 5 && 'Delivered'
                          }
                       </span></td>
                       {
                           order_items?.map((item)=>{
                             totalPrice += Number(item.price) * item.qty
                             
                           })

                        }
                        <td>৳ {totalPrice}</td>
                        
                        <td></td>
                       <td>
                          <Link
                            to={`/our-dashboard/view/order/${val.id}`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
              <tfoot>
                 <tr>
                   <th></th>
                   <th></th>
                   <th></th>
                   <th></th>
                   <th></th>
                   <th>Total</th>
                   <th>৳ {total_amount}</th>
                   <th></th>
                   <th></th>
                 </tr>
                 </tfoot>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  )
};

export default MonthlySell;
