import React from 'react'
import "./ScrollToTop.css"
import ScrollToTop from "react-scroll-to-top";

const ScrollToTopSection = () => {
    return (
        <>
             <ScrollToTop smooth top="500" component={<p style={{ color: "#ff4747", paddingTop:"5px", fontSize:"20px" }}><i class="fas fa-chevron-up"></i></p>} />
        </>
    )
}

export default ScrollToTopSection
