import React from 'react'
import ProfileSec from '../../../Components/admin/ProfileSection/ProfileSec'

const Profile = () => {
  return (
    <>
    <ProfileSec/>
    </>
  )
}

export default Profile