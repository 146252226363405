import React,{useState,useEffect} from 'react'
import axios from "axios";
import "./HowtoBuy.css"
import { MDBCard, MDBCardBody} from 'mdb-react-ui-kit';
import Helmet from "react-helmet"

const HowtoBuy = (props) => {

    const [how_to_buy, setHow_to_buy] = useState([])

  

// get data
useEffect(() => {
  let isMounted = true;
  props.onTopProgress(30);
  const fetchData = async () => {
    try {
    const res = await axios.get("/how-to-buy");
    if(isMounted){
      if (res.data.status === 200) {
        setHow_to_buy(res.data.how_to_buy);
        props.onTopProgress(70);
      }
      props.onTopProgress(100);
    }  
   } catch (e) {
      console.log(e);
   }
    
 }


 fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);



    return (
        <>
          {/* seo meta tag  start */}
      <Helmet>
        ‍<title>How to Buy</title>
         <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content={how_to_buy} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />




         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content={how_to_buy}
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={how_to_buy}
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content={how_to_buy}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content={how_to_buy}
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
      </Helmet> 
      {/* seo meta tag  end */}


        <div className="how_to_buy_section py-4 bg-light overflow-hidden">
        <div className="container">
                <h4 className='title text-center py-2'> How to Buy </h4>
                    <div className="row">
                        <div className="col-12">
                        <MDBCard className='shadow-1'>
                        <MDBCardBody>
                            <div dangerouslySetInnerHTML={{ __html: how_to_buy == null ?
                             ""
                            :how_to_buy && how_to_buy}} />
                            </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </div>
        </div>
            
        </>
    )
}

export default HowtoBuy
