import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';


const AllCommentblog = () => {
 //  get blog_comment data
 const [blog_comment, setBlog_comment] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;
   
   axios.get("/blog/post/comment/all").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setBlog_comment(res.data.blog_comment);
     }
     setloading(false);
    }
   }).catch(error => {});
  
  return () => {
    isMounted = false
  }
 }, []);
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


// status active or deactive 
const updateStatus = (id) => {
const formData = new FormData();
formData.append("is_active", 1);
axios
.post(`/blog/post/comment/update_status/${id}`, formData)
.then((res) => {
  if (res.data.status === 200) {
    toast.info(res.data.message, {
      position: "top-right",
      autoClose: 700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

        setBlog_comment(
          blog_comment.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

const handleDeleteItems = (id) => {
  axios
    .delete(`/blog/post/comment/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setBlog_comment(
          blog_comment.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>
}
  return (
    <>
      {/* seo title */}
      <Helmet>
        <title>All || Blog-Comment</title>
      </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Comments" pageTitle="All / blog-comments" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
                  <Link
                    to='/our-dashboard/admin/blog/comment/draft'
                    className='btn btn-danger btn float-right'>
                    Draft Comment
                  </Link>
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr >
                <th  >
                  SL
                  </th>
                  <th >
                   Comment
                  </th>
                  <th >
                   User
                  </th>
                  <th >
                  Post 
                  </th>
                  <th >
                   Status
                  </th>
                  <th >
                  Action
                  </th>
                  <th >
                    View
                  </th>
                  {/* <th >
                    Edit
                  </th> */}
                  <th >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {blog_comment && blog_comment.map((comment) => {
                 
                    return (
                      <tr key={comment.id}>
                        <td class="dtr-control">{comment.id}</td>
                       
                        <td>{comment.comment_body}</td>
                        <td>{comment.user?.name}</td>
                        <td>{comment.blog_post?.heading}</td>
                        <td><span className="p-1 rounded bg-success">
                         {comment.is_active == 0 ? "Actived" : "Deactived"} 
                        </span></td>
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(comment.id);
                                      }}
                                      >
                          Deatived Now
                        </span>
                      </td>
                        <td>
                          <Link
                            to={`/our-dashboard/admin/blog/commment/view-reply/${comment.id}`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            Reply
                          </Link>
                        </td>

                        <td>
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${comment.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${comment.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(comment.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllCommentblog;
