import React, { useReducer, useContext, useEffect } from "react";
import { reducer } from "./wishlistReducer";
import NoteContext from "./wishNoteContext";
import {useNavigate} from "react-router-dom";
// import { WishlistitemData } from "./Wishlist_itemData";
import axios from "axios";
import authContext from '../../../auth/Context/AuthUser/authContext';
import { ToastContainer, toast,Zoom } from "react-toastify";

const initialState = {
  itemWish: [],
  totalAmountWish: 0,
  totalItemWish: 0,
  isLoading: true,
  isRendering:true,
  wishSubmitting:false,
};
const WishlistStateDataProvider = ({ children }) => {
  const { Login } = useContext(authContext);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  // fetch WISH item data
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (Login) {
          dispatch({
            type: "FETCH_WISH_STARTED",
            payload: {
              loading: true,
            },
          });
          const res = await axios.get("/view-wish");
          if (isMounted) {
            if (res.data.status === 200) {
              dispatch({
                type: "FETCH_WISH_SUCCESS",
                payload: {
                  data: res.data.wish,
                },
              });
            }
            dispatch({
              type: "FETCH_WISH_END",
              payload: {
                loading: false,
              },
            });
          }
        }else{
          dispatch({
            type: "FETCH_WISH_END",
            payload: {
              loading: false,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [state.isRendering, Login]);

  // add to cart
  const addToBasketWish = async (id, color_id, size, qty) => {
    if (Login) {
      let data = {
        product_id: id,
        color_id: color_id,
        size: size,
        qty_count: qty,
      };
      dispatch({
        type: "BUTTON_DISABLED",
        payload: {
          submitting: true,
        },
      });
      await axios
        .post(`/add-to-wish`, data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "ADD_TO_BASKET_WISH",
              payload: {
                rendering: !state.isRendering,
              },
            });

            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          } else if (res.data.status === 401) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          } else if (res.data.status === 404) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          } else if (res.data.status === 409) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          } else if (res.data.status === 422) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          }
        })
        .catch((err) => {
          navigate("/", { replace: true });
        });
    }
  };

  //btn click remove item
  const removeItemWish = async (id, product_id) => {
    if (Login) {
      let data = {
        product_id: product_id,
      };
      dispatch({
        type: "BUTTON_DISABLED",
        payload: {
          submitting: true,
        },
      });
      await axios
        .post(`/remove-wish-item/${id}`, data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({
              type: "REMOVE_ITEM_WISH",
              payload: id,
            });
            dispatch({
              type: "BUTTON_DISABLED",
              payload: {
                submitting: false,
              },
            });
          }
        })
        .catch((err) => {});
    }
  };

  // decrement the item
  const decrementWish = async (cart_id) => {
    if (Login) {
      await axios
        .put(`/wish-decrement-quantity/${cart_id}`)
        .then((res) => {
          if (res.data.status === 200) {
            dispatch({
              type: "DECREMENT_WISH",
              payload: cart_id,
            });
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.data.status === 404) {
            toast.warn(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {});
    }
  };

  // increment the item
  const incrementWish = async (cart_id) => {
    if (Login) {
      await axios
        .put(`/wish-increment-quantity/${cart_id}`)
        .then((res) => {
          if (res.data.status === 200) {
            dispatch({
              type: "INCREMENT_WISH",
              payload: cart_id,
            });
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (res.data.status === 404) {
            toast.warn(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {});
    }
  };

  // clear the cart
  const clearWish = async () => {
    if (Login) {
      await axios
        .delete("/clear-cart")
        .then((res) => {
          if (res.data.status === 200) {
            dispatch({ type: "CLEAR_CART" });
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {});
    }
  };


  // we will use the useEffect to update the data
  useEffect(() => {
    dispatch({ type: "GET_TOTAL_WISH" });
    // console.log("Awesome");
  }, [state.itemWish]);



  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NoteContext.Provider
        value={{
          ...state,
          addToBasketWish,
          removeItemWish,
          clearWish,
          decrementWish,
          incrementWish,
        }}>
        {children}
      </NoteContext.Provider>
    </>
  );
};

export default WishlistStateDataProvider;
