const MessageData = [
    {
     allNotification:"3 Notification",
     item:"4 New Message",
     min:"2 mins",
     id:"0"
    },
    {
     item:"4 New Message",
     min:"2 mins",
     id:"0"
    },
    {
        item:"4 New Message",
        min:"2 mins",
        id:"0"
    },
       
    
]
export default MessageData