import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import CheckBox from "./CheckBox";
import LogoLoader from '../Loader/LogoLoader';
import ServicesCheckData from "./ServiceCheckData";
import ProductsCard from "../Products_Card/ProductsCard";
import ShopByDepartmentSection from "../ShopByDepartmentSection/ShopByDepartmentSection";
import GridView from './GridView';
import { RatingCheckData } from "./RattingCheckData";
import { GiCheckMark } from "react-icons/gi";
import { MDBRange, MDBTooltip } from "mdb-react-ui-kit";
import ContentLoader from "react-content-loader"
import axios from "axios";
import Helmet from "react-helmet";

const ShopSec = ({product, loading}) => {

  // grid views
  const [grid, setGrid] = useState(false);
  const [sortBy, setsortBy] = useState({});
  const [brand, setBrand] = useState([]);
  const [color, setColor] = useState([]);
  const [service, setservice] = useState(ServicesCheckData);
  const [rating, setrating] = useState(RatingCheckData);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [showfilter, setshowfilter] = useState(true);



// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try{
      await Promise.all([
        axios.get('/shop-brand'),
        axios.get('/shop-color')
    ]).then(axios.spread((brand,color) => {
           if(isMounted){
            setBrand(brand.data.brands);
            setColor(color.data.colors);
           }
       }))
    }catch(e){
      console.log(e);
    }
    }

  fetchData();

    return () => {
      isMounted = false;
    }
    
  }, []);


  const handleChangeSortBy = (e) => {
    setsortBy(e.target.value);
  };

  const handlchangeBrand = (id) => {
    const brandStateList = brand;
    const changeCheckedBrand = brandStateList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setBrand(changeCheckedBrand);
  };


  const handlchangeColor = (id) => {
    const ColorStateList = color;
    const changeCheckedColor = ColorStateList.map((item) =>
      item?.id === id ? { ...item, checked: !item.checked } : item
    );
    setColor(changeCheckedColor);
  };

  const handlchangeService = (id) => {
    const serviceStateList = service;
    const changeCheckedService = serviceStateList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setservice(changeCheckedService);
  };

  const handlchangeRating = (id) => {
    const ratingStateList = rating;
    const changeCheckedRating = ratingStateList.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setrating(changeCheckedRating);
  };

  const handleChangePrice = (e) => {
    setSelectedPrice(e.target.value);
  };



  
  const applyFilters = () => {
    let updatedList = product;

    // price Filter
    if (selectedPrice) {
    updatedList = updatedList?.filter((list) => 
       list.selling_price > parseInt(selectedPrice, 0)
    )
    }


   // sort by filter
   if(sortBy !== ""){
    updatedList = updatedList?.sort((a, b) =>
    sortBy === 'Price Low to High'
      ? a.selling_price - b.selling_price
      : sortBy === 'Price High to Low'
      ? b.selling_price - a.selling_price
      : sortBy === 'Best Match'
      ? a.id - b.id
      : null
  )
   }

    // brand Filter
    const brandChecked = brand
      .filter((item) => item.checked)
      .map((item) => item?.id.toString());

    if (brandChecked.length) {
      updatedList = updatedList?.filter((item) =>
     brandChecked.includes(item?.brand_id.toString())
     );
    }

    // multipul color Filter with array product_colors
    const colorChecked = color
      ?.filter((item) => item.checked)
      .map((item) => item?.id.toString());

    if (colorChecked.length) {
      updatedList = updatedList?.filter(({product_colors}) => {
        return(
          product_colors?.some((color) => colorChecked.includes(color?.color_id.toString()))
        );
    });
    }
    

    // // service filter
    // const serviceChecked = service
    //   .filter((item) => item.checked)
    //   .map((item) => item.label.toLocaleLowerCase());
    // if (serviceChecked.length) {
    //   updatedList = updatedList.filter((item) =>
    //     serviceChecked.includes(item.service)
    //   );
    // }


    // // ratting filter
    const ratingChecked = rating
      .filter((item) => item.checked)
      .map((item) => item.rating);
    if (ratingChecked.length) {
      updatedList = updatedList.filter(({average_rating}) => {
        return(
          ratingChecked <= average_rating
        );
      });
        
    }

    return updatedList;
    
  };





  const filterClear = () => {

// brand
    const brandChecked = brand.map((item) => item.checked === true  ? { ...item, checked: !item.checked } : item);
    setBrand(brandChecked);

// color
    const colorChecked = color.map((item) =>item.checked === true  ? { ...item, checked: !item.checked } : item);
    setColor(colorChecked);

    setservice(ServicesCheckData);
    setrating(RatingCheckData);
    setsortBy({});
    setSelectedPrice(0);
  };

  



  
  return (
    <>

    {/* seo meta tag  start */}
    <Helmet>
        ‍<title>Shop</title>
        <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />

         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
      </Helmet> 
      {/* seo meta tag  end */}



    <ShopByDepartmentSection/>
      <div className='shop-section py-4 bg-light'>
        <div className='container-lg bg-white py-3'>
          <div className='row square border-bottom pb-1 '>
            <div className='col-lg-6 col-md-6 col-10 mx-auto'>
              <div className='d-flex sm-d-block'>
                <div className='related-category d-none d-xxl-block d-lg-block d-md-block'>
                  <h5>Related Categories</h5>
                  <Link to={`/shop/${product[0]?.category?.category_name}`}>
                    <span className='text-info'>{product[0]?.category?.category_name}</span>
                  </Link>
                </div>

                <div className='latest-product ms-md-5'>
                  <h5>{product[0]?.category?.category_name}</h5>
                  <span>{product.length} items found in {product[0]?.category?.category_name}</span>
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-10 mx-auto pt-1'>
              <div className='mobile_filter_btn text-center ms-5'>
                <span
                  className='p-2 custom_bg rounded'
                  onClick={() => setshowfilter(!showfilter)}>
                  <MDBTooltip
                    tag='span'
                    placement='right'
                    title={`${showfilter ? "show " : "hide "}filters`}>
                    {" "}
                    {showfilter ? "show " : "hide "}
                    filters
                    <i class='text-danger fas fa-filter'></i>
                  </MDBTooltip>
                </span>
              </div>
              <div className='d-none d-xxl-block d-lg-block d-md-block'>
                <div className='sort-by d-flex justify-content-end '>
                  <span className='pt-1'>Sort By:</span>
                  <div className='d-inline ms-2'>
                    <form className='d-inline' action=''>
                      <select
                        value={sortBy}
                        onChange={handleChangeSortBy}
                        className='sort_select'>
                        <option value='Best Match'>Best Match</option>
                        <option value='Price Low to High'>
                          Price Low to High
                        </option>
                        <option value='Price High to Low'>
                          Price High to Low
                        </option>
                      </select>
                    </form>
                  </div>

                  <div className='views  ml-3'>
                    <span>&nbsp;&nbsp;View:</span>
                    <span className={`ms-2 ${grid ? null :  "setopacity" }`}>
                      <i
                        class='fas fa-th-large'
                        onClick={() => setGrid(false)}></i>
                    </span>
                    &nbsp;
                    <span className={`ms-2 ${grid ? "setopacity"  :  null }`}>
                      
                      <i
                        class='fas fa-list'
                        onClick={() => setGrid(true)}></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div
              className={`col-lg-2 col-md-3 square border-end left-side ${
                showfilter ? "custom_mobile_hide" : "custom_mobile_show"
              } `}>


                 {
                  loading ?
                  <>
                    <ContentLoader 
                    speed={2}
                    width='100%'
                    height='100%'
                    viewBox="0 0 220 699"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    // {...props}
                  >
                    <rect x="21" y="14" rx="3" ry="3" width="145" height="9" /> 
                    <rect x="52" y="43" rx="3" ry="3" width="114" height="10" /> 
                    <rect x="28" y="35" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="23" y="92" rx="3" ry="3" width="88" height="12" /> 
                    <rect x="57" y="123" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="23" y="118" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="22" y="143" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="58" y="149" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="23" y="170" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="59" y="176" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="24" y="200" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="58" y="209" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="26" y="260" rx="3" ry="3" width="88" height="12" /> 
                    <rect x="29" y="287" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="65" y="292" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="29" y="314" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="65" y="319" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="29" y="342" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="66" y="347" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="31" y="370" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="67" y="374" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="31" y="434" rx="3" ry="3" width="88" height="12" /> 
                    <rect x="34" y="456" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="69" y="460" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="34" y="484" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="69" y="488" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="34" y="513" rx="11" ry="11" width="22" height="23" /> 
                    <rect x="70" y="515" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="29" y="547" rx="3" ry="3" width="152" height="7" /> 
                    <rect x="30" y="565" rx="3" ry="3" width="152" height="7" /> 
                    <rect x="30" y="594" rx="3" ry="3" width="88" height="12" /> 
                    <rect x="33" y="614" rx="4" ry="4" width="22" height="18" /> 
                    <rect x="67" y="618" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="33" y="639" rx="4" ry="4" width="22" height="18" /> 
                    <rect x="69" y="643" rx="3" ry="3" width="106" height="11" /> 
                    <rect x="34" y="664" rx="4" ry="4" width="22" height="18" /> 
                    <rect x="70" y="667" rx="3" ry="3" width="106" height="11" />
                  </ContentLoader>
                  </>
                :
                <>
                <div className='prices-range pt-2'>
                <span
                  className='mobile_filter_hide text-end '
                  style={{ cursor: "pointer" }}
                  onClick={() => setshowfilter(!showfilter)}>
                  <i className='bg-danger  text-white py-1 px-2 rounded-circle fas fa-times'></i>
                </span>
                {/* filter Clear conditon */}
                {brand.some((item) => item.checked === true) ? (
                  <div className='filter_by_clear '>
                    <span>
                      Filtered By:&nbsp;&nbsp;
                      <span
                        className='text-info ms-2 p-1 rounded border'
                        onClick={filterClear}>
                        CLEAR ALL&nbsp;<i class='fas fa-times'></i>
                      </span>
                    </span>
                  </div>
                ) : color.some((item) => item.checked === true) ? (
                  <div className='filter_by_clear '>
                    <span>
                      Filtered By:&nbsp;&nbsp;
                      <span
                        className='text-info ms-2 p-1 rounded border'
                        onClick={filterClear}>
                        CLEAR ALL&nbsp;<i class='fas fa-times'></i>
                      </span>
                    </span>
                  </div>
                ) : service.some((item) => item.checked === true) ? (
                  <div className='filter_by_clear '>
                    <span>
                      Filtered By:&nbsp;&nbsp;
                      <span
                        className='text-info ms-2 p-1 rounded border'
                        onClick={filterClear}>
                        CLEAR ALL&nbsp;<i class='fas fa-times'></i>
                      </span>
                    </span>
                  </div>
                ) : rating.some((item) => item.checked === true) ? (
                  <div className='filter_by_clear '>
                    <span>
                      Filtered By:&nbsp;&nbsp;
                      <span
                        className='text-info ms-2 p-1 rounded border'
                        onClick={filterClear}>
                        CLEAR ALL&nbsp;<i class='fas fa-times'></i>
                      </span>
                    </span>
                  </div>
                ) : selectedPrice > 10 ? (
                  <div className='filter_by_clear '>
                    <span>
                      Filtered By:&nbsp;&nbsp;
                      <span
                        className='text-info ms-2 p-1 rounded border'
                        onClick={filterClear}>
                        CLEAR ALL&nbsp;<i class='fas fa-times'></i>
                      </span>
                    </span>
                  </div>
                ) : null}

                <h5 className='py-2'>Prices Range</h5>
                {/* price range */}

                <MDBRange min={5} max={100000}   onChange={handleChangePrice} />
              </div>
              <div className='product-brand pt-3'>
                <h5>Brand</h5>
                <div className='brand-check'>
                  {/* <!-- brand checkbox --> */}
                  {brand?.map((val) => {
                    return (
                      <CheckBox
                        label={val?.brand_name}
                        id={val?.id}
                        checked={val?.checked}
                        handleChange={handlchangeBrand}
                        key={val?.id}
                      />
                    );
                  })}
                  <span>
                    <Link to=''>View more</Link>
                  </span>
                </div>
              </div>
              <div className='product-color pt-3'>
                <h5>Color Family</h5>
                <div className='color-check'>
                  {/* <!-- color checkbox --> */}
                  {
                        color?.map((val)=>{
                          return(
                            <CheckBox
                            label={val?.color_name}
                            id={val?.id}
                            checked={val?.checked}
                            handleChange={handlchangeColor}
                            key={val?.id}
                          />
                          
                        )
                        })
                     }
                  <span>
                    <Link to=''>View more</Link>
                  </span>
                </div>
              </div>
              <div className='product-services pt-3'>
                <h5>Services</h5>
                <div className='services-check'>
                  {/* <!-- Services checkbox --> */}
                  {service.map((val) => {
                    return (
                      <CheckBox
                        label={val.label}
                        id={val.id}
                        checked={val.checked}
                        handleChange={handlchangeService}
                        key={val.id}
                      />
                    );
                  })}
                  <span>
                    <Link to=''>View more</Link>
                  </span>
                </div>
              </div>

              <div className='product-ratting pt-3'>
                <h5>Rating</h5>
                {rating.map((val) => {
                  return (
                    <div>
                      <input
                        type='checkbox'
                        className='btn-check'
                        id={val.id}
                        checked={val.checked}
                        onChange={() => handlchangeRating(val.id)}
                        val={val.rating}
                        key={val.id}
                      />
                      <label
                        className='btn btn-none py-1 px-3 mt-1'
                        for={val.id}>
                        {val.icon.map((val) => {
                          return <i className={val.icon}></i>;
                        })}
                        {val.checked && 
                          <GiCheckMark className='text-info ms-3' />
                        }
                      </label>
                    </div>
                  );
                })}
              </div>
                </>
                 }
            </div>
            
            <div className='col-lg-10 col-md-9'>
              <div
                className='row'>
              {loading ?
              Array(12)
                .fill()
                .map((val, idx) => {
                  return (
                    <>
                <div className='col-lg-3 col-md-4 col-10 mx-auto mt-4'   key={idx}>
                  <LogoLoader />
            </div>
            </>
            )})
            
            : !grid ? (
                  applyFilters()
                    ?.map((val) => {
                      return (
                        <div className='col-lg-3 col-md-4 col-10 mx-auto mt-4' key={val.id}>
                          <ProductsCard  {...val} />
                        </div>
                      );
                    })
                ) : (
                  <div className='col-lg-12 col-md-12 col-12 mx-auto'>
                    { applyFilters()?.map((val) => {
                            return (

                              <GridView {...val} />
                              
                            );
                          })
                       }
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopSec;

