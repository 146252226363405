import React from 'react'
import './PageBanner.css'

const Pagebanner = (props) => {
    return (
        <>
            <div className="page_banner overflow-hidden">
            <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mx-auto ">
                        <div className='banner_wrapper'>
                        <img src="assest/image/banar/contact_us.jpg" className='contact-banner d-block mx-auto' alt="" />
                        <div className="custom-banner-text text-center py-4">                           
                            <p>{props.page_title}</p>
                        </div>
                        </div>   
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pagebanner
