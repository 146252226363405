import React from 'react'
import BrandingProductSection from '../../../Components/frontend/BrandingProductSection/BrandingProductSection'

const BrandingProduct = () => {
  return (
    <>
    <BrandingProductSection/>
    </>
  )
}

export default BrandingProduct