import "./App.css";
import React from "react";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {toast,Zoom} from 'react-toastify';
import LoadingBar from 'react-top-loading-bar'
import authContext from './Components/auth/Context/AuthUser/authContext';
// routes data pass
import { frontendRoutes } from "./Components/frontend/routes/Frontendroutes";
import { adminRoutes } from "./Components/admin/routes/adminRoutes";
// common routes 
import ScrollToTopSection from "./Components/frontend/Scroll_To_Top_Section/ScrollToTop";
import DefaultScrollRoute from "./Components/frontend/Scroll_To_Top_Section/DefaultScrollRoute";
import HomeLayouts from './Components/frontend/Layouts/HomeLayouts';
import BlogLayouts from './Components/frontend/Layouts/BlogLayouts';
import HomePage from './Pages/frontend/Home/HomePage';
import Chatbot from "./Components/frontend/Chatbot_UI/Chatbot";
import Preloader from "./Components/frontend/Preloader/Preloader";
import UserOrder from './Components/frontend/UserProfileSection/UserOrder';
import UserProfileForm from './Components/frontend/UserProfileSection/ProfileForm';
import UserChangePasswordForm from './Components/frontend/UserProfileSection/ChangePasswordForm';
import UserAddStore from './Components/frontend/UserProfileSection/Store/AddStore';
import {PrivateUser}  from "./Components/frontend/Private/Private";
import CommingSoonPopUp from "./Components/frontend/CommingSoonPopUp/CommingSoonPopUp";
import Home from "./Pages/admin/Home/Home";
import PrivateDashboard from "./Components/admin/Private/Private";
import BlogIndex from './Components/frontend/BlogSection/BlogIndex';
import BlogSingleCategory from './Components/frontend/BlogSection/Blog_Single_Category/Blog_Single_Category';
import BlogCategoryWithSubcats from './Components/frontend/BlogSection/Blog_Category_with_Subcats/Blog_Category_with_Subcats';
import BlogSinglePost from './Components/frontend/BlogSection/BlogSinglePost/BlogSinglePost';

import BadRequest from './Pages/frontend/Errors/400';
import Unauthorized from './Pages/frontend/Errors/401';
import Forbidden from './Pages/frontend/Errors/403';
import InternalServerError from './Pages/frontend/Errors/505';
import NotFound from './Pages/frontend/Errors/404';




// axios setup
const BACKEND_APP_API_URL= process.env.REACT_APP_API_KEY
axios.defaults.withCredentials = true;
axios.defaults.baseURL = BACKEND_APP_API_URL;
// axios.defaults.timeout = 18000;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  "POST, GET, OPTIONS, PUT, PATCH, DELETE";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("user_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});


function App() {

    const { userLogOut} = useContext(authContext);
    const history =useNavigate();
    // Online or Offline
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [progress, setProgress] = useState(0)
    const onTopProgress = (val)=>{
      setProgress(val);
    }
    useEffect(() => {
      // Update network status
      const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
      };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);
        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);
        // Specify how to clean up after this effect for performance improvment
        return () => {
          window.removeEventListener('online', handleStatusChange);
          window.removeEventListener('offline', handleStatusChange);
        };
      }, [isOnline]);


        !isOnline &&
        toast.error('You Are Offline', {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
 
  
      // axios response errore responsive 
        axios.interceptors.response.use(
          function async (response) {
            return response;
          },
          function (error) {
            switch (error.response.status) {
              case 400:
                history("/BadRequest");
                break;
                case 401:
                history("/Unauthorized");
                localStorage.removeItem("user_token");
                userLogOut();
                window.location.reload();
                break;
              case 403:
                history("/Forbidden");
                break;
              case 404:
                history("/NotFound");
                break;
              case 500:
                history("/InternalServerError");
                break;
              default:
              history("/NotFound");
                break;
            }
            return Promise.reject(error.response);
          }
        );
  



     
      
    return (
      <>
        <div className='App'>
          
        <LoadingBar
        color='#344D67'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        // height='2'
      />
       {/* <Preloader /> */}
          {/* <CommingSoonPopUp /> */}
          <ScrollToTopSection />
          <DefaultScrollRoute />
        
          <Routes>
         
                {/* ----home---- */}
            <Route
              path='/'
              element={<HomeLayouts />}>
              <Route index path='/' element={<HomePage />} />
              {frontendRoutes?.map(({ path, Component }, index) => {
                return <Route   path={path} key={index} element={<Component onTopProgress={onTopProgress} />}/>;
              })
              }

              <Route path='/user' element={<PrivateUser  />}>
                <Route path='profile' element={<UserProfileForm />} />
                <Route path='add-store' element={<UserAddStore />} />
                <Route path='change-password' element={<UserChangePasswordForm />} />
                <Route path='order' element={<UserOrder />} />
            </Route> 

              {/* -----blog----*/}
            <Route
                  path='/blog'
                  element={<BlogLayouts />}>
                  <Route index path='/blog' element={<BlogIndex />} />
                  <Route path='category/:category' element={<BlogSingleCategory />} />
                  <Route path='category/:category/:subcats' element={<BlogCategoryWithSubcats />} />
                  <Route path='post/:slug' element={<BlogSinglePost />} />
           </Route>  
                
          </Route> 
          {/* ----dashboard---- */}
            <Route
                  path='/our-dashboard'
                  element={<PrivateDashboard />}>
                  <Route index path='/our-dashboard' element={<Home />} />
                  {adminRoutes?.map(({ path, component }, index) => {
                      return (
                        <Route
                          key={index}
                          path={path}
                          element={component}
                        />
                      );
                    })}
           </Route>  

            {/* ------ status errors ----- */}
            <Route path='*' element={<NotFound />} />
            <Route path='/BadRequest' element={<BadRequest />} />
            <Route path='/Unauthorized' element={<Unauthorized />} />
            <Route path='/Forbidden' element={<Forbidden />} />
            <Route path='/NotFound' element={<NotFound />} />
            <Route path='/InternalServerError' element={<InternalServerError />} />  
          </Routes>
          <Chatbot />
        </div>

      <ToastContainer
      autoClose={500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
       />

      </>
    );
  // }
}

export default App;
