import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment'
import { useParams } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from '../../../frontend/Loader/Loader';
import {Helmet} from "react-helmet";

const ViewPostblog = () => {
  const { id } = useParams();

  const [viewPost_blog, setViewPost_blog] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    axios.get(`/blog/post/view/${id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setViewPost_blog(res.data.blog_post);
      }
      setloading(false);
    }
    });
  
  return () => {
   isMounted = false
 }
  }, []);

  if (loading) {
    return  <Loader/>;
  }
  return (
    <>
            {/* seo title */}
            <Helmet>
            <title> View || Blog-Post</title>
          </Helmet>

      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Post' pageTitle='view / post' />
      {/* Main content */}
      <section className='content'>
        {/* Default box */}
        <div className='card card-solid'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <h3 className='d-inline-block d-sm-none post_heading'>
                  {viewPost_blog?.heading}
                </h3>
                <div className='col-12'>
                  <img
                    src={
                      viewPost_blog?.blog_image == null
                        ? null
                        :`${process.env.REACT_APP_API_URL}${viewPost_blog?.blog_image}`
                          
                    } 
                    style={{ width: "90%", height: "350px" }}
                    className='img-fluid product-image'
                    alt='post_image'
                  />
                </div>
              </div>
              <div className='col-12 col-sm-6'>
                <div className='post_title border-bottom'>
                  <h5 className='font-weight-bold'>{viewPost_blog?.heading}</h5>
                </div>
                <div className='post_tag py-2 border-bottom'>
                  <span className='font-weight-bold'>Tag:</span>
                  <span>{viewPost_blog?.tag}</span>
                </div>

                <div className='post_slug py-2 border-bottom'>
                  <span className='font-weight-bold'>slug:</span>
                  <span>{viewPost_blog?.slug}</span>
                </div>

                <div className='py-1 px-2 mt-3'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='##'
                    className='btn btn-info btn'>
                    Vew Post
                  </a>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <div className='category border-bottom py-1'>
                <h6 className='font-weight-bold d-inline'>Category: </h6>
                <span className='breadcrumd'>
                  {viewPost_blog?.blog_category?.category_name} 
                  &nbsp;<i class="fas fa-angle-right"></i>&nbsp;
                  {viewPost_blog?.blog_subcategory?.sub_category_name}
                </span>
              </div>
            </div>
            {/* ------------description ratting commnet---------- */}
            <div className='row mt-4'>
              <nav className='w-100'>
                <div className='nav nav-tabs' id='product-tab' role='tablist'>
                  <a
                    className='nav-item nav-link active'
                    id='product-desc-tab'
                    data-toggle='tab'
                    href='#product-desc'
                    role='tab'
                    aria-controls='product-desc'
                    aria-selected='true'>
                    Description
                  </a>
                  <a
                    className='nav-item nav-link'
                    id='product-comments-tab'
                    data-toggle='tab'
                    href='#product-comments'
                    role='tab'
                    aria-controls='product-comments'
                    aria-selected='false'>
                    Comments
                  </a>
                  <a
                    className='nav-item nav-link'
                    id='product-rating-tab'
                    data-toggle='tab'
                    href='#product-rating'
                    role='tab'
                    aria-controls='product-rating'
                    aria-selected='false'>
                    Rating
                  </a>
                </div>
              </nav>
              <div className='tab-content p-3' id='nav-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='product-desc'
                  role='tabpanel'
                  aria-labelledby='post-desc-tab'>
                  
                  <div
                      dangerouslySetInnerHTML={{
                        __html: viewPost_blog?.post_description,
                      }}
                    />
                </div>
                <div
                  className='tab-pane fade'
                  id='product-comments'
                  role='tabpanel'
                  aria-labelledby='blog-comments-tab'>
            <div className="card-footer card-comments">
               {
                viewPost_blog?.blog_comment?.map((comment)=>{
                  return (
                    <>
                      <div className="card-comment">
                    {/* User image */}
                    <img className="img-circle img-sm"     src={`${process.env.REACT_APP_API_URL}${comment.user?.profile_image}`} alt="User_Image" />
                    <div className="comment-text ml-4">
                      <div className="d-flex justify-content-between">
                      <span className="username">
                       {comment.user?.name}
                        
                      </span>{/* /.username */}
                      <span className="text-muted float-right">  {moment(comment.created_at).fromNow()}</span>
                      </div>
                     
                      {comment.comment_body}
                    </div>
                    {/* /.comment-text */}
                  </div>
                  {
                    comment?.available_blog_comment_reply?.map((comment_reply)=>{
                      return(
                        <>
                          <div className="card-comment ml-5">
                    {/* User image */}
                    <img className="img-circle img-sm"     src={`${process.env.REACT_APP_API_URL}${comment_reply.user?.profile_image}`} alt="User_Image" />
                    <div className="comment-text">
                      <div className="d-flex justify-content-between">
                      <span className="username">
                      {comment_reply.user?.name}
                        
                      </span>{/* /.username */}
                      <span className="text-muted float-right">  {moment(comment_reply.created_at).fromNow()}</span>
                      </div>
                     
                      {comment.comment_body}
                    </div>
                    {/* /.comment-text */}
                  </div>
                       </>
                      )
                    })
                  }

</>

                  )})}
                  
                  {/* /.card-comment */}
            </div>

                </div>
                <div
                  className='tab-pane fade'
                  id='product-rating'
                  role='tabpanel'
                  aria-labelledby='product-rating-tab'>
                  {" "}
                  Cras ut ipsum ornare, aliquam ipsum non, posuere elit. In hac
                  habitasse platea dictumst. Aenean elementum leo augue, id
                  fermentum risus efficitur vel. Nulla iaculis malesuada
                  scelerisque. Praesent vel ipsum felis. Ut molestie, purus
                  aliquam placerat sollicitudin, mi ligula euismod neque, non
                  bibendum nibh neque et erat. Etiam dignissim aliquam ligula,
                  aliquet feugiat nibh rhoncus ut. Aliquam efficitur lacinia
                  lacinia. Morbi ac molestie lectus, vitae hendrerit nisl.
                  Nullam metus odio, malesuada in vehicula at, consectetur nec
                  justo. Quisque suscipit odio velit, at accumsan urna
                  vestibulum a. Proin dictum, urna ut varius consectetur, sapien
                  justo porta lectus, at mollis nisi orci et nulla. Donec
                  pellentesque tortor vel nisl commodo ullamcorper. Donec varius
                  massa at semper posuere. Integer finibus orci vitae vehicula
                  placerat.{" "}
                </div>
              </div>
            </div>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default ViewPostblog;
