import React from 'react'
// import ServicesSection from '../../../Components/frontend/ServicesSection/ServicesSection'

const Services = () => {
    return (
        <>
        {/* <ServicesSection/> */}
        </>
    )
}

export default Services
