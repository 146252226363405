import React from 'react'
import AllProductSec from '../../../../Components/admin/ProductSection/AllProductSec'

const AllProducts = () => {
  return (
    <>
    <AllProductSec/>
    </>
  )
}

export default AllProducts