import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import ShopSec from './../ShopSec';




const SearchByShop = (props) => {
  

  const [loading, setloading] = useState(true);
  const [product, setProduct] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const search = searchParams.get("search");
  const history =useNavigate();


    useEffect(() => {
      let isMounted = true;
      props.onTopProgress(30);
      const fetchData = async ()=>{
      try {
        setloading(true);
        const res = await axios.get(`/search-shop/${search}`);
        if(isMounted){
          if (res.data.status === 200) {
            setProduct(res.data.products);
            props.onTopProgress(70);
          }else if(res.data.status === 400){
            toast.error(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history("/");
          }else if(res.data.status === 404){
            toast.error(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history("/");
          }
          setloading(false)
          props.onTopProgress(100);
        }
      } catch (e) {
         console.log(e);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    }
    }, [search]);


  
  return (
    <>
      
      <ShopSec 
       product={product} 
      loading={loading}     />

     
    </>
  );
};

export default SearchByShop;

