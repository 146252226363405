import React, { useState, useEffect } from "react";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
const AddSOSCategory = () => {
  const [insertsub_of_soscategory, setinsertsub_of_soscategory] = useState({
    category_id: "",
    sub_category_id: "",
    sub_of_soscategory_name: "",
    sub_of_soscategory_slug: "",
  });
  const {
    category_id,
    sub_category_id,
    sub_of_soscategory_name,
    sub_of_soscategory_slug,
  } = insertsub_of_soscategory;

  //  get categories 
  const [catgory, setCategory] = useState([]);

  //  get subcategory 
  const [subcatgory, setSubcategory] = useState([]);
  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

  const [loading, setloading] = useState(true);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);


  // get data
  useEffect(() => {
     let isMounted = true;
  
    axios.get("/category/all").then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setCategory(res.data.categories);
      }
      setloading(false);
    }
    });

  
  return () => {
    isMounted = false;
  }
  }, []);

 // get data
 useEffect(() => {
  let isMounted = true;

  if(category_id){
    axios.get(`/subcategory/list/${category_id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
       setSubcategory(res.data.subcategory);
      }
      setloading(false);
    }
    });
  }

return () => {
 isMounted = false;
}
}, [category_id]);



  const handleChange = (e) => {
    setinsertsub_of_soscategory({
      ...insertsub_of_soscategory,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const data = insertsub_of_soscategory;
    axios
      .post(`/sub-of-sos-category/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setinsertsub_of_soscategory({
            category_id: "",
            sub_category_id: "",
            sub_of_soscategory_name: "",
            sub_of_soscategory_slug: "",
          });

          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
     {/* seo title */}
     <Helmet>
        <title> Add || SOS-Category</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title='SoS-Categories'
        pageTitle='Add / SOS-Category'
      />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Add Category</h3>
                <div className='card-tools'>
                  <button
                    type='button'
                    className='btn btn-tool'
                    data-card-widget='collapse'
                    title='Collapse'>
                    <i className='fas fa-minus' />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <form  onSubmit={handleSubmit}>
                  <div class='form-group'>
                    <label for='exampleFormControlSelect1'>Category </label>
                    <select
                      name='category_id'
                      value={category_id}
                      onChange={handleChange}
                      class='form-control'
                      id='exampleFormControlSelect1'>
                      <option value='' disabled='disabled'>
                        --Select Category--
                      </option>
                      {catgory && catgory.map((val) => {
                        return (
                          <option value={val.id}>{val.category_name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.category_id}</span>
                  <div class='form-group'>
                    <label for='exampleFormControlSelect'>
                      {" "}
                      Sub Category Name{" "}
                    </label>
                    <select
                      name='sub_category_id'
                      value={sub_category_id}
                      onChange={handleChange}
                      class='form-control'
                      id='exampleFormControlSelect'>
                      <option value=''  disabled='disabled'>
                        --Select Sub Category--
                      </option>

                      {subcatgory && subcatgory.map((val) => {
                          return (
                            <option value={val.id} key={val.id}>
                              {val.sub_category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_category_id}</span>
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>
                      SOS Category 
                    </label>
                    <input
                      // required
                      type='text'
                      name='sub_of_soscategory_name'
                      value={sub_of_soscategory_name}
                      onChange={handleChange}
                      id='inputClientCompany'
                      className='form-control'
                      placeholder='Enter SOS Category'
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_of_soscategory_name}</span>
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Slug</label>
                    <input
                      // required
                      type='text'
                      name='sub_of_soscategory_slug'
                      value={sub_of_soscategory_slug}
                      onChange={handleChange}
                      id='inputClientCompany'
                      className='form-control'
                      placeholder='Enter Slug'
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_of_soscategory_slug}</span>
                  <button
                    type='submit'
                    class='btn btn-success btn-block w-75 mx-auto'
                    disabled={disableSubmitBtn}
                    >
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddSOSCategory;
