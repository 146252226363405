export const AllRoleData = [
  {
    id: "1",
    roleName: "Developer",
    Permissions: "edit Permission",
  },
];

export const adminCheckData = [
  {
    id: "1",
    check: "can add admin",
  },
  {
    id: "2",
    check: " can see all admin",
  },
  {
    id: "3",
    check: " can see deactivated admin",
  },
  {
    id: "4",
    check: " can active-deactive admin ",
  },
  {
    id: "5",
    check: " can delete admin",
  },
  {
    id: "6",
    check: "can view single admin",
  },
  {
    id: "7",
    check: "can add genuine super admin",
  },
  {
    id: "8",
    check: "can remove genuine super admin",
  },
  {
    id: "9",
    check: "can see admins role",
  },
];

export const categoryCheckData = [
  {
    check: "see categories",
  },
  {
    check: "edit categories",
  },
  {
    check: "delete categories",
  },
  {
    check: "add categories",
  },
];

export const couponCheckData = [
  {
    check: "add coupon",
  },
  {
    check: "edit coupon",
  },
  {
    check: "delete coupon",
  },
  {
    check: "see active coupon",
  },
  {
    check: "see deactive coupon",
  },
  {
    check: "see expired coupon",
  },
  {
    check: "active-deactive coupon",
  },
];

export const customServiceCheckData = [
  {
    check: "see new custom Service request",
  },
  {
    check: "can see approved custom Service",
  },
  {
    check: "can see delivered custom Service",
  },
  {
    check: "can see cancled custom Service",
  },
  {
    check: "can approve-cancle-mark as delevery custom Service",
  },
  {
    check: "can delete custom Service",
  },
  {
    check: "can add custom Service link",
  },
  {
    check: "view custom Service",
  },
  {
    check: "can download custom Service",
  },
];

export const orderCheckData = [
  {
    check: "see all orders",
  },
  {
    check: "see new orders",
  },
  {
    check: "see free orders ",
  },
  {
    check: "view order",
  },
  {
    check: "mark as unread order",
  },
];

export const permissionCheckData = [
  {
    check: " add permission",
  },
  {
    check: "edit permission",
  },
  {
    check: "delete permission",
  },
  {
    check: "can give permission to rules",
  },
  {
    check: " see all permission",
  },
];

export const productCheckData = [
  {
    check: "add products",
  },
  {
    check: "edti products",
  },
  {
    check: "remove products",
  },
  {
    check: "see products",
  },
  {
    check: "see draft products",
  },
  {
    check: "active-deactive products",
  },
  {
    check: "view products",
  },
  {
    check: "see customize products",
  },
];

export const roleCheckData = [
  {
    check: "add role",
  },
  {
    check: " edit role",
  },
  {
    check: " delete role",
  },
  {
    check: "see role",
  },
  {
    check: "can give role to admin user",
  },
];

export const sliderCheckData = [
  {
    check: "add slider",
  },
  {
    check: " edit slider",
  },
  {
    check: " delete slider",
  },
  {
    check: "see slider",
  },
];

export const SOScategoryCheckData = [
  {
    check: "add sos categories",
  },
  {
    check: " edit  sos categories",
  },
  {
    check: " delete sos categories",
  },
  {
    check: "see sos categories",
  },
];

export const SubcategoryCheckData = [
  {
    check: "add sub categories",
  },
  {
    check: " edit  sub categories",
  },
  {
    check: " delete sub categories",
  },
  {
    check: "see sub categories",
  },
];

export const userCheckData = [
  {
    check: "see all users",
  },
  {
    check: "see deactivated users",
  },
  {
    check: "can view users",
  },
  {
    check: "see sub categories",
  },
  {
    check: "can delete users",
  },
];

export const visitorCheckData = [
  {
    check: "see visitors sammury",
  },
  {
    check: " see visitor visited pages",
  },
  {
    check: " see bot visited pages",
  },
  {
    check: "see login attemps",
  },
  {
    check: "see visitor by country page",
  },
  {
    check: "see visitor by os page",
  },
  {
    check: " see visitor by browser page",
  },
  {
    check: "see visitor by languages page",
  },
  {
    check: " see unique visitors page",
  },
  {
    check: "see registered visitors page",
  },
  {
    check: "see url visit count page",
  },
];
