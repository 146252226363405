import React from 'react'
import ViewSubCategoryWithcates from '../../../../Components/admin/Categories_Section/ViewSubCategory_with_Cates'


const ViewSubCategoryWithcats = () => {
  return (
    <>
    <ViewSubCategoryWithcates/>

    </>
  )
}

export default ViewSubCategoryWithcats;