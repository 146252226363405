// 1st col hoverble
const HoverbleCardData=[
    {
        hoverble_img:"assest/image/payment/secure.png",
        hoverble_text:"Secure"
    },
    {
        hoverble_img:"assest/image/payment/fast.png",
        hoverble_text:"Fast"
    },
    {
        hoverble_img:"assest/image/payment/support.png",
        hoverble_text:"Support"
    },
    {
        hoverble_img:"assest/image/payment/not-share-informaton.png",
        hoverble_text:"Please do not share your Personal account information"
    }
];

// 2nd col hoverble
const WeAcceptPaymentMethodData=[   
    {
    payment_img: "/assest/image/payment/master-card.png",
  },
  {
    payment_img: "/assest/image/payment/visa.png",
  },
  {
    payment_img: "/assest/image/payment/bkash.png",
  },
  {
    payment_img: "/assest/image/payment/rocket.png",
  },
  {
    payment_img: "/assest/image/payment/nagad.png",
  },
  {
    payment_img: "/assest/image/payment/Cash.png",
  },
];

export default HoverbleCardData
export {WeAcceptPaymentMethodData};