import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from "../../../frontend/Loader/Loader";

const EditCategoryblog = () => {
  const { id } = useParams();
  const history = useNavigate();
  // update
  const [updatecategories_blog, setupdateCategories_blog] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

  // get data
  useEffect(() => {
    let isMounted = true;
  
    axios.get(`/blog/category/edit/${id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setupdateCategories_blog(res.data.data);
      }else if(res.data.status === 404){
        toast.error(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        history('/our-dashboard/admin/blog/categories')
       }
      setloading(false);
      }
    }).catch(error => {});
  
  return () => {
    isMounted = false
  }
  }, [rerendering]);

  // handleChange
  const handleChange = (e) => {
    setupdateCategories_blog({
      ...updatecategories_blog,
      [e.target.name]: e.target.value,
    });
  };

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = updatecategories_blog;
    axios
      .post(`/blog/category/update/${updatecategories_blog.id}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRerendering(!rerendering);
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
  };
  if (loading) {
    <Loader/>
  }
  return (
    <>
      {/* seo title */}
      <Helmet>
        <title>Edit || Blog-Categories</title>
      </Helmet>
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Categories' pageTitle=' Edit / Category' />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Edit Category</h3>
                <div className='card-tools'>
                  <button
                    type='button'
                    className='btn btn-tool'
                    data-card-widget='collapse'
                    title='Collapse'>
                    <i className='fas fa-minus' />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                  <div className='form-group'>
                    <label htmlFor='inputName'>Category Name</label>
                    <input
                      type='text'
                      id='inputName'
                      name='category_name'
                      onChange={handleChange}
                      value={
                        updatecategories_blog.category_name == null
                          ? ""
                          : updatecategories_blog.category_name
                      }
                      className='form-control'
                      placeholder='Enter Category Name'
                    />
                  </div>
                  <span className='text-danger'>
                    {validation_error.category_name}
                  </span>
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Category Slug</label>
                    <input
                      type='text'
                      id='inputClientCompany'
                      className='form-control'
                      name='category_slug'
                      onChange={handleChange}
                      value={
                        updatecategories_blog.category_slug == null
                          ? ""
                          : updatecategories_blog.category_slug
                      }
                      placeholder='Enter Slug'
                    />
                  </div>
                  <span className='text-danger'>
                    {validation_error.category_slug}
                  </span>
                  <button type='submit' class='btn btn-info'>
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>{" "}
                  &nbsp;
                  <Link to='/our-dashboard/all/categories'>
                    <button type='submit' class='btn btn-info'>
                      Back <i class='fas fa-angle-double-left'></i>
                    </button>
                  </Link>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditCategoryblog;
