export const reducer = (state, action) => {
  
  // add to cart
  if (action.type === "ADD_TO_BASKET_CART") {
    return {
      ...state,
      isRendering: action.payload.rendering
    };
  }



// remove cart
  if (action.type === "REMOVE_ITEM_CART") {
    return {
      ...state,
      itemCart: state.itemCart.filter((curElem) => {
        return curElem.id !== action.payload;
      }),
    };
  }



  if(action.type === 'BUTTON_DISABLED'){
    return{
      ...state,
      isSubmitting:action.payload.submitting
    }
  }



  if(action.type === 'FETCH_CART_STARTED'){
    return{
      ...state,
      isLoading:action.payload.loading
    }
  }

// cart data get
  if(action.type === 'FETCH_CART_SUCCESS'){
    return{
      ...state,
      itemCart:action.payload.data
    }
  }



  if(action.type === 'FETCH_CART_END'){
    return{
      ...state,
      isLoading:action.payload.loading
    }
  }




  // increment with out color size & increment with color size
  if (action.type === "INCREMENT_CART") {
    const updatedCart = state.itemCart.map((curElem) => {
      if (curElem.id === action.payload) {
        return { ...curElem, product_qty: curElem.product_qty + (curElem.product_qty < ( curElem?.product_colors?.color_qty ? curElem?.product_colors?.color_qty : curElem.product?.qty) ? 1 : 0)};
      }
      return curElem;
    });

    return { ...state, itemCart: updatedCart };
  }
 



    // decrement with out color size & decrement with color size
  if (action.type === "DECREMENT_CART") {
    const updatedCart = state.itemCart.map((curElem) => {
      if (curElem.id === action.payload) {
        return {
          ...curElem,
          product_qty: curElem.product_qty - (curElem.product_qty <= ( curElem?.product_colors?.color_qty ? curElem?.product_colors?.color_qty : curElem.product?.qty) && curElem.product_qty > 1 ? 1 : 0),
        };
      }
      return curElem;
    });
    // .filter((curElem) => curElem.quantity !== 0);
    return { ...state, itemCart: updatedCart };
  }


// cart static clear
  if (action.type === "CLEAR_CART") {
    return { ...state, itemCart: [] };
  }




  if (action.type === "GET_TOTAL_DELIVERY_CHARGE") {
    let { totalDeliveryCharge } = state.itemCart.reduce(
      (accum, curVal) => {
        let { delivery_cost} = curVal?.product;
        let updatedTotalDeliveryCharge = Number(delivery_cost);
        accum.totalDeliveryCharge += updatedTotalDeliveryCharge;
        return accum;
      },
      {
        totalDeliveryCharge: 0,
      }
    );
    return { ...state, totalDeliveryCharge };
  }





// cart total
if (action.type === "GET_TOTAL_CART") {
  let { totalItemCart, totalAmountCart } = state.itemCart.reduce(
    (accum, curVal) => {
      let { product_qty } = curVal;
      let { selling_price, discount_price} = curVal?.product;
    
      let updatedtotalAmountCart = (Number(selling_price) - Number(discount_price)) * product_qty;
      accum.totalAmountCart += updatedtotalAmountCart;

      accum.totalItemCart += product_qty;
      return accum;
    },
    {
      totalItemCart: 0,
      totalAmountCart: 0,
    }
  );
  return { ...state, totalItemCart, totalAmountCart };
}




// coupon get
if(action.type === 'FETCH_COUPON_DISCOUNT'){
  return{
    ...state,
    couponDiscount: action.payload.data
  }
}

// coupon apply
if(action.type === 'COUPON_APPLY'){
  return {
    ...state,
    isCouponRendering: action.payload.rendering
  };
}



// coupon validation error
if(action.type === 'COUPON_VALIDATION'){
  return{
    ...state,
    validationErrors:action.payload.errors
  }
}





return state;
};


