import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";

const AllMiniBannerSec = () => {
 //  get mini mini_banner   data
 const [mini_banner, setMini_banner] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
   let isMounted = true;
   if (isMounted) {
     axios.get("/setting/mini-banner/all").then((res) => {
       if (res.data.status === 200) {
         setMini_banner(res.data.mini_banner);
       }
       setloading(false);
     });
   }
   return () => {
     isMounted = false;
   };
 }, []);
 useEffect(() => {
   // initialize datatable
   if (!loading) {
     const script = document.createElement("script");
     script.src = "/dist/js/custom.js";
     script.async = true;
     document.body.appendChild(script);
   }
 }, [loading]);
 const handleDeleteItems = (id) => {
   axios
     .delete(`/setting/mini-banner/delete/${id}`)
     .then((res) => {
       if (res.data.status === 200) {
         toast.info(res.data.message, {
           position: "top-right",
           autoClose: 700,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });

         setMini_banner(mini_banner.filter((item) => item.id !== id));
       }
     })
     .catch((e) => {});
 };

 if (loading) {
   return <h2>loading....</h2>;
 }
 return (
   <>
         {/* seo title */}
         <Helmet>
        <title>All || Mini-Banner</title>
        </Helmet>

     {/* -------PageHeaderBreadCrumb------- */}
     <PageHeaderBreadCrumb Title='Setting' pageTitle='All-Mini_banner' />
     {/* Main content */}
     <section className='content'>
       {/* Default box */}
       <div className='card'>
         <div className='card-body p-0'>
           <table
             id='example1'
             className='table table-bordered table-striped text-center'>
             <thead>
               <tr>
                 <th>Sl</th>
                 <th>Image</th>
                 <th>Title</th>
                 
                 <th>Alt</th>
                 <th>Edit</th>
                 <th>Delete</th>
               </tr>
             </thead>
             <tbody>
               {mini_banner &&
                 mini_banner.map((val) => {
                   return (
                     <tr key={val.id}>
                       <td>{val.id}</td>
                       <td>
                         <ul className='list-inline'>
                           <li className='list-inline-item'>
                             <img
                               alt='item-img'
                               width='50'
                               src={
                                 val.image == null
                                   ? null
                                   : `${process.env.REACT_APP_API_URL}${val.image}`
                               }
                             />
                           </li>
                         </ul>
                       </td>
                       <td>{val.title}</td>
                       <td>{val.img_alt}</td>
                       <td>
                         <Link
                           to={`/our-dashboard/setting/edit/mini-banner/${val.id}`}
                           className='btn btn-info btn-sm'
                           href='#/'>
                           <i className='fas fa-edit pr-2' />
                           Edit
                         </Link>
                       </td>
                       <td>
                         <a
                           className='btn btn-danger btn-sm'
                           tabindex='-1'
                           role='dialog'
                           aria-labelledby='mySmallModalLabel'
                           aria-hidden='true'
                           data-toggle='modal'
                           data-target={`#userDelete_${val.id}`}
                           href='##'>
                           <i className='fas fa-trash pr-2'></i>
                           Delete
                           <div
                             class='modal fade bd-example-modal-sm'
                             id={`userDelete_${val.id}`}>
                             <div class='modal-dialog modal-sm modal-dialog-centered'>
                               <div class='modal-content'>
                                 <div class='modal-body text-center'>
                                   <i class='fas fa-times text-danger fa-3x'></i>
                                   <p className='mb-2 text-dark'>
                                     <span className='font-weight-bold h4'>
                                       Are you sure?
                                     </span>
                                     <br />
                                     Once deleted, you will not be able to
                                     recover this data!
                                   </p>
                                 </div>
                                 <div class='modal-footer border'>
                                   <button
                                     onClick={() => {
                                       handleDeleteItems(val.id);
                                     }}
                                     type='button'
                                     class='btn btn-danger'
                                     data-dismiss='modal'>
                                     Yes
                                   </button>
                                   <button
                                     type='button'
                                     class='btn btn-outline-danger'
                                     data-dismiss='modal'>
                                     No
                                   </button>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </a>
                       </td>
                     </tr>
                   );
                 })}
             </tbody>
           </table>
         </div>
         {/* /.card-body */}
       </div>
       {/* /.card */}
     </section>
     {/* /.content */}
   </>
 );
};

export default AllMiniBannerSec;
