import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
const AddBigBannerSec = () => {
  const [insertBigBanner, setInsertBigBanner] = useState({
    banner_1: "",
    url_1: "",
    banner_2: "",
    url_2: ""
  });
  const { banner_1, url_1, banner_2, url_2 } = insertBigBanner;

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  // image preview
  const [previewImage, setPreviewImage] = useState({
    banner_1: "",
    banner_2: "",
  });
  const [bigBanner, setbigBanner] = useState([]);
  // get data
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios.get("/setting/featured-categories-big-banner/all").then((res) => {
        if (res.data.status === 200) {
          setbigBanner(res.data.big_banner);
        }
        setloading(false);
      });
    }
    return () => {
      isMounted = true;
    };
  }, [rerendering]);


 // handleChange
  const handleChange = (e) => {
    if (bigBanner == null) {
      setInsertBigBanner({
        ...insertBigBanner,
        [e.target.name]: e.target.value,
      });
    } else {
      setbigBanner({
        ...bigBanner,
        [e.target.name]: e.target.value,
      });
    }
  };



  // handleChangeImage
  const handleChangeImage = (e) => {
    setPreviewImage({
      ...previewImage,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    if (bigBanner == null) {
      setInsertBigBanner({
        ...insertBigBanner,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setbigBanner({
        ...bigBanner,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    if (bigBanner == null) {
      const formData = new FormData();
      formData.append("banner_1", banner_1);
      formData.append("url_1", url_1);
      formData.append("banner_2", banner_2);
      formData.append("url_2", url_2);
      axios
        .post(`/setting/featured-categories-big-banner/insert`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setRerendering(!rerendering);
            setValidation_error({});
          } else {
            setDisableSubmitBtn(false);
            setValidation_error(res.data.validation_errors);
          }
        })
        .catch((err) => {});
    } else {
      const formData = new FormData();
      formData.append("banner_1", bigBanner.banner_1);
      formData.append("url_1", bigBanner.url_1);
      formData.append("banner_2", bigBanner.banner_2);
      formData.append("url_2", bigBanner.url_2);

      axios
        .post(
          `/setting/featured-categories-big-banner/update/${bigBanner.id}`,
          formData
        )
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setRerendering(res.data.message);
            setValidation_error({});
          } else if (res.data.status === 422) {
            setDisableSubmitBtn(false);
            setValidation_error(res.data.validation_errors);
          } else if (res.data.status === 404) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
          }
        })
        .catch((err) => {});
    }
  };

  if (loading) {
    return <h2>loading...</h2>;
  }

  return (
    <>


      <div className='col-md-6 mx-auto'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h3 className='card-title'>Add Big Banner</h3>
          </div>
          <div className='card-body'>
            <form
              onSubmit={handleSubmit}
              id='resetForm'
              encType='multipart/form-data'>
              <div className='form-group'>
                <label htmlFor='img-1'>Image 1</label>

                <input
                  type='file'
                  className='form-control'
                  accept='image/*'
                  name='banner_1'
                  onChange={handleChangeImage}
                  id='img-1'
                />
              </div>
              {validation_error.banner_1 && (
                <span className='text-danger'>
                  {validation_error.banner_1}
                  <br />
                </span>
              )}
              {previewImage.banner_1 && (
                <img
                  alt='img'
                  width='60'
                  src={(window.URL.srcObject = previewImage.banner_1)}
                />
              )}
              {!previewImage.banner_1 && (
                <img
                  alt='img'
                  width='60'
                  src={
                    bigBanner?.banner_1 == null
                      ? null
                      : `${process.env.REACT_APP_API_URL}${bigBanner?.banner_1}`
                  }
                />
              )}


                   <div className="form-group">
                    <label htmlFor="url_1">Banner Link 1</label>
                    <input
                    required
                      type="text"
                      id="url_1"
                      name="url_1"
                      onChange={handleChange}
                      value={url_1}
                      className="form-control"
                      placeholder="Enter URL"
                    />
                  </div>
                  <span className='text-danger'>
                  {bigBanner?.url_1}
                </span>
                <br />
                  {
                    validation_error.url_1 &&
                    <span className='text-danger'>
                          {validation_error.url_1}
                      
                      </span>
                  }
              <div className='form-group'>
                <label htmlFor='img-2'>Image 2</label>

                <input
                  type='file'
                  className='form-control'
                  accept='image/*'
                  name='banner_2'
                  onChange={handleChangeImage}
                  id='img-2'
                />
              </div>
              {validation_error.banner_2 && (
                <span className='text-danger'>
                  {validation_error.banner_2}
                  <br />
                </span>
              )}
              {previewImage.banner_2 && (
                <img
                  alt='item-img'
                  width='60'
                  src={(window.URL.srcObject = previewImage.banner_2)}
                />
              )}
              {!previewImage.banner_2 && (
                <img
                  alt='img'
                  width='60'
                  src={
                    bigBanner?.banner_2 == null
                      ? null
                      : `${process.env.REACT_APP_API_URL}${bigBanner?.banner_2}`
                  }
                />
              )}
              <div className="form-group">
                    <label htmlFor="url_2">Banner Link 2</label>
                    <input
                    required
                      type="text"
                      id="url_2"
                      name="url_2"
                      onChange={handleChange}
                      value={url_2}
                      className="form-control"
                      placeholder="Enter URL"
                    />
                  </div>
                  <span className='text-danger'>
                  {bigBanner?.url_2}
                </span>
                <br />
                  {
                    validation_error.url_2 &&
                    <span className='text-danger'>
                          {validation_error.url_2}
                      
                      </span>
                  }
              <button
                type='submit'
                class='btn btn-info btn-block w-75 mx-auto'
                disabled={disableSubmitBtn}>
                <i class='fas fa-paper-plane'></i> Submit
              </button>
            </form>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </div>
    </>
  );
};

export default AddBigBannerSec;
