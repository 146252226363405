export const NewsPortalData = [
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    },
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    },
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    },
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    },
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    },
    {
        title:"News Portal",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is a",
        img:'https://mdbootstrap.com/img/new/slides/042.webp'
    }
]