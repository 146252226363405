import React from 'react'
import DeactiveAdminSec from '../../../../Components/admin/Admin/DeactiveAdminSec'

const DeactiveAdmin = () => {
  return (
    <>
    <DeactiveAdminSec/>
    </>
  )
}

export default DeactiveAdmin