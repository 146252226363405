import React from 'react';
import CommingSoonSection from '../../../Components/frontend/CommingSoonSection/CommingSoonSection';
const text=['You can earn extra money from Affiliate Program|','Affiliate is Coming Soon!']
const AffiliateProgram = () => {
  return (
        <>
        <CommingSoonSection
        typed_text={text}
        />
        </>
  )
}

export default AffiliateProgram;
