import React from 'react'
import LocationSearchSection from '../../../Components/frontend/home/AreaSearchSection/AreaSearchSection'

const LocationSearch = () => {
  return (
    <>
    <LocationSearchSection/>
    </>
  )
}

export default LocationSearch