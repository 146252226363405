import React, { useContext, useState } from "react";
import {
  MDBCard,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInputGroup,
  MDBTooltip,
} from "mdb-react-ui-kit";
import Cartitem from "./Cart_item";
import cartNoteContext from "../Context/Cart/cartNoteContext";
import MobileCartitem from "./MobileCart_item";
import { Link } from "react-router-dom";
import "./CartSection.css";
import Loader from './../Loader/Loader';
import { useForm } from "react-hook-form";
import {Helmet} from "react-helmet";



const CartSection = () => {
  const { itemCart, clearCart, totalAmountCart, totalItemCart, couponDiscount,  isLoading, validationErrors, isSubmitting, applyCoupon, removeCoupon} =
    useContext(cartNoteContext);

  //  modal
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  

 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const CouponHandleSubmit = (value) => {
    const data = value;
    applyCoupon(data);  
}

console.log(couponDiscount, totalAmountCart)

  if (isLoading) {
    return <Loader/>
  }




  if (itemCart.length === 0) {
    return (
      <div className='cart_section bg-light py-4'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold'>Cart</h5>
          <p className='text-center fs-5 fw-bold py-5'>--Cart Empty--</p>
        </div>
      </div>
    );
  }

  return (
    <>
       {/* seo title */}
       <Helmet>
       <title>Cart</title>
       </Helmet>


      <div className='cart_section bg-light py-2 overflow-hidden'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold'>Cart</h5>
          <MDBCard className="my-4 p-3">

              <div className='d-flex mb-3 border-bottom align-items-center'>
              <h5 className='text-dark mb-0 fw-bold'>
                <span className='border-bottom border-info border-2 pb-2 d-block'>Shopping Cart ({totalItemCart})</span>
              </h5>
              {/* <br></br>
               <h5 className='text-dark mb-0'>
                  <span>
                  You Have ({totalItemCart}) Items in Shopping Cart
                </span>
              </h5> */}

                <MDBBtn
                  className='me-1 ms-auto btn btn-info btn-sm'
                  onClick={toggleShow}
                  color='danger'>
                  Clear Cart all
                  <i className='fas fa-trash-alt text-dark fs-6'></i>
                </MDBBtn>

            </div>
              <div className='d-none d-xxl-block d-lg-block d-md-block'>
                <MDBTable responsive>
                  <MDBTableHead className="bg-info">
                    <tr className='text-white text-center'>
                      <th scope='col'>Product Details</th>
                      <th scope='col'>Quantity</th>
                      <th scope='col'>Price</th>
                      <th scope='col'>Remove</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {itemCart?.map((val) => {
                      return <Cartitem key={val.id} {...val} />;
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>

              <div className='d-xxl-none d-lg-none d-md-none mobile_cart'>
                <div className='row'>
                  {itemCart?.map((val) => {
                    return <MobileCartitem key={val.id} {...val} />;
                  })}
                </div>
              </div>


              <div className='total_prices d-flex justify-content-end my-1'>
                <MDBTable borderless small className='w-50 w-md-50 w-lg-25'>
                <MDBTableHead>
                  <tr>
                    <th scope='col'></th>
                    <th scope='col'></th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody className="position-relative">
                  {
                    couponDiscount ?
                    <>
                  <tr>
                    <td className='py-0'><h6 className='fw-bold'>Subtotal:</h6></td>
                    <td className='py-0'><p className='fw-bold '> ৳  {totalAmountCart}</p></td>
                  </tr>
                  <tr>
                    <td className='py-0'><h6 className='fw-bold'>Discount:</h6></td>
                    <td className='py-0'><p className='fw-bold '> ৳  {couponDiscount?.discount_price}</p>
                    </td>

                  </tr>
                  <tr className='border-top'>
                    <td className='py-1'><h6 className='fw-bold'>Total:</h6></td>
                    <td className='py-1'><p className='fw-bold '> ৳   {couponDiscount?.discount_price && totalAmountCart - couponDiscount?.discount_price}</p></td>
                  </tr>
                     <div class="position-absolute top-0 end-0">
                     <MDBTooltip tag='span' wrapperClass='d-inline-block' title='Cancel Coupon'>
                    <MDBBtn color='danger' className="text-white btn btn-sm py-2 px-3" onClick={removeCoupon} disabled={isSubmitting} >
                      X
                    </MDBBtn>
                  </MDBTooltip>
                      </div>
                  </>
                  :
                  <tr>
                    <td className='py-0'><h6 className='fw-bold'>Total:</h6></td>
                    <td className='py-0'><p className='fw-bold '> ৳  {totalAmountCart}</p></td>
                  </tr>
                  
                     
                    
                  }
                </MDBTableBody>
              </MDBTable>
              </div>

              {
                !couponDiscount &&
              <div className='my-1'>
              <form  onSubmit={handleSubmit(CouponHandleSubmit)}>
              <label for="coupon" class="font-weight-bold coupon_label">Have a coupon code?</label>
              <MDBInputGroup className='w-100 w-md-50 w-lg-25'>
              <input
                    type='text'
                    name='copon_code'
                    id='copon_code'
                    placeholder='Enter Your Coupon Code'
                    className='form-control'
                    required
                    {...register("copon_code", {
                      required: true,
                      maxLength: 100,
                    })}
                  />
                  <MDBBtn type='submit' color='info'  size='sm'
                                disabled={isSubmitting}>
                      Apply Coupon
                    {isSubmitting && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
                  </MDBBtn>
                   </MDBInputGroup>


                {/* errors  validation fails  */}
                {validationErrors.copon_code  &&
                  <span className='text-danger'>
                    {validationErrors.copon_code}
                  </span>
                }
              </form>
              </div>
            }

              <div className='buy_btn d-flex justify-content-end me-2'>
                <Link to='/checkout'>
                  <MDBBtn
                    className='m-1'
                    color='info'>
                    BUY &nbsp;&nbsp;( Grand total ৳ &nbsp;
                    {
                    couponDiscount?.discount_price ?
                     totalAmountCart - couponDiscount?.discount_price
                    :
                     totalAmountCart
                  }
                  )
                  </MDBBtn>
                </Link>
              </div>
          </MDBCard>
        </div>

        
        {/* cart remove modal */}
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
          <MDBModalDialog scrollable className='modal-sm'>
            <MDBModalContent className='p-0'>
              <MDBModalHeader className='bg-danger text-white d-flex justify-content-center'>
                <MDBModalTitle className='p-3'>
                  <p className='h5 text-white'>Are You Sure ?</p>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody className='text-center'>
                <i class='fas fa-times text-danger fa-3x'></i>
                <p className='mb-2'>All Item will be removed from Cart</p>
              </MDBModalBody>
              <MDBModalFooter>
                <div onClick={toggleShow}>
                  <MDBBtn color='danger' onClick={clearCart}>
                    <p className='mb-0'>Yes</p>
                  </MDBBtn>
                </div>
                <MDBBtn
                  outline
                  className='mx-2'
                  color='danger'
                  onClick={toggleShow}>
                  No
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
};

export default CartSection;
