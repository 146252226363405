import React from 'react'
import { MDBCard, MDBCardBody, MDBCardImage } from 'mdb-react-ui-kit';
import Title from '../SectionTitle/Title/Title';
import Titleimg from '../SectionTitle/Title_img/Title_img';
const VlogSection = () => {
  return (
    <>
 <div className="vlog_section py-2">
                <div className="container">
                    <Title
                        section_title="Vlog"
                    />
                    <Titleimg />
                    <div className="row">

                        

                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                            <div className="youtube_video">
                                <iframe src='https://www.youtube.com/embed/-oGMwOOHFyk'
                                    frameborder='0'
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                    width="100%"
                                    height="330"
                                    className='rounded'
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                            <MDBCard className='mb-3 border hover-shadow'>
                                <MDBCardImage className='img-fluid rounded p-4' src='https://mdbootstrap.com/img/new/slides/041.webp' alt='document-img' />
                                <MDBCardBody className='p-0'>
                                    <span className='text-dark py-2 text-center d-block'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe, esse!</span>
                                </MDBCardBody>
                            </MDBCard>
                        </div>

                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                            <MDBCard className='mb-3 border hover-shadow'>
                                <MDBCardImage className='img-fluid rounded p-4' src='https://mdbootstrap.com/img/new/slides/041.webp' alt='document-img' />
                                <MDBCardBody className='p-0'>
                                    <span className='text-dark py-2 text-center d-block'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Saepe, esse!</span>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                            <div className="facebook_video">
                                <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&width=500&show_text=false&height=280&appId" className='rounded' width="100%" height="320" title='video' style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
    </>
  )
}

export default VlogSection