import React, { useState, useEffect } from "react";
import { Link, useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import {toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from '../../../frontend/Loader/Loader';

const EditSubCategoryblog = () => {
 

  const { id } = useParams();
  const history = useNavigate();
  // select option
  const [categories_blog, setCategories_blog] = useState([]);
 // update 
 const [updateSubcategories_blog, setUpdateSubCategories_blog] = useState([]);

 
 // rerendering axios insert data
 const [rerendering, setRerendering] = useState(null);
 const [loading, setloading] = useState(true);

 // backend validation check
 const [validation_error, setValidation_error] = useState([]);


 // get data
 useEffect(() => {
  let isMounted = true;
  
   axios.get(`/blog/subcategory/edit/${id}`).then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setUpdateSubCategories_blog(res.data.data);
      setCategories_blog(res.data.cat)
     }else if(res.data.status === 404){
      toast.error(res.data.message, {
        position: "top-center",
        theme: 'colored',
        transition: Zoom,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history('/our-dashboard/admin/blog/subcategories')
     }
    setloading(false);
    }
  }).catch(error => {});
  
  return () => {
   isMounted = false
 }
 }, [rerendering]);

 // handleChange 
 const handleChange = (e) => {
  setUpdateSubCategories_blog({
     ...updateSubcategories_blog,
     [e.target.name]: e.target.value,
   });
 };
 // handleSubmit
 const handleSubmit = (e) => {
  e.preventDefault();
  const data =  updateSubcategories_blog;
  
  axios
    .post(`blog/subcategory/update/${updateSubcategories_blog.id}`, data)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRerendering(res.data.message);
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
      }
    })
    .catch((err) => {});
};
if (loading) {
  <Loader/>
}
  return (
    <>
        {/* seo title */}
      <Helmet>
        <title> Edit || Blog-Subcategories</title>
        </Helmet>
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Categories"
        pageTitle="Edit / Sub-Category"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Sub Category</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form action="#" onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Category Name</label>
                    <select
                      class="form-control"
                      name="category_id"
                      value={
                        updateSubcategories_blog.category_id}
                      onChange={handleChange}
                      id="exampleFormControlSelect1"
                    >
                       <option disabled="disabled" >--Select Category--</option>
                      {categories_blog && categories_blog.map((val)=>{
                        return(
                          <option value={val.id} {...updateSubcategories_blog.category_id === val.id ? "selected": null} >{val.category_name}</option>
                        )
                      })}
                   
                     
                    </select>
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.category_id}</span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">
                      Sub Category 
                    </label>
                    <input
                    // required
                      type="text"
                      id="inputClientCompany"
                      name="sub_category_name"
                      value={updateSubcategories_blog.sub_category_name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Sub Category"
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_category_name}</span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Slug</label>
                    <input
                    // required
                      type="text"
                      id="inputClientCompany"
                      name="sub_category_slug"
                      value={updateSubcategories_blog.sub_category_slug}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Slug"
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_category_slug}</span>
                  <button type="submit" class="btn btn-info">
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>{" "}
                  &nbsp;
                  <Link to="/our-dashboard/all/categories">
                    <button type="submit" class="btn btn-info">
                      Back <i class="fas fa-angle-double-left"></i>
                    </button>
                  </Link>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditSubCategoryblog;
