import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
const EditBrand = () => {
  const { id } = useParams();

  const [update, setUpdate] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  // get data
  useEffect(() => {
    let isMounted = true;
    
      axios.get(`/brand/edit/${id}`).then((res) => {
        if (isMounted) {
        if (res.data.status === 200) {
          setUpdate(res.data.brand);
        }
        setloading(false);
      }
      }).catch(error => {});
    
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
 

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("brand_name", update.brand_name);
    axios
      .post(`/brand/update/${update.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setValidation_error({});
          setRerendering(!rerendering);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
      {/* seo title */}
      <Helmet>
        <title> Edit || Brand</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Brand' pageTitle='Edit-Brand' />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Edit Brand</h3>
                <div className='card-tools'>
                  <Link
                    to='/our-dashboard/setting/all/brand'
                    className='btn btn-success btn'>
                    All Brand
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <form action='#' onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='brand'>Brand</label>
                    <input
                      required
                      type='text'
                      name='brand_name'
                      value={update.brand_name}
                      onChange={handleChange}
                      id='brand'
                      className='form-control'
                      placeholder='Enter brand'
                    />
                  </div>
                  {
                    validation_error.brand_name &&
                    <span className='text-danger'>
                          {validation_error.brand_name}
                        <br/>
                      </span>
                  }
                  <button
                    type='submit'
                    class='btn btn-success btn-block w-75 mx-auto'
                    disabled={disableSubmitBtn}
                    >
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditBrand;
