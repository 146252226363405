import React from "react";
import EditHeaderSearchoptionSec from './../../../../Components/admin/Settings/Header/Edit_Header_Search_optionSec';

const EditHeaderSearchOption = () => {
  return <>
  <EditHeaderSearchoptionSec/>
  </>;
};

export default EditHeaderSearchOption;
