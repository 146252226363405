import React, { useEffect, useState } from "react";
import axios from "axios";
const SocialMedia = () => {
  const [headertop, setHeadertop] = useState([]);
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
    axios.get("/setting/header-top-content").then((res) => {
      if (res.data.status === 200) {
        setHeadertop(res.data.header_top);
      }
    });
    return () => {
      setHeadertop([]);
    };
  }
  return () => {
    isMounted = false
  };
  }, []);
  return (
    <>
      <div className='social-media'>
        <ul>
          <li>
            <a
              className='facebook text-white'
              href={headertop?.social_media_fb}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className=' fab fa-facebook-f'></i>
            </a>
          </li>

          <li>
            <a
              className='twitter text-white'
              href={headertop?.social_media_twitter}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className=' fab fa-twitter'></i>
            </a>
          </li>

          <li>
            <a
              className='linkedin text-white'
              href={headertop?.social_media_linkedin}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className='fab fa-linkedin-in'></i>
            </a>
          </li>

          <li>
            <a
              className='instagram text-white'
              href={headertop?.social_media_instagram}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className=' fab fa-instagram'></i>
            </a>
          </li>

          <li>
            <a
              className='pinterest text-white'
              href={headertop?.social_media_pinterest}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className=' fab fa-pinterest-p'></i>
            </a>
          </li>

          <li>
            <a
              className='youtube text-white'
              href={headertop?.social_media_youtube}
              target='_blank'
              style={{cursor: 'pointer'}}
              rel='noopener noreferrer'>
              <i className=' fab fa-youtube'></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SocialMedia;
