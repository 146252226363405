import React, { useEffect, useState } from "react";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import { MDBCard, MDBBtn, MDBTextArea } from "mdb-react-ui-kit";
import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {toast,Zoom } from "react-toastify";

const RequestPlanService = () => {
  const [searchParams, setsearchParams] = useSearchParams();
  const service = searchParams.get("service");
  const plan = searchParams.get("plan");

  const [data, setData] = useState([]);
   
    //  btn disabled
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
    const [validation_error, setValidation_error] = useState([]);

  // get data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
          const res = await axios.get(`/view-special-service/${service}`);
          if(isMounted){
            if (res.data.status === 200) {
              setData(res.data.special_service);
             }
  
          }
      
      } catch (e) {
         console.log(e);
      }
  }
  
  
    fetchData();
  
    return () => {
      isMounted = false;
    }
  }, []);

  console.log(data.id)
  return (
    <>
      <div className='request_plan_service py-3 bg-light overflow-hidden'>
        <div className='container'>
         <div className='bg-white py-3'>
          <Title section_title='Request Service Plan' />
          <Titleimg />
          <div className='row'>
            <div className='col-10 mx-auto'>
              <MDBCard className='mx-auto p-4 shadow-0' style={{ maxWidth: "37rem" }}>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    service_id: data.id ? data?.id : '',
                    plan_id: '',
                    detail: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = "Please Input Your Name";
                    }

                    // Email validate condition
                    if (!values.email) {
                      errors.email = "Please Input Your Email Address";
                    } else if (values.email.length > 50) {
                      errors.email = "Email maximum length of 50 characters";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Invalid Email Address";
                    }

                    // phone validate condition
                    if (!values.phone) {
                      errors.phone = "Please Input Your Phone Number";
                    } else if (values.phone.length < 11) {
                      errors.phone =
                        "Phone Number minimum length of 11 characters";
                    } else if (values.phone.length > 15) {
                      errors.phone =
                        "Phone Number maximum length of 15 characters";
                    } else if (!/^[0-9\b]+$/i.test(values.phone)) {
                      errors.phone = "Invalid Phone Number";
                    }

                    if (!values.service_id) {
                      errors.service_id = "Please Select Service Name";
                    }

                    if (!values.plan_id) {
                      errors.plan_id = "Please Select Plan";
                    }
                    if (!values.detail) {
                      errors.detail = "Please Input detail";
                    }
                    return errors;
                  }}
                  onSubmit={(values, formik) => {

              setDisableSubmitBtn(true);
               const data = values;
               console.log(values)
                axios
              .post("/custom-service-request", data)
              .then((res) => {
                if (res.data.status === 200) {
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 300,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  formik.resetForm();
                    document.getElementById("resetForm").reset();
                }else if(res.data.status === 422)  {
                  setValidation_error(res.data.validation_errors);
                }else if (res.data.status === 404) {
                  toast.error(res.data.message, {
                      position: "top-center",
                      theme: 'colored',
                      transition: Zoom,
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
              
            setDisableSubmitBtn(false);
          })
          .catch((err) => {});
                  }}>
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      {/* <!-- Full Name input --> */}
                      <div className='input-group'>
                        <input
                          type='text'
                          id='name'
                          onChange={formik.handleChange}
                          name='name'
                          value={formik.values.name}
                          placeholder='Name *'
                          className='form-control'
                        />
                        <label className='input-group-text' htmlFor='name'>
                          <i className='fas fa-user'></i>
                        </label>
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                        <span className='text-danger'>
                          {formik.errors.name}
                        </span>
                      ) : null}

                      {/* <!-- Email input --> */}

                      <div className='input-group mt-3'>
                        <input
                          type='email'
                          id='email'
                          onChange={formik.handleChange}
                          name='email'
                          value={formik.values.email}
                          placeholder='Email *'
                          className='form-control'
                        />
                        <label
                          className='input-group-text'
                          htmlFor='email'
                          style={{ paddingLeft: "10px" }}>
                          <i className='fas fa-envelope'></i>
                        </label>
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <span className='text-danger'>
                          {formik.errors.email}
                        </span>
                      ) : null}
                      {/* <!-- Phone input --> */}

                      <div className='input-group mt-3'>
                        <input
                          type='tel'
                          id='phone'
                          onChange={formik.handleChange}
                          name='phone'
                          value={formik.values.phone}
                          placeholder='Phone *'
                          className='form-control'
                        />
                        <label
                          className='input-group-text'
                          htmlFor='phone'
                          style={{ paddingLeft: "15px" }}>
                          <i className='fas fa-mobile-alt'></i>
                        </label>
                      </div>
                      {formik.touched.phone && formik.errors.phone ? (
                        <span className='text-danger'>
                          {formik.errors.phone}
                        </span>
                      ) : null}
                      {/* <!-- Service name select input --> */}
                      <div className='mt-2'>
                        <span className='fw-bold'>Service Name</span>
                        <div className='input-group'>
                          <select
                            className='form-control'
                            onChange={formik.handleChange}
                            name='service_id'
                            value={formik.values.service_id}
                            placeholder='Select Your Service'
                         
                          >
                             <option value='' disabled="disabled">--Select Your Service--</option>
                                <option
                                  value={data?.id}
                                  {...data?.id && "selected"}>
                                 {data?.title}
                                </option>
                          </select>
                        </div>
                        {formik.touched.service_id && formik.errors.service_id && (
                          <span className='text-danger'>
                            {formik.errors.service_id}
                          </span>
                        )}
                      </div>

                      {/* <!-- plan select input --> */}
                      <div className='mt-2'>
                        <span className='fw-bold'>Plan Name</span>
                        <div className='input-group'>
                          <select
                            className='form-control'
                            onChange={formik.handleChange}
                            name='plan_id'
                            value={formik.values.plan_id}
                            placeholder='Select Your Plan'
                           
                          >
                           
                      <option value='' disabled="disabled">--Select Your Plan--</option>
                      {data?.available_service_plan?.map((val)=>{
                        return (
                        <option value={val.id} {...plan === val?.name?.toLowerCase() && "selected"}>{val.name?.toUpperCase()}</option>
                        );
                      })}
                          </select>
                        </div>
                        {formik.touched.plan_id && formik.errors.plan_id ? (
                          <span className='text-danger'>
                            {formik.errors.plan_id}
                          </span>
                        ) : null}
                      </div>
                      {/* <!-- detail input --> */}
                      <div className='mt-3'>
                        <MDBTextArea
                          onChange={formik.handleChange}
                          name='detail'
                          value={formik.values.detail}
                          label='Comment'
                          id='textAreaExample'
                          rows={4}
                        />
                        {formik.touched.detail && formik.errors.detail ? (
                          <span className='text-danger'>
                            {formik.errors.detail}
                          </span>
                        ) : null}
                      </div>

                      <div>
                        <MDBBtn
                          type='submit'
                          disabled={disableSubmitBtn}
                          rounded
                          className='login-submit-btn btn btn-success btn-rounded btn-block my-4 mx-auto'
                          color='info'>
                          Submit <i class='fas fa-paper-plane'></i>
                        </MDBBtn>
                      </div>
                    </form>
                  )}
                </Formik>
              </MDBCard>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default RequestPlanService;
