import React, {useState,useEffect} from "react";
import axios from 'axios'
import ProductsCard from "../../Products_Card/ProductsCard";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import "./TopCollection.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import LogoLoader from '../../Loader/LogoLoader';

const TopCollection = () => {
   //  get product data
   const [topCollection, setTopCollection] = useState([]);
   const [loading, setloading] = useState(true);


// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await axios.get('/top-collection/all');
      if(isMounted){
        if (res.data.status === 200) {
          setTopCollection(res.data.top_collections);
         }
         setloading(false);
      }
     
    } catch (e) {
       console.log(e);
    }
}


  fetchData();

  return () => {
    isMounted = false;
  }
}, []);

  return (
    <>
    
       <>
       <section id='top_collection'>
        <div className='container-fluid pt-2 pb-2'>
          <Title section_title="Top Collection" />
          <Titleimg />
          <div className='row  bg-white  hover-shadow pb-4 g-2'>
          { loading ?
              Array(6)
                .fill()
                .map((val, idx) => {
                  return (
                <div className='col-md-2 col-10 mx-auto  mt-3'  key={idx}>
                  <LogoLoader/>
            </div>
            )})
            : 
            topCollection?.map((val, idx) => {
              return (
                <div
                  className='col-md-2 col-10 mx-auto mt-3'
                  key={idx}>
                  <ProductsCard key={idx} {...val} />
                </div>
              );
            })}
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </section>
       </>

    {/* } */}
      
    </>
  );
};

export default TopCollection;
