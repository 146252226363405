import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function EditorEdit({ handleChange, value }) {
  const handleEditorChange = (editor) => handleChange(editor);

  return (
    <>
      <Editor
        init={{
          height: 400,
          menubar: true,
          selector: "textarea",
          deprecation_warnings: false,
          plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars code fullscreen',
    'insertdatetime media nonbreaking save table contextmenu directionality',
    'emoticons template paste textcolor colorpicker textpattern imagetools'
          ],
          toolbar:
          
            "undo redo |" +
            "bold italic styleselect fontselect fontsizeselect | forecolor backcolor |fullscreen  preview save print insertfile image   pageembed template link anchor" +
            "codesample  a11ycheck ltr rtl  showcomments addcomment alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        value={value}
        onEditorChange={handleEditorChange}
      />
    </>
  );
}
