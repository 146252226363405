import React from 'react'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import {Helmet} from "react-helmet";

const GiveRoleAdminSec = () => {
  return (
    <>
         {/* seo title */}
    <Helmet>
        <title>Give-Role</title>
      </Helmet>
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Admin"
        pageTitle="Give-Role"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Give Role</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form action="#">
                  <div className='admin_info'>
                    <span className='font-weight-bold'>Name: <span className='font-weight-normal'>Rashel Islam Sojib</span></span>
                    <p>Roles:</p>
                  </div>

                  <div class="form-check mt-1">
                    <input type="checkbox" class="form-check-input" id="editor" />
                    <label class="form-check-label font-weight-bold" for="editor">Editor</label>
                  </div>
                  <div class="form-check mt-1">
                    <input type="checkbox" class="form-check-input" id="writer" />
                    <label class="form-check-label font-weight-bold" for="writer">Writer</label>
                  </div>
                  <div class="form-check mt-1">
                    <input type="checkbox" class="form-check-input" id="super-admin" />
                    <label class="form-check-label font-weight-bold" for="super-admin">Super Admin</label>
                  </div>
                  <div class="form-check mt-1">
                    <input type="checkbox" class="form-check-input" id="developer" />
                    <label class="form-check-label font-weight-bold" for="developer">Developer</label>
                  </div>

                  <button type="submit" class="btn btn-info btn-block mt-2"><i class="fas fa-paper-plane"></i> Submit</button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  )
}

export default GiveRoleAdminSec