import React from 'react'
import MiniSliderSec from '../../../../Components/admin/Settings/MiniSlider/AddMiniSliderSec'

const AddMiniSliderSetting = () => {
  return (
    <>
    <MiniSliderSec/>
    </>
  )
}

export default AddMiniSliderSetting