import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import "./BlogIndex.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,
} from "mdb-react-ui-kit";
import { FcFolder } from "react-icons/fc";
import { VscComment } from "react-icons/vsc";



const BlogIndex = (props) => {
  const [allblog_post, setAllBlog_post] = useState([]);
  const [loading, setloading] = useState(true);


// get data single post
useEffect(() => {
  let isMounted = true;
  
  const fetchData = async () => {
    try {
    const res = await axios.get("/blog/post/get-all");
    if(isMounted){
      if (res.data.status === 200) {
        setAllBlog_post(res.data.allblog_post);
      }
      setloading(false);
    }  
   } catch (e) {
      console.log(e);
   }
    
 }

 fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);





  return (
    <>
    {/* seo meta tag  */}
      {
        
        allblog_post && allblog_post?.map((val)=>{
          return(
            
            <Helmet>
               <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0"></script>
               <title> Blog </title>
            <meta name="keywords" content={val.slug} />
            <meta name="description" content={val.post_description} />
            <meta name="subject" content={val.post_description} />
            <meta name="topic" content={val.post_description} />
            {
              val.blog_category && 
              <meta name="category" content={val.blog_category.category_name} />
            }
            <meta name="og:title" content={val.post_description} />
            <meta name="og:image" content={`${process.env.REACT_APP_API_URL}${val?.blog_image}`}/>
            <meta property="og:image:alt" content={val.post_description} />
            <meta name="og:description"   content={val.post_description}/>
            <meta name="twitter:title" content={val.post_description} />
            <meta name="twitter:description" content={val.post_description}/>
            <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}${val?.blog_image}`} />
            <meta name="twitter:image:alt" content={val.post_description} />
            <meta itemProp="name" content={val.post_description} />
            <meta itemProp="description" content={val.post_description} />
            <meta itemProp="image" content={`${process.env.REACT_APP_API_URL}${val?.blog_image}`} />
            <meta itemProp="name" content={val.post_description} />
            <meta itemProp="image" ontent={`${process.env.REACT_APP_API_URL}${val?.blog_image}`} />
            <meta name="description" itemProp="description" content={val.post_description} />
            </Helmet>
          )
        })
        
      }

              <div className='blog_card py-2'>
                <div className='row'>
                  {loading ? (
                    <ContentLoader
                      viewBox='0 0 400 160'
                      height={160}
                      width={400}
                      backgroundColor='transparent'
                      {...props}>
                      <circle cx='150' cy='86' r='8' />
                      <circle cx='194' cy='86' r='8' />
                      <circle cx='238' cy='86' r='8' />
                    </ContentLoader>
                  ) : (
                    allblog_post?.map((val) => {
                      return (
                        <div className='col-md-4 mt-2' key={val.id}>
                          <MDBCard className='border'>
                            <MDBRipple
                              rippleColor='light'
                              rippleTag='div'
                              className='bg-image hover-overlay'>
                              <Link to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                {loading && !val?.blog_image ? (
                                  <ContentLoader
                                    width={180}
                                    height={190}
                                    viewBox='0 0 450 400'
                                    backgroundColor='#f0f0f0'
                                    foregroundColor='#dedede'
                                    {...props}>
                                    <rect
                                      x='43'
                                      y='304'
                                      rx='4'
                                      ry='4'
                                      width='271'
                                      height='9'
                                    />
                                    <rect
                                      x='44'
                                      y='323'
                                      rx='3'
                                      ry='3'
                                      width='119'
                                      height='6'
                                    />
                                    <rect
                                      x='42'
                                      y='77'
                                      rx='10'
                                      ry='10'
                                      width='388'
                                      height='217'
                                    />
                                  </ContentLoader>
                                ) : (
                                  <MDBCardImage
                                  className='custom_img'
                                    src={
                                      val?.blog_image == null
                                        ? null
                                        :`${process.env.REACT_APP_API_URL}${val?.blog_image}`
                                    }
                                    fluid
                                    alt='blog-post'
                                  />
                                )}
                              </Link>
                            </MDBRipple>
                            <MDBCardBody>
                              <MDBCardTitle>
                                <Link
                                  to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                  {val?.heading}
                                </Link>
                              </MDBCardTitle>

                              <MDBCardText>
                                <div className='post_info'>
                                  <span className='date px-2'>
                                    <i class='fas fa-calendar-alt pe-1'></i>
                                    {moment(val?.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                  <Link to='/blog'>
                                    <span className='post-admin'>
                                      <i class='fas fa-user-alt pe-1'></i>
                                      {val?.user?.name}
                                    </span>
                                  </Link>
                                  <Link
                                    to={`/blog/category/${val?.blog_category?.category_slug}`}>
                                    <span className='post_cate_gory px-1'>
                                      <FcFolder />
                                      &nbsp;
                                      {val?.blog_category?.category_name}
                                    </span>
                                  </Link>
                                  <Link to=''>
                                    <span className='cate_gory ps-2'>
                                      <VscComment />
                                    </span>
                                  </Link>
                                </div>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              
    </>
  );
};

export default BlogIndex;
