import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
const EditBrand = () => {
  const { id } = useParams();

  const [update, setUpdate] = useState([]);

// image preview
  const [previewImage, setPreviewImage] = useState(null);
 
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(null);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  // get data
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios.get(`/setting/brand/edit/${id}`).then((res) => {
        if (res.data.status === 200) {
          setUpdate(res.data.brand);
          setRerendering(null);
        }
        setloading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.files[0],
    });
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("brand_image", update.brand_image);
    formData.append("img_alt", update.img_alt);
    axios
      .post(`/setting/brand/update/${update.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setValidation_error({});
          setRerendering(res.data.message);
          setPreviewImage(null);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
            {/* seo title */}
            <Helmet>
        <title>Edit || Setting-Brand</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Setting' pageTitle='Edit-Brand' />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Edit Brand</h3>
                <div className='card-tools'>
                  <Link
                    to='/our-dashboard/setting/all-brand'
                    className='btn btn-success btn'>
                    All Brand
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <form action='#' onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='brand_image'>Brand Image</label>

                    <input
                      // required
                      type='file'
                      className='form-control'
                      accept='image/*'
                      name='brand_image'
                      onChange={handleChangeImage}
                      id='brand_image'
                    />
                  </div>
                  {
                    validation_error.brand_image &&
                    <span className='text-danger'>
                    {validation_error.brand_image}
                    <br />
                  </span>
                  }

                  {
                    previewImage &&
                    <img
                    alt='item-img'
                    width='60'
                    src={previewImage}
                  />
                   }
                   
                    {
                      !previewImage && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update?.brand_image == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${update?.brand_image}`
                    }
                  />
                  }
                  

                  <div className='form-group'>
                    <label htmlFor='inputslug'>Brand Image Alt</label>
                    <input
                      required
                      type='text'
                      name='img_alt'
                      value={update.img_alt}
                      onChange={handleChange}
                      id='brand_alt'
                      className='form-control'
                      placeholder='Enter Brand Image Alt'
                    />
                  </div>
                  <span className='text-danger'>
                    {validation_error.img_alt}
                  </span>
                  <button
                    type='submit'
                    class='btn btn-success btn-block w-75 mx-auto'
                    disabled={disableSubmitBtn}
                    >
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditBrand;
