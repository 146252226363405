import React from 'react'
import EditMiniBannerSec from '../../../../Components/admin/Settings/MiniBanner/EditMiniBannerSec'

const EditMiniBannerSetting = () => {
  return (
    <>
    <EditMiniBannerSec/>
    </>
  )
}

export default EditMiniBannerSetting