import React from 'react'
import EmagazineSection from '../../../Components/frontend/E-MagazineSection/EmagazineSection'

const EMagazine = () => {
  return (
    <>
      <EmagazineSection/>
    </>
  )
}

export default EMagazine