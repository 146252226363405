import React from "react";
import DashBoardHome from "../../../Components/admin/DashBoard_Home/DashBoard_Home";

const Home = () => {
  return (
    <>
      <DashBoardHome />
    </>
  );
};

export default Home;
