import React, {useState} from "react";
import { Formik } from "formik";
import { MDBCard, MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { toast, Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import axios from "axios";
const UserChangePasswordForm = () => {
  const [validation_error, setValidation_error] = useState([]);
  return (
    <>
          {/* seo title */}
          <Helmet>
          <title>Change-Password || User</title>
          </Helmet>
      <Formik
        initialValues={{
          old_password: "",
          new_password: "",
          new_password_confirmation: "",
        }}
        validate={(values) => {
          const errors = {};

          // ------   validation code    ------
          // old_password validate condition
          if (!values.old_password) {
            errors.old_password = "Please Input Your Old Password";
          } else if (values.old_password.length > 50) {
            errors.old_password =
              "old Password maximum length of 50 characters";
          } else if (values.old_password.length < 8) {
            errors.old_password = "old Password manimum length of 8 characters";
          }
          //  else if (old_password !== values.old_password) {
          //   errors.old_password = "old Password Not Match";
          // }

          // new_password validate condition
          if (!values.new_password) {
            errors.new_password = "Please Input Your New Password";
          } else if (values.new_password.length > 50) {
            errors.new_password =
              "New Password maximum length of 50 characters";
          } else if (values.new_password.length < 8) {
            errors.new_password = "New Password manimum length of 8 characters";
          }

          // comfirmpassword validate condition
          if (!values.new_password_confirmation) {
            errors.new_password_confirmation =
              "Please Input Your New Comfirmation Password";
          } else if (values.new_password_confirmation.length > 50) {
            errors.new_password_confirmation =
              "New Comfirmation Password maximum length of 50 characters";
          } else if (values.new_password_confirmation.length < 8) {
            errors.new_password_confirmation =
              "New Comfirmation Password manimum length of 8 characters";
          } else if (values.new_password_confirmation !== values.new_password) {
            errors.new_password_confirmation = "The Comfirm Password does not match";
          }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          const data = values;
          axios
            .put("/user/change-password", data)
            .then((res) => {
              if (res.data.status === 200) {
                document.getElementById("resetForm").reset();
                resetForm();
                toast.success(res.data.message, {
                  position: "top-center",
                  theme: "colored",
                  transition: Zoom,
                  autoClose: 500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

              } else {
                setValidation_error(res.data.validation_errors);
              }
            })
            .catch((err) => {});
        }}>
        {(formik) => (
          <MDBCard className='shadow-1 p-2'>
            <form action='#' onSubmit={formik.handleSubmit}>
              <div className='user_change_password'>
                <p className='text-center text-dark h4'>
                  ---Change Password---
                </p>
                <div style={{ width: "80%", margin: "auto", display: "block" }}>
                  <div className='mt-1'>
                    <label htmlFor='old-password' className='mb-1 custom_font'>
                      Old Password<span className='text-danger'>*</span>
                    </label>
                    <MDBInput
                      label='Old Password'
                      name='old_password'
                      placeholder='Old Password'
                      type='password'
                      size='lg'
                      id='old-password'
                      onChange={formik.handleChange}
                      value={formik.values.old_password}
                      touched={formik.touched.old_password}
                      onBlur={formik.handleBlur}
                    />

                    {validation_error.old_password ? (
                      <span className='text-danger'>
                        {validation_error.old_password}
                      </span>
                    ) : (
                      formik.touched.old_password &&
                      formik.errors.old_password && (
                        <span className='text-danger'>
                          {formik.errors.old_password}
                        </span>
                      )
                    )}
                  </div>
                  <div className='mt-2'>
                    <label htmlFor='new-password' className='mb-1 custom_font'>
                      New Password{" "}
                      <span className='text-danger custom_font fw-light font-monospace'>
                        [Must be 8 character's Long]*
                      </span>
                    </label>
                    <MDBInput
                      label='New Password'
                      name='new_password'
                      id='new-password'
                      placeholder='New Password'
                      type='password'
                      size='lg'
                      onChange={formik.handleChange}
                      value={formik.values.new_password}
                      touched={formik.touched.new_password}
                      onBlur={formik.handleBlur}
                    />
                    {validation_error.new_password ? (
                      <span className='text-danger'>
                        {validation_error.new_password}
                      </span>
                    ) : (
                      formik.touched.new_password &&
                      formik.errors.new_password && (
                        <span className='text-danger'>
                          {formik.errors.new_password}
                        </span>
                      )
                    )}
                  </div>
                  <div className='mt-2'>
                    <label
                      htmlFor='new-comfirm-password'
                      className='mb-1 custom_font'>
                      New Comfirmation Password
                      <span className='text-danger'>*</span>
                    </label>
                    <MDBInput
                      label='New Comfirmation Password'
                      name='new_password_confirmation'
                      id='new-comfirm-password'
                      placeholder='New Comfirmation Password'
                      type='password'
                      size='lg'
                      onChange={formik.handleChange}
                      value={formik.values.new_password_confirmation}
                      touched={formik.touched.new_password_confirmation}
                      onBlur={formik.handleBlur}
                    />
                    {validation_error.new_password_confirmation ? (
                      <span className='text-danger'>
                        {validation_error.new_password_confirmation}
                      </span>
                    ) : (
                      formik.touched.new_password_confirmation &&
                      formik.errors.new_password_confirmation && (
                        <span className='text-danger'>
                          {formik.errors.new_password_confirmation}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className='password_submit_btn mt-4 text-center'>
                <MDBBtn
                  className='m-1'
                  type='submit'
                  style={{ backgroundColor: "#39a0c5", width: "80%" }}>
                  Submit
                </MDBBtn>
              </div>
            </form>
          </MDBCard>
        )}
      </Formik>
    </>
  );
};

export default UserChangePasswordForm;
