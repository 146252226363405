import React from "react";
import WishlistSection from "../../../Components/frontend/Wishlist/WishlistSection";

const Wishlist = () => {
  return (
    <>
      <WishlistSection />
    </>
  );
};

export default Wishlist;
