export const data = {
  division: [
    {
      value: "Dhaka",
      label: "Dhaka",
      district: [
        {
          value: "Kishoreganj",
          label: "Kishoreganj",
          thana: [
            {
              value: "Bajitpur",
              label: "Bajitpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bhairab",
              label: "Bhairab",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kuliarchar",
              label: "Kuliarchar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Pakundia ",
              label: "Pakundia ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Itna ",
              label: "Itna ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Karimganj ",
              label: "Karimganj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Katiadi ",
              label: "Katiadi ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Austagram ",
              label: "Austagram ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mithamain ",
              label: "Mithamain ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Tarail ",
              label: "Tarail ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Hossainpur ",
              label: "Hossainpur ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Nikli ",
              label: "Nikli ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Gazipur",
          label: "Gazipur",
          thana: [
            {
              value: "Kaliganj",
              label: "Kaliganj",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kaliakair",
              label: "Kaliakair",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kaliganj",
              label: "Kaliganj",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Joydevpur",
              label: "Joydevpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sreepur",
              label: "Sreepur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Gopalganj",
          label: "Gopalganj",
          thana: [
            {
              value: "Gopalganj Sadar",
              label: "Gopalganj Sadar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Tungipara ",
              label: "Tungipara ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kotalipara ",
              label: "Kotalipara ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kashiani ",
              label: "Kashiani ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Muksudpur ",
              label: "Muksudpur ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Jamalpur",
          label: "Jamalpur",
          thana: [
            {
              value: "Islampur",
              label: "Islampur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Jamalpur Sadar ",
              label: "Jamalpur Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Dewanganj",
              label: "Dewanganj",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bakshiganj ",
              label: "Bakshiganj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Madarganj ",
              label: "Madarganj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Melandaha",
              label: "Melandaha",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sarishabari",
              label: "Sarishabari",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Tangail",
          label: "Tangail",
          thana: [
            {
              value: "Kalihati ",
              label: "Kalihati ",
            },
            {
              value: "Gopalpur",
              label: "Gopalpur",
            },
            {
              value: "Ghatail",
              label: "Ghatail",
            },
            {
              value: "Tangail Sadar",
              label: "Tangail Sadar",
            },
            {
              value: "Delduar",
              label: "Delduar",
            },
            {
              value: "Dhanbari ",
              label: "Dhanbari ",
            },
            {
              value: "Nagarpur ",
              label: "Nagarpur ",
            },
            {
              value: "Basail ",
              label: "Basail ",
            },
            {
              value: "Bhuapur ",
              label: "Bhuapur ",
            },
            {
              value: "Madhupur ",
              label: "Madhupur ",
            },
            {
              value: "Mirzapur ",
              label: "Mirzapur ",
            },
            {
              value: "Sakhipur ",
              label: "Sakhipur ",
            },
          ],
        },
        {
          value: "Dhaka",
          label: "Dhaka",
          thana: [
            {
              value: "Adabor",
              label: "Adabor",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Badda",
              label: "Badda",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Banani",
              label: "Banani",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bangshal",
              label: "Bangshal",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bimanbandar",
              label: "Bimanbandar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bsahantek",
              label: "Bsahantek",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Cantonment",
              label: "Cantonment",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Chalkbazar",
              label: "Chalkbazar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Dakhin Khan",
              label: "Dakhin Khan",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Darus-Salam",
              label: "Darus-Salam",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Demra",
              label: "Demra",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Dhanmondi",
              label: "Dhanmondi",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gandaria",
              label: "Gandaria",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gulshan",
              label: "Gulshan",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Hazaribag",
              label: "Hazaribag",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Jattrabari",
              label: "Jattrabari",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kafrul",
              label: "Kafrul",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kalabagan",
              label: "Kalabagan",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kamrangirchar",
              label: "Kamrangirchar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Khilgaon",
              label: "Khilgaon",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Khilkhet",
              label: "Khilkhet",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kodomtali",
              label: "Kodomtali",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kotwali",
              label: "Kotwali",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Lalbagh",
              label: "Lalbagh",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mirpur Model",
              label: "Mirpur Model",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mohammadpur",
              label: "Mohammadpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Motijheel",
              label: "Motijheel",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mugda",
              label: "Mugda",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "New Market",
              label: "New Market",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Pallabi",
              label: "Mugda",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Paltan",
              label: "Paltan",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Ramna Model",
              label: "Ramna Model",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Rampura",
              label: "Rampura",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Rupnagar",
              label: "Rupnagar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sabujbag",
              label: "Sabujbag",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shah Ali",
              label: "Shah Ali",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shahbag",
              label: "Shahbag",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shahjahanpur",
              label: "Shahjahanpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sutrapur",
              label: "Sutrapur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shyampur",
              label: "Shyampur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sher-e-Bangla Nagar",
              label: "Sher-e-Bangla Nagar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Tejgaon Industrial Police",
              label: "Tejgaon Industrial Police",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Tejgaon",
              label: "Tejgaon",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Turag",
              label: "Turag",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Uttara East",
              label: "Uttara East",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Uttara West",
              label: "Uttara West",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Uttar Khan",
              label: "Uttar Khan",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Vatara",
              label: "Vatara",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Wari",
              label: "Wari",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Narsingdi",
          label: "Narsingdi",
          thana: [
            {
              value: "Belabo",
              label: "Belabo",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Monohardi",
              label: "Monohardi",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Narsingdi Sadar",
              label: "Narsingdi Sadar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Palash",
              label: "Palash",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Raipura",
              label: "Raipura",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shibpur",
              label: "Shibpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Narayanganj",
          label: "Narayanganj",
          thana: [
            {
              value: "Narayanganj Sadar ",
              label: "Narayanganj Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Araihazar ",
              label: "Araihazar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Rupganj",
              label: "Rupganj",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bandar",
              label: "Bandar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sonargaon",
              label: "Sonargaon",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Siddhirganj",
              label: "Siddhirganj",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Fatullah ",
              label: "Fatullah ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Netrakona",
          label: "Netrakona",
          thana: [
            {
              value: "Netrokona Sadar ",
              label: "Netrokona Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kendua ",
              label: "Kendua ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mohangonj ",
              label: "Mohangonj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Khaliajuri ",
              label: "Khaliajuri ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Purbodhola ",
              label: "Purbodhola ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Atpara ",
              label: "Atpara ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Modon ",
              label: "Modon ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kolmkakanda ",
              label: "Kolmkakanda ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Barhatta ",
              label: "Barhatta ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Durgapur ",
              label: "Durgapur ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Faridpur",
          label: "Faridpur",
          thana: [
            {
              value: "Alfadanga",
              label: "Alfadanga",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Char Bhadrasan ",
              label: "Char Bhadrasan ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Nagarkanda",
              label: "Nagarkanda",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Faridpur Sadar ",
              label: "Faridpur Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Boalmari ",
              label: "Boalmari ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bhanga ",
              label: "Bhanga ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Madhukhali ",
              label: "Madhukhali ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sadarpur",
              label: "Sadarpur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Saltha ",
              label: "Saltha ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Mymensingh",
          label: "Mymensingh",
          thana: [
            {
              value: "Ishwarganj ",
              label: "Ishwarganj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gaffargaon",
              label: "Gaffargaon",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gauripur",
              label: "Gauripur",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Trishal",
              label: "Trishal",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Dhobaura",
              label: "Dhobaura",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Nandail",
              label: "Nandail",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Phulpur ",
              label: "Phulpur ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Fulbaria ",
              label: "Fulbaria ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bhaluka",
              label: "Bhaluka",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Mymensingh Sadar ",
              label: "Mymensingh Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Muktagachha ",
              label: "Muktagachha ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Haluaghat ",
              label: "Haluaghat ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Madaripur",
          label: "Madaripur",
          thana: [
            {
              value: "Madaripur Sadar ",
              label: "Madaripur Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kalkini ",
              label: "Kalkini ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Dasar ",
              label: "Dasar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Rajoir ",
              label: "Rajoir ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shibchar",
              label: "Shibchar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Manikganj",
          label: "Manikganj",
          thana: [
            {
              value: "Ghior ",
              label: "Ghior ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Daulatpur",
              label: "Madaripur Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Manikganj Sadar ",
              label: "Manikganj Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shibalaya ",
              label: "Shibalaya ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Saturia",
              label: "Saturia",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Singair ",
              label: "Singair ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Harirampur ",
              label: "Harirampur ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Munshiganj",
          label: "Munshiganj",
          thana: [
            {
              value: "Munshiganj Sadar ",
              label: "Munshiganj Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sreenagar  ",
              label: "Sreenagar  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Lohajang  ",
              label: "Lohajang  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sirajdikhan  ",
              label: "Sirajdikhan  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gazaria  ",
              label: "Gazaria  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Tongibari   ",
              label: "Tongibari   ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Rajbari",
          label: "Rajbari",
          thana: [
            {
              value: "Rajbari Sadar",
              label: "Rajbari Sadar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Baliakandi ",
              label: "Baliakandi ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Kalukhali",
              label: "Kalukhali",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Goalandaghat",
              label: "Goalandaghat",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Pangsha ",
              label: "Pangsha ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Shariatpur",
          label: "Shariatpur",
          thana: [
            {
              value: "Shariatpur Sadar",
              label: "Shariatpur Sadar",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Bhedarganj ",
              label: "Bhedarganj ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Damudya ",
              label: "Damudya ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Gosairhat  ",
              label: "Gosairhat  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Naria  ",
              label: "Naria  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Shakhipur",
              label: "Shakhipur  ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Zanjira ",
              label: "Zanjira   ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
        {
          value: "Sherpur ",
          label: "Sherpur ",
          thana: [
            {
              value: "Jhenaigati ",
              label: "Jhenaigati ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Nakla",
              label: "Nakla",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Nalitabari ",
              label: "Nalitabari ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sherpur Sadar ",
              label: "Sherpur Sadar ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
            {
              value: "Sreebardi ",
              label: "Sreebardi ",
              area: [
                {
                  value: "1",
                  label: "1",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      value: "Chittagong",
      label: "Chittagong",
      district: [
        {
          value: "Bandarban",
          label: "Bandarban",
          thana: [
            {
              value: "Alikadam",
              label: "Alikadam",
            },
            {
              value: "Thanchi",
              label: "Thanchi",
            },
            {
              value: "Naikhongchhari",
              label: "Naikhongchhari",
            },
            {
              value: "Bandarban Sadar",
              label: "Bandarban Sadar",
            },
            {
              value: "Ruma",
              label: "Ruma",
            },
            {
              value: "Rowangchhari",
              label: "Rowangchhari",
            },
            {
              value: "Lama",
              label: "Lama",
            },
          ],
        },
        {
          value: "Brahmanbaria",
          label: "Brahmanbaria",
          thana: [
            {
              value: "Akhaura",
              label: "Akhaura",
            },
            {
              value: "Ashuganj",
              label: "Ashuganj",
            },
            {
              value: "Kasba",
              label: "Kasba",
            },
            {
              value: "Nabinagar",
              label: "Nabinagar",
            },
            {
              value: "Banchharampur",
              label: "Banchharampur",
            },
            {
              value: "Brahmanbaria Sadar",
              label: "Brahmanbaria Sadar",
            },
            {
              value: "Sarail",
              label: "Sarail",
            },
          ],
        },
        {
          value: "Chandpur",
          label: "Chandpur",
          thana: [
            {
              value: "Chandpur Sadar",
              label: "Chandpur Sadar",
            },
            {
              value: "Faridganj ",
              label: "Faridganj ",
            },
            {
              value: "Haimchar ",
              label: "Haimchar ",
            },
            {
              value: "Hajiganj",
              label: "Hajiganj",
            },
            {
              value: "Kachua",
              label: "Kachua",
            },
            {
              value: "Matlab Dakshin",
              label: "Matlab Dakshin",
            },
            {
              value: "Matlab Uttar",
              label: "Matlab Uttar",
            },
            {
              value: "Shahrasti ",
              label: "Shahrasti ",
            },
          ],
        },
        {
          value: "Chittagong",
          label: "Chittagong",
          thana: [
            {
              value: "Akbar Shah",
              label: "Akbar Shah",
            },
            {
              value: "Bakalia",
              label: "Bakalia",
            },
            {
              value: "Bandar",
              label: "Bandar",
            },
            {
              value: "Chandgaon",
              label: "Chandgaon",
            },
            {
              value: "Bayazid Bostami",
              label: "Bayazid Bostami",
            },
            {
              value: "Chawkbazar",
              label: "Chawkbazar",
            },
            {
              value: "Chittagong Kotwali",
              label: "Chittagong Kotwali",
            },
            {
              value: "Double Mooring",
              label: "Double Mooring",
            },
            {
              value: "EPZ",
              label: "EPZ",
            },
            {
              value: "Halishahar",
              label: "Halishahar",
            },
            {
              value: "Karnaphuli ",
              label: "Karnaphuli ",
            },
            {
              value: "Khulshi",
              label: "Khulshi",
            },
            {
              value: "Pahartali",
              label: "Pahartali",
            },
            {
              value: "Panchlaish",
              label: "Panchlaish",
            },
            {
              value: "Patenga",
              label: "Patenga",
            },
            {
              value: "Sadarghat",
              label: "Sadarghat",
            },
          ],
        },
        {
          value: "Comilla",
          label: "Comilla",
          thana: [
            {
              value: "Comilla Adarsha Sadar",
              label: "Comilla Adarsha Sadar",
            },
            {
              value: "Chandina",
              label: "Chandina",
            },
            {
              value: "Chauddagram",
              label: "Chauddagram",
            },
            {
              value: "Titas",
              label: "Titas",
            },
            {
              value: "Daudkandi",
              label: "Daudkandi",
            },
            {
              value: "Debidwar",
              label: "Debidwar",
            },
            {
              value: "Barura",
              label: "Barura",
            },
            {
              value: "Burichang",
              label: "Burichang",
            },
            {
              value: "Brahmanpara",
              label: "Brahmanpara",
            },
            {
              value: "Manoharganj",
              label: "Manoharganj",
            },
            {
              value: "Muradnagar ",
              label: "Muradnagar ",
            },
            {
              value: "Meghna",
              label: "Meghna",
            },
            {
              value: "Laksam",
              label: "Laksam",
            },
            {
              value: "Nangalkot",
              label: "Nangalkot",
            },
            {
              value: "Comilla Sadar Dakshin",
              label: "Comilla Sadar Dakshin",
            },
            {
              value: "Homna",
              label: "Homna",
            },
          ],
        },
        {
          value: "Cox's Bazar",
          label: "Cox's Bazar",
          thana: [
            {
              value: "Chakaria",
              label: "Chakaria",
            },
            {
              value: "Teknaf",
              label: "Teknaf",
            },
            {
              value: "Chakaria",
              label: "Chakaria",
            },
            {
              value: "Cox's Bazar Sadar",
              label: "Cox's Bazar Sadar",
            },
            {
              value: "Eidgaon ",
              label: "Eidgaon ",
            },
            {
              value: "Kutubdia ",
              label: "Kutubdia ",
            },
            {
              value: "Moheshkhali ",
              label: "Moheshkhali ",
            },
            {
              value: "Pekua ",
              label: "Pekua ",
            },
            {
              value: "Ramu",
              label: "Ramu",
            },
            {
              value: "Teknaf",
              label: "Teknaf",
            },
            {
              value: "Ukhia",
              label: "Ukhia",
            },
          ],
        },
        {
          value: "Feni",
          label: "Feni",
          thana: [
            {
              value: "Feni Sadar",
              label: "Feni Sadar",
            },
            {
              value: "Daganbhuiyan",
              label: "Daganbhuiyan",
            },
            {
              value: "Chhagalnaiya",
              label: "Chhagalnaiya",
            },
            {
              value: "Sonagazi",
              label: "Sonagazi",
            },
            {
              value: "Parshuram",
              label: "Parshuram",
            },
            {
              value: "Fulgazi",
              label: "Fulgazi",
            },
          ],
        },
        {
          value: "Khagrachhari",
          label: "Khagrachhari",
          thana: [
            {
              value: "Dighinala",
              label: "Dighinala",
            },
            {
              value: "Guimara ",
              label: "Guimara ",
            },
            {
              value: "Khagrachhari Sadar",
              label: "Khagrachhari Sadar",
            },
            {
              value: "Lakshmichhari",
              label: "Lakshmichhari",
            },
            {
              value: "Mahalchhari",
              label: "Mahalchhari",
            },
            {
              value: "Manikchhari",
              label: "Manikchhari",
            },
            {
              value: "Matiranga",
              label: "Matiranga",
            },
            {
              value: "Manikchhari",
              label: "Manikchhari",
            },
            {
              value: "Ramgarh",
              label: "Ramgarh",
            },
          ],
        },
        {
          value: "Lakshmipur",
          label: "Lakshmipur",
          thana: [
            {
              value: "Kamalnagar ",
              label: "Kamalnagar ",
            },
            {
              value: "Ramganj  ",
              label: "Ramganj  ",
            },
            {
              value: "Ramgati ",
              label: "Ramgati ",
            },
            {
              value: "Raipur ",
              label: "Raipur ",
            },
            {
              value: "Lakshmipur Sadar ",
              label: "Lakshmipur Sadar ",
            },
          ],
        },
        {
          value: "Noakhali",
          label: "Noakhali",
          thana: [
            {
              value: "Senbagh",
              label: "Senbagh",
            },
            {
              value: "Begumganj",
              label: "Begumganj",
            },
            {
              value: "Chatkhil",
              label: "Chatkhil",
            },
            {
              value: "Companiganj ",
              label: "Companiganj ",
            },
            {
              value: "Noakhali Sadar",
              label: "Noakhali Sadar",
            },
            {
              value: "Kabirhat",
              label: "Kabirhat",
            },
            {
              value: "Hatiya",
              label: "Hatiya",
            },
            {
              value: "Sonaimuri",
              label: "Sonaimuri",
            },
            {
              value: "Suborno Char",
              label: "Suborno Char",
            },
            {
              value: "Bhashan Char",
              label: "Bhashan Char",
            },
          ],
        },
        {
          value: "Rangamati",
          label: "Rangamati",
          thana: [
            {
              value: "Kawkhali",
              label: "Kawkhali",
            },
            {
              value: "Kaptai",
              label: "Kaptai",
            },
            {
              value: "Juraichhari",
              label: "Juraichhari",
            },
            {
              value: "Naniarchar",
              label: "Naniarchar",
            },
            {
              value: "Barkal",
              label: "Barkal",
            },
            {
              value: "Baghaichhari",
              label: "Baghaichhari",
            },
            {
              value: "Belaichhari",
              label: "Belaichhari",
            },
            {
              value: "Rangamati Sadar ",
              label: "Rangamati Sadar ",
            },
            {
              value: "Rajasthali",
              label: "Rajasthali",
            },
            {
              value: "Langadu",
              label: "Langadu",
            },
          ],
        },
      ],
    },
    {
      value: "Rajshahi",
      label: "Rajshahi",
      district: [
        {
          value: "Bogra",
          label: "Bogra",
          thana: [
            {
              value: "Bogura Sadar",
              label: "Bogura Sadar",
            },
            {
              value: "Sherpur",
              label: "Sherpur",
            },
            {
              value: "Dhunat",
              label: "Dhunat",
            },
            {
              value: "Nandigram",
              label: "Nandigram",
            },
            {
              value: "Kahaloo",
              label: "Kahaloo",
            },
            {
              value: "Adamdighi",
              label: "Adamdighi",
            },
            {
              value: "Dupchanchia",
              label: "Dupchanchia",
            },
            {
              value: "Sariakandi",
              label: "Sariakandi",
            },
            {
              value: "Gabtali",
              label: "Gabtali",
            },
            {
              value: "Shibganj",
              label: "Shibganj",
            },
            {
              value: "Sonatala",
              label: "Sonatala",
            },
            {
              value: "Shajahanpur",
              label: "Shajahanpur",
            },
          ],
        },
        {
          value: "Joypurhat",
          label: "Joypurhat",
          thana: [
            {
              value: "Joypurhat Sadar",
              label: "Joypurhat Sadar",
            },
            {
              value: "Akkelpur ",
              label: "Akkelpur ",
            },
            {
              value: "Khetlal ",
              label: "Khetlal ",
            },
            {
              value: "Panchbibi ",
              label: "Panchbibi ",
            },
            {
              value: "Kalai ",
              label: "Kalai ",
            },
          ],
        },
        {
          value: "Naogaon",
          label: "Naogaon",
          thana: [
            {
              value: "Atrai",
              label: "Atrai",
            },
            {
              value: "Dhamoirhat",
              label: "Dhamoirhat",
            },
            {
              value: "Niamatpur",
              label: "Niamatpur",
            },
            {
              value: "Patnitala",
              label: "Patnitala",
            },
            {
              value: "Porsha",
              label: "Porsha",
            },
            {
              value: "Badalgachhi",
              label: "Badalgachhi",
            },
            {
              value: "Mahadebpur",
              label: "Mahadebpur",
            },
            {
              value: "Manda",
              label: "Manda",
            },
            {
              value: "Naogaon Sadar ",
              label: "Naogaon Sadar ",
            },
            {
              value: "Raninagar ",
              label: "Raninagar ",
            },
            {
              value: "Sapahar ",
              label: "Sapahar ",
            },
          ],
        },
        {
          value: "Natore",
          label: "Natore",
          thana: [
            {
              value: "Gurudaspur",
              label: "Gurudaspur",
            },
            {
              value: "Natore Sadar ",
              label: "Natore Sadar ",
            },
            {
              value: "Baraigram ",
              label: "Baraigram ",
            },
            {
              value: "Bagatipara ",
              label: "Bagatipara ",
            },
            {
              value: "Lalpur ",
              label: "Lalpur ",
            },
            {
              value: "Singra ",
              label: "Singra ",
            },
          ],
        },
        {
          value: "Chapai Nawabganj",
          label: "Chapai Nawabganj",
          thana: [
            {
              value: "Gomastapur",
              label: "Gomastapur",
            },
            {
              value: "Nawabganj Sadar",
              label: "Nawabganj Sadar",
            },
            {
              value: "Nachole",
              label: "Nachole",
            },
            {
              value: "Bholahat",
              label: "Bholahat",
            },
            {
              value: "Shibganj",
              label: "Shibganj",
            },
          ],
        },
        {
          value: "Pabna",
          label: "Pabna",
          thana: [
            {
              value: "Atgharia",
              label: "Atgharia",
            },
            {
              value: "Bera",
              label: "Bera",
            },
            {
              value: "Bhangura",
              label: "Bhangura",
            },
            {
              value: "Chatmohar",
              label: "Chatmohar",
            },
            {
              value: "Faridpur ",
              label: "Faridpur ",
            },
            {
              value: "Ishwardi",
              label: "Ishwardi",
            },
            {
              value: "Pabna Sadar ",
              label: "Santhia ",
            },
            {
              value: "Santhia",
              label: "Santhia",
            },
            {
              value: "Sujanagar",
              label: "Sujanagar",
            },
          ],
        },
        {
          value: "Rajshahi",
          label: "Rajshahi",
          thana: [
            {
              value: "Boalia",
              label: "Boalia",
              area: [
                {
                  value: "Headline Colony",
                  label: "Headline Colony",
                },
                {
                  value: "Small forest colony",
                  label: "Small forest colony",
                },
                {
                  value: "Small village",
                  label: "Small village",
                },
                {
                  value: "Shiroil Colony",
                  label: "Shiroil Colony",
                },
                {
                  value: "Railway Colony",
                  label: "Railway Colony",
                },
                {
                  value: "Boalia Para",
                  label: "Boalia Para",
                },
                {
                  value: "Sultanabad",
                  label: "Sultanabad",
                },
                {
                  value: "Ballobganj",
                  label: "Ballobganj",
                },
                {
                  value: "Headline",
                  label: "Headline",
                },
                {
                  value: "Sagorpara",
                  label: "Sagorpara",
                },
                {
                  value: "Rampur Bazar",
                  label: "Rampur Bazar",
                },
                {
                  value: "Chanson's chalk",
                  label: "Chanson's chalk",
                },
                {
                  value: "Ghooramara",
                  label: "Ghooramara",
                },
                {
                  value: "Explanation",
                  label: "Explanation",
                },
                {
                  value: "100. Ramchandra Pur",
                  label: "100. Ramchandra Pur",
                },
                {
                  value: "Bad Kajala",
                  label: "Bad Kajala",
                },
                {
                  value: "Ramchandra Pur",
                  label: "Ramchandra Pur",
                },
                {
                  value: "Talaimari",
                  label: "Talaimari",
                },
                {
                  value: "Queen Nagar",
                  label: "Queen Nagar",
                },
                {
                  value: "Mehrchandi",
                  label: "Mehrchandi",
                },
                {
                  value: "Nostalgia",
                  label: "Nostalgia",
                },
                {
                  value: "Chakpar Meherchandi",
                  label: "Chakpar Meherchandi",
                },
                {
                  value: "Mehrchandi",
                  label: "Mehrchandi",
                },
                {
                  value: "Hosnaganj",
                  label: "Hosnaganj",
                },
                {
                  value: "Sheikhpara",
                  label: "Sheikhpara",
                },
                {
                  value: "Dargapara",
                  label: "Dargapara",
                },
                {
                  value: "Zodhash",
                  label: "Zodhash",
                },
                {
                  value: "Sherusarpara",
                  label: "Sherusarpara",
                },
                {
                  value: "Hetmakhan Sajipara ",
                  label: "Hetmakhan Sajipara ",
                },
                {
                  value: "Hetamkhana",
                  label: "Hetamkhana",
                },
                {
                  value: "Malopara",
                  label: "Malopara",
                },
                {
                  value: "Rajahata",
                  label: "Rajahata",
                },
                {
                  value: "Kadirganj",
                  label: "Kadirganj",
                },
                {
                  value: "Matherpara",
                  label: "Matherpara",
                },
                {
                  value: "Karikarpara",
                  label: "Karikarpara",
                },
                {
                  value: "Shahajipara",
                  label: "Shahajipara",
                },
                {
                  value: "Sherusarpara",
                  label: "Sherusarpara",
                },
                {
                  value: "Fadkipara",
                  label: "Fadkipara",
                },
                {
                  value: "Kumara Para",
                  label: "Kumara Para",
                },
                {
                  value: "Sahib ganj ",
                  label: "Sahib ganj ",
                },
                {
                  value: "Saheb Bazar",
                  label: "Saheb Bazar",
                },
                {
                  value: "Queen market",
                  label: "Queen market",
                },
                {
                  value: "Malopara",
                  label: "Malopara",
                },
                {
                  value: "Ganakpara",
                  label: "Ganakpara",
                },
                {
                  value: "Miyapara",
                  label: "Miyapara",
                },
                {
                  value: "Dakkharbora",
                  label: "Dakkharbora",
                },
              ],
            },
            {
              value: "Matihar",
              label: "Matihar",
              area: [
                {
                  value: "Cajala",
                  label: "Cajala",
                },
                {
                  value: "Dharampur",
                  label: "Dharampur",
                },
                {
                  value: "Talaimari",
                  label: "Talaimari",
                },
                {
                  value: "Bad kajla",
                  label: "Bad kajla",
                },
                {
                  value: "Grazing land",
                  label: "Grazing land",
                },
                {
                  value: "Sahtbariya",
                  label: "Sahtbariya",
                },
                {
                  value: "Khojapur",
                  label: "Khojapur",
                },
                {
                  value: "Dashmari",
                  label: "Dashmari",
                },
                {
                  value: "Care Satbariya",
                  label: "Care Satbariya",
                },
                {
                  value: "Sishampur Dashmari",
                  label: "Sishampur Dashmari",
                },
                {
                  value: "University campus",
                  label: "University campus",
                },
                {
                  value: "Mirzapur",
                  label: "Mirzapur",
                },
                {
                  value: "Mascata Dighi",
                  label: "Mascata Dighi",
                },
                {
                  value: "Budhapara",
                  label: "Budhapara",
                },
                {
                  value: "Mohanpur",
                  label: "Mohanpur",
                },
                {
                  value: "Fruit garden",
                  label: "Fruit garden",
                },
                {
                  value: " Agricultural Firm",
                  label: " Agricultural Firm",
                },
                {
                  value: "Science and Industrial Laboratory",
                  label: "Science and Industrial Laboratory",
                },
                {
                  value: "Mehrchandi Badhupara",
                  label: "Mehrchandi Badhupara",
                },
              ],
            },
            {
              value: "Rajpara",
              label: "Rajpara",
              area: [
                {
                  value: "Kashaydanga",
                  label: "Kashaydanga",
                },
                {
                  value: " Shahajipara ",
                  label: " Shahajipara ",
                },
                {
                  value: "Raipara",
                  label: "Raipara",
                },
                {
                  value: " Aduburi ",
                  label: " Aduburi ",
                },
                {
                  value: " Ghuripara ",
                  label: " Ghuripara ",
                },
                {
                  value: "Salagacha ",
                  label: "Salagacha ",
                },
                {
                  value: "Hargram",
                  label: "Hargram",
                },
                {
                  value: "Hordupur",
                  label: "Hordupur",
                },
                {
                  value: "Hargagram Newpara",
                  label: "Hargagram Newpara",
                },
                {
                  value: "Hargram Ranidighi",
                  label: "Hargram Ranidighi",
                },
                {
                  value: "Hargram Colony",
                  label: "Hargram Colony",
                },
                {
                  value: "Hargram Bidirpara",
                  label: "Hargram Bidirpara",
                },
                {
                  value: "Nagarpara",
                  label: "Nagarpara",
                },
                {
                  value: "Mollapara",
                  label: "Mollapara",
                },
                {
                  value: "Sheikhpara",
                  label: "Sheikhpara",
                },
                {
                  value: "Tenpukur",
                  label: "Tenpukur",
                },
                {
                  value: "Baharampur",
                  label: "Baharampur",
                },
                {
                  value: "New Bisimla",
                  label: "New Bisimla",
                },
                {
                  value: "Laksmipur",
                  label: "Laksmipur",
                },
                {
                  value: "Hargam Bazar",
                  label: "Hargam Bazar",
                },
                {
                  value: "Bulanpur",
                  label: "Bulanpur",
                },
                {
                  value: "Goalpara",
                  label: "Goalpara",
                },
                {
                  value: "Keshabpur",
                  label: "Keshabpur",
                },
                {
                  value: "Nawabganj",
                  label: "Nawabganj",
                },
                {
                  value: "Rajpara",
                  label: "Rajpara",
                },
                {
                  value: "Mahisbathan",
                  label: "Mahisbathan",
                },
                {
                  value: "Kulupara",
                  label: "Kulupara",
                },
                {
                  value: "Bhatapara",
                  label: "Bhatapara",
                },
                {
                  value: "Laxmipur",
                  label: "Laxmipur",
                },
                {
                  value: "Chandipur",
                  label: "Chandipur",
                },
                {
                  value: "Serampore",
                  label: "Serampore",
                },
                {
                  value: "Kazihaata",
                  label: "Kazihaata",
                },
                {
                  value: "Sepipara",
                  label: "Sepipara",
                },
                {
                  value: "Betiapara",
                  label: "Betiapara",
                },
              ],
            },
            {
              value: "Shah Mokhdum",
              label: "Shah Mokhdum",
              area: [
                {
                  value: "Paba Natunpara",
                  label: "Paba Natunpara",
                },
                {
                  value: "Pabapara",
                  label: "Pabapara",
                },
                {
                  value: "Gangpara",
                  label: "Gangpara",
                },
                {
                  value: "Milpara",
                  label: "Milpara",
                },
                {
                  value: "Barobongram",
                  label: "Barobongram",
                },
                {
                  value: "Shahmokhdum Nagor",
                  label: "Shahmokhdum Nagor",
                },
                {
                  value: "Mahalderpara",
                  label: "Mahalderpara",
                },
                {
                  value: "Chayanir Abashik",
                  label: "Chayanir Abashik",
                },
                {
                  value: "Vaharalipara",
                  label: "Vaharalipara",
                },
                {
                  value: "Roypara",
                  label: "Roypara",
                },
                {
                  value: "Pabnapara",
                  label: "Pabnapara",
                },
                {
                  value: "Chalkpara",
                  label: "Chalkpara",
                },
                {
                  value: "Nutun Chalkpara",
                  label: "Nutun Chalkpara",
                },
                {
                  value: "Shekhpara",
                  label: "Shekhpara",
                },
                {
                  value: "Masterpara",
                  label: "Masterpara",
                },
                {
                  value: "Nampara",
                  label: "Nampara",
                },
                {
                  value: "Pachanipara",
                  label: "Pachanipara",
                },
                {
                  value: "Nawdapara",
                  label: "Nawdapara",
                },
                {
                  value: "Road Nawdapara",
                  label: "Road Nawdapara",
                },
                {
                  value: "Hat Nawdapara",
                  label: "Hat Nawdapara",
                },
                {
                  value: "Madho Nawdapara",
                  label: "Madho Nawdapara",
                },
                {
                  value: "Uttor Nawdapara",
                  label: "Uttor Nawdapara",
                },
                {
                  value: "Dakhin Nawdapara",
                  label: "Dakhin Nawdapara",
                },
                {
                  value: "Godagari",
                  label: "Godagari",
                },
                {
                  value: "Vugroil",
                  label: "Vugroil",
                },
                {
                  value: "Shontoshpur",
                  label: "Shontoshpur",
                },
                {
                  value: "Dangiparan",
                  label: "Dangiparan",
                },
                {
                  value: "Birostoil",
                  label: "Birostoil",
                },
                {
                  value: "Horisher Daing ",
                  label: "Horisher Daing ",
                },
                {
                  value: "Barobaria ",
                  label: "Barobaria ",
                },
                {
                  value: "Khirisin",
                  label: "Khirisin",
                },
                {
                  value: "Khirisintikor",
                  label: "Khirisintikor",
                },
                {
                  value: "Fudkipara",
                  label: "Fudkipara",
                },
                {
                  value: "Khirisho Fakirpara",
                  label: "Khirisho Fakirpara",
                },
                {
                  value: "Puraton Fudkipara",
                  label: "Puraton Fudkipara",
                },
                {
                  value: "Fudkipara",
                  label: "Fudkipara",
                },
                {
                  value: "Khirisho Dakhinpara",
                  label: "Khirisho Dakhinpara",
                },
                {
                  value: "Khirishon Darardhar",
                  label: "Khirishon Darardhar",
                },
              ],
            },
            {
              value: "Godagari",
              label: "Godagari",
              area: [
                {
                  value: "Gogram",
                  label: "Gogram",
                },
                {
                  value: "Godagari",
                  label: "Godagari",
                },
                {
                  value: "Char Ashariadaha",
                  label: "Char Ashariadaha",
                },
                {
                  value: "Deopara",
                  label: "Deopara",
                },
                {
                  value: "Pakri",
                  label: "Pakri",
                },
                {
                  value: "Basudebpur",
                  label: "Basudebpur",
                },
                {
                  value: "Matikata",
                  label: "Matikata",
                },
                {
                  value: "Mohanpur",
                  label: "Mohanpur",
                },
                {
                  value: "Rishikul ",
                  label: "Rishikul ",
                },
              ],
            },
            {
              value: "Tanore",
              label: "Tanore",
              area: [
                {
                  value: "Kalma ",
                  label: "Kalma ",
                },
                {
                  value: "Kamargaon",
                  label: "Kamargaon",
                },
                {
                  value: "Talanda",
                  label: "Talanda",
                },
                {
                  value: "Pachandar",
                  label: "Pachandar",
                },
                {
                  value: "Badhair",
                  label: "Badhair",
                },
                {
                  value: "Saranjai",
                  label: "Saranjai",
                },
                {
                  value: "Chanduria",
                  label: "Chanduria",
                },
              ],
            },
            {
              value: "Durgapur ",
              label: "Durgapur ",
              area: [
                {
                  value: "Kismat Gankair",
                  label: "Kismat Gankair",
                },
                {
                  value: "Joynagar",
                  label: "Joynagar",
                },
                {
                  value: "Jhaluka",
                  label: "Jhaluka",
                },
                {
                  value: "Deluabari",
                  label: "Deluabari",
                },
                {
                  value: "Dharmapur",
                  label: "Dharmapur",
                },
                {
                  value: "Noapara",
                  label: "Noapara",
                },
                {
                  value: "Maria",
                  label: "Maria",
                },
              ],
            },
            {
              value: "Paba",
              label: "Paba",
              area: [
                {
                  value: "Darshan Para ",
                  label: "Darshan Para ",
                },
                {
                  value: "Damkur ",
                  label: "Damkur ",
                },
                {
                  value: "Noahata ",
                  label: "Noahata ",
                },
                {
                  value: "Parila ",
                  label: "Parila ",
                },
                {
                  value: "Baragachhi ",
                  label: "Baragachhi ",
                },
                {
                  value: "Haragram ",
                  label: "Haragram ",
                },
                {
                  value: "Haripur ",
                  label: "Haripur ",
                },
                {
                  value: "Harian ",
                  label: "Harian ",
                },
                {
                  value: "Hujuri Para ",
                  label: "Hujuri Para ",
                },
                {
                  value: "Mahananda Khali",
                  label: "Mahananda Khali",
                },
                {
                  value: "Tikripara",
                  label: "Tikripara",
                },
                {
                  value: "Shripur",
                  label: "Shripur",
                },
                {
                  value: "Pillapara",
                  label: "Pillapara",
                },
                {
                  value: "Puthiapara",
                  label: "Puthiapara",
                },
                {
                  value: "Modhusudon pur",
                  label: "Modhusudon pur",
                },
                {
                  value: "Boroikuri",
                  label: "Boroikuri",
                },
                {
                  value: "Mdonhati",
                  label: "Mdonhati",
                },
                {
                  value: "Palopara",
                  label: "Palopara",
                },
                {
                  value: "Alaibidirpur",
                  label: "Alaibidirpur",
                },
                {
                  value: "Baghsara",
                  label: "Baghsara",
                },
                {
                  value: "Teghor",
                  label: "Teghor",
                },
                {
                  value: "Bagdhani",
                  label: "Bagdhani",
                },
                {
                  value: "Choubaria",
                  label: "Choubaria",
                },
                {
                  value: "Bosontopur",
                  label: "Bosontopur",
                },
                {
                  value: "Duari",
                  label: "Duari",
                },
                {
                  value: "Kumrapukur",
                  label: "Kumrapukur",
                },
                {
                  value: "Amgachi",
                  label: "Amgachi",
                },
                {
                  value: "Betkuri",
                  label: "Betkuri",
                },
                {
                  value: "Khandakatora",
                  label: "Khandakatora",
                },
                {
                  value: "Barogachi",
                  label: "Barogachi",
                },
                {
                  value: "Chalk Goaldaha",
                  label: "Chalk Goaldaha",
                },
                {
                  value: "Dadpur",
                  label: "Dadpur",
                },
                {
                  value: "Bagichapara",
                  label: "Bagichapara",
                },
                {
                  value: "Goalhod",
                  label: "Goalhod",
                },
                {
                  value: "Gopalhat",
                  label: "Gopalhat",
                },
                {
                  value: "Haider",
                  label: "Haider",
                },
                {
                  value: "Itaghati",
                  label: "Itaghati",
                },
                {
                  value: "Joykrishnopur",
                  label: "Joykrishnopur",
                },
                {
                  value: "Sonadanga",
                  label: "Sonadanga",
                },
                {
                  value: "Tojkandor",
                  label: "Tojkandor",
                },
                {
                  value: "Choto Amgachi",
                  label: "Choto Amgachi",
                },
                {
                  value: "Kanchipara",
                  label: "Kanchipara",
                },
                {
                  value: "Kanpara",
                  label: "Kanpara",
                },
                {
                  value: "Madhobpur",
                  label: "Madhobpur",
                },
                {
                  value: "Madhaipara",
                  label: "Madhaipara",
                },
                {
                  value: "Mathura",
                  label: "Mathura",
                },
                {
                  value: "Nawdapara",
                  label: "Nawdapara",
                },
                {
                  value: "Nagsar",
                  label: "Nagsar",
                },
                {
                  value: "Shobsar",
                  label: "Shobsar",
                },
                {
                  value: "Shuvopara",
                  label: "Shuvopara",
                },
                {
                  value: "Surjopur",
                  label: "Surjopur",
                },
                {
                  value: "Talgachi",
                  label: "Talgachi",
                },
                {
                  value: "Tekatapara",
                  label: "Tekatapara",
                },
                {
                  value: "Bajrapur",
                  label: "Bajrapur",
                },
                {
                  value: "Vogirothpur",
                  label: "Vogirothpur",
                },
                {
                  value: "Chalkparila",
                  label: "Chalkparila",
                },
                {
                  value: "Kanshandaporila",
                  label: "Kanshandaporila",
                },
                {
                  value: "Kamarparila",
                  label: "Kamarparila",
                },
                {
                  value: "Gholharia",
                  label: "Gholharia",
                },
                {
                  value: "Shirlia",
                  label: "Shirlia",
                },
                {
                  value: "Kapashmul",
                  label: "Kapashmul",
                },
                {
                  value: "Koyra",
                  label: "Koyra",
                },
                {
                  value: "Maria",
                  label: "Maria",
                },
                {
                  value: "Vallukpukur",
                  label: "Vallukpukur",
                },
                {
                  value: "Ramchandrapur",
                  label: "Ramchandrapur",
                },
                {
                  value: "Sarangpur",
                  label: "Sarangpur",
                },
                {
                  value: "Tebaria",
                  label: "Tebaria",
                },
                {
                  value: "Purapukur",
                  label: "Purapukur",
                },
                {
                  value: "Katalpara",
                  label: "Katalpara",
                },
              ],
            },
            {
              value: "Puthia ",
              label: "Puthia ",
              area: [
                {
                  value: "Baneshwar",
                  label: "Baneshwar",
                },
                {
                  value: "Belpukuria",
                  label: "Belpukuria",
                },
                {
                  value: "Bhalukgachhi",
                  label: "Bhalukgachhi",
                },
                {
                  value: "Jewpara",
                  label: "Jewpara",
                },
                {
                  value: "Puthia",
                  label: "Puthia",
                },
                {
                  value: "Silmaria",
                  label: "Silmaria",
                },
              ],
            },
            {
              value: "Baghmara",
              label: "Baghmara",
              area: [
                {
                  value: "Auch Para",
                  label: "Auch Para",
                },
                {
                  value: "Kachhari Kayali Para",
                  label: "Kachhari Kayali Para",
                },
                {
                  value: "Ganipur",
                  label: "Ganipur",
                },
                {
                  value: "Gobinda Para",
                  label: "Gobinda Para",
                },
                {
                  value: "Goalkandi",
                  label: "Goalkandi",
                },
                {
                  value: "Jhikra",
                  label: "Jhikra",
                },
                {
                  value: "Dwippur",
                  label: "Dwippur",
                },
                {
                  value: "Nardas",
                  label: "Nardas",
                },
                {
                  value: "Bara Bihanali",
                  label: "Bara Bihanali",
                },
                {
                  value: "Basu Para",
                  label: "Basu Para",
                },
                {
                  value: "Maria",
                  label: "Maria",
                },
                {
                  value: "Jogi Para",
                  label: "Jogi Para",
                },
                {
                  value: "Subhadanga",
                  label: "Subhadanga",
                },
                {
                  value: "Sreepur",
                  label: "Sreepur",
                },
                {
                  value: "Sonadanga",
                  label: "Sonadanga",
                },
                {
                  value: "Hamir Kutsha",
                  label: "Hamir Kutsha",
                },
              ],
            },
            {
              value: "Bagha",
              label: "Bagha",
              area: [
                {
                  value: "Arani",
                  label: "Arani",
                },
                {
                  value: "Gargari",
                  label: "Gargari",
                },
                {
                  value: "Pakuria",
                  label: "Pakuria",
                },
                {
                  value: "Bausa",
                  label: "Bausa",
                },
                {
                  value: "Bajubagha",
                  label: "Bajubagha",
                },
                {
                  value: "Manigram",
                  label: "Manigram",
                },
              ],
            },
            {
              value: "Mohanpur",
              label: "Mohanpur",
              area: [
                {
                  value: "Ghasigram",
                  label: "Ghasigram",
                },
                {
                  value: "Jahanabad",
                  label: "Jahanabad",
                },
                {
                  value: "Dhurail",
                  label: "Dhurail",
                },
                {
                  value: "Bakshimail",
                  label: "Bakshimail",
                },
                {
                  value: "Maugachhi",
                  label: "Maugachhi",
                },
                {
                  value: "Royghati",
                  label: "Royghati",
                },
              ],
            },
          ],
        },
        {
          value: "Sirajganj",
          label: "Sirajganj",
          thana: [
            {
              value: "Ullahpara",
              label: "Ullahpara",
            },
            {
              value: "Kazipur",
              label: "Kazipur",
            },
            {
              value: "Kamarkhanda ",
              label: "Kamarkhanda ",
            },
            {
              value: "Chauhali",
              label: "Chauhali",
            },
            {
              value: "Tarash",
              label: "Tarash",
            },
            {
              value: "Belkuchi",
              label: "Belkuchi",
            },
            {
              value: "Raiganj ",
              label: "Raiganj ",
            },
            {
              value: "Shahjadpur ",
              label: "Shahjadpur ",
            },
            {
              value: "Sirajganj Sadar ",
              label: "Sirajganj Sadar ",
            },
          ],
        },
      ],
    },
    {
      value: "Khulna",
      label: "Khulna",
      district: [
        {
          value: "Bagerhat",
          label: "Bagerhat",
          thana: [
            {
              value: "Kachua",
              label: "Kachua",
            },
            {
              value: "Chitalmari",
              label: "Chitalmari",
            },
            {
              value: "Fakirhat",
              label: "Fakirhat",
            },
            {
              value: "Bagerhat Sadar",
              label: "Bagerhat Sadar",
            },
            {
              value: "Mongla",
              label: "Mongla",
            },
            {
              value: "Morrelganj",
              label: "Morrelganj",
            },
            {
              value: "Mollahat",
              label: "Mollahat",
            },
            {
              value: "Rampal",
              label: "Rampal",
            },
            {
              value: "Sarankhola",
              label: "Sarankhola",
            },
          ],
        },
        {
          value: "Chuadanga",
          label: "Chuadanga",
          thana: [
            {
              value: "Alamdanga",
              label: "Alamdanga",
            },
            {
              value: "Chuadanga Sadar",
              label: "Chuadanga Sadar",
            },
            {
              value: "Jiban Nagar",
              label: "Jiban Nagar",
            },
            {
              value: "Damurhuda",
              label: "Damurhuda",
            },
            {
              value: "Mongla",
              label: "Mongla",
            },
          ],
        },
        {
          value: "Jessore",
          label: "Jessore",
          thana: [
            {
              value: "Abhaynagar",
              label: "Abhaynagar",
            },
            {
              value: "Keshabpur ",
              label: "Keshabpur ",
            },
            {
              value: "Chaugachha ",
              label: "Chaugachha ",
            },
            {
              value: "Jhikargachha",
              label: "Jhikargachha",
            },
            {
              value: "Bagherpara",
              label: "Bagherpara",
            },
            {
              value: "Manirampur",
              label: "Manirampur",
            },
            {
              value: "Jessore Sadar ",
              label: "Jessore Sadar ",
            },
            {
              value: "Sharsha",
              label: "Sharsha",
            },
          ],
        },
        {
          value: "Jhenaidah",
          label: "Jhenaidah",
          thana: [
            {
              value: "Kaliganj ",
              label: "Kaliganj ",
            },
            {
              value: "Kotchandpur",
              label: "Kotchandpur",
            },
            {
              value: "Jhenaidah Sadar",
              label: "Jhenaidah Sadar",
            },
            {
              value: "Maheshpur",
              label: "Maheshpur",
            },
            {
              value: "Shailkupa ",
              label: "Shailkupa ",
            },
            {
              value: "Harinakunda",
              label: "Harinakunda",
            },
          ],
        },
        {
          value: "Khulna",
          label: "Khulna",
          thana: [
            {
              value: "Batiaghata",
              label: "Batiaghata",
            },
            {
              value: "Daulatpur Thana",
              label: "Daulatpur Thana",
            },
            {
              value: "Dighalia",
              label: "Dighalia",
            },
            {
              value: "Khalishpur",
              label: "Khalishpur",
            },
            {
              value: "Khan Jahan Ali  ",
              label: "Khan Jahan Ali  ",
            },
            {
              value: "Paikgachha",
              label: "Paikgachha",
            },
            {
              value: "Phultala",
              label: "Phultala",
            },
            {
              value: "Rupsa",
              label: "Rupsa",
            },
            {
              value: "Sonadanga Model",
              label: "Sonadanga Model",
            },
            {
              value: "Terokhada",
              label: "Terokhada",
            },
          ],
        },
        {
          value: "Kushtia",
          label: "Kushtia",
          thana: [
            {
              value: "Bheramara",
              label: "Bheramara",
            },
            {
              value: "Daulatpur",
              label: "Daulatpur",
            },
            {
              value: "Khoksa",
              label: "Khoksa",
            },
            {
              value: "Kumarkhali",
              label: "Kumarkhali",
            },
            {
              value: "Kushtia Sadar",
              label: "Kushtia Sadar",
            },
            {
              value: "Mirpur",
              label: "Mirpur",
            },
            {
              value: "Islami university Thana",
              label: "Islami university Thana",
            },
          ],
        },
        {
          value: "Magura",
          label: "Magura",
          thana: [
            {
              value: "Sreepur",
              label: "Sreepur",
            },
            {
              value: "Shalikha",
              label: "Shalikha",
            },
            {
              value: "Mohammadpur",
              label: "Mohammadpur",
            },
            {
              value: "Magura Sadar",
              label: "Magura Sadar",
            },
          ],
        },
        {
          value: "Meherpur",
          label: "Meherpur",
          thana: [
            {
              value: "Mujibnagar",
              label: "Mujibnagar",
            },
            {
              value: "Meherpur",
              label: "Meherpur",
            },
            {
              value: "Gangni Police",
              label: "Gangni Police",
            },
          ],
        },
        {
          value: "Narail",
          label: "Narail",
          thana: [
            {
              value: "Narail Sadar",
              label: "Narail Sadar",
            },
            {
              value: "Lohagara",
              label: "Lohagara",
            },
            {
              value: "Kalia",
              label: "Kalia",
            },
          ],
        },
        {
          value: "Satkhira",
          label: "Satkhira",
          thana: [
            {
              value: "Narail Sadar",
              label: "Narail Sadar",
            },
            {
              value: "Lohagara",
              label: "Lohagara",
            },
            {
              value: "Kalia",
              label: "Kalia",
            },
          ],
        },
      ],
    },
    {
      value: "Barisal",
      label: "Barisal",
      district: [
        {
          value: "Barguna",
          label: "Barguna",
          thana: [
            {
              value: "Amtali",
              label: "Amtali",
            },
            {
              value: "Bamna",
              label: "Bamna",
            },
            {
              value: "barguna Sadar",
              label: "barguna Sadar",
            },
            {
              value: "Betagi",
              label: "Betagi",
            },
            {
              value: "patharghata",
              label: "patharghata",
            },
            {
              value: "Taltali",
              label: "Taltali",
            },
          ],
        },
        {
          value: "Barisal",
          label: "Barisal",
          thana: [
            {
              value: "Agailjhara",
              label: "Agailjhara",
            },
            {
              value: "Babuganj",
              label: "Babuganj",
            },
            {
              value: "Bakeraganj",
              label: "Bakeraganj",
            },
            {
              value: "Gaurnadi",
              label: "Gaurnadi",
            },
            {
              value: "Hizla",
              label: "Hizla",
            },
            {
              value: "Barisal Sadar",
              label: "Barisal Sadar",
            },
            {
              value: "Mhendiganj",
              label: "Mhendiganj",
            },
            {
              value: "Muladi",
              label: "Muladi",
            },
            {
              value: "Wazirpur",
              label: "Wazirpur",
            },
          ],
        },
        {
          value: "Bhola",
          label: "Bhola",
          thana: [
            {
              value: "Bhola Sadar",
              label: "Bhola Sadar",
            },
            {
              value: "burhanuddin",
              label: "burhanuddin",
            },
            {
              value: "Char Fasson",
              label: "Char Fasson",
            },
            {
              value: "Daulat Khan",
              label: "Daulat Khan",
            },
            {
              value: "Lalmohan",
              label: "Lalmohan",
            },
            {
              value: "Manpura",
              label: "Manpura",
            },
            {
              value: "Tazumuddin",
              label: "Tazumuddin",
            },
          ],
        },
        {
          value: "Jhalokati",
          label: "Jhalokati",
          thana: [
            {
              value: "Jhalokati Sadar",
              label: "Jhalokati Sadar",
            },
            {
              value: "Kanthalia",
              label: "Kanthalia",
            },
            {
              value: "NalChity",
              label: "NalChity",
            },
            {
              value: "Rajapur",
              label: "Rajapur",
            },
          ],
        },
        {
          value: "Patuakhali",
          label: "Patuakhali",
          thana: [
            {
              value: "Bauphal",
              label: "Bauphal",
            },
            {
              value: "Dashmina",
              label: "Dashmina",
            },
            {
              value: "Dumki",
              label: "Dumki",
            },
            {
              value: "Galachipa",
              label: "Galachipa",
            },
            {
              value: "Kalapara",
              label: "Kalapara",
            },
            {
              value: "Miraaganj",
              label: "Miraaganj",
            },
            {
              value: "Patuakhali Sadar",
              label: "Patuakhali Sadar",
            },
            {
              value: "Rangabali",
              label: "Rangabali",
            },
          ],
        },
        {
          value: "Pirojpur",
          label: "Pirojpur",
          thana: [
            {
              value: "Bhandaria",
              label: "Bhandaria",
            },
            {
              value: "Kawkhali",
              label: "Kawkhali",
            },
            {
              value: "Mathbaria",
              label: "Mathbaria",
            },
            {
              value: "Nazirpur",
              label: "Nazirpur",
            },
            {
              value: "Pirojpur Sadar",
              label: "Pirojpur Sadar",
            },
            {
              value: "Nesarabad",
              label: "Nesarabad",
            },
            {
              value: "Zianagar",
              label: "Zianagar",
            },
          ],
        },
      ],
    },
    {
      value: "Sylhet",
      label: "Sylhet",
      district: [
        {
          value: "Habiganj",
          label: "Habiganj",
          thana: [
            {
              value: "Ajmiriganj",
              label: "Ajmiriganj",
            },
            {
              value: "Bahubal",
              label: "Bahubal",
            },
            {
              value: "Baniachong",
              label: "Baniachong",
            },
            {
              value: "Chunarughat ",
              label: "Chunarughat ",
            },
            {
              value: "Hakiganj Sadar",
              label: "Hakiganj Sadar",
            },
            {
              value: "Lakhai",
              label: "Lakhai",
            },
            {
              value: "Madhabpur",
              label: "Madhabpur",
            },
            {
              value: "Nabiganj",
              label: "Nabiganj",
            },
          ],
        },
        {
          value: "Moulvibazar",
          label: "Moulvibazar",
          thana: [
            {
              value: "Barlekha",
              label: "Barlekha",
            },
            {
              value: "Juri",
              label: "Juri",
            },
            {
              value: "Kamalganj",
              label: "Kamalganj",
            },
            {
              value: "Kulaura ",
              label: "Kulaura ",
            },
            {
              value: "Maulvibazar Sadar",
              label: "Maulvibazar Sadar",
            },
            {
              value: "Rajnagar",
              label: "Rajnagar",
            },
            {
              value: "Sreemangal",
              label: "Sreemangal",
            },
          ],
        },
        {
          value: "Sunamganj",
          label: "Sunamganj",
          thana: [
            {
              value: "Bishwambarpur",
              label: "Bishwambarpur",
            },
            {
              value: "Chharak",
              label: "Chharak",
            },
            {
              value: "Dakshin Sunamganj",
              label: "Dakshin Sunamganj",
            },
            {
              value: "Derai",
              label: "Derai",
            },
            {
              value: "Dharampasha",
              label: "Dharampasha",
            },
            {
              value: "Dowarabazar",
              label: "Dowarabazar",
            },
            {
              value: "Jagannathpur",
              label: "Jagannathpur",
            },
            {
              value: "Sulla",
              label: "Sulla",
            },
            {
              value: "Sunamganj Sadar",
              label: "Sunamganj Sadar",
            },
            {
              value: "Thahirpur",
              label: "Thahirpur",
            },
          ],
        },
        {
          value: "Sylhet",
          label: "Sylhet",
          thana: [
            {
              value: "Balaganj",
              label: "Balaganj",
            },
            {
              value: "Beani Bazar",
              label: "Beani Bazar",
            },
            {
              value: "Bishwanath",
              label: "Bishwanath",
            },
            {
              value: "Companiganj",
              label: "Companiganj",
            },
            {
              value: "Dakshin Surma",
              label: "Dakshin Surma",
            },
            {
              value: "Fenchuganj",
              label: "Fenchuganj",
            },
            {
              value: "Golapganj",
              label: "Golapganj",
            },
            {
              value: "Gowainghat",
              label: "Gowainghat",
            },
            {
              value: "Jaintiapur",
              label: "Jaintiapur",
            },
            {
              value: "Kanaighat",
              label: "Kanaighat",
            },
            {
              value: "Sylhet Sadar",
              label: "Sylhet Sadar",
            },
            {
              value: "Zakiganj",
              label: "Zakiganj",
            },
          ],
        },
      ],
    },
    {
      value: "Rangpur",
      label: "Rangpur",
      district: [
        {
          value: "Dinajpur",
          label: "Dinajpur",
          thana: [
            {
              value: "Kotowali",
              label: "Kotowali",
            },
            {
              value: "Chirirbondor",
              label: "Chirirbondor",
            },
            {
              value: "Birol",
              label: "Birol",
            },
            {
              value: "Parbotipur",
              label: "Parbotipur",
            },
            {
              value: "Birganj",
              label: "Birganj",
            },
            {
              value: "Bochaganj",
              label: "Bochaganj",
            },
            {
              value: "Kaharole",
              label: "Kaharole",
            },
            {
              value: "Khanshama",
              label: "Khanshama",
            },
            {
              value: "Fulbari",
              label: "Fulbari",
            },
            {
              value: "Birampur",
              label: "Birampur",
            },
            {
              value: "Nababganj",
              label: "Nababganj",
            },
            {
              value: "Ghoraghat ",
              label: "Ghoraghat ",
            },
            {
              value: "Hakimpur",
              label: "Hakimpur",
            },
          ],
        },
        {
          value: "Gaibandha",
          label: "Gaibandha",
          thana: [
            {
              value: "Gaibandha sadar",
              label: "Gaibandha sadar",
            },
            {
              value: "Sadullahpur",
              label: "Sadullahpur",
            },
            {
              value: "Sundarganj",
              label: "Sundarganj",
            },
            {
              value: "Polashbari",
              label: "Polashbari",
            },
            {
              value: "Gobindganj",
              label: "Gobindganj",
            },
            {
              value: "Shaghata ",
              label: "Shaghata ",
            },
            {
              value: "Fulsori",
              label: "Fulsori",
            },
          ],
        },
        {
          value: "Kurigram",
          label: "Kurigram",
          thana: [
            {
              value: "Kurigram Sadar",
              label: "Kurigram Sadar",
            },
            {
              value: "Bazarhat",
              label: "Bazarhat",
            },
            {
              value: "Fulbari",
              label: "Fulbari",
            },
            {
              value: "Nogeshori",
              label: "Nogeshori",
            },
            {
              value: "Burunggamari",
              label: "Burunggamari",
            },
            {
              value: "Olipur ",
              label: "Olipur ",
            },
            {
              value: "Chilmari",
              label: "Chilmari",
            },
            {
              value: "Roumari",
              label: "Roumari",
            },
            {
              value: "Rajibpur",
              label: "Rajibpur",
            },
            {
              value: "Dushmara ",
              label: "Dushmara ",
            },
            {
              value: "Kochakata",
              label: "Kochakata",
            },
          ],
        },
        {
          value: "Lalmonirhat",
          label: "Lalmonirhat",
          thana: [
            {
              value: "Lalmonirhat",
              label: "Lalmonirhat",
            },
            {
              value: "Patgram",
              label: "Patgram",
            },
            {
              value: "Hatibandha",
              label: "Hatibandha",
            },
            {
              value: "Aditmari ",
              label: "Aditmari",
            },
            {
              value: "Kaligonj ",
              label: "Kaligonj ",
            },
          ],
        },
        {
          value: "Nilphamari",
          label: "Nilphamari",
          thana: [
            {
              value: "Nilfamari",
              label: "Nilfamari",
            },
            {
              value: "Saidpur",
              label: "Saidpur",
            },
            {
              value: "Jaldhaka",
              label: "Jaldhaka",
            },
            {
              value: "Kishorgong",
              label: "Kishorgong",
            },
            {
              value: "Domar",
              label: "Domar",
            },
            {
              value: "Dimla",
              label: "Dimla",
            },
          ],
        },
        {
          value: "Panchagarh",
          label: "Panchagarh",
          thana: [
            {
              value: "Panchagarh sadar",
              label: "Panchagarh sadar",
            },
            {
              value: "Boda",
              label: "Boda",
            },
            {
              value: "Atowari",
              label: "Atowari",
            },
            {
              value: "Tetulia ",
              label: "Tetulia ",
            },
            {
              value: "Debiganj",
              label: "Debiganj",
            },
          ],
        },
        {
          value: "Rangpur",
          label: "Rangpur",
          thana: [
            {
              value: "Kotwali",
              label: "Kotwali",
            },
            {
              value: "Taragonj",
              label: "Taragonj",
            },
            {
              value: "Bodorgonj",
              label: "Bodorgonj",
            },
            {
              value: "Gonagachora ",
              label: "Gonagachora ",
            },
            {
              value: "Mithapokor",
              label: "Mithapokor",
            },
            {
              value: "Pirojpur",
              label: "Pirojpur",
            },
            {
              value: "Pirgacha ",
              label: "Pirgacha ",
            },
            {
              value: "Kawnia",
              label: "Kawnia",
            },
          ],
        },
        {
          value: "Thakurgaon",
          label: "Thakurgaon",
          thana: [
            {
              value: "Thakurgaon",
              label: "Thakurgaon",
            },
            {
              value: "Baliadangi",
              label: "Baliadangi",
            },
            {
              value: "Ranishonkoil",
              label: "Ranishonkoil",
            },
            {
              value: "Pirganj ",
              label: "Pirganj ",
            },
            {
              value: "Horipur",
              label: "Horipur",
            },
            {
              value: "Ruhia",
              label: "Ruhia",
            },
          ],
        },
      ],
    },
    {
      value: "Mymensingh",
      label: "Mymensingh",
      district: [
        {
          value: "Jamalpur",
          label: "Jamalpur",
          thana: [
            {
              value: "Jamalpur",
              label: "Jamalpur",
            },
            {
              value: "Melandoho ",
              label: "Melandoho ",
            },
            {
              value: "Sarishabari ",
              label: "Sarishabari ",
            },
            {
              value: "Dewangonj",
              label: "Dewangonj",
            },
            {
              value: "Islampur",
              label: "Islampur",
            },
            {
              value: "Madargonj",
              label: "Madargonj",
            },
            {
              value: "Bakshigonj ",
              label: "Bakshigonj ",
            },
            {
              value: "bahadurabad",
              label: "bahadurabad",
            },
          ],
        },
        {
          value: "Mymensingh",
          label: "Mymensingh",
          thana: [
            {
              value: "Ishwarganj ",
              label: "Ishwarganj ",
            },
            {
              value: "Gaffargaon",
              label: "Gaffargaon",
            },
            {
              value: "Gauripur",
              label: "Gauripur",
            },
            {
              value: "Trishal",
              label: "Trishal",
            },
            {
              value: "Dhobaura",
              label: "Dhobaura",
            },
            {
              value: "Nandail",
              label: "Nandail",
            },
            {
              value: "Phulpur ",
              label: "Phulpur ",
            },
            {
              value: "Fulbaria ",
              label: "Fulbaria ",
            },
            {
              value: "Bhaluka",
              label: "Bhaluka",
            },
            {
              value: "Mymensingh Sadar ",
              label: "Mymensingh Sadar ",
            },
            {
              value: "Muktagachha ",
              label: "Muktagachha ",
            },
            {
              value: "Haluaghat ",
              label: "Haluaghat ",
            },
          ],
        },
        {
          value: "Netrokona",
          label: "Netrokona",
          thana: [
            {
              value: "Netrokona model",
              label: "Netrokona model",
            },
            {
              value: "Purbodhola",
              label: "Purbodhola",
            },
            {
              value: "Durgapur",
              label: "Durgapur",
            },
            {
              value: "Kendua",
              label: "Kendua",
            },
            {
              value: "Barhatta",
              label: "Barhatta",
            },
            {
              value: "Atpara",
              label: "Atpara",
            },
            {
              value: "Kalmakanda",
              label: "Kalmakanda",
            },
            {
              value: "Mohongonj",
              label: "Mohongonj",
            },
            {
              value: "Madan",
              label: "Madan",
            },
            {
              value: "Khaliajuri",
              label: "Khaliajuri",
            },
          ],
        },
        {
          value: "Sherpur",
          label: "Sherpur",
          thana: [
            {
              value: "Jhenaigati ",
              label: "Jhenaigati ",
            },
            {
              value: "Nakla",
              label: "Nakla",
            },
            {
              value: "Nalitabari ",
              label: "Nalitabari ",
            },
            {
              value: "Sherpur Sadar ",
              label: "Sherpur Sadar ",
            },
            {
              value: "Sreebardi ",
              label: "Sreebardi ",
            },
          ],
        },
      ],
    },
  ],
};
