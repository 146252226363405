const ServicesCheckData = [
  {
    checked: false,
    label: "Cash On Delivery",
    id: "16",
  },
  {
    checked: false,
    label: "Fulfilled By Daraz",
    id: "17",
  },
  {
    checked: false,
    label: "Free Shipping",
    id: "18",
  },
  {
    checked: false,
    label: "DarazMall",
    id: "19",
  },
];
export default ServicesCheckData;
