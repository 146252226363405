import React from 'react'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import { SummaryData } from './VisitorData'

const VisitorSummarySec = () => {
  return (
    <>
      <PageHeaderBreadCrumb
        Title="Visitor"
        pageTitle="Visitor-Summary"
      />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <span className='font-weight-bold'>Summary</span>
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th className="text-center">
                    Period
                  </th>
                  <th className="text-center">
                    Unique Visitors
                  </th>
                  <th className="text-center">
                    Visits
                  </th>
                </tr>
              </thead>
              <tbody>
                {SummaryData.length === 0 ?
                  <td colSpan="12" className='text-center bg-warning font-weight-bold h4'>No data available in table</td> : <>
                    {
                      SummaryData.map((Value, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {Value.time}
                            </td>
                            <td className="text-center">
                              {Value.Uniquevisitor}
                            </td>
                            <td className="text-center">
                              {Value.visit}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </>}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>

         {/* Default box */}
         <span className='font-weight-bold'>Last 10 Requests</span>
        <div className="card">
          <div className="card-body p-0">
            <table className="table table-striped projects">
              <thead>
                <tr>
                  <th className="text-center">
                  Request
                  </th>
                  <th className="text-center">
                  Referrer
                  </th>
                  <th className="text-center">
                  Visitor
                  </th>
                </tr>
              </thead>
              <tbody>
                {SummaryData.length === 0 ?
                  <td colSpan="12" className='text-center bg-warning font-weight-bold h4'>No data available in table</td> : <>
                    {
                      SummaryData.map((Value, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">
                             <span> {Value.date}</span><span> {Value.time}</span>
                             <p>Get: &nbsp;<a  target="_blank" rel="noreferrer" href={Value.getLink}>{Value.getLink}</a></p>
                            </td>
                            <td className="text-center">
                              {Value.Uniquevisitor}
                            </td>
                            <td className="text-center">
                              {Value.visit}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </>}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
    </>
  )
}

export default VisitorSummarySec