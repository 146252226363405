import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardOverlay,
  MDBCardImage,
  MDBBtn,
  MDBIcon,
} from "mdb-react-ui-kit";
import Title from "../SectionTitle/Title/Title";
import Titleimg from "../SectionTitle/Title_img/Title_img";
import { Link } from "react-router-dom";
import { EmagazineData } from "./EmagazineData";

const EmagazineSection = () => {
  return (
    <>
      <div className="e_magazine_section py-3">
        <Title section_title="E-Magazine" />
        <Titleimg />
        <div className="container">
          <div className="row py-1">
            <h6 className="text-center mb-0">---Weekley Report---</h6>
            {EmagazineData.map((Value, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-10 mx-auto mt-2"
                  key={index}
                >
                  
                    <MDBCard background="dark" className="text-white">
                      <MDBCardImage
                        overlay
                        src={Value.img}
                        alt="e-magazine-img"
                      />
                      <MDBCardOverlay className="text-center">
                        <MDBCardTitle>{Value.title}</MDBCardTitle>
                        <MDBCardText className="mb-2">{Value.text}</MDBCardText>
                        <MDBCardText className="mb-1">{Value.time}</MDBCardText>
                        <a href='/assest/image/e-magazine-pdf/e-magazine.pdf' target="_blank">  
                        <MDBBtn size='sm' style={{ backgroundColor: "#55acee" }}>
                        <MDBIcon fas icon="file-pdf" />{" "}
                          PDF View
                        </MDBBtn>
                        </a>
                        
                      </MDBCardOverlay>
                    </MDBCard>
                 
                </div>
              );
            })}
</div>
<div className="row py-1">
            <h6 className="text-center mb-0 mt-2">---Monthly Report---</h6>
            {EmagazineData.map((Value, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-10 mx-auto mt-2"
                  key={index}
                >
                  
                    <MDBCard background="dark" className="text-white">
                      <MDBCardImage
                        overlay
                        src={Value.img}
                        alt="e-magazine-img"
                      />
                      <MDBCardOverlay className="text-center">
                        <MDBCardTitle>{Value.title}</MDBCardTitle>
                        <MDBCardText className="mb-2">{Value.text}</MDBCardText>
                        <MDBCardText className="mb-1">{Value.time}</MDBCardText>
                        <a href='/assest/image/e-magazine-pdf/e-magazine.pdf' target="_blank">  
                        <MDBBtn size='sm' style={{ backgroundColor: "#55acee" }}>
                        <MDBIcon fas icon="file-pdf" />{" "}
                          PDF View
                        </MDBBtn>
                        </a>
                      </MDBCardOverlay>
                    </MDBCard>
                  
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmagazineSection;
