import React from 'react'
import ActiveCouponSec from '../../../../Components/admin/CouponSection/ActiveCouponSec'

const ActiveCoupon = () => {
  return (
    <>
    <ActiveCouponSec/>
    </>
  )
}

export default ActiveCoupon