import React from "react";
import "./HomePage.css";
import { Helmet } from 'react-helmet';
import BannerSliderMiniSliderRightBanner from "../../../Components/frontend/home/Banner_Slider_&_Mini_Carousel_&_RightBanner/BannerSlider_MiniSlider_RightBanner";
import TopCollection from "../../../Components/frontend/home/TopCollectionSection/TopCollection";
import FeaturedCategories from "../../../Components/frontend/home/Featured _Categories/FeaturedCategories";
import NewArrivals from "../../../Components/frontend/home/New_Arrivals_Carousel/NewArrivals";
import AllProductSection from "../../../Components/frontend/home/AllProductsSection/AllProductSection";
import GiftitemBannerProductCarousel from "../../../Components/frontend/home/Gift_item_Banner_&_ProductCarousel/Gift_item_Banner_&_ProductCarousel";
import BrandCarousel from "../../../Components/frontend/home/Brand_Carousle_Section/BrandCarousel";
// import MoreToLove from '../../../Components/frontend/home/More _To _Love/MoreToLove';
import ShopByDepartmentSection from "../../../Components/frontend/ShopByDepartmentSection/ShopByDepartmentSection";
import NewsLetter from "../../../Components/frontend/home/NewsLetter/NewsLetter";
import SpecialServicesSection from "../../../Components/frontend/home/SpecialServicesSection/SpecialServicesSection";
import TraditionalSection from "../../../Components/frontend/home/TraditionalSection/TraditionalSection";
import WeeklyOfferSection from "../../../Components/frontend/home/WeeklyOffterSection/WeeklyOfferSection";
import ImageStoreSection from "../../../Components/frontend/home/ImageStoreSection/ImageStoreSection";
import StoreSection from "../../../Components/frontend/home/StoreSection/StoreSection";
import AreaSearch from "../../../Components/frontend/home/AreaSearchSection/AreaSearchSection";
import HomeBlogSection from "../../../Components/frontend/home/HomeBlogSection/HomeBlogSection";
import Documentory from "../../../Components/frontend/home/Documentory/Documentory";

const HomePage = () => {
  
  return (
    <>

       <Helmet>
            <meta charSet="utf-8" />
            <title>
             OurRajshahi - One Click Total Solution
            </title>
            <meta name="description" content="OurRajshahi - Collection of all District Branding Online Shopping" />
        </Helmet>

     <div className='home bg-light'>
      <div className='shop_by_depertment'>
        <ShopByDepartmentSection />
      </div>
      <BannerSliderMiniSliderRightBanner />
      <SpecialServicesSection />
      <FeaturedCategories />
      <NewArrivals />
      <TopCollection />
        {/* gift item &  product-carousel*/}
        <GiftitemBannerProductCarousel />
        <WeeklyOfferSection  />
      <TraditionalSection />
      <AreaSearch />
      <StoreSection />
      {/* recent blog post*/}
      <HomeBlogSection />
       <Documentory />
      <ImageStoreSection />
      <AllProductSection />
      <BrandCarousel />
      {/* <MoreToLove/> */}
      <NewsLetter />
      </div>
    </>
  );
};

export default HomePage;
