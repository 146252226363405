import React, {useState, useContext } from "react";
import { Link, useNavigate} from "react-router-dom";
import cartNoteContext from "../Context/Cart/cartNoteContext";
const GridView = ({
  product_img,
  selling_price,
  product_name,
  id,
  qty,
  product_colors,
  product_description,
  brand
}) => {
  const { itemCart, addToBasketCart, removeItemCart } =
    useContext(cartNoteContext);

// defaults para metter pass add to cart with card icon
const [defaults, setDefaults] = useState({
  color:'',
  size:'', 
  countqty: 1,
 });

 const history = useNavigate();
// filter cart id by product id-----get single cart item
const filterCart = itemCart?.filter((cart) => cart?.product_id === id);

// check cart id by product  id------true or false
let checkCart = itemCart?.some((cart) => cart.product_id === id);


const handleAddtoCart = (pro_name)=>{
  product_colors?.length > 0 ?
  history(`/product/${pro_name}`)
  :
  addToBasketCart(
    id,
    defaults.color,
    defaults.size,
    defaults.countqty,
  )
  
}

  return <>
                       
      <div className='card hover-shadow my-2'>
        <div className='row'>
          <div className='col-md-4 col-10 mx-auto'>
            <div>
            <Link to={`/product/${product_name}`}>
          {
            product_img && 
            <img src={`${process.env.REACT_APP_API_URL}${product_img}`}
             alt='products-img' 
            className='grid_img' />
          }
       
        </Link>
              {checkCart ? (
                <div className='text-center mb-3'>
                  <span
                    className='grid_cart p-2 bg-light text-danger rounded-circle  border border-info mt-3'
                    onClick={()=> removeItemCart(filterCart[0]?.id, filterCart[0]?.product_id)}>
                     <i class='fas fa-cart-plus  text-danger'></i>
                  </span>
                </div>
              ) : (
                <div className='text-center mb-3'>
                  <span
                    className='grid_cart mt-3 p-2 text-danger rounded-circle  border border-info'
                    onClick={()=> handleAddtoCart(product_name)}>
                    <i className='text-info rounded-circle fas fa-cart-plus'></i>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='col-md-8 col-10 mx-auto'>
            <div className='grid_product_details mt-3'>
              <Link className='text-dark fw-light' to=''>
                <p className='mb-2 name'>{product_name}</p>
              </Link>
              <p className='mb-0 prices fw-bold'>
                <span>BDT: </span>৳ {selling_price}
              </p>
              <p className='mb-2 brand fw-light'>
                <span className='fw-normal'>Brand: </span>
                <Link className='text-dark fw-light' to=''>
                  {brand?.brand_name}
                </Link>
              </p>
              <p className='mb-2 rating_wrap fw-light'>
                <span className='fw-normal'>Rating: </span>
                <Link className='text-dark fw-light' to=''>
                 
                </Link>
              </p>
            
                  {product_colors?.map((color)=>{
                    return (
              <p className='mb-2 color_wrap fw-light'>
              <span className='fw-normal'>Color: </span>
              <Link className='text-dark fw-light' to=''>
                                {
                                  color?.color_name
                                }
              </Link>
              </p>
                    )
                  })}
                
              <p className='mb-1 color fw-light'>
                <span className='fw-normal blockquote-footer'>Bangladesh</span>
              </p>
              <div
                    dangerouslySetInnerHTML={{
                      __html:
                      product_description == null
                            ? ""
                            : 
                            product_description &&
                            product_description,
                    }}
                  />         
                      
            </div>
          </div>
        </div>
      </div>
  
  </>;
};

export default GridView;
