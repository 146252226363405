import React from 'react'
import ViewProductWrapper from '../../../../Components/admin/ProductSection/ViewProductWrapper'

const ViewProduct = () => {
  return (
    <>
      <ViewProductWrapper/>
    </>
  )
}

export default ViewProduct