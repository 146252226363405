import React from 'react'
import {InlineShareButtons} from 'sharethis-reactjs';
const ShareBtn = ({data}) => {
    return (
        <>
                     {/* social share */}
                     <div className='mt-2 blog_share_btn'>
                            
                            <InlineShareButtons
                                    config={{
                                      alignment: 'center',  // alignment of buttons (left, center, right)
                                      color: 'white',      // set the color of buttons (social, white)
                                      enabled: true,        // show/hide buttons (true, false)
                                      font_size: 13,        // font size for the buttons
                                      labels: 'cta',        // button labels (cta, counts, null)
                                      language: 'en',       // which language to use (see LANGUAGES)
                                      networks: [           // which networks to include (see SHARING NETWORKS)
                                        'facebook',
                                        'twitter',
                                        'linkedin',
                                        'pinterest',
                                        'whatsapp',
                                        'sharethis'
                                      ],
                                      padding: 10,          // padding within buttons (INTEGER)
                                      radius: 4,            // the corner radius on each button (INTEGER)
                                      show_total: false,
                                      size: 33,             // the size of each button (INTEGER)
                          
                                      // OPTIONAL PARAMETERS
                                    url: `https://ourrajshahi.com/product/${data?.product_slug}`, // (defaults to current url)
                                    image: `${process.env.REACT_APP_API_URL}${data?.product_img}`, // (defaults to og:image or twitter:image)
                                    description: data?.meta_description,     // (defaults to og:description or twitter:description)
                                    title: data?.product_name,          // (defaults to og:title or twitter:title)
                                    message: 'custom email text',     // (only for email sharing)
                                    subject: 'custom email subject',  // (only for email sharing)
                                    username: 'custom twitter handle' // (only for twitter sharing) // (only for twitter sharing)
                                    }}
                                  />
                            </div>
        </>
    )
}

export default ShareBtn
