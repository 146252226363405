import React, { useState, useEffect, useContext } from "react";
import {InlineShareButtons} from 'sharethis-reactjs';
import axios from "axios";
import {Helmet} from "react-helmet";
import ContentLoader from "react-content-loader"
import moment from 'moment'
import "./BlogSinglePost.css";
import { FcFolder } from "react-icons/fc";
import { VscComment } from "react-icons/vsc";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBCardTitle,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCardFooter,
  MDBTextArea,
} from "mdb-react-ui-kit";
import authContext from '../../../auth/Context/AuthUser/authContext';

const BlogSinglePost = (props) => {
  // auth login context
  const { Login, toggleShow} =useContext(authContext);
  const { slug } = useParams();
  const [single_blog_post, setSingle_blog_post] = useState([]);
  const [loading, setloading] = useState(true);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
     // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  const history = useNavigate();

  // get data single post
  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
      const res = await axios.get(`blog/post/single-post/${slug}`);
      if(isMounted){
        if (res.data.status === 200) {
          setSingle_blog_post(res.data.single_blog_post);
        }else if (res.data.status === 404) {
          toast.error(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history("/blog");
        }
        setloading(false);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }

   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, [slug, rerendering]);



   //  blog comments
   const [blog_comment, setBlog_comment]=useState({
        'comment_body': ''
    });
    const {comment_body}=blog_comment;

   //  blog comments reply
   const [blog_comment_reply, setBlog_comment_reply]=useState({
    'comment_id':'',
    'reply_body': ''
  });

  // comment reply show hide
  const [show_reply, setShow_reply]=useState(null);

  const handleToggleReply = (comment_id, user_name) => {
    show_reply === comment_id ? setShow_reply(null): setShow_reply(comment_id);
    show_reply === comment_id ? setBlog_comment_reply({...blog_comment_reply, comment_id: '', reply_body: ''}) : setBlog_comment_reply({...blog_comment_reply, comment_id: comment_id, reply_body: `@${user_name} `});

  //  comment reply click  reply input hide
    if(comment_id){
      setShow_reply_with_reply(null);
    }
  }



  // comment reply with reply show hide
const [show_reply_with_reply, setShow_reply_with_reply]=useState(null);
const handleToggleReplywithReply = (reply_id , comment_id, user_name) => {
  show_reply_with_reply === reply_id ? setShow_reply_with_reply(null): setShow_reply_with_reply(reply_id);
  show_reply_with_reply === reply_id ? setBlog_comment_reply({...blog_comment_reply, comment_id: '', reply_body: ''}) : setBlog_comment_reply({...blog_comment_reply, comment_id: comment_id, reply_body: `@${user_name} `});

  //  reply id click  comment input hide
  if(reply_id){
    setShow_reply(null);
  }
}

  
   const handleChange = (e)=>{
    setBlog_comment({...blog_comment , [e.target.name]: e.target.value});
  }

   const handleChangeReply = (e)=>{
      setBlog_comment_reply({...blog_comment_reply , [e.target.name]: e.target.value});
    }

  // blog comment submit
  const handleSubmit_comment = (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_id", single_blog_post.id);
    formData.append("comment_body", comment_body);
    
  if(Login){
    axios
    .post(`/blog/post/comment/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setBlog_comment({
          'post_id': '',
          'comment_body': ''
    });
        setRerendering(!rerendering);
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
      }
    })
    .catch((err) => {});
  }
      
  }

  // blog comment reply submit
  const handleSubmit_comment_reply = (e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("post_id", single_blog_post.id);
    formData.append("comment_id", blog_comment_reply.comment_id);
    formData.append("reply_body", blog_comment_reply.reply_body);
    
  if(Login){
    axios
    .post(`/blog/post/comment/reply/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShow_reply(null)
        setBlog_comment_reply({...blog_comment_reply, comment_id: '', reply_body: ''}) 
        setRerendering(!rerendering);
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
      }
    })
    .catch((err) => {});
  }
      
  }
     








  return (
    <>
      {/* seo meta tag  start */}
      <Helmet>
        ‍<title>{single_blog_post?.heading}</title>
        <meta name='keywords' content={single_blog_post?.tag} />
        <meta name='description' content={single_blog_post?.post_description} />
        <meta name='subject' content={single_blog_post?.post_description} />
        <meta name='topic' content={single_blog_post?.post_description} />
          <meta
            name='category'
            content={single_blog_post?.blog_category?.category_name}
          />

        <meta name='og:title' content={single_blog_post?.heading} />
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}`}
        />
        <meta
          property='og:image:alt'
          content={single_blog_post?.heading}
        />
        <meta
          name='og:description'
          content={single_blog_post?.post_description}
        />
        <meta
          name='twitter:title'
          content={single_blog_post?.heading}
        />
        <meta
          name='twitter:description'
          content={single_blog_post?.post_description}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}`}
        />
        <meta
          name='twitter:image:alt'
          content={single_blog_post?.heading}
        />
        <meta itemProp='name' content={single_blog_post?.post_description} />
        <meta
          itemProp='description'
          content={single_blog_post?.post_description}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}`}
        />
        <meta itemProp='name' content={single_blog_post?.post_description} />
        <meta
          itemProp='image'
          ontent={`${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={single_blog_post?.post_description}
        />
      </Helmet>
      {/* seo meta tag  end */}
   
                <div className='blog_views mt-1'>
                  <MDBCard className='border d-block mx-auto'>
                  <MDBCardBody  className='text-center'>
                    {loading ? (
                      // page loading start
                      <>
                      <div className='mx-auto text-center'>
                      <ContentLoader
                        viewBox='0 0 500 350'
                        height="50%" 
                        width="50%"
                        backgroundColor='#f3f3f3'
                        foregroundColor='#ecebeb'
                        {...props}>
                        <path d='M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z' />
                        <ellipse cx='120' cy='140' rx='28' ry='28' />
                      </ContentLoader>
                      <br/>
                      <ContentLoader 
                        viewBox="0 0 400 180"  
                        height="80%" 
                        width="80%" {...props}>
                      <rect x="0" y="13" rx="4" ry="4" width="550" height="9" />
                      <rect x="0" y="29" rx="4" ry="4" width="150" height="8" />
                      <rect x="0" y="50" rx="4" ry="4" width="450" height="10" />
                      <rect x="0" y="65" rx="4" ry="4" width="450" height="10" />
                      <rect x="0" y="79" rx="4" ry="4" width="150" height="10" />
                      <rect x="0" y="99" rx="5" ry="5" width="450" height="200" />
                    </ContentLoader>
                    <ContentLoader 
                    viewBox="0 0 476 150"  
                        height="80%" 
                        width="80%" {...props}>
                    <rect x="48" y="8" width="88" height="6" rx="3" />
                    <rect x="48" y="26" width="52" height="6" rx="3" />
                    <rect x="0" y="56" width="410" height="6" rx="3" />
                    <rect x="0" y="72" width="380" height="6" rx="3" />
                    <rect x="0" y="88" width="178" height="6" rx="3" />
                    <circle cx="20" cy="20" r="20" />
                  </ContentLoader>
                  </div>
                      </>
                      
                      // page loading end
                    ) : (
                      <>
                      {
                        !single_blog_post?.blog_image ?
                        <ContentLoader
                        viewBox='0 0 500 500'
                        height={200}
                        width={200}
                        backgroundColor='#f3f3f3'
                        foregroundColor='#ecebeb'
                        {...props}>
                        <path d='M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z' />
                        <ellipse cx='120' cy='140' rx='28' ry='28' />
                      </ContentLoader>
                      : <MDBCardImage
                        className='rounded img-fluid post_img'
                        src={
                          single_blog_post?.blog_image == null
                            ? null
                            : `${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}`
                        }
                        fluid
                        alt='blog_img'
                      />
                      }
                        
                        <MDBCardTitle className='pt-2'>
                          <p>{single_blog_post?.heading}</p>
                        </MDBCardTitle>
                        <MDBCardText>
                          <div className='post_info'>
                            <span className='date px-2'>
                              <i className='fas fa-calendar-alt pe-1'></i>

                              {moment(single_blog_post?.created_at).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                            {single_blog_post?.slug && (
                              <>
                                <Link
                                  to={`/blog/post/${single_blog_post?.slug.toLowerCase()}`}>
                                  <span className='post-admin'>
                                    <i className='fas fa-user-alt pe-1'></i>
                                    {single_blog_post?.user?.name}
                                  </span>
                                </Link>
                                <Link
                                  to={`/blog/category/${single_blog_post?.blog_category?.category_slug}`}>
                                  <span className='post_cate_gory px-1'>
                                    <FcFolder />
                                    &nbsp;
                                    {
                                      single_blog_post?.blog_category
                                        .category_name
                                    }
                                  </span>
                                </Link>
                                <Link
                                  to={`/blog/post/${single_blog_post?.slug.toLowerCase()}`}>
                                  <span className='comment ps-2'>
                                    <VscComment />
                                  </span>
                                </Link>
                              </>
                            )}
                          </div>
                          <div className='mt-4 blog_views_discription'>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: single_blog_post?.post_description,
                              }}
                            />
                          </div>

                          {/* social share */}
                          <div className='mt-2 blog_share_btn'>
                            
                          <InlineShareButtons
                                  config={{
                                    alignment: 'center',  // alignment of buttons (left, center, right)
                                    color: 'white',      // set the color of buttons (social, white)
                                    enabled: true,        // show/hide buttons (true, false)
                                    font_size: 13,        // font size for the buttons
                                    labels: 'cta',        // button labels (cta, counts, null)
                                    language: 'en',       // which language to use (see LANGUAGES)
                                    networks: [           // which networks to include (see SHARING NETWORKS)
                                      'facebook',
                                      'twitter',
                                      'linkedin',
                                      'pinterest',
                                      'whatsapp',
                                      'sharethis'
                                    ],
                                    padding: 10,          // padding within buttons (INTEGER)
                                    radius: 4,            // the corner radius on each button (INTEGER)
                                    show_total: false,
                                    size: 33,             // the size of each button (INTEGER)
                        
                                    // OPTIONAL PARAMETERS
                                    url: `https://ourrajshahi.com/blog/post/${single_blog_post?.slug.toLowerCase()}`, // (defaults to current url)
                                    image: `${process.env.REACT_APP_API_URL}${single_blog_post?.blog_image}` , // (defaults to og:image or twitter:image)
                                    description: single_blog_post?.post_description,     // (defaults to og:description or twitter:description)
                                    title: single_blog_post?.heading ,          // (defaults to og:title or twitter:title)
                                    message: 'custom email text',     // (only for email sharing)
                                    subject: 'custom email subject',  // (only for email sharing)
                                    username: 'custom twitter handle' // (only for twitter sharing)
                                  }}
                                />
                          </div>
                          







                          {/* add comments */}
                          <div className='blog_add_comment mt-5'>
                            <form onSubmit={handleSubmit_comment}>
                              <div>
                                <MDBTextArea
                                  label='Add Your Comment'
                                  id='comment_body'
                                  name='comment_body'
                                  onChange={handleChange}
                                  value={comment_body}
                                  rows={3}
                                  style={{ backgroundColor: "#fff" }}
                                  wrapperClass='w-100'
                                />
                                {
                                  validation_error?.comment_body && 
                                  <span className='text-danger text-start'>
                                  {validation_error?.comment_body}
                                </span>
                                }
                                
                              </div>
                              </form>
                              {/*--- user login check comment submit btn with login pop up --- */}
                              {Login ? (
                                <MDBBtn
                                  type='submit'
                                  onClick={handleSubmit_comment}
                                  className='mt-2 float-end'
                                  color='info'
                                  >
                                  <i className='far fa-paper-plane'></i>&nbsp;
                                  Submit
                                </MDBBtn>
                                
                              ) : (
                                <MDBBtn
                                  type='submit'
                                  onClick={toggleShow}
                                  className='mt-2 float-end'
                                  color='info'
                                  >
                                  <i className='far fa-paper-plane'></i>&nbsp;
                                  Submit
                                </MDBBtn>
                              )}
                            
                          </div>
                        </MDBCardText>
                      </>
                    )}
                    </MDBCardBody>
                    {/* all comments Views */}
                  <div className='mt-2 container'>
                    { loading ? null : 
                      <h4 class='mb-0 text-start border-bottom'>comments</h4>
                    }
                    <MDBCardBody className='p-0 pb-4'>
                      {
                        single_blog_post?.blog_comment?.map((comment)=>{
                          return (
                       
                            <>
                              <div
                                className='d-flex flex-start align-items-center'
                                key={comment.id}>
                                <MDBCardImage
                                  className='rounded-circle shadow-1-strong me-3'
                                  src={`${process.env.REACT_APP_API_URL}${comment.user?.profile_image}`}
                                  alt='avatar'
                                  width='50'
                                  height='50'
                                />
                                <div>
                                  <h6 className='fw-bold text-dark mb-1'>
                                    {comment.user?.name}
                                  </h6>
                                  <p className='text-muted small mb-0'>
                                    Shared publicly -{" "}
                                    {moment(comment.created_at).fromNow()}
                                  </p>
                                </div>
                              </div>

                              <p className='mt-2 pb-2'>
                                {comment?.comment_body}
                              </p>
                              
                              {/* like, comment, reply icon */}
                              <div className='small d-flex justify-content-start mb-3 p-1 border-bottom'>
                                <a
                                  href='#!'
                                  className='d-flex align-items-center me-2 text-info'>
                                  <MDBIcon far icon='thumbs-up me-2' />
                                  <p className='mb-0'>Like</p>
                                </a>
                                <a
                                  href='#!'
                                  className='d-flex align-items-center me-2 text-info'>
                                  <MDBIcon far icon='comment-dots me-2' />
                                  <p className='mb-0'>Comment</p>
                                </a>
                                <a
                                  href='#!'
                                  onClick={() =>
                                    handleToggleReply(comment.id, comment.user?.name)
                                  }
                                  className='link-muted text-info'>
                                  <MDBIcon fas icon='reply me-1' /> Reply
                                </a>
                              </div>

                              {/* user comment reply view */}
                            {
                               comment?.available_blog_comment_reply?.map((comment_reply, idx)=>{
                                return(
                                  <>
                                  <div className='d-flex flex-start my-3 ms-4' key={comment_reply.id}>
                                <a className='ms-2' href='##'>
                                  <MDBCardImage
                                    className='rounded-circle shadow-1-strong me-3'
                                    src={`${process.env.REACT_APP_API_URL}${comment_reply.user?.profile_image}`}
                                    alt='avatar'
                                    width='40'
                                    height='40'
                                  />
                                </a>

                                <div className='flex-grow-1 flex-shrink-1'>
                                  <div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <p className='mb-1'>
                                       {
                                        comment_reply.user?.name
                                       }
                                        <span className='small'>
                                           - {moment(comment_reply?.created_at).fromNow()}
                                        </span>
                                      </p>
                                      <a
                                  href='#!'
                                  onClick={() =>
                                    handleToggleReplywithReply(comment_reply?.id, comment.id,  comment_reply.user?.name)
                                  }
                                  className='link-muted'>
                                  <MDBIcon fas icon='reply me-1 text-info' /> Reply
                                </a>
                                    </div>
                                    <p className='small mb-0'>
                                      {
                                        comment_reply?.reply_body
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* comment reply show hide  */}
                              {show_reply_with_reply === comment_reply.id && (
                                <MDBCardFooter
                                  className='pt-2 pb-4 border-0 p-0'
                                  // style={{ backgroundColor: "#f8f9fa" }}
                                  >
                                  <div className='d-flex flex-start w-100'>
                                    <MDBCardImage
                                      className='rounded-circle shadow-1-strong me-3'
                                      src={`${process.env.REACT_APP_API_URL}${comment_reply.user?.profile_image}`}
                                      alt='avatar'
                                      width='30'
                                      height='30'
                                    />
                                    {/* comment reply form */}
                                    <form className='w-100' onSubmit={handleSubmit_comment_reply}>
                                      <div>
                                      <MDBInput
                                        label='Add Your Reply'
                                        id='reply_body'
                                        textarea
                                        rows={4}
                                        name='reply_body'
                                        onChange={handleChangeReply}
                                        value={blog_comment_reply.reply_body}
                                        wrapperClass='w-100'
                                      />
                                      </div>
                                    </form>
                                  </div>
                                  <div className='float-end mt-2 pt-1'>
                                    {Login ? (
                                      <MDBBtn
                                        onClick={handleSubmit_comment_reply}
                                        size='sm'
                                        color='info'
                                        className='me-1'>
                                        Reply
                                      </MDBBtn>
                                    ) : (
                                      <MDBBtn
                                        onClick={toggleShow}
                                        size='sm'
                                        color='info'
                                        className='me-1'>
                                        Reply
                                      </MDBBtn>
                                    )}
                                  </div>
                                </MDBCardFooter>
                              )}
                              </>
                                )
                               })
                            }
                              

                              {/* comment reply show hide  */}
                              {show_reply === comment.id && (
                                <MDBCardFooter
                                  className='pt-2 pb-4 border-0 p-0'
                                  style={{ backgroundColor: "#f8f9fa" }}>
                                  <div className='d-flex flex-start w-100'>
                                    <MDBCardImage
                                      className='rounded-circle shadow-1-strong me-3'
                                      src={`${process.env.REACT_APP_API_URL}${comment.user?.profile_image}`}
                                      alt='avatar'
                                      width='30'
                                      height='30'
                                    />
                                    {/* comment reply form */}
                                    <form className='w-100' onSubmit={handleSubmit_comment_reply}>
                                      <div>
                                      <MDBInput
                                        label='Add Your Reply'
                                        id='reply_body'
                                        textarea
                                        rows={4}
                                        name='reply_body'
                                        onChange={handleChangeReply}
                                        value={blog_comment_reply.reply_body}
                                        wrapperClass='w-100'
                                      />
                                      </div>
                                    </form>
                                  </div>
                                  <div className='float-end mt-2 pt-1'>
                                    {Login ? (
                                      <MDBBtn
                                        onClick={handleSubmit_comment_reply}
                                        size='sm'
                                        color='info'
                                        className='me-1'>
                                        Reply
                                      </MDBBtn>
                                    ) : (
                                      <MDBBtn
                                        onClick={toggleShow}
                                        size='sm'
                                        color='info'
                                        className='me-1'>
                                        Reply
                                      </MDBBtn>
                                    )}
                                  </div>
                                </MDBCardFooter>
                              )}
                            </>
                          );
                        })
                      }
                      
                    </MDBCardBody>                  
                  </div>
                  </MDBCard>
                </div>
              
                
    </>
  );
};

export default BlogSinglePost;










