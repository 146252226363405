import React from 'react'
import CommingSoonSection from '../../../Components/frontend/CommingSoonSection/CommingSoonSection'
const text=['Career is Coming Soon!']
const Career = () => {
    return (
        <>
            <CommingSoonSection
             typed_text={text}
            />
        </>
    )
}

export default Career
