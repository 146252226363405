import React, { useState, useEffect } from "react";
import "./AreaSearch.css";
import {
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import { Formik } from "formik";
import { data } from "./SearchOptionData";

// import axios from "axios";
//const custom_bg = { backgroundColor: "rgb(223, 223, 223)" }; // #d7edf2

const AreaSearchSection = () => {
  const [areaCheckOption, setareaCheckOption] = useState(true);
  // const [api, setapi] = useState();
  // useEffect(() => {
  //   axios
  //     .get("https://jsonplaceholder.typicode.com/posts/1")
  //     .then((res) => {
  //       setapi(res.data);
  //       console.log(res.data);
  //     })
  //     .catch((erorr) => {
  //       console.log(erorr);
  //     });
  // });
  // useEffect(() => {
  //   new google.maps.places.Autocomplete(
  //     document.getElementById("autocomplete")
  //   )
  // })
  console.log(areaCheckOption);
  return (
    <>
      <div className='area_search py-2'>
        <div className='container'>
          <Title section_title='Area Search' />
          <Titleimg />
          <MDBCard
            className='mx-auto  py-4 custom_bg'
            // style={{
            //   backgroundImage: `url("/assest/image/location-search/2.jpg")`,
            // }}
          >
            <MDBCardBody className='p-2'>
              <Formik
                initialValues={{
                  keyword: "",
                  division: "",
                  district: "",
                  thana: "",
                  area: "",
                  // writeArea: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.keyword) {
                    errors.keyword = "Please Input Search Keyword";
                  }

                  if (!values.division) {
                    errors.division = "Please Select Division";
                  }

                  if (!values.district) {
                    errors.district = "Please Select District";
                  }

                  if (!values.thana) {
                    errors.thana = "Please Select Thana";
                  }

                  if (!values.area) {
                    errors.area = "Please Select Area";
                  }

                  return errors;
                }}
                onSubmit={(values, formik) => {
                  alert(JSON.stringify(values, null, 2));
                  console.log(values);
                  formik.setSubmitting(false);

                  formik.resetForm();
                  document.getElementById("resetForm").reset();
                }}>
                {(formik) => (
                  <form
                    action='#'
                    id='resetForm'
                    onSubmit={formik.handleSubmit}>
                    <div className='row'>
                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <div className='mt-1'>
                          <span className='custom_title'>
                            Keyword<span className='text-danger'>*</span>
                          </span>

                          <MDBInput
                            label='Search by Keyword'
                            id='inputKeyword'
                            name='keyword'
                            placeholder='keyword'
                            type='text'
                            value={formik.values.keyword}
                            onChange={formik.handleChange}
                            className='form-control'
                          />

                          {formik.touched.keyword && formik.errors.keyword ? (
                            <span className='text-danger'>
                              {formik.errors.keyword}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <div className='mt-1'>
                          <span className='custom_title'>
                            Division<span className='text-danger'>*</span>
                          </span>

                          <select
                            className='form-control'
                            name='division'
                            placeholder='Select Division'
                            value={formik.values.data}
                            onChange={formik.handleChange}>
                            <option value=''>--Select Division--</option>
                            {data.division.map((value, key) => {
                              return (
                                <option value={value.value} key={key}>
                                  {value.value}
                                </option>
                              );
                            })}
                          </select>

                          {formik.touched.division && formik.errors.division ? (
                            <span className='text-danger'>
                              {formik.errors.division}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <div className='mt-1'>
                          <span className='custom_title'>
                            District<span className='text-danger'>*</span>
                          </span>

                          <select
                            name='district'
                            class='form-control'
                            placeholder='Select District'
                            value={formik.values.district}
                            onChange={formik.handleChange}>
                            <option value=''>--Select District--</option>
                            {data.division
                              .find(
                                (divi) => divi.value === formik.values.division
                              )
                              ?.district.sort((a, b) =>
                                a.value > b.value ? 1 : -1
                              )
                              .map((value, key) => {
                                return (
                                  <option value={value.value} key={key}>
                                    {value.value}
                                  </option>
                                );
                              })}
                          </select>

                          {formik.touched.district && formik.errors.district ? (
                            <span className='text-danger'>
                              {formik.errors.district}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <div className='mt-1'>
                          <span className='custom_title'>
                            Thana<span className='text-danger'>*</span>
                          </span>

                          <select
                            class='form-control'
                            name='thana'
                            placeholder='thana'
                            value={formik.values.thana}
                            onChange={formik.handleChange}>
                            <option value=''>--Select thana--</option>
                            {data.division
                              .find(
                                (divi) => divi.value === formik.values.division
                              )
                              ?.district?.find(
                                (dis) => dis.value === formik.values.district
                              )
                              ?.thana.sort((a, b) =>
                                a.value > b.value ? 1 : -1
                              )
                              .map((value, key) => {
                                return (
                                  <option value={value.value} key={key}>
                                    {value.value}
                                  </option>
                                );
                              })}
                          </select>

                          {formik.touched.thana && formik.errors.thana ? (
                            <span className='text-danger'>
                              {formik.errors.thana}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <div className='mt-1'>
                          <span className='custom_title'>
                            Area<span className='text-danger'>*</span>
                          </span>

                          {!areaCheckOption ? (
                            <div>
                              <input
                                className='form-control'
                                name='area'
                                placeholder='area'
                                value={formik.values.area}
                                onChange={formik.handleChange}
                                type='text'
                              />
                              <div>
                                <MDBCheckbox
                                  name='areaCheck'
                                  checked={areaCheckOption}
                                  onChange={() => {
                                    setareaCheckOption(!areaCheckOption);
                                    formik.setFieldValue("area", "");
                                  }}
                                  value=''
                                  id='areaCheck'
                                  label='Show Option'
                                />
                              </div>
                            </div>
                          ) : (
                            <select
                              className='form-control'
                              name='area'
                              placeholder='area'
                              value={formik.values.area}
                              onChange={formik.handleChange}>
                              <option value=''>--Select Area--</option>
                              {data.division
                                .find(
                                  (divi) =>
                                    divi.value === formik.values.division
                                )
                                ?.district?.find(
                                  (dis) => dis.value === formik.values.district
                                )
                                ?.thana?.find(
                                  (thana) => thana.value === formik.values.thana
                                )
                                ?.area.sort((a, b) =>
                                  a.value > b.value ? 1 : -1
                                )
                                .map((value, key) => {
                                  return (
                                    <option value={value.value} key={key}>
                                      {value.value}
                                    </option>
                                  );
                                })}
                              <option
                                value=''
                                onClick={() =>
                                  setareaCheckOption(!areaCheckOption)
                                }>
                                🖌️ Write Your Area{" "}
                              </option>
                            </select>
                          )}

                          {formik.touched.area && formik.errors.area ? (
                            <span className='text-danger'>
                              {formik.errors.area}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='col-lg-2 col-md-2 col-10 mx-auto'>
                        <MDBBtn
                          style={{
                            marginTop: "28px",
                          }}
                          className='w-100 bg-info'
                          type='submit'>
                          Search
                        </MDBBtn>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
      {/* <LocationDropdown/> */}
    </>
  );
};

export default AreaSearchSection;
