import React, { useState, useEffect } from "react";
import axios from "axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useParams } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import {Helmet} from "react-helmet";

const EditSOSCategoryWrapper = () => {
  const { id } = useParams();

   //  get sos update
   const [update, setUpdate] = useState([]);
   // backend validation check
   const [validation_error, setValidation_error] = useState([]);
   const [loading, setloading] = useState(true);
   const [rerendering, setRerendering] = useState(false);
 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  //  get categories 
  const [catgory, setCategory] = useState([]);
  //  get subcategory 
  const [subcategory, setSubcategory] = useState([]);


   // get data
   useEffect(() => {
    let isMounted = true;
   
     axios.get(`/sub-of-sos-category/edit/${id}`).then((res) => {
      if (isMounted) {
       if (res.data.status === 200) {
        setUpdate(res.data.data);
        setCategory(res.data.category);
       }
       setloading(false);
      }
     });
 
    return () => {
      isMounted = false;
    }
   }, [rerendering]);

 // get data
 useEffect(() => {
  let isMounted = true;
if (isMounted) {
  if(update?.category_id){
    axios.get(`/subcategory/list/${update.category_id}`).then((res) => {
      if (res.data.status === 200) {
       setSubcategory(res.data.subcategory);
      }
      setloading(false);
    });
  }
}
return () => {
 isMounted = false;
}
}, [update.category_id]);

   const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const data = update;
    axios
      .post(`/sub-of-sos-category/update/${update.id}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRerendering(!rerendering);
          setDisableSubmitBtn(false);
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  if (loading) {
    return <h2>loading....</h2>;
  }

  return (
    <>
         {/* seo title */}
         <Helmet>
        <title> Edit || SOS-Category</title>
        </Helmet>

    {/* -------PageHeaderBreadCrumb------- */}
    <PageHeaderBreadCrumb
      Title='SoS-Categories'
      pageTitle='Edit / SOS-Category'
    />
    {/* Main content */}
    <section className='content'>
      <div className='row'>
        <div className='col-md-8 mx-auto'>
          <div className='card card-primary'>
            <div className='card-header'>
              <h3 className='card-title'>Edit Sos-Category</h3>
              <div className='card-tools'>
                <button
                  type='button'
                  className='btn btn-tool'
                  data-card-widget='collapse'
                  title='Collapse'>
                  <i className='fas fa-minus' />
                </button>
              </div>
            </div>
            <div className='card-body'>
              <form  onSubmit={handleSubmit}>
                <div class='form-group'>
                  <label for='exampleFormControlSelect1'>Category </label>
                  <select
                    name='category_id'
                    value={update.category_id}
                    onChange={handleChange}
                    class='form-control'
                    id='exampleFormControlSelect1'>
                    <option value='' disabled='disabled'>
                      --Select Category--
                    </option>
                    {catgory.map((val) => {
                      return (
                        <option value={val.id} {...update.category_id === val.id ? "selected": ""}>{val.category_name}</option>
                      );
                    })}
                  </select>
                </div>
                <span className='text-danger'>{validation_error && validation_error.category_id}</span>
                <div class='form-group'>
                  <label for='exampleFormControlSelect'>
                    {" "}
                    Sub Category {" "}
                  </label>
                  <select
                    name='sub_category_id'
                    value={update.sub_category_id}
                    onChange={handleChange}
                    class='form-control'
                    id='exampleFormControlSelect'>
                    <option value='' disabled='disabled'>
                      --Select Sub Category--
                    </option>

                    {subcategory.map((val) => {
                        return (
                          <option value={val.id} key={val.id} {...update.sub_category_id === val.id ? "selected": ""}>
                            {val.sub_category_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <span className='text-danger'>{validation_error && validation_error.sub_category_id}</span>
                <div className='form-group'>
                  <label htmlFor='inputClientCompany'>
                    SOS Category 
                  </label>
                  <input
                    // required
                    type='text'
                    name='sub_of_soscategory_name'
                    value={update.sub_of_soscategory_name}
                    onChange={handleChange}
                    id='inputClientCompany'
                    className='form-control'
                    placeholder='Enter SOS Category'
                  />
                </div>
                <span className='text-danger'>{validation_error && validation_error.sub_of_soscategory_name}</span>
                <div className='form-group'>
                  <label htmlFor='inputClientCompany'>Slug</label>
                  <input
                    // required
                    type='text'
                    name='sub_of_soscategory_slug'
                    value={update.sub_of_soscategory_slug}
                    onChange={handleChange}
                    id='inputClientCompany'
                    className='form-control'
                    placeholder='Enter Slug'
                  />
                </div>
                <span className='text-danger'>{validation_error && validation_error.sub_of_soscategory_slug}</span>
                <button
                  type='submit'
                  class='btn btn-success btn-block w-75 mx-auto' disabled={disableSubmitBtn}>
                  <i class='fas fa-paper-plane'></i> Submit
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
      </div>
    </section>
    {/* /.content */}
  </>
  )
}

export default EditSOSCategoryWrapper