import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import axios from "axios";
import {toast,Zoom } from "react-toastify";
import Loader from './../../frontend/Loader/Loader';
import { useParams, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";


const ViewOrderWrapper = () => {

  const { id } = useParams();
  const history = useNavigate();

  //  get order info data
  const [orderInfo, setOrderInfo] = useState([]);
  const [statusMsg, setStatusMsg] = useState({
    msg: '',
  });
  const {msg} = statusMsg;
  const [loading, setloading] = useState(true);
  const [rerender, setRerender] = useState(false);

  // backend validation check
const [validation_error, setValidation_error] = useState([]);

  // get data
  useEffect(() => {
   let isMounted = true;
 const fatchData = async () =>{
  const res = await axios.get(`/order/view/${id}`);
  if (isMounted) {
   if (res.data.status === 200) {
     setOrderInfo(res.data.order);
   } else if (res.data.status === 404) {
     toast.error(res.data.message, {
       position: "top-center",
       theme: "colored",
       transition: Zoom,
       autoClose: 500,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
     history("/our-dashboard/all/order");
   }
   setloading(false);
  }
 }

 fatchData();
 
return () => {
  isMounted = false
}
 
  
  }, [rerender]);
  
  useEffect(() => {
   // initialize datatable
   if (!loading) {
     const script = document.createElement("script");
     script.src = "/dist/js/custom.js";
     script.async = true;
     document.body.appendChild(script);
   }
 }, [loading]);
 


// handleChange 
const handleChange = (e) => {
  if(e.target.name === 'cashondelivery_payment'){
    setOrderInfo({
    ...orderInfo,
    [e.target.name]: e.target.value,
  });
  }else{
    setStatusMsg({
    ...statusMsg,
    [e.target.name]: e.target.value,
  });
  }
};





 // status code 0,1,2,3,4,5,6,7
 const updateStatus = (status_val) => {
 const formData = new FormData();
 formData.append("status", status_val);
  if(orderInfo?.payment_mode === 'cash-on-delivery'){
    formData.append("cashondelivery_payment", orderInfo?.cashondelivery_payment);
  }
  
 
 formData.append('status_message', msg);
 axios
 .post(`/order/update/${id}`, formData)
 .then((res) => {
   if (res.data.status === 200) {
     toast.success(res.data.message, {
       position: "top-center",
       theme: 'colored',
       transition: Zoom,
       autoClose: 400,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
      setRerender(!rerender)
      setValidation_error({});
      setStatusMsg({
        msg: '',
      });
      }else if (res.data.status === 422) {
        setValidation_error(res.data.validation_errors);
      }
     })
     .catch((e) => {});
 };


 var totalPrice = 0;

 if (loading) {
  return <Loader />;
}

  return (
    <>
           {/* seo title */}
           <Helmet>
        <title> View || Order</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Orders"
        pageTitle="View-Orders"
      />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-10 mx-auto">
                <div className="billing_info">
                  <span className='text-start px-3 h5 font-weight-bold'>Billing Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Billing Full Name
                        </td>
                        <td>
                          {orderInfo?.billing_full_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Billing Email
                        </td>
                        <td>
                        {orderInfo?.billing_email}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Billing Number
                        </td>
                        <td>
                        {orderInfo?.billing_phone}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Billing Address:
                        </td>
                        <td>
                        {orderInfo?.billing_full_address}
                        </td>
                      </tr>
                      <tr>
                      <td>
                        Billing City:
                        </td>
                        <td>
                        {orderInfo?.billing_city}
                        </td>
                      </tr>
                      <tr>
                      <td>
                        Billing Zip Code:
                        </td>
                        <td>
                        {orderInfo?.billing_zip_code}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="shipping_info">
                  <span className='text-start px-3 h5 font-weight-bold'>Shipping Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Shipping Full Name
                        </td>
                        <td>
                          {orderInfo?.shipping_full_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Shipping Email
                        </td>
                        <td>
                        {orderInfo?.shipping_email}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Shipping Number
                        </td>
                        <td>
                        {orderInfo?.shipping_phone}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Shipping Address:
                        </td>
                        <td>
                        {orderInfo?.shipping_full_address}
                        </td>
                      </tr>
                      <tr>
                      <td>
                        Shipping City:
                        </td>
                        <td>
                        {orderInfo?.shipping_city}
                        </td>
                      </tr>
                      <tr>
                      <td>
                      Shipping Zip Code:
                        </td>
                        <td>
                        {orderInfo?.shipping_zip_code}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-10 mx-auto">
                <div className="user_info">
                  <span className='text-start px-3 h5 font-weight-bold'>User Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Name:
                        </td>
                        <td>
                        {orderInfo?.user?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Email:
                        </td>
                        <td>
                        {orderInfo?.user?.email}
                        </td>
                        
                      </tr>
                      <tr>
                      <td>
                          Phone:
                        </td>
                        <td>
                        {orderInfo?.user?.phone}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="more_user_info">
                  <span className='text-start px-3 h5 font-weight-bold'>More User Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        City:
                        </td>
                        <td>
                        {orderInfo?.user?.user_details?.city}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Full Address
                        </td>
                        <td>
                        {orderInfo?.user?.user_details?.full_address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Zip Code
                        </td>
                        <td>
                        {orderInfo?.user?.user_details?.zip_code}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>



                <div className="payment_mode">
                  <span className='text-start px-3 h5 font-weight-bold'>Payment Mode</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        Payment
                        </td>
                        <td>
                          <span className='text-info'>
                          {orderInfo?.payment_mode}
                          </span>
                        </td>
                      </tr>
                     

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}




        <div class="card">
              <div class="card-header">
                <h3 class="card-title">Order Status</h3>
              </div>
              {/* <!-- /.card-header --> */}
              <div class="card-body">

              <div className="form-group mt-2">
                    <label htmlFor="inputName">Set Status Message</label>
                    <input
                      required
                      type="text"
                      onChange={handleChange}
                      name="msg"
                      value={msg}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.status_message &&
                    <span className='text-danger'>
                          {validation_error.status_message}
                    <br/>
                  </span>
                  }
                  {
                    orderInfo?.payment_mode === 'cash-on-delivery'  && 
                    <>
                    <div className="form-group mt-2">
                    <label htmlFor="inputName">Set Cash On Delivery Payment</label>
                    <input
                      required
                      type="text"
                      onChange={handleChange}
                      name="cashondelivery_payment"
                      value={orderInfo?.cashondelivery_payment === null ? "" : orderInfo?.cashondelivery_payment}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.cashondelivery_payment &&
                    <span className='text-danger'>
                          {validation_error.cashondelivery_payment}
                    <br/>
                  </span>
                  }
                  </>
                  }
                 

                  
                        {
                          orderInfo?.status === 0 ? 
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary" 
                             onClick={() => {
                             updateStatus(0);
                            }}>
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"  
                          onClick={() => {
                             updateStatus(1);
                            }}>
                          <i class="fas fa-play"></i> Comfirmed
                          </a>
                          </>
                          :
                          orderInfo?.status === 1 ? 
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-dot-circle"></i></span>
                          <i class="fas fa-play"></i> Comfirmed
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <i class="fas fa-inbox"></i> Processing
                        </a>
                        </>
                          :
                          orderInfo?.status === 2 ? 
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-check"></i></span>
                          <i class="fas fa-play"></i> Comfirmed
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <span class="badge bg-teal"><i class="fas fa-dot-circle"></i></span>
                        <i class="fas fa-inbox"></i> Processing
                        </a>
                         <a  href='##' class="btn btn-app mr-2 bg-warning"
                          onClick={() => {
                            updateStatus(3);
                           }}
                         >
                          <i class="fas fa-barcode"></i> Picked
                          </a>
                        </>
                          
                          :
                          orderInfo?.status === 3 ? 
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-check"></i></span>
                          <i class="fas fa-play"></i> Comfirmed
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <span class="badge bg-teal"><i class="fas fa-check"></i></span>
                        <i class="fas fa-inbox"></i> Processing
                        </a>
                         <a  href='##' class="btn btn-app mr-2 bg-warning"
                          onClick={() => {
                            updateStatus(3);
                           }}
                         >
                          <span class="badge bg-info"><i class="fas fa-dot-circle"></i></span>
                          <i class="fas fa-barcode"></i> Picked
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-info"
                           onClick={() => {
                            updateStatus(4);
                           }}
                          >
                            <i class="fas fa-shipping-fast"></i> Shipped
                          </a>
                        </>
                          :
                          orderInfo?.status === 4 ?      
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-check"></i></span>
                          <i class="fas fa-play"></i> Comfirmed
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <span class="badge bg-teal"><i class="fas fa-check"></i></span>
                        <i class="fas fa-inbox"></i> Processing
                        </a>
                         <a  href='##' class="btn btn-app mr-2 bg-warning"
                          onClick={() => {
                            updateStatus(3);
                           }}
                         >
                          <span class="badge bg-info"><i class="fas fa-check"></i></span>
                          <i class="fas fa-barcode"></i> Picked
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-info"
                           onClick={() => {
                            updateStatus(4);
                           }}
                          >
                            <span class="badge bg-danger"><i class="fas fa-dot-circle"></i></span>
                            <i class="fas fa-shipping-fast"></i> Shipped
                          </a>
                          
                          { orderInfo?.payment_mode === 'cash-on-delivery' ?
                           <>
                           
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(5);
                           }}
                          >
                         <i class="fas fa-money-bill-wave"></i> Cash-On-Delivery-Payment-Delivered
                          </a>
                           </>
                           :
                           <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(5);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-thumbs-up"></i> Delivered
                          </a>
                          }
                        </>
                          :
                          orderInfo?.status === 5 ?   
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             onClick={() => {
                             updateStatus(7);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-check"></i></span>
                          <i class="fas fa-play"></i> Comfirmed
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <span class="badge bg-teal"><i class="fas fa-check"></i></span>
                        <i class="fas fa-inbox"></i> Processing
                        </a>
                         <a  href='##' class="btn btn-app mr-2 bg-warning"
                          onClick={() => {
                            updateStatus(3);
                           }}
                         >
                          <span class="badge bg-info"><i class="fas fa-check"></i></span>
                          <i class="fas fa-barcode"></i> Picked
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-info"
                           onClick={() => {
                            updateStatus(4);
                           }}
                          >
                            <span class="badge bg-danger"><i class="fas fa-check"></i></span>
                            <i class="fas fa-shipping-fast"></i> Shipped
                          </a>
                         
                          { orderInfo?.payment_mode === 'cash-on-delivery' ?
                           <>
                           
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                             updateStatus(5);
                            }}
                          >
                           <span class="badge bg-success"><i class="fas fa-check"></i></span>
                         <i class="fas fa-money-bill-wave"></i> Cash-On-Delivery-Payment-Delivered
                          </a>
                           </>
                           :
                           <a  href='##' class="btn btn-app mr-2 bg-success"
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-thumbs-up"></i> Delivered
                          </a>
                          }

                        </>
                       
                          :  orderInfo?.status === 6 ? 
                          <>
                          { orderInfo?.payment_mode === 'cash-on-delivery' &&
                            <a  href='##' class="btn btn-app mr-2 bg-danger" 
                             >
                          <span class="badge bg-success"><i class="fas fa-dot-circle"></i></span>
                          <i class="fas fa-window-close"></i> Cancled
                          </a>
                          }
                          <a  href='##' class="btn btn-app mr-2 bg-secondary" 
                             onClick={() => {
                             updateStatus(0);
                            }}>
                          {/* <span class="badge bg-success"><i class="fas fa-check"></i></span> */}
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          
                          </>
                          : null
                        }
                
                <br />
                <span className='text-info'>
                          {orderInfo?.status_message}
                  </span>
                 

              

              
                


              </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}




        






        <div class="card">
              <div class="card-header">
                <h3 class="card-title">Order Items</h3>
              </div>
              {/* <!-- /.card-header --> */}
              <div class="card-body">
                <table id="example1" class="table table-bordered table-striped text-center">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Product-Name</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                  </thead>
                  <tbody>
                        {
                          orderInfo?.order_items?.map((item)=>{
                            const {product, product_colors}=item;
                            totalPrice += Number(item.price) * item.qty;
                            return(
                    <tr>
                    <td>{item?.id}</td>
                    <td>
                    <a href={`/product/${product?.product_slug}`}  target='_blank'>
                    <ul className='list-inline'>
                            <li className='list-inline-item'>
                              <img
                                alt='product_img'
                                width='50'
                                src={
                                  product?.product_img == null
                                    ? null
                                    : `${process.env.REACT_APP_API_URL}${product?.product_img}`
                                }
                              />
                            </li>
                          </ul>
                          </a>
                    </td>
                    <td>{product?.product_name}</td>
                    <td>{product_colors?.color?.color_name}</td>
                    <td>{item?.size}</td>
                    <td>{Number(item?.price)} x {item?.qty}</td>
                    <td>৳ {Number(item?.price) * item?.qty}</td>
                  </tr>
                            )
                          })
                        }
                  
                 
                  </tbody>
                  <tfoot>
                  {orderInfo?.discount_price &&
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Discount</th>
                    <th className='text-danger'>{orderInfo?.discount_price}</th>
                  </tr>
                  }
                  {orderInfo?.payment_mode === 'cash-on-delivery' ?
                  <>
                  <tr>
                  <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Paid Amount</th>
                    <th className='text-danger'>৳ {orderInfo?.cashondelivery_payment === null ? 0 : orderInfo?.cashondelivery_payment}</th>
                    </tr>
                    <tr>
                  <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Deu Amount</th>
                    <th className='text-danger'>৳ {orderInfo?.cashondelivery_payment !== null && orderInfo?.discount_price ? totalPrice - Number(orderInfo?.cashondelivery_payment) - orderInfo?.discount_price : totalPrice - Number(orderInfo?.cashondelivery_payment)}</th>
                    </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th className='text-danger'>৳ {orderInfo?.discount_price ? totalPrice - orderInfo?.discount_price : totalPrice}</th>
                  </tr>
                  </>
                  :
                  <>
                 
                  <tr>
                  <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Paid Amount</th>
                    <th className='text-danger'>৳ {orderInfo?.discount_price ? totalPrice - orderInfo?.discount_price : totalPrice}</th>
                    </tr>
                    <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th className='text-danger'>৳ {orderInfo?.discount_price ? totalPrice - orderInfo?.discount_price : totalPrice}</th>
                  </tr>  
                  </>
                   } 
                  </tfoot>
                </table>
              </div>
              {/* <!-- /.card-body --> */}
            </div>
        {/* <!-- /.card --> */}

      </section>
    </>
  )
}

export default ViewOrderWrapper