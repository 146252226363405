import React from 'react'
import HeaderTopSec from '../../../../Components/admin/Settings/HeaderTop/HeaderTopSec'

const HeaderTopSetting = () => {
  return (
    <>
    <HeaderTopSec/>
    </>
  )
}

export default HeaderTopSetting