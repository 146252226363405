import React from 'react'
import NewOrderSec from '../../../../Components/admin/OrderSection/NewOrderSec'

const NewOrders = () => {
  return (
    <>
    <NewOrderSec/>
    </>
  )
}

export default NewOrders