import React from 'react'
import { MDBCard, MDBCardBody,  MDBCardText, MDBCardImage } from 'mdb-react-ui-kit';
import Title from '../SectionTitle/Title/Title';
import Titleimg from '../SectionTitle/Title_img/Title_img';
import { NewsPortalData } from './NewsPortalData';
import BlogRightSite from '../BlogSection/Blog_Right_Site/BlogRightSite';
const NewsPortalSection = () => {
  return (
    <>
    <div className="news_portal_section py-3">
    <div className="container">
        <Title
        section_title="News-Portal"
        />
        <Titleimg/>
    <MDBCard className='p-4 border'>
    <div className="row">
    <div className="col-lg-8 col-md-8 col-10 mx-auto">
    <div className="row">
    {
               NewsPortalData.map((Value, index)=>{
                   return( 
                    <div className="col-lg-4 col-md-4 col-10 mx-auto mt-4" key={index}>
                    <MDBCard className='rounded hover-shadow border'>
                       <MDBCardBody className='p-2'>  
                       <p className='mb-0 text-center text-dark fw-bold py-1'>{Value.title}</p>   
                         <MDBCardText className='text-center'>
                         {Value.text}
                         </MDBCardText>
                       </MDBCardBody>
                       <MDBCardImage  position='bottom' src={Value.img} alt='news-portal-img' />
                     </MDBCard>
                   </div>    
                   )
               })
           }
    </div>
    </div>
           <div className="col-lg-4 col-md-4 col-10 mx-auto">
             <BlogRightSite/>
           </div>
          
 

    </div>
    </MDBCard>
    </div>
    </div>
    </>
  )
}

export default NewsPortalSection  