// city cityoption
export const cityOption = [
    { value: 'Bagerhat ', label: 'Bagerhat ' },
    { value: 'Bandarban  ', label: 'Bandarban  ' },
    { value: 'Barguna  ', label: 'Barguna  ' },
    { value: 'Barisal  ', label: 'Barisal  ' },
    { value: 'Bhola  ', label: 'Bhola  ' },
    { value: 'Bogra  ', label: 'Bogra  ' },
    { value: 'Brahmanbaria  ', label: 'Brahmanbaria  ' },
    { value: 'Chandpur  ', label: 'Chandpur  ' },
    { value: 'Chapai Nawabganj  ', label: 'Chapai Nawabganj  ' },
    { value: 'Chittagong  ', label: 'Chittagong  ' },
    { value: 'Chuadanga  ', label: 'Chuadanga  ' },
    { value: 'Comilla  ', label: 'Comilla  ' },
    { value: 'Coxs Bazar ', label: 'Coxs Bazar ' },
    { value: 'Dhaka  ', label: 'Dhaka  ' },
    { value: 'Dinajpur  ', label: 'Dinajpur  ' },
    { value: 'Faridpur  ', label: 'Faridpur  ' },
    { value: 'Dinajpur  ', label: 'Dinajpur  ' },
    { value: 'Faridpur  ', label: 'Faridpur  ' },
    { value: 'Feni  ', label: 'Feni  ' },
    { value: 'Gaibandha  ', label: 'Gaibandha  ' },
    { value: 'Gazipur  ', label: 'Gazipur  ' },
    { value: 'Gopalganj  ', label: 'Gopalganj  ' },
    { value: 'Habiganj  ', label: 'Habiganj  ' },
    { value: 'Jamalpur  ', label: 'Jamalpur  ' },
    { value: 'Jessore  ', label: 'Jessore  ' },
    { value: 'Jhalakathi  ', label: 'Jhalakathi  ' },
    { value: 'Jhenaidah  ', label: 'Jhenaidah  ' },
    { value: 'Joypurhat  ', label: 'Joypurhat  ' },
    { value: 'Khagrachhari  ', label: 'Khagrachhari  ' },
    { value: 'Khulna  ', label: 'Khulna  ' },
    { value: 'Kishoreganj  ', label: 'Kishoreganj  ' },
    { value: 'Kurigram  ', label: 'Kurigram  ' },
    { value: 'Kushtia  ', label: 'Kushtia  ' },
    { value: 'Lakshmipur   ', label: 'Lakshmipur   ' },
    { value: 'Lalmonirhat   ', label: 'Lalmonirhat   ' },
    { value: 'Madaripur   ', label: 'Madaripur   ' },
    { value: 'Magura   ', label: 'Magura   ' },
    { value: 'Manikganj   ', label: 'Manikganj   ' },
    { value: 'Meherpur   ', label: 'Meherpur   ' },
    { value: 'Moulvibazar   ', label: 'Moulvibazar   ' },
    { value: 'Munshiganj   ', label: 'Munshiganj   ' },
    { value: 'Mymensingh   ', label: 'Mymensingh   ' },
    { value: 'Naogaon   ', label: 'Naogaon   ' },
    { value: 'Narail   ', label: 'Narail   ' },
    { value: 'Narayanganj   ', label: 'Narayanganj   ' },
    { value: 'Narsingdi   ', label: 'Narsingdi   ' },
    { value: 'Natore   ', label: 'Natore   ' },
    { value: 'Netrokona   ', label: 'Netrokona   ' },
    { value: 'Nilphamari   ', label: 'Kushtia  ' },
    { value: 'Noakhali   ', label: 'Noakhali   ' },
    { value: 'Pabna   ', label: 'Pabna   ' },
    { value: 'Panchagarh   ', label: 'Panchagarh   ' },
    { value: 'Patuakhali   ', label: 'Patuakhali   ' },
    { value: 'Pirojpur   ', label: 'Pirojpur   ' },
    { value: 'Rajbari   ', label: 'Rajbari   ' },
    { value: 'Rajshahi   ', label: 'Rajshahi   ' },
    { value: 'Rangamati   ', label: 'Rangamati   ' },
    { value: 'Rangpur   ', label: 'Rangpur   ' },
    { value: 'Satkhira   ', label: 'Satkhira   ' },
    { value: 'Shariatpur   ', label: 'Shariatpur   ' },
    { value: 'Sherpur   ', label: 'Sherpur   ' },
    { value: 'Sirajganj   ', label: 'Sirajganj   ' },
    { value: 'Sunamganj   ', label: 'Sunamganj   ' },
    { value: 'Sylhet  ', label: 'Sylhet  ' },
    { value: 'Tangail   ', label: 'Tangail   ' },
    { value: 'Thakurgaon   ', label: 'Thakurgaon   ' },
  
  ]

  
export const subjectOption = [
  {
    value: 'History', label: "History"
  },
  {
    value: 'Traditional ', label: "Traditional "
  },
  {
    value: 'Images ', label: "Images"
  },
  
]

export const divisionOption =[
  {
    value:"Dhaka", label:"Dhaka"
  },
  {
    value:"Chittagong", label:"Chittagong"
  },
  {
    value:"Rajshahi", label:"Rajshahi"
  },
  {
    value:"Khulna", label:"Khulna"
  },
  {
    value:"Barisal", label:"Barisal"
  },
  {
    value:"Sylhet", label:"Sylhet"
  },
  {
    value:"Rangpur", label:" 	Rangpur"
  },
  {
    value:"Mymensingh", label:"Mymensingh"
  }
 ]