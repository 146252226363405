import React, { useContext, useState } from "react";
import {
  MDBCard,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import wishNoteContext from "../Context/Wishlist/wishNoteContext";
import { Link } from "react-router-dom";

const Wis_item = ({id,
  product,
  product_colors,
  product_qty,
  product_id,}) => {
  // remove, increment, decrement
  const { removeItemWish } = useContext(wishNoteContext);

  //  modal
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  return (
    <>
      <tr>
        <td>
          <Link to={`/product/${product?.product_slug}`} className='text-dark'>
            <div className='wishlist_product_details d-flex align-items-center'>
              <div className=''>
                <img
                  className='img-fluid rounded wishlist_img'
                  src={
                    product?.product_img == null
                      ? null
                      : `${process.env.REACT_APP_API_URL}${product?.product_img}`
                  }
                  alt='cart_img'
                />
              </div>
              <div className='m-2'>
                <span className='d-flex'>
                  <p className='ps-1 fw-normal mb-0 px-2'>
                    {product?.product_name}
                  </p>
                </span>
                <span className='d-flex'>
                  <p className='fw-normal text-black-50 mb-0 px-2'>SKU:</p>
                  <p className='ps-1 fw-normal  mb-0 px-2'>
                    {product?.product_code}
                  </p>
                </span>
                {product?.brand && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50  mb-0 px-2'>Brand:</p>
                    <p className='ps-1 fw-normal  mb-0 px-2'>
                      {product?.brand?.brand_name}
                    </p>
                  </span>
                )}

                
                {product?.color && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50 mb-0 px-2'>Color:</p>
                    <p className='ps-1 fw-normal mb-0 px-2'>
                      {product_colors?.color?.color_name}
                    </p>
                  </span>
                )}
                
                {product?.size && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50 mb-0 px-2'>Size:</p>
                    <p className='ps-1 fw-normal mb-0 px-2'>
                      {product?.size}
                    </p>
                  </span>
                )}
              </div>
            </div>
          </Link>
        </td>

        <td className='text-center'>
          <div className='mt-4'>
            <span>
              <p className='fw-bold mb-0'>৳ {Number(product?.selling_price) - Number(product?.discount_price) * product_qty}</p>
            </span>
          </div>
        </td>
        <td className='text-center'>
          <div className='mt-3'>
            <span>
              <MDBBtn
                className='m-1'
                onClick={toggleShow}
                style={{ backgroundColor: " #ff4747", color: "white" }}>
                <i className='fas fa-trash-alt text-dark fs-6'></i>
              </MDBBtn>
            </span>
          </div>
        </td>

        
        {/* cart remove modal */}
        <MDBModal
          scrollable
          show={basicModal}
          setShow={setBasicModal}
          tabIndex='-1'>
          <MDBModalDialog className='modal-sm'>
            <MDBModalContent className='p-0'>
              <MDBModalHeader className='bg-danger text-white d-flex justify-content-center'>
                <MDBModalTitle className='p-3'>
                  <p className='h5 text-white'>Are You Sure ?</p>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody className='text-center'>
                <i class='fas fa-times text-danger fa-3x'></i>
                <p className='mb-2'>Item will be removed from Wishlist</p>
              </MDBModalBody>
              <MDBModalFooter>
                <div onClick={toggleShow}>
                  <MDBBtn color='danger' onClick={() => removeItemWish(id, product_id)}>
                    <p className='mb-0'>
                      Yes
                    </p>
                  </MDBBtn>
                </div>
                <MDBBtn
                  outline
                  className='mx-2'
                  color='danger'
                  onClick={toggleShow}>
                  No
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </tr>
    </>
  );
};

export default Wis_item;
