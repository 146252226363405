import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { Link } from "react-router-dom";
import Loader from './../../frontend/Loader/Loader';
import axios from "axios";
import moment from 'moment'
import {Helmet} from "react-helmet";

const NewServiceRequestSec = () => {
    //  get data data
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(true);
    // get data
    useEffect(() => {
     let isMounted = true;
   
      axios.get("/custom-service/new").then((res) => {
       if (isMounted) {
        if (res.data.status === 200) {
         setData(res.data.new);
        }
        setloading(false);
       }
      }).catch((e) => {});
     
     return () => {
       isMounted = false
     }
    }, []);
    
    useEffect(() => {
     // initialize datatable
     if (!loading) {
       const script = document.createElement("script");
       script.src = "/dist/js/custom.js";
       script.async = true;
       document.body.appendChild(script);
     }
   }, [loading]);
   
   
  
  
   if (loading) {
     return <Loader />;
   }
     return (
       <>
              {/* seo title */}
          <Helmet>
          <title> New || Custom-service</title>
          </Helmet>
  
         {/* -------PageHeaderBreadCrumb------- */}
         <PageHeaderBreadCrumb Title="Custom-service" pageTitle="New-Service" />
         {/* Main content */}
         <section className="content">
           {/* Default box */}
           <div className="card">
             <div className="card-body">
                     <Link
                       to='/our-dashboard/custom-service/all'
                       className='btn btn-danger btn float-right'>
                       All Service
                     </Link>
               <table id="example1" className="table table-bdataed table-striped text-center">
                 <thead>
                   <tr >
                   <th  >
                     SI
                     </th>
                     
                     <th >
                      Name
                     </th>
  
                     <th >
                     Invoice ID
                     </th>
                     <th >
                      Service Name
                     </th>
                     <th>
                      Plan Name
                     </th>
                     
                     <th >
                     Price
                     </th>
                     
                     <th >
                     Status 
                     </th>
                     <th >
                     Time
                     </th>
                     <th >
                       View
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   {data && data?.map((val) => {
                       return (
                         <tr key={val.id}>
                           <td class="dtr-control">{val.id}</td>
                          
                           <td>
                              {val?.name}
                           </td>
                           <td>{val?.invoice_id}</td>
                          <td>
                           
                            {
                            val?.special_service?.title
                          }
                          
                          </td>
                          <td>
                           
                            {
                            val?.service_plan?.name
                          }
                          
                          </td>
  
                          <td>{val?.price}</td>
  
                            <td><span className={val.status === 0 && 'p-1 rounded bg-warning'}>
                            {
                            val.status === 0 && 'Pending'
                            
                          }</span></td>
                           
                          
  
                           <td>{moment(val.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                          <td>
                             <Link
                               to={`/our-dashboard/view/custom-service/${val.id}`}
                               className="btn btn-primary btn-sm p-2 "
                               href="#/"
                             >
                               View
                             </Link>
                           </td>
                         </tr>
                       );
                   
                   })}
                 </tbody>
               </table>
             </div>
             {/* /.card-body */}
           </div>
           {/* /.card */}
         </section>
         {/* /.content */}
       </>
     )
};

export default NewServiceRequestSec;
