import React from 'react'
import ViewAdminSec from '../../../../Components/admin/Admin/ViewAdminSec'

const ViewAdmin = () => {
  return (
    <>
    <ViewAdminSec/>
    </>
  )
}

export default ViewAdmin