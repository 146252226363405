import React from 'react'
import FooterTopData from './FooterTopData'
import "./FooterTop.css"
const FooterTop = () => {
    return (
        <>
        <div className="footer-top  text-xx-center text-lg-center text-md-center">
        <div className="container">
            <div className="row">
                {
                    FooterTopData.map((Value, index)=>{
                       return(
                        <div className="col-lg-4 col-md-4 col-10 mx-auto pt-1"  key={index}>
                                <p className='pt-2'><a href={Value.href}><i className={Value.icon}></i>{Value.name}</a></p>
                        </div>
                       )
                    })
                }
            </div>
            </div>
            </div>
        </>
    )
}

export default FooterTop
