import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';

const AllSubCategoryblog = () => {
 //  get categories option  data
 const [subCategories_blog, setSubcategories_blog] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;

   axios.get("/blog/subcategory/all").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setSubcategories_blog(res.data.blog_sub_categories);
     }
     setloading(false);
    }
   }).catch(error => {});
  
  return () => {
   isMounted = false
 }
 }, []);
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


const handleDeleteItems = (id) => {
  axios
    .delete(`/blog/subcategory/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setSubcategories_blog(
          subCategories_blog.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>
}
  return (
    <>
           {/* seo title */}
           <Helmet>
        <title> All || Blog-Subcategories</title>
        </Helmet>
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Sub-Categories" pageTitle="All / blog-Sub-Categories" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
          <Link
                    to='/our-dashboard/admin/blog/add/subcategory'
                    className='btn btn-success btn float-right'>
                    Add SubCategories
                  </Link>
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr >
                <th  className="text-center" >
                SL
                  </th>
                  <th  className="text-center">
                    Category Name
                  </th>
                  <th  className="text-center">
                    Sub Category 
                  </th>
                  <th  className="text-center">
                    Slug
                  </th>
                 
                  <th  className="text-center">
                    Edit
                  </th>
                  <th  className="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {subCategories_blog && subCategories_blog?.map((val) => {
                 
                    return (
                      <tr key={val.id}>
                        <td className="text-center" class="dtr-control">{val.id}</td>
                        <td className="text-center">{val.blog_category.category_name}</td>
                        <td className="text-center">{val.sub_category_name}</td>
                        <td className="text-center">{val.sub_category_slug}</td>
                       
                        <td className="text-center">
                          <Link
                            to={`/our-dashboard/admin/blog/edit/subcategory/ ${val.id}`}
                            className="btn btn-info btn-sm"
                          >
                            <i className="fas fa-edit pr-2" />
                            Edit
                          </Link>
                        </td>
                        <td className="text-center">
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllSubCategoryblog;
