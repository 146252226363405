export const reducer = (state, action) => {
  
  if (action.type === "ADD_TO_BASKET_WISH") {
    return {
      ...state,
      isRendering: action.payload.rendering
    };
  }




  if (action.type === "REMOVE_ITEM_WISH") {
    return {
      ...state,
      itemWish: state.itemWish.filter((curElem) => {
        return curElem.id !== action.payload;
      }),
    };
  }



  if(action.type === 'BUTTON_DISABLED'){
    return{
      ...state,
      wishSubmitting:action.payload.submitting
    }
  }



  if(action.type === 'FETCH_WISH_STARTED'){
    return{
      ...state,
      isLoading:action.payload.loading
    }
  }


  if(action.type === 'FETCH_WISH_SUCCESS'){
    return{
      ...state,
      itemWish:action.payload.data
    }
  }



  if(action.type === 'FETCH_WISH_END'){
    return{
      ...state,
      isLoading:action.payload.loading
    }
  }



  // increment with out color size & increment with color size
  if (action.type === "INCREMENT_WISH") {
    const updatedWish = state.itemWish.map((curElem) => {
      if (curElem.id === action.payload) {
        return { ...curElem, product_qty: curElem.product_qty + (curElem.product_qty < ( curElem?.product_colors?.color_qty ? curElem?.product_colors?.color_qty : curElem.product?.qty) ? 1 : 0)};
      }
      return curElem;
    });

    return { ...state, itemWish: updatedWish };
  }
 



    // decrement with out color size & decrement with color size
  if (action.type === "DECREMENT_WISH") {
    const updatedWish = state.itemWish.map((curElem) => {
      if (curElem.id === action.payload) {
        return {
          ...curElem,
          product_qty: curElem.product_qty - (curElem.product_qty <= ( curElem?.product_colors?.color_qty ? curElem?.product_colors?.color_qty : curElem.product?.qty) && curElem.product_qty > 1 ? 1 : 0),
        };
      }
      return curElem;
    });
    // .filter((curElem) => curElem.quantity !== 0);
    return { ...state, itemWish: updatedWish };
  }



  if (action.type === "CLEAR_WISH") {
    return { ...state, itemWish: [] };
  }




  if (action.type === "GET_TOTAL_WISH") {
    let { totalItemWish, totalAmountWish } = state?.itemWish?.reduce(
      (accum, curVal) => {
        let { product_qty } = curVal;
        let { selling_price} = curVal?.product;
      
        let updatedtotalAmountWish = selling_price * product_qty;
        accum.totalAmountWish += updatedtotalAmountWish;

        accum.totalItemWish += product_qty;
        return accum;
      },
      {
        totalItemWish: 0,
        totalAmountWish: 0,
      }
    );
    return { ...state, totalItemWish, totalAmountWish };
  }

  return state;
};
