import React from "react";
import "./PaymentMethodSection.css";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardText,
} from "mdb-react-ui-kit";
import Pagebanner from "../PageBanner/PageBanner";
import Titleimg from "../SectionTitle/Title_img/Title_img";
import HoverbleCardData from "./PaymentMethodData";
import { WeAcceptPaymentMethodData } from "./PaymentMethodData";
const PaymentMethodSection = () => {
  return (
    <>
      <div className="payment_method_section">
        <Pagebanner page_title="Payment Methods" />
        <Titleimg />
        <div className="container pb-4">
          <MDBCard className="p-4 border">
            <div className="row">
              {HoverbleCardData.map((Value, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-3 col-10 mx-auto mt-4"
                    key={index}
                  >
                    <MDBCard className="square  rounded hoverle_card border">
                      <div className="card_img text-center  rounded">
                        <Link to="#">
                          <MDBCardImage
                            className="img-fluid hoverble_img"
                            src={Value.hoverble_img}
                            position="top"
                            alt="hoverble-img"
                          />
                          <p className="text-center text-light mt-2">
                            {Value.hoverble_text}
                          </p>
                        </Link>
                      </div>
                    </MDBCard>
                  </div>
                );
              })}

              <div className="custom_payment_method py-4">
                <p className="text-center mx-auto h5">
                  We Accept Payment Method
                </p>
                <div className="row">
                  {WeAcceptPaymentMethodData.map((Value, index) => {
                    return (
                      <div
                        className="col-lg-2 col-md-2 col-10 mx-auto mt-4 px-4"
                        key={index}
                      >
                        <MDBCard className="square  rounded  border">
                          <div className="row">
                            <MDBCardImage
                              src={Value.payment_img}
                              alt="payment"
                              position="top"
                            />
                          </div>
                        </MDBCard>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </MDBCard>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodSection;
