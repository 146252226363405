import React,{useState, useEffect} from "react";
import GiftitemSlider from "react-slick";
import axios from "axios";
import "./Gift_item_Banner_&_ProductCarousel.css";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader"
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Gift_item_Banner_ProductCarousel = (props) => {

//  get giftItem data
const [giftItem, setGiftItem] = useState([]);
const [loading, setloading] = useState(true);

// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await axios.get("/gift-item-with-carousel/all");
      if(isMounted){
        if (res.data.status === 200) {
          setGiftItem(res.data.gift_item_with_carousel);
         }
         setloading(false);
      }
     
    } catch (e) {
       console.log(e);
    }
}

  fetchData();

  return () => {
    isMounted = false;
  }
}, []);





  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
            {/* seo meta tag  start */}
            {
          giftItem?.carousel?.map(val=>{
        <Helmet>
         <meta name='keywords' content={val?.img_alt} />
        <meta name='description' content={val?.img_alt} />
        <meta name='subject' content={val?.img_alt} />
        <meta name='topic' content={val?.img_alt} />
       

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={val?.img_alt}  />
        <meta
          itemprop="description"
          content={val?.img_alt}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
         <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${giftItem?.banner?.image}`}
        />
        
        <meta
          name='description'
          itemProp='description'
          content={val?.img_alt}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={val?.img_alt} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${giftItem?.banner?.image}`}
        />
        <meta
          property='og:image:alt'
          content={val?.img_alt}
        />
        <meta
          name='og:description'
          content={val?.img_alt}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='val?.summary_large_image' />
        <meta
          name='twitter:title'
          content={val?.img_alt} 
        />
        <meta
          name='twitter:description'
          content={val?.img_alt}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${giftItem?.banner?.image}`}
        />
        <meta
          name='twitter:image:alt'
          content={val?.img_alt}
        />
      </Helmet> 
          })
        }
        
      {/* seo meta tag  end */}


      <section id='banner_product_carousel' className='my-2'>
        <div className='container'>
          <h5 className='title text-start fw-bold py-1'>
                Find the perfect gift items
          </h5>
          
          <div className='row g-3'>
            <div className='col-lg-6 col-md-6 col-12 mx-auto'>
              {
                 loading ?
                 <ContentLoader 
                   speed={2}
                   width='100%'
                   height='100%'
                   viewBox="0 0 400 140"
                   backgroundColor="#f3f3f3"
                   foregroundColor="#ecebeb"
                   {...props}
                 >
                   <rect x="5" y="0" rx="3" ry="3" width="365" height="100" />
                 </ContentLoader>
               :
               <div className='banner-img'>
                <Link to=''>
                  <LazyLoadImage
                    effect="blur"
                    className='img-fluid mx-auto w-100 rounded mb-2'
                    style={{ height: "156px" }}
                    placeholderSrc='./assest/image/loader/placeholder.jpg'
                    src={`${process.env.REACT_APP_API_URL}${giftItem?.banner?.image}`}
                    alt='banner-img'
                  />
                </Link>
              </div>
              }
              
            </div>
            <div className='col-lg-6 col-md-6 col-12 mx-auto'>

                <GiftitemSlider {...settings}>
                  {loading ?
                  Array(4)
                  .fill()
                  .map((val, idx) => {
                    return (
                      <div
                        data-index={idx}
                        key={idx}>
                          <ContentLoader
                            speed={3}
                            width='100%'
                            height='100%'
                            viewBox='0 0 200 120'
                            backgroundColor='#f5f5f5'
                            foregroundColor='#dbdbdb'
                            {...props}>
                            <rect
                              x='53'
                              y='20'
                              rx='3'
                              ry='3'
                              width='130'
                              height='100'
                            />
                          </ContentLoader>
                        </div>
                    );
                  })
                  :
                  giftItem?.carousel?.map((val) => {
                    return (
                      <div key={val.id} data-index={val.id}>
                        <div  className='card w-75 mx-auto'>
                          <Link to={val?.url}>
                            <LazyLoadImage
                             effect="blur"
                              className='mx-auto rounded '
                               width="210px"
                              height="150px"
                              placeholderSrc='./assest/image/loader/placeholder.jpg'
                              src={`${process.env.REACT_APP_API_URL}${val?.image}`}
                              alt={val?.img_alt}
                            />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </GiftitemSlider>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gift_item_Banner_ProductCarousel;
