import React, { useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import "./ProfileSec.css";
import {Helmet} from "react-helmet";
import { Formik } from "formik";

const ProfileSec = () => {
  // get user details
  const [user, setUser] = useState([]);
  // rerendering axios insert data
 const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

   // backend validation check
   const [validation_error, setValidation_error] = useState([]);
   //  btn disabled
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios
        .get("/admin/profile")
        .then((res) => {
          if (res.data.status === 200) {
            setUser(res.data.user);
          }
          setloading(false);
        })
        .catch((err) => {
        });
    }
    return () => {
      isMounted = false;
    };
  }, [rerendering]);
 
 
  if (loading) {
    return <h2>loading....</h2>;
  }


  return (
    <>
        {/* seo title */}
        <Helmet>
        <title> Profile</title>
        </Helmet>



      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Profile' pageTitle='Profile' />

      {/* Main content */}
      <section className='content profile'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-3'>
              {/* Profile Image */}
              <div className='card card-primary card-outline'>
                <div className='card-body box-profile border'>
                  <div className='text-center custom_bg'>
                    <img
                      className='profile-user-img img-responsive  img-circle'
                      src={`${process.env.REACT_APP_API_URL}${user?.profile_image}`}
                      alt='admin'
                    />
                  </div>
                  <h3 className='profile-username text-center'>{user?.name}</h3>
                  <p className='text-muted text-center'>Web Developer</p>
                  <ul className='list-group list-group-unbordered mb-3'>
                    <li className='list-group-item text-center'>
                      <b>Phone:</b>&nbsp; <a href='#/'>{user?.phone}</a>
                    </li>
                    <li className='list-group-item text-center'>
                      {/* <b>Email:</b>&nbsp;  */}
                      <a href='#/'>{user?.email}</a>
                    </li>
                    <li className='list-group-item text-center social_info'>
                      <button type='button' class='btn btn-primary mr-1'>
                        <a target='_blank' rel="noreferrer" href={user?.user_detail?.facebook_id}>
                          <i class='fab fa-facebook'></i>
                        </a>
                      </button>
                      <button type='button' class='btn btn-success mr-1'>
                        <a target='_blank' rel="noreferrer" href={user?.user_detail?.instagram_id}>
                          <i class='fab fa-instagram'></i>
                        </a>
                      </button>
                      <button type='button' class='btn btn-info mr-1'>
                        <a target='_blank' rel="noreferrer" href={user?.user_detail?.twitter_id}>
                          {" "}
                          <i class='fab fa-twitter'></i>
                        </a>
                      </button>
                    </li>
                  </ul>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}

              {/* /.card */}
            </div>
            {/* /.col */}
            <div className='col-md-9'>
              <div className='card'>
                <div className='card-header p-2'>
                  <ul className='nav nav-pills'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        href='#activity'
                        data-toggle='tab'>
                        Activity
                      </a>
                    </li>
                  </ul>
                </div>
                {/* /.card-header */}
                <div className='card-body'>
                  <div className='tab-content'>
                    <div className='tab-pane active' id='activity'>
                      <Formik
                        initialValues={{
                          full_address: user?.user_detail?.full_address
                            ? user?.user_detail?.full_address
                            : "",
                          company_name: user?.company_name
                            ? user?.company_name
                            : "",
                          bio: user?.user_detail?.bio
                            ? user?.user_detail?.bio
                            : "",
                          city: user?.user_detail?.city
                            ? user?.user_detail?.city
                            : "",
                          zip_code: user?.user_detail?.zip_code
                            ? user?.user_detail?.zip_code
                            : "",
                          gender: user?.user_detail?.gender
                            ? user?.user_detail?.gender
                            : "",
                          facebook_id: user?.user_detail?.facebook_id
                            ? user?.user_detail?.facebook_id
                            : "",
                          twitter_id: user?.user_detail?.twitter_id
                            ? user?.user_detail?.twitter_id
                            : "",
                          linkedin_id: user?.user_detail?.linkedin_id
                            ? user?.user_detail?.linkedin_id
                            : "",
                          pinterest_id: user?.user_detail?.pinterest_id
                            ? user?.user_detail?.pinterest_id
                            : "",
                          profile_image: user?.user_detail?.profile_image
                            ? user?.user_detail?.profile_image
                            : "",
                        }}
                        enableReinitialize={true}
                        validate={(values) => {
                          const errors = {};

                          return errors;
                        }}
                        onSubmit={(values) => {
                          setDisableSubmitBtn(true);
                          const formData = new FormData();
                          formData.append("full_address", values.full_address);
                          formData.append("company_name", values.company_name);
                          formData.append("bio", values.bio);
                          formData.append("city", values.city);
                          formData.append("zip_code", values.zip_code);
                          formData.append("gender", values.gender);
                          formData.append("facebook_id", values.facebook_id);
                          formData.append("twitter_id", values.twitter_id);
                          formData.append("linkedin_id", values.linkedin_id);
                          formData.append("pinterest_id", values.pinterest_id);
                          formData.append(
                            "profile_image",
                            values.profile_image
                          );
                          axios
                            .post("/admin/profile/update", formData)
                            .then((res) => {
                              if (res.data.status === 200) {
                                toast.success(res.data.message, {
                                  position: "top-center",
                                  theme: "colored",
                                  transition: Zoom,
                                  autoClose: 500,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                                setDisableSubmitBtn(false);
                                setValidation_error({});
                                setRerendering(!rerendering);
                              } else {
                                setDisableSubmitBtn(false);
                                setValidation_error(res.data.validation_errors);
                              }
                            })
                            .catch((err) => {});
                        }}>
                        {(formik) => (
                          <form
                            onSubmit={formik.handleSubmit}
                            id='resetForm'
                            encType='multipart/form-data'>
                            <div className='form-row'>
                              <div className='form-group col-md-6'>
                                <label htmlFor='inputfullName'>
                                  <i class='fas fa-user'></i>&nbsp;Full Name
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={user?.name}
                                  disabled
                                  // onChange={formik.handleChange}
                                  name='name'
                                  id='inputfullName'
                                  placeholder='Full Name'
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor='inputuserName'>
                                  <i class='fas fa-user'></i>&nbsp;Username
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={user?.user_name}
                                  disabled
                                  // onChange={formik.handleChange}
                                  name='user_name'
                                  id='inputuserName'
                                  placeholder='User Name'
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor='inputEmail'>
                                  <i class='fas fa-envelope'></i>&nbsp;Email
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={user?.email}
                                  //  onChange={formik.handleChange}
                                  disabled
                                  name='email'
                                  id='inputEmail'
                                  placeholder='Email'
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor='inputphone'>
                                  <i class='fas fa-mobile'></i>&nbsp;Phone
                                </label>
                                <input
                                  type='tel'
                                  className='form-control'
                                  value={user?.phone}
                                  //  onChange={formik.handleChange}
                                  disabled
                                  name='phone'
                                  id='inputphone'
                                  placeholder='Phone'
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label htmlFor='full_address'>
                                  <i class='fas fa-address-card'></i>
                                  &nbsp;Full-Address
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={formik.values.full_address}
                                  onChange={formik.handleChange}
                                  name='full_address'
                                  id='full_address'
                                  placeholder='Full-Address'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.full_address}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputcity'>
                                  <i class='fas fa-building'></i>&nbsp;City
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputcity'
                                  value={formik.values.city}
                                  onChange={formik.handleChange}
                                  name='city'
                                  placeholder='City'
                                />
                                <span className='text-danger'>
                                  {validation_error && validation_error.city}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputzipcode'>
                                  <i class='fas fa-building'></i>&nbsp;Zip Code
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputzipcode'
                                  value={formik.values.zip_code}
                                  onChange={formik.handleChange}
                                  name='zip_code'
                                  placeholder='Zip Code'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.zip_code}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputbio'>
                                  <i class='fas fa-info-circle'></i>&nbsp;About
                                  Yourself (150 characters.)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={formik.values.bio}
                                  onChange={formik.handleChange}
                                  name='bio'
                                  id='inputbio'
                                  placeholder='About Yourself Bio'
                                />
                                <span className='text-danger'>
                                  {validation_error && validation_error.bio}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputfacebook'>
                                  <i class='fab fa-facebook'></i>&nbsp;Facebook
                                  url (include https://)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={formik.values.facebook_id}
                                  onChange={formik.handleChange}
                                  name='facebook_id'
                                  id='inputfacebook'
                                  placeholder='https://'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.facebook_id}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputtwitter'>
                                  <i class='fab fa-twitter'></i>&nbsp;Twitter
                                  url (include https://)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={formik.values.twitter_id}
                                  onChange={formik.handleChange}
                                  name='twitter_id'
                                  id='inputtwitter'
                                  placeholder='https://'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.twitter_id}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputlinkedin'>
                                  <i class='fab fa-linkedin'></i> &nbsp;Linkedin
                                  url (include https://)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputlinkedin'
                                  value={formik.values.linkedin_id}
                                  onChange={formik.handleChange}
                                  name='linkedin_id'
                                  placeholder='https://'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.linkedin_id}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputlinkedin'>
                                  <i class='fab fa-linkedin'></i>{" "}
                                  &nbsp;Pinterest url (include https://)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputlinkedin'
                                  value={formik.values.pinterest_id}
                                  onChange={formik.handleChange}
                                  name='pinterest_id'
                                  placeholder='https://'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.pinterest_id}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputuserImage'>
                                  <i class='fas fa-user-circle'></i>&nbsp;Change
                                  Profile Picture
                                </label>
                                <div class='input-group'>
                                  <div class='custom-file'>
                                    <input
                                      type='file'
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "profile_image",
                                          e.target.files[0]
                                        );
                                      }}
                                      name='profile_image'
                                      class='custom-file-input'
                                      id='inputuserImage'
                                    />
                                    <label
                                      class='custom-file-label'
                                      for='inputuserImage'>
                                      Choose file
                                    </label>
                                  </div>
                                </div>
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.profile_image}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='inputcompany'>
                                  <i class='fas fa-building'></i>&nbsp;Company
                                  Name (If available)
                                </label>
                                <input
                                  type='text'
                                  className='form-control'
                                  id='inputcompany'
                                  value={formik.values.company_name}
                                  onChange={formik.handleChange}
                                  name='company_name'
                                  placeholder='Company Name'
                                />
                                <span className='text-danger'>
                                  {validation_error &&
                                    validation_error.company_name}
                                </span>
                              </div>

                              <div className='form-group col-md-6'>
                                <label htmlFor='male'>
                                  <i class='far fa-user-circle'></i>&nbsp;Gender
                                </label>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    onChange={formik.handleChange}
                                    name='gender'
                                    id='male'
                                    value='male'
                                    defaultChecked
                                    checked={formik.values.gender === "male"}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='male'>
                                    Male
                                  </label>
                                </div>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    onChange={formik.handleChange}
                                    name='gender'
                                    id='female'
                                    value='female'
                                    checked={formik.values.gender === "female"}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='female'>
                                    Female
                                  </label>
                                </div>
                                <span className='text-danger'>
                                  {validation_error && validation_error.gender}
                                </span>
                              </div>
                            </div>
                            <div className='form-group row'>
                              <div className='col-12'>
                                <button
                                 disabled={disableSubmitBtn}
                                  type='submit'
                                  class='btn btn-info btn-block'>
                                  <i class='fas fa-paper-plane'></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                    {/* /.tab-pane */}
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
};

export default ProfileSec;
