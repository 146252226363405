import React from "react";
import "./Title_img.css";

const Titleimg = () => {
  return (
    <>
      <p className="section_title_img pb-0 mb-0">
        <img
          className="sec_img img-fluid mx-auto  d-block"
          src="/assest/image/logo/ourrajshahi-icon.png"
          alt="section_title_img"
        />
      </p>
    </>
  );
};

export default Titleimg;
