import React from 'react'
import AddCouponSec from '../../../../Components/admin/CouponSection/AddCouponSec'

const AddCoupon = () => {
  return (
    <>
    <AddCouponSec/>
    </>
  )
}

export default AddCoupon