import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function MiniSlider(props) {
  //  get mini_slider data
  const [minislider, setMinislider] = useState([]);
  const [loading, setloading] = useState(true);
  
// get data
 useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await  axios.get("/mini-slider/all");
      if(isMounted){
        if (res.data.status === 200) {
          setMinislider(res.data.mini_slider);
         }
         setloading(false);
      }
       
    } catch (e) {
       console.log(e);
    }
  }
  
  fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);



  var settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
       {/* seo meta tag  start */}
   {
            minislider?.map(val=>
              (
                // <>
                 <Helmet>
                <meta name='keywords' content='OurRajshahi' />
                <meta name='description' content={val?.title} />
                <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
                <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        
                 {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={val?.title}  />
                <meta
                  itemprop="description"
                  content={val?.title} 
                />
                <meta
                  itemprop="image"
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  itemProp='image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  name='description'
                  itemProp='description'
                  content={val?.title} 
                />
        
        
                 {/* <!-- Facebook Meta Tags --> */}
                <meta name='og:title' content='OurRajshahi' />
                
                <meta
                  name='og:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
               
                <meta
                  name='og:description'
                  content={val?.title} 
                />
        
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content='summary_large_image' />
                <meta
                  name='twitter:title'
                  content='OurRajshahi' 
                />
                <meta
                  name='twitter:description'
                  content={val?.title} 
                />
                <meta
                  name='twitter:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                </Helmet> 
                // </>
              )
            )}
      {/* seo meta tag  end */}


        <Slider {...settings2}>
          {
          loading ? 
          Array(5)
            .fill()
            .map((val, idx) => {
              return (
                
                    <ContentLoader
                      speed={2}
                      width='100%'
                      height='100%'
                      viewBox='0 0 400 165'
                      backgroundColor='#f3f3f3'
                      foregroundColor='#ecebeb'
                      {...props}>
                      <rect
                        x='50'
                        y='3'
                        rx='3'
                        ry='3'
                        width='300'
                        height='160'
                      />
                    </ContentLoader>
                  
              );
            })
            :
           minislider?.map((val) => {
            return (
                <div className='mini-product' key={val.id} data-index={val.id}>
                    <Link to={val.url}>
                      <LazyLoadImage
                         effect="blur"
                        className='mx-auto rounded border'
                        width="100%"
                        height="140px"
                        placeholderSrc='./assest/image/loader/placeholder.jpg'
                        src={
                          val.image &&
                          `${process.env.REACT_APP_API_URL}${val?.image}`
                        }
                        alt={val?.img_alt}
                      />
                    </Link>
                </div>
              
            );
          })}
        </Slider>
  
    </>
  );
}
