import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";

const AllHeaderSearchOptionSec = () => {
  //  get header search option  data
  const [headerSearchOption, setheaderSearchOption] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
    axios.get("/setting/header-search-option").then((res) => {
      if (res.data.status === 200) {
        setheaderSearchOption(res.data.data);
      }
      setloading(false);
    });
  }, []);

  useEffect(() => {
    // initialize datatable
    if (!loading) {
      const script = document.createElement("script");
      script.src = "/dist/js/custom.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [loading]);

  const handleDeleteItems = (id) => {
    axios
      .delete(`/setting/header-search-option/delete/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setheaderSearchOption(
            headerSearchOption.filter((item) => item.id !== id)
          );
        }
      })
      .catch((e) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title='Setting'
        pageTitle='All-Header-Search-Option'
      />
      {/* Main content */}
      <section className='content'>
        {/* Default box */}
        <div className='card'>
          <div className='card-body p-0'>
            <table id='example1' className='table table-striped projects'>
              <thead>
                <tr>
                  <th className='text-center'>Sl</th>
                  <th className='text-center'>Search Option</th>
                  <th className='text-center'>Edit</th>
                  <th className='text-center'>Delete</th>
                </tr>
              </thead>
              <tbody>
              {headerSearchOption.length == 0 ? (
                  <td
                    colSpan="12"
                    className="text-center bg-warning font-weight-bold h4"
                  >
                    No data available in table
                  </td>
                ) : (
                <>
                  {headerSearchOption && headerSearchOption.map((val) => {
                    return (
                      <tr key={val.id}>
                        <td className='text-center'>{val.id}</td>

                        <td className='text-center'>{val.search_option}</td>

                        <td className='text-center'>
                          <Link
                            to={`/our-dashboard/setting/edit-header-search-option/${val.id}`}
                            className='btn btn-info btn-sm'
                            href='#/'>
                            <i className='fas fa-edit pr-2' />
                            Edit
                          </Link>
                        </td>
                        <td className='text-center'>
                          <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                  
                </>
                )}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllHeaderSearchOptionSec;
