import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { Link } from "react-router-dom";
import Loader from './../../frontend/Loader/Loader';
import axios from "axios";
import {Helmet} from "react-helmet";

const AllOrderAmount = () => {
 //  get order data
 const [data, setData] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;

   axios.get("/accounts/all").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
        setData(res.data.data);
     }
     setloading(false);
    }
   }).catch((e) => {});
  
  return () => {
    isMounted = false
  }
 }, []);
 
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


const {today_amount, weekly_amount, monthly_amount, yearly_amount}= data;

if (loading) {
  return <Loader />;
}
  return (
    <>
           {/* seo title */}
       <Helmet>
       <title>Accounts</title>
       </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Accounts" pageTitle="Accounts" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr >
                <th  >
                   Time
                  </th>
                  <th >
                   Total Amount
                  </th>
                  <th >
                   Total Charge
                  </th>
                  <th >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
             
                 
                   
                      <tr>
                        <td>Today</td>
                        <td>৳ {today_amount}</td>
                        <td></td>
                       <td>
                          <Link
                            to={`/our-dashboard/today-sell`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                      </tr>

                      <tr>
                        <td>Last 7 day</td>
                        <td>৳ {weekly_amount}</td>
                        <td></td>
                        
                       <td>
                          <Link
                            to={`/our-dashboard/weekly-sell`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                      </tr>

                      <tr>
                        <td>This Month</td>
                        <td>৳ {monthly_amount}</td>
                        <td></td>
                        
                       <td>
                          <Link
                            to={`/our-dashboard/monthly-sell`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                      </tr>


                      <tr>
                        <td>This Year</td>
                        <td>৳ {yearly_amount}</td>
                        <td></td>
                        
                       <td>
                          <Link
                            to={`/our-dashboard/yearly-sell`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                   
              </tbody>
              <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>৳ {today_amount + weekly_amount + monthly_amount + yearly_amount}</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </tfoot>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  )
};

export default AllOrderAmount;
