import React from 'react'
import LiveStreamingSection from '../../../Components/frontend/LiveStreamingSection/LiveStreamingSection'

const LiveStreaming = () => {
  return (
    <>
    <LiveStreamingSection/>
    </>
  )
}

export default LiveStreaming