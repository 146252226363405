import React from 'react'
import CommunityTourismSection from '../../../Components/frontend/CommunityTourismsection/CommunityTourismSection'

const CommunityTourism = () => {
  return (
    <>
    <CommunityTourismSection/>
    </>
  )
}

export default CommunityTourism