import React from 'react'
import { Link } from 'react-router-dom'
const PageHeaderBreadCrumb = (props) => {
  const { pageTitle, Title } = props;
  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header border-bottom" style={{ padding: "4px .5rem", marginBottom: "8px" }}>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 page-title">{Title}</h1>
            </div>{/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to="/our-dashboard">Home</Link></li>
                <li className="breadcrumb-item active">{pageTitle}</li>
              </ol>
            </div>{/* /.col */}
          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
    </>
  )
}

export default PageHeaderBreadCrumb