import React, {useState, useContext } from "react";
import { Link, useNavigate} from "react-router-dom";
import "./ProductsCard.css";
import cartNoteContext from "../Context/Cart/cartNoteContext";
import WishlistNoteContext from "../Context/Wishlist/wishNoteContext";
import Helmet from "react-helmet";
import { BsCart3 } from "react-icons/bs";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import authContext from "./../../auth/Context/AuthUser/authContext";
const ProductsCard = ({
  product_img,
  selling_price,
  discount_price,
  product_slug,
  product_name,
  id,
  product_colors,
  meta_keyword,
  meta_description,
}) => {
 
  // login
  const { Login, toggleShow } = useContext(authContext);
   const history = useNavigate();
   const { itemCart, addToBasketCart, removeItemCart } =
    useContext(cartNoteContext);
    // wishlist
  const { itemWish, addToBasketWish, removeItemWish } =
  useContext(WishlistNoteContext);

// defaults para metter pass add to cart with card icon
const [defaults, setDefaults] = useState({
  color:'',
  size:'', 
  countqty: 1,
 });



// filter cart id by product id-----get single cart item
const filterCart = itemCart?.filter((cart) => cart?.product_id === id);

// check cart id by product  id------true or false
let checkCart = itemCart?.some((cart) => cart.product_id === id);

// check wish id by product  id------true or false
let checkWish = itemWish?.some(
  (wish) => wish.product_id === id
);

// filter wish id by product id-----get single wish item
const filterWish = itemWish?.filter(
  (wish) => wish.product_id === id
);


// handle add to cart 
const handleAddtoCart = (pro_name)=>{
  product_colors?.length > 0 ?
  history(`/product/${pro_name}`)
  :
  addToBasketCart(
    id,
    defaults.color,
    defaults.size,
    defaults.countqty,
  )
  
}

// handle add to wish 
const handleAddtoWish= (pro_name)=>{
  product_colors?.length > 0 ?
  history(`/product/${pro_name}`)
  :
  addToBasketWish(
    id,
    defaults.color,
    defaults.size,
    defaults.countqty,
  )
  
}



  return (
    <>
    {/* seo meta tag  start */}
    <Helmet>
         <meta name='keywords' content={meta_keyword} />
        <meta name='description' content={meta_description} />
        <meta name='subject' content={meta_description} />
        <meta name='topic' content={meta_description} />
       
         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={product_name}  />
        <meta
          itemprop="description"
          content={meta_description}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${product_img}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={meta_description}
        />
        
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={meta_description} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${product_img}`}
        />
        <meta
          property='og:image:alt'
          content={product_name}
        />
        <meta
          name='og:description'
          content={meta_description}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content={product_name} 
        />
        <meta
          name='twitter:description'
          content={meta_description}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${product_img}`}
        />
        <meta
          name='twitter:image:alt'
          content={product_name}
        />
      </Helmet> 
      {/* seo meta tag  end */}




      <div className='product-card position-relative bg-white rounded shadow-1 hover-shadow border'>
        <div className='position-absolute top-0 p-2  end-0  mt-2 hover_icon z_index'>
            {/* add cart */}
        {Login ? (
          checkCart ? (
          <p  className='icon_wrap' 
          onClick={()=> 
          removeItemCart(filterCart[0]?.id,
           filterCart[0]?.product_id)}>
            <BsCart3 className='text-danger' style={{ fontSize: "13px"}}  />
          
          </p>
          )
          :(
            <p className='icon_wrap' onClick={()=> handleAddtoCart(product_slug)}>
                <BsCart3 style={{ fontSize: "13px"}}  />
            </p>
          )
          ) : (
            <p className='icon_wrap' onClick={toggleShow}>
                <BsCart3 style={{ fontSize: "13px"}}  />
            </p>
          )}


         {/* add wish */}
         {Login ? (
         checkWish ? (
          <p  className='icon_wrap' 
            onClick={()=> 
            removeItemWish(
            filterWish[0]?.id,
            filterWish[0]?.product_id
          )}>
          <i className='far fa-heart text-danger'></i>
          </p>
          )
          :(
            <p className='icon_wrap'  onClick={()=> handleAddtoWish(product_slug)}>
             <i className='far fa-heart'></i>
            </p>
          )
          ) : (
            <p className='icon_wrap' onClick={toggleShow}>
               <i className='far fa-heart'></i>
            </p>
          )}
        </div>
        <Link to={`/product/${product_slug}`}>
          {
            <LazyLoadImage 
            effect="blur" 
             src={`${process.env.REACT_APP_API_URL}${product_img}`} 
             alt='products-img' 
             className='img-fit'
             placeholderSrc='./assest/image/loader/placeholder.jpg'
              />
          }
        </Link>
        <div>
          <div className='p-2 px-3 bottom-text'>
          <div className='fs-6'>
             {
              Number(discount_price) > 0 &&
              <del>৳ {Number(selling_price).toFixed(0)}</del>
             }
              <span className='prices text-info ms-3'>৳ {Number(selling_price) - Number(discount_price)}</span>
            </div>
            <h3 className='product_name'>
             <Link className='text-dark' to={`/product/${product_slug}`}>
              {product_name}
              </Link>
              </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsCard;
