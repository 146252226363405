import React, { useContext, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import wishNoteContext from "../Context/Wishlist/wishNoteContext";
import { Link } from "react-router-dom";

const MobileWishlist_item = ({
  id,
  product,
  product_colors,
  product_qty,
  product_id,
}) => {
  const { removeItemWish } = useContext(wishNoteContext);

  //  modal
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  
  return (
    <>
      <div className='col-10 mx-auto'>
        <MDBCard>
          <MDBCardBody>
            <Link to={`/product/${product?.product_slug}`} className='text-dark'>
              <p
                className='h6 text-center p-2 mb-0 rounded text-white bg-info'>
                Product Details
              </p>
              <MDBCardImage
                className='mobile_wishlist_img rounded'
                src={
                  product?.product_img == null
                    ? null
                    : `${process.env.REACT_APP_API_URL}${product?.product_img}`
                      
                }
                position='top'
                alt='cart-img'
              />
              <span className='d-flex border'>
                <p className='ps-1 mb-0 px-2'> {product?.product_name}</p>
              </span>
              <span className='d-flex border'>
                <p className='fw-normal text-black-50 mb-0 px-2'>SKU:</p>&nbsp;
                <p className='ps-1 fw-normal mb-0 px-2 '>  {product?.product_code}</p>
              </span>
              {product?.brand && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50 mb-0 px-2'>Brand:</p>
                    <p className='ps-1 fw-normal mb-0 px-2'>
                      {product?.brand?.brand_name}
                    </p>
                  </span>
                )}
                {product?.color && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50 mb-0 px-2'>Color:</p>
                    <p className='ps-1 fw-normal mb-0 px-2'>
                      {product_colors?.color?.color_name}
                    </p>
                  </span>
                )}
                
                {product?.size && (
                  <span className='d-flex'>
                    <p className='fw-normal text-black-50 mb-0 px-2'>Size:</p>
                    <p className='ps-1 fw-normal text-dark mb-0 px-2'>
                      {product?.size}
                    </p>
                  </span>
                )}
            </Link>
            <div className='Quantity_price_remove'>
              <div
                className='mt-2 d-flex justify-content-between bg-info'>
                <div>
                  <p className='h6  p-2 mb-0 rounded text-white'>Price</p>
                </div>
                <div>
                  <p className='h6  p-2 mb-0 rounded text-white'>Remove</p>
                </div>
              </div>
              <div className='d-flex justify-content-between pt-2'>
                <span>
                  <p className='mb-0'>৳ {Number(product?.selling_price) - Number(product?.discount_price) * product_qty}</p>
                </span>
                <span className='text-danger'>
                  <MDBBtn
                    onClick={toggleShow}
                    style={{ backgroundColor: " #ff4747", color: "white" }}>
                    <i className='fas fa-trash-alt text-dark fs-6'></i>
                  </MDBBtn>
                </span>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
        
        {/* cart remove modal */}
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
          <MDBModalDialog scrollable className='modal-sm'>
            <MDBModalContent className='p-0'>
              <MDBModalHeader className='bg-danger text-white d-flex justify-content-center'>
                <MDBModalTitle className='p-3'>
                  <p className='h5 text-white'>Are You Sure ?</p>
                </MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody className='text-center'>
                <i class='fas fa-times text-danger fa-3x'></i>
                <p className='mb-2'>Item will be removed from Wishlist</p>
              </MDBModalBody>
              <MDBModalFooter>
                <div>
                  <MDBBtn color='danger' onClick={toggleShow} >
                    <p className='mb-0' onClick={() => removeItemWish(id,product_id)}>
                      Yes
                    </p>
                  </MDBBtn>
                </div>
                <MDBBtn
                  outline
                  className='mx-2'
                  color='danger'
                  onClick={toggleShow}>
                  No
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
};

export default MobileWishlist_item;
