import React, { useState } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
const AddBrand = () => {
  const [insert, setinsert] = useState({
    brand_name: "",
  });
  const {brand_name} = insert;

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const handleChange = (e) => {
    setinsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("brand_name", brand_name);
    axios
    .post(`/brand/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setinsert({
          brand_name: "",
        });
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});

  };
  return (
    <>
   {/* seo title */}
   <Helmet>
        <title> Add || Brand</title>
        </Helmet>
        
              {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Brand" pageTitle="Add-Brand" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Brand</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/all/brand"
                    className="btn btn-success btn"
                  >
                    All Brand
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} encType='multipart/form-data' id='resetForm'>
                 
                  <div className="form-group">
                    <label htmlFor="brand">brand</label>
                    <input
                      required
                      type="text"
                      name="brand_name"
                      value={brand_name}
                      onChange={handleChange}
                      id="brand"
                      className="form-control"
                      placeholder="Enter Brand Name"
                    />
                  </div>
                  {
                    validation_error.brand_name &&
                    <span className='text-danger'>
                          {validation_error.brand_name}
                        <br/>
                      </span>
                  }
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddBrand;
