import React from "react";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import ReactPlayer from "react-player";
const Documentory = () => {
  return (
    <>
      <div className='documentary py-2'>
        <div className='container'>
          <Title section_title='Documentary' />
          <Titleimg />
          <div className='row'>
            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=EJY0jNhFecE'
                />
              </div>
            </div>

            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=9DVG3aCcOdA'
                />
              </div>
            </div>

            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=Qq6PYqHN2Ho'
                />
              </div>
            </div>

            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=-NQOXqyp0ao'
                />
              </div>
            </div>
            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=LqUf-SsbkzU'
                />
              </div>
            </div>
            <div className='col-md-2 col-10 mx-auto mt-4'>
              <div className='youtube_video'>
                <ReactPlayer
                  width='100%'
                  url='https://www.youtube.com/watch?v=ZvQdQOBtv6o'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documentory;
