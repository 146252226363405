import React from "react";
import AddGallerySec from "../../../../Components/admin/GallerySection/AddGallerySec";

const AddGallery = () => {
  return (
    <>
      <AddGallerySec />
    </>
  );
};

export default AddGallery;
