export const EmagazineData = [
    {
        title:"E-Magazine title",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is",
        img:"https://mdbootstrap.com/img/new/slides/017.webp",
        time:"Last updated 2 days ago"
    },
    {
        title:"E-Magazine title",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is",
        img:"https://mdbootstrap.com/img/new/slides/017.webp",
        time:"Last updated 2 days ago"
    },
    {
        title:"E-Magazine title",
        text:"This is a wider card with supporting text below as a natural lead-in to additional content. Thiscontent is",
        img:"https://mdbootstrap.com/img/new/slides/017.webp",
        time:"Last updated 2 days ago"
    }
]