import React from "react";
import ViewSOSCategoryWrapper from "../../../../Components/admin/Sub_Categories_Section/ViewSOSCategoryWrapper";

const ViewSOScategory = () => {
  return (
    <>
      <ViewSOSCategoryWrapper />
    </>
  );
};

export default ViewSOScategory;
