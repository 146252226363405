import React, { useState, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader"
import axios from "axios";
import { MDBCard, MDBListGroupItem } from "mdb-react-ui-kit";
import "./BlogRightSite.css";

const BlogRightSite = (props) => {

  const [banner, setBanner] = useState([]);
  const [blog_mega_menu, setBlog_mega_menu] = useState([]);
  const [blog_recent_post, setBlog_recent_post] = useState([]);
  const [loading, setloading] = useState(true);
   let location = useLocation();

  useEffect(() => {
      let isMounted = true;
      
      const fetchData = async () => {
        try{
          await Promise.all([
            axios.get("/blog/right-site-others"),
            axios.get("/blog/post/recent"),
            axios.get("/blog/mega-menu"),
            
        ]).then(axios.spread((right, recent, mega_menu) => {
               if(isMounted){
                setBanner(right.data.blog_right_site_others);
                setBlog_recent_post(recent.data.blog_recent_post);
                setBlog_mega_menu(mega_menu.data.blog_mega_menu);
               }
               setloading(false)
           }))
         
        }catch(e){
          console.log(e);
        }
      }
        
     fetchData();
      
      return () => {
        isMounted = false;
      }

  }, [location]);

 


useEffect(() => {
    if (window.FB) {
        window.FB.XFBML.parse();
    }
},[]);


  return (
    <>
      <div className='blog_right_site'>
        <MDBCard className='border'>
          <MDBCard>
            <div className='blog_banner px-4 py-2'>
              { !banner?.add_banner_1 ?
              <div className='mx-auto text-center'>
                <ContentLoader 
                speed={2}
                height="100%" 
                width="100%"
                // viewBox="0 0 476 154"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="10" y="9" rx="3" ry="3" width="95%" height="26" /> 
                <rect x="10" y="52" rx="3" ry="3" width="95%" height="93" />
              </ContentLoader>
              </div>
               :
               <>
               <div className='title custom_bg text-center mt-1'>
                <span>Our Rajshahi</span>
              </div>
              <div className='banner_img py-3'>
                {
                  banner?.add_banner_1 && 
                  <img
                  className='img-fluid rounded'
                  src={
                    banner?.add_banner_1 == null
                      ? null
                      : `${process.env.REACT_APP_API_URL}${banner?.add_banner_1}`
                  }
                  alt='banner_img'
                />
                }
                
              </div>
              </>
              }
              
            </div>
          </MDBCard>

          <div className='recent_post_wrapper px-4  mt-2 pb-4'>
            <MDBCard>
              <div className='title custom_bg text-center mt-1'>
                <span>Recent Post</span>
              </div>
              {loading  ? 
              <div className='mx-auto text-center'>
             <ContentLoader 
             speed={2}
             height="90%" 
             width="100%"
             viewBox="0 0 260 40"
             backgroundColor="#f3f3f3"
             foregroundColor="#ecebeb"
             {...props}
           >
             <rect x="70" y="9" rx="3" ry="3" width="165" height="13" /> 
             <rect x="5" y="2" rx="0" ry="0" width="51" height="36" />
           </ContentLoader>
           </div>
              :
              blog_recent_post?.map((val) => {
                return (
                  <div className='recent_post py-2 border' key={val.id}>
                    <Link  className='text-dark' to={`/blog/post/${val?.slug.toLowerCase()}`}>
                      <div className='thumbile px-2'>
                        <img
                          className='img-fluid'
                          src={
                            val?.blog_image == null
                              ? null
                              : `${process.env.REACT_APP_API_URL}${val?.blog_image}`
                          }
                          alt='blog-img'
                        />
                      </div>
                      <div className='post_context'>
                        <span>
                        <div
                      dangerouslySetInnerHTML={{
                        __html: val.post_short_description,
                      }}
                    />
                          
                          </span>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </MDBCard>
          </div>

          <div className='post_cate_info px-4 card'>
            <div className='title custom_bg text-center mt-1'>
              <span>All Categories</span>
            </div>
            <div className='ps-3'>
              {blog_mega_menu?.map((val) => {
                const {category_slug}=val;
                return (
                  <ul className='post_cate_gory_meu' key={val.id}>
                    <li className='post_cate_gory_item'>
                      <MDBListGroupItem>
                        {" "}
                        <Link  className='text-dark' to={`/blog/category/${category_slug}`}>
                          <span className='post_cate_gory px-1'>
                            &nbsp;
                            {val?.category_name}
                          </span>
                        </Link>{" "}
                      </MDBListGroupItem>
                      <ul className='post_sub_category_menu card ms-3 p-1 w-75 text-center'>
                        {val?.blog_subcategory?.map((val) => {
                          return (
                            <li
                              className='post_sub_cate_gory_item border'
                              key={val.id}>
                                {" "}
                        <Link  className='text-dark' to={`/blog/category/${category_slug}/${val?.sub_category_slug}`}>
                          <span className='post_cate_gory px-1'>
                            &nbsp;
                            {val?.sub_category_name}
                          </span>
                        </Link>{" "}
                             
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
          <div className='facebook_page card px-4'>
            <div className='title custom_bg text-center mt-1'>
              <span>Facebook Page</span>
            </div>
              <div className='my-2'>
            <iframe
              title='facebook-page'
              src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fourrajshahidistrict&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
              height='130'
              style={{ border: "none", overflow: "hidden", margin:'auto', display:'block', width:'100%'}}
              scrolling='no'
              frameborder='0'
              allowfullscreen='true'
              allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe>
              </div>
          </div>

          <div className='blog_banner px-4 py-2'>
            { !banner?.add_banner_2 ?
              <ContentLoader 
              speed={2}
              height="100%" 
              width="100%"
              // viewBox="0 0 476 154"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect x="10" y="9" rx="3" ry="3" width="95%" height="26" /> 
              <rect x="10" y="52" rx="3" ry="3" width="95%" height="93" />
            </ContentLoader>
            :
            <>
            <div className='title custom_bg text-center mt-1'>
              <span>Our Rajshahi</span>
            </div>
            <div className='banner_img py-3'>
            {
                  banner?.add_banner_2 && 
                  <img
                  className='img-fluid rounded'
                  src={
                    banner?.add_banner_2 == null
                      ? null
                      : `${process.env.REACT_APP_API_URL}${banner?.add_banner_2}`
                  }
                  alt='banner_img'
                />
                }
            </div>
            </>
            }
            
          </div>
        </MDBCard>
      </div>
    </>
  );
};

export default React.memo(BlogRightSite);
