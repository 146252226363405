const Footer_contact_usData = [
  {
    title: "Contact Us",
    stay_connected: "Stay Connected:",
    name: "Hotline: 09611863686",
    icon: "fas fa-phone",
  },
  {
    name: "Phone: 01701089346",
    icon: "fas fa-mobile-alt",
  },
  {
    name: "Email: info@ourrajshahi.com",
    icon: "fas fa-envelope",
  },
  {
    name: "Address: Alupatti, Boalia, Rajshahi, BD",
    icon: "fas fa-map-marker-alt",
  },
];
export default Footer_contact_usData;
