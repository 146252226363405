import React from "react";
import "./ImageStoreSection.css";
import { ImageStoreData } from "./ImageStoreData";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
const ImageStoreSection = () => {
  return (
    <>
      <div className='image_store_Section py-2'>
        <div className='container-fluid'>
          <h5 className='section-title text-center fw-bold py-2'>
            Image Store
          </h5>
          <div className='row g-2'>
            {ImageStoreData.map((Value, index) => {
              return (
                <div
                  className='col-lg-3 col-md-3 col-10 mx-auto mt-2'
                  key={index}>
                  <div className='bg-image hover-zoom hover-overlay w-75 mx-auto'>
                    <a
                      href='https://www.imgpaper.com'
                      target='_blank'
                      rel='noreferrer'>
                      <LazyLoadImage
                       effect="blur"
                       placeholderSrc='./assest/image/loader/placeholder.jpg'
                        src={Value.img}
                        className='w-100 rounded'
                        alt='img-store'
                      />
                      <div
                        className='mask rounded'
                        style={{
                          background: "rgba(0, 0, 0, 0.4)",
                        }}></div>
                      <div className='prices_cart d-flex justify-content-between'>
                        <div className='mt-1'>
                          <p className='mb-0 text-white ms-2'>
                            $ {Value.price}
                          </p>
                        </div>
                        <div className='me-4 mt-1'>
                          <a
                            className='me-3 hover_icon'
                            href='https://www.imgpaper.com'>
                            <i class='fas fa-heart'></i>
                          </a>
                          <a
                            className='ms-3 hover_icon'
                            href='https://www.imgpaper.com'>
                            <i class=' cart fas fa-cart-plus'></i>
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageStoreSection;
