import React, {useContext} from "react";
import { Outlet} from "react-router-dom";
import HeaderTop from "../Header-Top/HeaderTop";
import MainHeader from "../Main-Header/MainHeader";
import Footer from "../Footer/Footer";
import PopUp from './../Login_Register_Wrapper/modal/PopUp';
import authContext from './../../auth/Context/AuthUser/authContext';


const HomeLayouts = () => {
  const { Login } = useContext(authContext);
  return <>
   <HeaderTop />
   <MainHeader />
   { !Login &&
    <PopUp/>
   }
     
      <Outlet />
    <Footer />
  </>;
};

export default HomeLayouts;
