import React from 'react'
import "./CommunityTourismSection.css"
import { Link } from 'react-router-dom'
import { MDBCard, MDBCardImage } from 'mdb-react-ui-kit';
import Title from '../SectionTitle/Title/Title';
import Titleimg from '../SectionTitle/Title_img/Title_img';
import { CommunityTourismData } from './communityTourismData';
const CommunityTourismSection = () => {
    return (
        <>
            <div className="community_tourism_section py-3">
                <div className="container">
                    <Title
                        section_title="Community-Tourism"
                    />
                    <Titleimg />
                    <div className="row">
                        {
                            CommunityTourismData.map((Value, index)=>{
                                return(
                       <div className="col-lg-3 col-md-3 col-10 mx-auto mt-4">
                            <MDBCard className='square  rounded hoverle_card  w-75'>
                                <div className="custom_bg text-center  rounded">
                                    <Link to="">
                                        <MDBCardImage className='img-fluid hoverble_img' src={Value.img} position='top' alt='cash-on-delivery' />
                                        <p className='text-center custom_color py-2 mb-0 fw-bold'>{Value.title}</p>
                                        <p className='text-center text-dark mb-0 '>{Value.text}</p>
                                    </Link>
                                </div>
                            </MDBCard>
                        </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommunityTourismSection