import React from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast,Zoom} from 'react-toastify';
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import {Helmet} from "react-helmet";
import EditorEdit from "../../Editor/Editor";

const AddSpecialServiceSec = () => {
  const [insert, setInsert] = useState({
    title: "",
    slug: "",
    image: "",
    img_alt: "",
    thumbnail_image: "",
    thumb_title: "",
    detail: "",

  });
  const { title, slug, image, img_alt, thumbnail_image, thumb_title, detail } =
    insert;
 // backend validation check
 const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const handleChange = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.files[0],
    });
  };


  const handleDetails = (newtext: any) => {
    setInsert({
      ...insert,
      detail: newtext,
    });
    
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("image", image);
    formData.append("img_alt", img_alt);
    formData.append("thumbnail_image", thumbnail_image);
    formData.append("thumb_title", thumb_title);
    formData.append("detail", detail);
    axios
    .post(`/setting/special-service/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setInsert({
          title: "",
          slug: "",
          image: "",
          img_alt: "",
          thumbnail_image: "",
          thumb_title: "",
          detail: "",
        });
         setValidation_error({});
        document.getElementById("resetForm").reset();
       
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});
  };
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title>Add || Special-Service</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Add-Special-Service" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Special Service</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/setting/all-special-service"
                    className="btn btn-success btn"
                  >
                    All Special Service
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    // required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={title}
                      className="form-control"
                      placeholder="Enter title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }

                   <div className="form-group">
                    <label htmlFor="inputtitle">Slug</label>
                    <input
                    // required
                      type="text"
                      id="slug"
                      name="slug"
                      onChange={handleChange}
                      value={slug}
                      className="form-control"
                      placeholder="Enter slug"
                    />
                  </div>
                  {
                    validation_error.slug &&
                    <span className='text-danger'>
                          {validation_error.slug}
                      
                      </span>
                  }

                 
                  <div className="form-group">
                    <label htmlFor="img">Image</label>

                    <input
                    // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(image)}
                  />
                   }
                  <div className="form-group">
                    <label htmlFor="inputslug">Image Alt</label>
                    <input
                    // required
                      type="text"
                      id="inputalt"
                      name="img_alt"
                      onChange={handleChange}
                      value={img_alt}
                      className="form-control"
                      placeholder="Enter Image Alt"
                    />
                  </div>
                  {
                    validation_error.img_alt &&
                    <span className='text-danger'>
                          {validation_error.img_alt}
                      <br />
                      </span>
                  }

                  <div className="form-group">
                    <label htmlFor="thumbnail_image">Thumbnail Image</label>
                    <input
                    // required
                      type="file"
                      id="thumbnail_image"
                      name="thumbnail_image"
                      onChange={handleChangeImage}
                      className="form-control"
                      placeholder="thumbnail image"
                    />
                  </div>
                  {
                    thumbnail_image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(thumbnail_image)}
                  />
                   }
                  {
                    validation_error.thumbnail_image &&
                    <span className='text-danger'>
                          {validation_error.thumbnail_image}
                      <br />
                      </span>
                  }

                  <div className="form-group">
                    <label htmlFor="thumb_title">Thumb Title</label>
                    <input
                    // required
                      type="text"
                      id="thumb_title"
                      name="thumb_title"
                      onChange={handleChange}
                      value={thumb_title}
                      className="form-control"
                      placeholder="Enter thumb title"
                    />
                  </div>
                  {
                    validation_error.thumb_title &&
                    <span className='text-danger'>
                          {validation_error.thumb_title}
                      <br />
                      </span>
                  }

                  <label htmlFor="thumbnail_image">Service Info / Details</label>
                     <EditorEdit
                      name="community"
                      handleChange={handleDetails}
                      value={detail}              />

                {
                    validation_error.detail &&
                    <span className='text-danger'>
                          {validation_error.detail}
                      <br />
                      </span>
                  }

                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto my-2"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
}

export default AddSpecialServiceSec;
