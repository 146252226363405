import React from 'react'
import AllMiniBannerSec from '../../../../Components/admin/Settings/MiniBanner/AllMiniBannerSec'

const AllMiniBannerSetting = () => {
  return (
    <>
    <AllMiniBannerSec/>
    </>
  )
}

export default AllMiniBannerSetting