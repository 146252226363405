import React from 'react'
import AddPermissionSec from '../../../Components/admin/Role_&_Permission/AddPermissionSec'

const AddPermission = () => {
  return (
    <>
    <AddPermissionSec/>
    </>
  )
}

export default AddPermission