import React from 'react'
import AllSpecialServiceSec from './../../../../Components/admin/Settings/SpecialService/AllSpecialServiceSec';


const AllSpecialServiceSetting = () => {
  return (
    <>
     <AllSpecialServiceSec/>
    </>
  )
}

export default AllSpecialServiceSetting