import React from "react";
import Item from './Item';
const Delivered = ({data}) => {
  return <>
      <Item title='Delivered' data={data} />
  </>;
};

export default Delivered;

