import React, {useContext } from "react";
import { BsCart3 } from "react-icons/bs";
import "./MobileMenu.css";
import { Link } from "react-router-dom";
import { MDBCard } from "mdb-react-ui-kit";
import { AiOutlineBars } from "react-icons/ai";
import AllcateGories from "../../ShopByDepartmentSection/AllcateGories";
import axios from "axios";
import { toast } from "react-toastify";
import authContext from "../../../auth/Context/AuthUser/authContext";
import wishNoteContext from "../../Context/Wishlist/wishNoteContext";
const MobileMenu = (props) => {

  const { Login, userLogOut, toggleShow } = useContext(authContext);
  const { itemWish } = useContext(wishNoteContext);
  const logout = () => {
    axios
      .post("/logout")
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("user_token");
          userLogOut();
        }
      })
      .catch((err) => {});
  };
  return (
    <>
        {/* mobile menu */}
        <div className='mobile-menu category cart'>
          <ul className='d-flex justify-content-around axtra-menu'>
            <li className='axtra-menu-item axtra-menu-wishlist text-center'>
              <Link to='/'>
                <i className='extra-icon fas fa-home text-info'></i>
                <br />
                <p>Home</p>
              </Link>
            </li>
            <li className='menu-btn axtra-menu-item  text-center'>
              <Link to='#'>
                <i className='extra-icon fas fa-bars text-info'></i>
                <br />
                <p>Categories</p>
              </Link>
            </li>
            <li className='axtra-menu-item axtra-menu-cart text-center'>
              <Link to='/cart'>
                <BsCart3   className='text-info' style={{ fontSize: "20px"}}  />
                <span className='mini-counter-item'>{itemWish?.length}</span>
                <p>Cart</p>
              </Link>
            </li>

            {Login && Login ? (
              /* user login dropdown menu  */
              <li className='axtra-menu-item axtra_menu_loging_account'>
                <img
                  className='img-fluid  user_img'
                  src={`${process.env.REACT_APP_API_URL}${props?.profile_image}`}
                  alt='user'
                />
                &nbsp;
                <div className='login_hover_menu'>
                  <MDBCard className='border py-2'>
                    <div className='ps-1'>
                      {props?.usertype === "admin" ? (
                        <div className='user_profile custom_hover pt-1 ps-2 border'>
                          <Link to='/our-dashboard' target='_blank'>
                            <p className='mb-0'>
                              <i className='fas fa-user-edit pe-2'></i>
                              &nbsp;Dashboard 
                            </p>
                          </Link>
                        </div>
                      ) : (
                        <>
                          <div className='user_profile custom_hover pt-1 ps-2 border'>
                            <Link to='/user/profile'>
                              <p className='mb-0'>
                                <i className='fas fa-user-edit pe-2'></i>
                                &nbsp;Profile
                              </p>
                            </Link>
                          </div>
                          {/* <div className='user_cart custom_hover pt-1 ps-2 border'>
                            <Link to='/cart'>
                              <p className='mb-0'>
                                <i className='fas fa-shopping-cart pe-2'></i>
                                &nbsp;Cart
                              </p>
                            </Link>
                          </div> */}
                          <div className='user_order custom_hover pt-1 ps-2 border'>
                            <Link to='/user/order'>
                              <p className='mb-0'>
                                <i className='fas fa-box-open pe-2'></i>&nbsp;
                                Orders
                              </p>
                            </Link>
                          </div>

                          <div className='user_change_password custom_hover pt-1 ps-2 border'>
                            <Link to='/user/change-password'>
                              <p className='mb-0'>
                                <i className='fas fa-unlock pe-2'></i>
                                &nbsp;Change Password
                              </p>
                            </Link>
                          </div>
                          <div className='user_logout custom_hover pt-1 ps-2 border'>
                            <Link to='/'onClick={logout}>
                              <p className='mb-0'>
                                <i class='fas fa-power-off pe-2'></i>
                                &nbsp;Logout
                              </p>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </MDBCard>
                </div>
              </li>
            ) : (
              /* login wrap */
              <li className='axtra-menu-item axtra-menu-cart text-center'>
              <a href="##" className='mt-2 text-dark  hover_effect' onClick={toggleShow}>
                       <i className='extra-icon far fa-user text-info'> </i> 
                       <p className='login-text pb-0'>Login</p>
                      </a>
              </li>
            )}
          </ul>
        </div>

        {/* mobile side nav/ drawer */}
        <div className='mobile_side_drawer'>
          <div className='menu'>
            <Link to='/' className='back-home-site-icon'>
              <img
                style={{ height: "40px", width: "40", marginTop: "10px" }}
                src='assest/image/logo/ourrajshahi-icon.png'
                alt='ourrajshahi-site-icon'
              />
            </Link>

            <button className='hide-btn'>
              <i className='fas fa-times'></i>
            </button>
            <div className='mobile_left_category'>
              <p className='d-inline ps-3 '>
                <AiOutlineBars style={{ fontSize: "20px" }} />
                &nbsp; Categories
              </p>
              <AllcateGories />
            </div>
          </div>
        </div>
    </>
  );
};

export default MobileMenu;
