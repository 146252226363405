import React from 'react'
import DeactiveUserSec from '../../../../Components/admin/UserSection/DeactiveUserSec'

const DeactiveUser = () => {
  return (
    <>
    <DeactiveUserSec/>
    </>
  )
}

export default DeactiveUser