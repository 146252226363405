import React, { useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Loader from './../../frontend/Loader/Loader';
import moment from "moment";

const ExpiredCouponSec = () => {

  const [loading, setloading] = useState(true);
  const [data, setData]= useState([]);
 // get data
 useEffect(() => {
  let isMounted = true;
   
   axios.get("/coupon/expired").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setData(res.data.coupons);
     }
     setloading(false);
    }
   }).catch(error => {});
  
  return () => {
    isMounted = false
  }
 }, []);
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);



    // status active or deactive 
    const updateStatus = (id, status) => {
    const formData = new FormData();
    formData.append('_method', 'PUT')
    formData.append("status", status === 0 ? 1 : 0);
    axios
    .post(`/coupon/update_status/${id}`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

            setData(
              data.filter((item) => item.id !== id)
            );
          }
        })
        .catch((e) => {});
};

const handleDeleteItems = (id) => {
  axios
    .delete(`/coupon/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setData(
          data.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>
}
  return (
    <>
        {/* seo title */}
       <Helmet>
        <title> Expired || Coupon</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Coupon" pageTitle="Expired-Coupon" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Coupon Code</th>
                  <th>Discount %</th>
                  <th>Created</th>
                  <th>Limit Starter Time</th>
                  <th>For</th>
                  <th>Status</th>
                  <th>Action</th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                
                    {data.map((coupon) => {
                      return (
                        <tr key={coupon.id}>
                          <td>{coupon.id}</td>
                          <td>{coupon.coupon_code}</td>
                          <td>{coupon.discount} %</td>
                          <td>{moment(coupon.created_at).fromNow()}</td>
                          <td>
                            {  coupon.start_date !== null ?
                            coupon.start_date
                           : 
                           <span className='text-warning'>Full-Time</span>}</td>
                          <td>
                            {  coupon.end_date !== null ?
                            <>
                            {coupon.end_date} <span className='text-info'>hours</span> 
                            </>
                           : 
                           <span className='text-warning'>Full-Time</span>}</td>
                          <td><span className="p-1 rounded bg-success">
                         {coupon.status === 0 ? "Actived" : "Deactived"} 
                        </span>
                        </td>
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(coupon.id, coupon.status);
                                      }}
                                      >
                          Deatived Now
                        </span>
                      </td>

                          <td>
                            <Link
                              to={`/our-dashboard/edit-coupon/${coupon.id}`}
                              className="btn btn-info btn-sm"
                            >
                              <i className="fas fa-edit pr-2" />
                              Edit
                            </Link>
                          </td>

                          <td>
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${coupon.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${coupon.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(coupon.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                        </tr>
                      );
                    })}
                  
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default ExpiredCouponSec;
