export const RatingCheckData = [
  {
    id: "5",
    checked: false,
    rating: "5.00",
    icon: [
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
    ],
  },
  {
    id: "4",
    checked: false,
    rating: "4.00",
    icon: [
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
    ],
  },
  {
    id: "3",
    checked: false,
    rating: "3.00",
    icon: [
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
    ],
  },
  {
    id: "2",
    checked: false,
    rating: "2.00",
    icon: [
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
    ],
  },
  {
    id: "1",
    checked: false,
    rating: "1.00",
    icon: [
      {
        icon: "rating fas fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
      {
        icon: "unrating far fa-star",
      },
    ],
  },
];
