import React, {useContext} from "react";
import { NavLink} from "react-router-dom";
import { MDBCard, MDBCardBody, MDBBtn, MDBCardText } from "mdb-react-ui-kit";
import authContext from './../../auth/Context/AuthUser/authContext';
import { BsCart3 } from "react-icons/bs";
const UserProfileCard = (props) => {
 
  // auth login context
  const { currentUser, userDetails} =useContext(authContext);


  return (
    <>
      <MDBCard className='mt-2 border shadow-2'>
        <MDBCardBody className='p-0'>
          <div className='text-center rounded bg-white user_profile_card'>
            <img
              className='img-fluid  user_img'
              src={`${process.env.REACT_APP_API_URL}${currentUser?.profile_image}`}
              alt='user-img'
            />
          </div>
          <MDBCardText>
            <div className='user_details text-center pt-2'>
              <p className='name fw-bold mb-0 text-dark border-bottom'>{currentUser?.name}</p>
              <p className='user_name mb-0 border-bottom'>{currentUser?.user_name}</p>
              <p className='user_email mb-0 border-bottom'>{currentUser?.email}</p>
            </div>
            <div className='user_social_link text-center py-2 border-bottom'>
              <MDBBtn
                className='mx-1'
                href={userDetails?.user_detail?.facebook_id}
                style={{ backgroundColor: "#3B5998" }}
                tag='a'
                floating
                target='_blank'>
                <i className='text-white fab fa-facebook-f'></i>
              </MDBBtn>
              <MDBBtn
                className='mx-1'
                href={userDetails?.user_detail?.twitter_id}
                style={{ backgroundColor: "#28aae1" }}
                tag='a'
                floating
                target='_blank'>
                <i className='text-white fab fa-twitter'></i>
              </MDBBtn>
              <MDBBtn
                className='mx-1'
                href={userDetails?.user_detail?.linkedin_id}
                style={{ backgroundColor: "#0077ff" }}
                tag='a'
                floating
                target='_blank'>
                <i className='text-white fab fa-linkedin-in'></i>
              </MDBBtn>
              <MDBBtn
                className='mx-1'
                href={userDetails?.user_detail?.pinterest_id}
                style={{ backgroundColor: "#C41F26" }}
                tag='a'
                floating
                target='_blank'>
                <i className='text-white fab fa-pinterest-p'></i>
              </MDBBtn>
            </div>
            <div className='user_nav ps-4'>
              <li className='border-bottom'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/user/profile'>
                  <i className='text-info fas fa-user-edit pe-2'></i>&nbsp;Edit Profile
                </NavLink>
              </li>
              <li className='border-bottom'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/cart'>
                 <BsCart3 style={{ fontSize: "20px"}} className='text-info pe-2' /> &nbsp;Cart
                </NavLink>
              </li>
              <li className='border-bottom'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/wishlist'>
                  <i className='text-info far fa-heart pe-2'></i>&nbsp;Wishlist
                </NavLink>
              </li>
              <li className='border-bottom'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/user/order'>
                  <i className='text-info fas fa-box-open pe-2'></i>&nbsp;Orders
                </NavLink>
              </li>
              <li className='border-bottom'>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/checkout'>
                 <i class=" text-info fas fa-money-check pe-2"></i>&nbsp;Checkout
                </NavLink>
              </li>
              <li style={{ borderBottom: "none" }}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-info fw-bold" : "text-dark"
                  }
                  to='/user/change-password'>
                  <i className='text-info fas fa-unlock pe-2'></i>&nbsp;Change Password
                </NavLink>
              </li>
            </div>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </>
  );
};

export default UserProfileCard;
