import React, {useState,useEffect} from "react";
import axios from 'axios'
import "./WeekleyOffer.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Helmet from "react-helmet";

const WeeklyOfferSection = () => {
   //  get product data
   const [weeklyOffer, setWeeklyOffer] = useState([]);


  

 // get data
 useEffect(() => {
  let isMounted = true;

  const fetchData = async () => {
    try {
      const res = await axios.get("/weekly-offer/all");
      if(isMounted){
        if (res.data.status === 200) {
          setWeeklyOffer(res.data.weekly_offers);
         }
      }
     
    } catch (e) {
       console.log(e);
    }
}


  fetchData();

  return () => {
    isMounted = false;
  }
}, []);



  return (
       <>
   {/* seo meta tag  start */}
   {
            weeklyOffer?.map(val=>
              (
                 <Helmet>
                <meta name='keywords' content='OurRajshahi' />
                <meta name='description' content={val?.product_name} />
                <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
                <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        
                 {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={val?.product_name}  />
                <meta
                  itemprop="description"
                  content={val?.product_name} 
                />
                <meta
                  itemprop="image"
                  content={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                />
                <meta
                  itemProp='image'
                  ontent={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                />
                <meta
                  name='description'
                  itemProp='description'
                  content={val?.product_name} 
                />
        
        
                 {/* <!-- Facebook Meta Tags --> */}
                <meta name='og:title' content='OurRajshahi' />
                
                <meta
                  name='og:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                />
               
                <meta
                  name='og:description'
                  content={val?.product_name} 
                />
        
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content='summary_large_image' />
                <meta
                  name='twitter:title'
                  content='OurRajshahi' 
                />
                <meta
                  name='twitter:description'
                  content={val?.product_name} 
                />
                <meta
                  name='twitter:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                />
                </Helmet> 
              )
            )}
      {/* seo meta tag  end */}



       <div className='bannner_section py-2'>
       <h5
         className='section-title text-center 
      fw-bold py-2'>
         Weekly Offer
       </h5>
       <div className='container'>
         <div className='row shadow-2 bg-white g-2'>
          {
            weeklyOffer.map((val, idx) => {
              return(
            <div className={`col-10 mx-auto my-3 ${idx === 0 ? 'col-md-3' : idx === 1 ? 'col-md-2' : idx === 2 ? 'col-md-3' : 'col-md-2'}`} key={idx}>
             <Link to={`/product/${val?.product_slug}`}>
                   <LazyLoadImage
                    effect="blur"
                    width="100%"
                    height="125px"
                    placeholderSrc='./assest/image/loader/placeholder.jpg'
                     className='border rounded  hover-shadow hoverle_img'
                     src={`${process.env.REACT_APP_API_URL}${val?.product_img}`}
                     alt='banner-img'
                   />
             </Link>
           </div>
              )
            })
          }
           
         </div>
       </div>
     </div>
    </>
  );
};

export default WeeklyOfferSection;
