import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import './style.css'
const Unauthorized  = () => {
  
  return <>
      <div className='bg-light'>
          <div className='items'>
            <MDBCard className='text-center'>
              <MDBCardBody className='my-2'>
                <MDBCardTitle className="text-danger fw-bold fs-1">401</MDBCardTitle>
                <MDBCardText className='text-info fw-bold fs-2'>Unauthorized</MDBCardText>
                <MDBBtn color='info m-2'>
                  <a className='text-white' href='/'>
                    Go To Shopping
                  </a>
                </MDBBtn>
                <MDBBtn color='info m-2'>
                  <a className='text-white' href='/'>
                    Back to Home
                  </a>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </div>
      </div>
  </>;
};

export default Unauthorized ;




