import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import"./assets/dist/scss/mdb.free.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BrowserRouter } from "react-router-dom";

// auth login wishlist & cart data provider context
import AuthStateDataProvider from './Components/auth/Context/AuthUser/authState';
import WishlistStateDataProvider from "./Components/frontend/Context/Wishlist/WishlistState";
import CartStateDataProvider from "./Components/frontend/Context/Cart/CartState";



ReactDOM.render(
  <BrowserRouter>
  <AuthStateDataProvider>

  <CartStateDataProvider>
          <WishlistStateDataProvider>

      <App />

    </WishlistStateDataProvider>
        </CartStateDataProvider>

    </AuthStateDataProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
