import React from 'react'
import VisitorSummarySec from '../../../../Components/admin/VisitorSection/VisitorSummarySec'

const VisitorSummary = () => {
  return (
    <>
    <VisitorSummarySec/>
    </>
  )
}

export default VisitorSummary