import React, { useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { adminCheckData } from "./Role_&_PermissionData";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

const AllPermissionSec = () => {
  useEffect(() => {
    // initialize datatable
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  });
  return (
    <>
        {/* seo title */}
       <Helmet>
        <title>All-Permission ||Role-Permission</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Role & Permission"
        pageTitle="All-Permission"
      />
      {/* Main content */}
      <section className="content">
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr>
                  <th className="text-center">Permission Name</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-danger text-center font-weight-bold">
                    Admin
                  </td>
                </tr>
                {adminCheckData.map((Value, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{Value.check}</td>
                      <td className="text-center">
                        <Link
                          to={`/our-dashboard/edit-permission/${Value.id}`}
                          className="btn btn-info btn-sm"
                        >
                          <i className="fas fa-edit pr-2" />
                          Edit
                        </Link>
                      </td>
                      <td className="text-center">
                        <a
                          className="btn btn-danger btn-sm"
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm"
                          href="##"
                        >
                          <i className="fas fa-trash pr-2"></i>
                          Delete
                          <div
                            class="modal fade bd-example-modal-sm"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="mySmallModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-sm modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-body text-center">
                                  <i class="fas fa-times text-danger fa-3x"></i>
                                  <p className="mb-2 text-dark">
                                    <span className="font-weight-bold h4">
                                      Are you sure?
                                    </span>
                                    <br />
                                    Once deleted, you will not be able to
                                    recover this data!
                                  </p>
                                </div>
                                <div class="modal-footer border">
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-danger"
                                    data-dismiss="modal"
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}

    
    </>
  );
};

export default AllPermissionSec;
