export const reducer = (state, action) => {
  if (action.type === "USER_LOGIN") {
    return {
      ...state,
      Login: true,
    };
  }

  if(action.type === 'FETCH_CURRENT_USER'){
    return{
      ...state,
      currentUser:action.payload.data
    }
  }

  if(action.type === 'FETCH_USER_DETAILS'){
    return{
      ...state,
      userDetails:action.payload.data
    }
  }

  if(action.type === 'FETCH_END'){
    return{
      ...state,
      isLoading:action.payload.loading
    }
  }



  if(action.type === 'FETCH_RERENDER'){
    return{
      ...state,
      isRendering:action.payload.render
    }
  }

  if (action.type === "USER_LOGOUT") {
    return {
      ...state,
      Login: false,
    };
  }


  if (action.type === "LOGIN_POP_UP_SHOW_HIDE") {
    return {
      ...state,
      Modal: action.payload,
    };
  }

  


  return state;
};
