import React from 'react'
import GiveRoleAdminSec from '../../../../Components/admin/Admin/GiveRoleAdminSec'

const GiveRoleAdmin = () => {
  return (
    <>
    <GiveRoleAdminSec/>
    </>
  )
}

export default GiveRoleAdmin