import React from "react";
import AllPermissionSec from "../../../Components/admin/Role_&_Permission/AllPermissionSec";

const AllPermission = () => {
  return (
    <>
      <AllPermissionSec />
    </>
  );
};

export default AllPermission;
