import React, { useContext, useState, useEffect } from "react";
import Helmet from "react-helmet";
import "./style.css";
import {
  MDBProgress,
  MDBProgressBar,
  MDBTooltip,
  MDBBreadcrumb, 
  MDBBreadcrumbItem,
  MDBBadge
} from "mdb-react-ui-kit";
import ShopByDepartmentSection from "../ShopByDepartmentSection/ShopByDepartmentSection";
import ProductsCard from "../Products_Card/ProductsCard";
import ProductZoom from "./Product_Zoom_Effect/ProductZoom";
import ProductDescription from "./ProductDescription";
import ShareBtn from "./ShareBtn";
import RattingFillter from "./RattingFillter";
import { MDBCard, MDBBtn } from "mdb-react-ui-kit";
import { Link, useParams, useNavigate } from "react-router-dom";
import authContext from "./../../auth/Context/AuthUser/authContext";
import cartNoteContext from "../Context/Cart/cartNoteContext";
import WishlistNoteContext from "../Context/Wishlist/wishNoteContext";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import { Formik } from "formik";
import ContentLoader from "react-content-loader"
import LogoLoader from './../Loader/LogoLoader';
import ReactStars from "react-rating-stars-component";
import { BsCart3 } from "react-icons/bs";

const SingleProduct = (props) => {
  // login
  const { Login, toggleShow } = useContext(authContext);
  // cart
  const { itemCart, addToBasketCart, removeItemCart, isSubmitting } =
    useContext(cartNoteContext);
  // wishlist
  const { itemWish, addToBasketWish, removeItemWish, wishSubmitting } =
    useContext(WishlistNoteContext);
  const history = useNavigate();
  const { slug } = useParams();
  const [loading, setloading] = useState(true);

  //  get singleProduct data
  const [singleProduct, setSingleProduct] = useState([]);
  const [averageRating, setAverageRating] = useState([]);
  const [starRating, setStarRating] = useState([]);
  const [sameProduct, setSameProduct] = useState([]);
  const [similarProduct, setSimilarProduct] = useState([]);
  const [likeProduct, setLikeProduct] = useState([]);

  //  checked product id with order delivered  by product review
  const [orderCheck, setOrderCheck] = useState([]);

  const [countqty, setCountqty] = useState(1);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");


  // buy btn click 
  const [buyBtnAddtoCart, setBuyBtnAddtoCart] = useState(false);
  // wish list btn click 
    const [wishBtn, setWishlistBtn] = useState(false);
  
  

  // get data
  useEffect(() => {
    let isMounted = true;
    props.onTopProgress(30);
    const fetchData = async () => {
      try {
        setloading(true);
        const res = await axios.get(`/single-product/${slug}`);
        if(isMounted){
          if (res.data.status === 200) {
            setSingleProduct(res.data.single_product);
            setAverageRating(res.data.average_rating);
            setStarRating(res.data.star_rating);
            setSameProduct(res.data.same_product);
            setSimilarProduct(res.data.similar_product);
            setLikeProduct(res.data.like_product);
            setOrderCheck(res.data.check_order)
            props.onTopProgress(70);
         
          } else if (res.data.status === 404) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history("/");
          }
          setloading(false);
          props.onTopProgress(100);
        }
        
      } catch (e) {
         console.log(e);
      }
    }

    fetchData();
     
    return () => {
      isMounted = false;
    }
    
  }, [slug]);







//input radio default checked index === 0 color id get by auto selected
useEffect(() => {
  if (!loading) {
    const colorId = document.getElementById("color_0");
    if (colorId != null) {
      setSelectedColor(colorId?.value);
  }else{
    setSelectedColor('');
  }
      
  }
}, [slug,loading]);



// increment  count  qty
  const IncrementCountqty = () => {
    setCountqty(
      (prev) =>
        prev +
        (prev <
        (filterColorSize()[0]?.color_qty
          ? filterColorSize()[0]?.color_qty
          : singleProduct?.qty)
          ? 1
          : 0)
    );
  };


// decrement  count  qty
  const DecrementCountqty = () => {
    setCountqty((prev) => prev - (prev > 1 ? 1 : 0));
  };

  // color click by  filter size qty
  const filterColorSize = () => {
    let updateSize = [];
    if (selectedColor !== "") {
      updateSize =
        singleProduct?.product_colors &&
        singleProduct?.product_colors?.filter((item) =>
          item?.id.toString().includes(selectedColor.toString())
        );
    }
    return updateSize;
  };




  // filter cart id by product id-----get single cart item
  const filterCart = itemCart?.filter(
    (cart) => cart.product_id === singleProduct?.id
  );
  

  // check cart id by product  id------true or false
  let checkCart = itemCart?.some(
    (cart) => cart.product_id === singleProduct?.id
  );


 // filter wish id by product id-----get single wish item
 const filterWish = itemWish?.filter(
  (wish) => wish.product_id === singleProduct?.id
);


// check wish id by product  id------true or false
let checkWish = itemWish?.some(
  (wish) => wish.product_id === singleProduct?.id
);



  

  return (
    <>
    {/* seo meta tag  start */}
    <Helmet>
        ‍<title>{singleProduct?.product_name}</title>
         <meta name='keywords' content={singleProduct?.meta_keyword} />
        <meta name='description' content={singleProduct?.meta_description} />
        <meta name='subject' content={singleProduct?.meta_description} />
        <meta name='topic' content={singleProduct.meta_description} />
        <meta
            name='category'
            content={singleProduct?.category?.category_name}
          />



         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={singleProduct?.product_name}  />
        <meta
          itemprop="description"
          content={singleProduct?.meta_description}
        />
        <meta
          itemProp='image'
          ontent={`${process.env.REACT_APP_API_URL}${singleProduct?.product_img}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={singleProduct?.meta_description}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={singleProduct?.meta_description} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${singleProduct?.product_img}`}
        />
        <meta
          property='og:image:alt'
          content={singleProduct?.product_description}
        />
        <meta
          name='og:description'
          content={singleProduct?.meta_description}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content={singleProduct?.product_name} 
        />
        <meta
          name='twitter:description'
          content={singleProduct?.meta_description}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${singleProduct?.product_img}`}
        />
        <meta
          name='twitter:image:alt'
          content={singleProduct?.product_name}
        />
      </Helmet> 
      {/* seo meta tag  end */}



     <ShopByDepartmentSection /> 
      <section className='product_page bg-light py-2 overflow-hidden'>
        <div className='container-lg'>
          <div className='row'>
            {/* breadcrumb */}
            {
              !loading &&
           <MDBBreadcrumb>
                <MDBBreadcrumbItem>
                  <Link className='text-info' to="/"><i class="fas fa-home"></i></Link>
                </MDBBreadcrumbItem>
                    {
                      singleProduct?.category?.category_name &&
                   <MDBBreadcrumbItem>
                <Link className='text-info' to={`/shop/${singleProduct?.category?.category_slug}`}>
               
                {singleProduct?.category?.category_name}
              </Link>
                </MDBBreadcrumbItem>
                    }
                
                {
                      singleProduct?.subcategory?.sub_category_name &&
                <MDBBreadcrumbItem>
                <Link className='text-info' to={`/shop/${singleProduct?.category?.category_slug}/${singleProduct?.subcategory?.sub_category_slug}`}  >
                            {singleProduct?.subcategory?.sub_category_name}
                          </Link>
              </MDBBreadcrumbItem>
                }
                {
                     singleProduct?.soscategory?.sub_of_soscategory_name &&
                <MDBBreadcrumbItem>
                <Link className='text-info'
                   to={`/shop/${singleProduct?.category?.category_slug}/${singleProduct?.subcategory?.sub_category_slug}/${singleProduct?.soscategory?.sub_of_soscategory_slug}`}>
                                  {singleProduct?.soscategory?.sub_of_soscategory_name}
                                </Link>
            </MDBBreadcrumbItem>
             }
            <MDBBreadcrumbItem active>{singleProduct?.product_slug}</MDBBreadcrumbItem>
              </MDBBreadcrumb>

            }
            
            <div className='col-lg-6 col-md-6 col-12 mx-auto pt-1'>
              {
                loading ?
                <>
          <ContentLoader 
          speed={2}
          width='100%'
           height='100%'
          viewBox="0 0 503 600"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="40" y="15" rx="0" ry="0" width="461" height="280" /> 
          <rect x="77" y="320" rx="3" ry="3" width="72" height="32" /> 
          <rect x="191" y="320" rx="3" ry="3" width="68" height="27" /> 
          <rect x="299" y="320" rx="3" ry="3" width="68" height="27" /> 
          <rect x="404" y="320" rx="3" ry="3" width="68" height="27" /> 
          <rect x="91" y="380" rx="0" ry="0" width="382" height="16" /> 
          <rect x="170" y="410" rx="0" ry="0" width="276" height="16" /> 
          <rect x="58" y="460" rx="0" ry="0" width="431" height="11" /> 
          <rect x="62" y="480" rx="0" ry="0" width="431" height="11" /> 
          <rect x="62" y="500" rx="0" ry="0" width="431" height="11" /> 
          <rect x="222" y="520" rx="0" ry="0" width="247" height="12" />
         
        </ContentLoader>
                </>
                :
                <>

                
                 <ProductZoom
                productImg={
                  singleProduct?.product_img && singleProduct?.product_img
                }
                gallaryImg={
                  singleProduct?.product_gallary_img &&
                  singleProduct?.product_gallary_img
                }
              />
              {/* next prev btn */}
              <div className='my-2 mx-auto d-flex justify-content-evenly'>
                <Link
                  to={`/product/${slug}`}>
                  
                  <MDBBtn
                    color='info'
                    // disabled={isSubmitting}
                    size='sm'
                    className='text-white'>
                      Previous Product
                  </MDBBtn>
                </Link>
                <Link to={`/product/${slug}`}>
                                  
                  <MDBBtn
                    color='info'
                    // disabled={isSubmitting}
                    size='sm'
                    className='text-white'>
                      Next Product
                 </MDBBtn>
                </Link>
              </div>
              <div className='d-none d-xxl-block d-lg-block d-md-block'>
                  {/* data pass props */}
                <div>
                  <ProductDescription
                    description={singleProduct?.product_description}
                  />
                </div>
                <div>
                  <ShareBtn data={
                  singleProduct
                } />
                </div>
                <div>
                  <RattingFillter averageRating={averageRating}  starRating={starRating} proId={singleProduct?.id} orderCheck={orderCheck} />
                </div>
              </div>
                </>
              }
             
            </div>
            <div className='col-lg-6 col-md-6 col-12 mx-auto'>

              {
                loading ?

                <>
                 <ContentLoader 
                speed={2}
                width='100%'
                height='100%'
                viewBox="0 0 503 600"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="134" y="18" rx="3" ry="3" width="353" height="8" /> 
                <rect x="232" y="38" rx="3" ry="3" width="243" height="7" /> 
                <rect x="63" y="14" rx="2" ry="2" width="52" height="20" /> 
                <rect x="71" y="123" rx="19" ry="19" width="35" height="34" /> 
                <rect x="122" y="138" rx="3" ry="3" width="353" height="8" /> 
                <rect x="84" y="266" rx="4" ry="4" width="71" height="32" /> 
                <rect x="174" y="264" rx="4" ry="4" width="71" height="32" /> 
                <rect x="271" y="263" rx="4" ry="4" width="71" height="32" /> 
                <rect x="366" y="262" rx="4" ry="4" width="71" height="32" /> 
                <rect x="67" y="388" rx="4" ry="4" width="113" height="42" /> 
                <rect x="68" y="457" rx="15" ry="15" width="29" height="28" /> 
                <rect x="123" y="500" rx="3" ry="3" width="70" height="9" /> 
                <rect x="225" y="464" rx="3" ry="3" width="243" height="10" /> 
                <rect x="70" y="496" rx="15" ry="15" width="29" height="28" /> 
                <rect x="119" y="464" rx="3" ry="3" width="70" height="9" /> 
                <rect x="222" y="499" rx="3" ry="3" width="243" height="10" /> 
                <rect x="62" y="546" rx="3" ry="3" width="248" height="12" /> 
                <rect x="139" y="54" rx="3" ry="3" width="353" height="8" /> 
                <rect x="64" y="47" rx="2" ry="2" width="52" height="20" /> 
                <rect x="210" y="72" rx="3" ry="3" width="243" height="7" /> 
                <rect x="63" y="83" rx="2" ry="2" width="52" height="20" /> 
                <rect x="139" y="90" rx="3" ry="3" width="353" height="8" /> 
                <rect x="240" y="109" rx="3" ry="3" width="243" height="7" /> 
                <rect x="74" y="164" rx="19" ry="19" width="35" height="34" /> 
                <rect x="72" y="207" rx="19" ry="19" width="35" height="34" /> 
                <rect x="122" y="178" rx="3" ry="3" width="353" height="8" /> 
                <rect x="126" y="220" rx="3" ry="3" width="353" height="8" /> 
                <rect x="188" y="158" rx="3" ry="3" width="243" height="8" /> 
                <rect x="229" y="200" rx="3" ry="3" width="243" height="7" /> 
                <rect x="168" y="236" rx="3" ry="3" width="243" height="7" /> 
                <rect x="74" y="318" rx="3" ry="3" width="243" height="7" /> 
                <rect x="224" y="337" rx="3" ry="3" width="243" height="7" /> 
                <rect x="117" y="357" rx="3" ry="3" width="243" height="7" /> 
                <rect x="211" y="388" rx="4" ry="4" width="113" height="42" /> 
                <rect x="353" y="387" rx="4" ry="4" width="113" height="42" />
              </ContentLoader>
                </>
                :
                <>
                 <MDBCard className='card_responsive border py-2'>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    color: selectedColor,
                    size: selectedSize,
                   
                  }}
                  validate={(values) => {

                    const errors = {};
                    if(filterColorSize()[0]?.size?.length > 0 && !checkCart){
                    // select_role validate condition
                    if (!values.color) {
                      errors.color = "Please Select a Color";
                    }
                    // select_role validate condition
                    if (!values.size) {
                      errors.size = "Please Select a Size";
                    }
                    if(filterColorSize()[0]
                    ?.color_qty === 0){
                      errors.color_qty = "Out of Stock";
                    }
                    
                    return errors;
                    }

                   

                  }}
                  onSubmit={(values, { resetForm }) => {
                    let {color, size}=values;
                    // buy btn click true or false
                    if(buyBtnAddtoCart){
                      if(checkCart){
                        history("/checkout")
                      }else{
                        addToBasketCart(
                          singleProduct?.id,
                          selectedColor,
                          selectedSize,
                          singleProduct?.qty !== countqty ? countqty : singleProduct?.qty
                          );
                          
                          history("/checkout");
                      }
                      
                    }else if(wishBtn){
                      addToBasketWish(
                        singleProduct?.id,
                        selectedColor,
                        selectedSize,
                        singleProduct?.qty !== countqty ? countqty : singleProduct?.qty
                        );
                    }else{
                      addToBasketCart(
                        singleProduct?.id,
                        color,
                        size,
                        singleProduct?.qty !== countqty ? countqty : singleProduct?.qty
                      );
                    }
                    setSelectedSize('');
                  }}>
                  {(formik) => (
                   
                      <div className='py-2 ps-2 product-details'>
                         <form  id='resetForm'>
                        <>
                          {/* ----pro_name----- */}
                          <div className='pro_name'>
                            <h2>
                              {singleProduct?.product_name}
                            </h2>
                          </div>
                            
                            <div className='d-flex'>
                            {/* ----pro-code----- */}
                            <div className='pro_code ms-2'>
                             <span className='fw-normal text-black-50'>
                                SKU:
                                <span className='fw-normal text-dark'>
                               &nbsp;{singleProduct?.product_code}
                              </span>
                              </span>
                              
                          </div>

                          

                          {/* ----brand----- */}
                          {singleProduct?.brand?.brand_name && (
                            <div className='brand ms-2'>
                              <span className='text-black-50'>
                              Brand:
                              <span className='fw-normal text-dark'>
                               &nbsp; {singleProduct?.brand?.brand_name}
                              </span>
                              </span>
                              
                            </div>
                          )}
                            </div>
                          

                          {/* ----color----- */}
                          {singleProduct?.product_colors?.length > 0 && (
                            <div className='color my-1'>
                                <span className='fw-normal text-black-50'>
                                  Color:
                                  <span>
                                  {singleProduct?.product_colors.map(
                                  (val, index) => {
                                    return (
                                      <>
                                        <MDBTooltip
                                          tag='a'
                                          wrapperProps={{ href: "#" }}
                                          title={val?.color?.color_name}>
                                          <input
                                            type='radio'
                                            class='ms-3'
                                            style={{'accent-color': val?.color?.color_name, 'box-shadow': `0 0 0 2px ${val?.color?.color_name}`}}
                                            id={`color_${index}`}
                                            name='color'
                                            autocomplete='off'
                                            value={val.id}
                                            defaultChecked={index  === 0}
                                            onChange={(e) => setSelectedColor(e.target.value)}
                                          />
                                          
                                        </MDBTooltip>
                                      </>
                                    );
                                  }
                                )}
                                  </span>
                                </span>

                                {
                                  filterColorSize()[0]
                                  ?.color_qty <= 0 &&
                                <MDBBadge className="ms-5" color='warning' pill>
                                Out of Stock
                              </MDBBadge>
                                }
                            </div>
                          )}
                          {/* color errors */}
                          {formik.touched.color && (
                            <span className='text-danger'>
                              {formik.errors.color}
                            </span>
                          )}

                          {/* ------size---- */}
                          {filterColorSize()[0]?.size?.length > 0 && (
                            <div className='size my-1'>
                                <span className='fw-normal text-black-50'>Size: </span>
                                {filterColorSize()[0]
                                  ?.size?.split(",")
                                  .map((val) => {
                                    return (
                                      <>
                                        <input
                                     
                                          type='radio'
                                          class='btn-check'
                                          id={`size_${val}`}
                                          name='size'
                                          autocomplete='off'
                                          value={val}
                                          checked={formik.values.size === val}
                                          onChange={(e) => setSelectedSize(e.target.value)}
                                        />
                                        <label
                                              style={{cursor:'pointer'}}
                                          class='p-1  m-2 square border rounded fw-bold text-dark'
                                          for={`size_${val}`}>
                                          {val}
                                        </label>
                                      </>
                                    );
                                  })}
                            </div>
                          )}

                          {/* size errors */}
                          {formik.touched.size && (
                            <span className='text-danger'>
                              {formik.errors.size}
                            </span>
                          )}

                          {/* ------qty---- */}
                            <div className='quantity d-flex my-2'>
                              <span className='fw-normal text-black-50'>
                                Quantity:
                              </span>
                              <i
                                class={`fas fa-minus ms-4 p-1 border ${
                                  countqty > 1 ? "" : "not_allowed"
                                }`}
                                onClick={DecrementCountqty}></i>{" "}
                              <span className='ms-4'> {countqty}</span>{" "}
                              <i
                                class={`fas fa-plus ms-4 p-1 border ${
                                  countqty <
                                  (filterColorSize()[0]?.color_qty
                                    ? filterColorSize()[0]?.color_qty
                                    : singleProduct?.qty)
                                    ? ""
                                    : "not_allowed"
                                }`}
                                onClick={IncrementCountqty}></i>
                              <span className='ms-4'>
                                {" "}
                                ৳ {Number(singleProduct?.selling_price) - Number(singleProduct?.discount_price) * countqty}
                              </span>
                            </div>
                        

                          <div>
                            <span className='fw-normal text-black-50'>Price: </span>৳
                            <span className='fw-normal text-black'>
                              &nbsp;{Number(singleProduct?.selling_price) - Number(singleProduct?.discount_price)}
                            </span>
                          </div>
                        
                          </>
                          </form>
                          <div className='d-sm-block'>
                            {Login ? (
                              <MDBBtn
                                color='info'
                                
                                disabled={isSubmitting}
                                className='mx-2 text-white'
                                onClick={() =>{ setBuyBtnAddtoCart(true); formik.handleSubmit()}}>
                                <BsCart3 style={{ fontSize: "13px"}}  />&nbsp; Buy
                              </MDBBtn>
                            ) : (
                              <MDBBtn
                               color='info'
                                className='mx-2 text-white'  onClick={toggleShow}>
                                <BsCart3 style={{ fontSize: "13px"}}  /> &nbsp; Buy
                              </MDBBtn>
                            )}

                            {/* login check with add to cart  */}

                            {Login ? (
                              checkCart ? (
                                <MDBBtn
                                  onClick={() =>
                                    removeItemCart(
                                      filterCart[0]?.id,
                                      filterCart[0]?.product_id
                                    )
                                  }
                                  color='danger'
                                  disabled={isSubmitting}
                                  className='mx-2 text-white'>
                                  <i class='fas fa-minus'></i>&nbsp;Remove Cart
                                </MDBBtn>
                              ) : (
                                <MDBBtn
                                  type='submit'
                                  color='info'
                                  onClick={formik.handleSubmit}
                                  disabled={isSubmitting}
                                  className='mx-2 text-white'>
                                  <BsCart3 style={{ fontSize: "13px"}}  />&nbsp;Add to
                                  Cart
                                </MDBBtn>
                              )
                            ) : (
                              <MDBBtn
                               color='info'
                                className='mx-2 text-white'  onClick={toggleShow}>
                                <BsCart3 style={{ fontSize: "13px"}}  /> &nbsp; Add to
                                  Cart
                              </MDBBtn>
                            )}

                            {/* wishlist add or remove  condition*/}
                            {  Login ? (
                              checkWish
                              ? (
                               <MDBBtn
                               disabled={wishSubmitting}
                               onClick={() =>
                                 removeItemWish(
                                   filterWish[0]?.id,
                                   filterWish[0]?.product_id
                                 )
                               }
                                 tag='Link'
                                 color='danger'
                                 className='mt-2 mx-2'>
                                 <i class='fas fa-minus'></i> Remove Wishlist
                               </MDBBtn>
                             ) :
                             (
                              <MDBBtn
                               onClick={() =>{ setWishlistBtn(true); formik.handleSubmit()}}
                               disabled={wishSubmitting}
                                color='info'
                                className='mt-2 mx-2'>
                                  <i className='far fa-heart'></i> &nbsp; Wishlist
                              </MDBBtn>
                              )
                            ) : (
                              <MDBBtn
                               color='info'
                                className='mx-2 text-white'  onClick={toggleShow}>
                                  <i className='far fa-heart text-danger'></i> &nbsp; Wishlist
                              </MDBBtn>
                            )}
                          </div>
                        

                        <div className='d-xxl-none d-lg-none d-md-none'>
                          {/* data pass props */}
                          <div>
                            <ProductDescription
                              description={singleProduct?.product_description}
                            />
                          </div>
                          <div>
                            <ShareBtn data={
                  singleProduct
                } />
                          </div>
                          <div>
                            <RattingFillter averageRating={averageRating}  starRating={starRating} proId={singleProduct?.id} orderCheck={orderCheck} />
                          </div>
                        </div>
                      </div>
                    
                  )}
                </Formik>
              </MDBCard>


                <div className='over-reviwes d-flex pt-2'>
                  <div className='ratting'>
                          <div className='d-flex'>
                          <ReactStars
                                    count={5}
                                    size={18}
                                    isHalf={true}
                                    edit={false}
                                    value={averageRating?.average}
                                    emptyIcon={<i className='far fa-star'></i>}
                                    halfIcon={<i className='fa fa-star-half-alt'></i>}
                                    fullIcon={<i className='fa fa-star'></i>}
                                    activeColor='#ffa900'
                                  />
                                  <span className='ms-2'>
                                  {Number(averageRating?.average).toFixed(2)} out of / 5 <i class='fas fa-chevron-down'></i>
                      </span>
                          </div>
                            

                    {/* hover-ratting */}
                    { starRating?.length > 0 && 
                    <div className='hoverable_percentage pt-2 border'>
                              { starRating?.map((val, i)=>{
                                return (
                                  <>
                          <MDBProgress height='23' className='border rounded mb-2' key={i}>
                        <MDBProgressBar  width={val?.percent.toFixed(2)} bgColor='warning' valuemin={0} valuemax={100}>
                        {val?.percent.toFixed(2) } % 
                        </MDBProgressBar>
                      </MDBProgress>
                                  </>
                                )
                              })
                            }
                      
                        <a  className='mt-3 mb-0 nav-item text-info' href='#view_rating'>View All Reviwes</a>
                      
                    </div>
                    }
                  </div>
                  <div className='riviews-product'>
                    <span>{averageRating?.total_review} Reviews</span>
                  </div>
                  <div className='riviews-order'>
                    <span>{orderCheck?.total_order} 0rders</span>
                  </div>
                </div>

              <div className='by-same-product'>
              <div className='d-flex mb-3  border-bottom  align-items-center'>
            <h5 className='text-dark mb-0 fw-bold'><span className='border-bottom border-info border-2 pb-2 d-block'>By Same Product</span></h5>
            </div>
                <div className='row'>
                  {sameProduct.map((val) => {
                    return (
                      <div
                        className='col-md-6 col-lg-6 col-10 mx-auto mt-3'
                        key={val.id}>
                        <ProductsCard {...val} />
                      </div>
                    );
                  })}
                </div>
              </div>
                </>
              }
            </div>
          </div>
    


          <div className='similar_product bg-white mt-3 p-2'>
            <div className='d-flex mb-3 border-bottom align-items-center'>
            <h5 className='text-dark mb-0 fw-bold'><span className='border-bottom border-info border-2 pb-2 d-block'>Similar Product</span></h5>
            <Link to='' className='me-0 ms-auto btn btn-info btn-sm'>
                See More 
            </Link>
            </div>
            <div className='row'>
            {loading ?
              Array(12)
                .fill()
                .map((val, idx) => {
                  return (
                <div className='col-md-3  col-10 mx-auto mt-3'  key={idx}>
                  <LogoLoader />
            </div>
            )})
            :
            similarProduct.map((val) => {
                return (
                  <div
                    className='col-md-3  col-10 mx-auto mt-3'
                    key={val.id}>
                    <ProductsCard {...val} />
                  </div>
                );
              })}
            </div>
          </div>


          <div className='Like_product bg-white mt-5 mb-2 p-2'>
            <div className='d-flex mb-3 border-bottom  align-items-center'>
            <h5 className='text-dark mb-0 fw-bold'><span className='border-bottom border-info border-2 pb-2 d-block'>You May Like</span></h5>
            <Link to='' className='me-0 ms-auto btn btn-info btn-sm'>
                See More 
            </Link>
            </div>
            
            <div className='row'>
            {loading ?
              Array(12)
                .fill()
                .map((val, idx) => {
                  return (
                <div className='col-md-3 col-10 mx-auto mt-3'  key={idx}>
                  <LogoLoader />
            </div>
            )})
            :
            likeProduct.map((val) => {
                return (
                  <div
                    className='col-md-3  col-10 mx-auto mt-3'
                    key={val.id}>
                    <ProductsCard {...val} />
                  </div>
                );
              })}
            </div>
          </div>
          </div>
      </section>
    </>
  );
};

export default SingleProduct;
