import React, {useState,useEffect} from "react";
import ProductsCard from "../../Products_Card/ProductsCard";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import { Link } from "react-router-dom";
import axios from "axios";
import LogoLoader from '../../Loader/LogoLoader';
const AllProductSection = (props) => {
  //  get product data
  const [product, setProduct] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
      const res = await axios.get("/all-product");
      if(isMounted){
        if (res.data.status === 200) {
          setProduct(res.data.products);
        }
        setloading(false);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }


   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);


  

  return (
    <>
      <section className='all_product'>
        <div className='container pt-2 pb-2'>
          <Title section_title='All-Product' />
          <Titleimg />
          <div className='row bg-white hover-shadow pb-4 g-lg-2 g-md-1'>
       {   loading ?
              Array(6)
                .fill()
                .map((val, idx) => {
                  return (
                <div className='col-md-2 col-10 mx-auto  mt-4'  key={idx}>
                  <LogoLoader/>
            </div>
            )})
            :
            product?.map((val, idx) => {
              return (
                <div
                  className='col-md-2 col-10 mx-auto mt-4'
                  key={idx}>
                  <ProductsCard key={idx} {...val} />
                </div>
              );
            })}
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllProductSection;
