export const CommunityTourismData = [
    {
        img: "/assest/image/services/booking.png",
        title: "Hotel Booking",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Tourist Guide",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Community Service",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Hotel Booking",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Hotel Booking",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Tourist Guide",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Community Service",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        img: "/assest/image/services/booking.png",
        title: "Hotel Booking",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit"
    },
]