import React from "react";
import WorkShopSeminarSection from "../../../Components/frontend/WorkShop_&_SeminarSection/WorkShopSeminarSection";

const WorkShopSeminar = () => {
  return (
    <>
      <WorkShopSeminarSection />
    </>
  );
};

export default WorkShopSeminar;
