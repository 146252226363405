import React from "react";
import VisitorVisitPageSec from "../../../../Components/admin/VisitorSection/VisitorVisitPageSec";

const VisitorVisitPage = () => {
  return (
    <>
      <VisitorVisitPageSec />
    </>
  );
};

export default VisitorVisitPage;
