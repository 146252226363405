import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import {Link} from "react-router-dom";
import moment from 'moment'
const Item = ({data, title}) => {
  
  var totalPrice = 0

 
  return <>
 <div className='Item px-2'>
 <h6 className='text-dark text-start'>ORDER TIMELINE:</h6>
   <div className="d-flex justify-content-around">
    <div className='time pe-4 w-25'>
    <p>{moment(data[0]?.updated_at).format("ll")}
      <br/>
      {moment(data[0]?.updated_at).format("LT")}
    </p>
    </div>
    <div className='pending_text w-75'>
    <h6 className='mb-1'><i class="fas fa-dot-circle text-danger me-1"></i> {title}</h6>
    <p className='mb-3'>{data[0]?.status_message}</p>
    </div>
   </div>
<div className='productS'>
<h4 className='text-dark text-start'>Products</h4>
<MDBTable hover responsive>
      <MDBTableHead>
        <tr>
          <th scope='col'>Product Name</th>
          <th scope='col'>Price</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
      {
        data[0]?.order_items?.map((item)=>{
          const {product, product_colors}=item;
          totalPrice += Number(item.price) * item.qty;
          return (
            <>
            <tr>
          <td>
          <Link to={`/product/${product?.product_slug}`}>
          <div className='d-flex align-items-center'>
              <img
                src={`${process.env.REACT_APP_API_URL}${product?.product_img}`}
                alt=''
                style={{ width: '45px', height: '45px' }}
                className='rounded-circle'
              />
              <div className='ms-3'>
              <p className='fw-bold text-dark mb-0'>{product?.product_name}</p>
                <p className='text-muted mb-0'>
                   color:{product_colors?.color?.color_name}, &nbsp;
                   size: {item?.size}, &nbsp;
                    qty: {item?.qty}, &nbsp;
                    </p>
              </div>
            </div>
            </Link>
          </td>
          <td>
          <p className='fw-normal mb-1 mt-2'>৳ {Number(item.price) * item.qty}</p>
          </td>
        </tr>
            </>
          );
        })
       }

       <h4 className='text-dark text-start my-4'>Order Summary</h4>
      <tr>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>Subtotal</p></td>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>৳ {totalPrice}</p></td>
      </tr>  
      {
        data[0]?.discount_price &&
      <tr>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>Discount</p></td>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>৳ {data[0]?.discount_price}</p></td>
      </tr>  
    }

   {/* cash-on-delivery */}
{
    data[0]?.payment_mode === 'cash-on-delivery' ? 
    <>
      <tr>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>Paid Amount</p></td>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>৳ { data[0]?.cashondelivery_payment !== null ?  data[0]?.cashondelivery_payment : 0 }</p></td>
      </tr>  
      
      <tr>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>Due Amount</p></td>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>৳ {data[0]?.cashondelivery_payment !== null ? data[0]?.discount_price ? (totalPrice - Number(data[0]?.cashondelivery_payment) - data[0]?.discount_price) : totalPrice - Number(data[0]?.cashondelivery_payment) : data[0]?.discount_price ? totalPrice - data[0]?.discount_price : totalPrice}</p></td>
      </tr> 
      </>
       :
       <>
       {/* nagad, bkash  */}
       <tr>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>Paid Amount</p></td>
        <td className='py-0'><p className='fw-bold fs-7 mb-1 mt-2'>৳ {totalPrice - data[0]?.discount_price}</p></td>
      </tr>  
       </>

   
}

       <tr>
        <td className='py-0'><p className='fw-bold fs-6 mb-1 mt-2'>Total</p></td>
        <td className='py-0'><p className='fw-bold fs-6 mb-1 mt-2'>৳ {data[0]?.discount_price ? totalPrice - data[0]?.discount_price : totalPrice}</p></td>
      </tr>

     
       







       
      </MDBTableBody>
    </MDBTable>
</div>
 </div>
  </>;
};

export default Item;
