import React, { useReducer, useContext, useEffect } from "react";
import { reducer } from "./CartReducer";
import NoteContext from "./cartNoteContext";
import {useNavigate} from "react-router-dom";
import  axios from "axios";
import { ToastContainer, toast,Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import authContext from '../../../auth/Context/AuthUser/authContext';
const initialState = {
  itemCart: [],
  totalAmountCart: 0,
  totalItemCart: 0,
  totalDeliveryCharge: 0,
  couponDiscount: [],
  validationErrors: [],
  isLoading: true,
  isCouponRendering:false,
  isRendering:false,
  isSubmitting:false
};
const CartStateDataProvider = (props) => {
  const { Login} =useContext(authContext);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);


  // fetch cart item data 
useEffect(() => {
  let isMounted = true;
  
  const getCartData = async () => {
    try {
      if(Login){
        dispatch({
          type: "FETCH_CART_STARTED",
          payload:{
            loading: true,
          } 
        });
        const res = await axios
          .get('/view-cart');
          if(isMounted){
            if (res.data.status === 200) {
              dispatch({
                type: "FETCH_CART_SUCCESS",
                payload:{
                  data:res.data.cart
                } 
              });
              }
              dispatch({
                type: "FETCH_CART_END",
                payload:{
                  loading: false,
                } 
              });
            }
           
          }else{
            dispatch({
              type: "FETCH_CART_END",
              payload: {
                loading: false,
              },
            });
          }
     
   } catch (e) {
      console.log(e);
   }
    
 }



 getCartData();


  return () => {
    isMounted = false;
  }
}, [state.isRendering, Login]);


  // coupon  data 
  useEffect(() => {
    let isMounted = true;
    
   const getCouponData = async () => {
    try {
      if(Login){
        const res = await axios
          .get('/coupon/get');
          if(isMounted){
            if (res.data.status === 200) {
              dispatch({
                type: "FETCH_COUPON_DISCOUNT",
                payload:{
                  data:res.data.coupon
                } 
              });
              }
            }
          }
     
   } catch (e) {
      console.log(e);
   }
    
  }
   getCouponData();
    return () => {
      isMounted = false;
    }
  }, [state.isCouponRendering, state.totalItemCart,  Login]);



  // add to cart
  const addToBasketCart = async (id, color_id, size, qty) => {
    if(Login){
      let data = {
        product_id: id,
        color_id:color_id,
        size: size,
        qty_count: qty,
        }
        dispatch({
          type: "BUTTON_DISABLED",
          payload:{
            submitting: true,
          } 
        });
        await axios
        .post(`/add-to-cart`, data)
        .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "ADD_TO_BASKET_CART",
            payload: {
              rendering: !state.isRendering
            },
          });

          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });

        }else if(res.data.status === 401){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });
        }else if(res.data.status === 404){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });
        }else if(res.data.status === 409){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });
        }else if(res.data.status === 422){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });
        }


        })
        .catch((err) => {
          navigate("/", { replace: true });
        })
    }
          
  };

  //btn click remove item
  const removeItemCart = async (id, product_id) => {
    if(Login){
      let data = {
        product_id: product_id,
        }
        dispatch({
          type: "BUTTON_DISABLED",
          payload:{
            submitting: true,
          } 
        });
    await axios
      .post(`/remove-cart-item/${id}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch({
          type: "REMOVE_ITEM_CART",
          payload: id,
          });
          dispatch({
            type: "BUTTON_DISABLED",
            payload:{
              submitting: false,
            } 
          });
        }
      })
      .catch((err) => {});
    }
    
  };
  
  // decrement the item
  const decrementCart = async (cart_id) => {
    if(Login){ 
      await axios
        .put(`/cart-decrement-quantity/${cart_id}`)
        .then((res) => {
          if (res.data.status === 200) {
             dispatch({
              type: "DECREMENT_CART",
              payload: cart_id,
            });
            toast.success(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            
          }else if (res.data.status === 404){
            toast.warn(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {});
      }
    
  };


  // increment the item
  const incrementCart = async (cart_id) => {
    if(Login){ 
    await axios
      .put(`/cart-increment-quantity/${cart_id}`)
      .then((res) => {
        if (res.data.status === 200) {
           dispatch({
            type: "INCREMENT_CART",
            payload: cart_id,
          });
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
        }else if (res.data.status === 404){
          toast.warn(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {});
    }
    
  };



 


// clear the cart
const clearCart = async () => {
  if(Login){ 
    await axios
      .delete('/clear-cart')
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({ type: "CLEAR_CART" });
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          
        }
      })
      .catch((err) => {});
    }
 
};

//checkout by cart  clear
const reRenderCartClear =  () => {
  dispatch({ type: "CLEAR_CART" });
};

  // we will use the useEffect to update the data
  useEffect(() => {
    dispatch({ type: "GET_TOTAL_CART" });

    dispatch({ type: "GET_TOTAL_DELIVERY_CHARGE" });
  }, [state.itemCart]);




 //apply coupon
 const applyCoupon = async (coupon_code) => {
  if(Login){
    let data = {
      coupon_code: coupon_code,
      }
      dispatch({
        type: "BUTTON_DISABLED",
        payload:{
          submitting: true,
        } 
      });
  await axios
    .post('/coupon/apply', data)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: "COUPON_APPLY",
          payload: {
            rendering: !state.isCouponRendering
          },
        });

        dispatch({
          type: "BUTTON_DISABLED",
          payload:{
            submitting: false,
          } 
        });
      }else if(res.data.status === 422){
        dispatch({
          type: "COUPON_VALIDATION",
          payload:{
            errors:res.data.validation_errors
          } 
        });
      }else if (res.data.status === 404) {
        toast.error(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }else if (res.data.status === 400) {
        toast.error(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      dispatch({
        type: "BUTTON_DISABLED",
        payload:{
          submitting: false,
        } 
      });
    })
    .catch((err) => {});
  }
  
};


 //apply coupon
 const removeCoupon = async () => {
  if(Login){

      dispatch({
        type: "BUTTON_DISABLED",
        payload:{
          submitting: true,
        } 
      });
  await axios
    .post('/coupon/remove')
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch({
          type: "COUPON_APPLY",
          payload: {
            rendering: !state.isCouponRendering
          },
        });
        dispatch({
          type: "BUTTON_DISABLED",
          payload:{
            submitting: false,
          } 
        });
      }
    })
    .catch((err) => {});
  }
  
};






  return (
    <>
     <ToastContainer
    position='top-right'
    autoClose={500}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
    <NoteContext.Provider
      value={{
        ...state,
        removeItemCart,
        clearCart,
        incrementCart,
        decrementCart,
        addToBasketCart,
        reRenderCartClear,
        applyCoupon,
        removeCoupon,
        
      }}>
      {props.children}
    </NoteContext.Provider>
    </>
   
    
  );
};

export default CartStateDataProvider;
