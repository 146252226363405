import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import "./CopyRight.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";


const CopyRight = (props) => {
  const [copyright, setCopyright] = useState([]);

// get data
useEffect(() => {
  let isMounted = true;

  const fetchData = async () => {
    try {
      props.onTopProgress(30);
    const res = await axios.get("/copy-right");
    if(isMounted){
      if (res.data.status === 200) {
        setCopyright(res.data.copyright);
        props.onTopProgress(70);
      }
      props.onTopProgress(100);
    }  
   } catch (e) {
      console.log(e);
   }
    
 }


 fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);



  return (
    <>
   {/* seo meta tag  start */}
    <Helmet>
        ‍<title>Copy Right</title>
         <meta name='keywords' content='OurRajshahi' />
        <meta name='description' content={copyright} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />


         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi - Collection of all District Branding Online Shopping'  />
        <meta
          itemprop="description"
          content={copyright} 
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={copyright} 
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
       
        <meta
          name='og:description'
          content={copyright} 
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi' 
        />
        <meta
          name='twitter:description'
          content={copyright} 
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
      </Helmet> 
      {/* seo meta tag  end */}



      <div className='copy_right_section py-4 bg-light overflow-hidden'>
        <div className='container'>
          <h4 className='title text-center py-2'>Copy Right</h4>
          <div className='row'>
            <div className='col-12'>
              <MDBCard className='shadow-1'>
                <MDBCardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          copyright == null
                            ? ""
                            : copyright && copyright,
                      }}
                    />
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
