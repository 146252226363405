import React, {useState, useEffect} from 'react'
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import axios from "axios";
import { toast,Zoom } from "react-toastify";


const SubscriptionSec = () => {
 //  get newsletter option  data
 const [newsletter, setNewsletter] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;
   axios.get("/newsletter/all").then((res) => {
     if (isMounted) {
     if (res.data.status === 200) {
       setNewsletter(res.data.newsletter);
     }
     setloading(false);
    }
   });
   return () => {
    isMounted = false
  }
 }, []);

 useEffect(() => {
   // initialize datatable
   if (!loading) {
     const script = document.createElement("script");
     script.src = "/dist/js/custom.js";
     script.async = true;
     document.body.appendChild(script);
   }
 }, [loading]);


 const handleDeleteItems = (id) => {
   axios
     .delete(`/newsletter/delete/${id}`)
     .then((res) => {
       if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

         setNewsletter(
           newsletter.filter((item) => item.id !== id)
         );
       }
     })
     .catch((e) => {});
 };

 if (loading) {
   return <h2>loading....</h2>;
 }


 return (
   <>
           {/* seo title */}
     <Helmet>
        <title> All || Newsletter</title>
        </Helmet>


     {/* -------PageHeaderBreadCrumb------- */}
     <PageHeaderBreadCrumb
       Title="newsletter"
       pageTitle="All / newsletter"
     />
     {/* Main content */}
     <section className="content">
       {/* Default box */}
       <div className="card">
       
         <div className="card-body p-0">
         
           <table id="example1"   className="table table-striped projects">
             <thead>
               <tr>
               <th className="text-center">
                  Email
                 </th>
                 
                 <th className="text-center">
                   Delete
                 </th>

               </tr>
             </thead>
             <tbody>
               {
                 newsletter && newsletter?.map((val) => {
                   return (
                     <tr key={val.id}>
                         <td className="text-center">
                         {val.email}
                       </td>
                     
                       <td className="text-center">
                       <a
                           className='btn btn-danger btn-sm'
                           tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                           data-toggle='modal'
                           data-target={`#userDelete_${val.id}`}
                           href='##'>
                           <i className='fas fa-trash pr-2'></i>
                           Delete
                           <div
                             class='modal fade bd-example-modal-sm'
                            
                             id={`userDelete_${val.id}`}
                             >
                             <div class='modal-dialog modal-sm modal-dialog-centered'>
                               <div class='modal-content'>
                                 <div class='modal-body text-center'>
                                   <i class='fas fa-times text-danger fa-3x'></i>
                                   <p className='mb-2 text-dark'>
                                     <span className='font-weight-bold h4'>
                                       Are you sure?
                                     </span>
                                     <br />
                                     Once deleted, you will not be able to
                                     recover this data!
                                   </p>
                                 </div>
                                 <div class='modal-footer border'>
                                   <button
                                     onClick={() => {
                                       handleDeleteItems(val.id);
                                     }}
                                     type='button'
                                     class='btn btn-danger'
                                     data-dismiss='modal'>
                                     Yes
                                   </button>
                                   <button
                                     type='button'
                                     class='btn btn-outline-danger'
                                     data-dismiss='modal'>
                                     No
                                   </button>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </a>
                       </td>
                     </tr>

                   )
                 })
               }


             </tbody>
           </table>
         </div>
         {/* /.card-body */}
       </div>
       {/* /.card */}
     </section>
     {/* /.content */}

   </>
  );
};

export default SubscriptionSec;
