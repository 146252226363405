import React, { useState, useEffect } from "react";
import axios from "axios";
import {  toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from '../../../frontend/Loader/Loader';

const RightSiteOthers = () => {
  const [insert_blog_right_site_others, setinsert_blog_right_site_others] =
    useState({
      update_breaking: "",
      add_banner_1: "",
      add_banner_2: "",
    });

  const [blog_right_site_others, setblog_right_site_others] = useState([]);
  // image preview
  const [previewImage, setPreviewImage] = useState({
    add_banner_1: "",
    add_banner_2: "",
  });
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);
  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

  // get data
  useEffect(() => {
    let isMounted = true;
   
      axios.get("/blog/right-site-others/all").then((res) => {
        if (isMounted) {
        if (res.data.status === 200) {
          setblog_right_site_others(res.data.blog_right_site_others);
        }
        setloading(false);
      }
      }).catch(error => {});
    
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  const handleUpdate_breaking = (e) => {
    if (blog_right_site_others == null) {
      setinsert_blog_right_site_others({
        ...insert_blog_right_site_others,
        [e.target.name]: e.target.value,
      });
    } else {
      setblog_right_site_others({
        ...blog_right_site_others,
        [e.target.name]: e.target.value,
      });
    }
  };

  // handleChangeImage header logo
  const handleChangeImage = (e) => {
    setPreviewImage({
      ...previewImage,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    if (blog_right_site_others == null) {
      setinsert_blog_right_site_others({
        ...insert_blog_right_site_others,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setblog_right_site_others({
        ...blog_right_site_others,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    if (blog_right_site_others == null) {
      const formData = new FormData();
      formData.append(
        "update_breaking",
        insert_blog_right_site_others.update_breaking
      );
      formData.append(
        "add_banner_1",
        insert_blog_right_site_others.add_banner_1
      );
      formData.append(
        "add_banner_2",
        insert_blog_right_site_others.add_banner_2
      );

      axios
        .post(`/blog/right-site-others/insert`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setRerendering(!rerendering);
            setValidation_error({});
          } else {
            setDisableSubmitBtn(false);
            setValidation_error(res.data.validation_errors);
          }
        })
        .catch((err) => {});
    } else {
      const formData = new FormData();
      formData.append(
        "update_breaking",
        blog_right_site_others.update_breaking
      );
      formData.append("add_banner_1", blog_right_site_others.add_banner_1);
      formData.append("add_banner_2", blog_right_site_others.add_banner_2);

      axios
        .post(
          `/blog/right-site-others/update/${blog_right_site_others.id}`,
          formData
        )
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setRerendering(!rerendering);
            setValidation_error({});
          } else if (res.data.status === 422) {
            setDisableSubmitBtn(false);
            setValidation_error(res.data.validation_errors);
          } else if (res.data.status === 404) {
            setDisableSubmitBtn(false);
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((err) => {});
    }
  };

  if (loading) {
    return  <Loader/>;
  }
  return (
    <>
        {/* seo title */}
        <Helmet>
        <title> Blog-Right-Side</title>
        </Helmet>

      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Blog-Right-Site-Others' pageTitle='Blog' />
      {/* Main content */}
      <section className='content footer_setting'>
        <div className='container'>
          <form onSubmit={handleSubmit} encType='multipart/form-data'>
            <div className='card card-primary card-outline'>
              <div className='card-body box-profile border'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div class='form-group'>
                      <label className='p-2'>Update Breaking News</label>
                      <textarea
                        name='update_breaking'
                        onChange={handleUpdate_breaking}
                        value={
                          blog_right_site_others == null
                            ? insert_blog_right_site_others.update_breaking
                            : blog_right_site_others.update_breaking == null
                            ? ""
                            : blog_right_site_others.update_breaking
                        }
                        class='form-control'
                        rows='3'
                        placeholder='Enter ...'></textarea>
                    </div>
                    <span className='text-danger'>
                      {validation_error.update_breaking}
                    </span>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='customFile'>Add Banner 1</label>

                      <input
                        type='file'
                        className='form-control'
                        accept='image/*'
                        name='add_banner_1'
                        onChange={handleChangeImage}
                        id='customFile'
                      />
                    </div>
                    <span className='text-danger'>
                      {validation_error.add_banner_1}
                    </span>
                    <div className='my-2'>
                      {previewImage.add_banner_1 && (
                        <img
                          alt='img'
                          width='60'
                          src={
                            (window.URL.srcObject = previewImage.add_banner_1)
                          }
                        />
                      )}
                      {!previewImage.add_banner_1 && (
                        <img
                          alt='img'
                          width='60'
                          src={
                            blog_right_site_others == null
                              ? ""
                              : blog_right_site_others?.add_banner_1 == null
                              ? ""
                              : `${process.env.REACT_APP_API_URL}${blog_right_site_others?.add_banner_1}`
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label htmlFor='customFile'>Add Banner 1</label>

                      <input
                        type='file'
                        className='form-control'
                        accept='image/*'
                        name='add_banner_2'
                        onChange={handleChangeImage}
                        id='customFile'
                      />
                    </div>
                    <span className='text-danger'>
                      {validation_error.add_banner_2}
                    </span>
                    <div className='my-2'>
                      {previewImage.add_banner_2 && (
                        <img
                          alt='img'
                          width='60'
                          src={
                            (window.URL.srcObject = previewImage.add_banner_2)
                          }
                        />
                      )}
                      {!previewImage.add_banner_2 && (
                        <img
                          alt='img'
                          width='60'
                          src={
                            blog_right_site_others == null
                              ? ""
                              : blog_right_site_others?.add_banner_2 == null
                              ? ""
                              : `${process.env.REACT_APP_API_URL}${blog_right_site_others?.add_banner_2}`
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-12'>
                <button
                  type='submit'
                  disabled={disableSubmitBtn}
                  class='btn btn-info btn-block w-75 mx-auto'>
                  <i class='fas fa-paper-plane'></i> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default RightSiteOthers;
