import React, {useContext, useState, useEffect } from "react";
import { MDBBtn,MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import {toast,Zoom} from 'react-toastify';
import axios from "axios";
import authContext from '../../../auth/Context/AuthUser/authContext';
import Loader from "../../Loader/Loader";
import {  useSearchParams, useNavigate } from "react-router-dom";
const EmailVerifyOtp = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const otp_pin = searchParams.get("otp_code");
  const user_email = localStorage.getItem("email_verify");
  const history =useNavigate();

  const { userLogin } = useContext(authContext);
  const [validation_error, setValidation_error] = useState([]);
  const [verify, setVerify] = useState(true);
  const [loading, setloading] = useState(true);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
 

  // get user
  useEffect(() => {
    let isMounted = true;
 const verifyEmail = async ()=> {
  if(user_email !== null  && otp_pin !== null ){
    setDisableSubmitBtn(true);
    const data = {
      'email':user_email,
      'otp_pin': otp_pin,
     };
      await axios
        .post("/email/verify", data)
        .then((res) => {
          if(isMounted){
            if (res.data.status === 200) {
              document.getElementById("resetForm").reset();
              toast.success(res.data.message, {
                position: "top-center",
                theme: 'colored',
                autoClose: 500,
                transition: Zoom,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setDisableSubmitBtn(false);
              userLogin();
              localStorage.setItem("user_token", res.data.access_token);
              history("/user/profile");
              localStorage.removeItem("email_verify");
            } else {
              toast.error('OTP verification failed', {
                position: "top-center",
                theme: 'colored',
                transition: Zoom,
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setDisableSubmitBtn(false);
              setVerify(false);
              localStorage.removeItem("email_verify");
            }
          }
         
        })
        .catch((err) => {
          
        });
  
  }
  setloading(false);
}

verifyEmail();

  return () => {
      isMounted = false
    }
   
  }, [searchParams, otp_pin]);





  const handleResendOTP = ()=>{
    const data = {
        'email':user_email ? user_email : '',
    };
            axios
              .post("/resend/email/token", data)
              .then((res) => {
                if (res.data.status === 200) {
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 400,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else if (res.data.status === 400) {
                    toast.error(res.data.message, {
                        position: "top-center",
                        theme: 'colored',
                        transition: Zoom,
                        autoClose: 400,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
              })
              .catch((err) => {});

  }



 if(user_email == null){
    return (
    <>
     <div className='email_verify container py-4'>
        <div  className='card p-3 mx-auto' style={{ maxWidth: '540px' }}>
        <h4 className='text-center py-5 fw-bold border-bottom w-75 mx-auto'>Email verification</h4>
        {
        loading ?
        <Loader />
        :
        <p className='text-center fw-bold text-info'>Invalid Request</p>
        }
        </div>
      </div>
      </>
    )
  }else if(!verify){
    return (
    <>
    <div className='email_verify container py-4'>
       <div  className='card p-3 mx-auto' style={{ maxWidth: '540px' }}>
       <h4 className='text-center py-5 fw-bold border-bottom w-75 mx-auto'>Email verification</h4>
       {
        loading ?
        <Loader />
        : 
        <p className='text-center fw-bold text-danger'>Verification failed</p>
       }
       
       </div>
     </div>
     </>
    )
  }
  return (
    <>
      <div className='email_verify container py-4'>
        <div  className='card p-3 mx-auto' style={{ maxWidth: '540px' }}>
        <h4 className='text-center py-3 fw-bold border-bottom w-75 mx-auto'>Email verification</h4>
        {
          !otp_pin &&
          <p className='text-center fw-bold text-info'>Verification code has been sent to your email.</p>
        }
       
        <Formik
        enableReinitialize={true}
        
        initialValues={{
          email: user_email ? user_email : '',
          otp_pin: otp_pin ? otp_pin : '',
        }}
        validate={(values) => {
          const errors = {};

          

          // Email validate condition
          if (!values.email) {
            errors.email = "Please Input Your Email Address";
          } else if (values.email.length > 100) {
            errors.email = "Email maximum length of 50 characters";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid Email Address";
          } 

          // otp pin validate condition
          if (!values.otp_pin) {
            errors.otp_pin = "Please Input Your OTP Pin";
          } else if (!/^[0-9\b]+$/i.test(values.otp_pin)) {
            errors.otp_pin = "Invalid OTP Pin";
          }  else if (values.otp_pin.length > 6) {
            errors.otp_pin = "OTP Pin maximum length of 6 characters";
          }

         

         

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          setDisableSubmitBtn(true);
          const data = values;
            axios
              .post("/email/verify", data)
              .then((res) => {
                if (res.data.status === 200) {
                  document.getElementById("resetForm").reset();
                  resetForm();
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    autoClose: 500,
                    transition: Zoom,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                  userLogin();
                  localStorage.setItem("user_token", res.data.access_token);
                  history("/user/profile");
                  localStorage.removeItem("email_verify");
                }else {
                  toast.error('OTP verification failed', {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                  setVerify(false);
                  localStorage.removeItem("email_verify");
                }
              })
              .catch((err) => {
                setDisableSubmitBtn(false);
              });
        }}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} id='resetForm'>
          {/* email Input */}
          <div >
          <label className='fw-bold'>Email</label>
            <div className='input-group'>
              <input
                type='text'
                name='email'
                id='email'
                disabled
                placeholder='Email'
                className='form-control'
                onChange={formik.handleChange}
                  value={formik.values.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
              />
              <label
                className='input-group-text'
                htmlFor='email'>
               <i className='fas fa-envelope'></i>
              </label>
            </div>
            {/* errors will return when field validation fails  */}
            {/* {validation_error.email ? (
                <span className='text-danger'>{validation_error.email}</span>
              ) : (
                formik.touched.email &&
                formik.errors.email && (
                  <span className='text-danger'>{formik.errors.email}</span>
                )
              )} */}
          </div>
           {/*OTP Input */}
           <div className='py-3'>
          <lable className='fw-bold float-start'>Enter OTP</lable>
          <Link to='##' className='fw-normal float-end text-dark' onClick={handleResendOTP}>Resend Code?</Link>
            <div className='input-group'>
              <input
                type='text'
                name='otp_pin'
                id='otp_pin'
                placeholder='Enter OTP'
                className='form-control'
                onChange={formik.handleChange}
                value={formik.values.otp_pin}
                touched={formik.touched.otp_pin}
                onBlur={formik.handleBlur}
              />
              <label
                className='input-group-text'
                htmlFor='otp_pin'>
             <i class="fas fa-key"></i>
              </label>
            </div>
            {/* errors will return when field validation fails  */}
            {validation_error.otp_pin ? (
                <span className='text-danger'>{validation_error.otp_pin}</span>
              ) : (
                formik.touched.otp_pin &&
                formik.errors.otp_pin && (
                  <span className='text-danger'>{formik.errors.otp_pin}</span>
                )
              )}
          </div>

          <div>
            <MDBBtn
              type='submit'
              rounded
              className='btn btn-danger btn-rounded btn-block my-3 mx-auto w-75 d-block'
              color='danger'
              disabled={disableSubmitBtn}
              >
              Submit
              {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
            </MDBBtn>
          </div>
          </form>
        )}
      </Formik>
      </div>
      </div>
    </>
  );
};

export default EmailVerifyOtp;
