import React from "react";
import AllHeaderSearchOptionSec from './../../../../Components/admin/Settings/Header/All_Header_Search_optionSec';

const AllHeaderSearchOption = () => {
  return <>
   <AllHeaderSearchOptionSec/>
  </>;
};

export default AllHeaderSearchOption;
