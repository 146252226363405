import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import EditorEdit from "../../../Editor/Editor";

const EditServicePlanSec = () => {
  const { id } = useParams();

  const [update, setUpdate] = useState([]);

// image preview
  const [previewImage, setPreviewImage] = useState({
    image:'',
  });
 
    //  get Special service option  data
    const [specialService, SetSpecialService] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  
   //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  
  // get data
  useEffect(() => {
    let isMounted = true;
    


      const getUpdateData = async () => {
        try {
            const res = await axios
              .get(`/service-plan/edit/${id}`);
              if(isMounted){
                if (res.data.status === 200) {
                  setUpdate(res.data.service_plan);
                  }
                } 
         
       } catch (e) {
          console.log(e);
       }

      }
       
      const getSpecialServiceData = async () => {
        try {
            const res = await axios
              .get("/setting/special-service/all");
              if(isMounted){
                if (res.data.status === 200) {
                  SetSpecialService(res.data.special_service);
                  }
                } 
         
       } catch (e) {
          console.log(e);
       }
      }

       getUpdateData();
       getSpecialServiceData();

       setloading(false);


    return () => {
      isMounted = false;
    };
  }, [rerendering]);








  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.files[0],
    });
    setPreviewImage({...previewImage, [e.target.name]: e.target.files[0]});
  };


  const handleDetails = (newtext: any) => {
    setUpdate({
      ...update,
      detail: newtext,
    });
    
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("name", update.name);
    formData.append("title", update.title);
    formData.append("special_service_id", update.special_service_id);
    formData.append("image", update.image);
    formData.append("detail", update.detail);
    formData.append("price", update.price);
    formData.append("price_time", update.price_time);

    axios
      .post(`/service-plan/update/${update.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValidation_error({});
          setRerendering(!rerendering);
          setPreviewImage({
            image:'',
          });
          setDisableSubmitBtn(false);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title>Edit || Special-Plan</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Edit-Special-Service" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Service Plan</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/all-service-plan"
                    className="btn btn-success btn"
                  >
                    All Service plan
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="name">Plan-Name</label>
                    <input
                    // required
                      type="text"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={update.name}
                      className="form-control"
                      placeholder="Enter Plan Name"
                    />
                  </div>
                  {
                    validation_error.name &&
                    <span className='text-danger'>
                          {validation_error.name}
                      
                      </span>
                  }

                  <div class="form-group">
                    <label for="special_service_id">Special-Service </label>
                    <select
                      class="form-control"
                      name="special_service_id"
                      value={update.special_service_id}
                      onChange={handleChange}
                      id="special_service_id"
                    >
                      <option value='' disabled="disabled">--Select Special-Service--</option>
                      {specialService?.map((val)=>{
                        return (
                        <option value={val.id} {...update?.special_service_id === val.id && "selected"}>{val.slug}</option>
                        );
                      })}
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.special_service_id}
                        </span>

                   <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    // required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={update.title}
                      className="form-control"
                      placeholder="Enter title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }

                 
                  <div className="form-group">
                    <label htmlFor="img">Image</label>

                    <input
                    // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    previewImage.image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(previewImage.image)}
                  />
                   }

                    {
                      !previewImage.image && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update.image == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${update?.image}`
                    }
                  />
                  }
                  
                  <div className="form-group">
                    <label htmlFor="inputslug">Price</label>
                    <input
                    // required
                      type="text"
                      id="inputalt"
                      name="price"
                      onChange={handleChange}
                      value={update.price}
                      className="form-control"
                      placeholder="Enter price"
                    />
                  </div>
                  {
                    validation_error.price &&
                    <span className='text-danger'>
                          {validation_error.price}
                      <br />
                      </span>
                  }


                  
                         <div class="form-group">
                    <label for="price_time">Price Time</label>
                    <select
                      class="form-control"
                      name="price_time"
                      value={update.price_time}
                      onChange={handleChange}
                      id="price_time"
                    >
                       <option value='' disabled="disabled" >--Select Price Time--</option>
                        <option value='day' {...update.price_time === 'day' && "selected"}>Day</option>
                        <option value='week' {...update.price_time === 'week' && "selected"}>Weekly</option>
                        <option value='half month' {...update.price_time === 'half month' && "selected"}>Half Month</option>
                        <option value='month' {...update.price_time === 'month' && "selected"}>Month</option>
                        <option value='half year' {...update.price_time === 'half year' && "selected"}>Half Year</option>
                        <option value='year' {...update.price_time === 'year' && "selected"}>Year</option>
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.price_time}
                        </span>

                  <label htmlFor="thumbnail_image">Plan-Details</label>
                     <EditorEdit
                      name="community"
                      handleChange={handleDetails}
                      value={update.detail}              />

                {
                    validation_error.detail &&
                    <span className='text-danger'>
                          {validation_error.detail}
                      <br />
                      </span>
                  }



                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto my-2"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditServicePlanSec;
