import React from 'react'
import { topsearchData } from '../../../../Components/admin/SearchSection/SearchesData'
import SearchesWrapper from '../../../../Components/admin/SearchSection/SearchesWrapper'

const TopSearches = () => {
  return (
    <>
    <SearchesWrapper
     searchesData ={topsearchData}
    />
    </>
  )
}

export default TopSearches