import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import Slider from "react-slick";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function BannerSlider(props) {

  //  get slider data
  const [slider, setSlider] = useState([]);
  const [loading, setloading] = useState(true);


 // get data
 useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await  axios.get("/slider/all");
      if(isMounted){
        if (res.data.status === 200) {
          setSlider(res.data.slider);
         }
         setloading(false);
      }
     
    } catch (e) {
       console.log(e);
    }
  }
  
  fetchData();

  return () => {
    isMounted = false;
  }
}, []);



  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    initialSlide: 0,
    arrows: true,
  };
  return (
    <>
       {/* seo meta tag  start */}
       {
            slider?.map(val=>
              (
                // <>
                 <Helmet>
                <meta name='keywords' content='OurRajshahi' />
                <meta name='description' content={val?.title} />
                <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
                <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        
                 {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={val?.title}  />
                <meta
                  itemprop="description"
                  content={val?.title} 
                />
                <meta
                  itemprop="image"
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  itemProp='image'
                  ontent={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  name='description'
                  itemProp='description'
                  content={val?.title} 
                />
        
        
                 {/* <!-- Facebook Meta Tags --> */}
                <meta name='og:title' content='OurRajshahi' />
                
                <meta
                  name='og:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
               
                <meta
                  name='og:description'
                  content={val?.title} 
                />
        
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content='summary_large_image' />
                <meta
                  name='twitter:title'
                  content='OurRajshahi' 
                />
                <meta
                  name='twitter:description'
                  content={val?.title} 
                />
                <meta
                  name='twitter:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                </Helmet> 
                // </>
              )
            )}
      {/* seo meta tag  end */}




    
      {
        <Slider {...settings}>
        { loading ?
        
            <ContentLoader 
            speed={2}
            width='100%'
            height='100%'
            viewBox="0 0 606 190"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="0" y="3" rx="3" ry="3" width="100%" height="180" />
          </ContentLoader>
            
        :
         slider?.map((val) => {
          return (
            <div key={val.id} data-index={val.id}>
              <Link to={val.url} >
                <LazyLoadImage
                 effect="blur"
                className='rounded-bottom border border-top-0'
                width="100%"
                height="60%"
                src={
                  val?.image &&
                  `${process.env.REACT_APP_API_URL}${val?.image}`
                }
                alt={val?.img_alt}
              />
              
              </Link>
            </div>
          );
        })}
      </Slider>
      }
      
    </>
  );
}
