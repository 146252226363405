import React from 'react'

const ProductDescription = (props) => {

    return (
        <>
            <div className="products-description">
                      <h5 className='fw-bold py-2 border-bottom'>Description</h5>
                      
                      <div className='product-feauters'>
                      <div
                    dangerouslySetInnerHTML={{
                      __html:
                      props.description == null
                            ? ""
                            : 
                            props.description &&
                            props.description,
                    }}
                  />         
                      </div>
                    </div>  
        </>
    )
}

export default ProductDescription
