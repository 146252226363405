import Profile from "../../../Pages/admin/Profile/Profile";
import AllCategories from "../../../Pages/admin/Categories/AllCategories/AllCategories";
import AddCategories from "../../../Pages/admin/Categories/AddCategories/AddCategories";
import EditeCategories from "../../../Pages/admin/Categories/EditeCategories/EditeCategories";
import ViewSubCategoryWithcats from "../../../Pages/admin/Categories/ViewSubCategorywithcates/ViewSubCategory_with_cats";

import AllSubCategories from "../../../Pages/admin/SubCategories/AllSubCategories/AllSubCategories";
import AddSubCategories from "../../../Pages/admin/SubCategories/AddSubCategories/AddSubCategories";
import ViewSOScategory from "../../../Pages/admin/SubCategories/ViewSOScategory/ViewSOScategory";
import EditeSubcategories from "../../../Pages/admin/SubCategories/EditeSubcategories/EditeSubcategories";

import AllSOSCategories from "../../../Pages/admin/SOSCategories/AllSOSCategories/AllSOSCategories";
import AddSOSCategories from "../../../Pages/admin/SOSCategories/AddSOSCategories/AddSOSCategories";
import EditeSOSCategories from "../../../Pages/admin/SOSCategories/EditeSOSCategories/EditeSOSCategories";

// setting
import HeaderTopSetting from "../../../Pages/admin/Settings/HeaderTop/HeaderTopSetting";
import HeaderSetting from "../../../Pages/admin/Settings/Header/HeaderSetting";

import AddSliderSetting from "../../../Pages/admin/Settings/Slider/AddSliderSetting";
import AllSliderSetting from "../../../Pages/admin/Settings/Slider/AllSliderSetting";
import EditSliderSetting from "../../../Pages/admin/Settings/Slider/EditSliderSetting";

import AddMiniSliderSetting from "../../../Pages/admin/Settings/MiniSlider/AddMiniSliderSetting";
import AllMiniSliderSetting from "../../../Pages/admin/Settings/MiniSlider/AllMiniSliderSetting";
import EditMiniSliderSetting from "../../../Pages/admin/Settings/MiniSlider/EditMiniSliderSetting";

import AddMiniBannerSetting from "../../../Pages/admin/Settings/MiniBanner/AddMiniBannerSetting";
import AllMiniBannerSetting from "../../../Pages/admin/Settings/MiniBanner/AllMiniBannerSetting";
import EditMiniBannerSetting from "../../../Pages/admin/Settings/MiniBanner/EditMiniBannerSetting";

import AllSpecialServiceSetting from "../../../Pages/admin/Settings/SpecialService/AllSpecialServiceSetting";
import AddSpecialServiceSetting from "../../../Pages/admin/Settings/SpecialService/AddSpecialServiceSetting";
import EditSpecialServiceSetting from "../../../Pages/admin/Settings/SpecialService/EditSpecialServiceSetting";


import AllServicePlanSec from "./../Settings/SpecialService/ServicePlan/AllServicePlanSec";
import AddServicePlanSec from "./../Settings/SpecialService/ServicePlan/AddServicePlanSec";
import EditServicePlanSec from "./../Settings/SpecialService/ServicePlan/EditServicePlanSec";
import DeactiveServicePlan from "./../Settings/SpecialService/ServicePlan/DeactiveServicePlanSec";

import AddFeaturedCategoriesSetting from './../Settings/FeaturedCategories/AddFeaturedCategoriesSec';
import AllFeaturedCategoriesSetting from './../Settings/FeaturedCategories/AllFeaturedCategoriesSec';
import EditFeaturedCategorisSetting from './../Settings/FeaturedCategories/EditFeaturedCategoriesSec';

import AddTraditionalSetting from './../Settings/Traditional/AddTraditionalSec';
import AllTraditionalSetting from './../Settings/Traditional/AllTraditionalSec';
import EditTraditionalSetting from './../Settings/Traditional/EditTraditionalSec';

import AddGiftItemSetting from "../../../Components/admin/Settings/Gift_Item/AddGiftItemSec";
import AllGiftItemSetting from "../../../Components/admin/Settings/Gift_Item/AllGiftItemSec";
import EditGiftItemSetting from "../../../Components/admin/Settings/Gift_Item/EditGiftItemSec";

import AddBrandSetting from "../../../Components/admin/Settings/Brand/AddBrand";
import AllBrandSetting from "../../../Components/admin/Settings/Brand/AllBrand";
import EditBrandSetting from "../../../Components/admin/Settings/Brand/EditBrand";
import FooterSetting from "../../../Pages/admin/Settings/Footer/FooterSetting";





import AddProducts from "../../../Pages/admin/Product/AddProducts/AddProducts";
import AllProducts from "../../../Pages/admin/Product/AllProducts/AllProducts";
import ViewProduct from "../../../Pages/admin/Product/ViewProduct/ViewProduct";
import EditeProduct from "../../../Pages/admin/Product/EditProducts/EditeProduct";
import DraftProducts from "../../../Pages/admin/Product/DraftProducts/DraftProducts";
import AllReviewProduct from "../ProductSection/Reviews/AllReviewProduct";
import ReviewDraftProduct from "../ProductSection/Reviews/ReviewDraftProduct";
import ReplyWithReview from "../ProductSection/Reviews/ReplyWithReview";

import AddColor from '../../../Components/admin/Color/AddColor';
import AllColor from '../../../Components/admin/Color/AllColor';
import EditColor from '../../../Components/admin/Color/EditColor';

import AddBrand from '../../../Components/admin/Brand/AddBrand';
import AllBrand from '../../../Components/admin/Brand/AllBrand';
import EditBrand from '../../../Components/admin/Brand/EditBrand';



import AllOrders from "../../../Pages/admin/Orders/AllOrders/AllOrders";
import ViewOrders from "../../../Pages/admin/Orders/ViewOrders/ViewOrders";
import NewOrders from "../../../Pages/admin/Orders/NewOrders/NewOrders";

import NewServiceRequest from "../../../Pages/admin/CustomServices/NewServiceRequest/NewServiceRequest";
import ApprovedService from "../../../Pages/admin/CustomServices/ApprovedService/ApprovedService";
import DeliveredService from "../../../Pages/admin/CustomServices/DeliveredService/DeliveredService";
import CancledService from "../../../Pages/admin/CustomServices/CancledService/CancledService";
import AllServiceSec from '../../../Components/admin/CustomServiceSection/AllServiceSec'
import ViewCustomService from '../../../Components/admin/CustomServiceSection/ViewCustomService'

import Subscription from "../../../Pages/admin/Newsletter/Subscription";
import TopSearches from "../../../Pages/admin/Searches/TopSearches/TopSearches";
import TopSearchesByUser from "../../../Pages/admin/Searches/TopSearchesByUser/TopSearchesByUser";
import LastMonthSearches from "../../../Pages/admin/Searches/Last30daySearches/Last30daySearches";
import LastMonthSearchesByUser from "../../../Pages/admin/Searches/Last30daySearchesByUser/Last30daySearchesByUser";
import LastOnYearSearches from "../../../Pages/admin/Searches/Last1yearSearches/Last1yearSearches";
import LastOneYearSearchesByUser from "../../../Pages/admin/Searches/Last1yearSearchesByUser/Last1yearSearchesByUser";
import TodaysTopSearches from "../../../Pages/admin/Searches/TodaysTopSearches/TodaysTopSearches";
import TodaysTopSearchesByUser from "../../../Pages/admin/Searches/TodaysTopSearchesByUser/TodaysTopSearchesByUser";

import AllSearches from "../../../Pages/admin/Searches/AllSearches/AllSearches";
import AddAdmin from "../../../Pages/admin/Admin/AddAdmin/AddAdmin";
import AllAdmin from "../../../Pages/admin/Admin/AllAdmin/AllAdmin";
import DeactiveAdmin from "../../../Pages/admin/Admin/DeactiveAdmin/DeactiveAdmin";
import GiveRoleAdmin from "../../../Pages/admin/Admin/GiveRoleAdmin/GiveRoleAdmin";
import ViewAdmin from "../../../Pages/admin/Admin/ViewAdmin/ViewAdmin";
import VisitorSummary from "../../../Pages/admin/Visitor/VisitorSummary/VisitorSummary";
import VisitorVisitPage from "../../../Pages/admin/Visitor/VisitorVisitPage/VisitorVisitPage";

import AllUsers from "../../../Pages/admin/Users/AllUsers/AllUsers";
import ViewUsers from "../../../Pages/admin/Users/ViewUsers/ViewUsers";
import DeactiveUser from "../../../Pages/admin/Users/DeactiveUser/DeactiveUser";
import AddGallery from "../../../Pages/admin/Galllery/AddGallery/AddGallery";
import AllGalleryImages from "../../../Pages/admin/Galllery/AllGalleryImages/AllGalleryImages";

import EditGallery from "../../../Pages/admin/Galllery/EditGallery/EditGallery";
import ChangePassword from "../../../Pages/admin/Profile/ChangePassword";
import ActiveCoupon from "../../../Pages/admin/Coupon/ActiveCoupon/ActiveCoupon";
import AddCoupon from "../../../Pages/admin/Coupon/AddCoupon/AddCoupon";
import DeactiveCoupon from "../../../Pages/admin/Coupon/DeactiveCoupon/DeactiveCoupon";
import ExpiredCoupon from "../../../Pages/admin/Coupon/ExpiredCoupon/ExpiredCoupon";
import EditCoupon from "../../../Pages/admin/Coupon/EditCoupon/EditCoupon";



import AllRole from "../../../Pages/admin/Role_&_Permission/AllRole";
import AllPermission from "../../../Pages/admin/Role_&_Permission/AllPermission";
import AddPermission from "../../../Pages/admin/Role_&_Permission/AddPermission";
import EditPermission from "../../../Pages/admin/Role_&_Permission/EditPermission";
import GivePermission from "../../../Pages/admin/Role_&_Permission/GivePermission";
import EditeRole from "../../../Pages/admin/Role_&_Permission/EditeRole";


import AddStore from "../../../Components/admin/Store/AddStore";
import AllStore from "../../../Components/admin/Store/AllStore";
import EditStore from "../../../Components/admin/Store/EditStore";


import AllHeaderSearchOption from "./../../../Pages/admin/Settings/Header/All_Header_Search_Option";
import EditHeaderSearchOption from "./../../../Pages/admin/Settings/Header/Edit_Header_Search_Option";

// blog
import AllCategoryblog from '../../admin/Blog/Category_blog/AllCategory_blog';
import AddCategoryblog from './../Blog/Category_blog/AddCategory_blog';
import EditCategoryblog from './../Blog/Category_blog/Edit_Category_blog';
import AllSubCategoryblog from './../Blog/Sub_Categories_blog/AllSubCategory_blog';
import AddSubCategoryblog from './../Blog/Sub_Categories_blog/AddSubCategory_blog';
import EditSubCategoryblog from './../Blog/Sub_Categories_blog/Edit_SubCategory_blog';
import ViewSubCategorywithCatesblog from './../Blog/Category_blog/ViewSubCategory_with_Cates_blog';
import Addpostblog from './../Blog/post/Add_post_blog';
import AllPostblog from './../Blog/post/AllPost_blog';
import Editpostblog from './../Blog/post/edit_post_blog';
import DraftPostblog from './../Blog/post/Draftpost_blog';
import ViewPostblog from './../Blog/post/ViewPost_blog';
import AllCommentblog from './../Blog/Comments/AllComment_blog';
import ViewCommentReplyWithCommentblog from './../Blog/Comments/ViewCommentReply_with_Commment_blog';
import DraftCommentblog from './../Blog/Comments/draftComment_blog';
import RightSiteOthers from './../Blog/Right_Site_Others/Right_Site_Others';

import AllOrderAmount from './../Accounts/AllOrderAmount';
import TodaySell from './../Accounts/TodaySell';
import WeeklySell from './../Accounts/WeeklySell';
import MonthlySell from './../Accounts/MonthlySell';
import YearlySell from './../Accounts/YearlySell';




export const adminRoutes = [
  
 {
    path: "all/categories",
    component: <AllCategories />,
  },
  {
    path: "add/categories",
    component: <AddCategories />,
  },
  {
    path: "all/categories/view-sub-category/:id",
    component: <ViewSubCategoryWithcats />,
  },
  {
    path: "edit/sub-category/:id",
    component: <EditeSubcategories />,
  },
  {
    path: "all/sub-categories",
    component: <AllSubCategories />,
  },
  {
    path: "add/sub-categories",
    component: <AddSubCategories />,
  },
  {
    path: "all/sos-categories",
    component: <AllSOSCategories />,
  },
  {
    path: "add/sos-categories",
    component: <AddSOSCategories />,
  },
  {
    path: "edit/category/:id",
    component: <EditeCategories />,
  },
  {
    path: "all/sub-category/view-sos-category/:id",
    component: <ViewSOScategory />,
  },
  {
    path: "edit/sos-category/:id",
    component: <EditeSOSCategories />,
  },
  {
    path: "all/store",
    component: <AllStore />,
  },
  {
    path: "add/store",
    component: <AddStore />,
  },
  {
    path: "edit/store/:id",
    component: <EditStore />,
  },
  {
    path: "all/product",
    component: <AllProducts />,
  },
  {
    path: "add/product",
    component: <AddProducts />,
  },
  {
    path: "view/product/:id",
    component: <ViewProduct />,
  },
  {
    path: "edit/product/:id",
    component: <EditeProduct />,
  },
  {
    path: "product/review",
    component: <AllReviewProduct />,
  },
  {
    path: "product/review/draft",
    component: <ReviewDraftProduct />,
  },
  {
    path: "product/review/view-reply/:id",
    component: <ReplyWithReview />,
  },
  {
    path: "product/draft",
    component: <DraftProducts />,
  },
  {
    path: "add/color",
    component: <AddColor />,
  },
  {
    path: "all/color",
    component: <AllColor />,
  },
  {
    path: "edit/color/:id",
    component: <EditColor />,
  },
  {
    path: "add/brand",
    component: <AddBrand/>,
  },
  {
    path: "all/brand",
    component: <AllBrand />,
  },
  {
    path: "edit/brand/:id",
    component: <EditBrand />,
  },
  {
    path: "all/orders",
    component: <AllOrders />,
  },
  {
    path: "view/order/:id",
    component: <ViewOrders />,
  },
  {
    path: "orders/new",
    component: <NewOrders />,
  },
  {
    path: "accounts",
    component: <AllOrderAmount />,
  },
  {
    path: "today-sell",
    component: <TodaySell />,
  },
  {
    path: "weekly-sell",
    component: <WeeklySell />,
  },
  {
    path: "monthly-sell",
    component: <MonthlySell />,
  },
  {
    path: "yearly-sell",
    component: <YearlySell />,
  },
  {
    path: "custom-service/new",
    component: <NewServiceRequest />,
  },
  {
    path: "custom-service/approved",
    component: <ApprovedService />,
  },
  {
    path: "custom-service/delivered",
    component: <DeliveredService />,
  },
  {
    path: "custom-service/cancled",
    component: <CancledService />,
  },
  {
    path: "custom-service/all",
    component: <AllServiceSec />,
  },
  {
    path: "view/custom-service/:id",
    component: <ViewCustomService />,
  },
  {
    path: "newsletter-subscription",
    component: <Subscription />,
  },
  {
    path: "top-search",
    component: <TopSearches />,
  },
  {
    path: "top-search-user",
    component: <TopSearchesByUser />,
  },
  {
    path: "top-search/month",
    component: <LastMonthSearches />,
  },
  {
    path: "top-search-user/month",
    component: <LastMonthSearchesByUser />,
  },
  {
    path: "top-search/year",
    component: <LastOnYearSearches />,
  },
  {
    path: "top-search-user/year",
    component: <LastOneYearSearchesByUser />,
  },
  {
    path: "today-search",
    component: <TodaysTopSearches />,
  },
  {
    path: "today-search-user",
    component: <TodaysTopSearchesByUser />,
  },
  {
    path: "all-search",
    component: <AllSearches />,
  },
  {
    path: "add-admin",
    component: <AddAdmin />,
  },
  {
    path: "all-admin",
    component: <AllAdmin />,
  },
  {
    path: "deactive-admin",
    component: <DeactiveAdmin />,
  },
  {
    path: "admin-give-role/:id",
    component: <GiveRoleAdmin />,
  },
  {
    path: "view/admin/:id",
    component: <ViewAdmin />,
  },
  {
    path: "all-role",
    component: <AllRole />,
  },
  {
    path: "admin-give-permission/:id",
    component: <GivePermission />,
  },
  {
    path: "admin-edit-role/:id",
    component: <EditeRole />,
  },
  {
    path: "all-permission",
    component: <AllPermission />,
  },
  {
    path: "add-permission",
    component: <AddPermission />,
  },
  {
    path: "edit-permission/:id",
    component: <EditPermission />,
  },
  {
    path: "stats/visitor-summary",
    component: <VisitorSummary />,
  },
  {
    path: "stats/visitor-visit-page",
    component: <VisitorVisitPage />,
  },
  {
    path: "all-user",
    component: <AllUsers />,
  },
  {
    path: "view/user/:id",
    component: <ViewUsers />,
  },
  {
    path: "deactive-user",
    component: <DeactiveUser />,
  },
  {
    path: "add-gallery",
    component: <AddGallery />,
  },
  {
    path: "all-gallery",
    component: <AllGalleryImages />,
  },
  {
    path: "edit/gallery/:id",
    component: <EditGallery />,
  },
  {
    path: "active-coupon",
    component: <ActiveCoupon />,
  },
  {
    path: "add-coupon",
    component: <AddCoupon />,
  },
  {
    path: "edit-coupon/:id",
    component: <EditCoupon />,
  },
  {
    path: "deactive-coupon",
    component: <DeactiveCoupon />,
  },
  {
    path: "expired-coupon",
    component: <ExpiredCoupon />,
  },
  {
    path: "profile",
    component: <Profile />,
  },
  {
    path: "change-password/",
    component: <ChangePassword />,
  },
  {
    path: "setting/header-top",
    component: <HeaderTopSetting />,
  },
  {
    path: "setting/header",
    component: <HeaderSetting />,
  },
  {
    path: "setting/all-header-search-option",
    component: <AllHeaderSearchOption />,
  },
  {
    path: "setting/edit-header-search-option/:id",
    component: <EditHeaderSearchOption />,
  },
  {
    path: "setting/add-slider",
    component: <AddSliderSetting />,
  },
  {
    path: "setting/all-slider",
    component: <AllSliderSetting />,
  },
  {
    path: "setting/edit/slider/:id",
    component: <EditSliderSetting />,
  },
  {
    path: "setting/add-mini-slider",
    component: <AddMiniSliderSetting />,
  },
  {
    path: "setting/all-mini-slider",
    component: <AllMiniSliderSetting />,
  },
  {
    path: "setting/edit/mini-slider/:id",
    component: <EditMiniSliderSetting />,
  },
  {
    path: "setting/add-mini-banner",
    component: <AddMiniBannerSetting />,
  },
  {
    path: "setting/all-mini-banner",
    component: <AllMiniBannerSetting />,
  },
  {
    path: "setting/edit/mini-banner/:id",
    component: <EditMiniBannerSetting />,
  },
  {
    path: "setting/add-special-service",
    component: <AddSpecialServiceSetting />,
  },
  {
    path: "setting/all-special-service",
    component: <AllSpecialServiceSetting />,
  },
  {
    path: "setting/edit/special-service/:id",
    component: <EditSpecialServiceSetting />,
  },{
    path: "add-service-plan",
    component: <AddServicePlanSec />,
  },
  {
    path: "all-service-plan",
    component: <AllServicePlanSec />,
  },
  {
    path: "edit/service-plan/:id",
    component: <EditServicePlanSec />,
  },
  {
    path: "deactive-service-plan",
    component: <DeactiveServicePlan />,
  },
  {
    path: "setting/add-featured-categories",
    component: <AddFeaturedCategoriesSetting />,
  },
  {
    path: "setting/all-featured-categories",
    component: <AllFeaturedCategoriesSetting />,
  },
  {
    path: "setting/edit/featured-categories/:id",
    component: <EditFeaturedCategorisSetting />,
  },
  {
    path: "setting/add-traditional",
    component: <AddTraditionalSetting />,
  },
  {
    path: "setting/all-traditional",
    component: <AllTraditionalSetting />,
  },
  {
    path: "setting/edit/traditional/:id",
    component: <EditTraditionalSetting />,
  },
  {
    path: "setting/add-gift-item",
    component: <AddGiftItemSetting />,
  },
  {
    path: "setting/all-gift-carousel",
    component: <AllGiftItemSetting />,
  },
  {
    path: "setting/edit/gift-carousel/:id",
    component: <EditGiftItemSetting />,
  },
  {
    path: "setting/add-brand",
    component: <AddBrandSetting />,
  },
  {
    path: "setting/all-brand",
    component: <AllBrandSetting />,
  },
  {
    path: "setting/edit/brand/:id",
    component: <EditBrandSetting />,
  },
  {
    path: "setting/footer",
    component: <FooterSetting />,
  },
  // blog 
  {
    path:"admin/blog/categories",
    component: <AllCategoryblog/>
  },
  {
    path:"admin/blog/add/category",
    component: <AddCategoryblog/>
  },
  {
    path:"admin/blog/edit/category/:id",
    component: <EditCategoryblog/>
  }
  ,{
    path:"admin/blog/category/view-sub-category/:id",
    component: <ViewSubCategorywithCatesblog/>
  },{
    path:"admin/blog/subcategories",
    component: <AllSubCategoryblog/>
  },
  {
    path:"admin/blog/add/subcategory",
    component: <AddSubCategoryblog/>
  },
  {
    path:"admin/blog/edit/subcategory/:id",
    component: <EditSubCategoryblog/>
  },{
    path:"admin/blog/post",
    component: <AllPostblog/>
  },
  {
    path:"admin/blog/add/post",
    component: <Addpostblog/>
  },
  {
    path:"admin/blog/edit/post/:id",
    component: <Editpostblog/>
  },
  {
    path:"admin/blog/post/draft",
    component: <DraftPostblog/>
  },
  {
    path:"admin/blog/post/view/:id",
    component: <ViewPostblog/>
  },
  {
    path:"admin/blog/comment",
    component: <AllCommentblog/>
  },
  {
    path:"admin/blog/comment/draft",
    component: <DraftCommentblog/>
  },
  {
    path:"admin/blog/commment/view-reply/:id",
    component: <ViewCommentReplyWithCommentblog/>
  },
  {
    path:"admin/blog/right-site-others",
    component: <RightSiteOthers/>
  }
  
];
