import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import axios from "axios";
import {  toast ,Zoom} from "react-toastify";
import {Helmet} from "react-helmet";



const AllCategory = () => {
 
  //  get categories option  data
  const [categories, setCategories] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
    axios.get("/category/all").then((res) => {
      if (res.data.status === 200) {
        setCategories(res.data.categories);
      }
      setloading(false);
    }).catch(error => {});
  }
  return () => {
    isMounted = false;
  }
  }, []);

  useEffect(() => {
    // initialize datatable
    if (!loading) {
      const script = document.createElement("script");
      script.src = "/dist/js/custom.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [loading]);
 

  const handleDeleteItems = (id) => {
    axios
      .delete(`/category/delete/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setCategories(
            categories.filter((item) => item.id !== id)
          );
        }
      })
      .catch((e) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
 

  return (
    <>
     {/* seo title */}
     <Helmet>
        <title> All || Categories</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Categories"
        pageTitle="All / Categories"
      />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
        
          <div className="card-body p-0">
          
            <table id="example1"   className="table table-striped projects">
              <thead>
                <tr>
                <th className="text-center">
                   SL
                  </th>
                  <th className="text-center">
                    Category Name
                  </th>
                  <th className="text-center">
                    Slug
                  </th>
                  <th className="text-center">
                  Category Icon
                  </th>
                  <th className="text-center">
                    See Sub Category
                  </th>
                  <th className="text-center">
                    Edit
                  </th>
                  <th className="text-center">
                    Delete
                  </th>

                </tr>
              </thead>
              <tbody>
                {
                  categories && categories?.map((val) => {
                    return (
                      <tr key={val.id}>
                          <td className="text-center">
                          {val.id}
                        </td>
                        <td className="text-center">
                          {val.category_name}
                        </td>
                        <td className="text-center">
                          {val.category_slug}
                        </td>
                        <td className="text-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <img alt="item-img" width="60" src={
                              val.item_img == null
                                ? null
                                : `${process.env.REACT_APP_API_URL}${val.item_img}`
                                  
                            } />
                            </li>

                          </ul>
                        </td>
                        <td className="text-center">
                          <Link to={`/our-dashboard/all/categories/view-sub-category/${val.id}`} className="btn btn-primary btn-sm p-2 " href="#/">
                            
                            <i className="fas fa-folder pr-2">
                            </i>
                            {/* <i class="fas fa-folder-upload pr-2"></i> */}
                            See Sub Category
                          </Link>
                        </td>
                        <td className="text-center">
                          <Link to={`/our-dashboard/edit/category/${val.id}`} className="btn btn-info btn-sm" href="#/">
                            <i className="fas fa-edit pr-2" />
                            Edit
                          </Link>
                        </td>
                        <td className="text-center">
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>

                    )
                  })
                }


              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}

    </>
  )
}

export default AllCategory