import React from "react";
import "./WorkShopSeminar.css";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBIcon,
  MDBCardText
  
} from "mdb-react-ui-kit";
import Title from "../SectionTitle/Title/Title";
import Titleimg from "../SectionTitle/Title_img/Title_img";
import { WorkShopSeminarData } from "./WorkShopSeminarData";


const WorkShop_SeminarSection = () => {
  return (
    <>
      <div className="workshop_seminar_section py-3">
        <Title section_title="WorkShop and Seminar" />
        <Titleimg />
        <div className="container">
          <div className="row custom_bg py-2 px-3">
          {
            WorkShopSeminarData.map((Value, index)=>{
              return(
                <div className="col-lg-4 col-md-4 col-10 mx-auto mt-4" key={index}>
              <MDBCard className="workshop_card hover-shadow">
                <MDBCardImage
                  position="top"
                  alt="workshop-seminar-img"
                  src={Value.img}
                />
                <MDBCardBody className="p-2 text-center">
                  <p className="title mb-0 fs-5">
                  <MDBIcon far icon="hand-point-right" /> {Value.titel}
                  </p>
                  <p className="date mb-0 fs-6 py-1 fw-bold">
                  <MDBIcon far icon="clock" /> {Value.date}
                  </p>
                  <p className="location mb-0 fs-6 py-1 fw-bold">
                  <MDBIcon fas icon="map-marker-alt" /> {Value.location}
                  </p>
                  <MDBCardText className="text-muted description">
                    {Value.description}
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </div>
              )
            })
          }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkShop_SeminarSection;
