import React from 'react';
import CheckOutSection from '../../../Components/frontend/CheckOutSection/CheckOutSection';

const CheckOut = () => {
  return(
      <>
      <CheckOutSection/>
      </>
  )
};

export default CheckOut;
