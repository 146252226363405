import React from 'react'
import VlogSection from '../../../Components/frontend/VlogSection/VlogSection'

const Vlog = () => {
  return (
    <>
    <VlogSection/>
    </>
  )
}

export default Vlog