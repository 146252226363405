import React, { useState } from "react";
import "./PopUp.css";
import Login from "../Login";
import Register from "../Register";
import EmailVerify from '../EmailVerify';
import ForgotPassword from '../ForgotPassword';
import ForgotVerifyPin from '../ForgotVerifyPin';
import ResetPassword from '../ResetPassword';

import {

  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";

const TabPanel = () => {
  const [basicActive, setBasicActive] = useState("tab1");
  const handleBasicClick = (value: string) => {
    if (value === basicActive) {
    setResetPassword_show(false)
    setVerifyPin_show(false)
    setforgotpassword_show(false)
    setEmailVerify_login_show(false)
      return ;
      
    }
    setBasicActive(value);
    
  };


  // email  verify or resgister show hide backend response with handle props true or false
  const [emailVerify_register_show, setEmailVerify_register_show] = useState(false);
  const [emailVerify_login_show, setEmailVerify_login_show] = useState(false);
// show forgot password
  const [forgotpassword_show, setforgotpassword_show] = useState(false);
  // show forgot password verify pin
  const [VerifyPin_show, setVerifyPin_show] = useState(false);
  // show reset password 
  const [resetPassword_show, setResetPassword_show] = useState(false);
 const [userEmail, setUserEmail] = useState();

  const Verify_login = (show, email) => {
    setEmailVerify_login_show(show);
    setUserEmail(email);
  };

  const Verify_register = (show, email) => {
    setEmailVerify_register_show(show);
    setUserEmail(email);

  };

  const onSetEmailVerify = (show) => {
    setEmailVerify_register_show(show);
    setEmailVerify_login_show(show);
    setBasicActive("tab1");
  };

  const onSetforgotPassword = (show) => {
    setforgotpassword_show(show);
  };

  const onSetPinVerify = (show) => {
    setVerifyPin_show(show);
    setforgotpassword_show(false);
  };

  const onSetResetPassword = (show) => {
    setResetPassword_show(show);
    setVerifyPin_show(false)
  };

  const onSetResetComplete = (show) => {
    setResetPassword_show(show);
    setVerifyPin_show(show);
    setforgotpassword_show(show);
    setEmailVerify_login_show(show);
  };

  return (
    <>
      <section id="log_register_panel">
        <MDBTabs className="mb-2 d-flex justify-content-around">
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}
            >
              Login
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}
            >
              Register
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
        <MDBTabsContent>
          <MDBTabsPane show={basicActive === "tab1"}>
            {/* login */}
            

            {
              emailVerify_login_show ?
              <EmailVerify email={userEmail} handleEmailVerify={onSetEmailVerify} />
              : forgotpassword_show ?
              <ForgotPassword handleVerifyPin={onSetPinVerify} handleforgotPassword={onSetforgotPassword} />
              : VerifyPin_show ?
               <ForgotVerifyPin handleResetPassword={onSetResetPassword} />
               : resetPassword_show ?
                <ResetPassword handleResetComplete={onSetResetComplete}/>
              :<Login handleEmailVerify={Verify_login} handleforgotPassword={onSetforgotPassword} />
              
            }
          </MDBTabsPane>
          <MDBTabsPane show={basicActive === "tab2"}>
            {/* Register */}
            {
              emailVerify_register_show ?
              <EmailVerify email={userEmail} handleEmailVerify={onSetEmailVerify} />
              : <Register handleEmailVerify={Verify_register} />
            }
        
            
          </MDBTabsPane>
        </MDBTabsContent>
      </section>
    </>
  );
};

export default TabPanel;
