import React from 'react'
import AddSpecialServiceSec from './../../../../Components/admin/Settings/SpecialService/AddSpecialServiceSec';


const AddSpecialServiceSetting = () => {
  return (
    <>
      <AddSpecialServiceSec/>
    </>
  )
}

export default AddSpecialServiceSetting