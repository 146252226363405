import React from "react";
import AllUserSec from "../../../../Components/admin/UserSection/AllUserSec";

const AllUsers = () => {
  return (
    <>
      <AllUserSec />
    </>
  );
};

export default AllUsers;
