import { useEffect, useState, useContext } from "react";
import {Helmet} from "react-helmet";
import { Navigate, Outlet, useNavigate} from "react-router-dom";
import axios from "axios";
import authContext from '../../auth/Context/AuthUser/authContext'
import Loader from './../../frontend/Loader/Loader';
import Header from "../../../layouts/admin/Header/Header.jsx";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import AdminFooter from "../../../layouts/admin/Footer/Footer.jsx";

  if (window.location.pathname.includes("/our-dashboard")) {

    import("../../../../node_modules/admin-lte/dist/css/adminlte.min.css");
    import("../../../../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css");
    import("../../../../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css");
    import("../../../../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");
    import("../../../../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css");
    import("../../../../node_modules/admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css");
    import("../../../../node_modules/admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css");
  }

export default function PrivateDashboard() {

// auth login context
  const { Login} =useContext(authContext);
  const [adminAuth, setAdminAuth] = useState(false);
  const [loading, setloading] = useState(true);
  const history =useNavigate();

 // get data
 useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      if(Login){
        const res = await axios.get("/admin-private-route");
        if(isMounted){
           document.body.className = "hold-transition sidebar-mini layout-fixed";
          if (res.data.status === 200) {
            document.title = "OurRajshahi Dash Board";
            setAdminAuth(true);
            setloading(false);
            // const trees = window.$('[data-widget="treeview"]');
            // trees.Treeview("init");
          } else if (res.data.status === 400) {
            history("/Unauthorized");
        }else if (res.data.status === 401) {
          history("/Unauthorized");
      }

        }
      }else{
        setAdminAuth(false);
        setloading(false);
      }
    } catch (e) {
       console.log(e);
    }
  }

  fetchData();
   
  return () => {
    isMounted = false;
  }
  
}, [Login]);







  if(loading){
    return  <Loader/>;
  }

  return adminAuth ? (
    <>
    <Helmet>
    

           
  {/* <!-- admin script start --> */}
<script type="text/javascript" src="https://cdn.tiny.cloud/1/wf61usc2c97fl313e16tteh0kzdb8h286txvagh2w8gkros8/tinymce/5/tinymce.min.js"
    referrerpolicy="origin"></script> 
  {/* <!-- backend script end --> */}

       </Helmet>

    <div className='hold-transition sidebar-mini layout-fixed'>
          <div className='wrapper'>
    <Header />
    <SidebarMenu />
      <div class='content-wrapper overflow-hidden'>
      <Outlet />

      </div>
      <AdminFooter />
      </div>
        </div>
    </>
  ) : (
    <Navigate to='/401' />
  );
}
