import React from 'react'
import Typed from 'react-typed';
import "./CommingSoonSection.css"
const CommingSoonSection = (props) => {
    return (
        <>
                <div className="comming_soon_sec overflow-hidden">
            <div className="">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mx-auto ">
                        <div className='banner_wrapper'>
                        <img src="assest/image/banar/contact_us.jpg" className='contact-banner d-block mx-auto' alt="" />
                        <div className="custom-banner-text text-center py-4"> 
                           
                        <h2>
                        
                        <Typed
                            strings={props.typed_text}
                                typeSpeed={40}
                                loop 
                                smartBackspace
                                >
                            </Typed>
                            
                        </h2>
                        
                        </div>
                        </div>   
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommingSoonSection
