import React, { useState, useContext } from "react";
import { Formik } from "formik";
import { toast,Zoom } from "react-toastify";
import {
  MDBInputGroup,
  MDBCard,
  MDBFile,
  MDBRadio,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import {Helmet} from "react-helmet";
import CustomCitySelect from "./custom/CustomCitySelect";
import option from "./custom/CityoptionData";
import authContext from './../../auth/Context/AuthUser/authContext';
const UserProfileForm = (props) => {
 // auth login context
 const { currentUser, userDetails, profileUpdateRender} =useContext(authContext);

   const [validation_error, setValidation_error] = useState([]);
   //  btn disabled
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);


   


  return (
    <>
          {/* seo title */}
          <Helmet>
          <title>Profile || User</title>
          </Helmet>


    <Formik
      initialValues={{
        full_address: userDetails?.user_detail?.full_address
          ? userDetails?.user_detail?.full_address
          : "",
        company_name: userDetails?.company_name ? userDetails?.company_name : "",
        bio: userDetails?.user_detail?.bio ? userDetails?.user_detail?.bio : "",
        city: userDetails?.user_detail?.city ? userDetails?.user_detail?.city : "",
        zip_code: userDetails?.user_detail?.zip_code
          ? userDetails?.user_detail?.zip_code
          : "",
        gender: userDetails?.user_detail?.gender ? userDetails?.user_detail?.gender : "",
        facebook_id: userDetails?.user_detail?.facebook_id
          ? userDetails?.user_detail?.facebook_id
          : "",
        twitter_id: userDetails?.user_detail?.twitter_id
          ? userDetails?.user_detail?.twitter_id
          : "",
        linkedin_id: userDetails?.user_detail?.linkedin_id
          ? userDetails?.user_detail?.linkedin_id
          : "",
        pinterest_id: userDetails?.user_detail?.pinterest_id
          ? userDetails?.user_detail?.pinterest_id
          : "",
        profile_image: userDetails?.user_detail?.profile_image
          ? userDetails?.user_detail?.profile_image
          : "",
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};

        if (values.full_address.length > 100) {
          errors.full_address = "Full Address maximum length of 100 characters";
        }

        // company validate condition

        if (values.company_name.length > 150) {
          errors.company_name = "Full Address maximum length of 150 characters";
        }

        // bio validate condition
        if (values.bio.length > 150) {
          errors.bio = "Full Address maximum length of 150 characters";
        }
        return errors;
      }}
      onSubmit={(values) => {
        setDisableSubmitBtn(true);
        const formData = new FormData();
        formData.append("full_address", values.full_address);
        formData.append("company_name", values.company_name);
        formData.append("bio", values.bio);
        formData.append("city", values.city);
        formData.append("zip_code", values.zip_code);
        formData.append("gender", values.gender);
        formData.append("facebook_id", values.facebook_id);
        formData.append("twitter_id", values.twitter_id);
        formData.append("linkedin_id", values.linkedin_id);
        formData.append("pinterest_id", values.pinterest_id);
        formData.append("profile_image", values.profile_image);
        axios
          .post("/profile/update", formData)
          .then((res) => {
            if (res.data.status === 200) {
              toast.success(res.data.message, {
                position: "top-center",
                theme: "colored",
                transition: Zoom,
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setDisableSubmitBtn(false);
              setValidation_error({});
              profileUpdateRender();
            } else if  (res.data.status === 422){
              setDisableSubmitBtn(false);
              setValidation_error(res.data.validation_errors);
            }
          })
          .catch((err) => {});
      }}>
      {(formik) => (
        <MDBCard className='shadow-1 p-2'>
          <form  onSubmit={formik.handleSubmit}>
            <div className='user_profile_form'>
              <p className='text-center text-dark h4'>---Profile---</p>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* name input */}
                  <div className='mt-1'>
                    <label htmlFor='fullName'>
                      Full Name <span className='text-danger'>*</span>
                    </label>
                    <MDBInputGroup>
                      <span className='input-group-text'>
                        <i className='fas fa-user'></i>
                      </span>
                      <input
                        className='form-control'
                        name='name'
                        placeholder='Full Name *'
                        id='fullName'
                        type='text'
                        disabled
                        value={currentUser?.name}

                      />
                    </MDBInputGroup>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* username input */}
                  <div className='mt-1'>
                    <label htmlFor='User-Name'>
                      User Name<span className='text-danger'>*</span>
                    </label>
                    <MDBInputGroup>
                      <span className='input-group-text'>
                        <i class='fa fa-user'></i>
                      </span>
                      <input
                        className='form-control'
                        name='userName'
                        placeholder='User Name*'
                        id='User-Name'
                        type='text'
                        disabled
                        value={currentUser?.user_name}
                        
                      />
                    </MDBInputGroup>

                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* email input */}
                  <div className='mt-2'>
                    <label htmlFor='email'>
                      Email <span className='text-danger'>*</span>
                    </label>
                    <MDBInputGroup>
                      <span
                        className='input-group-text'
                        style={{ paddingLeft: "10px" }}>
                        <i className='fas fa-envelope'></i>
                      </span>
                      <input
                        className='form-control'
                        name='email'
                        placeholder='Email *'
                        id='email'
                        type='email'
                        disabled
                        value={currentUser?.email}
                      />
                    </MDBInputGroup>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* phone inputs */}
                  <div className='mt-2'>
                    <label htmlFor='phone'>
                      Phone <span className='text-danger'>*</span>
                    </label>
                    <MDBInputGroup>
                      <span
                        className='input-group-text'
                        style={{ paddingLeft: "15px" }}>
                        <i className='fas fa-mobile-alt'></i>
                      </span>

                      <input
                        className='form-control'
                        name='phone'
                        placeholder='Phone *'
                        id='phone'
                        type='tel'
                        disabled
                        value={currentUser?.phone}
                      />
                    </MDBInputGroup>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* address input */}
                  <div className='mt-2'>
                    <label htmlFor='full-address'>
                       Full Address
                       {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup
                      textBefore={<i className='fas fa-map-marker-alt'></i>}>
                      <input
                        className='form-control'
                        label='full-address'
                        name='full_address'
                        placeholder='Full Address *'
                        id='full-address'
                        type='text'
                        
                        value={formik.values.full_address}
                        onChange={formik.handleChange}
                        touched={formik.touched.full_address}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.full_address ? (
                      <span className='text-danger'>
                        {validation_error.full_address}
                      </span>
                    ) : (
                      formik.touched.full_address &&
                      formik.errors.full_address && (
                        <span className='text-danger'>
                          {formik.errors.full_address}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* city input */}
                  <div className='mt-2'>
                    <label htmlFor='city'>
                      City 
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup textBefore={<i class='fas fa-building'></i>}>
                      <CustomCitySelect
                        onChange={(value) =>
                          formik.setFieldValue("city", value.value)
                        }
                        value={formik.values.city}
                        options={option}
                        touched={formik.touched.city}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>

                    {validation_error.city ? (
                      <span className='text-danger'>
                        {validation_error.city}
                      </span>
                    ) : (
                      formik.touched.city &&
                      formik.errors.city && (
                        <span className='text-danger'>
                          {formik.errors.city}
                        </span>
                      )
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* company/website input */}
                  <div className='mt-2'>
                    <label htmlFor='phone'>
                      Company / Website
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup
                      textBefore={<i className='fas fa-building'></i>}>
                      <input
                        className='form-control'
                        label='Company / Website'
                        name='company_name'
                        placeholder='Company / Website *'
                        id='company'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.company_name}
                        touched={formik.touched.company_name}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.company_name ? (
                      <span className='text-danger'>
                        {validation_error.company_name}
                      </span>
                    ) : (
                      formik.touched.company_name &&
                      formik.errors.company_name && (
                        <span className='text-danger'>
                          {formik.errors.company_name}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* zip input */}
                  <div className='mt-2'>
                    <label htmlFor='zip-code'>
                      Zip Code 
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup
                      textBefore={<i className='fas fa-file-archive'></i>}>
                      <input
                        className='form-control'
                        label='Zip Code'
                        name='zip_code'
                        placeholder='Zip Code *'
                        id='zip-code'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.zip_code}
                        touched={formik.touched.zip_code}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.zip_code ? (
                      <span className='text-danger'>
                        {validation_error.zip_code}
                      </span>
                    ) : (
                      formik.touched.zip_code &&
                      formik.errors.zip_code && (
                        <span className='text-danger'>
                          {formik.errors.zip_code}
                        </span>
                      )
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* facebook input */}
                  <div className='mt-2'>
                    <label htmlFor='facebook'>
                      Facebook
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup>
                      <span
                        className='input-group-text'
                        style={{ paddingLeft: "15px" }}>
                        <i className='fab fa-facebook-f'></i>
                      </span>
                      <input
                        className='form-control'
                        label='Facebook'
                        name='facebook_id'
                        id='facebook'
                        placeholder='https://www.facebook.com/username'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.facebook_id}
                        touched={formik.touched.facebook_id}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.facebook_id ? (
                      <span className='text-danger'>
                        {validation_error.facebook_id}
                      </span>
                    ) : (
                      formik.touched.facebook_id &&
                      formik.errors.facebook_id && (
                        <span className='text-danger'>
                          {formik.errors.facebook_id}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* twitter input */}
                  <div className='mt-2'>
                    <label htmlFor='twitter'>
                      twitter
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup
                      textBefore={<i className='fab fa-twitter'></i>}>
                      <input
                        className='form-control'
                        label='twitter'
                        name='twitter_id'
                        id='twitter'
                        placeholder='https://www.twitter.com/username'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.twitter_id}
                        touched={formik.touched.twitter_id}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.twitter_id ? (
                      <span className='text-danger'>
                        {validation_error.twitter_id}
                      </span>
                    ) : (
                      formik.touched.twitter_id &&
                      formik.errors.twitter_id && (
                        <span className='text-danger'>
                          {formik.errors.twitter_id}
                        </span>
                      )
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* linkedin input */}
                  <div className='mt-2'>
                    <label htmlFor='linkedin'>
                      Linkedin
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup>
                      <span
                        className='input-group-text'
                        style={{ paddingLeft: "15px" }}>
                        <i className='fab fa-linkedin-in'></i>
                      </span>

                      <input
                        className='form-control'
                        label='linkedin'
                        name='linkedin_id'
                        id='facebook'
                        placeholder='https://www.linkedin.com/username'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.linkedin_id}
                        touched={formik.touched.linkedin_id}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.linkedin_id ? (
                      <span className='text-danger'>
                        {validation_error.linkedin_id}
                      </span>
                    ) : (
                      formik.touched.linkedin_id &&
                      formik.errors.linkedin_id && (
                        <span className='text-danger'>
                          {formik.errors.linkedin_id}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* pinterest input */}
                  <div className='mt-2'>
                    <label htmlFor='pinterest'>
                      Pinterest
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup
                      textBefore={<i className='fab fa-pinterest-p'></i>}>
                      <input
                        className='form-control'
                        label='pinterest'
                        name='pinterest_id'
                        id='facebook'
                        placeholder='https://www.pinterest.com/username'
                        type='text'
                        onChange={formik.handleChange}
                        value={formik.values.pinterest_id}
                        touched={formik.touched.pinterest_id}
                        onBlur={formik.handleBlur}
                      />
                    </MDBInputGroup>
                    {validation_error.pinterest_id ? (
                      <span className='text-danger'>
                        {validation_error.pinterest_id}
                      </span>
                    ) : (
                      formik.touched.pinterest_id &&
                      formik.errors.pinterest_id && (
                        <span className='text-danger'>
                          {formik.errors.pinterest_id}
                        </span>
                      )
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  <div className='mt-2'>
                    <label htmlFor='zip-code'> Select Gender:</label>
                    <MDBInputGroup>
                      <MDBRadio
                        inline
                        name='gender'
                        id="
                        className='form-control'Male"
                        label='Male'
                        Value='male'
                        onChange={formik.handleChange}
                        checked={formik.values.gender === "male"}
                      />
                      <MDBRadio
                        inline
                        name='gender'
                        id="
                        className='form-control'Female"
                        label='Female'
                        Value='female'
                        onChange={formik.handleChange}
                        checked={formik.values.gender === "female"}
                      />
                    </MDBInputGroup>
                    {validation_error.gender ? (
                      <span className='text-danger'>
                        {validation_error.gender}
                      </span>
                    ) : (
                      <span className='text-danger'>
                        {formik.errors.gender}
                      </span>
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* change picture input */}
                  <div className='mt-2'>
                    <label htmlFor='phone'>
                      <i className='fas fa-user-circle pe-2'></i>Change Profile
                      Picture
                      {/* <span className='text-danger'>*</span> */}
                    </label>
                    <MDBInputGroup>
                      <div className='file-container'>
                        <MDBFile
                          name='profile_image'
                          value={formik.values.changeImage}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "profile_image",
                              e.target.files[0]
                            );
                          }}
                          className='w-100'
                          id='customFile'
                        />
                      </div>
                    </MDBInputGroup>
                    {validation_error.profile_image ? (
                      <span className='text-danger'>
                        {validation_error.profile_image}
                      </span>
                    ) : (
                      formik.touched.profile_image &&
                      formik.errors.profile_image && (
                        <span className='text-danger'>
                          {formik.errors.profile_image}
                        </span>
                      )
                    )}
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'></div>

                <div className='col-lg-6 col-md-6 col-12 mx-auto'>
                  {/* bio input */}
                  <div className='mt-2'>
                    <label htmlFor='bio'>
                      <i className='fas fa-info-circle pe-2'></i>Intro Bio
                      {/* <span className='text-danger'>*</span> */}
                    </label>

                    <textarea
                      className='form-control w-100'
                      label='Intro Bio'
                      name='bio'
                      placeholder='About Yourself (150 characters.)*'
                      id='bio'
                      rows={2}
                      type='text'
                      onChange={formik.handleChange}
                      value={formik.values.bio}
                      touched={formik.touched.bio}
                      onBlur={formik.handleBlur}
                    />

                    {validation_error.bio ? (
                      <span className='text-danger'>
                        {validation_error.bio}
                      </span>
                    ) : (
                      formik.touched.bio &&
                      formik.errors.bio && (
                        <span className='text-danger'>{formik.errors.bio}</span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='user_update-btn mt-4 text-center'>
              <MDBBtn
                className='m-1 bg-info w-75'
                type='submit'
                disabled={disableSubmitBtn}>
                Submit
              </MDBBtn>
            </div>
          </form>
        </MDBCard>
      )}
    </Formik>
    </>
  );
};

export default UserProfileForm;
