import React from 'react'
import EditSOSCategoryWrapper from '../../../../Components/admin/SOS_Categories_Section/Edit_SOSCategoryWrapper'

const EditeSOSCategories = () => {
  return (
    <>
    <EditSOSCategoryWrapper/>
    </>
  )
}

export default EditeSOSCategories