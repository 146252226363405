import React from 'react'
import AddCategory from '../../../../Components/admin/Categories_Section/AddCategory'

const AddCategories = (props) => {

  return (
    <>
    <AddCategory/>
    </>
  )
}

export default AddCategories