import React, { useState } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import {  toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";

const AddCategory = () => {
  const [insertCategory, setInsertCategory] = useState({
    category_name: "",
    category_slug: "",
    item_img: "",
  });
    // backend validation check
    const [validation_error, setValidation_error] = useState([]);
 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const {item_img, category_name,  category_slug } = insertCategory;
  const handleChange = (e) => {
    setInsertCategory({ ...insertCategory, [e.target.name]: e.target.value });
  };
  const handleChangeImage = (e) => {
    setInsertCategory({ ...insertCategory, [e.target.name]: e.target.files[0], });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
      formData.append("category_name", insertCategory.category_name);
      formData.append("category_slug", insertCategory.category_slug);
      formData.append("item_img", insertCategory.item_img);
    axios
      .post(`/category/insert`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setInsertCategory({
            category_name: "",
            category_slug: "",
            item_img: "",
          });
          document.getElementById("resetForm").reset();
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
     {/* seo title */}
     <Helmet>
        <title> Add || Categories</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Categories" pageTitle="Add / Categories" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Category</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputName">Category Name</label>
                    <input
                      required
                      type="text"
                      id="inputName"
                      name="category_name"
                      onChange={handleChange}
                      value={category_name}
                      className="form-control"
                      placeholder="Enter Category Name"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_name}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Category Slug</label>
                    <input
                      required
                      type="text"
                      name="category_slug"
                      value={category_slug}
                      onChange={handleChange}
                      id="category_slug"
                      className="form-control"
                      placeholder="Enter Category Slug"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_slug}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Category Icon</label>

                    <input
                      required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="item_img"
                      onChange={handleChangeImage}
                      id="customFile"
                    />
                  </div>
                  {
                    validation_error.item_img &&
                    <span className='text-danger'>
                          {validation_error.item_img}
                    <br/>
                  </span>
                  }
                  
                  
                  {
                    item_img &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(item_img)}
                  />
                   }
                    <br />
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddCategory;
