import React,{useState} from 'react'
import axios from "axios";
import {toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
const ChangePasswordSec = () => {
  const [changePassword, setChangePassword] = useState({
          old_password: "",
          new_password: "",
          new_password_confirmation: "",
  });
  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
     //  btn disabled
     const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const { old_password, new_password, new_password_confirmation } = changePassword;
  const handleChange = (e) => {
    setChangePassword({ ...changePassword, [e.target.name]: e.target.value });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = changePassword;
    setDisableSubmitBtn(true);
    axios
      .put(`/admin/change-password`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setChangePassword({
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
          });
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
        {/* seo title */}
        <Helmet>
        <title> Change-Password || Profile</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Profile"
        pageTitle="change-password"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Change Password</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} >              
                <div className="form-group">
                    <label htmlFor="inputPassword">Old Password</label>
                    <input type="password" id="inputPassword"  className="form-control" 
                    name='old_password' 
                     onChange={handleChange}
                      value={old_password}
                      placeholder='Password' />
                  </div>
                  {validation_error.old_password &&
                      <span className='text-danger'>
                        {validation_error.old_password}
                      </span>
                      }
                  <div className="form-group">
                    <label htmlFor="inputNewPassword">New Password</label>
                    <input type="password" id="inputNewPassword" className="form-control" 
                    name='new_password' 
                     onChange={handleChange}
                      value={new_password} placeholder='New Password' />
                  </div>
                  {validation_error.new_password &&
                      <span className='text-danger'>
                        {validation_error.new_password}
                      </span>
                      }
                  <div className="form-group">
                    <label htmlFor="inputRetypeNewPassword">Re-type new password</label>
                    <input type="password"  name='new_password_confirmation' 
                     onChange={handleChange}
                      value={new_password_confirmation} id="inputRetypeNewPassword" className="form-control" placeholder='Re-type new password' />
                  </div>
                    {validation_error.new_password_confirmation &&
                      <span className='text-danger'>
                        {validation_error.new_password_confirmation}
                      </span>
                         }
                  <button type="submit"  disabled={disableSubmitBtn} class="btn btn-info btn-block"><i class="fas fa-paper-plane"></i> Submit</button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  )
}

export default ChangePasswordSec