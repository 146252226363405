import React from "react";
import SubscriptionSec from "../../../Components/admin/NewsLetterSection/SubscriptionSec";

const Subscription = () => {
  return (
    <>
      <SubscriptionSec />
    </>
  );
};

export default Subscription;
