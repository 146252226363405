import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProductSection.css";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import EditorEdit from "../Editor/Editor";
import { toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";

const AddProductSec = () => {
  const [addProduct, setaddProduct] = useState({
    product_name: "",
    product_slug: "",
    selling_price: "",
    discount_price: "",
    qty:'',
    color: [],
    color_qty:[],
    size: [],
    brand_id:"",
    meta_keyword: "",
    meta_description: "",
    sos_category_id: "",
    location: "",
    delivery_cost: "",
    product_img: "",
    gallary_img:[],
    product_description: "",
    weekly_offer:false,
    new_arrival:false,
    top_collection:false,
  });
  const {
    product_name,
    product_slug,
    selling_price,
    discount_price,
    qty,
    color_qty,
    size,
    color,
    brand_id,
    meta_keyword,
    meta_description,
    sos_category_id,
    location,
    delivery_cost,
    product_img,
    gallary_img,
    product_description,
    weekly_offer,
    new_arrival,
    top_collection,
  } = addProduct;




//  get color
const [colors, setColors] = useState([]);
const [brands, setBrands] = useState([]);
  
//  get category
const [category, setCategory] = useState([]);
const [category_id, setCategoryId] = useState("");

//  get subcate
const [subcategory, setSubcategory] = useState([]);
const [sub_category_id, setSubCategoryId] = useState("");

//  get soscate
const [soscategory, setSoscategory] = useState([]);

// backend validation check
const [validation_error, setValidation_error] = useState([]);
const [loading, setloading] = useState(true);
//  btn disabled
const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

const handleChange = (e) => {
  setaddProduct({ ...addProduct, [e.target.name]: e.target.value });
};

const handleChangeType = (e) => {
  setaddProduct({ ...addProduct, [e.target.name]: e.target.checked });
};

const handleCategory = (e) => {
  setCategoryId(e.target.value);
};

const handleSubCategory = (e) => {
  setSubCategoryId(e.target.value);
};


const handleChangeImage = (e) => {
  setaddProduct({
    ...addProduct,
    [e.target.name]: e.target.files[0],
  });
};

const imageMimeType = /image\/(jpeg|png|jpg|gif|svg)/i;

const handleChangeGallaryImage = (e) => {
  setaddProduct({...addProduct, gallary_img: [...gallary_img, ...e.target.files] });
};



function deleteFile(e) {
    const filter = gallary_img.filter((item, index) => index !== e);
    setaddProduct({...addProduct, gallary_img: filter });
  }


const handleDesciption = (newDescption) => {
  setaddProduct({ ...addProduct, product_description: newDescption });
};



const colorhandleChange = (e) => {
// Destructuring
const {value, checked } = e.target;
// Case 1 : The user checks the box
if (checked) {
  setaddProduct({...addProduct,
    color: [...color, value],
  });
}

// Case 2  : The user unchecks the box
else {
  setaddProduct({...addProduct,
    color: color.filter((e) => e !== value),
  });
}


};

const colorQtyhandleChange = (e, index) => {
   const colorQty = color_qty; // Make a copy of the color_qty first.
   colorQty[index] = e.target.value; // Update it with the modified color_qty.
   setaddProduct({...addProduct,
    color_qty: colorQty,
  });

};
const sizehandleChange = (e, index) => {
  const sizes = size; // Make a copy of the size first.
  sizes[index] = e.target.value; // Update it with the modified size.
  setaddProduct({...addProduct,
    size: sizes,
 });

}






// get color
useEffect(() => {
  let isMounted = true;

  axios.get("/color/all").then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      setColors(res.data.colors);
    }
    setloading(false);
  }
  });

return () => {
 isMounted = false;
}
}, []);

// get brand
useEffect(() => {
  let isMounted = true;

  axios.get("/brand/all").then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      setBrands(res.data.brands);
    }
    setloading(false);
  }
  });

return () => {
 isMounted = false;
}
}, []);


// get category
useEffect(() => {
  let isMounted = true;

  axios.get("/category/all").then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      setCategory(res.data.categories);
    }
    setloading(false);
    setCategoryId('');
  }
  });

return () => {
 isMounted = false;
}
}, []);

// get subcate
useEffect(() => {
  let isMounted = true;
  if(category_id){
    axios.get(`/subcategory/list/${category_id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
       setSubcategory(res.data.subcategory);
      }
      setloading(false);
    
    }
    });
 
}else{
  setSubCategoryId('');
}
return () => {
 isMounted = false;
}
}, [category_id]);

// get soscate
useEffect(() => {
  let isMounted = true;

  if(category_id && sub_category_id){
    axios.get(`/sub-of-sos-category/list/${category_id}/${sub_category_id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setSoscategory(res.data.sos_category);
      }
      setloading(false);
      
        }
    });

}else{
  setaddProduct({...addProduct,
    sos_category_id:"",
     });
}
return () => {
 isMounted = false;
}
}, [category_id,sub_category_id]);

 

  const handleSubmit = (e) => {

    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
      formData.append("product_name", product_name);
      formData.append("product_slug", product_slug);
      formData.append("selling_price", selling_price);
      formData.append("discount_price", discount_price);
      color.forEach(value => {
        formData.append(`color[]`, value);
      });
      color_qty.forEach(value => {
        formData.append(`color_qty[]`, value);
      });
      size.forEach(value => {
        formData.append(`size[]`, value);
      });
      formData.append("qty", qty);
      formData.append("brand_id", brand_id);
      formData.append("meta_keyword", meta_keyword);
      formData.append("meta_description", meta_description);
      formData.append("category_id", category_id);
      formData.append("sub_category_id", sub_category_id);
      formData.append("sos_category_id", sos_category_id);
      formData.append("location", location);
      formData.append("delivery_cost", delivery_cost);
      formData.append("product_img", product_img);
      gallary_img.forEach(value => {
        formData.append(`gallary_img[]`, value);
      });
      formData.append("product_description", product_description);
      formData.append("weekly_offer", weekly_offer === true ? 1 : 0);
      formData.append("new_arrival", new_arrival === true ? 1 : 0);
      formData.append("top_collection", top_collection === true ? 1 : 0);
    axios
      .post(`/product/insert`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setaddProduct({
            product_name: "",
            product_slug: "",
            selling_price: "",
            discount_price: "",
            qty:"",
            color: [],
            color_qty:[],
            size: [],
            brand_id:"",
            meta_keyword: "",
            meta_description: "",
            sos_category_id: "",
            location: "",
            free_shipping_delivery_cost: "",
            product_img: "",
            gallary_img:[],
            product_description: "",
            weekly_offer: false,
            new_arrival: false,
            top_collection: false,
          });
          setCategoryId('')
          setSubCategoryId('')
          document.getElementById("resetForm").reset();
          setValidation_error({});
        } else if(res.data.status === 422){
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  







  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
          {/* seo title */}
          <Helmet>
        <title> Add || Product</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Products Add" pageTitle="Add / Products" />
      {/* Main content */}
      <section className="content product_add">
        <form id="resetForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">

                  <div className="form-group">
                    <label htmlFor="inputName">Products Name</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="product_name"
                      value={product_name}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.product_name &&
                    <span className='text-danger'>
                          {validation_error.product_name}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputName">Products Slug</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="product_slug"
                      value={product_slug}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.product_slug &&
                    <span className='text-danger'>
                          {validation_error.product_slug}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputName">Selling Price</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="selling_price"
                      value={selling_price}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.selling_price &&
                    <span className='text-danger'>
                          {validation_error.selling_price}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputName">Discount Price</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="discount_price"
                      value={discount_price}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.discount_price &&
                    <span className='text-danger'>
                          {validation_error.discount_price}
                    <br/>
                  </span>
                  }

                      <div className="form-group">
                        <label htmlFor="inputName">qty</label>
                        <input 
                          className="form-control"
                           type="text"
                           name='qty'
                           value={qty}
                           onChange={handleChange}
                            />
                        </div>
                              {
                    validation_error.qty &&
                    <span className='text-danger'>
                          {validation_error.qty}
                  </span>
                  }
                  
                   <div className="form-group">
                    <label>Brand</label>
                    <select
                      onChange={handleChange}
                      name="brand_id"
                      value={brand_id}
                      className="form-control"
                      style={{ width: "100%" }}
                    >
                    <option value='' >
                        --Select Brand--
                      </option>

                      {brands?.map((val) => {
                          return (
                            <option value={val.id} key={val.id}>
                              {val.brand_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {
                    validation_error.brand_id &&
                    <span className='text-danger'>
                          {validation_error.brand_id}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputName">Meta Keyword</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="meta_keyword"
                      value={meta_keyword}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.meta_keyword &&
                    <span className='text-danger'>
                          {validation_error.meta_keyword}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputName">Meta Description</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="meta_description"
                      value={meta_description}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                   {
                    validation_error.meta_description &&
                    <span className='text-danger'>
                          {validation_error.meta_description}
                    <br/>
                  </span>
                  }
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}

              {/* color, brand, qty start  */}
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Color, qty, Size</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className='row'> 
                  
                
                      {/* <!-- checkbox --> */}
                      {
                        colors?.map((val, i)=>{
                          return(
                            <>
                              <div className="col-md-3">
                                <div className='border p-2'>

                              
                              <div className="form-group">
                              <label htmlFor="inputName">Color</label>
                     <div className="form-check border p-2 pl-4">
                   
                    <input 
                          className="form-check-input"
                           type="checkbox" id={val.id}
                           name='color'
                           value={val.id}
                           onChange={colorhandleChange}
                            />
                          <label className="form-check-label mb-1" for={val.id} >{val.color_name}</label>
                  </div>

                         
                        </div>
                        <div className="form-group">
                        <label htmlFor="inputName">color qty</label>
                        <input 
                          className="form-control"
                           type="number"
                           name='color_qty'
                           onChange={(e)=> colorQtyhandleChange(e, i)}
                           value={color_qty[i] ? color_qty[i] : ""}
                           min='0'
                           max='100'
                           pattern='^([1-5]?[0-9]|100)$'
                           placeholder='0'
                         
                            />
                              </div>
                    <div className="form-group">
                    <label htmlFor="size">Size(M,L,X,XL,2XL)</label>
                    <input
                      // required
                      type="text"
                      onChange={(e)=>sizehandleChange(e, i)}
                      name="size"
                      id="size"
                      className="form-control"
                      value={size[i] ? size[i] : ""}
                      placeholder='M, L, X, XL, 2XL'
                    />
                  </div>
                        </div>
                        </div>
                            </>
                          )
                        })
                      }
                        </div>
 



                     
                 
                        {
                    validation_error.color &&
                    <span className='text-danger'>
                          {validation_error.color}
                  </span>
                  }
                  <br/>
                   {
                    validation_error.color_qty &&
                    <span className='text-danger'>
                          {validation_error.color_qty}
                  </span>
                  }
                  <br />
                  {
                    validation_error.size &&
                    <span className='text-danger'>
                          {validation_error.size}
                  </span>
                  }
                    
                       
                
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
               {/* color, brand, qty end  */}






               
            </div>
            
            <div className="col-md-6">
              <div className="row">
                
               <div className="col-md-12 mx-auto">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Type</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                <div className="d-inline">
                  <div class="icheck-primary d-inline m-2">
                        <input type="checkbox" name="weekly_offer" 
                        defaultChecked={weekly_offer}   onChange={handleChangeType} value={weekly_offer} id="weekly_offer"
                        />
                        <label for="weekly_offer">
                            Weekly-Offer
                        </label>
                      </div>
                      <div class="icheck-primary d-inline m-2">
                        <input type="checkbox" name="new_arrival" 
                        defaultChecked={new_arrival}   onChange={handleChangeType} value={new_arrival} id="new_arrival"
                        />
                        <label for="new_arrival">
                            New-Arrival
                        </label>
                      </div>
                      <div class="icheck-primary d-inline m-2">
                        <input type="checkbox" name="top_collection" 
                        defaultChecked={top_collection}   onChange={handleChangeType} value={top_collection} id="top_collection"
                        />
                        <label for="top_collection">
                            Top-Collection
                        </label>
                      </div>
                      </div>

                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
                
              </div>
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Select</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputStatus">Category</label>
                    <select
                      id="inputStatus"
                      onChange={handleCategory}
                      name="category_id"
                      value={category_id}
                      className="form-control custom-select"
                    >
                     <option value='' disabled='disabled'>
                        --Select Category--
                      </option>
                      {category && category.length > 0 &&  category.map((val) => {
                        return (
                          <option value={val.id}>{val.category_name}</option>
                        );
                      })}
                      </select>
                  </div>
                  {
                    validation_error.category_id &&
                    <span className='text-danger'>
                          {validation_error.category_id}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label>Sub Category</label>
                    <select
                      onChange={handleSubCategory}
                      name="sub_category_id"
                      value={sub_category_id}
                      className="form-control"
                      style={{ width: "100%" }}
                    >
                    <option value='' >
                        --Select Sub Category--
                      </option>

                      {subcategory && subcategory.length > 0 && subcategory?.map((val) => {
                          return (
                            <option value={val.id} key={val.id}>
                              {val.sub_category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {
                    validation_error.sub_category_id &&
                    <span className='text-danger'>
                          {validation_error.sub_category_id}
                    <br/>
                  </span>
                  }
                  <div className="form-group">
                    <label>SOS Category</label>
                    <select
                      onChange={handleChange}
                      name="sos_category_id"
                      value={addProduct.sos_category_id}
                      className="form-control"
                      style={{ width: "100%" }}
                    >
                      <option value='' >
                        --Select Sos Category--
                      </option>

                      {soscategory && soscategory.length > 0 && soscategory.map((val) => {
                          return (
                            <option value={val?.id} key={val?.id}>
                              {val?.sub_of_soscategory_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {
                    validation_error.sos_category_id &&
                    <span className='text-danger'>
                          {validation_error.sos_category_id}
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputStatus">Location</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="location"
                      value={location}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.location &&
                    <span className='text-danger'>
                          {validation_error.location}
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputStatus">
                      Free Shipping / Delivery Cost
                    </label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="delivery_cost"
                      value={delivery_cost}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.delivery_cost &&
                    <span className='text-danger'>
                          {validation_error.delivery_cost}
                  </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Product Image</label>

                    <input
                      // required
                 
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="product_img"
                      onChange={handleChangeImage}
                      id="customFile1"
                    />
                  </div>
                  {
                    validation_error.product_img &&
                    <span className='text-danger'>
                          {validation_error.product_img}
                    <br/>
                  </span>
                  }
                  {
                    product_img &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(product_img)}
                  />
                  
                   }
                  <br />
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Gallary Image</label>

                    <input
                      // required
                      multiple 
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="gallary_img"
                      onChange={handleChangeGallaryImage}
                      // disabled={file.length === 5}
                      id="customFile2"
                    />
                  </div>

                   <div className="form-group preview">
        {gallary_img.length > 0 &&
          gallary_img.map((item, index) => {
            return (
              <div key={item}>
                 <img
                    alt='preview-img'
                    width='60'
                    src={URL.createObjectURL(item)}
                  />
                <button type="button"   className="btn btn-sm btn-danger m-2" onClick={() => deleteFile(index)}>
                  delete
                </button>
              
                    
                    { !item.type.match(imageMimeType) && 
                      <span className='text-danger'>
                      The product img must be an image.The product img must be a file of type: jpeg, png, jpg, gif, svg.
                      <br/>
                     </span>

                    }
                    { item.size > 2097152 && 
                      <span className='text-danger'>
                      The product img must not be greater than 2048 kilobytes.
                      <br/>
                     </span>

                    }
              </div>
              
            );

          })}
      </div>
                  
                  
                 <br />
                  <div className="editor-sec">
                    <label htmlFor="editor">Product Description</label>
                    <EditorEdit
                      name="desciption"
                      handleChange={handleDesciption}
                      value={product_description}
                    />
                  </div>
                  {
                    validation_error.product_description &&
                    <span className='text-danger'>
                          {validation_error.product_description}
                    <br/>
                  </span>
                  }
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
          
          <div className="row">
            <div className="col-12 py-4">
              <button
                type="submit"
                className="btn btn-success btn-block mx-auto w-75"
                disabled={disableSubmitBtn}
              >
                <i className="fas fa-paper-plane"></i> Submit
              </button>
            </div>
          </div>
        </form>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddProductSec;
