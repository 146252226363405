import React, {useState, useEffect} from 'react'
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
const AddSubCategory = () => {

  const [insertSubcategory, setInsertSubcategory] = useState({
    category_id: "",
    sub_category_name: "",
    sub_category_slug: "",
  });

  const { category_id, sub_category_name, sub_category_slug } = insertSubcategory;

//  get category option  data
const [category, setCategory] = useState([]);
const [loading, setloading] = useState(true);

// backend validation check
const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

// get data
useEffect(() => {
  let isMounted = true;
  axios.get("/category/all").then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      setCategory(res.data.categories);
    }
    setloading(false);
  }
  });
  return () => {
    isMounted = false;
  }
}, []);

 
 
  const handleChange = (e) => {
    setInsertSubcategory({ ...insertSubcategory, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  setDisableSubmitBtn(true);
    const data = insertSubcategory;
    axios
      .post(`/subcategory/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setInsertSubcategory({
            category_id: "",
          sub_category_name: "",
          sub_category_slug: "",
          });
          
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
    if (loading) {
    return <h2>loading....</h2>;
  }
 

  return (
    <>
              {/* seo title */}
              <Helmet>
        <title> Add || SubCategory</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Sub-Category"
        pageTitle="Add / Sub-Category"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Sub Category</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Category </label>
                    <select
                      class="form-control"
                      name="category_id"
                      value={category_id}
                      onChange={handleChange}
                      id="exampleFormControlSelect1"
                    >
                      <option value='' disabled="disabled" >--Select Category--</option>
                      {category && category.map((val)=>{
                        return (
                        <option value={val.id}>{val.category_name}</option>
                        );
                      })}
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_id}
                        </span>
                        
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">
                      Sub Category
                    </label>
                    <input
                    // required
                      type="text"
                      name="sub_category_name"
                      value={sub_category_name}
                      onChange={handleChange}
                      id="inputClientCompany"
                      className="form-control"
                      placeholder="Enter Sub Category"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.sub_category_name}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Slug</label>
                    <input
                    // required
                      type="text"
                      name="sub_category_slug"
                      value={sub_category_slug}
                      onChange={handleChange}
                      id="inputClientCompany"
                      className="form-control"
                      placeholder="Enter Slug"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.sub_category_slug}
                        </span>
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddSubCategory;
