import React from 'react'
import ExpiredCouponSec from '../../../../Components/admin/CouponSection/ExpiredCouponSec'

const ExpiredCoupon = () => {
  return (
    <>
    <ExpiredCouponSec/>
    </>
  )
}

export default ExpiredCoupon