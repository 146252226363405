import React from "react";
import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
const CheckBox = ({ id, label,checked, handleChange }) => {
  return (
    <>
      <MDBListGroup>
        <MDBListGroupItem>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value={label}
              id={label}
              checked={checked}
              onChange={() => handleChange(id)}
            />
            <label class="form-check-label" for={label}>
              {label}
            </label>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
    </>
  );
};

export default CheckBox;
