import React from 'react'
import CommingSoonSection from '../../../Components/frontend/CommingSoonSection/CommingSoonSection'
const text=['If you have any questions you can post','Forum is Coming Soon!']
const Forum = () => {
    return (
        <>
            <CommingSoonSection
             typed_text={text}
            />
        </>
    )
}

export default Forum
