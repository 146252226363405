import React from 'react'
import AllGalleryImageSec from '../../../../Components/admin/GallerySection/AllGalleryImageSec'

const AllGalleryImages = () => {
  return (
    <>
    <AllGalleryImageSec/>
    </>
  )
}

export default AllGalleryImages