import React from 'react'
import EditSliderSec from '../../../../Components/admin/Settings/Slider/EditSliderSec'


const EditSliderSetting = () => {
  return (
    <>
    <EditSliderSec/>
    </>
  )
}

export default EditSliderSetting