import React, { useReducer, useEffect } from "react";
import { reducer } from "./authReducer";
import NoteContext from "./authContext";
import  axios from "axios";
const initialState = {
  Login: localStorage.getItem("user_token") ? true : false,
  currentUser: [],
  userDetails:[],
  isLoading: true,
  isRendering:false,
  Modal:false,
};
const AuthStateDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // get user
  useEffect(() => {
    let isMounted = true;
 const fetchCurrentUser = async ()=> {
  try {
  if (state.Login) {
    const res = await axios.get("/me");
    if (isMounted) {
    if (res.data.status === 200) {
      dispatch({
        type: "FETCH_CURRENT_USER",
        payload:{
          data:res.data.user
        } 
      });
    }
    dispatch({
      type: "FETCH_END",
      payload:{
        loading: false,
      } 
    });
  }
  }
  } catch (e) {
    console.log(e);
    if(e){
      localStorage.removeItem("user_token");
      userLogOut();
      // navigate("/", { replace: true });
    }
 }
}

   fetchCurrentUser();

const fetchUserDetails = async () =>{
  try {
    if (state.Login) {
  const res = await axios.get("/profile");
    if (isMounted) {
    if (res.data.status === 200) {
      dispatch({
        type: "FETCH_USER_DETAILS",
        payload:{
          data:res.data.user_details
        } 
      });
    }
    }
    }
  } catch (e) {
    console.log(e);
 }
  }
  
     
    fetchUserDetails();

  return () => {
      isMounted = false
    }
  }, [state.Login, state.isRendering]);




  const userLogin = () => {
    return dispatch({
      type: "USER_LOGIN",
    });
  };

  const userLogOut = () => {
    return dispatch({
      type: "USER_LOGOUT",
    });
  };


  const profileUpdateRender = () => {
    return dispatch({
      type: "FETCH_RERENDER",
      payload:{
        render: true,
      }
    });
  };


  const toggleShow = () =>{
     dispatch({
      type: "LOGIN_POP_UP_SHOW_HIDE",
      payload: !state.Modal
    });
  } 



  return (
    <NoteContext.Provider
      value={{
        ...state,
        userLogin,
        userLogOut,
        profileUpdateRender,
        toggleShow
      }}>
      {children}
    </NoteContext.Provider>
  );
};

export default AuthStateDataProvider;
