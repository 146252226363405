import React from 'react'
import AllMiniSliderSec from '../../../../Components/admin/Settings/MiniSlider/AllMiniSliderSec'

const AllMiniSliderSetting = () => {
  return (
    <>
    <AllMiniSliderSec/>
    </>
  )
}

export default AllMiniSliderSetting