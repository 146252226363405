import React from 'react'
import NewServiceRequestSec from '../../../../Components/admin/CustomServiceSection/NewServiceRequestSec'

const NewServiceRequest = () => {
  return (
    <>
    <NewServiceRequestSec/>
    </>
  )
}

export default NewServiceRequest