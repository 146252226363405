import React from 'react'
import "./Chatbot.css"
const Chatbot = () => {
    return (
        <>
            <div className="chatbot_wrapper animated">
                <ul className="chatbot_menu">
                    <li className='triger_hover'>
                        <a className='comment_icon' href='#/'><i class="far fa-comments"></i></a>
                        <ul className="hoverle d-flex">
                            <li className='px-4'><a className='whatsapp_icon' href="https://wa.me/+8801770871128" target="_blank" rel="noopener noreferrer"><i class="fab fa-whatsapp"></i></a></li>
                            <li><a className='massenger_icon' href="https://www.facebook.com/messages/t/1242555689233003" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-messenger"></i></a></li>
                        </ul>
                    </li>
                </ul>
            </div> 
        </>
    )
}

export default Chatbot
