import React, { useContext, useState } from "react";
import {
  MDBCard,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Wishlistitem from "./Wishlist_item";
import wishNoteContext from "../Context/Wishlist/wishNoteContext";
import MobileWishlistitem from "./MobileWishlist_item";
import "react-toastify/dist/ReactToastify.css";
import "./WishlistSection.css";
import Loader from './../Loader/Loader';
import {Helmet} from "react-helmet";
const WishlistSection = () => {
  const { itemWish, clearWish, totalItemWish, totalAmountWish, isLoading } = useContext(wishNoteContext);

    //  modal
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);
    

    if (isLoading) {
      return <Loader/>
    }




  if (itemWish.length === 0) {
    return (
      <div className='wishlist_section py-4 bg-light'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold'>Wishlist</h5>
          <p className='text-center fs-5 fw-bold py-5'>--Wishlist Empty--</p>
        </div>
      </div>
    );
  }

  return (
      
    <>
    {/* seo title */}
    <Helmet>
      <title>Checkout</title>
      </Helmet>
      <div className='wishlist_section bg-light py-2  overflow-hidden'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold'>Wishlist</h5>
          <MDBCard className="my-4 p-3">
              <div className='cart_total'>
                <h5 className='cart_title'>Shopping Wishlist</h5>
                <span className=''>
                  You Have ({totalItemWish}) Items in Shopping Wishlist
                </span>
              </div>
              <div className='d-none d-xxl-block d-lg-block d-md-block'>
                <MDBTable responsive>
                  <MDBTableHead className='bg-info'>
                    <tr className='text-white text-center'>
                      <th scope='col'>Product Details</th>
                      <th scope='col'>Price</th>
                      <th scope='col'>Remove</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {itemWish?.map((val) => {
                      return <Wishlistitem key={val.id} {...val} />;
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className='d-xxl-none d-lg-none d-md-none mobile_cart'>
                <div className='row'>
                  {itemWish?.map((val) => {
                    return <MobileWishlistitem key={val.id} {...val} />;
                  })}
                </div>
              </div>
              <div className='cart_clear-btn  d-flex justify-content-end'>
                <MDBBtn
                  className='m-1'
                  onClick={toggleShow}
                  style={{ backgroundColor: " #ff4747", color: "white" }}>
                  Clear Cart all{" "}
                  <i className='fas fa-trash-alt text-dark fs-6'></i>
                </MDBBtn>
              </div>
              <div className='total_prices d-flex justify-content-end me-5 mt-3'>
                <span className='d-flex'>
                  <p className='fw-bold'>Total:</p>
                  <p className='fw-bold '>৳ {totalAmountWish}</p>
                </span>
              </div>
          </MDBCard>
        </div>

        
        {/* cart remove modal */}
        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
          <MDBModalDialog scrollable className='modal-sm'>
            <MDBModalContent className='p-0'>
              <MDBModalHeader className='bg-danger text-white d-flex justify-content-center'>
                <MDBModalTitle className='p-3'>
                  <p className='h5 text-white'>Are You Sure ?</p>
                </MDBModalTitle>
                {/* <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn> */}
              </MDBModalHeader>
              <MDBModalBody className='text-center'>
                <i class='fas fa-times text-danger fa-3x'></i>
                <p className='mb-2'>All Item will be removed from Cart</p>
              </MDBModalBody>
              <MDBModalFooter>
                <div onClick={toggleShow}>
                  <MDBBtn color='danger' onClick={clearWish}>
                    <p className='mb-0'>Yes</p>
                  </MDBBtn>
                </div>
                <MDBBtn
                  outline
                  className='mx-2'
                  color='danger'
                  onClick={toggleShow}>
                  No
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
};

export default WishlistSection;
