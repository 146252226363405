import React from 'react'
import EditCouponSec from '../../../../Components/admin/CouponSection/EditCouponSec'

const EditCoupon = () => {
  return (
    <>
    <EditCouponSec/>
    </>
  )
}

export default EditCoupon