import React from 'react'
import AllCategory from '../../../../Components/admin/Categories_Section/AllCategory'

const AllCategories = () => {
  return (
    <>
    <AllCategory/>
    </>
  )
}

export default AllCategories