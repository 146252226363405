import React, {useState, useEffect} from 'react'
import EditorEdit from "../../Editor/Editor";
// import { InputTags } from 'react-bootstrap-tagsinput'
// import 'react-bootstrap-tagsinput/dist/index.css'
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";

const Add_post_blog = () => {
 //  get blog categories with blog subcats option  data
 const [blogcats_with_blogsubcats, setBlogcats_with_blogsubcats] = useState([]);
 const [loading, setloading] = useState(true);
// get data
useEffect(() => {
  axios.get("/blog/post/blogcats-with-blogsubcats").then((res) => {
    if (res.data.status === 200) {
      setBlogcats_with_blogsubcats(res.data.blog_categories);
    }
    setloading(false);
  });
  return () => {
    setBlogcats_with_blogsubcats([]);
  };
}, []);

    const [addPost_blog, setaddPost_blog] = useState({
        blog_category_id: "",
        blog_sub_category_id: "",
        blog_image: "",
        heading: "",
        tag: "",
        status:false,
        post_description: "",
      });

      const {
        blog_category_id,
        blog_sub_category_id,
        heading,
        blog_image,
        post_description,
        tag,
        status,
      } = addPost_blog;

      // backend validation check
    const [validation_error, setValidation_error] = useState([]);

const handleChange = (e) => {
        setaddPost_blog({ ...addPost_blog, [e.target.name]: e.target.value });
      };
      
      const DrafthandleChange = (e) => {
        setaddPost_blog({ ...addPost_blog, [e.target.name]: e.target.checked });
      };

      const handleChangeImage = (e) => {
        setaddPost_blog({
          ...addPost_blog,
          [e.target.name]: e.target.files[0],
        });
      };
    
      const handleDesciption = (newDescption) => {
        setaddPost_blog({ ...addPost_blog, post_description: newDescption });
      };


      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
      formData.append("heading", addPost_blog.heading);
      formData.append("tag", addPost_blog.tag);
      formData.append("blog_category_id", addPost_blog.blog_category_id);
      formData.append("blog_sub_category_id", addPost_blog.blog_sub_category_id);
      formData.append("blog_image", addPost_blog.blog_image);
      formData.append("post_description", addPost_blog.post_description);
      formData.append("status", addPost_blog.status  === true ? 1 : 0);
      console.log(formData)
      axios
      .post(`/blog/post/insert`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setaddPost_blog({
            blog_category_id: "",
            blog_sub_category_id: "",
            blog_image: "",
            heading: "",
            tag: "",
            status: false,
            post_description: "",
          });
          document.getElementById("resetForm").reset();
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
        
      };
      if (loading) {
        return <h2>loading....</h2>;
      }
  return (
     <>
      {/* seo title */}
      <Helmet>
        <title> Add || Blog-Post</title>
      </Helmet>

     <section className="content product_add">
        <form id="resetForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-5">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">Heading</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="heading"
                      value={heading}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.heading}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputName">Tag</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="tag"
                      value={tag}
                      id="inputName"
                      className="form-control"
                    />
                  </div>

       {/* <InputTags values={tag} name="tag" onChange={handleChange} onTags={(value) => setState(value.values)} /> */}

       <span className='text-danger'>
                          {validation_error.tag}
                        </span>
                      <div className="form-group">
                    <label htmlFor="inputClientCompany">Post Image</label>
                    <input
                      // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="blog_image"
                      onChange={handleChangeImage}
                      id="customFile2"
                    />
                  </div>
                   {
                    validation_error.blog_image &&
                    <span className='text-danger'>
                    {validation_error.blog_image}
                    <br />
                  </span>
                  }

                    {
                    blog_image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(blog_image)}
                  />
                   }
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>


            <div className="col-md-5">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Select</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputStatus">Category</label>
                    <select
                      id="inputStatus"
                      onChange={handleChange}
                      name="blog_category_id"
                      value={blog_category_id}
                      className="form-control custom-select"
                    >
                      <option value="">--Select Blog Category--</option>
                      {
                        blogcats_with_blogsubcats?.map((val)=>{
                          return(
                            <option value={val.id}>{val.category_name}</option>
                          )
                        })
                      }
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.blog_category_id}
                        </span>
                  <div class="form-group">
                    <label>Sub Category</label>
                    <select
                      onChange={handleChange}
                      name="blog_sub_category_id"
                      value={blog_sub_category_id}
                      class="form-control"
                      style={{ width: "100%" }}
                    >
                      <option value="">--Select Blog Sub Category--</option>
                      {
                        blogcats_with_blogsubcats?.find((cats)=> cats.id == blog_category_id)?.blog_subcategory.map((val)=>{
                          return(
                            <option value={val.id}>{val.sub_category_name}</option>
                          )
                        })
                      }
                   
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.blog_sub_category_id}
                        </span>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>


            <div className="col-md-2">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Type</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div class="icheck-primary d-inline">
                        <input type="checkbox" name="status" defaultChecked={status}   onChange={DrafthandleChange} value={status} id="checkboxPrimary2"/>
                        <label for="checkboxPrimary2">
                            Draft
                        </label>
                      </div>
                  

                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>

          </div>





          <div className="row">
          <div className="col-12 py-1">
          <div className="editor-sec">
                    <label htmlFor="editor">Post Description</label>
                    <EditorEdit
                      name="post_description"
                      handleChange={handleDesciption}
                      value={post_description}
                    />
                     </div>
                     <span className='text-danger'>
                          {validation_error.post_description}
                        </span>
                  </div>
          </div>
          <div className="row">
            <div className="col-12 py-4">
              <button
                type="submit"
                className="btn btn-success btn-block mx-auto w-75"
              >
                <i class="fas fa-paper-plane"></i> Submit
              </button>
            </div>
            
          </div>
        </form>
      </section>
     </>
    )
};

export default Add_post_blog;
