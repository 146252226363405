import React from 'react'
import AllAdminSec from '../../../../Components/admin/Admin/AllAdminSec'

const AllAdmin = () => {
  return (
    <>
    <AllAdminSec/>
    </>
  )
}

export default AllAdmin