import React from 'react'
import DeactiveCouponSec from '../../../../Components/admin/CouponSection/DeactiveCouponSec'

const DeactiveCoupon = () => {
  return (
    <>
    <DeactiveCouponSec/>
    </>
  )
}

export default DeactiveCoupon