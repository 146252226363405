import React from 'react'
import AddSOSCategory from '../../../../Components/admin/SOS_Categories_Section/AddSOSCategory'

const AddSOSCategories = () => {
  return (
    <>
    <AddSOSCategory/>
    </>
  )
}

export default AddSOSCategories