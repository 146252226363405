import React, {useState} from 'react'
import {MDBContainer, MDBNavbar, MDBNavbarToggler, MDBNavbarNav, MDBNavbarItem, MDBCollapse} from 'mdb-react-ui-kit';
import { AiOutlineBars } from "react-icons/ai";
import AllcateGories from './AllcateGories';
const MediumDevicesAllcateGories = () => {
    const [showBasic, setShowBasic] = useState(false);
    return (
        <>
        <section className='medium_devices_allcategories d-none d-md-block d-lg-none d-xxl-none'>
        <MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer fluid>
        <MDBNavbarToggler
          className='text-dark'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <AiOutlineBars  style={{fontSize:"22px"}}/>&nbsp; Categories
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>
                 <AllcateGories/>
            </MDBNavbarItem>    
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>

            </section>
        </>
    )
}

export default MediumDevicesAllcateGories
