import React from 'react'
import EditProductWrapper from '../../../../Components/admin/ProductSection/EditProductWrapper'

const EditeProduct = () => {
  return (
    <>
    <EditProductWrapper/>
    </>
  )
}

export default EditeProduct