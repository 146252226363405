import React, {useContext} from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import MessageData from './MessageData';
import { toast } from "react-toastify";
import {Zoom} from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import wishNoteContext from "../../../Components/frontend/Context/Wishlist/wishNoteContext";
const Header = () => {
  const navigate = useNavigate();

  const {userLogOut } =
  useContext(wishNoteContext);
  const logout = () => {
    axios
      .post("/logout")
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.removeItem("user_token");
          navigate('/', { replace: true })
          window.location.reload();
          userLogOut();
        } else {
          // setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <nav className="main-header admin_header navbar navbar-expand navbar-white navbar-light text-white">
        {/* <!-- Left navbar links --> */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#/" role="button"><i class="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/our-dashboard" className="nav-link">Home</Link>
          </li>
        </ul>

        {/* <!-- Right navbar links --> */}
        <ul className="navbar-nav ml-auto">


          {/* <!-- Messages Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#/">
              <i className="fas fa-envelope"></i>
              {MessageData.length === 0 ? <span className="badge badge-danger navbar-badge"></span> : <span className="badge badge-danger navbar-badge">{MessageData.length}</span>}
            </a>

           
          </li>



          {/* <!-- order Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#/">
              <i class="fas fa-shopping-bag"></i>
              {MessageData.length === 0 ? <span className="badge badge-danger navbar-badge"></span> : <span className="badge badge-danger navbar-badge">{MessageData.length}</span>}
            </a>

           
          </li>

          {/* <!-- notification Dropdown Menu --> */}
          <li className="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#/">
              <i class="fas fa-bell"></i>
              {MessageData.length === 0 ? <span className="badge badge-danger navbar-badge"></span> : <span className="badge badge-danger navbar-badge">{MessageData.length}</span>}
            </a>

           
          </li>
          <li className="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#/">
            <i class="fas fa-user-alt"></i>
            </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <Link className="dropdown-item" to="/our-dashboard/profile"><i class="fas fa-user-edit"></i>&nbsp; Profile</Link>
              <Link className="dropdown-item" to="/our-dashboard/change-password"><i class="fas fa-lock"></i>&nbsp; Change Password</Link>
              <a href='##' className="dropdown-item"   onClick={logout}><i class="fas fa-power-off"></i>&nbsp; LogOut</a>
              </div>
          </li>
 
          {/* ----fullscreen--- */}
          <li className="nav-item">
            <a class="nav-link" data-widget="fullscreen" href="##" role="button">
              <i className="fas fa-expand-arrows-alt"></i>
            </a>
          </li>
        </ul>
      </nav>

    </>
  )
}

export default Header