import React from 'react'
import AllSubCategory from '../../../../Components/admin/Sub_Categories_Section/AllSubCategory'

const AllSubCategories = () => {
  return (
    <>
    <AllSubCategory/>
    </>
  )
}

export default AllSubCategories