import React from 'react'
import EditCategoryWrapper from '../../../../Components/admin/Categories_Section/Edit_CategoryWrapper'

const EditeCategories = () => {
  return (
    <>
    <EditCategoryWrapper/>
    </>
  )
}

export default EditeCategories