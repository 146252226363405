import React from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast,Zoom} from 'react-toastify';
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import {Helmet} from "react-helmet";

const AddTraditionalSec = () => {
  const [insert, setInsert] = useState({
    title: "",
    image: "",
    img_alt: "",
    url: ""
  });
  const { title, image, img_alt, url } =
    insert;
 // backend validation check
 const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const handleChange = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.files[0],
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", image);
    formData.append("img_alt", img_alt);
    formData.append("url", url);
    axios
    .post(`/setting/traditional/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setInsert({
          title: "",
          image: "",
          img_alt: "",
          url: ""
        });
         setValidation_error({});
        document.getElementById("resetForm").reset();
       
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});
  };
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title>Add || Traditional</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Add-Traditional" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Traditional</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/setting/all-traditional"
                    className="btn btn-success btn"
                  >
                    All Traditional
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={title}
                      className="form-control"
                      placeholder="Enter title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }
                 
                  <div className="form-group">
                    <label htmlFor="img">Image</label>

                    <input
                    required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(image)}
                  />
                   }
                  <div className="form-group">
                    <label htmlFor="inputslug">Image Alt</label>
                    <input
                    required
                      type="text"
                      id="inputalt"
                      name="img_alt"
                      onChange={handleChange}
                      value={img_alt}
                      className="form-control"
                      placeholder="Enter Image Alt"
                    />
                  </div>
                  {
                    validation_error.img_alt &&
                    <span className='text-danger'>
                          {validation_error.img_alt}
                      <br />
                      </span>
                  }
                   <div className="form-group">
                    <label htmlFor="url">Add Link </label>
                    <input
                    required
                      type="text"
                      id="url"
                      name="url"
                      onChange={handleChange}
                      value={url}
                      className="form-control"
                      placeholder="Enter URL"
                    />
                  </div>
                  {
                    validation_error.url &&
                    <span className='text-danger'>
                          {validation_error.url}
                      
                      </span>
                  }
                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
}

export default AddTraditionalSec;
