import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader"
import axios from "axios";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBCollapse,
  MDBNavbarItem,
} from "mdb-react-ui-kit";
import "./blog_mega_menu.css";
const BlogMegaMenu = (props) => {
  const [showBasic, setShowBasic] = useState(false);
  const [blog_mega_menu, setBlog_mega_menu] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
      const res = await axios.get("/blog/mega-menu")
      if(isMounted){
        if (res.data.status === 200) {
          setBlog_mega_menu(res.data.blog_mega_menu);
        }
        setloading(false);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }

   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);


  
  return (
    <div className='blog_mega_menu custom_sticky'>
      <MDBNavbar  expand='lg' light bgColor='light'>
        <MDBContainer>
          {/* <MDBNavbarBrand href='#'>Brand</MDBNavbarBrand> */}

          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowBasic(!showBasic)}>
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
              {blog_mega_menu?.map((val) => {
                const { category_slug } = val;
                return (
                  <MDBNavbarItem>
                    {/* Navbar dropdown */}

                    <li className='nav-item dropdown dropdown-hover position-static'>
                      <Link
                        className='nav-link dropdown-toggle'
                        to={`blog/category/${category_slug}`}
                        id='navbarDropdown'
                        role='button'
                        data-mdb-toggle='dropdown'
                        aria-expanded='false'>
                      { loading ? 
                      <ContentLoader viewBox="0 0 500 420" height={420} width={500} {...props}>
                   <rect x="25" y="92" rx="0" ry="0" width="113" height="36" />
                </ContentLoader>:
                  val?.category_name}
                      </Link>
                      {/* Dropdown menu */}
                      <div
                        className='dropdown-menu  mt-0'
                        aria-labelledby='navbarDropdown'
                        style={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                        }}>
                        {val?.blog_subcategory?.map((val) => {
                          const { sub_category_slug } = val;
                          return (
                            <ul className='sub-menu-item'>
                              <Link
                                to={`/blog/category/${category_slug}/${sub_category_slug}`}
                                className='text-center text-dark hover_effect'>
                                {val?.sub_category_name}
                              </Link>
                            </ul>
                          );
                        })}
                      </div>
                    </li>
                  </MDBNavbarItem>
                );
              })}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default BlogMegaMenu;
