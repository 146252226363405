import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from "../../frontend/Loader/Loader";
import moment from 'moment'
import {Helmet} from "react-helmet";

const ViewProductWrapper = () => {
  const { id } = useParams();

  const [singleProduct, setSingleProduct] = useState([]);
  console.log(singleProduct)
  const [loading, setloading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    axios.get(`/product/view/${id}`).then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setSingleProduct(res.data.view_product);
      }
      setloading(false);
    }
    }).catch(error => {});
  
  return () => {
   isMounted = false
 }
  }, []);

  useEffect(() => {
    // initialize datatable
    if (!loading) {
      const script = document.createElement("script");
      script.src = "/dist/js/custom.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [loading]);
  if (loading) {
    return  <Loader/>
  }
  return (
    <>
        {/* seo title */}
        <Helmet>
        <title> View || Product</title>
        </Helmet>


      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Product' pageTitle='Product / View' />
      {/* Main content */}
      <section className='content'>
        {/* Default box */}
        <div className='card card-solid'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <h3 className='d-inline-block d-sm-none product_name'>
                  {singleProduct.product_name}
                </h3>
                <div className='col-12'>
                  <img
                    src={
                      singleProduct?.product_img == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${singleProduct?.product_img}`
                    }
                    style={{ width: "90%", height: "350px" }}
                    className='img-fluid product-image'
                    alt='Product'
                  />
                </div>
                <div className='col-12 product-image-thumbs'>
                  <div className='product-image-thumb'>
                    <img
                      src={
                        singleProduct?.product_img == null
                          ? null
                          : `${process.env.REACT_APP_API_URL}${singleProduct?.product_img}`
                      }
                      alt='Product'
                    />
                  </div>
                  {singleProduct?.product_gallary_img?.map((val) => {
                    return (
                      <>
                        <div className='product-image-thumb' key={val.id}>
                          <img
                            src={
                              val?.gallary_img == null
                                ? null
                                : `${process.env.REACT_APP_API_URL}${val?.gallary_img}`
                            }
                            alt='Product'
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className='col-12 col-sm-6'>
                <h4 className='product_name border-bottom py-2'>
                  {singleProduct?.product_name}
                </h4>
                <div className='product_code border-bottom'>
                  <h5>
                    Product-code: &nbsp;
                    <span
                      className='font-weight-bold'
                      style={{ fontSize: "14px" }}>
                      {singleProduct?.product_code}
                    </span>
                  </h5>
                </div>

                <div>
                  <div className='available_color_qty_size mt-1'>
                    <h5>Available:</h5>
                    <div className='row'>
                      {singleProduct?.product_colors?.map((val) => {
                        return (
                          <>
                            <div className='col-md-3'>
                              <div className='border p-2'>
                                <div
                                  className='btn-group btn-group-toggle'
                                  data-toggle='buttons'>
                                  <label className='btn btn-default text-center'>
                                    <input
                                      type='radio'
                                      name='color_option'
                                      id='color_option_a2'
                                      autoComplete='off'
                                    />
                                    {val.color?.color_name.toLowerCase()}
                                    <br />
                                    <i
                                      className={`fas fa-circle fa-2x text-${val.color?.color_name.toLowerCase()}`}
                                    />
                                  </label>
                                </div>
                                <div className='qty border'>
                                  <h5>
                                    qty: &nbsp;
                                    <span
                                      className='font-weight-bold'
                                      style={{ fontSize: "14px" }}>
                                      {val.qty}
                                    </span>
                                  </h5>
                                </div>
                                <div className='size border'>
                                  <h5>
                                    Size: &nbsp;
                                    <span
                                      className='font-weight-bold'
                                      style={{ fontSize: "14px" }}>
                                      {val.size}
                                    </span>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className='brand border-bottom'>
                  <h5>
                    Brand: &nbsp;
                    <span
                      className='font-weight-bold'
                      style={{ fontSize: "14px" }}>
                      {singleProduct?.brand?.brand_name}
                    </span>
                  </h5>
                </div>

                <div className='product_demension mt-2'>
                  <h5>Product Dimension width x height:</h5>
                  <p className='font-weight-bold'>200px * 200px</p>
                </div>

                <div className='bg-primary rounded py-1 px-2 mt-3'>
                  <h6 className='mt-0'>
                    Discount-Price: ৳ {singleProduct?.discount_price}
                  </h6>
                  <h6 className='mt-0'>
                    Selling-Price: ৳ {singleProduct?.selling_price}
                  </h6>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <div className='category border-bottom py-1'>
                <h6 className='font-weight-bold d-inline'>Category: </h6>
                <span className='breadcrumd'>
                  {singleProduct?.category?.category_name}/
                  {singleProduct?.subcategory?.sub_category_name}/
                  {singleProduct?.soscategory?.sub_of_soscategory_name}
                </span>
              </div>

              <div className='meta_keyword border-bottom py-1'>
                <h6 className='font-weight-bold d-inline'>Meta Title: </h6>
                <span className='breadcrumd'>{singleProduct.meta_keyword}</span>
              </div>
              <div className='meta_description border-bottom py-1'>
                <h6 className='font-weight-bold d-inline'>
                  Meta Description:{" "}
                </h6>
                <span className='breadcrumd'>
                  {singleProduct.meta_description}
                </span>
              </div>
            </div>
            {/* ------------description ratting commnet---------- */}
            <div className='row mt-4'>
              <nav className='w-100'>
                <div className='nav nav-tabs' id='product-tab' role='tablist'>
                  <a
                    className='nav-item nav-link active'
                    id='product-desc-tab'
                    data-toggle='tab'
                    href='#product-desc'
                    role='tab'
                    aria-controls='product-desc'
                    aria-selected='true'>
                    Description
                  </a>
                  <a
                    className='nav-item nav-link'
                    id='product-comments-tab'
                    data-toggle='tab'
                    href='#product-comments'
                    role='tab'
                    aria-controls='product-comments'
                    aria-selected='false'>
                    Product Reviews
                  </a>
                  <a
                    className='nav-item nav-link'
                    id='product-rating-tab'
                    data-toggle='tab'
                    href='#product-rating'
                    role='tab'
                    aria-controls='product-rating'
                    aria-selected='false'>
                    Rating
                  </a>
                </div>
              </nav>
              <div className='tab-content p-3' id='nav-tabContent'>
                <div
                  className='tab-pane fade show active'
                  id='product-desc'
                  role='tabpanel'
                  aria-labelledby='product-desc-tab'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleProduct?.product_description,
                    }}
                  />
                </div>
                <div
                  className='tab-pane fade'
                  id='product-comments'
                  role='tabpanel'
                  aria-labelledby='product-comments-tab'>

            {/* /.card-comment */}
              <div className="card card-widget">
              {singleProduct?.verify_product_reviews?.map((review) => {
                      return (
                        <>
              <div className="card-header px-4 py-2">
                <div className="user-block">
                  <img className="img-circle"  src={`${process.env.REACT_APP_API_URL}${review.user?.profile_image}`} alt="user" />
                  <span className="username"><a href="##"> {review.user?.name}</a></span>
                  <span className="description">public - {moment(review.created_at).fromNow()}</span>
                </div>
                </div>
                <div class="card-body px-4 py-2 border-bottom">
                <p>rating: {review.rating}</p>
                <p>{review.review}</p>
                </div>
                </>
                      );
                    })}
              </div>
             {/* /.card-comment */}
               

                </div>
                <div
                  className='tab-pane fade'
                  id='product-rating'
                  role='tabpanel'
                  aria-labelledby='product-rating-tab'>
                  {" "}
                  Cras ut ipsum ornare, aliquam ipsum non, posuere elit. In hac
                  habitasse platea dictumst. Aenean elementum leo augue, id
                  fermentum risus efficitur vel. Nulla iaculis malesuada
                  scelerisque. Praesent vel ipsum felis. Ut molestie, purus
                  aliquam placerat sollicitudin, mi ligula euismod neque, non
                  bibendum nibh neque et erat. Etiam dignissim aliquam ligula,
                  aliquet feugiat nibh rhoncus ut. Aliquam efficitur lacinia
                  lacinia. Morbi ac molestie lectus, vitae hendrerit nisl.
                  Nullam metus odio, malesuada in vehicula at, consectetur nec
                  justo. Quisque suscipit odio velit, at accumsan urna
                  vestibulum a. Proin dictum, urna ut varius consectetur, sapien
                  justo porta lectus, at mollis nisi orci et nulla. Donec
                  pellentesque tortor vel nisl commodo ullamcorper. Donec varius
                  massa at semper posuere. Integer finibus orci vitae vehicula
                  placerat.{" "}
                </div>
              </div>
            </div>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default ViewProductWrapper;
