import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'


const EditCategoryWrapper = () => {
  const { id } = useParams();
 // update 
 const [updatecategories, setupdateCategories] = useState([]);

// image preview
const [previewImage, setPreviewImage] = useState(null);

 // rerendering axios insert data
 const [rerendering, setRerendering] = useState(false);
 const [loading, setloading] = useState(true);

 // backend validation check
 const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

 // get data
 useEffect(() => {
  let isMounted = true;
    if (isMounted) {
   axios.get(`/category/edit/${id}`).then((res) => {
     if (res.data.status === 200) {
      setupdateCategories(res.data.data);
     }
     setloading(false);
   }).catch(error => {});
  }
  return () => {
    isMounted = false
  }
 }, [rerendering]);

 // handleChange 
 const handleChange = (e) => {
   setupdateCategories({
     ...updatecategories,
     [e.target.name]: e.target.value,
   });
 };
 const handleChangeImage = (e) => {
  setupdateCategories({ ...updatecategories, [e.target.name]: e.target.files[0]});
  setPreviewImage(URL.createObjectURL(e.target.files[0]));
};

  // handleSubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("category_name", updatecategories.category_name);
    formData.append("category_slug", updatecategories.category_slug);
    formData.append("item_img", updatecategories.item_img);
    axios
      .post(`/category/update/${updatecategories.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setRerendering(!rerendering);
          setValidation_error({});
          setPreviewImage(null);
          
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  if (loading) {
    <h2>loading...</h2>;
  }
  return (
    <>
     {/* seo title */}
     <Helmet>
        <title> Edit || Categories</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Categories' pageTitle=' Edit / Category' />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Edit Category</h3>
                <div className='card-tools'>
                  <button
                    type='button'
                    className='btn btn-tool'
                    data-card-widget='collapse'
                    title='Collapse'>
                    <i className='fas fa-minus' />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <form onSubmit={handleSubmit} encType='multipart/form-data'>
                  <div className='form-group'>
                    <label htmlFor='inputName'>Category Name</label>
                    <input
                      type='text'
                      id='inputName'
                      name='category_name'
                      onChange={handleChange}
                      value={
                        updatecategories.category_name == null
                          ? ""
                          : updatecategories.category_name
                      }
                      className='form-control'
                      placeholder='Enter Category Name'
                    />
                  </div>
                  <span className='text-danger'>
                    {validation_error.category_name}
                  </span>
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Category Slug</label>
                    <input
                      type='text'
                      id='inputClientCompany'
                      className='form-control'
                      name='category_slug'
                      onChange={handleChange}
                      value={
                        updatecategories.category_slug == null
                          ? ""
                          : updatecategories.category_slug
                      }
                      placeholder='Enter Slug'
                    />
                  </div>
                  <span className='text-danger'>
                    {validation_error.category_slug}
                  </span>
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Category Icon</label>
                    <div class='input-group mb-3'>
                      <div class='custom-file'>
                        <input
                          type='file'
                          class='custom-file-input'
                          accept='image/*'
                          name='item_img'
                          onChange={handleChangeImage}
                          id='inputGroupFile01'
                        />
                        <label class='custom-file-label' for='inputGroupFile01'>
                          Choose file
                        </label>
                      </div>
                    </div>
                  </div>
                  {
                    validation_error.item_img &&
                    <span className='text-danger'>
                    {validation_error.item_img}
                    <br />
                  </span>
                  }
                  
                  
                  {previewImage && (
                    <img
                      alt='item-img'
                      width='60'
                      src={previewImage}
                    />
                  )}
                  {!previewImage && 
                  <img
                    alt='item-img'
                    width='60'
                    src={
                      updatecategories?.item_img == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${updatecategories?.item_img}`
                    }
                  />}
                  
                  
                  <br />
                  <button type='submit' class='btn btn-info my-2' disabled={disableSubmitBtn}>
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>{" "}
                  &nbsp;
                  <Link to='/our-dashboard/all/categories'>
                    <button type='submit' class='btn btn-info'
                    
                    >
                      Back <i class='fas fa-angle-double-left'></i>
                    </button>
                  </Link>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
}

export default EditCategoryWrapper