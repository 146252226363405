import React from 'react'
import ViewUserSec from '../../../../Components/admin/UserSection/ViewUserSec'

const ViewUsers = () => {
  return (
    <>
    <ViewUserSec/>
    </>
  )
}

export default ViewUsers