import React, { useState,useEffect } from "react";
import EditorEdit from "../../Editor/Editor";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from '../../../frontend/Loader/Loader';

const FooterSec = () => {
  const [insertfooterSetting, setinsertfooterSetting] = useState({
    privacy_policy: "",
    return_policy: "",
    terms_conditions: "",
    terms_of_service: "",
    terms_of_uses: "",
    copyright: "",
    faq: "",
    how_to_buy: "",
    help: "",
    sitemap: "",
    forum: "",
    community: "",
  });
  const {
    privacy_policy,
    return_policy,
    terms_conditions,
    terms_of_service,
    terms_of_uses,
    copyright,
    faq,
    how_to_buy,
    help,
    sitemap,
    forum,
    community,
  } = insertfooterSetting;

  const [footerSetting, setfooterSetting] = useState([])

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(null);
  const [loading, setloading] = useState(true);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

   // get data
   useEffect(() => {
    axios.get("/setting/footer-content").then((res) => {
      if (res.data.status === 200) {
        setfooterSetting(res.data.footer_setting);
      }
      setloading(false);
    });
  }, [rerendering]);





  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
   setDisableSubmitBtn(true);
    if(footerSetting == null ){
      const data = insertfooterSetting;
      axios
        .post(`/setting/footer-content/insert`, data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
          }
        })
        .catch((err) => {});
    }else{
      const data = footerSetting;
      axios
        .post(`/setting/footer-content/update/${footerSetting.id}`, data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              theme: 'colored',
              transition: Zoom,
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setRerendering(res.data.message)
          }
        })
        .catch((err) => {});
    }
    
    
  };

  const handlefooter_1 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, privacy_policy: newtext });
    }else{
      setfooterSetting({ ...footerSetting, privacy_policy: newtext });
    }
    
  };
  const handlefooter_2 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, return_policy: newtext });
    }else{
      setfooterSetting({ ...footerSetting, return_policy: newtext });
      
    }
    
  };
  const handlefooter_3 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, terms_conditions: newtext });
    }else{
      setfooterSetting({ ...footerSetting, terms_conditions: newtext });
    
    }
    
  };
  const handlefooter_4 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, terms_of_service: newtext });
    }else{
      setfooterSetting({ ...footerSetting, terms_of_service: newtext });
      
    }
  
  };
  const handlefooter_5 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, terms_of_uses: newtext });
    }else{
      setfooterSetting({ ...footerSetting, terms_of_uses: newtext });
     
    }
    
  };
  const handlefooter_6 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, copyright: newtext });
    }else{
      setfooterSetting({ ...footerSetting, copyright: newtext });
      
    }
  
  };
  const handlefooter_7 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, faq: newtext });
    }else{
      setfooterSetting({ ...footerSetting, faq: newtext });
    }
    
  };
  const handlefooter_8 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, how_to_buy: newtext });
    }else{
      setfooterSetting({ ...footerSetting, how_to_buy: newtext });
    }
 
  };
  const handlefooter_9 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, help: newtext });
    }else{
      setfooterSetting({ ...footerSetting, help: newtext });
    }
  
  };
  const handlefooter_10 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, sitemap: newtext });
    }else{
      setfooterSetting({ ...footerSetting, sitemap: newtext });
    }
   
  };
  const handlefooter_11 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, forum: newtext });
    }else{
      setfooterSetting({ ...footerSetting, forum: newtext });
    }

  };
  const handlefooter_12 = (newtext: any) => {
    if(footerSetting == null){
      setinsertfooterSetting({ ...insertfooterSetting, community: newtext });
    }else{
      setfooterSetting({ ...footerSetting, community: newtext });
    }
   
  };

  if(loading){
    return <Loader />
  }
  return (
    <>
         {/* seo title */}
       <Helmet>
        <title>Footer || Setting</title>
        </Helmet>

          {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Footer" />
      {/* Main content */}
      <section className="content footer_setting">
        <div className="container">
          <form  onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-outline">
                  <h5 className="p-2">Privacy Policy</h5>

                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="privact_policy"
                      handleChange={handlefooter_1}
                      value={footerSetting == null ? privacy_policy : footerSetting.privacy_policy == null ? "" :footerSetting.privacy_policy}
                    />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Return Policy</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="return_policy"
                      handleChange={handlefooter_2}
                      value={footerSetting == null ? return_policy : footerSetting.return_policy == null ? "" : footerSetting.return_policy }
                    />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Terms & Conditions</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="terms_conditions"
                      handleChange={handlefooter_3}
                      value={footerSetting == null ? terms_conditions : footerSetting.terms_conditions == null ? "" :footerSetting.terms_conditions}
                    />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Terms of Service</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="terms_service"
                      handleChange={handlefooter_4}
                      value={footerSetting == null ? terms_of_service : footerSetting.terms_of_service == null ? "" :footerSetting.terms_of_service}
                    />
                  </div>
                </div>
                <div className="card card-primary card-outline">
                  <h5 className="p-2">Terms of Use</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="trems_use"
                      handleChange={handlefooter_5}
                      value={footerSetting == null ? terms_of_uses : footerSetting.terms_of_uses == null ? "" :footerSetting.terms_of_uses}
                    />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Copyright</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="copy_right"
                      handleChange={handlefooter_6}
                      value={footerSetting == null ? copyright
                   : footerSetting.copyright == null ? "" :   footerSetting.copyright }              />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">FAQ</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="faq"
                      handleChange={handlefooter_7}
                      value={footerSetting == null ? faq
          : footerSetting.faq == null ? "" : footerSetting.faq}          />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">How to Buy</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="how_to_buy"
                      handleChange={handlefooter_8}
                      value={footerSetting == null ? how_to_buy
   : footerSetting.how_to_buy == null ? "" : footerSetting.how_to_buy  }               />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Help</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="help"
                      handleChange={handlefooter_9}
                      value={footerSetting == null ? help
         : footerSetting.help == null ? "" : footerSetting.help}           />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Sitemap</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      handleChange={handlefooter_10}
                      value={footerSetting == null ? sitemap
      : footerSetting.sitemap == null ? "" :   footerSetting.sitemap   }           name="sitemap"
                    />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Forum</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="forum"
                      handleChange={handlefooter_11}
                      value={footerSetting == null ? forum
        : footerSetting.forum == null ? "" :  footerSetting.forum   }        />
                  </div>
                </div>

                <div className="card card-primary card-outline">
                  <h5 className="p-2">Community</h5>
                  <div className="card-body box-profile border">
                    <EditorEdit
                      name="community"
                      handleChange={handlefooter_12}
                      value={footerSetting == null ? community
    : footerSetting.community == null ? "" :   footerSetting.community }              />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 py-4">
                <button
                  type="submit"
                  className="btn btn-success btn-block mx-auto w-75"
                  disabled={disableSubmitBtn}
                >
                  <i className="fas fa-paper-plane"></i> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default FooterSec;
