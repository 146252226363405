import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRipple,
} from "mdb-react-ui-kit";
import { FcFolder } from "react-icons/fc";
import { VscComment } from "react-icons/vsc";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const HomeBlogSection = (props) => {

  const [loading, setloading] = useState(true);
  const [blogPost, setBlogPost] =
    useState([]);

  

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
      const res = await axios.get('/blog/post/recent')
      if(isMounted){
        if (res.data.status === 200) {
          setBlogPost(
            res.data.blog_recent_post
          );
        }
        setloading(false);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }

   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);


  return (
    <>
        <div className='py-4'>
        <div className='container-fluid'>
        <Title section_title='Blog' />
          <Titleimg />
              <div className='py-2'>
                <div className='row g-2'>
                  {loading
                    ? Array(6)
                        .fill()
                        .map((val, idx) => {
                          return (
                            <div className='col-10 col-md-2 mt-2' key={idx}>
                              <ContentLoader
                                width='100%'
                                height='100%'
                                viewBox='0 0 450 360'
                                backgroundColor='#f0f0f0'
                                foregroundColor='#dedede'
                                {...props}>
                                <rect
                                  x='43'
                                  y='304'
                                  rx='4'
                                  ry='4'
                                  width='271'
                                  height='9'
                                />
                                <rect
                                  x='44'
                                  y='323'
                                  rx='3'
                                  ry='3'
                                  width='119'
                                  height='6'
                                />
                                <rect
                                  x='42'
                                  y='77'
                                  rx='10'
                                  ry='10'
                                  width='388'
                                  height='217'
                                />
                              </ContentLoader>
                            </div>
                          );
                        })
                    : blogPost?.map((val) => {
                        return (
                          <div className='col-10 col-md-2 mt-2 mx-auto' key={val.id}>
                            <MDBCard className='border'>
                              <MDBRipple
                                rippleColor='light'
                                rippleTag='div'
                                className='bg-image hover-overlay'>
                                <Link
                                  to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                  <LazyLoadImage
                                    effect="blur"
                                    width="100%"
                                    height="auto"
                                    // placeholderSrc='./assest/image/loader/placeholder.jpg'
                                    src={
                                      val?.blog_image == null
                                        ? null
                                        :  `${process.env.REACT_APP_API_URL}${val?.blog_image}`
                                    }
                                    
                                    alt='blog-post'
                                  />
                                </Link>
                              </MDBRipple>
                              <MDBCardBody>
                                <MDBCardTitle>
                                  <Link
                                  className='text-dark'
                                    to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                    {val.heading}
                                  </Link>
                                </MDBCardTitle>

                                <MDBCardText>
                                  <div className='post_info'>
                                    <span className='date px-2'>
                                      <i class='fas fa-calendar-alt pe-1'></i>
                                      {moment(val?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                    <Link to='/blog' className='text-dark'>
                                      <span className='post-admin'>
                                        <i class='fas fa-user-alt pe-1'></i>
                                        {val?.name}
                                      </span>
                                    </Link>
                                    <Link
                                      to={`/blog/category/${val?.blog_category?.category_slug}`}>
                                      <span className='post_cate_gory px-1'>
                                        <FcFolder />
                                        &nbsp;
                                        {val?.category_name}
                                      </span>
                                    </Link>
                                    <Link to='/blog' className='text-dark'>
                                      <span className='cate_gory ps-2'>
                                        <VscComment />
                                      </span>
                                    </Link>
                                  </div>
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </div>
                        );
                      })}
              </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBlogSection;
