import React from 'react'
import AddSliderSec from '../../../../Components/admin/Settings/Slider/AddSliderSec'


const AddSliderSetting = () => {
  return (
    <>
    <AddSliderSec/>
    </>
  )
}

export default AddSliderSetting