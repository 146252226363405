import React from 'react'
import FooterSec from '../../../../Components/admin/Settings/Footer/FooterSec'

const FooterSetting = () => {
  return (
    <>
    <FooterSec/>
    </>
  )
}

export default  FooterSetting