import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MDBBtn, MDBIcon,MDBSpinner } from "mdb-react-ui-kit";
import { Formik } from "formik";
import {toast,Zoom } from "react-toastify";
import axios from "axios";
import SocialLogin from "./SocialLogin";

const Register = (props) => {
  const [validation_error, setValidation_error] = useState([]);
  const [realTimeValidation, setRealTimeValidation] = useState();
   //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [passwordShown, setpasswordShown] = useState(false);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');


  const togglePasswordVisiblity = () => {
    setpasswordShown(passwordShown ? false : true);
  };

  // comfrim password show hide
  const [comfirm_passwordShown, setcomfirm_passwordShown] = useState(false);

  const toggle_comfrim_PasswordVisiblity = () => {
    setcomfirm_passwordShown(comfirm_passwordShown ? false : true);
  };



 // get data
 useEffect(() => {
  let isMounted = true;
  
  const fetchData = async () => {
    try {
      if(phone || email ===! ''){
        const res = await axios.get("/real-time-validate");
        if(isMounted){
          if (res.data.status === 200) {
            setRealTimeValidation(res.data.validation_errors);
          }
        }  
      }
    
   } catch (e) {
      console.log(e);
   }
    
 }


 fetchData();
  
  return () => {
    isMounted = false;
  }
}, [phone, email]);

 





const handleChange = (e)=>{
  setEmail({...email, [e.target.name]: e.target.value});
  setPhone({...phone, [e.target.name]: e.target.value});
 
}








  return (
    <>

    

      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          company_name: "",
          password: "",
          password_confirmation: "",
          select_role: "",
          check: "",
        }}
        validate={(values) => {
          const errors = {};

          // ------  registration validation code    ------
          // fullName validate condition
          if (!values.name) {
            errors.name = "Please Input Your Full Name";
          } else if (values.name.length > 50) {
            errors.name = "Fullname maximum length of 50 characters";
          } else if (!/^[a-zA-Z ]*$/i.test(values.name)) {
            errors.name = "Invalid  Full Name";
          }

          // Email validate condition
          if (!values.email) {
            errors.email = "Please Input Your Email Address";
          } else if (values.email.length > 100) {
            errors.email = "Email maximum length of 50 characters";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid Email Address";
          } else if (realTimeValidation.some((val) => val.email === values.email)) {
            errors.email = "The Email has Already been taken";
          }

          // Phone validate condition
          if (!values.phone) {
            errors.phone = "Please Input Your Phone Number";
          } else if (values.phone.length < 11) {
            errors.phone = "Phone Number minimum length of 11 characters";
          } else if (values.phone.length > 15) {
            errors.phone = "Phone Number maximum length of 15 characters";
          } else if (!/^[0-9\b]+$/i.test(values.phone)) {
            errors.phone = "Invalid Phone Number";
          } else if (realTimeValidation.some((val) => val.phone === values.phone)) {
            errors.phone = "The Phone has Already been taken";
          }

          // company_name validate condition
          if (values.company_name.length > 100) {
            errors.company_name =
              "Company Name maximum length of 15 characters";
          }

          // comfirmpassword validate condition
          if (!values.password) {
            errors.password = "Please Input Your Password";
          } else if (values.password.length > 50) {
            errors.password = "Password maximum length of 50 characters";
          } else if (values.password.length < 8) {
            errors.password = "Password manimum length of 8 characters";
          }

          // password_confirmation validate condition
          if (!values.password_confirmation) {
            errors.password_confirmation = "Please Input Your Comfirm Password";
          } else if (values.password_confirmation.length > 50) {
            errors.password_confirmation =
              "Comfirm Password maximum length of 50 characters";
          } else if (values.password_confirmation.length < 8) {
            errors.password_confirmation =
              "Comfirm Password manimum length of 8 characters";
          } else if (values.password !== values.password_confirmation) {
            errors.password_confirmation = "Password did not match";
          }

          // select_role validate condition
          if (!values.select_role) {
            errors.select_role = "Please Select a Role";
          }
          // select_role validate condition
          if (!values.check) {
            errors.check = "";
          }

          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          setDisableSubmitBtn(true);
          const data = values;
          axios.get("/sanctum/csrf-cookie").then((response) => {
            axios
              .post("/register", data)
              .then((res) => {
                if (res.data.status === 200) {
                  document.getElementById("resetForm").reset();
                  resetForm();
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 300,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                  props.handleEmailVerify(true, res.data.email);
                  localStorage.setItem("email_verify", res.data.email);
                } else {
                  setValidation_error(res.data.validation_errors);
                  setDisableSubmitBtn(false);
                }
              })
              .catch((err) => {});
          });
        }}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit} id='resetForm'>
            <div>
              {/* <!-- Full Name input --> */}
              <div className='input-group'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  placeholder='Full Name *'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  onBlur={formik.handleBlur}
                />
                <label className='input-group-text' htmlFor='name'>
                  <i className='fas fa-user'></i>
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.name ? (
                <span className='text-danger'>{validation_error.name}</span>
              ) : (
                formik.touched.name &&
                formik.errors.name && (
                  <span className='text-danger'>{formik.errors.name}</span>
                )
              )}
            </div>
            {/* <!-- Email input --> */}
            <div>
              <div className='input-group mt-3'>
                <input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Email *'
                  className='form-control'
                  onChange={(e)=> {formik.handleChange(e); handleChange(e)}}
                  value={formik.values.email}
                  touched={formik.touched.email}
                  onBlur={formik.handleBlur}
                />
                <label
                  className='input-group-text'
                  htmlFor='email'
                  style={{ paddingLeft: "10px" }}>
                  <i className='fas fa-envelope'></i>
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.email ? (
                <span className='text-danger'>{validation_error.email}</span>
              ) : (
                formik.touched.email &&
                formik.errors.email && (
                  <span className='text-danger'>{formik.errors.email}</span>
                )
              )}
            </div>
            {/* <!-- Phone input --> */}
            <div>
              <div className='input-group mt-3'>
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Phone *'
                  className='form-control'
                  onChange={(e)=> {formik.handleChange(e); handleChange(e)}}
                  value={formik.values.phone}
                  touched={formik.touched.phone}
                  onBlur={formik.handleBlur}
                />
                <label
                  className='input-group-text'
                  htmlFor='phone'
                  style={{ paddingLeft: "15px" }}>
                  <i className='fas fa-mobile-alt'></i>
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.phone ? (
                <span className='text-danger'>{validation_error.phone}</span>
              ) : (
                formik.touched.phone &&
                formik.errors.phone && (
                  <span className='text-danger'>{formik.errors.phone}</span>
                )
              )}
            </div>
            {/* <!-- Company input --> */}
            <div>
              <div className='input-group mt-3'>
                <input
                  type='text'
                  id='company_name'
                  name='company_name'
                  placeholder='Company Name (optional)'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.company_name}
                  touched={formik.touched.company_name}
                  onBlur={formik.handleBlur}
                />
                <label
                  className='input-group-text'
                  htmlFor='company_name'
                  style={{ paddingLeft: "8px" }}>
                  <MDBIcon fas icon='city' />
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.company_name ? (
                <span className='text-danger'>
                  {validation_error.company_name}
                </span>
              ) : (
                formik.touched.company_name &&
                formik.errors.company_name && (
                  <span className='text-danger'>
                    {formik.errors.company_name}
                  </span>
                )
              )}
            </div>
            {/* <!-- Password input --> */}
            <div>
              <div className='input-group mt-3'>
                <input
                  type={passwordShown ? "text" : "password"}
                  id='Password'
                  placeholder='Password'
                  name='password'
                  autoComplete='off'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.Password}
                  touched={formik.touched.Password}
                  onBlur={formik.handleBlur}
                />
                <label className='input-group-text' htmlFor='Password' onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <i
                      className='fas fa-lock-open'
                      ></i>
                  ) : (
                    <i
                      className='fas fa-lock'
                     ></i>
                  )}
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.password ? (
                <span className='text-danger'>{validation_error.password}</span>
              ) : (
                formik.touched.password &&
                formik.errors.password && (
                  <span className='text-danger'>{formik.errors.password}</span>
                )
              )}
            </div>
            {/* <!--Repeat password input --> */}
            <div>
              <div className='input-group  mt-3'>
                <input
                  type={comfirm_passwordShown ? "text" : "password"}
                  id='RepeatPassword'
                  placeholder='Comfirm Password'
                  name='password_confirmation'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                  touched={formik.touched.password_confirmation}
                  onBlur={formik.handleBlur}
                />
                <label
                  className='input-group-text ps-2'
                  htmlFor='RepeatPassword'  onClick={toggle_comfrim_PasswordVisiblity}>
                  {comfirm_passwordShown ? (
                    <i
                      className='far fa-eye'
                     ></i>
                  ) : (
                    <i
                      className='fas fa-eye-slash'
                      ></i>
                  )}{" "}
                </label>
                {/* <label className="input-group-text" htmlFor="inputGroupFile01"><i className="fas fa-lock"></i></label> */}
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.password_confirmation ? (
                <span className='text-danger'>
                  {validation_error.password_confirmation}
                </span>
              ) : (
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation && (
                  <span className='text-danger'>
                    {formik.errors.password_confirmation}
                  </span>
                )
              )}
            </div>
            {/* Please select a role input radio */}
            <div className='input-group  mt-3  d-flex '>
              <span className='fs-6 pe-3 text-dark'>Please select a role:</span>
              <div className='form-check pe-3'>
                <label htmlFor='buyer'>
                  <input
                    type='radio'
                    name='select_role'
                    value='Buyer'
                    onChange={formik.handleChange}
                    checked={formik.values.select_role === "Buyer"}
                    className='form-check-input'
                    id='buyer'
                  />{" "}
                  Buyer
                </label>
              </div>

              <div className='form-check pe-3'>
                <label htmlFor='Seller'>
                  <input
                    type='radio'
                    name='select_role'
                    value='Seller'
                    onChange={formik.handleChange}
                    checked={formik.values.select_role === "Seller"}
                    className='form-check-input'
                    id='Seller'
                  />{" "}
                  Seller
                </label>
              </div>

              <div className='form-check pe-3'>
                <label htmlFor='Both'>
                  <input
                    type='radio'
                    name='select_role'
                    value='Both'
                    onChange={formik.handleChange}
                    checked={formik.values.select_role === "Both"}
                    className='form-check-input'
                    id='Both'
                  />
                  Both
                </label>
              </div>
            </div>
            <div>
              {/* errors will return when field validation fails  */}
              {validation_error.select_role ? (
                <span className='text-danger'>
                  {validation_error.select_role}
                </span>
              ) : (
                 formik.touched.select_role &&
                ((
                  <span className='text-danger'>
                    {formik.errors.select_role}
                  </span>
                ))
              )}
            </div>

            {/* <!-- Checkbox --> */}
            <div className='form-check  d-flex justify-content-center'>
              {/* <!-- Checked checkbox --> */}
              <div class='form-check'>
                <input
                  class='form-check-input'
                  type='checkbox'
                  value='true'
                  name='check'
                  onChange={formik.handleChange}
                  id='invalidCheck2'
                  required
                />
                <label class='form-check-label' htmlFor='invalidCheck2'>
                  I Agree <Link to=''>Terms & Conditions</Link>
                </label>
              </div>
            </div>
            <div>
              <MDBBtn
                type='submit'
                className='login-submit-btn btn btn-danger btn-rounded btn-block mb-2 mt-2 mx-auto'
                color='danger'
                disabled={disableSubmitBtn}
                >
                Register
                {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
              </MDBBtn>
            </div>

            {/* social sign in */}
            <div className='text-center social'>
            <SocialLogin />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Register;
