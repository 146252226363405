import React from 'react'
import AddSubCategory from '../../../../Components/admin/Sub_Categories_Section/AddSubCategory'

const AddSubCategories = () => {
  return (
    <>
    <AddSubCategory/>
    </>
  )
}

export default AddSubCategories