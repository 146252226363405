import React,{useEffect, useState} from "react";
import "./featuredcate.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import ContentLoader from "react-content-loader"
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FeaturedCategories = (props) => {
   //  get featured data
   const [featured, setFeatured] = useState([]);
   const [loading, setloading] = useState(true);
  


// get data
useEffect(() => {
  let isMounted = true;
  
  const fetchData = async () => {
    try {
      const res = await  axios.get("/featured/all");
          if(isMounted){
      if (res.data.status === 200) {
        setFeatured(res.data.featured);
       }
       setloading(false);
    }  
   } catch (e) {
      console.log(e);
   }
    
 }


 fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);




   
  return (
    <>
            {/* seo meta tag  start */}
            {
          featured?.small_banner?.map(val=>{
        <Helmet>
         <meta name='keywords' content={val?.title} />
        <meta name='description' content={val?.title} />
        <meta name='subject' content={val?.title} />
        <meta name='topic' content={val?.title} />
       

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={val?.title}  />
        <meta
          itemprop="description"
          content={val?.title}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
         <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_1}`}
        />
         <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_2}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={val?.title}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={val?.title} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_1}`}
        />
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_2}`}
        />
        <meta
          property='og:image:alt'
          content={val?.img_alt}
        />
        <meta
          name='og:description'
          content={val?.title}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='val?.summary_large_image' />
        <meta
          name='twitter:title'
          content={val?.title} 
        />
        <meta
          name='twitter:description'
          content={val?.title}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_1}`}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_2}`}
        />
        <meta
          name='twitter:image:alt'
          content={val?.img_alt}
        />
      </Helmet> 
          })
        }
        
      {/* seo meta tag  end */}



      <section className='featured_categories overflow-hidden py-2'>
        <div className='container-fluid'>
          <Title section_title='Featured Categories' />
          <Titleimg />
          <div className="row pb-3 g-2 bg-white shadow-2 hover-shadow">
            <div className='col-lg-4 col-md-4 col-10 mx-auto mt-2'>
              {
                loading ?
                <ContentLoader 
                  speed={2}
                  width='100%'
                  height='100%'
                  viewBox="0 0 400 140"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="5" y="12" rx="3" ry="3" width="365" height="110" />
                </ContentLoader>
              :
              <div className='featured_card'>
                <Link to={featured?.big_banner?.url_1}>
                  <LazyLoadImage
                    effect="blur"
                    height="188px"
                    width="100%"
                   
                    src={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_1}`}
                    alt='featured_banar'
                    className='long-banner  mx-auto d-block hover-shadow border rounded'
                  />
                  </Link>
                  <br />
                  <Link to={featured?.big_banner?.url_2}>
                  <LazyLoadImage
                   effect="blur"
                    height="188px"
                    width="100%"
                   
                    src={`${process.env.REACT_APP_API_URL}${featured?.big_banner?.banner_2}`}
                    alt='featured_banar'
                    className='long-banner  mx-auto d-block  hover-shadow border rounded'
                  />
                </Link>
              </div>
              }
            </div>

            <div className='col-lg-8 col-md-8 col-10 mx-auto  mt-4'>
              <div className='row g-2'>
                {loading ?
              Array(4)
                .fill()
                .map((val, idx) => {
                  return (
                <div  className='col-lg-3 col-md-3 col-10 mx-auto mt-2' 
                  key={idx}>
                      <ContentLoader 
                    speed={2}
                    width='100%'
                    height='100%'
                    viewBox="0 0 606 300"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <rect x="0" y="3" rx="3" ry="3" width="100%" height="250" />
                  </ContentLoader>
                    </div>
                    );})
                  :
                  featured?.small_banner?.map((val) => {
                  return (
                    <div
                      className='col-lg-3 col-md-3 col-10 mx-auto mt-2'
                      key={val.id}>
                      <Link to='' className='text-center text-dark'>
                        <div className='featured_card shadow-4 border rounded'>
                          <h6>{val?.title}</h6>
                          <LazyLoadImage
                            effect="blur"
                           width="100%"
                           height="150px"
                            placeholderSrc='./assest/image/loader/placeholder.jpg'
                            src={`${process.env.REACT_APP_API_URL}${val?.image}`}
                            alt={val?.img_alt}
                          />
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedCategories;
