import React, { useState } from "react";
import axios from "axios";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb';
import {toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";

const AddAdminSec = () => {

  const [insertAdmin, setInsertAdmin] = useState({
  name:'',
  email:'',
  phone:'',
  password:'',
  password_confirmation:'',
  });

    // backend validation check
    const [validation_error, setValidation_error] = useState([]);
 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);


 const handleChange = (e) => {
  setInsertAdmin({ ...insertAdmin, [e.target.name]: e.target.value, });
};



  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const data = insertAdmin;
    axios
      .post(`/admin/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          document.getElementById("resetForm").reset();
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      {/* seo title */}
      <Helmet>
        <title>Add-Admin</title>
      </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Admin' pageTitle='Add-Admin' />
      {/* Main content */}
      <section className='content'>
        <div className='row'>
          <div className='col-md-8 mx-auto'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>Add Admin</h3>
                <div className='card-tools'>
                  <button
                    type='button'
                    className='btn btn-tool'
                    data-card-widget='collapse'
                    title='Collapse'>
                    <i className='fas fa-minus' />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <form onSubmit={handleSubmit} id='resetForm'>
                  <div className='form-group'>
                    <label htmlFor='inputName'>Full Name</label>
                    <input
                      type='text'
                      onChange={handleChange}
                      name='name'
                      id='inputName'
                      className='form-control'
                      placeholder='Full Name'
                    />
                  </div>
                  {validation_error.name && (
                    <span className='text-danger'>{validation_error.name}</span>
                  )}

                  <div className='form-group'>
                    <label htmlFor='inputEmail'>Email</label>
                    <input
                      type='email'
                      onChange={handleChange}
                      name='email'
                      id='inputEmail'
                      className='form-control'
                      placeholder='Email'
                    />
                  </div>
                  {validation_error.email && (
                    <span className='text-danger'>
                      {validation_error.email}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputPhone'>Phone</label>
                    <input
                      type='tel'
                      onChange={handleChange}
                      name='phone'
                      id='inputPhone'
                      className='form-control'
                      placeholder='Phone'
                    />
                  </div>
                  {validation_error.phone && (
                    <span className='text-danger'>
                      {validation_error.phone}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputPassword'>Password</label>
                    <input
                      type='password'
                      onChange={handleChange}
                      name='password'
                      id='inputPassword'
                      className='form-control'
                      placeholder='Password'
                    />
                  </div>
                  {validation_error.password && (
                    <span className='text-danger'>
                      {validation_error.password}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputComfrimPassword'>Comfirm</label>
                    <input
                      type='password'
                      onChange={handleChange}
                      name='password_confirmation'
                      id='inputComfirmmPassword'
                      className='form-control'
                      placeholder='Comfirm Password'
                    />
                  </div>
                  {validation_error.password_confirmation && (
                    <span className='text-danger'>
                      {validation_error.password_confirmation}
                    </span>
                  )}
                  <button
                    type='submit'
                    class='btn btn-info btn-block w-75 mx-auto'
                    disabled={disableSubmitBtn}>
                    <i class='fas fa-paper-plane'></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
}

export default AddAdminSec