import React from 'react';
import CartSection from '../../../Components/frontend/CartSection/CartSection';


const Cart = () => {
  return (
      <>
      <CartSection/>
      </>
  )
};

export default Cart;
