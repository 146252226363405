import React from 'react'
import AllSOSCategory from '../../../../Components/admin/SOS_Categories_Section/AllSOSCategory'

const AllSOSCategories = () => {
  return (
    <>
    <AllSOSCategory/>
    </>
  )
}

export default AllSOSCategories