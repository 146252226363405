export const SummaryData = [
    {
        date:"26-2-2022",
        time: "50min",
        getLink:"http://ourrajshahi.com/",
        Uniquevisitor: "20",
        visit: "100"
    },
    {
        date:"26-2-2022",
        time: "50min",
        getLink:"http://ourrajshahi.com/",
        Uniquevisitor: "20",
        visit: "100"
    },
    {
        date:"26-2-2022",
        time: "50min",
        getLink:"http://ourrajshahi.com/",
        Uniquevisitor: "20",
        visit: "100"
    }
]