export const Collectiondata = [
  {
    img: "../assest/image/location-search/2.jpg",
    store_name: "Store",
    store: "Store",
    product: "",
    store_details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. consectetur adipisicing elit.",
  },
  {
    img: "../assest/image/location-search/2.jpg",
    store_name: "Store",
    store: "Store",
    product: "",
    store_details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. consectetur adipisicing elit.",
  },
  {
    img: "../assest/image/location-search/2.jpg",
    store_name: "Store",
    store: "Store",
    product: "",
    store_details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. consectetur adipisicing elit.",
  },
  {
    img: "../assest/image/location-search/2.jpg",
    store_name: "Store",
    store: "Store",
    product: "",
    store_details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. consectetur adipisicing elit.",
  },
];
