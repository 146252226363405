import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';

const ReplyWithReview = () => {

  const { id } = useParams();

   //  data
   const [data, setData] = useState([]);
   // rerendering axios insert data
 const [rerendering, setRerendering] = useState(false);
   const [loading, setloading] = useState(true);

   // get data
   useEffect(() => {
    let isMounted = true;
    
     axios.get(`/product/reply-with-review/${id}`).then((res) => {
      if (isMounted) {
       if (res.data.status === 200) {
        setData(res.data.reply_with_review);
       }else if(res.data.status === 404){
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
       }
       setloading(false);
      }
     }).catch(error => {});
    
    return () => {
      isMounted = false
    }
   }, [rerendering]);
 
   useEffect(() => {
     // initialize datatable
     if (!loading) {
       const script = document.createElement("script");
       script.src = "/dist/js/custom.js";
       script.async = true;
       document.body.appendChild(script);
     }
   }, [loading]);
  

    // status active or deactive 
   const updateStatus = (id, status) => {
    const formData = new FormData();
    formData.append("status", status === 0 ? 1 : 0);
    axios
    .post(`/product/review/reply/update_status/${id}`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setRerendering(!rerendering);
          }
        })
        .catch((e) => {});
    };



 // handle delet item
   const handleDeleteItems = (id) => {
     axios
       .delete(`/product/review/reply/delete/${id}`)
       .then((res) => {
         if (res.data.status === 200) {
           toast.info(res.data.message, {
             position: "top-right",
             autoClose: 700,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           });
 
           setData(
            data.filter((item) => item.id !== id)
           );
         }
       })
       .catch((e) => {});
   };
 
   if (loading) {
     return  <Loader/>
   }

  return (
    <>
      {/* seo title */}
        <Helmet>
        <title> View-Reply || Product-Review</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Comment"
        pageTitle={`All/Commment/view-reply/${id}` }     />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                <th>
                  SL
                  </th>
                  <th>
                    Product
                  </th>
                  <th>
                    Review
                  </th>
                  <th >
                   Review-Reply
                  </th>
                  <th >
                    User
                  </th>
                  <th >
                   Status
                  </th>
                  <th >
                  Action
                  </th>
                  
                  
                  <th >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                
                 { data && data.map((val) => {
                  
                      return (
                        <tr key={val.id}>
                        <td >{val.id}</td>
                        <td >{val.product?.product_slug}</td>
                          <td >{val.product_review?.review}</td>
                          <td >
                            {val.review_reply}
                          </td>
                          <td >{val.user?.name}</td>
                          <td><span className="p-1 rounded bg-success">
                         {val.status === 1 ? "Actived" : "Deactived"} 
                        </span></td>
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(val.id, val.status);
                                      }}
                                      >
                          {val.status === 1 ? "Deatived Now" : "Active Now" }
                        </span>
                      </td>
                          
                          <td >
                          <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          </td>
                        </tr>
 );
                
})}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
    </>
  );
};

export default ReplyWithReview;
