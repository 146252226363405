import React, { useEffect, useState } from "react";
import "./HeaderTop.css";
import SocialMedia from "../SocialMedia/SocialMedia";
import axios from "axios";
const HeaderTop = () => {
  const [headertop, setHeadertop] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const res = await axios.get("/setting/header-top-content");
        if(isMounted){
          if (res.data.status === 200) {
            setHeadertop(res.data.header_top);
          }
        }
        
      } catch (e) {
         console.log(e);
      }
   }
  fetchData();
     return () => {
      isMounted = false;
    }
  }, []);


  return (
    <>
      <section
        className='header-top d-sm-none d-md-block d-lg-block bg-info overflow-hidden'
        id='raindrops-red'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-8  d-flex top-left justify-content-start'>
              <a className='text-white' href='mailto:info@ourrajshahi.com'>
                {/* <i className='fas fa-envelope hoverable'></i>&nbsp; */}
                {/* {headertop?.header_top_email} */}

                <i class="fas fa-info-circle"></i>&nbsp; 
                Website Under Construction
              </a>
              &nbsp;
              <a className='text-white'  href='tel://09611863686'>
                <i className='fas fa-phone-alt'></i>&nbsp;
                { headertop?.header_top_hotline}
              </a>
            </div>
            <div className='col-lg-4 col-md-4 d-flex top-right justify-content-end'>
              <SocialMedia />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HeaderTop;
