import React from 'react'
import NewsPortalSection from '../../../Components/frontend/NewsPortalSection/NewsPortalSection'

const NewsPortal = () => {
  return (
    <>
    <NewsPortalSection/>
    </>
  )
}

export default NewsPortal