import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {  toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
const EditFeaturedCategorisSec = () => {
  const { id } = useParams();

  const [update_SmallBanner, setUpdate_SmallBanner] = useState([]);

// image preview
  const [previewImage, setPreviewImage] = useState(null);
 
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  
   //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  
  // get data
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios.get(`/setting/featured-categories-small-banner/edit/${id}`).then((res) => {
        if (res.data.status === 200) {
          setUpdate_SmallBanner(res.data.gift_carousel);
        }
        setloading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [rerendering]);


  // handlechange
  const handleChange = (e) => {
    setUpdate_SmallBanner({
      ...update_SmallBanner,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setUpdate_SmallBanner({
      ...update_SmallBanner,
      [e.target.name]: e.target.files[0],
    });
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };


  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("title", update_SmallBanner.title);
    formData.append("image", update_SmallBanner.image);
    formData.append("img_alt", update_SmallBanner.img_alt);
    formData.append("url", update_SmallBanner.url);
    axios
      .post(`/setting/featured-categories-small-banner/update/${update_SmallBanner.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValidation_error({});
          setRerendering(!rerendering);
          setPreviewImage(null);
          setDisableSubmitBtn(false);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
           {/* seo title */}
      <Helmet>
        <title>Edit || Featured-Categories</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Edit-Featured-Categories-Small-Banner" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Small Banner</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/setting/all-featured-categories"
                    className="btn btn-success btn"
                  >
                    All Small Banner
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                 
                <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={update_SmallBanner.title}
                      className="form-control"
                      placeholder="Enter  title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }
                 
                 
                  <div className="form-group">
                    <label htmlFor="img"> Image</label>

                    <input
                    required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    previewImage &&
                    <img
                    alt='item-img'
                    width='60'
                    src={previewImage}
                  />
                   }
                   
                    {
                      !previewImage && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update_SmallBanner?.image == null
                        ? null
                        :`${process.env.REACT_APP_API_URL}${update_SmallBanner?.image}`
                    }
                  />
                  }
                  <div className="form-group">
                    <label htmlFor="inputslug">Image Alt</label>
                    <input
                      required
                      type="text"
                      id="inputalt"
                      name="img_alt"
                      onChange={handleChange}
                      value={update_SmallBanner.img_alt}
                      className="form-control"
                      placeholder="Enter Image Alt"
                    />
                  </div>
                  {
                    validation_error.img_alt &&
                    <span className='text-danger'>
                          {validation_error.img_alt}
                      <br />
                      </span>
                  }

               <div className="form-group">
                    <label htmlFor="url"> Link </label>
                    <input
                    required
                      type="text"
                      id="url"
                      name="url"
                      onChange={handleChange}
                      value={update_SmallBanner.url}
                      className="form-control"
                      placeholder="Enter URL"
                    />
                  </div>
                  {
                    validation_error.url &&
                    <span className='text-danger'>
                          {validation_error.url}
                      
                      </span>
                  }
                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditFeaturedCategorisSec;
