import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Terms_of_Use.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import Helmet from "react-helmet"

const Terms_of_Use = (props) => {
  const [terms_of_uses, setterms_of_uses] = useState([]);

  
  // get data
  useEffect(() => {
    let isMounted = true;
    props.onTopProgress(30);
    const fetchData = async () => {
      try {
      const res = await  axios.get("/terms-of-uses");
      if(isMounted){
        if (res.data.status === 200) {
          setterms_of_uses(res.data.terms_of_uses);
          props.onTopProgress(70);
        }
        props.onTopProgress(100);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }


   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);

  return (
    <>
  {/* seo meta tag  start */}
  <Helmet>
        ‍<title>Terms of Uses</title>
         <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content={terms_of_uses} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />




         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content={terms_of_uses}
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={terms_of_uses}
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content={terms_of_uses}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content={terms_of_uses}
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
      </Helmet> 
      {/* seo meta tag  end */}



      <div className='Terms_of_Use_Section py-4 bg-light overflow-hidden'>
        <div className='container'>
          <h4 className='title text-center py-2'>Terms of Use</h4>
          <div className='row'>
            <div className='col-12'>
              <MDBCard className='shadow-1'>
                <MDBCardBody>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                        terms_of_uses == null
                            ? ""
                            : 
                          terms_of_uses &&
                          terms_of_uses,
                      }}
                    />
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms_of_Use;
