import React, {useState,useEffect} from "react";
import "./Gallery.css"
import Helmet from "react-helmet"
import { Link } from 'react-router-dom';
import { MDBCard} from 'mdb-react-ui-kit';
import axios from "axios";
import ContentLoader from "react-content-loader"
const Gallery = (props) => {


  const [gallery, setGallery] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        props.onTopProgress(30);
      const res = await axios.get("/gallery");
      if(isMounted){
        if (res.data.status === 200) {
          setGallery(res.data.gallery);
          props.onTopProgress(70);
        }
        setloading(false);
        props.onTopProgress(100);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }


   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);


  return (
    <>
 {/* seo meta tag  start */}
        {
            gallery?.map(val=>
              (
                // <>
                 <Helmet>
                   ‍<title>Gallery</title>
                <meta name='keywords' content='OurRajshahi' />
                <meta name='description' content={val?.title} />
                <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
                <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        
                 {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content='OurRajshahi - Collection of all District Branding Online Shopping'  />
                <meta
                  itemprop="description"
                  content={val?.title} 
                />
                <meta
                  itemprop="image"
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  itemProp='image'
                  ontent={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  name='description'
                  itemProp='description'
                  content={val?.title} 
                />
        
        
                 {/* <!-- Facebook Meta Tags --> */}
                <meta name='og:title' content='OurRajshahi' />
                
                <meta
                  name='og:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
               
                <meta
                  name='og:description'
                  content={val?.title} 
                />
        
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content='summary_large_image' />
                <meta
                  name='twitter:title'
                  content='OurRajshahi' 
                />
                <meta
                  name='twitter:description'
                  content={val?.title} 
                />
                <meta
                  name='twitter:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                </Helmet> 
                // </>
              )
            )}
        
      
      {/* seo meta tag  end */}



      <div className="gallery_section bg-light py-4">
      <h4 className='title text-center py-2'>Gallery</h4>
        <div className="container">
          <MDBCard className='shadow-1'>
            <div className="row">
            {   loading ?
              Array(8)
                .fill()
                .map((val, idx) => {
                  return (
                <div className='col-lg-3 col-md-4 col-10 mx-auto mt-4'  key={idx}>
                   <ContentLoader 
                  speed={2}
                  width='100%'
                  height='100%'
                  viewBox="0 0 320 260"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="98" y="163" rx="3" ry="3" width="178" height="11" /> 
                  <circle cx="59" cy="182" r="20" /> 
                  <rect x="33" y="9" rx="3" ry="3" width="248" height="137" /> 
                  <rect x="119" y="187" rx="0" ry="0" width="117" height="11" />
                </ContentLoader>
            </div>
            )})
            :
            gallery?.map(({image, title}, index)=>{
                  return(
                    <div className="col-lg-3 col-md-4 col-10 mx-auto mt-4" key={index}>
                    <div className='bg-image hover-overlay' style={{ maxWidth: '24rem' }}>
                      <Link to="">
                      <img  src={`${process.env.REACT_APP_API_URL}${image}`}
                 className='w-100 mx-auto d-block img-fluid' alt="gallery-img" />
                      <div className="download_icon">
                      <a href="##"><i class="fas fa-download"></i></a>
                      </div>
                      <div
                        className='mask'
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
                      >
                        <div className=' text-center mt-5'>
                          <p className='text-white mb-0 img_name'>{title}</p>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>
                  )
                })
              }
             
             
              
            </div>
          </MDBCard>
        </div>
      </div>
    </>
  )
}

export default Gallery