import React from 'react'
import CancledServiceSec from '../../../../Components/admin/CustomServiceSection/CancledServiceSec'

const CancledService = () => {
  return (
    <>
    <CancledServiceSec/>
    </>
  )
}

export default CancledService