import React from 'react'
import AddProductSec from '../../../../Components/admin/ProductSection/AddProductSec'

const AddProducts = () => {
  return (
    <>
    <AddProductSec/>
    </>
  )
}

export default AddProducts