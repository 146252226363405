import React from 'react'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import {Helmet} from "react-helmet";

const EditPermissionSec = () => {
  return (
    <>
    {/* seo title */}
          <Helmet>
        <title>Edit-Permission || Role-Permission</title>
        </Helmet>

     {/* -------PageHeaderBreadCrumb------- */}
     <PageHeaderBreadCrumb
                Title="Permission"
                pageTitle="Edit-Permission"
            />
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Edit Permission</h3>
                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="form-group">
                                        <label htmlFor="inputPermissionName">Permission Name</label>
                                        <input type="text" id="inputPermissionName" className="form-control" placeholder='Enter Permission Name' />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputPermissiongroup">Permission Name</label>
                                        <input type="text" id="inputPermissiongroup" className="form-control" placeholder='Enter Group Name' />
                                    </div>
                                    <button type="submit" class="btn btn-success btn-block w-75 mx-auto"><i class="fas fa-paper-plane"></i> Submit</button>
                                </form>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            </section>
            {/* /.content */}
    </>
  )
}

export default EditPermissionSec