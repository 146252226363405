import React from 'react'
import AllOrderSec from '../../../../Components/admin/OrderSection/AllOrderSec'

const AllOrders = () => {
  return (
    <>
    <AllOrderSec/>
    </>
  )
}

export default AllOrders