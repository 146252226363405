import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import Brandslider from "react-slick";
import "./BrandCarousel.css";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BrandCarousel = (props) => {
  //  get brand data
  const [brand, setBrand] = useState([]);
  const [loading, setloading] = useState(true);

  // get data
 useEffect(() => {
  let isMounted = true;

  const fetchData = async () => {
  try {
    const res = await  axios.get("/home-brand/all");
    if(isMounted){
      if (res.data.status === 200) {
        setBrand(res.data.brands);
       }
       setloading(false);
    }
    
  } catch (e) {
     console.log(e);
  }
}

fetchData();

  return () => {
    isMounted = false;
  }
}, []);



  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2500,
    pauseOnFocus: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
            {/* seo meta tag  start */}
            {
          brand?.map(val=>{
        <Helmet>
         <meta name='keywords' content={val?.img_alt} />
        <meta name='description' content={val?.img_alt} />
        <meta name='subject' content={val?.img_alt} />
        <meta name='topic' content={val?.img_alt} />
       

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={val?.img_alt}  />
        <meta
          itemprop="description"
          content={val?.img_alt}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${val?.brand_image}`}
        />
        <meta
          name='description'
          itemProp='description'
          content={val?.img_alt}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={val?.img_alt} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${val?.brand_image}`}
        />
        <meta
          property='og:image:alt'
          content={val?.img_alt}
        />
        <meta
          name='og:description'
          content={val?.img_alt}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='val?.summary_large_image' />
        <meta
          name='twitter:title'
          content={val?.img_alt} 
        />
        <meta
          name='twitter:description'
          content={val?.img_alt}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${val?.brand_image}`}
        />
        <meta
          name='twitter:image:alt'
          content={val?.img_alt}
        />
      </Helmet> 
          })
        }
        
      {/* seo meta tag  end */}


      <section className='brand_carousel py-3'>
        <div className='container'>
          <h5 className='section-title text-center fw-bold py-2'>Brand</h5>
         
            <div className='bg-white rounded'>
              <Brandslider {...settings}>
                {loading ?
                  Array(5)
                  .fill()
                  .map((val, idx) => {
                    return (
                      <div
                        key={idx}>
                          <ContentLoader
                            speed={3}
                            width='100%'
                            height='100%'
                            viewBox='0 0 200 85'
                            backgroundColor='#f5f5f5'
                            foregroundColor='#dbdbdb'
                            {...props}>
                            <rect
                              x='53'
                              y='16'
                              rx='3'
                              ry='3'
                              width='108'
                              height='48'
                            />
                          </ContentLoader>
                        </div>
                    );
                  })
                  :
                  brand?.map((val, idx) => {
                    return (
                      <div
                         className="mx-auto mt-2"
                        data-index={idx}
                        key={idx}  style={{ width: 160}}>
                          <Link to='' >
                            <LazyLoadImage
                             effect="blur"
                              className='mx-auto img_fit'
                              placeholderSrc='./assest/image/loader/placeholder.jpg'
                              src={
                                `${process.env.REACT_APP_API_URL}${val?.brand_image}`
                              }
                              alt={val?.img_alt}
                            />
                          </Link>
                      </div>
                    );
                  })}
              </Brandslider>
            </div>
          
        </div>
      </section>
    </>
  );
};

export default BrandCarousel;
