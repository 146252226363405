import React from 'react'
import GivePermissionSec from '../../../Components/admin/Role_&_Permission/GivePermissionSec'

const GivePermission = () => {
  return (
    <>
    <GivePermissionSec/>
    </>
  )
}

export default GivePermission