import React, { useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import axios from "axios";
const HeaderTopSec = () => {
  // insert content
  const [insertheadertop, setinsertHeadertop] = useState({
    header_top_email: "",
    header_top_hotline: "",
    address:"",
    social_media_fb: "",
    social_media_twitter: "",
    social_media_linkedin: "",
    social_media_instagram: "",
    social_media_pinterest: "",
    social_media_youtube: "",
  });

  // update content
  const [headertop, setHeadertop] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(null);
  const [loading, setloading] = useState(true);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  //  handlechange value
  const handleChange = (e) => {
    if (headertop == null) {
      setinsertHeadertop({
        ...insertheadertop,
        [e.target.name]: e.target.value,
      });
    } else {
      setHeadertop({
        ...headertop,
        [e.target.name]: e.target.value,
      });
    }
  };

  // get data
  useEffect(() => {
    axios.get("/setting/header-top-content").then((res) => {
      if (res.data.status === 200) {
        setHeadertop(res.data.header_top);
      }
      setloading(false);
    });
  }, [rerendering]);

  // form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    if (headertop == null) {
      const data = insertheadertop;
      axios.post(`/setting/header-top-content/insert`, data).then((res) => {
        if (res.data.status === 200) {
          setRerendering(res.data.message);
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
        }
      });
    } else {
      const data = headertop;
      axios
        .post(`/setting/header-top-content/update/${headertop.id}`, data)
        .then((res) => {
          if (res.data.status === 200) {
            toast.info(res.data.message, {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
          }
        });
    }
  };

  if (loading) {
    return <h2>loading...</h2>;
  }
  return (
    <>
               {/* seo title */}
               <Helmet>
        <title>Header-Top || Setting</title>
        </Helmet>

      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Setting' pageTitle='Header-Top' />
      {/* Main content */}
      <section className='content profile'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <form className='form-horizontal' onSubmit={handleSubmit}>
                    <div>
                      <div className='form-row'>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputEmail'>
                            <i class='fas fa-envelope'></i>&nbsp;Email
                          </label>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.header_top_email
                                : headertop.header_top_email == null
                                ? ""
                                : headertop.header_top_email
                            }
                            name='header_top_email'
                            className='form-control'
                            id='inputEmail'
                            placeholder='Email'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputphone'>
                            <i class='fas fa-phone'></i>&nbsp;Hotline
                          </label>
                          <input
                            type='tel'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.header_top_hotline
                                : headertop.header_top_hotline == null
                                ? ""
                                : headertop.header_top_hotline
                            }
                            name='header_top_hotline'
                            className='form-control'
                            id='inputphone'
                            placeholder='hotline'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputaddress'>
                          <i class="fas fa-map-marker-alt"></i>&nbsp;Address
                          </label>
                          <input
                            type='text'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.address
                                : headertop.address == null
                                ? ""
                                : headertop.address
                            }
                            name='address'
                            className='form-control'
                            id='inputaddress'
                            placeholder='Address'
                          />
                        </div>

                        <div className='form-group col-md-6'>
                          <label htmlFor='inputfacebook'>
                            <i class='fab fa-facebook'></i>&nbsp;Facebook url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_fb
                                : headertop.social_media_fb == null
                                ? ""
                                : headertop.social_media_fb
                            }
                            name='social_media_fb'
                            className='form-control'
                            id='inputfacebook'
                            placeholder='https://'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputtwitter'>
                            <i class='fab fa-twitter'></i>&nbsp;Twitter url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_twitter
                                : headertop.social_media_twitter == null
                                ? ""
                                : headertop.social_media_twitter
                            }
                            name='social_media_twitter'
                            className='form-control'
                            id='inputtwitter'
                            placeholder='https://'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputlinkedin'>
                            <i class='fab fa-linkedin'></i> &nbsp;Linkedin url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_linkedin
                                : headertop.social_media_linkedin == null
                                ? ""
                                : headertop.social_media_linkedin
                            }
                            name='social_media_linkedin'
                            className='form-control'
                            id='inputlinkedin'
                            placeholder='https://'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputinstagram'>
                            <i class='fab fa-instagram-square'></i>{" "}
                            &nbsp;Instagram url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_instagram
                                : headertop.social_media_instagram == null
                                ? ""
                                : headertop.social_media_instagram
                            }
                            name='social_media_instagram'
                            className='form-control'
                            id='inputinstagram'
                            placeholder='https://'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputpinterest'>
                            <i class='fab fa-pinterest'></i>&nbsp;Pinterest url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_pinterest
                                : headertop.social_media_pinterest == null
                                ? ""
                                : headertop.social_media_pinterest
                            }
                            name='social_media_pinterest'
                            className='form-control'
                            id='inputpinterest'
                            placeholder='https://'
                          />
                        </div>
                        <div className='form-group col-md-6'>
                          <label htmlFor='inputyoutube'>
                            <i class='fab fa-youtube'></i>&nbsp;Youtube url
                          </label>
                          <input
                            type='url'
                            onChange={handleChange}
                            value={
                              headertop == null
                                ? insertheadertop.social_media_youtube
                                : headertop.social_media_youtube == null
                                ? ""
                                : headertop.social_media_youtube
                            }
                            name='social_media_youtube'
                            className='form-control'
                            id='inputyoutube'
                            placeholder='https://'
                          />
                        </div>
                        
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-12'>
                        <button
                          type='submit'
                          class='btn btn-success btn-block w-75 mx-auto'
                          disabled={disableSubmitBtn}
                          >
                          <i class='fas fa-paper-plane'></i> Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
};

export default HeaderTopSec;
