const CopyRight_Data = [
  {
    payment_img: "/assest/image/payment/master-card.png",
  },
  {
    payment_img: "/assest/image/payment/visa.png",
  },
  {
    payment_img: "/assest/image/payment/bkash.png",
  },
  {
    payment_img: "/assest/image/payment/rocket.png",
  },
  {
    payment_img: "/assest/image/payment/nagad.png",
  },
  {
    payment_img: "/assest/image/payment/Cash.png",
  },
];
export default CopyRight_Data;
