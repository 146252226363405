import React from "react";
import { Outlet} from "react-router-dom";

import BlogMegaMenu from './../BlogSection/blog_mega_menu/blogMegaMenu';
import BlogMarquee from './../BlogSection/Breaking_News/Blog_Marquee';
import BlogRightSite from  './../BlogSection/Blog_Right_Site/BlogRightSite'
const BlogLayouts = () => {
  return <>
         <BlogMegaMenu/>
        <div className='blog_main py-4 overflow-hidden'>
        <div className='container-fluid container-lg'>
          <div className='row'>
            <div className='col-lg-8 col-md-8 col-10 mx-auto mt-sm-2'>
              <BlogMarquee />
              <Outlet />
              </div>
            <div className='col-lg-4 col-md-4 col-10 mx-auto mt-sm-2'>
              <BlogRightSite />
            </div>
          </div>
        </div>
      </div>
  </>;
};

export default BlogLayouts;