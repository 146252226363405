import { useEffect, useState, useContext } from "react";
import { Navigate, Outlet, useNavigate} from "react-router-dom";
import axios from "axios";
import Loader from './../Loader/Loader';
import authContext from './../../auth/Context/AuthUser/authContext';
import "./style.css"
import { MDBCard} from 'mdb-react-ui-kit';
import UserProfileCard from './../UserProfileSection/ProfileCard';
export const PrivateUser = () => {
  const { Login} = useContext(authContext);
  const [userAuth, setUserAuth] = useState(false);
  const [loading, setloading] = useState(true);
  const history =useNavigate();


  // get data
  useEffect(() => {
    let isMounted = true;
   
    const fetchData = async () => {
      try {
        if(Login){
          const res = await axios.get("/private-route");
          if(isMounted){
            if (res.data.status === 200) {
              setUserAuth(true);
            }else if (res.data.status === 400) {
              history("/");
            }else if (res.data.status === 401) {
            history("/");
           }
            setloading(false);
          }
        }else{
          setUserAuth(false);
          setloading(false);
        }        
      } catch (e) {
         console.log(e);
      }
    }

    fetchData();
     
    return () => {
      isMounted = false;
    }
    
  }, [Login]);





  if(loading){
    return <Loader/>
  }

  return userAuth ? 
  
   <>
   <div className="user_profile_card_form_section py-4 bg-light">
          <div className="container-lg">
           <MDBCard className='p-4 pt-1 border'>
            <div className="row">
              <div className="col-lg-3 col-md-4 col-12 mx-auto">
                  <UserProfileCard 
                  />
              </div>
              <div className="col-lg-9 col-md-8 col-12 mx-auto">
                <Outlet/>
              </div>
            </div>
            </MDBCard>
          </div>
        
       </div>
   </>
  
  : <Navigate to='/' />;
};
