import React, { Component } from 'react';
import "./custominformationRequestForm.css"
import { MDBCard, MDBCardBody, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import CustomSelect from './CustomSelect';
import { cityOption, divisionOption, subjectOption } from './optionData';
import { Formik } from 'formik';

class CustomInformationRequestForm extends Component {
  render() {
    return (
      <>

        <div className="custom_request_form overflow-hidden">
          <div className="container">


            <div className="row">
              <div className="col-md-12">
                <MDBCard>
                  <MDBCardBody>
                    <Formik
                      initialValues={{
                        fullName: "",
                        email: "",
                        phone: "",
                        subject: "",
                        division: "",
                        district: "",
                        thana: "",
                        area: "",
                      }}
                      validate={values => {

                        const errors = {};


                        // ------  billing validation code    ------
                        // fullName validate condition
                        if (!values.fullName) {
                          errors.fullName = "Please Input Your Full Name"
                        } else if (values.fullName.length > 50) {
                          errors.fullName = "Fullname maximum length of 50 characters"
                        } else if (
                          !/^[a-zA-Z ]*$/i.test(values.fullName)
                        ) {
                          errors.fullName = 'Invalid  Full Name';
                        }

                        // Email validate condition
                        if (!values.email) {
                          errors.email = 'Please Input Your Email Address';
                        } else if (values.email.length > 100) {
                          errors.email = "Email maximum length of 50 characters"
                        }
                        else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                          errors.email = 'Invalid Email Address';
                        }
                        // phone validate condition
                        if (!values.phone) {
                          errors.phone = "Please Input Your Phone Number"
                        } else if (values.phone.length < 11) {
                          errors.phone = "Phone Number minimum length of 11 characters"
                        } else if (values.phone.length > 15) {
                          errors.phone = "Phone Number maximum length of 15 characters"
                        } else if (!/^[0-9\b]+$/i.test(values.phone)) {
                          errors.phone = "Invalid Phone Number"
                        }

                        // subject validate condition
                        if (!values.subject) {
                          errors.subject = "Please Select Subject"
                        }

                        // division validate condition
                        if (!values.division) {
                          errors.division = "Please Select Division"
                        }

                        // district validate condition
                        if (!values.district) {
                          errors.district = "Please Select District"
                        }

                        // thana validate condition
                        if (!values.thana) {
                          errors.thana = "Please Select Thana"
                        }
                        // area validate condition
                        if (!values.area) {
                          errors.area = "Please Select Area"
                        }
                        return errors;

                      }}
                      touched={{
              
            }}
                      onSubmit={(values) => {
                        alert(JSON.stringify(values, null, 2));
                        console.log(values);

                      }}

                    >
                      {(formik) => (
                        <form action="#" onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Your Name<span className='text-danger'> * </span></span>
                                <MDBInput label="&#xf007; Your Name" id='inputYourName' name='fullName' placeholder="Your-Name" type='text'
                                  onChange={formik.handleChange}
                                  value={formik.values.fullName}
                                  className={`form-control ${formik.errors.fullName ? 'is-invalid' : ''}`}
                                />
                                {
                                  formik.errors.fullName &&  formik.touched.fullName ? <span className='text-danger'>{formik.errors.fullName}</span> : null
                                }
                                  
                                
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Email<span className='text-danger'> * </span></span>
                                <MDBInput label='&#xf0e0; Email' id='inputEmail' name='email' placeholder="Email" type='email'
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                  className={`form-control ${formik.errors.email ? 'is-invalid' : ''}`}
                                />
                                <span className='text-danger'>{formik.errors.email}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Phone<span className='text-danger'> * </span></span>
                                <MDBInput label="&#xf2b9;  Phone" id='inputPhone' name='phone' placeholder="&#xf2b9; Phone" type='tel'
                                  onChange={formik.handleChange}
                                  value={formik.values.phone}
                                  className={`form-control ${formik.errors.phone ? 'is-invalid' : ''}`}
                                />
                                <span className='text-danger'>{formik.errors.phone}</span>
                              </div>
                            </div>

                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Subject<span className='text-danger'> * </span></span>
                                <CustomSelect
                                  options={subjectOption}
                                  onChange={value => formik.setFieldValue('subject', value.value)}
                                  value={formik.values.subject}

                                />
                                <span className='text-danger'>{formik.errors.subject}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Division <span className='text-danger'> * </span></span>
                                <CustomSelect
                                  options={divisionOption}
                                  onChange={value => formik.setFieldValue('division', value.value)}
                                  value={formik.values.division}

                                />
                                <span className='text-danger'>{formik.errors.division}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>District <span className='text-danger'> * </span></span>
                                <CustomSelect
                                  options={cityOption}
                                  onChange={value => formik.setFieldValue('district', value.value)}
                                  value={formik.values.district}

                                />
                                <span className='text-danger'>{formik.errors.district}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Thana <span className='text-danger'> * </span></span>
                                <CustomSelect
                                  options={cityOption}
                                  onChange={value => formik.setFieldValue('thana', value.value)}
                                  value={formik.values.thana}
                                />
                                <span className='text-danger'>{formik.errors.thana}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <span className='fw-bold'>Area<span className='text-danger'> * </span></span>
                                <CustomSelect
                                  options={cityOption}
                                  onChange={value => formik.setFieldValue('area', value.value)}
                                  value={formik.values.area}
                                />
                                <span className='text-danger'>{formik.errors.area}</span>
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <MDBInput label='Request For' placeholder="Request For" id='request' textarea rows={2} />
                              </div>
                            </div>
                            <div className="col-6 px-5">
                              <div className="mt-3">
                                <MDBInput label='Comment' placeholder="Comment" id='comment' textarea rows={2} />
                              </div>
                            </div>
                            <div className="mt-5 text-center">
                              <MDBBtn className='w-75' type="submit" >Submit</MDBBtn>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default CustomInformationRequestForm;


