import React, { useState } from "react";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import { Link} from "react-router-dom";
const AddCategoryblog= () => {
  const [insertCategory_blog, setInsertCategory_blog] = useState({
    category_name: "",
    category_slug: "",
  });
    // backend validation check
    const [validation_error, setValidation_error] = useState([]);

  const { category_name,  category_slug } = insertCategory_blog;
  const handleChange = (e) => {
    setInsertCategory_blog({ ...insertCategory_blog, [e.target.name]: e.target.value });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = insertCategory_blog;

    axios
      .post(`/blog/category/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInsertCategory_blog({
            category_name: "",
    category_slug: "",
          });
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      {/* seo title */}
      <Helmet>
        <title>Add ||Blog-Categories</title>
      </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="BlogCategories" pageTitle="Add /blog-Categories" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Category</h3>
                <div className="card-tools">
                <Link
                    to='/our-dashboard/admin/blog/categories'
                    className='btn btn-success btn'>
                    All Blog-categories
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputName">Category Name</label>
                    <input
                      // required
                      type="text"
                      id="inputName"
                      name="category_name"
                      onChange={handleChange}
                      value={category_name}
                      className="form-control"
                      placeholder="Enter Category Name"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_name}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Category Slug</label>
                    <input
                      // required
                      type="text"
                      name="category_slug"
                      value={category_slug}
                      onChange={handleChange}
                      id="category_slug"
                      className="form-control"
                      placeholder="Enter Category Slug"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_slug}
                        </span>
                  
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddCategoryblog;
