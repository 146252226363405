import React from 'react'
import AllSliderSec from '../../../../Components/admin/Settings/Slider/AllSliderSec'



const AllSliderSetting = () => {
  return (
    <>
    <AllSliderSec/>
    </>
  )
}

export default AllSliderSetting