import React, { useState } from "react";
import { storeType } from "./data";
import { MDBBtn, MDBCheckbox } from "mdb-react-ui-kit";
import { Formik } from "formik";
import { data } from "./SearchOptionData";


const AddStore = () => {
  const [areaCheckOption, setareaCheckOption] = useState(true);
  return (
    <>
      <section className="user_add_store pt-2 pb-4">
        <div className="container">
        <h4 className='text-dark text-center fw-bold'>Add Store</h4>
          <div className="px-5 card hover-shadow  py-2">
            <Formik
              initialValues={{
                name: "",
                type: "",
                image: "",
                category: "",
                productImg: "",
                phone: "",
                website: "",
                division: "",
                district: "",
                thana: "",
                area: "",
                details: "",
              }}
              validate={(values) => {
                const errors = {};

                // ------  billing validation code    ------
                // fullName validate condition
                if (!values.name) {
                  errors.name = "Please Input Your Name";
                }
                // else if (values.name.length > 50) {
                //   errors.name =
                //     "Name maximum length of 50 characters";
                // } else if (!/^[a-zA-Z ]*$/i.test(values.name)) {
                //   errors.name = "Invalid  Name";
                // }

                // img validate condition
                if (!values.image) {
                  errors.image = "Please choice Image";
                }

                if (!values.productImg) {
                  errors.productImg = "Please choice Product Image";
                }

                if (!values.type) {
                  errors.type = "Please Select Type";
                }
                // category validate condition
                if (!values.category) {
                  errors.category = "Please Select Type";
                }

                // phone validate condition
                if (!values.phone) {
                  errors.phone = "Please Input Your Phone Number";
                } else if (values.phone.length < 11) {
                  errors.phone = "Phone Number minimum length of 11 characters";
                } else if (values.phone.length > 15) {
                  errors.phone = "Phone Number maximum length of 15 characters";
                } else if (!/^[0-9\b]+$/i.test(values.phone)) {
                  errors.phone = "Invalid Phone Number";
                }

                if (!values.division) {
                  errors.division = "Please Select Division";
                }

                if (!values.district) {
                  errors.district = "Please Select District";
                }

                if (!values.thana) {
                  errors.thana = "Please Select Thana";
                }

                if (!values.area) {
                  errors.area = "Please Select Area";
                }

                // details validate condition
                if (!values.details) {
                  errors.details = "Please Input Your Details";
                } else if (values.details.length > 100) {
                  errors.details = "Details maximum length of 100 characters";
                }

                return errors;
              }}
              onSubmit={(values) => {
                alert(JSON.stringify(values, null, 2));
                console.log(values);
              }}
            >
              {(formik) => (
                <form action="#" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="inputName">
                          {" "}
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          id="inputName"
                          className={`form-control ${
                            formik.touched.name && formik.errors.name
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                      {formik.touched.name && formik.errors.name ? (
                        <span span className="text-danger">
                          {formik.errors.name}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="type">
                          Type <span className="text-danger">*</span>
                        </label>
                        <select
                          id="type"
                          name="type"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          className={`form-control custom-select ${
                            formik.touched.type && formik.errors.type
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select one</option>
                          {storeType.map((value) => {
                            return (
                              <option value={value.value}>{value.label}</option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.touched.type && formik.errors.type ? (
                        <span span className="text-danger">
                          {formik.errors.type}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="image">
                          Image <span className="text-danger">*</span>
                        </label>
                        <div class="input-group">
                          <input
                            type="file"
                            className={`form-control ${
                              formik.touched.image && formik.errors.image
                                ? "is-invalid"
                                : ""
                            }`}
                            name="image"
                            onChange={(e) => {
                              formik.setFieldValue("image", e.target.files[0]);
                            }}
                            accept="image/*"
                            id="image"
                          />
                        </div>
                      </div>
                      {formik.touched.image && formik.errors.image ? (
                        <span span className="text-danger">
                          {formik.errors.image}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="category">
                          Category <span className="text-danger">*</span>
                        </label>
                        <select
                          id="category"
                          name="category"
                          value={formik.values.category}
                          onChange={formik.handleChange}
                          className={`form-control custom-select ${
                            formik.touched.category && formik.errors.category
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select one</option>
                          <option value="Arts & Crafts">Arts & Crafts</option>
                          <option value="Electronics">Electronics</option>
                          <option value="Fashion">Fashion</option>
                          <option value="Food">Food</option>
                          <option value="Grocery">Grocery</option>
                          <option value="Gift Items">Gift Items</option>
                          <option value="Health & Beauty">
                            Health & Beauty
                          </option>
                          <option value="Home Decor">Home Decor</option>
                          <option value="Lifestyle">Lifestyle</option>
                          <option value="Traditionale">Traditionale</option>
                          <option value="Mango">Mango</option>
                          <option value="Silk">Silk</option>
                        </select>
                      </div>
                      {formik.touched.category && formik.errors.category ? (
                        <span span className="text-danger">
                          {formik.errors.category}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="productimage">
                          Product Image <span className="text-danger">*</span>
                        </label>
                        <div class="input-group">
                          <input
                            type="file"
                            className={`form-control ${
                              formik.touched.productImg &&
                              formik.errors.productImg
                                ? "is-invalid"
                                : ""
                            }`}
                            name="productImg"
                            accept="image/*"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "productImg",
                                e.target.files[0]
                              );
                            }}
                          />
                        </div>
                      </div>
                      {formik.touched.productImg && formik.errors.productImg ? (
                        <span span className="text-danger">
                          {formik.errors.productImg}
                        </span>
                      ) : null}
                    </div>

                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="phone">
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          name="phone"
                          placeholder="Phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          type="tel"
                          id="phone"
                          className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                      {formik.touched.phone && formik.errors.phone ? (
                        <span span className="text-danger">
                          {formik.errors.phone}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="website">Website (if available)</label>
                        <input
                          name="website"
                          placeholder="Website"
                          value={formik.values.website}
                          onChange={formik.handleChange}
                          type="text"
                          id="website"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      {" "}
                      <div className="form-group">
                        <label htmlFor="division">
                          Division <span className="text-danger">*</span>
                        </label>
                        <select
                          id="division"
                          className={`form-control custom-select ${
                            formik.touched.division && formik.errors.division
                              ? "is-invalid"
                              : ""
                          }`}
                          name="division"
                          value={formik.values.division}
                          onChange={formik.handleChange}
                        >
                          <option value="">--Select Division--</option>
                          {data.division.map((value, key) => {
                            return (
                              <option value={value.value} key={key}>
                                {value.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {formik.touched.division && formik.errors.division ? (
                        <span span className="text-danger">
                          {formik.errors.division}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="distirct">
                          District <span className="text-danger">*</span>
                        </label>
                        <select
                          id="distirct"
                          className={`form-control custom-select ${
                            formik.touched.district && formik.errors.district
                              ? "is-invalid"
                              : ""
                          }`}
                          name="district"
                          value={formik.values.district}
                          onChange={formik.handleChange}
                        >
                          <option value="">--Select District--</option>
                          {data.division
                            .find(
                              (divi) => divi.value === formik.values.division
                            )
                            ?.district.sort((a, b) =>
                              a.value > b.value ? 1 : -1
                            )
                            .map((value, key) => {
                              return (
                                <option value={value.value} key={key}>
                                  {value.value}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      {formik.touched.district && formik.errors.district ? (
                        <span span className="text-danger">
                          {formik.errors.district}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      <div className="form-group mb-1">
                        <label htmlFor="thana">
                          Thana <span className="text-danger">*</span>
                        </label>
                        <select
                          id="thana"
                          className={`form-control custom-select ${
                            formik.touched.thana && formik.errors.thana
                              ? "is-invalid"
                              : ""
                          }`}
                          name="thana"
                          value={formik.values.thana}
                          onChange={formik.handleChange}
                        >
                          <option value="">--Select thana--</option>
                          {data.division
                            .find(
                              (divi) => divi.value === formik.values.division
                            )
                            ?.district?.find(
                              (dis) => dis.value === formik.values.district
                            )
                            ?.thana.sort((a, b) => (a.value > b.value ? 1 : -1))
                            .map((value, key) => {
                              return (
                                <option value={value.value} key={key}>
                                  {value.value}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      {formik.touched.thana && formik.errors.thana ? (
                        <span span className="text-danger">
                          {formik.errors.thana}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      {" "}
                      <div className="form-group mb-1">
                        <label htmlFor="area">
                          Area <span className="text-danger">*</span>
                        </label>
                        {!areaCheckOption ? (
                          <div>
                            <input
                              className={`form-control ${
                                formik.touched.thana && formik.errors.thana
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="area"
                              placeholder="area"
                              value={formik.values.area}
                              onChange={formik.handleChange}
                              type="text"
                            />
                            <div>
                              <MDBCheckbox
                                name="areaCheck"
                                checked={areaCheckOption}
                                onChange={() => {
                                  setareaCheckOption(!areaCheckOption);
                                  formik.setFieldValue("area", "");
                                }}
                                value=""
                                id="areaCheck"
                                label="Show Option"
                              />
                            </div>
                          </div>
                        ) : (
                          <select
                            className={`form-control custom-select ${
                              formik.touched.thana && formik.errors.thana
                                ? "is-invalid"
                                : ""
                            }`}
                            name="area"
                            placeholder="area"
                            value={formik.values.area}
                            onChange={formik.handleChange}
                          >
                            <option value="">--Select Area--</option>
                            {data.division
                              .find(
                                (divi) => divi.value === formik.values.division
                              )
                              ?.district?.find(
                                (dis) => dis.value === formik.values.district
                              )
                              ?.thana?.find(
                                (thana) => thana.value === formik.values.thana
                              )
                              ?.area.sort((a, b) =>
                                a.value > b.value ? 1 : -1
                              )
                              .map((value, key) => {
                                return (
                                  <option value={value.value} key={key}>
                                    {value.value}
                                  </option>
                                );
                              })}
                            <option
                              value=""
                              onClick={() =>
                                setareaCheckOption(!areaCheckOption)
                              }
                            >
                              🖌️ Write Your Area{" "}
                            </option>
                          </select>
                        )}
                      </div>
                      {formik.touched.area && formik.errors.area ? (
                        <span span className="text-danger">
                          {formik.errors.area}
                        </span>
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-10 mx-auto">
                      {" "}
                      <div class="form-group mb-1">
                        <label for="details">
                          Details <span className="text-danger">*</span>
                        </label>
                        <textarea
                          name="details"
                          placeholder="Details"
                          value={formik.values.details}
                          onChange={formik.handleChange}
                          className={`form-control ${
                            formik.touched.details && formik.errors.details
                              ? "is-invalid"
                              : ""
                          }`}
                          id="details"
                          rows="3"
                        ></textarea>
                      </div>
                      {formik.touched.details && formik.errors.details ? (
                        <span span className="text-danger">
                          {formik.errors.details}
                        </span>
                      ) : null}
                    </div>
                    {/* {formik.values.image && <Preview val={formik.values.image} />} */}

                    <div className="col-12  text-center">
                      <MDBBtn
                        className="mx-auto w-50 my-2 w-100"
                        type="submit"
                        color='info'
                        
                      >
                        Add Store
                      </MDBBtn>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddStore;
