import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';

const ViewSubCategory_with_Cates_blog = () => {
  const { id } = useParams();
   //  get categories   data
   const [subcateWithcates_blog, setSubcateWithcates_blog] = useState([]);
   const [loading, setloading] = useState(true);
   // get data
   useEffect(() => {
    let isMounted = true;
   
     axios.get(`/blog/category/subcats-with-cats/${id}`).then((res) => {
      if (isMounted) {
       if (res.data.status === 200) {
        setSubcateWithcates_blog(res.data.blog_subcategories);
       }
       setloading(false);
      }
     }).catch(error => {});
    
    return () => {
      isMounted = false
    }
   }, []);
 
   useEffect(() => {
     // initialize datatable
     if (!loading) {
       const script = document.createElement("script");
       script.src = "/dist/js/custom.js";
       script.async = true;
       document.body.appendChild(script);
     }
   }, [loading]);
  
 // handle delet item
   const handleDeleteItems = (id) => {
     axios
       .delete(`/blog/subcategory/delete/${id}`)
       .then((res) => {
         if (res.data.status === 200) {
           toast.info(res.data.message, {
             position: "top-right",
             autoClose: 700,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           });
 
           setSubcateWithcates_blog(
            subcateWithcates_blog.filter((item) => item.id !== id)
           );
         }
       })
       .catch((e) => {});
   };
 
   if (loading) {
     return  <Loader/>
   }

  return (
    <>
      {/* seo title */}
      <Helmet>
        <title>Blog ||View-Sub-Category-with-Cate</title>
      </Helmet>
      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Categories"
        pageTitle={`All /blog-Categories / View-Sub-Category-with-cates/${id}` }     />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr>
                <th  className="text-center">
                SL
                  </th>
                  <th  className="text-center">
                    Category Name
                  </th>
                  <th className="text-center">
                    Sub Category 
                  </th>
                  <th className="text-center">
                    Slug
                  </th>
                  <th className="text-center">
                    Edit
                  </th>
                  <th className="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                
                 { subcateWithcates_blog && subcateWithcates_blog.map((val) => {
                  
                      return (
                        <tr key={val.id}>
                             <td className="text-center">{val.id}</td>
                          <td className="text-center">{val.blog_category.category_name}</td>
                          <td className="text-center">
                            {val.sub_category_name}
                          </td>
                          <td className="text-center">{val.sub_category_slug}</td>
                          <td className="text-center">
                            <Link
                              to={`/our-dashboard/blog/edit/sub-category/${val.id}`}
                              className="btn btn-info btn-sm"
                              href="#/"
                            >
                              <i className="fas fa-pencil-alt pr-2"></i>
                              Edit
                            </Link>
                          </td>
                          <td className="text-center">
                          <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          </td>
                        </tr>
 );
                
})}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
    </>
  );
};

export default ViewSubCategory_with_Cates_blog;
