import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import axios from "axios";
import {toast,Zoom } from "react-toastify";
import Loader from '../../frontend/Loader/Loader';
import { useParams, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";


const ViewCustomService = () => {

  const { id } = useParams();
  const history = useNavigate();

  //  get order info data
  const [data, setData] = useState([]);
  const [statusMsg, setStatusMsg] = useState('');
  const [loading, setloading] = useState(true);
  const [rerender, setRerender] = useState(false);

  // backend validation check
const [validation_error, setValidation_error] = useState([]);

  // get data
  useEffect(() => {
   let isMounted = true;
 const fatchData = async () =>{
  const res = await axios.get(`/custom-service/view/${id}`);
  if (isMounted) {
   if (res.data.status === 200) {
     setData(res.data.custom_service);
   } else if (res.data.status === 404) {
     toast.error(res.data.message, {
       position: "top-center",
       theme: "colored",
       transition: Zoom,
       autoClose: 500,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
     history("/our-dashboard/custom-service/all");
   }
   setloading(false);
  }
 }

 fatchData();
 
return () => {
  isMounted = false
}
 
  
  }, [rerender]);
  
  useEffect(() => {
   // initialize datatable
   if (!loading) {
     const script = document.createElement("script");
     script.src = "/dist/js/custom.js";
     script.async = true;
     document.body.appendChild(script);
   }
 }, [loading]);
 







 // status code 0,1,2,3,4,5
 const updateStatus = (status_val) => {
 const formData = new FormData();
 formData.append("status", status_val);
 formData.append('status_message', statusMsg);
 axios
 .post(`/custom-service/update/${id}`, formData)
 .then((res) => {
   if (res.data.status === 200) {
     toast.success(res.data.message, {
       position: "top-center",
       theme: 'colored',
       transition: Zoom,
       autoClose: 400,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
     });
      setRerender(!rerender)
      setValidation_error({});
      setStatusMsg('');
      }else if (res.data.status === 422) {
        setValidation_error(res.data.validation_errors);
      }
     })
     .catch((e) => {});
 };


 var totalPrice = 0;

 if (loading) {
  return <Loader />;
}

  return (
    <>
           {/* seo title */}
           <Helmet>
        <title> View || Custom-Service</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Custom-Service"
        pageTitle="View-Custom-Service"
      />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-10 mx-auto">
                <div className="custom_service_info">
                  <span className='text-start px-3 h5 font-weight-bold'>Custom Service Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                           Name
                        </td>
                        <td>
                          {data?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        custom_service Email
                        </td>
                        <td>
                        {data?.email}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        custom_service Number
                        </td>
                        <td>
                        {data?.phone}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                
              </div>
              <div className="col-lg-6 col-md-6 col-10 mx-auto">
                <div className="user_info">
                  <span className='text-start px-3 h5 font-weight-bold'>User Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Name:
                        </td>
                        <td>
                        {data?.user?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Email:
                        </td>
                        <td>
                        {data?.user?.email}
                        </td>
                        
                      </tr>
                      <tr>
                      <td>
                          Phone:
                        </td>
                        <td>
                        {data?.user?.phone}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="more_user_info">
                  <span className='text-start px-3 h5 font-weight-bold'>More User Info</span>
                  <table id="example1" className="table table-bordered table-striped text-center">
                    <thead>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                        City:
                        </td>
                        <td>
                        {data?.user?.user_details?.city}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Full Address
                        </td>
                        <td>
                        {data?.user?.user_details?.full_address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                        Zip Code
                        </td>
                        <td>
                        {data?.user?.user_details?.zip_code}
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>



               
              </div>
            </div>

          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}




        <div class="card">
              <div class="card-header">
                <h3 class="card-title">Order Status</h3>
              </div>
              {/* <!-- /.card-header --> */}
              <div class="card-body">

              <div className="form-group">
                    <label htmlFor="inputName">Set Status Message</label>
                    <input
                      required
                      type="text"
                      onChange={ e => setStatusMsg(e.target.value)}
                      name="statusMsg"
                      value={statusMsg}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  

                  {
                    validation_error.status_message &&
                    <span className='text-danger'>
                          {validation_error.status_message}
                    <br/>
                  </span>
                  }

                  
                        {
                          data?.status === 0 ? 
                          <>
                          <a  href='##' class="btn btn-app mr-2 bg-danger"  
                          onClick={() => {
                             updateStatus(3);
                            }}>
                          <i class="fas fa-play"></i> Cancled
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-secondary" 
                             onClick={() => {
                             updateStatus(0);
                            }}>
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          
                          <a  href='##' class="btn btn-app mr-2 bg-success"  
                          onClick={() => {
                             updateStatus(1);
                            }}>
                          <i class="fas fa-play"></i> Approved
                          </a>
                          </>
                          :
                          data?.status === 1 ? 
                          <>
                          <a  href='##' class="btn btn-app mr-2 bg-danger"  
                          onClick={() => {
                             updateStatus(3);
                            }}>
                          <i class="fas fa-play"></i> Cancled
                          </a>

                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Pending
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-dot-circle"></i></span>
                          <i class="fas fa-play"></i> Approved
                        </a>
                        <a  href='##' class="btn btn-app mr-2 bg-danger"
                         onClick={() => {
                          updateStatus(2);
                         }}
                        >
                        <i class="fas fa-inbox"></i> delivered
                        </a>
                        </>
                          :
                          data?.status === 2 ? 
                          <>
                          <a  href='##' class="btn btn-app mr-2 bg-danger"  
                          onClick={() => {
                             updateStatus(3);
                            }}>
                          <i class="fas fa-play"></i> Cancled
                          </a>

                          <a  href='##' class="btn btn-app mr-2 bg-secondary"
                           onClick={() => {
                            updateStatus(0);
                           }}
                          >
                          <span class="badge bg-success"><i class="fas fa-check"></i></span>
                          <i class="fas fa-pause"></i> Approved
                          </a>
                          <a  href='##' class="btn btn-app mr-2 bg-success"
                           onClick={() => {
                            updateStatus(1);
                           }}
                          >
                          <span class="badge bg-purple"><i class="fas fa-check"></i></span>
                          <i class="fas fa-play"></i> delivered
                        </a>
                        
                        </>
                        : null
                          
                         

                        }
                
                <br />
                <span className='text-info'>
                          {data?.status_message}
                  </span>
                 

              

              
                


              </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}




        






        <div class="card">
              <div class="card-header">
                <h3 class="card-title">Plan Items</h3>
              </div>
              {/* <!-- /.card-header --> */}
              <div class="card-body">
                <table id="example1" class="table table-bordered table-striped text-center">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Plan-Name</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                  </thead>
                  <tbody>
                        {
                          data?.order_items?.map((item)=>{
                            const {product, product_colors}=item;
                            totalPrice += Number(item.price) * item.qty;
                            return(
                    <tr>
                    <td>{item?.id}</td>
                    <td>
                    <Link to={`/product/${product?.product_slug}`}>
                    <ul className='list-inline'>
                            <li className='list-inline-item'>
                              <img
                                alt='product_img'
                                width='50'
                                src={
                                  product?.product_img == null
                                    ? null
                                    : `${process.env.REACT_APP_API_URL}${product?.product_img}`
                                }
                              />
                            </li>
                          </ul>
                          </Link>
                    </td>
                    <td>{product?.product_name}</td>
                    <td>{product_colors?.color?.color_name}</td>
                    <td>{item?.size}</td>
                    <td>{Number(item?.price)} x {item?.qty}</td>
                    <td>{Number(item?.price) * item?.qty}</td>
                  </tr>
                            )
                          })
                        }
                  
                 
                  </tbody>
                  <tfoot>
                  {data?.discount_price &&
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Discount</th>
                    <th className='text-danger'>{data?.discount_price}</th>
                  </tr>
                  }
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Total</th>
                    <th className='text-danger'>{data?.discount_price ? totalPrice - data?.discount_price : totalPrice}</th>
                  </tr>
                  </tfoot>
                </table>
              </div>
              {/* <!-- /.card-body --> */}
            </div>
        {/* <!-- /.card --> */}

      </section>
    </>
  )
}

export default ViewCustomService 