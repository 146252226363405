import React from 'react'
import EditGallerySec from '../../../../Components/admin/GallerySection/EditeGallerySec'

const EditGallery = () => {
  return (
    <>
    <EditGallerySec/>
    </>
  )
}

export default EditGallery