import React, {useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { Link } from "react-router-dom";
import Loader from './../../frontend/Loader/Loader';
import axios from "axios";
import moment from 'moment'
import {Helmet} from "react-helmet";

const NewOrderSec = () => {

  //  get new order data
  const [newOrder, setNewOrder] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
   let isMounted = true;
 
    axios.get("/order/new").then((res) => {
     if (isMounted) {
      if (res.data.status === 200) {
        setNewOrder(res.data.new_orders);
      }
      setloading(false);
     }
    }).catch((e) => {});
   
   return () => {
     isMounted = false
   }
  }, []);
  
  useEffect(() => {
   // initialize datatable
   if (!loading) {
     const script = document.createElement("script");
     script.src = "/dist/js/custom.js";
     script.async = true;
     document.body.appendChild(script);
   }
 }, [loading]);
 

 if (loading) {
   return <Loader />;
 }
   return (
     <>
            {/* seo title */}
        <Helmet>
        <title> New || Order</title>
        </Helmet>

       {/* -------PageHeaderBreadCrumb------- */}
       <PageHeaderBreadCrumb Title="Order" pageTitle="All / Order" />
       {/* Main content */}
       <section className="content">
         {/* Default box */}
         <div className="card">
           <div className="card-body">
                   <Link
                     to='/our-dashboard/order/draft'
                     className='btn btn-danger btn float-right'>
                     Draft order
                   </Link>
             <table id="example1" className="table table-bordered table-striped text-center">
               <thead>
                 <tr >
                 <th  >
                   SI
                   </th>
                   <th >
                    Name
                   </th>
                   <th >
                    Invoice ID
                   </th>
                   <th>
                    Payment Mode
                   </th>
                   <th >
                   Order Status 
                   </th>
                   <th >
                   Price
                   </th>
                   <th >
                   Time
                   </th>
                   <th >
                     View
                   </th>
                   {/* <th >
                     Delete
                   </th> */}
                 </tr>
               </thead>
               <tbody>
                 {newOrder && newOrder.map((val) => {
                  const {user, order_items}=val;
                  var  totalPrice = 0
                     return (
                       <tr key={val.id}>
                         <td class="dtr-control">{val.id}</td>
                        
                         <td>
                            {user?.name}
                         </td>
                         
                         <td>{val?.invoice_id}</td>
                         <td>
                          <span className='text-success'>
                          {
                          val?.payment_mode
                        }
                        </span>
                        </td>
                         <td><span className='p-1 rounded bg-danger'>
                          {
                          val?.status === 0 && 'Pending'

                        }</span></td>

                         {
                            order_items?.map((item)=>{
                              totalPrice += Number(item.price)* item.qty
                              
                            })

                         }
                         <td>{val?.discount_price ? totalPrice - val?.discount_price : totalPrice}</td>
                         
                         <td>{moment(val?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        <td>
                           <Link
                             to={`/our-dashboard/view/order/${val.id}`}
                             className="btn btn-primary btn-sm p-2 "
                           >
                             View
                           </Link>
                         </td>
                       </tr>
                     );
                 
                 })}
               </tbody>
             </table>
           </div>
           {/* /.card-body */}
         </div>
         {/* /.card */}
       </section>
       {/* /.content */}
     </>
   )
}

export default NewOrderSec;
