import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';

const DraftPostblog = () => {
 //  get categories option  data
 const [blog_post_draft, setBlog_post_draft] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;

   axios.get("/blog/post/draft").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setBlog_post_draft(res.data.blog_post_draft);
     }
     setloading(false);
    }
   }).catch(error => {});
  
  return () => {
   isMounted = false
 }
 }, []);
 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);

const updateStatus = (id) => {

const formData = new FormData();
formData.append("status", 0);
axios
.post(`/blog/post/update_status/${id}`, formData)
.then((res) => {
  if (res.data.status === 200) {
    toast.info(res.data.message, {
      position: "top-right",
      autoClose: 700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

        setBlog_post_draft(
          blog_post_draft.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

const handleDeleteItems = (id) => {
  axios
    .delete(`/blog/post/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setBlog_post_draft(
          blog_post_draft.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>;
}
  return (
    <>
       {/* seo title */}
       <Helmet>
        <title> Draft || Blog-Post</title>
      </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Post" pageTitle="All / blog-post" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
          <Link
                    to='/our-dashboard/admin/blog/add/post'
                    className='btn btn-success btn float-right ml-2'>
                    Add Post
                  </Link>
                  <Link
                    to='/our-dashboard/admin/blog/post'
                    className='btn btn-warning btn float-right'>
                    ALL Post
                  </Link>
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr >
                <th  className="text-center" >
                  SL
                  </th>
                  <th  className="text-center">
                   Image
                  </th>
                  <th  className="text-center">
                   Heading
                  </th>
                  <th  className="text-center">
                    Category Name
                  </th>
                  <th  className="text-center">
                    Sub Category Name
                  </th>
                  <th  className="text-center">
                   Status
                  </th>
                  <th  className="text-center">
                  Action
                  </th>
                  <th  className="text-center">
                    View
                  </th>
                  <th  className="text-center">
                    Edit
                  </th>
                  <th  className="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {blog_post_draft && blog_post_draft.map((val) => {
                 
                    return (
                      <tr key={val.id}>
                        <td className="text-center" class="dtr-control">{val.id}</td>
                        <td className="text-center">
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <img alt="item-img" className="table-avatar" src={
                              val.blog_image == null
                                ? null
                                :`http://localhost:8000/${val.blog_image}`
                                  
                            } />
                            </li>

                          </ul>
                        </td>
                        <td className="text-center">{val.heading}</td>
                        <td className="text-center">{val.blog_category.category_name}</td>
                        <td className="text-center">{val.blog_subcategory.sub_category_name}</td>
                        <td className="text-center"><span className="p-1 rounded bg-success">
                         {val.status == 1 ? "Deactived" : "Actived"} 
                        </span></td>
                        <td className="text-center">
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(val.id);
                                      }}
                                      >
                          Actived Now
                        </span>
                      </td>
                        <td className="text-center">
                          <Link
                            to={`/our-dashboard/admin/blog/post/view/${val.id}`}
                            className="btn btn-primary btn-sm p-2 "
                            href="#/"
                          >
                            View
                          </Link>
                        </td>
                        <td className="text-center">
                          <Link
                            to={`/our-dashboard/admin/blog/edit/post/${val.id}`}
                            className="btn btn-info btn-sm"
                          >
                            <i className="fas fa-edit pr-2" />
                            Edit
                          </Link>
                        </td>
                        <td className="text-center">
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default DraftPostblog;
