import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "./ProductSection.css";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import EditorEdit from "../Editor/Editor";
import { toast, Zoom } from "react-toastify";
import Loader from "../../frontend/Loader/Loader";
import {Helmet} from "react-helmet";

const EditProductWrapper = () => {
  const { id } = useParams();
  const history = useNavigate();

  const [updateProduct, setUpdateProduct] = useState([]);

  //extra add product color qty size
  const [addColor, setAddColor] = useState({
    color: [],
    color_qty: [],
    size: [],
  
  });
  const { color,color_qty,size } = addColor;

  //add gallary img
  const [addGallaryImg, setAddGallaryImg] = useState({
    gallary_img:[],
  
  });

  //  get color
  const [colors, setColors] = useState([]);

  //  get brands
  const [brands, setBrands] = useState([]);

  //  get category
  const [category, setCategory] = useState([]);
  //  get subcate
  const [subcategory, setSubcategory] = useState([]);
  //  get soscate
  const [soscategory, setSoscategory] = useState([]);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  const [loading, setloading] = useState(true);
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  // image preview
  const [previewImage, setPreviewImage] = useState({
    product_img: "",
    gallary_img: "",
  });

  // get color
  useEffect(() => {
    let isMounted = true;

    axios.get("/color/all").then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setColors(res.data.colors);
        }
        setloading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  // get brand
  useEffect(() => {
    let isMounted = true;

    axios.get("/brand/all").then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setBrands(res.data.brands);
        }
        setloading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  // get product
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios
        .get(`/product/edit/${id}`)
        .then((res) => {
          if (res.data.status === 200) {
            setUpdateProduct(res.data.product);
            setCategory(res.data.categories);
          } else if (res.data.status === 404) {
            toast.error(res.data.message, {
              position: "top-center",
              theme: "colored",
              transition: Zoom,
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history("/our-dashboard/all/product");
          }
          setloading(false);
        })
        .catch((error) => {});
    }
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  // get sub category
  useEffect(() => {
    let isMounted = true;

    if (updateProduct.category_id) {
      axios
        .get(`/subcategory/list/${updateProduct.category_id}`)
        .then((res) => {
          if (isMounted) {
            if (res.data.status === 200) {
              setSubcategory(res.data.subcategory);
              if (!res.data.subcategory.length) {
              }
            }
            setloading(false);
          }
        });
    } else {
      setUpdateProduct({ ...updateProduct, sub_category_id: "" });
    }

    return () => {
      isMounted = false;
    };
  }, [updateProduct.category_id]);

  // get sos_category
  useEffect(() => {
    let isMounted = true;

    if (updateProduct.category_id && updateProduct.sub_category_id) {
      axios
        .get(
          `/sub-of-sos-category/list/${updateProduct.category_id}/${updateProduct.sub_category_id}`
        )
        .then((res) => {
          if (isMounted) {
            if (res.data.status === 200) {
              setSoscategory(res.data.sos_category);
              if (!res.data.sos_category.length) {
              }
            }
            setloading(false);
          }
        });
    } else {
      setUpdateProduct({ ...updateProduct, sos_category_id: "" });
    }

    return () => {
      isMounted = false;
    };
  }, [updateProduct.category_id, updateProduct.sub_category_id]);

  // handle change multipul input
  const handleChange = (e) => {
    setUpdateProduct({ ...updateProduct, [e.target.name]: e.target.value });
  };

  const handleChangeType = (e) => {
    setUpdateProduct({ ...updateProduct, [e.target.name]: e.target.checked });
  };

  const handleDesciption = (newDescption) => {
    setUpdateProduct({ ...updateProduct, product_description: newDescption });
  };

// handle change img
  const handleChangeImage = (e) => {
    setPreviewImage({
      ...previewImage,
      [e.target.name]: URL.createObjectURL(e.target.files[0]),
    });
    setUpdateProduct({
      ...updateProduct,
      [e.target.name]: e.target.files[0],
    });
  };

  const imageMimeType = /image\/(jpeg|png|jpg|gif|svg)/i;
  // gallary img
  const handleChangeGallaryImage = (e) => {
    const { gallary_img } = addGallaryImg;
    setAddGallaryImg({...addGallaryImg, gallary_img: [...gallary_img, ...e.target.files] });
  };
  function deleteFile(e) {
    const { gallary_img } = addGallaryImg;
    const filter = gallary_img.filter((item, index) => index !== e);
    setAddGallaryImg({...addGallaryImg, gallary_img: filter });
  }


// handle change color
  const colorhandleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    if (checked) {
      setAddColor({ ...addColor, color: [...color, value] });
    }

    // Case 2  : The user unchecks the box
    else {
      setAddColor({
        ...addColor,
        color: color.filter((e) => e !== value),
      });
    }
  };

  const colorQtyhandleChange = (e, index) => {
    const colorQty = color_qty; // Make a copy of the color_qty first.
    colorQty[index] = e.target.value; // Update it with the modified color_qty.
    setAddColor({...addColor,
     color_qty: colorQty,
   });
 
 };
 const sizehandleChange = (e, index) => {
   const sizes = size; // Make a copy of the size first.
   sizes[index] = e.target.value; // Update it with the modified size.
   setAddColor({...addColor,
     size: sizes,
  });
 
 }



  //update color colorqty, size
  const updateColorhandleChange = (e, index) => {
    // Destructuring
    const { value } = e.target;
    const indexData = updateProduct.product_colors[index];
    const { product_colors } = updateProduct;
    const newColor = [...product_colors];
    newColor[index] = { ...indexData, [e.target.name]: value };
    setUpdateProduct({ ...updateProduct, product_colors: newColor });
  };


  // filtering edit color colorqty size
  const FilterAddColor = () => {
    let updatedColorList = colors;
    const productColor =
      updateProduct.product_colors &&
      updateProduct.product_colors.map((item) => item.color_id);
    if (productColor?.length) {
      updatedColorList = updatedColorList.filter(
        (item) => !productColor.includes(item.id)
      );
    }
    return updatedColorList;
  };



  // submit product
  const handleSubmit = (e) => {
    
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append('_method', 'PUT')
    formData.append("product_name", updateProduct.product_name);
    formData.append("product_slug", updateProduct.product_slug);
    formData.append("selling_price", updateProduct.selling_price);
    formData.append("discount_price", updateProduct.discount_price);
    formData.append("qty", updateProduct.qty);
    addColor?.color.forEach((value) => {
      formData.append(`color[]`, value);
    });
    addColor?.color_qty.forEach((value) => {
      formData.append(`color_qty[]`, value);
    });
    addColor?.size.forEach((value) => {
      formData.append(`size[]`, value);
    });
    if(updateProduct?.brand_id !== null ){
      formData.append("brand_id", updateProduct?.brand_id);
    }
    
    formData.append("meta_keyword", updateProduct.meta_keyword);
    formData.append("meta_description", updateProduct.meta_description);
    formData.append("category_id", updateProduct.category_id);
    formData.append("sub_category_id", updateProduct.sub_category_id);
    if(updateProduct.sos_category_id !== null){
      formData.append("sos_category_id", updateProduct.sos_category_id);
    }
   
    formData.append("location", updateProduct.location);
    formData.append(
      "delivery_cost",
      updateProduct.delivery_cost
    );
    formData.append("product_img", updateProduct.product_img);
    addGallaryImg.gallary_img.forEach(value => {
      formData.append(`gallary_img[]`, value);
    });
    formData.append("product_description", updateProduct.product_description);
    formData.append("weekly_offer", updateProduct.weekly_offer === 1 || updateProduct.weekly_offer === true ? 1 : 0);
      formData.append("new_arrival", updateProduct.new_arrival === 1 || updateProduct.new_arrival === true ? 1 : 0);
      formData.append("top_collection", updateProduct.top_collection === 1 || updateProduct.top_collection === true ? 1 : 0);
    axios
      .post(`/product/update/${updateProduct.id}`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
      }

      })
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setValidation_error({});
          setRerendering(!rerendering);
          setAddColor({
            color: [],
            color_qty: [],
            size: [],
          
          });
          document.getElementById("gallary_img").value = '';
          setAddGallaryImg({
            gallary_img:[]
          });
        } else if(res.data.status === 422){
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };


  // update Color, colorqty,size
  const updateColors = (e, id, index) => {

    e.preventDefault();
    setDisableSubmitBtn(true);
    console.log(e, id, index)
    let { color_qty, size } = updateProduct.product_colors[index];
    const formData = new FormData();
    formData.append("product_id", updateProduct.id);
    formData.append("color_qty", color_qty);
    formData.append("size", size);

    axios
      .post(`/product/updateColor/${id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setRerendering(!rerendering);
          setValidation_error({});
        } else if(res.data.status === 422){
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
        else if (res.data.status === 404) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  // delete Colors, qty,size
  const deleteColors = (e, id) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    axios
      .delete(`/product/deleteColor/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          let color = updateProduct.product_colors;
          setUpdateProduct({
            ...updateProduct,
            product_colors: color.filter((item) => item.id !== id),
          });
        } else if (res.data.status === 404) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
        }
      })
      .catch((e) => {});
  };



// delete Gallary Img
const deleteGallaryImg = (e, id) => {
  e.preventDefault();
  setDisableSubmitBtn(true);
  axios
    .delete(`/product/gallary-img/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: "colored",
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        let Image = updateProduct.product_gallary_img;
        setUpdateProduct({
          ...updateProduct,
          product_gallary_img: Image.filter((item) => item.id !== id),
        });
      } else if (res.data.status === 404) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: "colored",
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
      }
    })
    .catch((e) => {});
};







  if (loading) {
    return <Loader/>
  }
  return (
    <>
          {/* seo title */}
          <Helmet>
        <title> Edit || Product</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title='Product' pageTitle='Edit / Products' />
      {/* Main content */}
      <section className='content product_add edit_product'>
        <form id='resetForm'  enctype='multipart/form-date'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='card card-primary'>
                <div className='card-header'>
                  <h3 className='card-title'>General</h3>
                  <div className='card-tools'>
                    <button
                      type='button'
                      className='btn btn-tool'
                      data-card-widget='collapse'
                      title='Collapse'>
                      <i className='fas fa-minus' />
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='form-group'>
                    <label htmlFor='inputName'>Products Name</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='product_name'
                      value={updateProduct.product_name}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.product_name && (
                    <span className='text-danger'>
                      {validation_error.product_name}
                      <br />
                    </span>
                  )}
                  <div className="form-group">
                    <label htmlFor="inputName">Products Slug</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="product_slug"
                      value={updateProduct.product_slug}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  {
                    validation_error.product_slug &&
                    <span className='text-danger'>
                          {validation_error.product_slug}
                    <br/>
                  </span>
                  }
                  <div className='form-group'>
                    <label htmlFor='inputName'>Selling Price</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='selling_price'
                      value={updateProduct.selling_price}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.selling_price && (
                    <span className='text-danger'>
                      {validation_error.selling_price}
                      <br />
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputName'>Discount Price</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='discount_price'
                      value={updateProduct.discount_price}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.discount_price && (
                    <span className='text-danger'>
                      {validation_error.discount_price}
                      <br />
                    </span>
                  )}
                   <div className="form-group">
                        <label htmlFor="inputName">qty</label>
                        <input 
                          className="form-control"
                           type="text"
                           name='qty'
                           value={updateProduct.qty}
                           onChange={handleChange}
                            />
                        </div>
                              {
                    validation_error.qty &&
                    <span className='text-danger'>
                          {validation_error.qty}
                  </span>
                  }

                  <div className='form-group'>
                    <label>Brand</label>
                    <select
                      onChange={handleChange}
                      name='brand_id'
                      value={updateProduct.brand_id}
                      className='form-control'
                      style={{ width: "100%" }}>
                      <option value=''>--Select Brand--</option>

                      {brands?.map((val) => {
                        return (
                          <option
                            value={val.id}
                            key={val.id}
                            {...(updateProduct.brand == val.id
                              ? "selected"
                              : null)}>
                            {val.brand_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {validation_error.brand_id && (
                    <span className='text-danger'>
                      {validation_error.brand_id}
                      <br />
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputName'>Meta Keyword</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='meta_keyword'
                      value={updateProduct.meta_keyword}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.meta_keyword && (
                    <span className='text-danger'>
                      {validation_error.meta_keyword}
                      <br />
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputName'>Meta Description</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='meta_description'
                      value={updateProduct.meta_description}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.meta_description && (
                    <span className='text-danger'>
                      {validation_error.meta_description}
                      <br />
                    </span>
                  )}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}

              {/* extra add color, brand, qty start  */}
              <div className='card card-primary'>
                <div className='card-header'>
                  <h3 className='card-title'>Color, qty, Size</h3>
                  <div className='card-tools'>
                    <button
                      type='button'
                      className='btn btn-tool'
                      data-card-widget='collapse'
                      title='Collapse'>
                      <i className='fas fa-minus' />
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <h4>Add Color</h4>
                  <div className='row border-bottom pb-2'>
                    {/* <!-- checkbox --> */}

                    {FilterAddColor()?.map((color, i) => {
                      return (
                        <div className='col-md-3'>
                          <div className='border p-2'>
                            <div className='form-group'>
                              <label htmlFor='inputName'>Color</label>
                              <div className='form-check border p-2 pl-4'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={color.id}
                                  name='color'
                                  value={color.id}
                                  onChange={colorhandleChange}
                                />
                                <label
                                  className='form-check-label mb-1'
                                  for={color.id}>
                                  {color.color_name}
                                </label>
                              </div>
                            </div>
                            <div className='form-group'>
                              <label htmlFor='inputName'>color qty</label>
                              <input
                                className='form-control'
                                type="number"
                                name='color_qty'
                                onChange={(e)=> colorQtyhandleChange(e, i)}
                                value={color_qty[i] ? color_qty[i] : ""}
                                min='0'
                                max='100'
                                pattern='^([1-5]?[0-9]|100)$'
                                placeholder='0'
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='inputName'>
                                Size(M,L,X,XL,2XL)
                              </label>
                              <input
                                // required
                                type='text'
                                onChange={(e)=>sizehandleChange(e, i)}
                              name="size"
                              id="size"
                              className="form-control"
                              value={size[i] ? size[i] : ""}
                              placeholder='M, L, X, XL, 2XL'
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {validation_error.color && (
                    <span className='text-danger'>
                      {validation_error.color}
                    </span>
                  )}
                  <br />
                  {validation_error.color_qty && (
                    <span className='text-danger'>{validation_error.color_qty}</span>
                  )}
                  <br />
                  {validation_error.size && (
                    <span className='text-danger'>{validation_error.size}</span>
                  )}

                  <div className='show_color_qty_size'>
                    <table
                      id='example1'
                      className='table table-bordered table-striped text-center'>
                      <thead>
                        <tr>
                          <th>Color Name</th>
                          <th>qty</th>
                          <th>Size</th>
                          <th>Update</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {updateProduct?.product_colors?.map((val, index) => {
                          return (
                            <>
                              <tr key={val.id}>
                                <td class='dtr-control'>{val.color?.color_name}</td>
                                <td class='dtr-control'>
                                  <input
                                    className='form-control'
                                    type='number'
                                    name='color_qty'
                                    onChange={(e) =>
                                      updateColorhandleChange(e, index)
                                    }
                                    value={val.color_qty}
                                    min='0'
                                    max='50'
                                    pattern='^([1-5]?[0-9]|50)$'
                                    placeholder='0'
                                  />
                                </td>
                                <td class='dtr-control'>
                                  <input
                                    // required
                                    type='text'
                                    onChange={(e) =>
                                      updateColorhandleChange(e, index)
                                    }
                                    value={val.size}
                                    name='size'
                                    id='inputName'
                                    className='form-control'
                                    placeholder='M, L, X, XL, 2XL'
                                  />
                                </td>
                                <td>
                                  
                                  <button
                                    className='btn btn-info btn-sm p-1'
                                    onClick={(e) => {
                                      updateColors(e, val.id, index);
                                    }}
                                    disabled={disableSubmitBtn}
                                    >
                                    Update
                                  </button>
                                  
                                </td>
                                <td>
                                  <button
                                    className='btn btn-danger btn-sm p-1'
                                    onClick={(e) => {
                                      deleteColors(e, val.id, index);
                                    }}
                                    disabled={disableSubmitBtn}
                                    
                                    >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
              {/* color, brand, qty end  */}
            </div>
            <div className='col-md-6'>
            <div className="row">
                
                <div className="col-md-12 mx-auto">
               <div className="card card-warning">
                 <div className="card-header">
                   <h3 className="card-title">Type</h3>
                   <div className="card-tools">
                     <button
                       type="button"
                       className="btn btn-tool"
                       data-card-widget="collapse"
                       title="Collapse"
                     >
                       <i className="fas fa-minus" />
                     </button>
                   </div>
                 </div>
                 <div className="card-body">
                 <div className="d-inline">
                  <div class="icheck-primary d-inline m-2">
                    
                        <input type="checkbox" name="weekly_offer" 
                        defaultChecked={updateProduct.weekly_offer && updateProduct.weekly_offer === 1 ? true: false}   onChange={handleChangeType}  id="weekly_offer"
                        />
                        <label for="weekly_offer">
                            Weekly-Offer
                        </label>
                      </div>
                      <div class="icheck-primary d-inline m-2">
                        <input type="checkbox" name="new_arrival" 
                        defaultChecked={updateProduct.new_arrival && updateProduct.new_arrival === 1 ? true: false}   onChange={handleChangeType} id="new_arrival"
                        />
                        <label for="new_arrival">
                            New-Arrival
                        </label>
                      </div>
                      <div class="icheck-primary d-inline m-2">
                        <input type="checkbox" name="top_collection" 
                        defaultChecked={updateProduct.top_collection && updateProduct.top_collection === 1 ? true: false}   onChange={handleChangeType} id="top_collection"
                        />
                        <label for="top_collection">
                            Top-Collection
                        </label>
                      </div>
                      </div>
 
                 </div>
                 {/* /.card-body */}
               </div>
               {/* /.card */}
             </div>
                 
               </div>
              <div className='card card-secondary'>
                <div className='card-header'>
                  <h3 className='card-title'>Select</h3>
                  <div className='card-tools'>
                    <button
                      type='button'
                      className='btn btn-tool'
                      data-card-widget='collapse'
                      title='Collapse'>
                      <i className='fas fa-minus' />
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='form-group'>
                    <label htmlFor='inputStatus'>Category</label>
                    <select
                      id='inputStatus'
                      onChange={handleChange}
                      name='category_id'
                      value={updateProduct.category_id}
                      className='form-control'>
                      <option value='' disabled='disabled'>
                        --Select Category--
                      </option>
                      {category &&
                        category.map((val) => {
                          return (
                            <option
                              value={val.id}
                              key={val.id}
                              {...(updateProduct.category_id == val.id
                                ? "selected"
                                : null)}>
                              {val.category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {validation_error.category_id && (
                    <span className='text-danger'>
                      {validation_error.category_id}
                      <br />
                    </span>
                  )}
                  <div class='form-group'>
                    <label>Sub Category</label>
                    <select
                      onChange={handleChange}
                      name='sub_category_id'
                      value={updateProduct.sub_category_id}
                      class='form-control'
                      style={{ width: "100%" }}>
                      <option value='' disabled='disabled'>
                        --Select Sub Category--
                      </option>

                      {subcategory &&
                        subcategory.map((val) => {
                          return (
                            <option
                              value={val.id}
                              key={val.id}
                              {...(updateProduct.sub_category_id == val.id
                                ? "selected"
                                : null)}>
                              {val.sub_category_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {validation_error.sub_category_id && (
                    <span className='text-danger'>
                      {validation_error.sub_category_id}
                      <br />
                    </span>
                  )}
                  <div class='form-group'>
                    <label>SOS Category</label>
                    <select
                      onChange={handleChange}
                      name='sos_category_id'
                      value={updateProduct.sos_category_id}
                      class='form-control'
                      style={{ width: "100%" }}>
                      <option value='' disabled='disabled'>
                        --Select Sos Category--
                      </option>

                      {soscategory &&
                        soscategory.map((val) => {
                          return (
                            <option
                              value={val.id}
                              key={val.id}
                              {...(updateProduct?.sos_category_id == val?.id
                                && "selected"
                                )}>
                              {val.sub_of_soscategory_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {validation_error.sos_category_id && (
                    <span className='text-danger'>
                      {validation_error.sos_category_id}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputStatus'>Location</label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='location'
                      value={updateProduct.location}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.location && (
                    <span className='text-danger'>
                      {validation_error.location}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputStatus'>
                      Free Shipping / Delivery Cost
                    </label>
                    <input
                      // required
                      type='text'
                      onChange={handleChange}
                      name='delivery_cost'
                      value={updateProduct.delivery_cost}
                      id='inputName'
                      className='form-control'
                    />
                  </div>
                  {validation_error.delivery_cost && (
                    <span className='text-danger'>
                      {validation_error.delivery_cost}
                    </span>
                  )}
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Product Image</label>

                    <input
                      // required
                      type='file'
                      className='form-control'
                      accept='image/*'
                      name='product_img'
                      onChange={handleChangeImage}
                      id='customFile1'
                    />
                  </div>
                  {validation_error.product_img && (
                    <span className='text-danger'>
                      {validation_error.product_img}
                      <br />
                    </span>
                  )}
                  {previewImage.product_img && (
                    <img
                      alt='product_img'
                      width='60'
                      src={(window.URL.srcObject = previewImage.product_img)}
                    />
                  )}
                  {!previewImage.product_img && (
                    <img
                      alt='product_img'
                      className='img-fluid w-25'
                      src={
                        updateProduct?.product_img == null
                          ? null
                          : `${process.env.REACT_APP_API_URL}${updateProduct?.product_img}`
                      }
                    />
                  )}
                  <br />
                  <div className='form-group'>
                    <label htmlFor='inputClientCompany'>Gallary Image</label>

                    <input
                      // required
                      multiple 
                      type='file'
                      className='form-control'
                      accept='image/*'
                      name='gallary_img'
                      onChange={handleChangeGallaryImage}
                      id='gallary_img'
                    />
                  </div>
                  <div className="form-group preview">
        {addGallaryImg.gallary_img.length > 0 &&
          addGallaryImg.gallary_img.map((item, index) => {
            return (
              <div key={item}>
                 <img
                    alt='preview-img'
                    width='60'
                    src={URL.createObjectURL(item)}
                  />
                <button type="button"   className="btn btn-sm btn-danger m-2" onClick={() => deleteFile(index)}>
                  delete
                </button>
              
                    
                    { !item.type.match(imageMimeType) && 
                      <span className='text-danger'>
                      The product img must be an image.The product img must be a file of type: jpeg, png, jpg, gif, svg.
                      <br/>
                     </span>

                    }
                    { item.size > 2097152 && 
                      <span className='text-danger'>
                      The product img must not be greater than 2048 kilobytes.
                      <br/>
                     </span>

                    }
              </div>
              
            );

          })}
      </div>


                  <div className='row'>
                  <div className='col-12 product-image-thumbs my-3'>
                    {
                      updateProduct?.product_gallary_img?.map((val)=>{
                        return(
                          <>

                        <div className="product-image-thumb position-relative" key={val.id}>
                      
                    <img src={
                        val?.gallary_img == null
                          ? null
                          : `${process.env.REACT_APP_API_URL}${val?.gallary_img}`
                      } alt="Product" />
                      <button
                            className='btn btn-danger btn-sm p-1'
                           onClick={(e) => {
                              deleteGallaryImg(e, val.id);
                           }}
                          disabled={disableSubmitBtn}>
                                    Delete
                           </button>
                     
                  </div>
                      

                    
                          </>
                        )

                      })
                    }
                  
                  </div>
                  </div>
                    


                  
                  <br />
                  <div className='editor-sec'>
                    <label htmlFor='editor'>Product Description</label>
                    <EditorEdit
                      name='desciption'
                      handleChange={handleDesciption}
                      value={updateProduct.product_description}
                    />
                  </div>
                  {validation_error.product_description && (
                    <span className='text-danger'>
                      {validation_error.product_description}
                      <br />
                    </span>
                  )}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>

          <div className='row'>
            <div className='col-12 py-4'>
              <buttton
                onClick={handleSubmit}
                className='btn btn-success btn-block mx-auto w-75'
                disabled={disableSubmitBtn}
                >
                <i class='fas fa-paper-plane'></i> Submit
              </buttton>
            </div>
          </div>
        </form>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditProductWrapper;
