import React from 'react'
import EditPermissionSec from '../../../Components/admin/Role_&_Permission/EditPermissionSec'

const EditPermission = () => {
  return (
    <>
    <EditPermissionSec/>
    </>
  )
}

export default EditPermission