import React, {useState, useEffect} from 'react'
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { Link} from "react-router-dom";
const AddSubCategoryblog = () => {

  const [insertSubcategory_blog, setInsertSubcategory_blog] = useState({
    category_id: "",
    sub_category_name: "",
    sub_category_slug: "",
  });

  const { category_id, sub_category_name, sub_category_slug } = insertSubcategory_blog;

//  get categories option  data
const [blog_categories, setblog_Categories] = useState([]);
const [loading, setloading] = useState(true);

// backend validation check
const [validation_error, setValidation_error] = useState([]);

// get data
useEffect(() => {
  axios.get("/blog/category/all").then((res) => {
    if (res.data.status === 200) {
      setblog_Categories(res.data.blog_categories);
    }
    setloading(false);
  });
  return () => {
    setblog_Categories([]);
  };
}, []);

 
 
  const handleChange = (e) => {
    setInsertSubcategory_blog({ ...insertSubcategory_blog, [e.target.name]: e.target.value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    const data = insertSubcategory_blog;
    axios
      .post(`/blog/subcategory/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInsertSubcategory_blog({
            category_id: "",
    sub_category_name: "",
    sub_category_slug: "",
          });
          
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
  };
    if (loading) {
    return <h2>loading....</h2>;
  }
 

  return (
    <>
       {/* seo title */}
        <Helmet>
        <title> Add || Blog-Subcategories</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Sub-Categories"
        pageTitle="Add / Sub-Categories"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Sub Category</h3>
                <div className="card-tools">
                <Link
                    to='/our-dashboard/admin/blog/subcategories'
                    className='btn btn-success btn'>
                    All Blog-Subcategories
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Category </label>
                    <select
                      class="form-control"
                      name="category_id"
                      value={category_id}
                      onChange={handleChange}
                      id="exampleFormControlSelect1"
                    >
                      <option value='' disabled="disabled" >--Select Categories--</option>
                      {blog_categories && blog_categories.map((val)=>{
                        return (
                        <option value={val.id}>{val.category_name}</option>
                        );
                      })}
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.category_id}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">
                      Sub Category 
                    </label>
                    <input
                    // required
                      type="text"
                      name="sub_category_name"
                      value={sub_category_name}
                      onChange={handleChange}
                      id="inputClientCompany"
                      className="form-control"
                      placeholder="Enter Sub Category"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.sub_category_name}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Slug</label>
                    <input
                    // required
                      type="text"
                      name="sub_category_slug"
                      value={sub_category_slug}
                      onChange={handleChange}
                      id="inputClientCompany"
                      className="form-control"
                      placeholder="Enter Slug"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.sub_category_slug}
                        </span>
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddSubCategoryblog;
