import React from 'react'

const Footer = () => {
  return (
    <>
      <footer class="main-footer">
      <strong>Copyright© 2018-2022 <a href="http://ourrajshahi.com/" target="_blank" rel="noreferrer" >OurRajshahi</a> || Powered by: <a href="/https://powerwebit.com" target="_blank" rel="noreferrer" >PowerWeb IT</a> || All Rights Reserved.</strong>
  </footer>
    </>
  )
}

export default Footer