import React from 'react'
import CommingSoonSection from '../../../Components/frontend/CommingSoonSection/CommingSoonSection'
const text=['Do you want to be a community builder?','Community is Coming Soon!']
const Community = () => {
    return (
        <>
            <CommingSoonSection
              typed_text={text}
            />
        </>
    )
}

export default Community
