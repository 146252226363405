import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import ShopSec from './../ShopSec';

const CategoryByShop = (props) => {
  
  const [loading, setloading] = useState(true);
  const [product, setProduct] = useState([]);
  const {category_slug} = useParams();
  const history =useNavigate();



  useEffect(() => {
    let isMounted = true;
    props.onTopProgress(30);
    const fetchData = async ()=>{
    try {
      setloading(true);
      const res = await axios.get(`/shop/${category_slug}`);
      if(isMounted){
        if (res.data.status === 200) {
          setProduct(res.data.product_data.products);
          props.onTopProgress(70);
        }else if(res.data.status === 400){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history("/");
        }else if(res.data.status === 404){
          toast.error(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history("/");
        }
        setloading(false)
        props.onTopProgress(100);
      }
        
    } catch (e) {
       console.log(e);
    }
  }
  fetchData();
  return () => {
    isMounted = false;
  }
  }, [category_slug]);



  return (
    <>
     <ShopSec  
      product={product}
      loading={loading}   />
    </>
  );
};

export default CategoryByShop;
