import React, { useContext } from "react";
import cartNoteContext from "../Context/Cart/cartNoteContext";

const OrderPlace = () => {
  const { totalAmountCart, itemCart, couponDiscount, totalDeliveryCharge } = useContext(cartNoteContext);
  return (
    <>
      <div className='order_place_wraper'>
        <span className='fs-5 fw-bold  pb-2'>Your order</span>

        <div className='order_place border px-3 py-2'>
          <div className='d-flex justify-content-between'>
            <span className='fs-5 fw-bold'>Product</span>
            <span className='fs-5 fw-bold'>Subtotal</span>
          </div>
          <div className='product_name_wrap'>
            {itemCart.map((val) => {
              const {product, product_qty}=val;
              return (
                <div
                  className='d-flex justify-content-between border pt-1 mt-2  px-2'
                  key={val?.id}>
                  <span className='fw-normal text-dark'>
                    {product?.product_name}
                    <br />
                    <span className='text-info  mb-1'>x {product_qty}</span>
                  </span>
                  <span className='product_total'>
                    ৳ {product?.selling_price - product?.discount_price  * product_qty}
                  </span>
                </div>
              );
            })}
          </div>

          <div className='d-flex justify-content-between py-2 tatal-sub-amount-wrap'>
            <span className='fs-6 fw-bold'>Subtotal:</span>
            <span className='text-danger fs-5 fw-bold'>৳ {totalAmountCart}</span>
          </div>
          {
          couponDiscount &&
          <div className='d-flex justify-content-between py-2 tatal-sub-amount-wrap'>
            <span className='fs-6 fw-bold'>Discount:</span>
            <span className='text-danger fs-5 fw-bold'>৳  {couponDiscount?.discount_price}</span>
          </div>
          }

          <div className='d-flex justify-content-between py-2 border-bottom'>
            <span className='fs-6 fw-bold'>
              Shipping
              <p className='delivery_charge mb-1'>
                Delivery Charge:
              </p>
            </span>
            <span className='text-danger pt-4 fw-bold'>
              ৳ {totalDeliveryCharge}
            </span>
          </div>
          <div className='d-flex justify-content-between p-2'>
            <span className='total fs-6 fw-bold'>Total:</span>
            <span className='total_amount fs-5 text-danger fw-bold '>
              <strong> ৳  {
                    couponDiscount?.discount_price ?
                     (totalAmountCart - couponDiscount?.discount_price) + totalDeliveryCharge
                    :
                     totalAmountCart + totalDeliveryCharge
                  }</strong>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPlace;
