import React, {useState, useEffect} from 'react'
import axios from "axios";
import { Link } from "react-router-dom";
import { toast,Zoom} from 'react-toastify';
import PageHeaderBreadCrumb from "../../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import {Helmet} from "react-helmet";
import EditorEdit from "../../../Editor/Editor";

const AddServicePlanSec = () => {
  const [insert, setInsert] = useState({
    name: "",
    special_service_id:"",
    title: "",
    image: "",
    detail: "",
    price: "",
    price_time: "",
  });
  const { name, special_service_id, title, image, detail, price, price_time } =
    insert;


    //  get Special service option  data
    const [specialService, SetSpecialService] = useState([]);

 // backend validation check
 const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const handleChange = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.files[0],
    });
  };


  const handleDetails = (newtext: any) => {
    setInsert({
      ...insert,
      detail: newtext,
    });
    
  };


  // get data
useEffect(() => {
  let isMounted = true;
  axios.get("/setting/special-service/all").then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      SetSpecialService(res.data.special_service);
    }

    }
  });
  return () => {
    isMounted = false;
  }
}, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("title", title);
    formData.append("special_service_id", special_service_id);
    formData.append("image", image);
    formData.append("detail", detail);
    formData.append("price", price);
    formData.append("price_time", price_time);

    axios
    .post(`/service-plan/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setInsert({
          name: "",
          special_service_id:"",
          title: "",
          image: "",
          detail: "",
          price: "",
        });
         setValidation_error({});
        document.getElementById("resetForm").reset();
       
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});
  };
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title>Add || Service-Plan</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Add-Special-Service" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Service Plan</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/all-service-plan"
                    className="btn btn-success btn"
                  >
                    All Service Plan
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="name">Plan-Name</label>
                    <input
                    // required
                      type="text"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={name}
                      className="form-control"
                      placeholder="Enter Plan Name"
                    />
                  </div>
                  {
                    validation_error.name &&
                    <span className='text-danger'>
                          {validation_error.name}
                      
                      </span>
                  }

                  <div class="form-group">
                    <label for="special_service_id">Special-Service </label>
                    <select
                      class="form-control"
                      name="special_service_id"
                      value={special_service_id}
                      onChange={handleChange}
                      id="special_service_id"
                    >
                      <option value='' disabled="disabled" >--Select Special-Service--</option>
                      {specialService && specialService.map((val)=>{
                        return (
                        <option value={val.id}>{val.slug}</option>
                        );
                      })}
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.special_service_id}
                        </span>

                   <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    // required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={title}
                      className="form-control"
                      placeholder="Enter title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }

                 
                  <div className="form-group">
                    <label htmlFor="img">Image</label>

                    <input
                    // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(image)}
                  />
                   }
                  
                  <div className="form-group">
                    <label htmlFor="inputslug">Price</label>
                    <input
                    // required
                      type="text"
                      id="inputalt"
                      name="price"
                      onChange={handleChange}
                      value={price}
                      className="form-control"
                      placeholder="Enter price"
                    />
                  </div>
                  {
                    validation_error.price &&
                    <span className='text-danger'>
                          {validation_error.price}
                      <br />
                      </span>
                  }


                  
                         <div class="form-group">
                    <label for="price_time">Price Time</label>
                    <select
                      class="form-control"
                      name="price_time"
                      value={price_time}
                      onChange={handleChange}
                      id="price_time"
                    >
                       <option value='' disabled="disabled" >--Select Price Time--</option>
                        <option value='day'>Day</option>
                        <option value='week'>Weekly</option>
                        <option value='half month'>Half Month</option>
                        <option value='month'>Month</option>
                        <option value='half year'>Half Year</option>
                        <option value='year'>Year</option>
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.price_time}
                        </span>

                  <label htmlFor="thumbnail_image">Plan-Details</label>
                     <EditorEdit
                      name="community"
                      handleChange={handleDetails}
                      value={detail}              />

                {
                    validation_error.detail &&
                    <span className='text-danger'>
                          {validation_error.detail}
                      <br />
                      </span>
                  }



                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto my-2"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
}

export default AddServicePlanSec;
