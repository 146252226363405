import React from 'react'
import ChangePasswordSec from '../../../Components/admin/ProfileSection/ChangePasswordSec'

const ChangePassword = () => {
  return (
    <>
    <ChangePasswordSec/>
    </>
  )
}

export default ChangePassword