const PaymentMethodSelectData = [
    // {
    //     payment_img:"assest/image/payment/master-card.png",
    //     text:"master-card"
    // },
    // {
    //     payment_img:"assest/image/payment/visa.png",
    //     text:"visa"
    // },
    {
      payment_img:"assest/image/payment/bkash.png",
      text:"bkash"
    },
    {
      payment_img:"assest/image/payment/nagad.png",
      text:"nagad"
   },
    // {
    //     payment_img:"assest/image/payment/rocket.png",
    //     text:"rocket"
    // },
    {
        payment_img:"assest/image/payment/Cash.png",
        text:"cash-on-delivery"
    }
  
  ];

  export default PaymentMethodSelectData