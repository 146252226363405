import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Help.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import Helmet from "react-helmet"

const Help = (props) => {
  const [help, setHelp] = useState([]);

// get data
useEffect(() => {
  let isMounted = true;
  props.onTopProgress(30);
  const fetchData = async () => {
    try {
    const res = await axios.get("/help");
    if(isMounted){
      if (res.data.status === 200) {
        setHelp(res.data.help);
        props.onTopProgress(70);
      }
      props.onTopProgress(100);
    }  
   } catch (e) {
      console.log(e);
   }
    
 }


 fetchData();
  
  return () => {
    isMounted = false;
  }
}, []);



  return (
    <>
      {/* seo meta tag  start */}
      <Helmet>
        ‍<title>Help</title>
         <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content={help} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content={help}
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={help}
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content={help}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content={help}
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
      </Helmet> 
      {/* seo meta tag  end */}

      <div className='help_section py-4 bg-light overflow-hidden'>
        <div className='container'>
          <h4 className='title text-center py-2'> Help</h4>
          <div className='row'>
            <div className='col-12'>
              <MDBCard className='shadow-2'>
                <MDBCardBody className='px-5'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: 
                        help == null
                        ? ""
                        : 
                        help && help,
                      }}
                    />
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Help;
