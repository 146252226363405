import React from 'react'
import DraftProductSec from '../../../../Components/admin/ProductSection/DraftProductSec'

const DraftProducts = () => {
  return (
    <>
    <DraftProductSec/>
    </>
  )
}

export default DraftProducts