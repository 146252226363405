import React, {useEffect} from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import Lottie from "lottie-react";
import data from "./payment-failed.json";
const Failed = () => {
  const style = {
    height: 200,
    margin: "auto",
    display: "block",
  };
  const [searchParams, setsearchParams] = useSearchParams();
  const code = searchParams?.get("code");
  const message = searchParams?.get("message");
  const history =useNavigate();


    // get data
    useEffect(() => {
      let isMounted = true;

        try {
         if(isMounted){
          if(!code || !message){
            history("/");
          }
         }  
         
        } catch (e) {
           console.log(e);
        }
    
      return () => {
        isMounted = false;
      }
    }, []);

 

  return (
    <>
      <div className='payment_failed py-5 bg-light overflow-hidden'>
        <div className='container'>
          <div className='bg-white'>
            <MDBCard className='text-center'>
              <MDBCardBody className='my-2'>
                <MDBCardTitle className="text-danger fw-bold fs-3">{code}</MDBCardTitle>
                <MDBCardText className='text-danger fw-bold fs-2'>{message}</MDBCardText>
                <Lottie className="img-fuild mx-auto" animationData={data} loop={true} autoplay={true} style={style} />
                <MDBBtn color='info mt-3'>
                  <Link className='text-white' to='/'>
                    Back to Home
                  </Link>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Failed;
