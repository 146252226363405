import React from 'react'
import EditSpecialServiceSec from './../../../../Components/admin/Settings/SpecialService/EditSpecialServiceSec';


const EditSpecialServiceSetting = () => {
  return (
    <>
    <EditSpecialServiceSec/>
    </>
  )
}

export default EditSpecialServiceSetting