import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { toast, Zoom } from "react-toastify";
import Loader from '../../../frontend/Loader/Loader';
import {Helmet} from "react-helmet";

const AllReviewProduct = () => {
 //  get  data
 const [productReview, setProductReview] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;
   
   axios.get("/product/review/all").then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setProductReview(res.data.product_reviews);
     }
     setloading(false);
    }
   }).catch(error => {});
  
  return () => {
    isMounted = false
  }
 }, []);

 useEffect(() => {
  // initialize datatable
  if (!loading) {
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  }
}, [loading]);


// status verify review
const updateStatus = (id) => {
const formData = new FormData();
formData.append("status", 0);
axios
.post(`/product/review/update_status/${id}`, formData)
.then((res) => {
  if (res.data.status === 200) {
    toast.success(res.data.message, {
      position: "top-center",
      theme: 'colored',
      transition: Zoom,
      autoClose: 200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

        setProductReview(
          productReview.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

const handleDeleteItems = (id) => {
  axios
    .delete(`/product/review/delete/${id}`)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: 'colored',
          transition: Zoom,
          autoClose: 200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setProductReview(
          productReview.filter((item) => item.id !== id)
        );
      }
    })
    .catch((e) => {});
};

if (loading) {
  return  <Loader/>
}
  return (
    <>
       {/* seo title */}
         <Helmet>
        <title> All || Product-Review</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Review" pageTitle="All/ Product-Review" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body">
                  <Link
                    to='/our-dashboard/product/review/draft'
                    className='btn btn-danger btn float-right'>
                    Draft Review
                  </Link>
            <table id="example1" className="table table-bordered table-striped text-center">
              <thead>
                <tr >
                <th  >
                  SL
                  </th>
                  <th >
                  Review
                  </th>
                  <th >
                   Ratting
                  </th>
                  <th >
                  User 
                  </th>
                  <th >
                   Status
                  </th>
                  <th >
                  Action
                  </th>
                  <th >
                    View
                  </th>
                  {/* <th >
                    Edit
                  </th> */}
                  <th >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {productReview && productReview.map((review) => {
                 
                    return (
                      <tr key={review.id}>
                        <td class="dtr-control">{review.id}</td>
                       
                        <td>{review.review}</td>
                        <td>{review.rating}</td>
                        <td>{review.user?.name}</td>
                        <td><span className="p-1 rounded bg-success">
                         {review.status === 1 ? "Actived" : "Deactived"} 
                        </span></td>
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(review.id);
                                      }}
                                      >
                          Deatived Now
                        </span>
                      </td>
                        <td>
                          <Link
                            to={`/our-dashboard/product/review/view-reply/${review.id}`}
                            className="btn btn-primary btn-sm p-2 "
                            
                          >
                            Reply
                          </Link>
                        </td>

                        <td>
                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${review.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${review.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(review.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllReviewProduct;
