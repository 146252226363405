import React, { useContext, useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBProgress,
  MDBProgressBar,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBTextArea,
  MDBIcon,
  MDBTypography,
  MDBBtn,
  MDBInput,
  MDBBadge,
} from "mdb-react-ui-kit";
import axios from "axios";
import { Zoom, toast } from "react-toastify";
import authContext from "./../../auth/Context/AuthUser/authContext";

const RattingFillter = (props) => {
  // auth login context
  const { Login, currentUser } = useContext(authContext);
  const { proId, orderCheck, starRating, averageRating } = props;

  const [basicActive, setBasicActive] = useState("tab3");
  const handleBasicClick = (value: string) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  //  get product-review data
  const [verifyReview, setVerifyReview] = useState([]);
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  //  product review
  const [addReview, setAddReview] = useState({
    review: "",
    rating: "",
  });
  const { review, rating } = addReview;
  // review reply show hide
  const [show_reply, setShow_reply] = useState(null);

  //  reply with reply show hide
  const [show_reply_with_reply, setShow_reply_with_reply] = useState(null);

  //  blog comments reply
  const [reviewReply, setReviewReply] = useState({
    review_id: "",
    review_reply: "",
  });

  const handleChange = (e) => {
    setAddReview({ ...addReview, [e.target.name]: e.target.value });
  };

  const ratingChanged = (newRating) => {
    setAddReview({ ...addReview, rating: newRating });
  };

  const handleChangeReply = (e) => {
    setReviewReply({ ...reviewReply, [e.target.name]: e.target.value });
  };

  const handleToggleReply = (review_id, user_name) => {
    show_reply === review_id ? setShow_reply(null) : setShow_reply(review_id);

    show_reply === review_id
      ? setReviewReply({ ...reviewReply, review_id: "", review_reply: "" })
      : setReviewReply({
          ...reviewReply,
          review_id: review_id,
          review_reply: `@${user_name} `,
        });

    //  comment reply click  reply input hide
    if (review_id) {
      setShow_reply_with_reply(null);
    }
  };

  const handleToggleReplywithReply = (reply_id, review_id, user_name) => {
    show_reply_with_reply === reply_id
      ? setShow_reply_with_reply(null)
      : setShow_reply_with_reply(reply_id);
    show_reply_with_reply === reply_id
      ? setReviewReply({ ...reviewReply, review_id: "", review_reply: "" })
      : setReviewReply({
          ...reviewReply,
          review_id: review_id,
          review_reply: `@${user_name} `,
        });

    //  reply id click  comment input hide
    if (reply_id) {
      setShow_reply(null);
    }
  };

  // get data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const res = await axios.get(`/verify-product-review/${proId}`);
        if (isMounted) {
          if (res.data.status === 200) {
            setVerifyReview(res.data.product_reviews);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [rerendering, Login]);

  // product-review submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("product_id", proId);
    formData.append("review", review);
    formData.append("rating", rating);

    axios
      .post(`/product/add-review`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setAddReview({
            review: "",
            rating: "",
          });
          setValidation_error({});
          setDisableSubmitBtn(false);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  // product-review-reply submit
  const handleSubmitReply = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("product_id", proId);
    formData.append("review_id", reviewReply.review_id);
    formData.append("review_reply", reviewReply.review_reply);

    axios
      .post("/product/add-review-reply", formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: "colored",
            transition: Zoom,
            autoClose: 400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setReviewReply({
            product_id: "",
            review_reply: "",
          });
          setRerendering(!rerendering);
          setValidation_error({});
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className='customer-overall-review py-2' id='view_rating'>
        <MDBTabs className='mb-1'>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab1")}
              active={basicActive === "tab1"}>
              OVERVIEW
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab2")}
              active={basicActive === "tab2"}>
              SPECIFICATION
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              onClick={() => handleBasicClick("tab3")}
              active={basicActive === "tab3"}>
              CUSTOMER RVIEW(30K)
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>
      </div>
      <MDBTabsContent>
        <MDBTabsPane show={basicActive === "tab1"}>
          <div className='overview-check'>
            <img
              style={{ width: "50%", height: "100px" }}
              src='/assest/image/product_banner/product_banner.jpg'
              alt=''
            />
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === "tab2"}>
          <div className='specifications-view-check'>
            <li>specificarions</li>
            <li>specificarions</li>
            <li>specificarions</li>
            <li>specificarions</li>
            <li>specificarions</li>
          </div>
        </MDBTabsPane>
        <MDBTabsPane show={basicActive === "tab3"}>
          <div
            className='mx-auto d-block'
            style={{ margin: "auto", display: "block" }}>
            <div className='bg-white shadow-3 rounded mx-auto p-2'>
              <div className='row'>
                <div className='col-10 col-md-6'>
                  {/* start rating percentage */}
                  <div className='text-center mx-auto d-block'>
                    <span className='fs-2'>{Number(averageRating?.average).toFixed(2)}</span>
                    <span className='fw-normal text-black-50 fs-5'>/5</span>
                    <ReactStars
                    classNames='text-center mx-auto d-block'
                                    count={5}
                                    size={25}
                                    isHalf={true}
                                    edit={false}
                                    value={averageRating?.average}
                                    emptyIcon={<i className='far fa-star'></i>}
                                    halfIcon={<i className='fa fa-star-half-alt'></i>}
                                    fullIcon={<i className='fa fa-star'></i>}
                                    activeColor='#ffa900'
                                  />
                      <p>{averageRating.total_review} Rating</p>
                  </div>

                </div>
                <div className='col-10 col-md-6'>
                  {
                    starRating?.map((val, i)=>{
                      return (
                        <>
                        <div className='d-flex'>  
                        <MDBProgress height='23' className='w-100 border rounded mb-2' key={i}>
                        <MDBProgressBar  width={val?.percent.toFixed(2)} bgColor='warning' valuemin={0} valuemax={100}>
                        {val?.percent.toFixed(2) } % 
                        </MDBProgressBar>
                      </MDBProgress>
                       <div className='rating_count ms-3'>{val?.count}</div>
                        </div>
                        </>
                      )
                    })
                  }

                  </div>
              </div>

            </div>
          </div>

          {/*   -----add rating and comment -------   */}
          {orderCheck?.delivered === true && (
            <>
              <div className='my-3 add_rating_comment'>
                <span>Add Rating</span>
                <ReactStars
                  count={5}
                  size={40}
                  isHalf={true}
                  value={rating}
                  emptyIcon={<i className='far fa-star'></i>}
                  halfIcon={<i className='fa fa-star-half-alt'></i>}
                  fullIcon={<i className='fa fa-star'></i>}
                  activeColor='#ffa900'
                  onChange={ratingChanged}
                />
                {validation_error?.rating && (
                  <span className='text-danger text-start mb-1'>
                    {validation_error?.rating}
                  </span>
                )}

                <form onSubmit={handleSubmit}>
                  <div>
                    <MDBTextArea
                      label='Add Your Comment'
                      id='review'
                      name='review'
                      onChange={handleChange}
                      value={review}
                      rows={3}
                      style={{ backgroundColor: "#fff" }}
                      wrapperClass='w-100'
                    />
                    {validation_error?.review && (
                      <span className='text-danger text-start'>
                        {validation_error?.review}
                      </span>
                    )}
                  </div>
                  <MDBBtn
                    type='submit'
                    className='mt-2 float-end'
                    color='info'
                    disabled={disableSubmitBtn}>
                    <i className='far fa-paper-plane'></i>&nbsp; Submit
                  </MDBBtn>
                </form>
              </div>
              <br />
              <br />
            </>
          )}

          {/* product reviews  */}
          <div className='my-3 p-2 bg-white rounded'>
            <div className='d-flex justify-content-between align-items-center mb-3 border-bottom'>
              <MDBTypography tag='h5' className='text-dark mb-0'>
                Product Reviews
              </MDBTypography>
              <MDBCard></MDBCard>
            </div>
             
             {
              verifyReview?.length > 0 &&
            <MDBCard className='mb-3 mx-4 shadow-2'>
              <MDBCardBody>
                {verifyReview?.map((review) => {
                  return (
                    <>
                      <div className='d-flex flex-start border-bottom mb-2'>
                        <MDBCardImage
                          className='rounded-circle shadow-1-strong me-3'
                          src={`${process.env.REACT_APP_API_URL}${review?.user?.profile_image}`}
                          alt='avatar'
                          width='40'
                          height='40'
                        />

                        <div className='w-100'>
                          <div className='d-flex justify-content-between align-items-center mb-3'>
                            <MDBTypography
                              tag='h6'
                              className='text-primary fw-normal mb-0'>
                              {review?.user?.name}
                              &nbsp;
                              <MDBIcon far icon='check-circle text-success' />
                            </MDBTypography>
                            <p className='mb-0'>
                              {moment(review?.created_at).fromNow()}
                            </p>
                          </div>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div
                              className='small mb-0'
                              style={{ color: "#aaa" }}>
                                 <ReactStars
                                    count={5}
                                    size={30}
                                    isHalf={true}
                                    edit={false}
                                    value={review?.rating}
                                    emptyIcon={<i className='far fa-star'></i>}
                                    halfIcon={<i className='fa fa-star-half-alt'></i>}
                                    fullIcon={<i className='fa fa-star'></i>}
                                    activeColor='#ffa900'
                                  />
                              <p>{review?.review}</p>
                            </div>
                            {Login && currentUser?.id === review?.user?.id && (
                              <div className='d-flex flex-row'>
                                <a
                                  href='#!'
                                  onClick={() =>
                                    handleToggleReply(
                                      review?.id,
                                      review?.user?.name
                                    )
                                  }
                                  className='link-muted'>
                                  <MDBIcon fas icon='reply me-1' /> Reply
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* comment reply show hide  */}
                      {show_reply === review?.id && (
                        <>
                          <div className='d-flex flex-start w-100'>
                            <MDBCardImage
                              className='rounded-circle shadow-1-strong me-3'
                              src={`${process.env.REACT_APP_API_URL}${review?.user?.profile_image}`}
                              alt='avatar'
                              width='30'
                              height='30'
                            />
                            {/* comment reply form */}
                            <form
                              className='w-100'
                              onSubmit={handleSubmitReply}>
                              <div>
                                <MDBInput
                                  label='Add Your Reply'
                                  id='review_reply'
                                  textarea
                                  rows={4}
                                  name='review_reply'
                                  onChange={handleChangeReply}
                                  value={reviewReply.review_reply}
                                  wrapperClass='w-100'
                                />
                              </div>
                              {validation_error?.review_reply && (
                                <span className='text-danger text-start'>
                                  {validation_error?.review_reply}
                                </span>
                              )}
                              <div className='float-end mt-2 pt-1'>
                                <MDBBtn
                                  type='submit'
                                  size='sm'
                                  color='info'
                                  className='me-1'>
                                  Reply
                                </MDBBtn>
                              </div>
                            </form>
                          </div>
                        </>
                      )}

                      {/* show verify review reply */}
                      {review?.verify_product_review_reply?.map((verify_reply) => {
                        return (
                          <>
                            <div className='d-flex flex-start border-bottom mb-2 ms-5'>
                              <MDBCardImage
                                className='rounded-circle shadow-1-strong me-3'
                                src={`${process.env.REACT_APP_API_URL}${verify_reply?.user?.profile_image}`}
                                alt='avatar'
                                width='40'
                                height='40'
                              />

                              <div className='w-100'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                  <MDBTypography
                                    tag='h6'
                                    className='text-primary fw-normal mb-0'>
                                    {review?.user?.name}
                                    &nbsp;
                                    <MDBIcon
                                      far
                                      icon='check-circle text-success'
                                    />
                                  </MDBTypography>
                                  <p className='mb-0'>
                                    {moment(verify_reply?.created_at).fromNow()}
                                  </p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div
                                    className='small mb-0'
                                    style={{ color: "#aaa" }}>
                                    <p>{verify_reply?.review_reply}</p>
                                  </div>
                                  <div className='d-flex flex-row'>
                                <a
                                  href='#!'
                                  onClick={() =>
                                    handleToggleReplywithReply(
                                      verify_reply?.id,
                                      review.id,
                                      verify_reply?.user?.name
                                    )
                                  }
                                  className='link-muted'>
                                  <MDBIcon fas icon='reply me-1' /> Reply
                                </a>
                              </div>
                                </div>
                              </div>
                            </div>

                            {/* comment reply with reply show hide  */}
                            {show_reply_with_reply === verify_reply?.id && (
                              <>
                                <div className='d-flex flex-start w-100'>
                                  <MDBCardImage
                                    className='rounded-circle shadow-1-strong me-3'
                                    src={`${process.env.REACT_APP_API_URL}${review?.user?.profile_image}`}
                                    alt='avatar'
                                    width='30'
                                    height='30'
                                  />
                                  {/* comment reply form */}
                                  <form
                                    className='w-100'
                                    onSubmit={handleSubmitReply}>
                                    <div>
                                      <MDBInput
                                        label='Add Your Reply'
                                        id='review_reply'
                                        textarea
                                        rows={4}
                                        name='review_reply'
                                        onChange={handleChangeReply}
                                        value={reviewReply.review_reply}
                                        wrapperClass='w-100'
                                      />
                                    </div>
                                    {validation_error?.review_reply && (
                                      <span className='text-danger text-start'>
                                        {validation_error?.review_reply}
                                      </span>
                                    )}
                                    <div className='float-end mt-2 pt-1'>
                                      <MDBBtn
                                        type='submit'
                                        size='sm'
                                        color='info'
                                        className='me-1'>
                                        Reply
                                      </MDBBtn>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}
                          </>
                        );
                      })}

                      {/* show pending review reply */}
                      {review?.pending_product_review_reply?.map((pending) => {
                        return (
                          <>
                            <div className='d-flex flex-start border-bottom mb-2 ms-5'>
                              <MDBCardImage
                                className='rounded-circle shadow-1-strong me-3'
                                src={`${process.env.REACT_APP_API_URL}${pending?.user?.profile_image}`}
                                alt='avatar'
                                width='40'
                                height='40'
                              />
                              <div className='w-100'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                  <MDBTypography
                                    tag='h6'
                                    className='text-primary fw-normal mb-0'>
                                    {review?.user?.name}
                                  </MDBTypography>
                                  <p className='mb-0'>
                                    {moment(pending?.created_at).fromNow()}
                                  </p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div
                                    className='small mb-0'
                                    style={{ color: "#aaa" }}>
                                    <p>{pending?.review_reply}</p>
                                  </div>
                                  <div className='d-flex flex-row'>
                                    <MDBBadge color='warning' pill>
                                      pending
                                    </MDBBadge>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </MDBCardBody>
            </MDBCard>
          }
          </div>
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};

export default RattingFillter;
