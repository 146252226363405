import React, { useState, useEffect } from "react";
import axios from "axios";
import Helmet from "react-helmet"
import "./FAQ.css";
import {
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";


const FAQpage = (props) => {
    const [faq, setFaq] = useState([]);

  
  
  // get data
  useEffect(() => {
    let isMounted = true;
    props.onTopProgress(30);
    const fetchData = async () => {
      try {
      const res = await axios.get("/faq");
      if(isMounted){
        if (res.data.status === 200) {
          setFaq(res.data.faq);
          props.onTopProgress(70);
        }
        props.onTopProgress(100);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }
  
  
   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);
  
  
  
  
    return (
      <>
      {/* seo meta tag  start */}
<Helmet>
        ‍<title>FAQ</title>
         <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content={faq} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />




         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content={faq}
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={faq}
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content={faq}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content={faq}
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
      </Helmet> 
      {/* seo meta tag  end */}



        <div className='faq_page py-4 bg-light overflow-hidden'>
          <h4 className='title text-center py-2'> FAQ</h4>
          <div className='container'>
            <div className='row '>
              <div className='col-12 mx-auto pt-3'>
                <MDBCard className='shadow-1 '>
                  <MDBCardBody>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: faq == null ?
                          ""
                          :faq && faq,
                        }}
                      />
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
export default FAQpage
