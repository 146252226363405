import React from "react";
import "./StoreSection.css";
import { MDBCard, MDBCardBody  } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import Title from "../../SectionTitle/Title/Title";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import { Collectiondata } from "./data";


const DataCollectionSection = () => {

  return (
    <>
      <div className='data_collection_sec py-2'>
        <div className='container'>
          <Title section_title='Store' />
          <Titleimg />
          <div className='row bg-white g-2'>
            {Collectiondata.map(
              ({ img, store_name, store, product, store_details }, index) => {
                return (
                  <div
                    className='col-lg-3 col-md-3 col-10 mx-auto my-4'
                    key={index}>
                    <MDBCard className='border rounded w-75 mx-auto hover-shadow'>
                      <Link to=''>
                        <MDBCardBody className='p-0'>
                          <div className=' rounded mx-auto d-block'>
                            <img
                              // effect="blur"
                              // height="150px"
                              // width="150px"
                              // placeholderSrc='./assest/image/loader/placeholder.jpg'
                              className='img-fluid d-block mx-auto rounded'
                              src={img}
                              alt='collection-img'
                            />
                          </div>

                          <div className='text-center bottom_text'>
                            <p className='mb-0 text-center text-dark'>
                              Store-Name: <span>{store_name}</span>
                            </p>
                            <p className='mb-0 text-center text-dark'>
                              Store: <span>{store}</span>
                            </p>
                            <p className='mb-0 text-center text-dark'>
                              Product: <span>{product}</span>
                            </p>
                            <p className='mb-0 text-center text-muted'>
                              Store-Details: <br />
                              <span>{store_details}</span>
                            </p>
                          </div>
                        </MDBCardBody>
                      </Link>
                    </MDBCard>
                  </div>
                );
              }
            )}
            {/* </Slider> */}
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCollectionSection;
