import React from 'react'
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import { adminCheckData, categoryCheckData, couponCheckData, customServiceCheckData, orderCheckData, permissionCheckData, productCheckData, roleCheckData, sliderCheckData, SOScategoryCheckData, userCheckData, visitorCheckData } from './Role_&_PermissionData'
const GivePermissionSec = () => {
  return (
    <>
      {/* seo title */}
         <Helmet>
        <title>Give-Permission || Role-Permission</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Role"
        pageTitle="Give-Permission"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Give Permission</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form action="#">
                    <div className="form-row">
                           <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2">
                            <h5 className='text-danger'>ADMIN</h5>
                            {adminCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}
                           </div>
                            </div>

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>CATEGORY</h5>
                            {categoryCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div>   

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>COUPON</h5>
                            {couponCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div>

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>CUSTOM SERVICES</h5>
                            {customServiceCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>ORDER</h5>
                            {orderCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>PERMISSION</h5>
                            {permissionCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>PRODUCT</h5>
                            {productCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>ROLE</h5>
                            {roleCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>SLIDER</h5>
                            {sliderCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div>

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>SOS CATEGORY</h5>
                            {SOScategoryCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 

                             <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>USER</h5>
                            {userCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div>  

                            <div className="form-group col-md-6 mx-auto">
                           <div className="check_box_wrap card p-2 mx-auto">
                            <h5 className='text-danger'>VISITOR</h5>
                            {visitorCheckData.map((Value, index)=>{
                                return(
                                    <div class="form-check" key={index}>
                                    <input type="checkbox" class="form-check-input" id={index} />
                                    <label class="form-check-label font-weight-bold" for={index}>{Value.check}</label>
                                     </div>   
                                )
                            })}   
                           </div>
                            </div> 
                    </div>
                    
                  
                  
                  <button type="submit" class="btn btn-success btn-block w-75 mx-auto"><i class="fas fa-paper-plane"></i> Submit</button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  )
}

export default GivePermissionSec