import React, { Component } from 'react';
import { MDBCard} from 'mdb-react-ui-kit';
import Title from '../SectionTitle/Title/Title';
import Titleimg from '../SectionTitle/Title_img/Title_img';
import VideoPlayer from 'react-video-js-player';

class LiveStreamingSection extends Component {
    player = {}
    state = {
        video: {
            src: "http://www.example.com/path/to/video.mp4",
            poster: "http://www.example.com/path/to/video_poster.jpg"
        }
    }

    onPlayerReady(player){
        console.log("Player is ready: ", player);
        this.player = player;
    }

    onVideoPlay(duration){
        console.log("Video played at: ", duration);
    }

    onVideoPause(duration){
        console.log("Video paused at: ", duration);
    }

    onVideoTimeUpdate(duration){
        console.log("Time updated: ", duration);
    }

    onVideoSeeking(duration){
        console.log("Video seeking: ", duration);
    }

    onVideoSeeked(from, to){
        console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd(){
        console.log("Video ended");
    }

    render() {
        return (
            <>
            <div className="live_streaming_section py-2">
                <div className="container">
                    <Title
                        section_title="Live Streaming"
                    />
                    <Titleimg />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                            <MDBCard className='mb-3 border hover-shadow'>
                            <VideoPlayer
                    controls={true}
                    src={this.state.video.src}
                    poster={this.state.video.poster}
                    width="720"
                    height="420"
                    onReady={this.onPlayerReady.bind(this)}
                    onPlay={this.onVideoPlay.bind(this)}
                    onPause={this.onVideoPause.bind(this)}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    onSeeking={this.onVideoSeeking.bind(this)}
                    onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={this.onVideoEnd.bind(this)}
                />
                            </MDBCard>
                        </div> 
                    </div>
                </div>
            </div>
               
            </>
        );
    }
}
export default LiveStreamingSection