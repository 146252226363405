import React from 'react'
import ApprovedServiceSec from '../../../../Components/admin/CustomServiceSection/ApprovedServiceSec'

const ApprovedService = () => {
  return (
    <>
    <ApprovedServiceSec/>
    </>
  )
}

export default ApprovedService