import React, { useState } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { storeType } from "./data";
import { data } from "./SearchOptionData";
import axios from "axios";
import {Helmet} from "react-helmet";
const AddStore = () => {
  const [areaCheckOption, setareaCheckOption] = useState(true);
  const [addStore, setaddStore] = useState({
    name: "",
    type: "",
    image: "",
    category: "",
    productimg: "",
    phone: "",
    website: "",
    division: "",
    district: "",
    thana: "",
    area: "",
    details: "",
  });
  const {
    name,
    type,
    image,
    category,
    productimg,
    phone,
    website,
    division,
    district,
    thana,
    area,
    details,
  } = addStore;

  const hanleChange = (e) => {
    setaddStore({ ...addStore, [e.target.name]: e.target.value });
  };

  const hanleChangeImg = (e) => {
    let file = e.target.files[0];
    setaddStore({ ...addStore, [e.target.name]: file });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(addStore)
    const data = {
      name,
      type,
      image,
      category,
      productimg,
      phone,
      website,
      division,
      district,
      thana,
      area,
      details,
    };
    // var url = "t/6whi6-1650450154/post";
    // let formData = new FormData();
    // formData.append("name", name);
    // formData.append("type", type);
    // formData.append("category", category);
    // formData.append("productimg", productimg);
    // formData.append("phone", phone);
    // formData.append("website", website);
    // formData.append("division", division);
    // formData.append("district", district);
    // formData.append("thana", thana);

    // axios
    //   .post(url, data)
    //   .then((res) => {
    //     console.log(res.data);
    //     console.log("ss");
    //   })
    //   .catch((erorr) => {
    //     console.log(erorr);
    //     console.log("ff");
    //   });
  };
  // You should have a server side REST API
  // let formData = new FormData();
  // formData.append("productimg", addStore.productimg);
  //     fetch('http://localhost:3000/our-dashboard/add/store', {
  //         method: 'POST',

  //         body: JSON.stringify({
  //             name: name,
  //             url: productimg,
  //             thumbnailUrl: productimg,
  //         }),
  //         headers: {
  //             'Content-type': 'application/json; charset=UTF-8',
  //         },
  //         data: formData,
  //     })
  //         .then((response) => response.json())
  //         .then((json) => console.log(json));

  // }
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title> Add || Store</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Store Add" pageTitle="Add / Store" />
      {/* Main content */}
      <section className="content product_add">
        <form action="#" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName"> Name</label>
                    <input
                    required
                      type="text"
                      name="name"
                      value={name}
                      onChange={hanleChange}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <select
                      id="type"
                      name="type"
                      value={type}
                      onChange={hanleChange}
                      className="form-control custom-select"
                    >
                      <option value="">Select one</option>
                      {storeType.map((value) => {
                        return (
                          <option value={value.value}>{value.label}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="image">Image</label>
                    <div class="input-group mb-3">
                      <input
                      required
                        type="file"
                        class="form-control"
                        name="image"
                        onChange={hanleChangeImg}
                        accept="image/*"
                        id="image"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <select
                      id="category"
                      name="category"
                      value={category}
                      onChange={hanleChange}
                      className="form-control custom-select"
                    >
                      <option value="">Select one</option>
                      <option value="Arts & Crafts">Arts & Crafts</option>
                      <option value="Electronics">Electronics</option>
                      <option value="Fashion">Fashion</option>
                      <option value="Food">Food</option>
                      <option value="Grocery">Grocery</option>
                      <option value="Gift Items">Gift Items</option>
                      <option value="Health & Beauty">Health & Beauty</option>
                      <option value="Home Decor">Home Decor</option>
                      <option value="Lifestyle">Lifestyle</option>
                      <option value="Traditionale">Traditionale</option>
                      <option value="Mango">Mango</option>
                      <option value="Silk">Silk</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="productimage">Product Image</label>
                    <div class="input-group mb-3">
                      <input
                      required
                        type="file"
                        class="form-control"
                        name="productimg"
                        onChange={hanleChangeImg}
                        accept="image/*"
                        id="productimage"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                    required
                      name="phone"
                      value={phone}
                      onChange={hanleChange}
                      type="tel"
                      id="phone"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="website">Website (if available)</label>
                    <input
                    required
                      name="website"
                      value={website}
                      onChange={hanleChange}
                      type="text"
                      id="website"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="division">Division</label>
                    <select
                      id="division"
                      className="form-control custom-select"
                      name="division"
                      value={division}
                      onChange={hanleChange}
                    >
                      <option value="">--Select Division--</option>
                      {data.division.map((value, key) => {
                        return (
                          <option value={value.value} key={key}>
                            {value.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="distirct">District</label>
                    <select
                      id="distirct"
                      className="form-control custom-select"
                      name="district"
                      value={district}
                      onChange={hanleChange}
                    >
                      <option value="">--Select District--</option>
                      {data.division
                        .find((divi) => divi.value === division)
                        ?.district.sort((a, b) => (a.value > b.value ? 1 : -1))
                        .map((value, key) => {
                          return (
                            <option value={value.value} key={key}>
                              {value.value}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="thana">Thana</label>
                    <select
                      id="thana"
                      className="form-control custom-select"
                      name="thana"
                      value={thana}
                      onChange={hanleChange}
                    >
                      <option value="">--Select thana--</option>
                      {data.division
                        .find((divi) => divi.value === division)
                        ?.district?.find((dis) => dis.value === district)
                        ?.thana.sort((a, b) => (a.value > b.value ? 1 : -1))
                        .map((value, key) => {
                          return (
                            <option value={value.value} key={key}>
                              {value.value}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="area">Area</label>
                    {!areaCheckOption ? (
                      <div>
                        <input
                        required
                          className="form-control custom-select"
                          name="area"
                          placeholder="area"
                          value={area}
                          onChange={hanleChange}
                          type="text"
                        />

                        <div class="custom-control custom-checkbox">
                          <input
                          required
                            type="checkbox"
                            name="area"
                            class="custom-control-input"
                            checked={areaCheckOption}
                            onChange={() => {
                              setareaCheckOption(!areaCheckOption);
                            }}
                            id="areaCheck"
                          />
                          <label class="custom-control-label" for="areaCheck">
                            Show Option
                          </label>
                        </div>
                      </div>
                    ) : (
                      <select
                        className="form-control custom-select"
                        name="area"
                        placeholder="Area"
                        value={area}
                        onChange={hanleChange}
                        id="area"
                      >
                        <option value="">--Select Area--</option>
                        {data.division
                          .find((divi) => divi.value === division)
                          ?.district?.find((dis) => dis.value === district)
                          ?.thana?.find((tha) => tha.value === thana)
                          ?.area.sort((a, b) => (a.value > b.value ? 1 : -1))
                          .map((value, key) => {
                            return (
                              <option value={value.value} key={key}>
                                {value.value}
                              </option>
                            );
                          })}
                        <option
                          value=""
                          onClick={() => setareaCheckOption(!areaCheckOption)}
                        >
                          🖌️ Write Your Area{" "}
                        </option>
                      </select>
                    )}
                  </div>

                  <div class="form-group">
                    <label for="details">Details</label>
                    <textarea
                    required
                      name="details"
                      value={details}
                      onChange={hanleChange}
                      class="form-control"
                      id="details"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-1 pb-4">
              <button
                type="submit"
                className="btn btn-success btn-block mx-auto w-50"
              >
                <i class="fas fa-paper-plane"></i> Submit
              </button>
            </div>
          </div>
        </form>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddStore;
