import React from 'react'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import {Helmet} from "react-helmet";
const EditRoleSec = () => {
    return (
        <>
            {/* seo title */}
            <Helmet>
        <title>Edit-Role || Role-Permission</title>
        </Helmet>

            {/* -------PageHeaderBreadCrumb------- */}
            <PageHeaderBreadCrumb
                Title="Role"
                pageTitle="Edit-Role"
            />
            {/* Main content */}
            <section className="content">
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Edit Role</h3>
                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="form-group">
                                        <label htmlFor="inputroleName">Role Name</label>
                                        <input type="text" id="inputroleName" className="form-control" placeholder='Enter Role Name' />
                                    </div>
                                    <button type="submit" class="btn btn-success btn-block w-75 mx-auto"><i class="fas fa-paper-plane"></i> Submit</button>
                                </form>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            </section>
            {/* /.content */}
        </>
    )
}

export default EditRoleSec