export const storeType = [
  {
    label: "Store",
    value: "Store",
  },
  {
    label: "Grocery",
    value: "Grocery",
  },
  {
    label: "Hotel",
    value: "Hotel",
  },
  {
    label: "Restaurant",
    value: "Restaurant ",
  },
  {
    label: "School",
    value: "School",
  },
  {
    label: "College",
    value: "College",
  },
  {
    label: "University",
    value: "University",
  },
  {
    label: "Tourist ",
    value: "Tourist ",
  },
  {
    label: "Spot",
    value: "Spot",
  },
  {
    label: "Confectionary",
    value: "Confectionary",
  },
  {
    label: "Office",
    value: "Bank",
  },
  {
    label: "Mosque",
    value: "Temple",
  },
];

export const AllStoreData = [
  {
    name: "Rashel Islam ",
    type: "Hotel",
    category: "food",
    img: "/dist/img/shop/25.jpg",
    phone: "01601377841",
    website: "ourrajshahi",
    division: "rajshahi",
    district: "rajshahi",
    thana: "paba",
    area: "darusha",
    details: "aaaaaaaaaa",
    id: "1",
  },
  {
    name: "Rashel Islam ",
    type: "Hotel",
    category: "food",
    img: "/dist/img/shop/25.jpg",
    phone: "01601377841",
    website: "ourrajshahi",
    division: "rajshahi",
    district: "rajshahi",
    thana: "paba",
    area: "darusha",
    details: "aaaaaaaaaa",
    id: "2",
  },
  {
    name: "Rashel Islam ",
    type: "Hotel",
    category: "food",
    img: "/dist/img/shop/25.jpg",
    phone: "01601377841",
    website: "ourrajshahi",
    division: "rajshahi",
    district: "rajshahi",
    thana: "paba",
    area: "darusha",
    details: "aaaaaaaaaa",
    id: "3",
  },
];
