import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import MediumDevicesAllcateGories from "./MediumDevicesAllcateGories";
import AllcateGories from "./AllcateGories";
import { MDBCollapse } from "mdb-react-ui-kit";

const AllcateGoriesSection = (props) => {
  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);
  return (
    <>
      <div className='shop_by_department_section shadow-1 border-top'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-3 custom-z-index'>
              <a href='##' className='title' onClick={toggleShow}>
                <AiOutlineBars
                  style={{ fontSize: "22px", marginTop: "-3px" }}
                />
                &nbsp; Categories
              </a>
              <MDBCollapse className='custom_style' show={showShow}>
                <AllcateGories />
              </MDBCollapse>
            </div>
            <div className='col-lg-6 col-md-6'></div>
            <div className='col-lg-3 col-md-3'></div>
          </div>
        </div>
      </div>
      <MediumDevicesAllcateGories />
    </>
  );
};

export default AllcateGoriesSection;
