import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Admin.css"
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import Loader from './../../frontend/Loader/Loader';

const ViewAdminSec = () => {
  const { id } = useParams();

 //  get admin option  data
 const [admin, setAdmin] = useState([]);
 const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
   let isMounted = true;
 
   axios.get(`/admin/view/${id}`).then((res) => {
     if (isMounted) {
     if (res.data.status === 200) {
       setAdmin(res.data.admin);
     }
     setloading(false);
   }
   }).catch(error => {});
 
 return () => {
   isMounted = false;
 }
 }, []);

 if (loading) {
  return <Loader />;
}



  return (
    <>
             {/* seo title */}
             <Helmet>
        <title>Admin-View</title>
      </Helmet>
      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb
        Title="Admin"
        pageTitle="Admin-View"
      />
      {/* Main content */}
      <section className="content view_admin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              {/* Profile Image */}
              <div className="card card-primary card-outline">
                <div className="card-body box-profile border">
                  <div className="text-center custom_bg">
                    <img
                      className='profile-user-img img-responsive  img-circle'
                      src={`${process.env.REACT_APP_API_URL}${admin?.profile_image}`}
                      alt='admin'
                    />
                  </div>
                  <h3 className="profile-username text-center">{admin?.name}</h3>
                  <p className="text-muted text-center">{admin?.usertype}</p>
                  <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item text-center">
                      <b>Phone:</b>&nbsp; <a href="#/">{admin?.phone}</a>
                    </li>
                    <li className="list-group-item text-center">
                      {/* <b>Email:</b>&nbsp;  */}
                      <a href="#/">{admin?.email}</a>
                    </li>
                    <li className="list-group-item text-center social_info">
                      <button type="button" class="btn btn-primary mr-1"><a href={admin?.user_detail?.facebook_id}><i class="fab fa-facebook"></i></a></button>
                      <button type="button" class="btn btn-success mr-1"><a href={admin?.user_detail?.instagram_id}><i class="fab fa-instagram"></i></a></button>
                      <button type="button" class="btn btn-info mr-1"><a href={admin?.user_detail?.twitter_id}> <i class="fab fa-twitter"></i></a></button>
                    </li>

                  </ul>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
            <div className="col-md-9">
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                  </ul>
                </div>{/* /.card-header */}
                <div className="card-body">
                  <div className="tab-content">
                    <div className="active tab-pane" id="activity">
                      
                        <form className="form-horizontal">
                          <div className="form-group row">
                            <label htmlFor="inputName" className="col-sm-2 col-form-label">Name:</label>
                            <div className="col-sm-10">
                              <input type="email" value={admin?.name} className="form-control" id="inputName" placeholder="Name" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="inputEmail" className="col-sm-2 col-form-label">UserName:</label>
                            <div className="col-sm-10">
                              <input type="email" value={admin?.user_name} className="form-control" id="inputEmail" placeholder="Username" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="inputName2" className="col-sm-2 col-form-label">Email:</label>
                            <div className="col-sm-10">
                              <input type="text" value={admin?.email} className="form-control" id="inputName2" placeholder="Email" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Phone:</label>
                            <div className="col-sm-10">
                              <input type="text" value={admin?.phone} className="form-control" id="inputName2" placeholder="Phone" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Company:</label>
                            <div className="col-sm-10">
                              <input type="text" value={admin?.company_name} className="form-control" id="inputSkills" placeholder="Company" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Gender:</label>
                            <div className="col-sm-10">
                              <input type="text" value={admin?.user_detail?.gender} className="form-control" id="inputSkills" placeholder="Gender" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="offset-sm-2 col-sm-10">
                              <button  className="btn btn-warning">Make Genuine Super Admin</button>
                            </div>
                          </div>
                        </form>
                    </div>
                  </div>
                  {/* /.tab-content */}
                </div>{/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>{/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  )
}

export default ViewAdminSec