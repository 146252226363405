import React, {useState,useEffect} from "react";
import axios from "axios";
import "./SpecialServicesSection.css";
import { Link } from "react-router-dom";
import { MDBCard } from "mdb-react-ui-kit";
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import Title from "../../SectionTitle/Title/Title";
import ContentLoader from "react-content-loader"
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ServicesSection(props) {
   //  get SpecialService data
   const [specialService, setSpecialService] = useState([]);
   const [loading, setloading] = useState(true);
  


// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await axios.get("/special-service/all");
      if(isMounted){
        if (res.data.status === 200) {
          setSpecialService(res.data.special_service);
         }
         setloading(false);
      }
     
    } catch (e) {
       console.log(e);
    }
}


  fetchData();

  return () => {
    isMounted = false;
  }
}, []);






  return (
    <>
            {/* seo meta tag  start */}
            {
          specialService?.map(val=>{
        <Helmet>
         <meta name='keywords' content={val?.title} />
        <meta name='description' content={val?.title} />
        <meta name='subject' content={val?.title} />
        <meta name='topic' content={val?.title} />
       

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={val?.title}  />
        <meta
          itemprop="description"
          content={val?.title}
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
         
        
        <meta
          name='description'
          itemProp='description'
          content={val?.title}
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={val?.title} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
        
        <meta
          property='og:image:alt'
          content={val?.img_alt}
        />
        <meta
          name='og:description'
          content={val?.title}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='val?.summary_large_image' />
        <meta
          name='twitter:title'
          content={val?.title} 
        />
        <meta
          name='twitter:description'
          content={val?.title}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${val?.image}`}
        />
       
        <meta
          name='twitter:image:alt'
          content={val?.img_alt}
        />
      </Helmet> 
          })
        }
        
      {/* seo meta tag  end */}




      <div className='special_services_section py-2'>
        <div className='container'>
          <Title section_title='Special Service' />
          <Titleimg />

     
          <div className='row  g-lg-2 g-1'>
            {
              loading ?
              Array(6)
                .fill()
                .map((val, idx) => {
                  return (
                <div   className='col-lg-2 col-md-2 col-10 mx-auto  mt-4'
                  key={idx}>
              <ContentLoader 
              speed={2}
              width='100%'
              height='100%'
              viewBox="0 0 400 320"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect x="9" y="11" rx="3" ry="3" width="380" height="210" /> 
              <rect x="180" y="245" rx="0" ry="0" width="180" height="20" />
            </ContentLoader>
            </div>
            );
                  })
            :
            specialService?.map((val) => {
              const { title, image, img_alt, slug } = val;
              return (
                <div
                  className='col-lg-2 col-md-2 col-10 mx-auto  mt-4'
                  key={val.id}>
                  <Link
                    to={`/view-special-services?service=${slug}`}>
                    <MDBCard className='square  rounded hoverle_card border'>
                      <div className='custom_padding bg-white text-center  rounded'>
                        <LazyLoadImage
                          effect="blur"
                          className='img-fluid hoverble_img'
                          placeholderSrc='./assest/image/loader/placeholder.jpg'
                          src={`${process.env.REACT_APP_API_URL}${image}`}
                          position='top'
                          alt={img_alt}
                        />
                        <p className='text-center text-dark mt-2'>{title}</p>
                      </div>
                    </MDBCard>
                  </Link>
                </div>
              );
            })
            }
            
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesSection;
