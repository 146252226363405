import React, { useContext} from "react";
import "./PopUp.css";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
} from "mdb-react-ui-kit";
import authContext from './../../../auth/Context/AuthUser/authContext';
import TabPanel from './TabPanel';
const PopUp = () => {

 // auth login context
 const { Modal, toggleShow, } =useContext(authContext);


  return (
    <>
      <div id='login_register_popup'>
        <MDBModal show={Modal} setShow={!Modal} staticBackdrop   tabIndex='-1'>
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader>
                <img
                  className='img-fluid site-icon'
                  src='/assest/image/logo/ourrajshahi-icon.png'
                  alt='ourrajshahi icon'
                />
                <MDBBtn
                  className='btn-close'
                  color='none'
                  onClick={toggleShow}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                {/* -------login resgister tap panel ----- */}
                <TabPanel/>
              </MDBModalBody>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
};

export default PopUp;
