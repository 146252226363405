import React from "react";
import PaymentMethodSection from "../../../Components/frontend/PaymentMethodSection/PaymentMethodSection";

const PaymentMethods = () => {
  return (
    <>
      <PaymentMethodSection />
    </>
  );
};

export default PaymentMethods;
