/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader"
import "./Blog_Marquee.css"
import axios from "axios";

const BlogMarquee = (props) => {
    const [breaking_news, setBreaking_news] = useState([]);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        let isMounted = true

          axios.get("/blog/right-site-others").then((res) => {
            if (isMounted) {
            if (res.data.status === 200) {
                setBreaking_news(res.data.blog_right_site_others);
            }
            setloading(false);
          }
          }).catch(error => {});
      
        return () => {
          isMounted = false
        };
      }, []);
    return (
        <>
                     <div className="marquee_style">
                        
                    
                        <span className='marqee_wrapper shadow-5'>
                          <marquee  className="fw-bold text-white" direction="up" scrolldelay="200" height="30px" >UpDate</marquee>
                          
                          </span>
                         
                          <marquee className="border pt-2 text-dark shadow-1" behavior="scroll" direction="" scrolldelay="200" height="40px">{breaking_news?.update_breaking && breaking_news?.update_breaking}</marquee>
                        
                        
                          
                      </div>



        </>
    )
}

export default BlogMarquee
