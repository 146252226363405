import React from "react";
import "./BrandingProductSection.css";
import Title from "../SectionTitle/Title/Title";
import {
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Titleimg from "../SectionTitle/Title_img/Title_img";

const BrandingProductSection = () => {
  return (
    <>
      <div className="branding_product_sec py-2">
        <Title section_title="Branding Product" />
        <Titleimg />
        <div className="container">
          <div className="row py-2">
            <div className="division_title">
              <h6 className="my-2 text-center">---Rajshahi division---</h6>
            </div>
            <div className="col-lg-6 col-md-6 col-10 mx-auto">
              <div className="product_list">
                <MDBCard className="p-2 border hoverle_card">
                  <MDBRow className="g-0">
                    <MDBCol md="12" lg="5">
                      <MDBCardImage
                        className="custom_img rounded mt-2"
                        src="/assest/image/shop/1.jpg"
                        alt="brading-img"
                      />
                    </MDBCol>
                    <MDBCol md="12" lg="7">
                      <MDBCardBody className="px-4 py-0">
                        <MDBCardText className="ps-4">
                          <p className="mb-0 fw-bold">
                            Product Name:{" "}
                            <span className="fw-normal">Computer</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Prices:{" "}
                            <span className="fw-normal">3000</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Details:
                            <br />
                            <span className="fw-normal">
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Rem, recusandae. Nobis deserunt
                              animi sed deleniti.
                            </span>
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-10 mx-auto">
              <div className="product_list">
                <MDBCard className="p-2 border hoverle_card">
                  <MDBRow className="g-0">
                    <MDBCol md="12" lg="5">
                      <MDBCardImage
                        className="custom_img rounded mt-2"
                        src="/assest/image/shop/1.jpg"
                        alt="brading-img"
                      />
                    </MDBCol>
                    <MDBCol md="12" lg="7">
                      <MDBCardBody className="px-4 py-0">
                        <MDBCardText className="ps-4">
                          <p className="mb-0 fw-bold">
                            Product Name:{" "}
                            <span className="fw-normal">Computer</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Prices:{" "}
                            <span className="fw-normal">3000</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Details:
                            <br />
                            <span className="fw-normal">
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Rem, recusandae. Nobis deserunt
                              animi sed deleniti.
                            </span>
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </div>
            </div>
          </div>
          <div className="row py-2">
            <div className="division_title">
              <h6 className="my-2 text-center">---Dhaka division---</h6>
            </div>
            <div className="col-lg-6 col-md-6 col-10 mx-auto">
              <div className="product_list">
                <MDBCard className="p-2 border hoverle_card">
                  <MDBRow className="g-0">
                    <MDBCol md="12" lg="5">
                      <MDBCardImage
                        className="custom_img rounded mt-2"
                        src="/assest/image/shop/1.jpg"
                        alt="brading-img"
                      />
                    </MDBCol>
                    <MDBCol md="12" lg="7">
                      <MDBCardBody className="px-4 py-0">
                        <MDBCardText className="ps-4">
                          <p className="mb-0 fw-bold">
                            Product Name:{" "}
                            <span className="fw-normal">Computer</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Prices:{" "}
                            <span className="fw-normal">3000</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Details:
                            <br />
                            <span className="fw-normal">
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Rem, recusandae. Nobis deserunt
                              animi sed deleniti.
                            </span>
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-10 mx-auto">
              <div className="product_list">
                <MDBCard className="p-2 border hoverle_card">
                  <MDBRow className="g-0">
                    <MDBCol md="12" lg="5">
                      <MDBCardImage
                        className="custom_img rounded mt-2"
                        src="/assest/image/shop/1.jpg"
                        alt="brading-img"
                      />
                    </MDBCol>
                    <MDBCol md="12" lg="7">
                      <MDBCardBody className="px-4 py-0">
                        <MDBCardText className="ps-4">
                          <p className="mb-0 fw-bold">
                            Product Name:{" "}
                            <span className="fw-normal">Computer</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Prices:{" "}
                            <span className="fw-normal">3000</span>
                          </p>
                          <p className="mb-0 fw-bold">
                            Product Details:
                            <br />
                            <span className="fw-normal">
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Rem, recusandae. Nobis deserunt
                              animi sed deleniti.
                            </span>
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandingProductSection;
