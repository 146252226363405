import React, { useEffect, useState } from "react";
import "./SpecialServicesSection.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";

const ViewSpecialService = () => {
  const [searchParams, setsearchParams] = useSearchParams();
  const slugs = searchParams.get("service");
  // const type = searchParams.get("type");
  const search = searchParams.get("search");
   //  get SpecialService data
   const [data, setData] = useState([]);
   
  


// get data
useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      
      if(slugs){
        const res = await axios.get(`/view-special-service/${slugs}`);
        if(isMounted){
          if (res.data.status === 200) {
            setData(res.data.special_service);
           }

        }
      }else if(search){
        const res = await axios.get(`/view-special-service/${search}`);
        if(isMounted){
          if (res.data.status === 200) {
            setData(res.data.special_service);
           }
        }
      }
      
     
    } catch (e) {
       console.log(e);
    }
}


  fetchData();

  return () => {
    isMounted = false;
  }
}, []);


const {slug} = data;
 

  return (
    <>
      <div className='view_special_service bg-light overflow-hidden'>
      <div className='container'>
        <div className='bg-white p-2 my-3'>
        <div className='d-flex mb-3 border-bottom align-items-center'>
            <h5 className='text-dark mb-0 fw-bold'><span className='border-bottom border-info border-2 pb-2 d-block'>{data?.title?.toUpperCase()}</span></h5>
            </div>
          <div className='row'>
            <div className='col-lg-5 col-md-5 col-10 mx-auto mt-4'>
              <MDBCard
                className='spacial_thumb_card mx-auto shadow-1 border'
                style={{ maxWidth: "22rem" }}>
                <div className='view_thembnail rounded-top'>
                       <LazyLoadImage
                          effect="blur"
                          className='rounded-top img-fluid'
                          width='100%'
                          height="200px"
                          placeholderSrc='./assest/image/loader/placeholder.jpg'
                          src={`${process.env.REACT_APP_API_URL}${data?.thumbnail_image}`}
                          alt='thumb'
                        />
                </div>

                <div className='services_img hover-shadow'>
                  <LazyLoadImage 
                   effect="blur" 
                  className='img img-fluid'
                  height='50px'
                  width='50px'
                   src={`${process.env.REACT_APP_API_URL}${data?.image}`}
                   placeholderSrc='./assest/image/loader/placeholder.jpg' alt='ser'

                    />
                </div>
                <MDBCardBody>
                  <div className='pt-4'>
                    <MDBCardTitle className='text-center'>{data?.title}</MDBCardTitle>
                    <p className='mb-1 text-center'>
                       {data?.thumb_title}
                    </p>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-lg-7 col-md-7 col-10 mx-auto mt-4'>
              <MDBCard className='mx-auto' style={{ maxWidth: "25rem" }}>
                <MDBListGroup className='text-center'>
                  <MDBListGroupItem
                    tag='span'
                    className='py-2 fw-bold fs-5 bg-info text-dark border border-info'
                    action
                    active
                    aria-current='true'>
                    {data?.title} info &nbsp;
                    <i class='text-light fas fa-info-circle'></i>
                  </MDBListGroupItem>
                  
                  <div
                      dangerouslySetInnerHTML={{
                        __html:
                        data?.detail
                      }}
                    />
                </MDBListGroup>
              </MDBCard>
            </div>
         
        </div>
        <div className='view_service_plan pt-5 '>

            <h5 className='text-center text-dark title'>--- Service Plan ---</h5>
            <div className='row'>
              {
                data?.available_service_plan?.map((val, i)=>{
                  return (
                    <div
                    className='col-lg-4 col-md-4 col-10 mx-auto mt-4'
                    key={i}>
                    <MDBCard
                      className='mx-auto custom_hover'
                      style={{ maxWidth: "27rem" }}>
                      <div className='service_plan_wrap '>
                        <h3 className='plan_name mb-0 rounded-top'>
                        {val?.name?.toUpperCase()}
                        </h3>
                        <div
                          className='d-flex justify-content-around custom_bg py-4
                                    '>
                          <div>
                            <span className='text-info'>
                            {val?.title}
                            </span>
                          </div>
                          <div>
                          <LazyLoadImage
                              effect="blur"
                              className='img-fluid custom_img'
                              height='100px'
                              width='100px'
                              placeholderSrc='./assest/image/loader/placeholder.jpg'
                              src={`${process.env.REACT_APP_API_URL}${val?.image}`}
                              alt='plan'
                            />

                          </div>
                        </div>
                      </div>
                      <MDBCardBody className='bg-light plan_context'>
                        <div
                            dangerouslySetInnerHTML={{
                              __html:
                              val?.detail
                            }}
                          />
                        <div>
                          <span className='plan_price fw-bold fs-4 my-4'>
                            <span className='fw-bold fs-6'>৳&nbsp;</span>
                            {val?.price}
                            <span className='fw-bold fs-6'>/ {val?.price_time}</span>
                          </span>
                        </div>

                        <Link
                          to={`/requset-service-plan?service=${slug
                            .toLowerCase()
                            .replace(
                              / +/g,
                              "-"
                            )}&plan=${val?.name.toLowerCase()}`}>
                          <button className='cutom_btn mt-4'>
                            shop Now&nbsp;
                            <i class=' fas fa-caret-square-right'></i>
                          </button>
                        </Link>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                  )
                })
              }
                
          
          </div>
        </div>


         
          
      </div>
      </div>
                <div className='view_service_bottom_animation'>
                     <div
                       className='bottom_bg'
                       style={{
                         background:
                           "url(/assest/image/service_bottom_animation/footer_bg.png) no-repeat scroll center 0",
                       }}>
                       <div
                         className='bg_one'
                         style={{
                           background: "url(../assest/image/service_bottom_animation/volks.gif) no-repeat center center",
                         }}></div>
                       <div
                         className='bg_two'
                         style={{
                           background: "url(../assest/image/service_bottom_animation/foodpanda-panda.gif) no-repeat center center",
                           width: "160px",
                           height: "130px",
                         }}></div>
                     </div>
                   </div>
      </div>
    </>
  );
};

export default ViewSpecialService;
