import React from 'react'
import AddMiniBannerSec from '../../../../Components/admin/Settings/MiniBanner/AddMiniBannerSec'

const AddMiniBannerSetting = () => {
  return (
    <>
    <AddMiniBannerSec/>
    </>
  )
}

export default AddMiniBannerSetting