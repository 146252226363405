import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Loader from './../../frontend/Loader/Loader';
import { toast ,Zoom} from "react-toastify";
import {Helmet} from "react-helmet";
const DeactiveAdminSec = () => {
  //  get admin option  data
  const [admin, setAdmin] = useState([]);
  const [loading, setloading] = useState(true);
  // get data
  useEffect(() => {
    let isMounted = true;
  
    axios.get("/admin/draft").then((res) => {
      if (isMounted) {
      if (res.data.status === 200) {
        setAdmin(res.data.admin);
      }
      setloading(false);
    }
    }).catch(error => {});
  
  return () => {
    isMounted = false;
  }
  }, []);

  useEffect(() => {
    // initialize datatable
    if (!loading) {
      const script = document.createElement("script");
      script.src = "/dist/js/custom.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [loading]);



 // status active or deactive 
const updateStatus = (id) => {
  const formData = new FormData();
  formData.append("_method", 'PUT');
  formData.append("status", 1);
  axios
  .post(`/admin/update_status/${id}`, formData)
  .then((res) => {
    if (res.data.status === 200) {
      toast.info(res.data.message, {
        position: "top-right",
        autoClose: 700,
        theme: 'colored',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
          setAdmin(
            admin.filter((item) => item.id !== id)
          );
        }else if (res.data.status === 400) {
          toast.warning(res.data.message, {
            position: "top-right",
            autoClose: 700,
            theme: 'colored',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((e) => {});
  };



  const handleDeleteItems = (id) => {
    axios
      .delete(`/admin/delete/${id}`)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setAdmin(
            admin.filter((item) => item.id !== id)
          );
        }else if (res.data.status === 400) {
          toast.warning(res.data.message, {
            position: "top-right",
            autoClose: 700,
            theme: 'colored',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((e) => {});
  };




  if (loading) {
    return <Loader />;
  }



  return (
    <>
    {/* seo title */}
    <Helmet>
        <title>Deactive-Admin</title>
      </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Admin" pageTitle="Deactive-Admin" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
        
          <div className="card-body p-0">
          
            <table id="example1"   className="table table-bordered table-striped text-center">
              <thead>
                <tr>
                 <th>Id</th>
                  <th>Full Name</th>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Action</th>
                  <th>View</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  admin && admin?.map((val) => {
                    return (
                      <tr key={val.id}>

                          <td>
                          {val.id}
                        </td>

                        <td>
                          {val.name}
                        </td>

                        <td>
                          {val.user_name}
                        </td>

                        <td>
                        {val.email}
                        </td>

                        <td>
                        <span className="p-1 rounded bg-success">
                         {val.usertype} 
                        </span>
                        </td>
                          
                        
                        <td>
                        <span className="btn btn-warning btn-sm p-1"  onClick={() => {
                                        updateStatus(val.id);
                                      }}
                                      >
                          Actived Now
                        </span>
                      </td>


                        <td>
                          <Link to={`/our-dashboard/view/admin/${val.id}`} className="btn btn-info btn-sm" >
                            view
                          </Link>
                        </td>

                        <td>

                        <a
                            className='btn btn-danger btn-sm'
                            tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true"
                            data-toggle='modal'
                            data-target={`#userDelete_${val.id}`}
                            href='##'>
                            <i className='fas fa-trash pr-2'></i>
                            Delete
                            <div
                              class='modal fade bd-example-modal-sm'
                             
                              id={`userDelete_${val.id}`}
                              >
                              <div class='modal-dialog modal-sm modal-dialog-centered'>
                                <div class='modal-content'>
                                  <div class='modal-body text-center'>
                                    <i class='fas fa-times text-danger fa-3x'></i>
                                    <p className='mb-2 text-dark'>
                                      <span className='font-weight-bold h4'>
                                        Are you sure?
                                      </span>
                                      <br />
                                      Once deleted, you will not be able to
                                      recover this data!
                                    </p>
                                  </div>
                                  <div class='modal-footer border'>
                                    <button
                                      onClick={() => {
                                        handleDeleteItems(val.id);
                                      }}
                                      type='button'
                                      class='btn btn-danger'
                                      data-dismiss='modal'>
                                      Yes
                                    </button>
                                    <button
                                      type='button'
                                      class='btn btn-outline-danger'
                                      data-dismiss='modal'>
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
      </>
  );
};

export default DeactiveAdminSec;
