import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
const HeaderSec = () => {
  // insert header logo
  const [insertheaderLogo, setinsertHeaderLogo] = useState({
    logo: "",
    logo_alt: "",
    search_camera: "",
  });

  // insert header search option
  const [insertSearchOption, setinsertSearchOption] = useState({
    search_option: "",
  });

  //  get header logo data
  const [headerlogo, setHeaderlogo] = useState([]);

  // image preview
   const [previewImage, setPreviewImage] = useState({
    logo: null,
    search_camera: null,
   });

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

   //  btn disabled
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  // get data
  useEffect(() => {
    axios.get("/setting/header-logo").then((res) => {
      if (res.data.status === 200) {
        setHeaderlogo(res.data.header_logo);
      }
      setloading(false);
    }).catch((err) => {});
  }, [rerendering]);

  // handlechange header logo
  const handleChange = (e) => {
    if (headerlogo == null) {
      setinsertHeaderLogo({
        ...insertheaderLogo,
        [e.target.name]: e.target.value,
      });
    } else {
      setHeaderlogo({
        ...headerlogo,
        [e.target.name]: e.target.value,
      });
    }
  };

  // handleChangeImage header logo
  const handleChangeImage = (e) => {
    if (headerlogo == null) {
      setinsertHeaderLogo({
        ...insertheaderLogo,
        [e.target.name]: e.target.files[0],
      });
      setPreviewImage({...previewImage, [e.target.name]:e.target.files[0]});
    } else {
      setHeaderlogo({
        ...headerlogo,
        [e.target.name]: e.target.files[0],
      });
      setPreviewImage({...previewImage, [e.target.name]:e.target.files[0]});
    }
   
  };

  // handleChangeImage header search option
  const SearchOptionHandleChange = (e) => {
    setinsertSearchOption({
      ...insertSearchOption,
      [e.target.name]: e.target.value,
    });
  };

  // SearchOptionHandleSubmit header search option
  const SearchOptionHandleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const data = insertSearchOption;
    axios
      .post(`/setting/header-search-option/insert`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisableSubmitBtn(false);
          setinsertSearchOption({
            search_option: "",
          });
          setValidation_error({});
          setRerendering(!rerendering);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  // handleSubmit header logo
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    if (headerlogo == null) {
      const formData = new FormData();
      formData.append("logo", insertheaderLogo.logo);
      formData.append("logo_alt", insertheaderLogo.logo_alt);
      formData.append("search_camera", insertheaderLogo.search_camera);
      axios
        .post(`/setting/header-logo/insert`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.info(res.data.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setValidation_error({});
            setRerendering(!rerendering);
            setPreviewImage({
              logo: null,
             search_camera: null,
            });
          } else {
            setValidation_error(res.data.validation_errors);
            setDisableSubmitBtn(false);
          }
        })
        .catch((err) => {});
    } else {
      const formData = new FormData();
      formData.append("logo", headerlogo.logo);
      formData.append("logo_alt", headerlogo.logo_alt);
      formData.append("search_camera", headerlogo.search_camera);
      axios
        .post(`/setting/header-logo/update/${headerlogo.id}`, formData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.info(res.data.message, {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setDisableSubmitBtn(false);
            setValidation_error({});
            setRerendering(!rerendering);
            setPreviewImage({
              logo: null,
             search_camera: null,
            });
          } else {
            setValidation_error(res.data.validation_errors);
            setDisableSubmitBtn(false);
          }
        })
        .catch((err) => {
          
        });
    }
  };

  if (loading) {
    <h2>loading....</h2>;
  }
  return (
    <>
               {/* seo title */}
               <Helmet>
        <title>Logo || Setting</title>
        </Helmet>

      {/* Content Header (Page header) */}
      <PageHeaderBreadCrumb Title='Setting' pageTitle='Header' />

      {/* Main content */}
      <section className='content product_add'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='card card-primary'>
              <div className='card-header'>
                <h3 className='card-title'>General</h3>
                <div className='card-tools'>
                  <button
                    type='button'
                    className='btn btn-tool'
                    data-card-widget='collapse'
                    title='Collapse'>
                    <i className='fas fa-minus' />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <form
                  className='form-horizontal'
                  encType='multipart/form-data'
                  onSubmit={handleSubmit}>
                  <div>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <div className='form-group'>
                          <label htmlFor='customFile'>Header Logo</label>

                          <input
                            type='file'
                            className='form-control'
                            accept='image/*'
                            name='logo'
                            onChange={handleChangeImage}
                            id='customFile'
                          />
                        </div>
                        <span className='text-danger'>
                          {validation_error.logo}
                        </span>
                        <div className='my-2'>
                        {previewImage.logo && (
                            <img
                              alt='item-img'
                              width='60'
                              src={URL.createObjectURL(previewImage.logo)}
                            />
                          )}
                          {!previewImage.logo && (
                            <img
                            width='60'
                              alt='logo'
                              src={
                                headerlogo == null
                                  ? ""
                                  
                                  : headerlogo.logo &&
                                    `${process.env.REACT_APP_API_URL}${headerlogo.logo}`
                              }
                            />
                          )}


                          
                        </div>
                      </div>

                      <div className='form-group col-md-12'>
                        <label htmlFor='inputlogoAlt'>Logo Alt</label>
                        <input
                          type='text'
                          name='logo_alt'
                          value={
                            headerlogo == null
                              ? insertheaderLogo.logo_alt
                              : headerlogo.logo_alt == null
                              ? ""
                              : headerlogo.logo_alt
                          }
                          onChange={handleChange}
                          className='form-control'
                          id='inputlogoAlt'
                          placeholder='Enter Logo Alt'
                        />
                        <span className='text-danger'>
                          {validation_error.logo_alt}
                        </span>
                      </div>
                      <div className='form-group col-md-12'>
                        <div className='form-group'>
                          <label htmlFor='customFile2'>
                            Header Search Camera
                          </label>

                          <input
                            type='file'
                            className='form-control'
                            accept='image/*'
                            name='search_camera'
                            onChange={handleChangeImage}
                            id='customFile2'
                          />
                        </div>
                        <span className='text-danger'>
                          {validation_error.search_camera}
                        </span>
                        <div className='my-2'>
                          {previewImage.search_camera && (
                            <img
                              alt='item-img'
                              width='60'
                              src={URL.createObjectURL(previewImage.search_camera)}
                            />
                          )}
                          {!previewImage.search_camera && (
                            <img
                            width='60'
                              alt='camera'
                              src={
                                headerlogo == null
                                  ? ""
                                  : headerlogo.search_camera == null
                                  ? ""
                                  : headerlogo.search_camera &&
                                    `${process.env.REACT_APP_API_URL}${headerlogo.search_camera}`
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='form-group row'>
                    <div className='col-12'>
                      <button
                        type='submit'
                        class='btn btn-info btn-block w-75 mx-auto'
                        disabled={disableSubmitBtn}
                        >
                        <i class='fas fa-paper-plane'></i> Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          <div className='col-md-6'>
            <div className='card card-secondary'>
              <div className='card-header'>
                <h3 className='card-title'>Header Search Option</h3>
                <div className='card-tools'>
                  <Link
                    to='/our-dashboard/setting/all-header-search-option'
                    className='btn btn-success btn'>
                    All Search Option
                  </Link>
                </div>
              </div>
              <div className='card-body'>
                <form
                  className='form-horizontal'
                  encType='multipart/form-data'
                  onSubmit={SearchOptionHandleSubmit}>
                  <div className='form-group col-md-12'>
                    <label htmlFor='input-search-option'>
                      Add Header Search Option
                    </label>
                    <input
                      type='text'
                      name='search_option'
                      value={insertSearchOption.search_option}
                      onChange={SearchOptionHandleChange}
                      className='form-control'
                      id='input-search-option'
                      placeholder='Enter Search Option'
                    />
                    {validation_error && (
                      <span className='text-danger'>
                        {validation_error.search_option}
                      </span>
                    )}
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <button
                        type='submit'
                        class='btn btn-info btn-block w-75 mx-auto'
                        disabled={disableSubmitBtn}
                        >
                        <i class='fas fa-paper-plane'></i> Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* /.card */}
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default HeaderSec;
