import React from 'react'
import SearchResultSection from '../../../Components/frontend/SearchResultSection/SearchResultSection'

const SearchResult = () => {
  return (
    <>
    <SearchResultSection/>
    </>
  )
}

export default SearchResult