import React, { useState } from "react";
import { MDBBtn,MDBSpinner } from "mdb-react-ui-kit";
import { Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Zoom} from 'react-toastify';
import axios from "axios";

const ResetPassword = (props) => {
    const [passwordShown, setpasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
      setpasswordShown(passwordShown ? false : true);
    };
  
    // comfrim password show hide
    const [comfirm_passwordShown, setcomfirm_passwordShown] = useState(false);
  
    const toggle_comfrim_PasswordVisiblity = () => {
      setcomfirm_passwordShown(comfirm_passwordShown ? false : true);
    };
    const [validation_error, setValidation_error] = useState([]);
    const user_forgot_email = localStorage.getItem("user_forgot_email")
    //  btn disabled
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  

    return (
      <>
        <div className='reset_password_verify'>
          <h4 className='text-center py-3 fw-bold border-bottom w-75 mx-auto'>Reset Password</h4>
          <Formik
          enableReinitialize={true}
          
          initialValues={{
            email: user_forgot_email,
            new_password: "",
            new_password_confirmation:""
          }}
          validate={(values) => {
            const errors = {};
  
            
  // comfirmpassword validate condition
  if (!values.new_password) {
    errors.new_password = "Please Input Your New Password";
  } else if (values.new_password.length > 50) {
    errors.new_password = "New Password maximum length of 50 characters";
  } else if (values.new_password.length < 8) {
    errors.new_password = "New Password manimum length of 8 characters";
  }

  // password_confirmation validate condition
  if (!values.new_password_confirmation) {
    errors.new_password_confirmation = "Please Input Your  Comfirm New Password";
  } else if (values.new_password_confirmation.length > 50) {
    errors.new_password_confirmation =
      "Comfirm New Password maximum length of 50 characters";
  } else if (values.new_password_confirmation.length < 8) {
    errors.new_password_confirmation =
      "Comfirm New Password manimum length of 8 characters";
  } else if (values.new_password !== values.new_password_confirmation) {
    errors.new_password_confirmation = "New Password did not match";
  }
        
  
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            setDisableSubmitBtn(true);
            const data = values;
              axios
                .post("/reset-password", data)
                .then((res) => {
                  if (res.data.status === 200) {
                    document.getElementById("resetForm").reset();
                    resetForm();
                    toast.success(res.data.message, {
                        position: "top-center",
                        theme: 'colored',
                        transition: Zoom,
                        autoClose: 600,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setDisableSubmitBtn(false);
                    props.handleResetComplete(false);
                    // localStorage.setItem("user_token", res.data.access_token);
                  } else if  (res.data.status === 422){
                    setDisableSubmitBtn(false);
                    setValidation_error(res.data.validation_errors);
                  }
                })
                .catch((err) => {
                  setDisableSubmitBtn(false);
                });
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} id='resetForm'>
             {/* <!-- new_password input --> */}
             <div>
             <lable className='fw-bold float-start'>New Password</lable>
              <div className='input-group mt-3'>
                <input
                  type={passwordShown ? "text" : "password"}
                  id='new_password'
                  placeholder='Enter Yout New Password'
                  name='new_password'
                  autoComplete='off'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.new_password}
                  touched={formik.touched.new_password}
                  onBlur={formik.handleBlur}
                />
                <label className='input-group-text' htmlFor='new_password' onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <i
                      className='fas fa-lock-open'
                      ></i>
                  ) : (
                    <i
                      className='fas fa-lock'
                      ></i>
                  )}
                </label>
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.new_password ? (
                <span className='text-danger'>{validation_error.new_password}</span>
              ) : (
                formik.touched.new_password &&
                formik.errors.new_password && (
                  <span className='text-danger'>{formik.errors.new_password}</span>
                )
              )}
            </div>
            {/* <!--Repeat password input --> */}
            <div className='py-3'>
            <lable className='fw-bold float-start'>New Comfirm Password</lable>
              <div className='input-group  mt-3'>
                <input
                  type={comfirm_passwordShown ? "text" : "password"}
                  id='new_password_confirmation'
                  placeholder='Enter Your New Comfirm Password'
                  name='new_password_confirmation'
                  className='form-control'
                  onChange={formik.handleChange}
                  value={formik.values.new_password_confirmation}
                  touched={formik.touched.new_password_confirmation}
                  onBlur={formik.handleBlur}
                />
                <label
                  className='input-group-text ps-2'
                  htmlFor='new_password_confirmation'
                  onClick={toggle_comfrim_PasswordVisiblity}
                  >
                  {comfirm_passwordShown ? (
                    <i
                      className='far fa-eye'
                      ></i>
                  ) : (
                    <i
                      className='fas fa-eye-slash'
                      ></i>
                  )}{" "}
                </label>
                {/* <label className="input-group-text" htmlFor="inputGroupFile01"><i className="fas fa-lock"></i></label> */}
              </div>
              {/* errors will return when field validation fails  */}
              {validation_error.new_password_confirmation ? (
                <span className='text-danger'>
                  {validation_error.new_password_confirmation}
                </span>
              ) : (
                formik.touched.new_password_confirmation &&
                formik.errors.new_password_confirmation && (
                  <span className='text-danger'>
                    {formik.errors.new_password_confirmation}
                  </span>
                )
              )}
            </div>
  
            <div>
              <MDBBtn
                type='submit'
                rounded
                className='login-submit-btn btn btn-danger btn-rounded btn-block my-3 mx-auto'
                color='danger'
                disabled={disableSubmitBtn}
                >
                Submit
                {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
              </MDBBtn>
            </div>
            </form>
          )}
        </Formik>
        </div>
      </>
    );
  };

export default ResetPassword;
