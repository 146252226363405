export const ImageStoreData = [
  {
    img: "../assest/image/img-store/7.jpg",
    price: "10",
  },
  {
    img: "../assest/image/img-store/8.jpg",
    price: "10",
  },
  {
    img: "../assest/image/img-store/9.jpg",
    price: "16",
  },
  {
    img: "../assest/image/img-store/3.jpg",
    price: "10",
  },
];
