import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TermsConditions.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import Helmet from "react-helmet"

const TermsConditions = (props) => {
    const [terms_conditions, setTerms_conditions] = useState([]);
  
  
    // get data
    useEffect(() => {
      let isMounted = true;
      props.onTopProgress(30);
      const fetchData = async () => {
        try {
        const res = await  axios.get("/terms-conditions");
        if(isMounted){
          if (res.data.status === 200) {
            setTerms_conditions(res.data.terms_conditions);
            props.onTopProgress(70);
          }
          props.onTopProgress(100);
        }  
       } catch (e) {
          console.log(e);
       }
        
     }
  
  
     fetchData();
      
      return () => {
        isMounted = false;
      }
    }, []);
  
  
  
  
    return (
      <>


  {/* seo meta tag  start */}
  <Helmet>
        ‍<title>Terms of Conditions</title>
         <meta name='keywords' content="OurRajshahi" />
        <meta name='description' content={terms_conditions} />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi'  />
        <meta
          itemprop="description"
          content={terms_conditions}
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content={terms_conditions}
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
        <meta
          name='og:description'
          content={terms_conditions}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi'
        />
        <meta
          name='twitter:description'
          content={terms_conditions}
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        
      </Helmet> 
      {/* seo meta tag  end */}

        <div className='terms_condition_section py-4 bg-light overflow-hidden'>
          <div className='container'>
            <h4 className='title text-center py-2'>Terms of Conditions</h4>
            <div className='row'>
              <div className='col-12'>
                <MDBCard className='shadow-1'>
                  <MDBCardBody>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            terms_conditions == null
                              ? ""
                              : terms_conditions &&
                                terms_conditions,
                        }}
                      />
                  </MDBCardBody>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  

export default TermsConditions
