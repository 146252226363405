import ViewSpecialService from "../home/SpecialServicesSection/ViewSpecialService";
import RequestPlanService from "../home/SpecialServicesSection/RequestPlanService";
import EmailVerifyOtp from "../Login_Register_Wrapper/mail/EmailVerifyOtp";
import CategoryShop from '../Shop/view/CategoryByShop';
import SubCategoryShop from '../Shop/view/SubCategoryByShop';
import SosCategoryShop from '../Shop/view/SosCategoryByShop';
import SearchShop from '../Shop/view/SearchByShop';

import SingleProduct from "../SingleProduct/SingleProduct";
import PaymentMethods from "../../../Pages/frontend/PaymentMethod/PaymentMethods";
import CheckOut from "../../../Pages/frontend/CheckOut/CheckOut";
import Cart from "../../../Pages/frontend/Cart/Cart";
import Wishlist from "../../../Pages/frontend/Wishlist/Wishlist";

import ContactUs from "../../../Pages/frontend/ContactUs/ContactUs";
import Services from "../../../Pages/frontend/Services/Services";
import AboutUs from "../../../Pages/frontend/AboutUs/AboutUs";
import FAQ from "../../../Pages/frontend/FAQ/FAQ";
import TermsConditions from "../../../Pages/frontend/TermsConditions/TermsConditions";
import ReturnPolicy from "../../../Pages/frontend/ReturnPolicy/ReturnPolicy";
import HowtoBuy from "../../../Pages/frontend/HowtoBuy/HowtoBuy";
import Help from "../../../Pages/frontend/Help/Help";
import PrivacyPolicy from "../../../Pages/frontend/PrivacyPolicy/PrivacyPolicy";
import TermsService from "../../../Pages/frontend/TermsService/TermsService";

import Forum from "../../../Pages/frontend/Forum/Forum";
import Community from "../../../Pages/frontend/Community/Community";
import Career from "../../../Pages/frontend/Career/Career";
import AffiliateProgram from "../../../Pages/frontend/AffiliateProgram/AffiliateProgram";
import TermsofUse from "../../../Pages/frontend/Terms_of_Use/Terms_of_Use";
import CopyRight from "../../../Pages/frontend/CopyRight/CopyRight";


import LocationSearch from "../../../Pages/frontend/LocationSearch/LocationSearch";
import SearchResult from "../../../Pages/frontend/SearchResult/SearchResult";
import BrandingProduct from "../../../Pages/frontend/BrandingProduct/BrandingProduct";
import Gallery from "../../../Pages/frontend/Gallery/Gallery";
import CustomInformationRequestForm from "../CustomInformationRequestForm/CustomInformationRequestForm";
import Documentary from "../../../Pages/frontend/Documentary/Documentary";
import Vlog from "../../../Pages/frontend/Vlog/Vlog";
import LiveStreaming from "../../../Pages/frontend/LiveStreaming/LiveStreaming";
import CommunityTourism from "../../../Pages/frontend/CommunityTourism/CommunityTourism";
import NewsPortal from "../../../Pages/frontend/NewsPortal/NewsPortal";
import EMagazine from "../../../Pages/frontend/E-Magazine/EMagazine";
import WorkShopSeminar from "../../../Pages/frontend/WorkShop_&_Seminar/WorkShopSeminar";
import Failed from './../../../Pages/frontend/Payment/Failed';




export const frontendRoutes = [

  {
    path: "view-special-services/",
    Component: ViewSpecialService,
  },
  {
    path: "requset-service-plan",
    Component: RequestPlanService,
  },{
    path: "verify-otp/email",
    Component: EmailVerifyOtp,
  },
  {
    path: "shop/:category_slug",
    Component: CategoryShop,
  },
  {
    path: "shop/:category_slug/:subcat_slug",
    Component: SubCategoryShop,
  },
  {
    path: "shop/:category_slug/:subcat_slug/:soscat_slug",
    Component: SosCategoryShop,
  },
  {
    path: "shop/",
    Component: SearchShop,
  },
  {
    path: "cart",
    Component: Cart,
  },
  {
    path: "wishlist",
    Component: Wishlist,
  },
  {
    path: "checkout",
    Component: CheckOut,
  },
  {
    path: "product/:slug",
    Component: SingleProduct,
  },
  {
    path: "about-us",
    Component: AboutUs,
  },
  {
    path: "contact-us",
    Component: ContactUs,
  },
  {
    path: "services",
    Component: Services,
  },
  {
    path: "faq",
    Component: FAQ,
  },
  {
    path: "terms-and-conditions",
    Component: TermsConditions,
  },
  {
    path: "privacy-policy",
    Component: PrivacyPolicy,
  },
  {
    path: "return-policy",
    Component: ReturnPolicy,
  },
  {
    path: "copy-right",
    Component: CopyRight,
  },

  {
    path: "help",
    Component: Help,
  },
  {
    path: "how-to-buy",
    Component: HowtoBuy,
  },
  {
    path: "terms-of-service",
    Component: TermsService,
  },
  {
    path: "terms-of-use",
    Component: TermsofUse,
  },
  {
    path: "payment-method",
    Component: PaymentMethods,
  },
  {
    path: "forum",
    Component: Forum,
  },
  {
    path: "community",
    Component: Community,
  },
  {
    path: "career",
    Component: Career,
  },
  {
    path: "affiliate-program",
    Component: AffiliateProgram,
  },

  {
    path: "location-search",
    Component: LocationSearch,
  },
  {
    path: "search-result",
    Component: SearchResult,
  },
  {
    path: "custom-request-form",
    Component: CustomInformationRequestForm,
  },
  {
    path: "branding-product",
    Component: BrandingProduct,
  },
  {
    path: "gallery",
    Component: Gallery,
  },
  {
    path: "documentary",
    Component: Documentary,
  },
  {
    path: "vlog",
    Component: Vlog,
  },
  {
    path: "live-streaming",
    Component: LiveStreaming,
  },
  {
    path: "community-tourism",
    Component: CommunityTourism,
  },
  {
    path: "news-portal",
    Component: NewsPortal,
  },
  {
    path: "e-magazine",
    Component: EMagazine,
  },
  {
    path: "workshop-seminar",
    Component: WorkShopSeminar,
  },
  {
    path:'payment/failed',
    Component: Failed,
  }


  
];
