import React from 'react'
import DeliveredServiceSec from '../../../../Components/admin/CustomServiceSection/DeliveredServiceSec'

const DeliveredService = () => {
  return (
    <>
    <DeliveredServiceSec/>
    </>
  )
}

export default DeliveredService