import React from 'react'
import { Link } from 'react-router-dom'
import "./CopyRight.css"
import moment from 'moment'
import CopyRight_Data from './CopyRight_Data'

const CopyRight = () => {
    return (
        <>
        <div className="footer_copy_right">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mx-auto text-center">
                               <p> Copyright&copy; 2018-{moment().year()} <Link to="/">OurRajshahi</Link> || Powered by: <a   href="https://powerwebit.com" target="_blank" rel="noopener noreferrer">PowerWeb IT</a> || All Rights Reserved</p>
                    </div>
                    <div className="d-flex justify-content-center mx-auto pt-3">

                        {
                            CopyRight_Data.map((Value, index)=>{
                                return(
                                    <img src={Value.payment_img} alt="payment-img" key={index} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
         
        </div>
            
        </>
    )
}

export default CopyRight
