import React from 'react'
import { MDBCard, MDBCardBody} from 'mdb-react-ui-kit';
import Title from '../SectionTitle/Title/Title';
import Titleimg from '../SectionTitle/Title_img/Title_img';
const SearchResultSection = () => {
    return (
        <>
            <div className="search_result py-4">
                <div className="container">
                    <Title
                        section_title="Search Result"
                    />
                    <Titleimg />
                    <div className="row">
                        <div className="col-md-12">
                            <MDBCard className='w-100 mx-auto'>
                                <MDBCardBody>     
                                        <div className="row">
                                            <div className="col-lg-5 col-md-6 col-10 mx-auto">
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Full Address:</span>&nbsp;
                                                    <span className='p-2'>Rashel Islam Sojib</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-10 mx-auto">
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Store Name:</span>&nbsp;
                                                     <span className='p-2'>Our-Rajshahi</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-10 mx-auto">
                                                <h6 className='mt-2'>Store Details</h6>
                                                <div className="">
                                                     <span className='fw-bold'>Phone:</span>&nbsp;
                                                       <span className='p-2'>01601377841</span>                                                
                                                </div>
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Email:</span>&nbsp;
                                                    <span className='p-2'>mdrashelislam86@gmail.com</span>
                                                    
                                                </div>
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Website:</span>&nbsp;
                                                    <span className='p-2'>http://ourrajshahi.com/</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-10 mx-auto">
                                                <h6 className='mt-2'>Images</h6>
                                                <div className="">
                                                     <span className='fw-bold'>Store:</span>&nbsp;
                                                    <span className='p-2'>Our-Rajshahi</span>
                                                </div>
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Products:</span>&nbsp;
                                                    <span className='p-2'><img src="" alt="" /> </span>
                                                </div>
                                                <div className="mt-3">
                                                     <span className='fw-bold'>Location:</span>&nbsp;
                                                    <span className='p-2'>182/Ahmed Plaza (3rd floor), Alupatti, Boalia,  </span>
                                                </div>
                                            </div>
                                        </div> 
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchResultSection