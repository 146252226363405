import React,{useEffect, useState} from "react";
import "./ProductZoom.css";
import ReactImageMagnify from 'react-image-magnify';

 const ProductZoom = ({ productImg, gallaryImg }) => {
  const [viewImg, setViewImg]=useState(productImg);
  useEffect(() => {
    setViewImg(productImg);
  }, [productImg]);
  return (
    <>
      <div className='product_zoom w-75 mx-auto d-block'>
        <ReactImageMagnify {...{
        smallImage: {
        alt: 'single-img',
        src: `${process.env.REACT_APP_API_URL}${viewImg}`,
        isFluidWidth:true,
        sizes: '(max-width: 480px) 100vw, (max-width: 800px) 30vw, 360px'
    },
       largeImage: {
        src:`${process.env.REACT_APP_API_URL}${viewImg}`,
        width: 800,
        height: 1200,
    },
    enlargedImageContainerStyle: {
      zIndex: "900",
      width: '200%',
      height: '100%'
    },
  
}} />

{/* gallay bottom */}
<ul className="d-flex justify-content-center my-3">
  <li className='custom_width' onClick={()=>setViewImg(productImg)}>
      <img
        src={`${process.env.REACT_APP_API_URL}${productImg}`}
        className='img-fluid rounded mx-auto d-block w-75 h-75 p-1 border'
        alt='product_img'
      />

  </li>
 
  
  {
    gallaryImg?.map((val)=>{
      return(
        <>
       <li className='custom_width' onClick={()=>setViewImg(val?.gallary_img)}>
      <img
        src={`${process.env.REACT_APP_API_URL}${val?.gallary_img}`}
        className='img-fluid rounded mx-auto d-block w-75 h-75 p-1 border'
        alt='gallary_img'
      />

  </li>
        </>
      )
    })
  }


</ul>

</div>

    </>
  );
};

export default ProductZoom;
