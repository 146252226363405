import React from "react";
import Item from './Item';
const Picked = ({data}) => {
  return <>
      <Item title='Picked' data={data} />
  </>;
};

export default Picked;



