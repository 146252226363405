import React from "react";
import './loader.css';
const LogoLoader = () => {
  return <>
       <div className='img_loader text-center mx-auto'>
        <img className='img-fluid' src="/assest/image/loader/logo_loader.gif"  alt='loading'></img>

       </div>
  </>;
};

export default LogoLoader;