import React from 'react'
import "./documentary.css";

const Documentary = () => {
  return (
    <>
            <div className="documentary_section py-2">
                <div className="container">
                <h4 className='title text-center py-2'>Documentary</h4>
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                        <div className="youtube_video">
                                <iframe src='https://www.youtube.com/embed/9DVG3aCcOdA'
                                    frameborder='0'
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                    width="100%"
                                    height="330"
                                    className='rounded'
                                />
                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                        <div className="youtube_video">
                                <iframe src='https://www.youtube.com/embed/Qq6PYqHN2Ho'
                                    frameborder='0'
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                    width="100%"
                                    height="330"
                                    className='rounded'
                                />
                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                        <div className="youtube_video">
                                <iframe src='https://www.youtube.com/embed/-NQOXqyp0ao'
                                    frameborder='0'
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                    width="100%"
                                    height="330"
                                    className='rounded'
                                />

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 mx-auto mt-4">
                        <div className="youtube_video">
                                <iframe src='https://www.youtube.com/embed/ZvQdQOBtv6o'
                                    frameborder='0'
                                    allow='autoplay; encrypted-media'
                                    allowfullscreen
                                    title='video'
                                    width="100%"
                                    height="330"
                                    className='rounded'
                                />
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
  )
}

export default Documentary