import React from 'react'
import EditRoleSec from '../../../Components/admin/Role_&_Permission/EditRoleSec'

const EditeRole = () => {
  return (
    <>
    <EditRoleSec/>
    </>
  )
}

export default EditeRole