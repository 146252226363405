import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ContentLoader from "react-content-loader";
import Helmet from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const BannerRight = (props) => {
  //  get mini_slider data
  const [rightbanner, setRightbanner] = useState([]);
  const [loading, setloading] = useState(true);
  
  // get data
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const res = await axios.get("/mini-banner");
        if(isMounted){
          if (res.data.status === 200) {
            setRightbanner(res.data.mini_banner);
           }
           setloading(false);
        }
       
      } catch (e) {
         console.log(e);
      }
 }


    fetchData();

    return () => {
      isMounted = false;
    }
  }, []);






  return (
    <>
        {/* seo meta tag  start */}
        <Helmet>
         <meta name='keywords' content={rightbanner[0]?.img_alt} />
        <meta name='description' content='OurRajshahi - Collection of all District Branding Online Shopping' />

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={rightbanner[0]?.img_alt}  />
        <meta
          itemprop="description"
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          itemProp='image'
          content={`${process.env.REACT_APP_API_URL}${rightbanner[0]?.image}`}
        />
        <meta
          name='description'
          itemProp='description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />

          
         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={rightbanner[0]?.img_alt} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${rightbanner[0]?.image}`}
        />
        <meta
          property='og:image:alt'
          content={rightbanner[0]?.img_alt}
        />
        <meta
          name='og:description'
          content={rightbanner[0]?.img_alt}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content={rightbanner[0]?.img_alt} 
        />
        <meta
          name='twitter:description'
          content={rightbanner[0]?.img_alt}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${rightbanner[0]?.image}`}
        />
        <meta
          name='twitter:image:alt'
          content={rightbanner[0]?.img_alt}
        />

         <meta name='keywords' content={rightbanner[1]?.img_alt} />
        <meta name='description' content='OurRajshahi - Collection of all District Branding Online Shopping' />

         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={rightbanner[1]?.img_alt}  />
        <meta
          itemprop="description"
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          itemProp='image'
          ontent={`${process.env.REACT_APP_API_URL}${rightbanner[1]?.image}`}
        />
        <meta
          name='description'
          itemProp='description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />

         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content={rightbanner[1]?.img_alt} />
        
        <meta
          name='og:image'
          content={`${process.env.REACT_APP_API_URL}${rightbanner[1]?.image}`}
        />
        <meta
          property='og:image:alt'
          content={rightbanner[1]?.img_alt}
        />
        <meta
          name='og:description'
          content={rightbanner[1]?.img_alt}
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content={rightbanner[1]?.img_alt} 
        />
        <meta
          name='twitter:description'
          content={rightbanner[1]?.img_alt}
        />
        <meta
          name='twitter:image'
          content={`${process.env.REACT_APP_API_URL}${rightbanner[1]?.image}`}
        />
        <meta
          name='twitter:image:alt'
          content={rightbanner[1]?.img_alt}
        />
      </Helmet> 
      {/* seo meta tag  end */}


      <div className="home_banner_right">
        {
          loading ?
          Array(2)
            .fill()
            .map((val, idx) => {
              return (
          <ContentLoader 
          speed={2}
          width='100%'
          height='100%'
          viewBox="0 0 250 240"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="10" y="3" rx="3" ry="3" width="230" height="230" /> 
        </ContentLoader>
        );
            })
            :
          rightbanner?.map((val)=>{
            return(
              <>
              <Link to={val.url} key={val?.id}>
              <LazyLoadImage
              effect="blur"
              className="banner_right_img rounded mt-2 border"
              width="100%"
              placeholderSrc='./assest/image/loader/placeholder.jpg'
                src={
                  val.image &&
                  `${process.env.REACT_APP_API_URL}${val?.image}`
                }
                alt={val?.img_alt}
            />
              </Link>
              </>
             
            )
          })
        }
       
        
      </div>
    </>
  );
};

export default BannerRight;
