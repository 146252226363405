import React from "react";
import Item from './Item';
const Shipped = ({data}) => {
  return <>
      <Item title='Shipped' data={data} />
  </>;
};

export default Shipped;

