import React from 'react'
import ViewOrderWrapper from '../../../../Components/admin/OrderSection/ViewOrderWrapper'

const ViewOrders = () => {
  return (
    <>
    <ViewOrderWrapper/>
    </>
  )
}

export default ViewOrders