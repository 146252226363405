import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import { AllStoreData } from "./data";
import {Helmet} from "react-helmet";
const AllStore = () => {
  useEffect(() => {
    // initialize datatable
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  });

  const [data, setdata] = useState(AllStoreData);

  const handledelet = (Id) => {
    // let filterdeleteItem = categorydata.filter(categorydata => categorydata.id !== Id);
    setdata(data.filter((data) => data.id !== Id));
    // alert(categorydata.id )
  };
  return (
    <>
           {/* seo title */}
           <Helmet>
        <title> All || Store</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Categories" pageTitle="All / Store" />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr>
                  <th style={{ width: "5%" }} className="text-center">
                    Name
                  </th>
                  <th style={{ width: "5%" }} className="text-center">
                    Type
                  </th>
                  <th style={{ width: "5%" }} className="text-center">
                    {" "}
                    Image
                  </th>
                  <th style={{ width: "10%" }} className="text-center">
                    Category
                  </th>
                  <th style={{ width: "5%" }} className="text-center">
                    {" "}
                    Product
                  </th>
                  <th style={{ width: "10%" }} className="text-center">
                    Phone
                  </th>
                  <th style={{ width: "10%" }} className="text-center">
                    Website
                  </th>
                  <th style={{ width: "10%" }} className="text-center">
                    Division
                  </th>
                  <th style={{ width: "9%" }} className="text-center">
                    District
                  </th>
                  <th style={{ width: "8%" }} className="text-center">
                    Thana
                  </th>
                  <th style={{ width: "8%" }} className="text-center">
                    Area
                  </th>
                  <th style={{ width: "10%" }} className="text-center">
                    Details
                  </th>
                  <th style={{ width: "5%" }} className="text-center">
                    Edit
                  </th>
                  <th style={{ width: "5%" }} className="text-center">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((Value, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{Value.name}</td>
                      <td className="text-center">{Value.type}</td>
                      <td className="text-center">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <img
                              alt="item-img"
                              className="table-avatar"
                              src={Value.img}
                            />
                          </li>
                        </ul>
                      </td>
                      <td className="text-center">{Value.category}</td>

                      <td className="text-center">
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <img
                              alt="item-img"
                              className="table-avatar"
                              src={Value.product_img}
                            />
                          </li>
                        </ul>
                      </td>
                      <td className="text-center">{Value.phone}</td>
                      <td className="text-center">{Value.website} </td>
                      <td className="text-center">{Value.division} </td>
                      <td className="text-center">{Value.district} </td>
                      <td className="text-center">{Value.thana} </td>
                      <td className="text-center">{Value.area} </td>
                      <td className="text-center">{Value.details} </td>
                      <td className="text-center">
                        <Link
                          to={`/our-dashboard/edit/store/${Value.id}`}
                          className="btn btn-primary btn-sm"
                          href="#/"
                        >
                          <i className="fas fa-edit" />
                        </Link>
                      </td>

                      <td className="text-center">
                        <a
                          className="btn btn-danger btn-sm"
                          data-toggle="modal"
                          data-target=".bd-example-modal-sm"
                          href="##/"
                        >
                          <i className="fas fa-trash"></i>
                          <div
                            class="modal fade bd-example-modal-sm"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="mySmallModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-sm modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-body text-center">
                                  <i class="fas fa-times text-danger fa-3x"></i>
                                  <p className="mb-2 text-dark">
                                    <span className="font-weight-bold h4">
                                      Are you sure?
                                    </span>
                                    <br />
                                    Once deleted, you will not be able to
                                    recover this data!
                                  </p>
                                </div>
                                <div class="modal-footer border">
                                  <button
                                    onClick={() => {
                                      handledelet(Value.id);
                                    }}
                                    type="button"
                                    class="btn btn-danger"
                                    data-dismiss="modal"
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-danger"
                                    data-dismiss="modal"
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default AllStore;
