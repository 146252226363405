const FooterTopData = [
    {
        name:"info@ourrajshahi.com",
        icon:"fas fa-envelope",
        href:"mailto:info@ourrajshahi.com"
    },
    {
        name:"09611863686",
        icon:"fas fa-phone",
        href:"tel://09611863686"
    },
    {
        icon:"fas fa-headset",
        name:"Support",
        href:"tel://01701089346"
    }
];
export default FooterTopData