import React, {useState, useEffect} from 'react'
import EditorEdit from "../../Editor/Editor";
// import { InputTags } from 'react-bootstrap-tagsinput'
// import 'react-bootstrap-tagsinput/dist/index.css'
import {useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import {  toast, Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import Loader from '../../../frontend/Loader/Loader';

const Edit_post_blog = () => {

  const { id } = useParams();
  const history = useNavigate();
  // update 
 const [updateblog_post, setupdateblog_post] = useState([]);

 // image preview
const [previewImage, setPreviewImage] = useState(null);

 //  get blog categories with blog subcats option  data
 const [blogcats_with_blogsubcats, setBlogcats_with_blogsubcats] = useState([]);

 // rerendering axios insert data
 const [rerendering, setRerendering] = useState(false);
 const [loading, setloading] = useState(true);


  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

// get data
useEffect(() => {
  let isMounted = true;

  axios.get(`/blog/post/edit/${id}`).then((res) => {
    if (isMounted) {
    if (res.data.status === 200) {
      setupdateblog_post(res.data.data);
      setBlogcats_with_blogsubcats(res.data.blogcats_with_blogsubcat);
      
    }else if(res.data.status === 404){
      toast.error(res.data.message, {
        position: "top-center",
        theme: 'colored',
        transition: Zoom,
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history('/our-dashboard/admin/blog/post')
     }
    setloading(false);
    }
  }).catch(error => {});


return () => {
 isMounted = false
}
}, [rerendering]);




const handleChange = (e) => {
        setupdateblog_post({ ...updateblog_post, [e.target.name]: e.target.value });
      };
      const DrafthandleChange = (e) => {
        setupdateblog_post({ ...updateblog_post, [e.target.name]: e.target.checked });
      };
      const handleChangeImage = (e) => {
        setupdateblog_post({
          ...updateblog_post,
          [e.target.name]: e.target.files[0],
        });
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
      };
    
      const handleDesciption = (newDescption) => {
        setupdateblog_post({ ...updateblog_post, post_description: newDescption });
      };


      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
      formData.append("heading", updateblog_post.heading);
      formData.append("tag", updateblog_post.tag);
      formData.append("blog_category_id", updateblog_post.blog_category_id);
      formData.append("blog_sub_category_id", updateblog_post.blog_sub_category_id);
      formData.append("blog_image", updateblog_post.blog_image);
      formData.append("post_description", updateblog_post.post_description);
      formData.append("status", updateblog_post.status === 1 || updateblog_post.status === true ? 1 : 0);
      axios
      .post(`/blog/post/update/${updateblog_post.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.info(res.data.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRerendering(!rerendering);
          setValidation_error({});
          setPreviewImage(null);
        } else {
          setValidation_error(res.data.validation_errors);
        }
      })
      .catch((err) => {});
        
      };
      if (loading) {
        return  <Loader/>;
      }
  return (
     <>
        {/* seo title */}
        <Helmet>
        <title> Edit || Blog-Post</title>
      </Helmet>

     <section className="content product_add">
        <form id="resetForm" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-5">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">General</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">Heading</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="heading"
                      value={updateblog_post.heading}
                      id="inputName"
                      className="form-control"
                    />
                  </div>
                  <span className='text-danger'>
                          {validation_error.heading}
                        </span>
                  <div className="form-group">
                    <label htmlFor="inputName">Tag</label>
                    <input
                      // required
                      type="text"
                      onChange={handleChange}
                      name="tag"
                      value={updateblog_post.tag}
                      id="inputName"
                      className="form-control"
                    />
                  </div>

       {/* <InputTags values={tag} name="tag" onChange={handleChange} onTags={(value) => setState(value.values)} /> */}

       <span className='text-danger'>
                          {validation_error.tag}
                        </span>
                      <div className="form-group">
                    <label htmlFor="inputClientCompany">Post Image</label>
                    <input
                      // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="blog_image"
                      onChange={handleChangeImage}
                      id="customFile2"
                    />
                  </div>
                
                  {
                    validation_error.blog_image &&
                    <span className='text-danger'>
                    {validation_error.blog_image}
                    <br />
                  </span>
                  }
                  {previewImage && (
                    <img
                      alt='blog_image'
                      width='60'
                      src={URL.createObjectURL(previewImage)}
                    />
                  )}
                  {!previewImage && 
                  <img
                    alt='blog_image'
                    className='img-fluid w-25'
                    src={
                      updateblog_post.blog_image == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${updateblog_post.blog_image}`
                    }
                  />}
                           
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>


            <div className="col-md-5">
              <div className="card card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Select</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputStatus">Category</label>
                    <select
                      id="inputStatus"
                      onChange={handleChange}
                      name="blog_category_id"
                      value={updateblog_post.blog_category_id}
                      className="form-control custom-select"
                    >
                      <option value="">--Select Blog Category--</option>
                      {
                        blogcats_with_blogsubcats?.map((val)=>{
                          return(
                            <option value={val.id} {...updateblog_post.blog_category_id === val.id ? "selected": null}>{val.category_name}</option>
                          )
                        })
                      }
                      
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.blog_category_id}
                        </span>
                  <div class="form-group">
                    <label>Sub Category</label>
                    <select
                      onChange={handleChange}
                      name="blog_sub_category_id"
                      value={updateblog_post.blog_sub_category_id}
                      class="form-control"
                      style={{ width: "100%" }}
                    >
                      <option value="">--Select Blog Sub Category--</option>
                      {
                        blogcats_with_blogsubcats?.find((cats)=> cats.id == updateblog_post.blog_category_id)?.blog_subcategory.map((val)=>{
                          return(
                            <option value={val.id} {...updateblog_post.blog_sub_category_id === val.id ? "selected": null}>{val.sub_category_name}</option>
                          )
                        })
                      }
                   
                      
                    </select>
                  </div>
                  <span className='text-danger'>
                          {validation_error.blog_sub_category_id}
                        </span>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>


            <div className="col-md-2">
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">Type</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                      title="Collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div class="icheck-primary d-inline">
                        <input type="checkbox" name="status"  defaultChecked={updateblog_post.status && updateblog_post.status === 1 ? true : false}  onChange={DrafthandleChange}  id="checkboxPrimary2"/>
                        <label for="checkboxPrimary2">
                            Draft
                        </label>
                      </div>
                  

                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>

          </div>





          <div className="row">
          <div className="col-12 py1">
          <div className="editor-sec">
                    <label htmlFor="editor">Post Description</label>
                    <EditorEdit
                      name="post_description"
                      handleChange={handleDesciption}
                      value={updateblog_post.post_description}
                    />
                     </div>
                     <span className='text-danger'>
                          {validation_error.post_description}
                        </span>
                  </div>
          </div>
          <div className="row">
            <div className="col-12 py-4">
              <button
                type="submit"
                className="btn btn-success btn-block mx-auto w-75"
              >
                <i class="fas fa-paper-plane"></i> Submit
              </button>
            </div>
            
          </div>
        </form>
      </section>
     </>
    )
};

export default Edit_post_blog;
