import React, {useState, useEffect} from 'react'
import Helmet from "react-helmet";
import axios from "axios";
import "./ContactSection.css"
import { MDBBtn, MDBCard, MDBCardBody,  MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import { useForm } from "react-hook-form";
import ShopByDepartmentSection from '../../../Components/frontend/ShopByDepartmentSection/ShopByDepartmentSection'
import ContentLoader from "react-content-loader"

const google_map =[
    {
        map_url:"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14538.106881375094!2d88.6034911!3d24.3629737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe60e3fa443db825e!2sPowerWeb%20IT!5e0!3m2!1sen!2sbd!4v1605790682241!5m2!1sen!2sbd"
    }
];

const ContactUs = (props) => {




  const [contactUs, setContactUs] = useState([]);
  const [loading, setloading] = useState(true);

// form validition
const { register, handleSubmit,  formState: { errors } } = useForm();
// form submit data
const onSubmit_register = (data) => {
    console.log(data);
    alert("form submit succecss");
  }




  // get data
  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
        props.onTopProgress(30);
      const res = await axios.get("/contact-us");
      if(isMounted){
        if (res.data.status === 200) {
          setContactUs(res.data.contact_us);
          props.onTopProgress(70);
        }
        setloading(false);
        props.onTopProgress(100);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }


   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, []);




    return (
        <>
{/* seo meta tag  start */}
<Helmet>
        ‍<title>Contact Us</title>
         <meta name='keywords' content={contactUs?.hotline} />
        <meta name='description' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />




         {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content='OurRajshahi - Collection of all District Branding Online Shopping'  />
        <meta
          itemprop="description"
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          itemprop="image"
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          itemProp='image'
          ontent="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='description'
          itemProp='description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />


         {/* <!-- Facebook Meta Tags --> */}
        <meta name='og:title' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        <meta
          name='og:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          property='og:image:alt'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          name='og:description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content='summary_large_image' />
        <meta
          name='twitter:title'
          content='OurRajshahi - Collection of all District Branding Online Shopping' 
        />
        <meta
          name='twitter:description'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
        <meta
          name='twitter:image'
          content="../../../assets/image/logo/ourrajshahi-icon.png"
        />
        <meta
          name='twitter:image:alt'
          content='OurRajshahi - Collection of all District Branding Online Shopping'
        />
      </Helmet> 
      {/* seo meta tag  end */}




                <ShopByDepartmentSection/>
            <section className='contact_us_sec pt-1'>
              
                <div className="container">
                    <h4 className='section-title text-center py-2'>Get In Touch </h4>
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-10 mx-auto">
                        <MDBCard>
                        <MDBCardBody className='px-5'>
                        <form onSubmit={handleSubmit(onSubmit_register)}>
               
                    {/* <!-- Full Name input --> */}
                    <div>
                    <div class="input-group mt-1">
                      <input type="text" id="full-name" name='full-name' placeholder='Full Name *' class="form-control" 
                      {...register("fullname", {
                         required: true,
                         maxLength: 50,
                         pattern:/^[a-zA-Z ]*$/
                        })}
                      />
                      <label class="input-group-text" for="full-name"><i class="fas fa-user"></i></label>           
                  </div>
                         {/* errors will return when field validation fails  */}
                         {
                        errors.fullname && 
                        <span className='text-danger'> 
                        {errors.fullname.type === "required" && "Please Input Your Full Name" }
                        {errors.fullname.type === "maxLength" && "Fullname maximum length of 50 characters" }
                        {errors.fullname.type === "pattern" && "Invalid  Full Name" }
                        </span>
                        }
                  </div>
                  {/* <!-- Email input --> */}
                  <div>
                  <div class="input-group mt-3">
                      <input type="email" id="email" name='email' placeholder='Email *' class="form-control" 
                      {...register("email", {
                        required: true,
                        maxLength: 100,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                       })}
                      />
                      <label class="input-group-text" for="email"><i class="fas fa-envelope"></i></label>
                  </div>
                   {/* errors will return when field validation fails  */}
                   {
                        errors.email && 
                        <span className='text-danger'> 
                        {errors.email.type === "required" && "Please Input Your Email Address" }    
                        {errors.email.type === "maxLength" && "Email maximum length of 100 characters" }
                        {errors.email.type === "pattern" && "Invalid Email Address" }
                        </span>
                        }
                        </div>
                  {/* <!-- Phone input --> */}
                  <div>
                  <div class="input-group mt-3">
                      <input type="tel" id="phone" name='phone' placeholder='Phone *' class="form-control" 
                       {...register("phone", {
                        required: true,
                        minLength: 11,
                        maxLength: 15,
                        pattern:/^[0-9\b]+$/
                       })}
                      />
                      <label class="input-group-text" for="phone"><i class="fas fa-mobile-alt"></i></label>
                  </div>
                    {/* errors will return when field validation fails  */}
                    {
                        errors.phone && 
                        <span className='text-danger'> 
                        {errors.phone.type === "required" && "Please Input Your Phone Number" }
                        {errors.phone.type === "minLength" && "Phone Number minimum length of 11 characters " }
                        {errors.phone.type === "maxLength" && "Phone Number maximum length of 15 characters" }
                        {errors.phone.type === "pattern" && "Invalid Phone Number" }
                        </span>
                        }
                  </div>
               
        {/* Please select a role input radio */}
        <div>
        <div class="input-group  mt-3  d-flex ">
                            <span class="p pe-3">Please select a role:</span>
                            <div className="form-check pe-3">
                              <label htmlFor="buyer">
                                <input
                                  {...register('role', { required: true })}
                                  type="radio"
                                  name="role"
                                  value="Buyer"
                                  className="form-check-input"
                                  id="buyer"
                                />{' '}
                                Buyer
                              </label>
                            </div>

                            <div className="form-check pe-3">
                              <label htmlFor="seller">
                                <input
                                  {...register('role', { required: true })}
                                  type="radio"
                                  name="role"
                                  value="Seller"
                                  className="form-check-input"
                                  id="seller"
                                />{' '}
                                Seller
                              </label>
                            </div>

                            <div className="form-check pe-3">
                              <label htmlFor="both">
                                <input
                                  {...register('role', { required: true })}
                                  type="radio"
                                  name="role"
                                  value="Both"
                                  className="form-check-input"
                                  id="both"
                                />
                             Both
                              </label>
                            </div>
                          </div>
                          <div>
                          {/* errors will return when field validation fails  */}
                          {
                              errors.role && 
                              <span className='text-danger'> 
                              {errors.role.type === "required" && "Please select a role " }
                              </span>
                          } 
                          </div>    
                          {/* <select>
        <option selected disabled>--Select City--</option>
        <option>New York</option>
        <option>Chicago</option>
        <option>Los Angeles</option>
        <option>Washington DC</option>
    </select>  */}
{/* 

                           <select name='roletitle' id="roletitle" className='contact_select mb-3'  autocomplete="off" required
                        {...register("roletitle", { 
                            required: true
                        })}
                        >
        <option value=" " autocomplete="off">-- Select Subject --</option>
        <option value="Buyer" autocomplete="off">Buyer</option>
        <option value="Seller" autocomplete="off">Seller</option>
        <option value="Both" autocomplete="off">Return Policy</option>
      </select>           */}
      </div>
                
     
                  <div class="input-group mt-3">
                  <textarea class="form-control form-control-lg" placeholder='&#x270E; Message *' style={{height:"130px"}} aria-label="With textarea"
                  {...register("massege", {
                    required: true,
                    minLength: 5,
                    maxLength: 256,
                    pattern:/^[a-zA-Z ]*$/
                   })}
                  ></textarea>
                  </div>
                    {/* errors will return when field validation fails  */}
                    {
                        errors.massege && 
                        <span className='text-danger'> 
                        {errors.massege.type === "required" && "Please give massege" }
                        {errors.massege.type === "minLength" && "Please give massege minLength of 5 characters " }
                        {errors.massege.type === "maxLength" && "Please give massege maximum of 256 characters" }
                        {errors.massege.type === "pattern" && "invalid massege type" }
                        </span>
                        }
      <MDBBtn type='submit mt-2' rounded className='btn btn-danger btn-rounded btn-block mb-2 mt-3 mx-auto' color='danger'  style={{width:"70%", display:"block", margin:"auto"}}>
        Submit
      </MDBBtn>

        </form>
        </MDBCardBody>
        </MDBCard>


                       

                        </div>
                        <div className="col-lg-4 col-md-4 col-10 mx-auto py-3">
                        <MDBCard>
                        <MDBListGroup flush>
                         <div className="contact_details">
                         {  
                          loading   ?
                      Array(5)
                        .fill()
                        .map((val, idx) => {
                          return (
                            <MDBListGroupItem key={idx}>
                              <p className='pt-2'> 
                              <ContentLoader 
                              speed={2}
                              width='100%'
                               height='100%'
                              viewBox="0 0 300 35"
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              <rect x="68" y="9" rx="3" ry="3" width="188" height="8" /> 
                              <rect x="113" y="23" rx="3" ry="3" width="111" height="8" /> 
                              <circle cx="38" cy="17" r="16" />
                            </ContentLoader>
                               </p>
                            </MDBListGroupItem>
                             )})
                           :
                           <>
                            <MDBListGroupItem><p className='pt-2'><i class="fas fa-phone"></i> &nbsp; {contactUs?.hotline} </p></MDBListGroupItem>
                            <MDBListGroupItem><p className='pt-2'><i class="fas fa-mobile-alt"></i> &nbsp; 01701089346 </p></MDBListGroupItem>
                            <MDBListGroupItem><p className='pt-2'><i class="fas fa-envelope"></i> &nbsp; {contactUs?.email} </p></MDBListGroupItem>
                            <MDBListGroupItem><p className='pt-2'><i class="fas fa-map-marker-alt"></i> &nbsp; {contactUs?.address} </p></MDBListGroupItem>
                            
                           </>
                            
                        
                  }
                         </div>
                         </MDBListGroup>
                        </MDBCard>

                 </div>
                 <div className="col-12 mx-auto">
                 <div className="google-map py-3">
                         <iframe title='google-map' src={google_map[0].map_url} style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0" width="100%" height="400" frameborder="0"></iframe>
                         </div>
                 </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ContactUs
