import React, {useEffect, useState} from "react";
import "./TraditionalSection.css";
import axios  from 'axios';
import { Link } from "react-router-dom";
import Title from "../../SectionTitle/Title/Title";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Titleimg from "../../SectionTitle/Title_img/Title_img";
import ContentLoader from "react-content-loader"
import Helmet from "react-helmet";

const TraditionalSection = (props) => {

     //  get traditional data
     const [traditional, setTraditional] = useState([]);
  const [loading, setloading] = useState(true);
 // get data
 useEffect(() => {
  let isMounted = true;
  const fetchData = async () => {
    try {
      const res = await axios.get("/traditional/all");
      if(isMounted){
        if (res.data.status === 200) {
          setTraditional(res.data.traditional);
         }
         setloading(false);
      }
     
    } catch (e) {
       console.log(e);
    }
}


  fetchData();

  return () => {
    isMounted = false;
  }
}, []);

  return (
    <>

 {/* seo meta tag  start */}
 {
            traditional?.map(val=>
              (
                // <>
                 <Helmet>
                <meta name='keywords' content='OurRajshahi' />
                <meta name='description' content={val?.title} />
                <meta name='subject' content='OurRajshahi - Collection of all District Branding Online Shopping' />
                <meta name='topic' content='OurRajshahi - Collection of all District Branding Online Shopping' />
        
        
                 {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={val?.title}  />
                <meta
                  itemprop="description"
                  content={val?.title} 
                />
                <meta
                  itemprop="image"
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  itemProp='image'
                  ontent={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                  name='description'
                  itemProp='description'
                  content={val?.title} 
                />
        
        
                 {/* <!-- Facebook Meta Tags --> */}
                <meta name='og:title' content='OurRajshahi' />
                
                <meta
                  name='og:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                <meta
                    property='og:image:alt'
                    content={val?.img_alt}
                  />
               
                <meta
                  name='og:description'
                  content={val?.title} 
                />
        
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content='summary_large_image' />
                <meta
                  name='twitter:title'
                  content='OurRajshahi' 
                />
                <meta
                  name='twitter:description'
                  content={val?.title} 
                />
                <meta
                  name='twitter:image'
                  content={`${process.env.REACT_APP_API_URL}${val?.image}`}
                />
                 <meta
                    name='twitter:image:alt'
                    content={val?.img_alt}
                  />
                </Helmet> 
                // </>
              )
            )}
      {/* seo meta tag  end */}





      <div className='traditional_section py-2'>
        <div className='container'>
          <Title section_title='Traditional' />
          <Titleimg />
          <div className='row g-2'>
            {loading ?
              Array(4)
                .fill()
                .map((val, idx) => {
                  return (
            <div  className='col-lg-3 col-md-3 col-10 mx-auto mt-2' key={idx}>
            <ContentLoader 
              speed={2}
              width='100%'
              height='100%'
              viewBox="0 0 400 320"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect x="11" y="12" rx="3" ry="3" width="220" height="140" /> 
              <rect x="52" y="160" rx="0" ry="0" width="130" height="5" /> 
              <rect x="95" y="170" rx="0" ry="0" width="100" height="5" />
            </ContentLoader>
            </div>
            );})
                :
              traditional?.map((val) => {
              return (
                <div
                  className='col-lg-3 col-md-3 col-10 mx-auto mt-4'
                  key={val.id}>
                  <MDBCard className='border rounded w-75 custom_bg hoverle_card mx-auto'>
                    <Link to={val.url}>
                      <MDBCardBody className='p-2'>
                        <LazyLoadImage
                          effect="blur"
                          placeholderSrc='./assest/image/loader/placeholder.jpg'
                          className='rounded img-fluid custom_img mt-1'
                          src={`${process.env.REACT_APP_API_URL}${val?.image}`}
                          alt={val?.img_alt}
                        />
                        <p className='mb-0 text-center text-dark pt-1'>
                          {val.title}
                        </p>
                      </MDBCardBody>
                    </Link>
                  </MDBCard>
                </div>
              );
            })}
          </div>
          <div className='more-btn pt-2'>
            <Link to='' className='mx-2 me-3 px-3 btn btn-info btn-sm float-end'>
                See More &nbsp;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TraditionalSection;
