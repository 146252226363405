import React, { useState, useEffect } from "react";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
import Select from 'react-select';

const AddCouponSec = () => {

  const [checked, setChecked] =useState(false);
  const [insert, setInsert] = useState({
    coupon_code: "",
    discount: "",
    category_id:[],
    max_qty: "",
    end_date: "",
  });

  const {coupon_code, discount, category_id, max_qty, end_date } = insert;

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

 const [category, setCategory] = useState([]);

 useEffect(() => {
  let isMounted = true;
  if (isMounted) {
    axios.get("/category/all").then((res) => {
      if (res.data.status === 200) {
        setCategory(res.data.categories);
      }
    });
  }
  return () => {
    isMounted = false;
  };
}, []);



  const handleChange = (e) => {
    setInsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("coupon_code", coupon_code);
    formData.append("discount", discount);
    formData.append("category_id", category_id.toString());
    formData.append("max_qty", max_qty);
     // make limit show
    if(checked){
      formData.append("make_limit_show", checked);
      formData.append("end_date", end_date);
    }


    axios
    .post(`/coupon/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setInsert({
          coupon_code: "",
          discount: "",
          category_id:[],
          max_qty: "",
          end_date: "",
        });
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});

  };


  return (
    <>
           {/* seo title */}
           <Helmet>
        <title> Add || Coupon</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Coupon"
        pageTitle="Add-Coupon"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Coupon</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="inputcode">Coupon Code <span className='text-danger'>*</span></label>
                    <input  type="text" id="inputcode" className="form-control" name='coupon_code' value={coupon_code} onChange={handleChange} placeholder='Enter Coupon Code' />
                  </div>
                  {
                    validation_error.coupon_code &&
                    <span className='text-danger'>
                          {validation_error.coupon_code}
                        <br/>
                      </span>
                  }

                  <div className="form-group">
                    <label htmlFor="inputdiscount">Discount % <span className='text-danger'>*</span></label>
                    <input  type="text" id="inputdiscount" className="form-control" name='discount' value={discount} onChange={handleChange} placeholder='Enter Discount' />
                  </div>
                  {
                    validation_error.discount &&
                    <span className='text-danger'>
                          {validation_error.discount}
                        <br/>
                      </span>
                  }

                   <div className="form-group">
                    <label htmlFor="inputdiscount">Category </label>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      onChange={(val) => 
                        setInsert({...insert, category_id: val.map(v => v.id)})} 
                      options={category}
                      getOptionLabel={({ category_name }) => category_name}
                      getOptionValue={({ id }) => id}
                      value={category.filter(obj => category_id.includes(obj.id))} // set selected values
                      placeholder="Add to a Category"
                      name="category_id" 
                    />

                  </div>
                  {
                    validation_error.category_id &&
                    <span className='text-danger'>
                          {validation_error.category_id}
                        <br/>
                      </span>
                  }
                  <div className="form-group">
                    <label htmlFor="inputdiscount">Max Quantity <span className='text-danger'>*</span></label>
                    <input  type="text" id="inputdiscount" className="form-control" name='max_qty' value={max_qty} onChange={handleChange} placeholder='Enter Quantity' />
                  </div>

                  {
                    validation_error.max_qty &&
                    <span className='text-danger'>
                          {validation_error.max_qty}
                        <br/>
                      </span>
                  }

                  <div className={ checked ? "d-block form-group" : "d-none" }>
                    <label htmlFor="inputHour">Hour</label>
                    <input  type="text" id="inputHour" className="form-control" name='end_date' value={end_date} onChange={handleChange}  placeholder='Enter Hour' />
                  </div>

                  {
                    validation_error.end_date &&
                    <span className='text-danger'>
                          {validation_error.end_date}
                        <br/>
                      </span>
                  }
                  <div class="form-group">
                  <div class="form-check">
                    <input  class="form-check-input"
                     type="checkbox" 
                     checked={checked} onChange={() => setChecked(!checked)}
                      value="" id="limitCheck" />
                    <label class="form-check-label" for="limitCheck">
                       Make Limited
                    </label>
                  </div>
                </div>
                  <button type="submit" disabled={disableSubmitBtn} class="btn btn-info btn-block w-75 mx-auto"><i class="fas fa-paper-plane"></i> Submit</button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  )
}

export default AddCouponSec