import React from "react";
import Item from './Item';
const Processing = ({data}) => {
  return <>
      <Item title='Processing' data={data} />
  </>;
};

export default Processing;

