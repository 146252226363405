import React from "react";
import "./BannerSliderSection.css";
import BannerSlider from "./BannerSlider";
import MiniSlider from "./MiniSlider";
import BannerRight from "./BannerRight";

const BannerSlider_MiniSlider_RightBanner = () => {
  return (
    <div className="container-fluid  banar_slider_section">
      <div className="row">
        <div className="col-lg-3 position"></div>
        <div className="col-lg-7 col-md-9 col-12 mx-auto">
          <div className="banner_slider">
            <BannerSlider />
          </div>
          <div className="mini-product-carousel">
            <MiniSlider />
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-3 mx-auto d-none d-xxl-block d-md-block">
          <BannerRight />
        </div>
      </div>
    </div>
  );
};
export default BannerSlider_MiniSlider_RightBanner;
