import React, {useEffect, useState}  from 'react'
import { Link} from 'react-router-dom'
import PageHeaderBreadCrumb from '../PageHeaderBreadCrumb/PageHeaderBreadCrumb'
import {Helmet} from "react-helmet";
import axios from "axios";
const DashBoard_Home = () => {


  const [data, setData] = useState([]);
   
  // get data
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
          const res = await axios.get('/dashboard');
          if(isMounted){
            if (res.data.status === 200) {
              setData(res.data.data);
             }
  
          }
       
      } catch (e) {
         console.log(e);
      }
  }
  
  
    fetchData();
  
    return () => {
      isMounted = false;
    }
  }, []);

const {order, custom_service, user, all_products}=data;




  return (
    <>
         {/* seo title */}
         <Helmet>
        <title> Home || Dashboard</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        pageTitle="Dashboard"
        title="Dashboard"
      />
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{order}</h3>
                  <p>New Orders</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag" />
                </div>
                <Link to="/new-order" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{custom_service} </h3>
                  <p>Custom Services Request</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <Link to="/new-custom-design" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{user} </h3>
                  <p>User Registrations</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add" />
                </div>
                <Link to="/show/all-users" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-3 col-6">
              {/* small box */}
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{all_products}</h3>
                  <p>All Products</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph" />
                </div>
                <Link to="/all/products" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
              </div>
            </div>
            {/* ./col */}
          </div>
          {/* /.row */}
          {/* Main row */}
          <div className="row">
            {/* Left col */}
            <section className="col-lg-7 connectedSortable">
              {/* Custom tabs (Charts with tabs)*/}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-chart-pie mr-1" />
                    Sales
                  </h3>
                  <div className="card-tools">
                    <ul className="nav nav-pills ml-auto">
                      <li className="nav-item">
                        <a className="nav-link active" href="#revenue-chart" data-toggle="tab">Area</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#sales-chart" data-toggle="tab">Donut</a>
                      </li>
                    </ul>
                  </div>
                </div>{/* /.card-header */}
                <div className="card-body">
                  <div className="tab-content p-0">
                    {/* Morris chart - Sales */}
                    <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 300 }}>
                      <canvas id="revenue-chart-canvas" height={300} style={{ height: 300 }} />
                    </div>
                    <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}>
                      <canvas id="sales-chart-canvas" height={300} style={{ height: 300 }} />
                    </div>
                  </div>
                </div>{/* /.card-body */}
              </div>
            </section>
            {/* /.Left col */}
            {/* right col (We are only adding the ID to make the widgets sortable)*/}
            <section className="col-lg-5 connectedSortable">
              {/* Map card */}
              <div className="card bg-gradient-primary">
                <div className="card-header border-0">
                  <h3 className="card-title">
                    <i className="fas fa-map-marker-alt mr-1" />
                    Visitors
                  </h3>
                  {/* card tools */}
                  <div className="card-tools">
                    <button type="button" className="btn btn-primary btn-sm daterange" title="Date range">
                      <i className="far fa-calendar-alt" />
                    </button>
                    <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse" title="Collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                  {/* /.card-tools */}
                </div>
                <div className="card-body">
                  <div id="world-map" style={{ height: 250, width: '100%' }} />
                </div>
                {/* /.card-body*/}
                <div className="card-footer bg-transparent">
                  <div className="row">
                    <div className="col-4 text-center">
                      <div id="sparkline-1" />
                      <div className="text-white">Visitors</div>
                    </div>
                    {/* ./col */}
                    <div className="col-4 text-center">
                      <div id="sparkline-2" />
                      <div className="text-white">Online</div>
                    </div>
                    {/* ./col */}
                    <div className="col-4 text-center">
                      <div id="sparkline-3" />
                      <div className="text-white">Sales</div>
                    </div>
                    {/* ./col */}
                  </div>
                  {/* /.row */}
                </div>
              </div>
            </section>
            {/* right col */}
          </div>
          {/* /.row (main row) */}
        </div>{/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  )
}

export default DashBoard_Home