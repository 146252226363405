import React, {useState} from "react";
import "./Newsletter.css";
import { useForm } from "react-hook-form";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import axios from "axios";
import { Zoom, toast } from "react-toastify";
const NewsLetter = () => {

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
 
  //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const NewsletteronSubmit = (values) => {
    setDisableSubmitBtn(true);
    const data = values;
    axios
    .post(`/newsletter/insert`, data)
    .then((res) => {
      if (res.data.status === 200) {
        toast.success(res.data.message, {
          position: "top-center",
          theme: "colored",
          transition: Zoom,
          autoClose: 400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        reset();
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});
}
  
 

  return (
    <>
      <div className='news_letter py-3 bg-white'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-md-12 col-10 mx-auto'>
              <div className='newsletter'>
                <h4>Newsletter</h4>
                <span>
                  Subcribe to get information about products and coupons
                </span>
              </div>
            </div>
            <div className='col-lg-7 col-md-12 col-10 mx-auto'>
              <form  onSubmit={handleSubmit(NewsletteronSubmit)}>
                <div className='input-group input-group-lg mb-2 mt-1'>
                  <input
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Enter Your Email'
                    className='form-control'
                    required
                    {...register("email", {
                      required: true,
                      maxLength: 100,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  <MDBBtn type='submit' className='bg-info'   disabled={disableSubmitBtn}>
                    <i className='far fa-envelope'></i> Subscribe
                    {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
                  </MDBBtn>
                </div>
                {/* errors will return when field validation fails  */}
                {validation_error.email  ?
                (
                  <span className='text-danger'>
                    {validation_error.email}
                  </span>
                )
                : errors.email &&
                 (
                  <span className='text-danger'>
                    {errors.email.type === "required" &&
                      "Please give email address"}
                    {errors.email.type === "maxLength" &&
                      "Please give email maximum of 50 characters"}
                    {errors.email.type === "pattern" &&
                      "invalid email address"}
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
