import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";

const EditSubCategoryWrapper = () => {
 

  const { id } = useParams();
  const [categories, setCategories] = useState([]);
 // update 
 const [updateSubcategories, setUpdateSubCategories] = useState([]);

 
 // rerendering axios insert data
 const [rerendering, setRerendering] = useState(false);
 const [loading, setloading] = useState(true);

 // backend validation check
 const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

 // get data
 useEffect(() => {
  let isMounted = true;
   axios.get(`/subcategory/edit/${id}`).then((res) => {
    if (isMounted) {
     if (res.data.status === 200) {
      setUpdateSubCategories(res.data.data);
      setCategories(res.data.category);
     }
     setloading(false);
    }
   });
   return () => {
    isMounted = false;
  }
 }, [rerendering]);

 // handleChange 
 const handleChange = (e) => {
   setUpdateSubCategories({
     ...updateSubcategories,
     [e.target.name]: e.target.value,
   });
 };
 // handleSubmit
 const handleSubmit = (e) => {
  e.preventDefault();
  setDisableSubmitBtn(true);
  const data =  updateSubcategories;
  
  axios
    .post(`/subcategory/update/${updateSubcategories.id}`, data)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setRerendering(!rerendering);
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});
};
if (loading) {
  <h2>loading...</h2>;
}
  return (
    <>
       {/* seo title */}
         <Helmet>
        <title> Edit || SubCategory</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Categories"
        pageTitle="Edit / Sub-Category"
      />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Sub Category</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-card-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fas fa-minus" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form action="#" onSubmit={handleSubmit}>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Category Name</label>
                    <select
                      class="form-control"
                      name="category_id"
                      value={
                        updateSubcategories.category_id}
                      onChange={handleChange}
                      id="exampleFormControlSelect1"
                    >
                       <option disabled="disabled" >--Select Category--</option>
                      {categories && categories.map((val)=>{
                        return(
                          <option value={val.id} {...updateSubcategories.category_id === val.id ? "selected": null} >{val.category_name}</option>
                        )
                      })}
                   
                     
                    </select>
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.category_id}</span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">
                      Sub Category
                    </label>
                    <input
                    // required
                      type="text"
                      id="inputClientCompany"
                      name="sub_category_name"
                      value={updateSubcategories.sub_category_name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Sub Category"
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_category_name}</span>
                  <div className="form-group">
                    <label htmlFor="inputClientCompany">Slug</label>
                    <input
                    // required
                      type="text"
                      id="inputClientCompany"
                      name="sub_category_slug"
                      value={updateSubcategories.sub_category_slug}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter Slug"
                    />
                  </div>
                  <span className='text-danger'>{validation_error && validation_error.sub_category_slug}</span>
                  <button type="submit" class="btn btn-info" disabled={disableSubmitBtn}>
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>{" "}
                  &nbsp;
                  <Link to="/our-dashboard/all/categories">
                    <button type="submit" class="btn btn-info">
                      Back <i class="fas fa-angle-double-left"></i>
                    </button>
                  </Link>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditSubCategoryWrapper;
