import React from 'react'
import AllSearchSec from '../../../../Components/admin/SearchSection/AllSearchSec'

const AllSearches = () => {
  return (
    <>
    <AllSearchSec/>
    </>
  )
}

export default AllSearches