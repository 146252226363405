import React from 'react'
import EditSubCategoryWrapper from '../../../../Components/admin/Sub_Categories_Section/Edit_SubCategoryWrapper'

const EditeSubcategories = () => {
  return (
    <>
    <EditSubCategoryWrapper/>
    </>
  )
}

export default EditeSubcategories