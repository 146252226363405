import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import "../BlogIndex.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,
} from "mdb-react-ui-kit";
import { FcFolder } from "react-icons/fc";
import { VscComment } from "react-icons/vsc";


const BlogSingleCategory = (props) => {
  const { category } = useParams();
  const [loading, setloading] = useState(true);
  const [blog_single_category_by_post, setBlog_single_category_by_post] =
    useState([]);

  

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
      const res = await axios.get(`/blog/post/cats-by-post/${category}`)
      if(isMounted){
        if (res.data.status === 200) {
          setBlog_single_category_by_post(
            res.data.blog_single_category_by_post
          );
        }
        setloading(false);
      }  
     } catch (e) {
        console.log(e);
     }
      
   }

   fetchData();
    
    return () => {
      isMounted = false;
    }
  }, [category]);


  return (
    <>
    
              <div className='blog_card py-2'>
                <div className='row'>
                  {loading
                    ? Array(3)
                        .fill()
                        .map((val, idx) => {
                          return (
                            <div className='col-lg-4 col-md-6 mt-2' key={idx}>
                              <ContentLoader
                                width='100%'
                                height='100%'
                                viewBox='0 0 450 360'
                                backgroundColor='#f0f0f0'
                                foregroundColor='#dedede'
                                {...props}>
                                <rect
                                  x='43'
                                  y='304'
                                  rx='4'
                                  ry='4'
                                  width='271'
                                  height='9'
                                />
                                <rect
                                  x='44'
                                  y='323'
                                  rx='3'
                                  ry='3'
                                  width='119'
                                  height='6'
                                />
                                <rect
                                  x='42'
                                  y='77'
                                  rx='10'
                                  ry='10'
                                  width='388'
                                  height='217'
                                />
                              </ContentLoader>
                            </div>
                          );
                        })
                    : blog_single_category_by_post?.map((val) => {
                        return (
                          <div className='col-lg-4 col-md-6 mt-2' key={val.id}>
                            <MDBCard className='border'>
                              <MDBRipple
                                rippleColor='light'
                                rippleTag='div'
                                className='bg-image hover-overlay'>
                                <Link
                                  to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                  <MDBCardImage
                                    className='custom_img'
                                    src={
                                      val?.blog_image == null
                                        ? null
                                        :  `${process.env.REACT_APP_API_URL}${val?.blog_image}`
                                    }
                                    fluid
                                    alt='blog-post'
                                  />
                                </Link>
                              </MDBRipple>
                              <MDBCardBody>
                                <MDBCardTitle>
                                  <Link
                                    to={`/blog/post/${val?.slug.toLowerCase()}`}>
                                    {val.heading}
                                  </Link>
                                </MDBCardTitle>

                                <MDBCardText>
                                  <div className='post_info'>
                                    <span className='date px-2'>
                                      <i class='fas fa-calendar-alt pe-1'></i>
                                      {moment(val?.created_at).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                    <Link to='/blog'>
                                      <span className='post-admin'>
                                        <i class='fas fa-user-alt pe-1'></i>
                                        {val?.name}
                                      </span>
                                    </Link>
                                    <Link
                                      to={`/blog/category/${val?.category_slug}`}>
                                      <span className='post_cate_gory px-1'>
                                        <FcFolder />
                                        &nbsp;
                                        {val?.category_name}
                                      </span>
                                    </Link>
                                    <Link to='/blog'>
                                      <span className='cate_gory ps-2'>
                                        <VscComment />
                                      </span>
                                    </Link>
                                  </div>
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </div>
                        );
                      })}
                </div>
              </div>
             
    </>
  );
};

export default BlogSingleCategory;
