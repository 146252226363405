import React, { useState } from "react";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import {  toast } from "react-toastify";
import {Helmet} from "react-helmet";
const AddBrand = () => {
  const [insert, setinsert] = useState({
    brand_image: "",
    img_alt: "",
  });
  const {brand_image, img_alt } = insert;

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);

 //  btn disabled
 const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const handleChange = (e) => {
    setinsert({
      ...insert,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setinsert({
      ...insert,
      [e.target.name]: e.target.files[0],
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("brand_image", insert.brand_image);
    formData.append("img_alt", img_alt);
    axios
    .post(`/setting/brand/insert`, formData)
    .then((res) => {
      if (res.data.status === 200) {
        toast.info(res.data.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDisableSubmitBtn(false);
        setinsert({
          brand_image: "",
          img_alt: "",
        });
        document.getElementById("resetForm").reset();
        setValidation_error({});
      } else {
        setValidation_error(res.data.validation_errors);
        setDisableSubmitBtn(false);
      }
    })
    .catch((err) => {});

  };
  return (
    <>
            {/* seo title */}
            <Helmet>
        <title>Add || Setting-Brand</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Add-Brand" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Add Brand</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/setting/all-brand"
                    className="btn btn-success btn"
                  >
                    All Brand
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} encType='multipart/form-data' id='resetForm'>
                  <div className="form-group">
                    <label htmlFor="brand_img">Brand Image</label>

                    <input
                      required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="brand_image"
                      onChange={handleChangeImage}
                      id="brand_image"
                    />
                  </div>
                  {
                    validation_error.brand_image &&
                    <span className='text-danger'>
                          {validation_error.brand_image}
                        <br/>
                      </span>
                  }
                  

                    {
                    brand_image &&
                    <img
                    alt='item-img'
                    width='60'
                    src={URL.createObjectURL(brand_image)}
                  />
                   }
                  <div className="form-group">
                    <label htmlFor="inputslug">Brand Image Alt</label>
                    <input
                      required
                      type="text"
                      name="img_alt"
                      value={img_alt}
                      onChange={handleChange}
                      id="brand_alt"
                      className="form-control"
                      placeholder="Enter Brand Image Alt"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-success btn-block w-75 mx-auto"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default AddBrand;
