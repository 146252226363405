import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast,Zoom } from "react-toastify";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import EditorEdit from "../../Editor/Editor";

const EditSpecialServiceSec = () => {
  const { id } = useParams();

  const [update, setUpdate] = useState([]);

// image preview
  const [previewImage, setPreviewImage] = useState({
    image:'',
    thumbnail_image:''
  });
 
  // rerendering axios insert data
  const [rerendering, setRerendering] = useState(false);
  const [loading, setloading] = useState(true);

  // backend validation check
  const [validation_error, setValidation_error] = useState([]);
  
   //  btn disabled
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  
  // get data
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      axios.get(`/setting/special-service/edit/${id}`).then((res) => {
        if (res.data.status === 200) {
          setUpdate(res.data.special_service);
        }
        setloading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [rerendering]);

  const handleChange = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeImage = (e) => {
    setUpdate({
      ...update,
      [e.target.name]: e.target.files[0],
    });
    setPreviewImage({...previewImage, [e.target.name]: e.target.files[0]});
  };


  const handleDetails = (newtext: any) => {
    setUpdate({
      ...update,
      detail: newtext,
    });
    
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitBtn(true);
    const formData = new FormData();
    formData.append("title", update?.title);
    formData.append("slug", update?.slug);
    formData.append("image", update?.image);
    formData.append("img_alt", update?.img_alt);
    formData.append("thumbnail_image", update?.thumbnail_image);
    formData.append("thumb_title", update?.thumb_title);
    formData.append("detail", update?.detail);
    axios
      .post(`/setting/special-service/update/${update.id}`, formData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: "top-center",
            theme: 'colored',
            transition: Zoom,
            autoClose: 200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValidation_error({});
          setRerendering(!rerendering);
          setPreviewImage({
            image:'',
            thumbnail_image:''
          });
          setDisableSubmitBtn(false);
        } else {
          setValidation_error(res.data.validation_errors);
          setDisableSubmitBtn(false);
        }
      })
      .catch((err) => {});
  };

  if (loading) {
    return <h2>loading....</h2>;
  }
  return (
    <>
         {/* seo title */}
         <Helmet>
        <title>Edit || Special-Service</title>
        </Helmet>


      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb Title="Setting" pageTitle="Edit-Special-Service" />
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Special Service</h3>
                <div className="card-tools">
                  <Link
                    to="/our-dashboard/setting/all-special-service"
                    className="btn btn-success btn"
                  >
                    All Special Service
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <form  onSubmit={handleSubmit} id="resetForm" encType='multipart/form-data'>
                  <div className="form-group">
                    <label htmlFor="inputtitle">Title</label>
                    <input
                    // required
                      type="text"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      value={update.title}
                      className="form-control"
                      placeholder="Enter title"
                    />
                  </div>
                  {
                    validation_error.title &&
                    <span className='text-danger'>
                          {validation_error.title}
                      
                      </span>
                  }

                   <div className="form-group">
                    <label htmlFor="inputtitle">Slug</label>
                    <input
                    // required
                      type="text"
                      id="slug"
                      name="slug"
                      onChange={handleChange}
                      value={update.slug}
                      className="form-control"
                      placeholder="Enter slug"
                    />
                  </div>
                  {
                    validation_error.slug &&
                    <span className='text-danger'>
                          {validation_error.slug}
                      
                      </span>
                  }

                 
                  <div className="form-group">
                    <label htmlFor="img">Image</label>

                    <input
                    // required
                      type="file"
                      className="form-control"
                      accept="image/*"
                      name="image"
                      onChange={handleChangeImage}
                      id="img"
                    />
                  </div>
                  {
                    validation_error.image &&
                    <span className='text-danger'>
                          {validation_error.image}
                      <br />
                      </span>
                  }
                  {
                    previewImage.image &&
                    <img
                    alt='image_'
                    width='60'
                    src={URL.createObjectURL(previewImage.image)}
                  />
                   }
                   
                    {
                      !previewImage.image && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update.image == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${update?.image}`
                    }
                  />
                  }
                  <div className="form-group">
                    <label htmlFor="inputslug">Image Alt</label>
                    <input
                    // required
                      type="text"
                      id="inputalt"
                      name="img_alt"
                      onChange={handleChange}
                      value={update.img_alt}
                      className="form-control"
                      placeholder="Enter Image Alt"
                    />
                  </div>
                  {
                    validation_error.img_alt &&
                    <span className='text-danger'>
                          {validation_error.img_alt}
                      <br />
                      </span>
                  }

                  <div className="form-group">
                    <label htmlFor="thumbnail_image">Thumbnail Image</label>
                    <input
                    // required
                      type="file"
                      id="thumbnail_image"
                      name="thumbnail_image"
                      onChange={handleChangeImage}
                      className="form-control"
                      placeholder="thumbnail image"
                    />
                  </div>
                  {
                    previewImage.thumbnail_image &&
                    <img
                    alt='thumbnail_image'
                    width='60'
                    src={URL.createObjectURL(previewImage.thumbnail_image)}
                  />
                   }
                   
                    {
                      !previewImage.thumbnail_image && 
                     <img
                    alt='item-img'
                    width='50'
                    src={
                      update.thumbnail_image == null
                        ? null
                        : `${process.env.REACT_APP_API_URL}${update?.thumbnail_image}`
                    }
                  />
                  }


                  {
                    validation_error.thumbnail_image &&
                    <span className='text-danger'>
                          {validation_error.thumbnail_image}
                      <br />
                      </span>
                  }

                  <div className="form-group">
                    <label htmlFor="thumb_title">Thumb Title</label>
                    <input
                    // required
                      type="text"
                      id="thumb_title"
                      name="thumb_title"
                      onChange={handleChange}
                      value={update.thumb_title}
                      className="form-control"
                      placeholder="Enter thumb title"
                    />
                  </div>
                  {
                    validation_error.thumb_title &&
                    <span className='text-danger'>
                          {validation_error.thumb_title}
                      <br />
                      </span>
                  }

                  <label htmlFor="thumbnail_image">Service Info / Details</label>
                     <EditorEdit
                      name="community"
                      handleChange={handleDetails}
                      value={update.detail}              />

                {
                    validation_error.detail &&
                    <span className='text-danger'>
                          {validation_error.detail}
                      <br />
                      </span>
                  }

                  <button
                    type="submit"
                    class="btn btn-info btn-block w-75 mx-auto my-2"
                    disabled={disableSubmitBtn}
                  >
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </div>
      </section>
      {/* /.content */}
    </>
  );
};

export default EditSpecialServiceSec;
