import React from 'react'
import "./Title.css"
const Title = (props) => {
    return (
        <>   
        <h5 className='title text-center fw-bold'>{props.section_title}</h5>
        </>
    )
}

export default Title
