import React, { useCallback, useState, useContext } from 'react'
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialTwitter,
  } from 'reactjs-social-login'
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import authContext from './../../auth/Context/AuthUser/authContext';
import axios from "axios";
import {toast,Zoom} from 'react-toastify';
import {  useNavigate } from "react-router-dom";

const SocialLogin = () => {
  const navigate = useNavigate();
  const {Login, userLogin, userLogOut } = useContext(authContext);
      //  btn disabled
     const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);


    const onLogoutSuccess = useCallback(() => {
      if(Login){
        axios
        .post("/logout")
        .then((res) => {
          if (res.data.status === 200) {
            toast.info(res.data.message, {
              position: "top-right",
              autoClose: 200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.removeItem("user_token");
            userLogOut();
            navigate('/');
            window.location.reload();
          }
        })
        .catch((err) => {});
      }
      
    }, [])

     const REDIRECT_URI = 'http://localhost:3000/'
  
    return (
      <>
          <div className='d-flex justify-content-center'>
            <LoginSocialFacebook
              appId={process.env.REACT_APP_FB_APP_ID || ''}
            fieldsProfile={
              'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={REDIRECT_URI}
            onResolve={(res) => {
              if(res?.provider && res?.data?.accessToken ){
                setDisableSubmitBtn(true);
                  const data = {
                    provider: res?.provider,
                    access_token:  res?.data?.accessToken 
                  }
                  axios.get("/sanctum/csrf-cookie").then((response) => {
                    axios
                      .post("/auth/social/callback", data)
                      .then((res) => {
                        if (res.data.status === 200) {
                          toast.info(res.data.message, {
                            position: "top-right",
                            theme: 'colored',
                            autoClose: 500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          setDisableSubmitBtn(false);
                          userLogin();
                          localStorage.setItem("user_token", res.data.access_token);
                        }else {
                          setDisableSubmitBtn(false);
                        }
                      })
                      .catch((err) => {
                   
                      });
                  });
                  setDisableSubmitBtn(false);
              }
            }}
            onReject={err => {
              console.log(err);
            }}
            >
              <MDBBtn
               disabled={disableSubmitBtn}
              size='sm'
              floating
              className='mx-1'
              style={{ backgroundColor: "#3b5998" }}
              href='#'>
              <MDBIcon fab icon='facebook-f' />
            </MDBBtn>
            </LoginSocialFacebook>
  
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GG_APP_ID || ''}
              fieldsProfile={
                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
              }
              // scope="https://www.googleapis.com/auth/userinfo.email openid"
            
              onResolve={(res) => {
                if(res?.provider && res?.data?.access_token ){
                  console.log(res)
                  setDisableSubmitBtn(true);
                    const data = {
                      provider: res?.provider,
                      access_token:  res?.data?.access_token 
                    }
                    axios.get("/sanctum/csrf-cookie").then((response) => {
                      axios
                        .post("/auth/social/callback", data)
                        .then((res) => {
                          if (res.data.status === 200) {
                            toast.info(res.data.message, {
                              position: "top-right",
                              theme: 'colored',
                              autoClose: 500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            setDisableSubmitBtn(false);
                            userLogin();
                            localStorage.setItem("user_token", res.data.access_token);
                          }else if (res.data.status === 404) {
                            toast.error(res.data.message, {
                                position: "top-center",
                                theme: 'colored',
                                transition: Zoom,
                                autoClose: 400,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    setDisableSubmitBtn(false);
                          
                        })
                        .catch((err) => {
                     
                        });
                    });
                    setDisableSubmitBtn(false);
                }
              }}
              onReject={(err) => {
                console.log(err)
              }}
            >
            <MDBBtn
             disabled={disableSubmitBtn}
              size='sm'
              floating
              className='mx-1'
              style={{ backgroundColor: "#dd4b39" }}
              href='#'>
              <MDBIcon fab icon='google' />
            </MDBBtn>
            </LoginSocialGoogle>
  
           
            <LoginSocialTwitter
              client_id={process.env.REACT_APP_TWITTER_APP_ID || ''}
              fieldsProfile={
                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
              }
              redirect_uri={REDIRECT_URI}
              onResolve={(res) => {
                // if(res?.provider && res?.data?.access_token ){
                //   setDisableSubmitBtn(true);
                //     const data = {
                //       provider: res?.provider,
                //       access_token:  res?.data?.access_token 
                //     }
                //     axios.get("/sanctum/csrf-cookie").then((response) => {
                //       axios
                //         .post("/auth/social/callback", data)
                //         .then((res) => {
                //           if (res.data.status === 200) {
                //             toast.info(res.data.message, {
                //               position: "top-right",
                //               theme: 'colored',
                //               autoClose: 500,
                //               hideProgressBar: false,
                //               closeOnClick: true,
                //               pauseOnHover: true,
                //               draggable: true,
                //               progress: undefined,
                //             });
                //             setDisableSubmitBtn(false);
                //             userLogin();
                //             localStorage.setItem("user_token", res.data.access_token);
                //           }else {
                //             setDisableSubmitBtn(false);
                //           }
                //         })
                //         .catch((err) => {
                     
                //         });
                //     });
                //     setDisableSubmitBtn(false);
                // }
              }}
              onReject={(err) => {
                console.log(err)
              }}
            >
              <MDBBtn
               disabled={disableSubmitBtn}
              size='sm'
              floating
              className='mx-1'
              style={{ backgroundColor: "#55acee" }}
              href='#'>
              <MDBIcon fab icon='twitter' />
            </MDBBtn>   
            </LoginSocialTwitter>
  
          </div>

      </>
    )};

export default SocialLogin;
