import React, { useState } from "react";
import { MDBBtn,MDBSpinner } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Zoom, toast } from "react-toastify";
import axios from "axios";

const ForgotVerifyPin = (props) => {
  const [validation_error, setValidation_error] = useState([]);
   //  btn disabled
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const user_forgot_email = localStorage.getItem("user_forgot_email")
  const handleResendOTP = ()=>{
    const data = {
        'email':user_forgot_email,
    };
            axios
              .post("/resend/forgot/pin", data)
              .then((res) => {
                if (res.data.status === 200) {
                  toast.info(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 200,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else if (res.data.status === 400) {
                    toast.error(res.data.message, {
                        position: "top-center",
                        theme: 'colored',
                        transition: Zoom,
                        autoClose: 200,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
              })
              .catch((err) => {});

  }
  return (
    <>
      
      <div className='otp_pin_verify'>
        <h4 className='text-center py-3 fw-bold border-bottom w-75 mx-auto'>
         Verify Code
        </h4>
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: user_forgot_email,
            otp_pin: '',
          }}
          validate={(values) => {
            const errors = {};

            // otp pin validate condition
          if (!values.otp_pin) {
            errors.otp_pin = "Please Input Your OTP Pin";
          } else if (!/^[0-9\b]+$/i.test(values.otp_pin)) {
            errors.otp_pin = "Invalid OTP Pin";
          }  else if (values.otp_pin.length > 6) {
            errors.otp_pin = "OTP Pin maximum length of 6 characters";
          }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            setDisableSubmitBtn(true);
            const data = values;
            axios
              .post("/verify/pin", data)
              .then((res) => {
                if (res.data.status === 200) {
                  document.getElementById("resetForm").reset();
                  resetForm();
                  toast.success(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                  props.handleResetPassword(true);
                  // localStorage.setItem("user_token", res.data.access_token);
                }else if (res.data.status === 400){
                  toast.error(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                }else if (res.data.status === 401){
                  toast.error(res.data.message, {
                    position: "top-center",
                    theme: 'colored',
                    transition: Zoom,
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setDisableSubmitBtn(false);
                } else {
                  setDisableSubmitBtn(false);
                  setValidation_error(res.data.validation_errors);
                }
              })
              .catch((err) => {
                setDisableSubmitBtn(false);
              });
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} id='resetForm'>
              {/* otp_pin Input */}
              <div>
              <lable className='fw-bold float-start'>Your Pin</lable>
          <Link to='##' className='fw-normal float-end text-dark' 
          onClick={handleResendOTP}
          >Resend Code?</Link>
                <div className='input-group'>
                  <input
                    type='text'
                    name='otp_pin'
                    id='otp_pin'
                    // disabled
                    placeholder='Enter Your Pin'
                    className='form-control'
                    onChange={formik.handleChange}
                    value={formik.values.otp_pin}
                    touched={formik.touched.otp_pin}
                    onBlur={formik.handleBlur}
                  />
                  <label className='input-group-text' htmlFor='otp_pin'>
                  <i class="fas fa-key"></i>
                  </label>
                </div>
                {/* errors will return when field validation fails  */}
                {validation_error.otp_pin ? (
                  <span className='text-danger'>{validation_error.otp_pin}</span>
                ) : (
                  formik.touched.otp_pin &&
                  formik.errors.otp_pin && (
                    <span className='text-danger'>{formik.errors.otp_pin}</span>
                  )
                )}
              </div>
              

              <div>
                <MDBBtn
                  type='submit'
                  rounded
                  className='login-submit-btn btn btn-danger btn-rounded btn-block my-3 mx-auto'
                  color='danger'
                  disabled={disableSubmitBtn}
                  >
                  VerifY Code
                  {disableSubmitBtn && <MDBSpinner size='sm' role='status' tag='span' className='ms-2' /> }
                </MDBBtn>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotVerifyPin;
