export const WorkShopSeminarData = [
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
    {
        img:"https://mdbootstrap.com/img/new/standard/city/062.webp",
        titel:"Workshop & Seminar Schedule",
        date:"Data: 20-3-2022",
        location:"Rajshahi",
        description:"Some quick example text to build on the card title and make up."
    },
]