import React, { useEffect } from "react";
import {Helmet} from "react-helmet";
import PageHeaderBreadCrumb from "../PageHeaderBreadCrumb/PageHeaderBreadCrumb";
import Pagination from "../Pagination/Pagination";
const SearchesWrapper = (props) => {
  const { searchesData } = props;
  useEffect(() => {
    // initialize datatable
    const script = document.createElement("script");
    script.src = "/dist/js/custom.js";
    script.async = true;
    document.body.appendChild(script);
  });
  return (
    <>
            {/* seo title */}
        <Helmet>
        <title>All || Search</title>
        </Helmet>

      {/* -------PageHeaderBreadCrumb------- */}
      <PageHeaderBreadCrumb
        Title="Searches"
        pageTitle={searchesData[0].pageTitle}
      />
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-body p-0">
            <table id="example1" className="table table-striped projects">
              <thead>
                <tr>
                  <th style={{ width: "70%" }} className="text-center">
                    Keyword
                  </th>
                  <th style={{ width: "30%" }} className="text-center">
                    Count
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchesData.length === 0 ? (
                  <td
                    colSpan="12"
                    className="text-center bg-warning font-weight-bold h4"
                  >
                    No data available in table
                  </td>
                ) : (
                  <>
                    {searchesData.map((Value, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{Value.keyword}</td>
                          <td className="text-center">{Value.count}</td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </>
  );
};

export default SearchesWrapper;
